import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  HStack,
  Stack,
  Container,
  Image,
  Divider,
  Text,
  IconButton,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

// Core Files
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import { GrPauseFill } from "@react-icons/all-files/gr/GrPauseFill";
import { MdSkipNext } from "@react-icons/all-files/md/MdSkipNext";
import { MdSkipPrevious } from "@react-icons/all-files/md/MdSkipPrevious";
import { GrPlayFill } from "@react-icons/all-files/gr/GrPlayFill";
import { BiVolumeMute } from "@react-icons/all-files/bi/BiVolumeMute";
import { Swiper, SwiperSlide } from "swiper/react";

// Service Files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getLyricsSoundtrackData from "../../../services/LyricsSoundtrack";

// Subsection Files
import Small from "../../../components/layouts/Typography/Small";
import H4 from "../../../components/layouts/Typography/H4";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const sound1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/LyricsSoundtrack/Bajaye Bansuriya Shyam.mp3`
const sound3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/LyricsSoundtrack/Hindola Jhule Shyam.mp3`
const sound2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/LyricsSoundtrack/Tadap Tadap Sagri Rain Guzri.mp3`
const polygon = `${IMAGE_BASE_PATH}/ui/Chevron/polygon.png`
const play = `${IMAGE_BASE_PATH}/ui/Chevron/play.png`
const soundIcon = `${IMAGE_BASE_PATH}/ui/Chevron/soundicon.svg`


export const LyricsSoundtrack = ({ winnerName, mergeData, data }) => {
  const [lyricsSoundtrack, setLyricsSoundtrack] = React.useState([{}]);
  const [currentSongData, setCurrentSongData] = React.useState({});
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [nextButtonColor, setNextButtonColor] = useState("white");
  const [previousButtonColor, setpreviousButtonColor] = useState("white");
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const [soundImage, setSoundImage] = useState([]);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);

  const showFullImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const citationFactor = (data) => {
    return data.map((item) => {
      return {
        songName: item.find((nestedItem) => nestedItem.key === "name")?.value,
        artistName:
          item.find((nestedItem) => nestedItem.key === "personality")?.value
            .length > 0
            ? item.find((nestedItem) => nestedItem.key === "personality")
                ?.value[0]?.full_name
            : "",
        duration: item.find(
          (nestedItem) => nestedItem.key === "duration_of_song"
        )?.value,
        songLink: item.find((nestedItem) => nestedItem.key === "song_link")
          ?.value,
        songImage:
          item.find((nestedItem) => nestedItem.key === "lyricsImage")?.value
            .length > 0 &&
          item.find((nestedItem) => nestedItem.key === "lyricsImage")?.value,
      };
    });
  };
  React.useEffect(() => {
    const getData = async () => {
      const result = await getLyricsSoundtrackData.lyricsSoundtrackByFlim(
        "soundTrack",
        winnerName
      );
      if (result) {
        setLyricsSoundtrack(citationFactor(result));
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (lyricsSoundtrack) {
      setSoundImage(lyricsSoundtrack?.filter((item) => item?.songImage));
    }
  }, [lyricsSoundtrack]);

  React.useEffect(() => {
    if (
      lyricsSoundtrack?.length > 0 &&
      Object.keys(currentSongData).length === 0
    ) {
      setCurrentSongData(lyricsSoundtrack[currentSongIndex]);

      if (currentSongIndex === 0) {
        setpreviousButtonColor("#747474");
      }
    }
  }, [currentSongData, currentSongIndex, lyricsSoundtrack]);

  const handleSongClick = (song, index) => {
    setCurrentSongData(song);
    setIsPlaying(false);
    if (index === 0) {
      setpreviousButtonColor("#747474");
      setNextButtonColor("white");
    } else if (index === lyricsSoundtrack.length - 1) {
      setpreviousButtonColor("white");
      setNextButtonColor("#747474");
    } else {
      setpreviousButtonColor("white");
      setNextButtonColor("white");
    }
  };

  const handlePreviousButtonClick = () => {
    if (currentSongIndex > 0) {
      let previousIndex = currentSongIndex - 1;
      setCurrentSongIndex(previousIndex);
      handleSongClick(lyricsSoundtrack[previousIndex], previousIndex);
      setIsPlaying(false);

      if (previousIndex === 0) {
        setpreviousButtonColor("#747474");
        setNextButtonColor("white");
      } else {
        setpreviousButtonColor("white");
        setNextButtonColor("white");
      }
    }
  };

  const handleNextButtonClick = () => {
    if (currentSongIndex < lyricsSoundtrack.length - 1) {
      let nextIndex = currentSongIndex + 1;
      setCurrentSongIndex(nextIndex);
      handleSongClick(lyricsSoundtrack[nextIndex], nextIndex);
      setIsPlaying(false);

      if (nextIndex === lyricsSoundtrack.length - 1) {
        setNextButtonColor("#747474");
        setpreviousButtonColor("white");
      } else {
        setpreviousButtonColor("white");
        setNextButtonColor("white");
      }
    }
  };

  const handlePauseClick = () => {
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event) => {
    const audioElement = audioRef.current;
    const newVolume = event.target.value;
    setVolume(newVolume);
    audioElement.volume = newVolume;
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    const audioElement = audioRef.current;

    if (isMuted) {
      audioElement.volume = volume;
    } else {
      audioElement.volume = 0;
    }
  };

  return (
    <Stack position={"relative"} height="calc(100vh - 280px)">
      <audio
        ref={audioRef}
        src={
          currentSongIndex === 0
            ? sound1
            : currentSongIndex === 1
            ? sound2
            : sound3
        }
      />
      <Stack
        justifyContent={"space-between"}
        width={"100%"}
        pb={"14"}
        flexDirection={{ base: "column", md: "row" }}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/3`)}
      >
        <HStack alignItems={"flex-start"} paddingTop={"20px"}>
          <Box>
            {lyricsSoundtrack?.map((item, index) => (
              <>
                <Stack
                  key={item?.songName}
                  flexDirection={"row"}
                  py="2"
                  justifyContent={"space-between"}
                >
                  <HStack alignItems={"flex-start"} gap={2}>
                    <Image
                      pt="2"
                      src={
                        currentSongData?.songName === item?.songName &&
                        isPlaying
                          ? play
                          : polygon
                      }
                      cursor={"pointer"}
                      onClick={() => {
                        setCurrentSongIndex(index);
                        handleSongClick(item, index);
                        handlePauseClick();
                      }}
                    ></Image>
                    <Box
                      paddingLeft={"15px"}
                      cursor={"pointer"}
                      onClick={() => {
                        setCurrentSongIndex(index);
                        handleSongClick(item, index);
                      }}
                    >
                      <H4>{item?.songName}</H4>
                      <Small py="1">{item?.artistName}</Small>
                    </Box>
                    
                  </HStack>
                  <HStack className="mt-0">
                    <IconButton
                      variant={"unstyled"}
                      fontSize={"25px"}
                      paddingLeft="30px"
                    >
                      <AiOutlineInfoCircle />
                    </IconButton>
                    <Box paddingLeft="10px">{item?.duration}</Box>
                  </HStack>
                </Stack>
                <Divider color={"#DFDFDF"}/>
              </>
            ))}
            <Divider color={"#DFDFDF"}></Divider>
          </Box>
        </HStack>
        <HStack
          width={{ base: "100%", md: "390px" }}
          px="0px"
          alignItems={"flex-center"}
        >
          <Stack className="col-12">
            <Stack id={"satyajitslidersound"} position={"relative"}>
              <HStack py="2">
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation]}
                >
                  {soundImage?.length > 0 &&
                    soundImage[0]?.songImage?.length > 0 &&
                    soundImage[0]?.songImage?.map((item, id) => {
                      return (
                        <>
                          <SwiperSlide className="myslide">
                            <Stack>
                              <Box width={"auto"} height={"354px"}>
                                <Image
                                  onClick={(e) => showFullImages(item, true , id)}
                                  width={"100%"}
                                  height={"inherit"}
                                  src={item?.value}
                                  cursor={"pointer"}
                                />
                              </Box>
                              <Text
                                pt={"16px"}
                                fontSize={"16px"}
                                fontWeight={"400"}
                                lineHeight={"17px"}
                                color="#035DA1"
                                textAlign={"left"}
                              >
                                {`${item?.name_of_publication} | ${item?.date_of_artwork} | ${item.medium_of_artwork} | ${item.revised_medium}`}
                              </Text>
                            </Stack>
                          </SwiperSlide>
                        </>
                      );
                    })}
                </Swiper>
              </HStack>
            </Stack>
            <HStack>
             <ImagePopupModal
              isOpen={fullScreenImage}
              onClose={() => showFullImages(null, false)}
              soundImage={soundImage[0]}
              imgData={imgData}
              clickedImageIndex={clickedImageIndex}
              imageHeight={{ base: "100%", md: "90vh" }}
             />
            </HStack>
          </Stack>
        </HStack>
      </Stack>
      <Box
        position={"fixed"}
        bottom={{ base: "8%", md: "0%" }}
        bg="black"
        p="6"
        marginLeft={{ base: "-20px !important", md: "-30px !important" }}
        width={{ base: "100%", md: "1192px" }}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/3`)}
      >
        <Container maxW={"944px"}>
          <Stack
            justifyItems={"center"}
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={"space-between"}
            alignItems={{ base: "center", md: "" }}
          >
            <HStack>
              <Wrap spacing={4}>
                <WrapItem>
                  <IconButton
                    variant={"unstyled"}
                    fontSize={"36px"}
                    color={previousButtonColor}
                    px={"14px"}
                    onClick={handlePreviousButtonClick}
                  >
                    <MdSkipPrevious />
                  </IconButton>
                </WrapItem>
                <WrapItem
                  width={"45px"}
                  height={"45px"}
                  justifyContent={"center"}
                >
                  <IconButton
                    fontSize={"16px"}
                    color={"black"}
                    borderRadius={"100%"}
                    bg="white"
                    _hover={{
                      outline: "0px",
                      background: "white",
                    }}
                    onClick={() => handlePauseClick()}
                  >
                    {isPlaying ? <GrPauseFill /> : <GrPlayFill />}
                  </IconButton>
                </WrapItem>
                <IconButton
                  variant={"unstyled"}
                  fontSize={"32px"}
                  color={nextButtonColor}
                  onClick={handleNextButtonClick}
                >
                  <MdSkipNext />
                </IconButton>
              </Wrap>
            </HStack>
            <HStack>
              <Box>
                <H4 color="white">{currentSongData?.songName}</H4>
                <Small color="white">{currentSongData?.artistName}</Small>
              </Box>
            </HStack>
            <HStack>
              <ButtonGroup variant="unstyled">
                {isMuted ? (
                  <BiVolumeMute
                    onClick={() => {
                      toggleMute();
                    }}
                    fontSize={"24px"}
                    color="white"
                  />
                ) : (
                  <Image
                    src={soundIcon}
                    onClick={() => {
                      toggleMute();
                    }}
                  ></Image>
                )}
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  style={{
                    height: "7px",
                    WebkitAppearance: "none",
                    background: "white",
                    border: "none",
                    borderRadius: "5px",
                    outline: "none",
                    opacity: "0.7",
                    transition: "opacity .2s",
                    cursor: "pointer",
                    marginTop: "8px",
                  }}
                />
              </ButtonGroup>
            </HStack>
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};

export const ImagePopupModal = ({
  isOpen,
  onClose,
  soundImage,
  clickedImageIndex,
  imageHeight,
}) => {

  return (
    <>
     <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {soundImage?.songImage?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                        height={"100vh"}
                      >
                        <Image
                          src={
                            item?.value
                          }
                          height={imageHeight ? imageHeight : "100%"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                                pt={"16px"}
                                fontSize={"16px"}
                                fontWeight={"400"}
                                lineHeight={"17px"}
                                color="#035DA1"
                                textAlign={"left"}
                              >
                                {`${item?.name_of_publication} | ${item?.date_of_artwork} | ${item.medium_of_artwork} | ${item.revised_medium}`}
                              </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
    </>
  );
};