import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import MobileNav from "../../components/Topheader";
import Slider from "./Slider";
import {
  common,
  explore,
  masterpersonalityName,
  personalityType,
} from "../../constants/constants";
import { MdHomeFilled } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import { MasterListType } from "../../CommonComponents/MasterListType";
import * as getMasterlistTypes from "../../services/MasterlistTypes";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`
const ycircle = `${IMAGE_BASE_PATH}/ui/Chevron/sidecircle.png`
const sub0 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe0.png`
const sub1 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe1.png`
const Hline = `${IMAGE_BASE_PATH}/ui/Chevron/Hline2.png`

export default function SanjeevKumar() {
  const { isOpen, onClose } = useDisclosure();
  let { tabIndex, drawerId } = useParams();
  const [showMasterlistType, setShowMasterlistType] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [masterlistOptions, setMasterlistOptions] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (tabIndex) {
      navigate("/sanjeev-kumar", { replace: true });
      if (parseInt(tabIndex) === 1 || parseInt(tabIndex) === 2) {
        setShowMasterlistType(true);
      } else {
        setShowMasterlistType(false);
      }
    }
  }, [tabIndex, navigate, drawerId]);

  useEffect(() => {
    const getData = async () => {
      const result = await getMasterlistTypes.getMasterlistTypeOptions("masterlistType","sanjeev-kumar");
      if (result) {
        const dataIntro = result;
        setMasterlistOptions(dataIntro);
      }
    };
    getData();
  }, []);
  return (
    <SidebarWithHeader>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        bgOpacity="100%"
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack alignItems={"center"}>
              <HStack pt="16" pb="8">
                <Image src={sub0}></Image>
              </HStack>
              <HStack pb="40px">
                <Text fontSize={"35px"} fontWeight={"700"} textAlign="center">
                  {common.SUBSCRIBE_TO_READ_MORE}
                </Text>
              </HStack>
              <HStack pb="8">
                <Box bg="#FFC015" width={"421px"} p="3">
                  <Text fontSize={"25px"} fontWeight={"700"} textAlign="center">
                    {common.SUBSCRIBE}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Image onClick={onClose} src={sub1}></Image>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Stack width={"auto"}>
        <Container maxW={"1380px"} px={"0px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt="9"
            variant="unstyled"
            defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
          >
            <HStack
              position={"fixed"}
              background={"white"}
              zIndex={9}
              paddingTop={"16px"}
              paddingBottom={"16px"}
              top={"64px"}
              paddingX={"130.5px"}
            >
              <TabList
                overflowX="auto"
                justify-content={"flex-end"}
                pb="4"
                alignItems={"center"}
              >
                <Tab
                  p="0"
                  textStyle={"tertiary"}
                  fontSize={"45px"}
                  fontWeight={"700"}
                  fontStyle="normal"
                  lineHeight={"80px"}
                  _selected={{
                    borderBottom: "4px",
                    borderColor: "#333333",
                    marginTop: "8px",
                  }}
                  onClick={() => {
                    setInitialSlide(initialSlide + 1);
                    setShowMasterlistType(false);
                  }}
                >
                  {masterpersonalityName.SANJEEVKUMAR}
                </Tab>

                <Tab
                  p="0"
                  pl={showMasterlistType ? "30rem" : "40rem"}
                  width={"max-content"}
                  color={"#333333"}
                  textStyle={"secondary"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  _selected={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  onClick={() => {
                    setInitialSlide(0);
                    setShowMasterlistType(true);
                  }}
                >
                  {explore.POSTS} (01)
                </Tab>

                <span
                  className="px-2"
                  fontSize="18px"
                  align-items="center"
                  fontWeight="700"
                >
                  |
                </span>

                <Tab
                  p="0"
                  width={"max-content"}
                  color={"#333333"}
                  textStyle={"secondary"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  _selected={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  onClick={() => {
                    setInitialSlide(0);
                    setShowMasterlistType(false);
                  }}
                >
                  {explore.INSIGHTS} (00)
                </Tab>

                {showMasterlistType && (
                  <MasterListType masterlistOptions={masterlistOptions} />
                )}
              </TabList>
            </HStack>
            <TabPanels pt="120px">
              <TabPanel px={"0"} p="0px" className="masterlistslider">
                <Slider />
                <Stack
                  marginTop={"-85px"}
                  justifyContent="space-between"
                  flexDirection={"row"}
                  px="14"
                >
                  <HStack zIndex={"9999"}>
                    {/* <Link href="/research-categories/cinemae"> */}
                    <Text fontSize={"15px"} fontWeight={"600"} color="black">
                      {personalityType.CINE} |
                    </Text>
                    {/* </Link> */}
                    {/* <Link href="/research-categories/indian-cinema"> */}
                    <Text
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="black"
                      marginLeft={"-4px"}
                    >
                      {explore.AGRAPHY}
                    </Text>
                    {/* </Link> */}
                    <Image src={Hline}></Image>
                  </HStack>
                  <HStack>
                    <Link my="5px" py="3">
                      <Image
                        src={ycircle}
                        width="auto"
                        height={"100%"}
                        px="1"
                        pb="1"
                      ></Image>
                      <Text
                        fontSize={"10px"}
                        fontWeight="700"
                        textStyle={"secondary"}
                        color={"#0066B3"}
                        textAlign="center"
                      >
                        {common.SUBSCRIBE}
                      </Text>
                    </Link>
                  </HStack>
                </Stack>
                <Stack alignItems={"center"}>
                  <Box>
                    <Text
                      py={"40"}
                      fontSize={"50px"}
                      fontWeight="900"
                      textAlign="center"
                    >
                    {common.WEBPAGE_COMING_SOON}
                    </Text>
                  </Box>
                </Stack>
              </TabPanel>

              <TabPanel px={"0"} py="0" width="100%">
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {common.WEBPAGE_COMING_SOON}
                </Text>
              </TabPanel>
              <TabPanel px={"0"} py="0" width="100%">
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                   {common.WEBPAGE_COMING_SOON}
                </Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [setPage] = useState("banner");
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}

      <MobileNav onOpen={onOpen} setPage={setPage} />
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
