import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import { explore } from '../../../../constants/constants'
import IllustratedBooks from './Publications/IllustratedBooks'
import AuthoredEditedBooks from './Publications/AuthoredEditedBooks'

export default function Publications({masterlist}) {
  return (
    <>
     <Tabs align={{ xs: "", sm: "" }} w="full">
        <Stack display={"flex"}>
          <Box
            position={"fixed"}
            bg="white"
            width={{ base: "100%", md: "1192px" }}
            paddingTop={"44px"}
            paddingBottom={"18px"}
             ml="-40px"
             px={"40px"}

          >
            <TabList
              overflowX="auto"
              borderBottom={"1px"}
              borderBottomColor={"#f1f1f1"}
              gap={12}
              className="verticalscroll"
            >
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.ILLUSTRATED_BOOKS}
              </Tab>

              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.AUTHORED_EDITED_BOOKS}
              </Tab>
              
            </TabList>
            <TabPanels>
              <TabPanel pt="40px" px="0px">
                <IllustratedBooks masterlist={masterlist}/>
              </TabPanel>
              <TabPanel pt="40px" px="0px">
                <AuthoredEditedBooks masterlist={masterlist}/>
              </TabPanel>
            </TabPanels>
          </Box>
        </Stack>
      </Tabs>
    </>
  )
}
