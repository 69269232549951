import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade, Navigation } from "swiper";
import { useEffect, useState } from "react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";

//Language file
import { common } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export const FullScreenImage = ({
  isOpen,
  onClose,
  imageData,
  clickedImageIndex,
  setClickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ImageFullScreen, setImageFullScreen] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const fullImageview = (image, flag, index) => {
    if (image) {
      setImageFullScreen(image);
      setFullscreen(flag);
      setClickedImageIndex(index);
    } else {
      setImageFullScreen(image);
      setFullscreen(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(windowWidth);
  return (
    <>
      {fullscreen ? (
        <Modal
          size={"full"}
          isCentered
          isOpen={fullscreen}
          onClick={() => fullImageview(null, false)}
          height="calc(100vh)"
          Id="modalfullscreen"
        >
          <ModalOverlay />
          <ModalContent zIndex={"999"}>
            <ModalBody>
              <Box bg="white">
                <Stack>
                  <HStack my="12" justifyContent={"center"}>
                    <Image
                      src={ImageFullScreen}
                      height={"auto"}
                    ></Image>
                  </HStack>
                  <HStack justifyContent="flex-end">
                    <Text
                      fontSize={"xl"}
                      bg={"#525252"}
                      color={"white"}
                      fontWeight={"400"}
                      padding="2"
                      borderRadius={"35px"}
                      width="max-content"
                      marginTop="-240px"
                      marginRight={"19%"}
                      cursor="pointer"
                      position={"absolute"}
                      onClick={(e) => fullImageview(null, true)}
                    >
                      <CgArrowsExpandRight />
                    </Text>
                  </HStack>
                </Stack>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Container maxW={"auto"} px="0px">
          <Modal
            size={"full"}
            isCentered
            isOpen={isOpen}
            onClick={onClose}
            Id="modalfullscreen"
            motionPreset="none"
          >
            <ModalOverlay />
            <ModalContent zIndex={"999"}>
              <ModalBody margin={{ base: "0", md: "16" }}>
                <Box
                  bg={"white"}
                  justifyContent={"space-between"}
                  position={"relative"}
                >
                  <Stack>
                    <Swiper
                      effect={{ sm: "fade", md: "creative" }}
                      id="allsliderskk1"
                      grabCursor={true}
                      navigation={true}
                      initialSlide={clickedImageIndex}
                      modules={[EffectCreative, EffectFade, Navigation]}
                    >
                      {data &&
                        data.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <Stack
                                maxW={"1180px"}
                                marginTop={{ base: "50px", md: "0" }}
                                flexDirection={{ base: "column", md: "row" }}
                                className="row"
                                alignItems={"flex-start"}
                                gap={"0"}
                              >
                                <HStack
                                  className="col-sm-12 col-md-6"
                                  justifyContent={"center"}
                                  border={"0.01rem solid #d7d7d7"}
                                  borderRadius={"5px"}
                                  background={"#eeeeee"}
                                >
                                  <Stack m="8">
                                    <Box
                                      display={"block"}
                                      justifyContent={"center"}
                                      width={"100%"}
                                      height={{ base: "auto", md: "auto" }}
                                    >
                                      <Image
                                        src={
                                          item?.imgfullPath
                                          // imageData !== null
                                          //   ? "https://d3fsixjuo450s3.cloudfront.net/" + imageData
                                          //   : ""
                                        }
                                        key={index}
                                        width={"100%"}
                                        height={"inherit"}
                                        objectFit={"contain"}
                                        alt=""
                                        onClick={(e) => {
                                          fullImageview(
                                            item?.imgfullPath,
                                            true,
                                            index
                                          );
                                        }}
                                        cursor={"pointer"}
                                      />
                                    </Box>
                                  </Stack>
                                </HStack>
                                <HStack
                                  alignItems={"flex-start"}
                                  className="col-sm-12 col-md-6"
                                  flexDirection={"column"}
                                >
                                  <Box
                                    paddingBottom={"10px"}
                                    width={"100%"}
                                    borderBottom={"1px"}
                                    borderColor={"#e8e8e8"}
                                  >
                                    <Text
                                      fontSize={"16px"}
                                      fontWeight={"700"}
                                    >
                                      {common.CONTENT_AWAITED}
                                    </Text>
                                  </Box>
                                  <Box
                                    py="2"
                                    width={"100%"}
                                    borderBottom={"1px"}
                                    borderColor={"#e8e8e8"}
                                    marginInlineStart={"0px!important"}
                                  >
                                    <Text
                                      fontSize={"20px"}
                                      fontWeight={"700"}
                                      color="black"
                                      pb="1"
                                    >
                                      {item.author}
                                    </Text>
                                    <Text
                                      fontSize={"18px"}
                                      fontWeight={"500"}
                                      color="black"
                                    >
                                      {item.desc}
                                    </Text>
                                  </Box>

                                  <SimpleGrid
                                    columns={[0, null, 1]}
                                    marginInlineStart={"0px!important"}
                                    spacing="2px"
                                    py="2"
                                  >
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      color="#000000"
                                    >
                                      {"Medium, Date"}
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"400"}
                                      color="black"
                                    >
                                      {item.date_of_artwork}
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"600"}
                                      color="#000000"
                                    >
                                      {index === 0
                                        ? "Size  "
                                        : "Date of Artwork Size  "}
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"400"}
                                      color="black"
                                    >
                                      {item.date_of_artwork_size}
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"600"}
                                      color="#000000"
                                    >
                                      {"Pricing"}
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"400"}
                                      color="black"
                                    >
                                      {common.CONTENT_AWAITED}
                                    </Text>
                                  </SimpleGrid>
                                  <Text
                                    pt="4"
                                    marginInlineStart={"0px!important"}
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="#035DA1"
                                    cursor={"pointer"}
                                    onClick={() => {
                                      onClose();
                                      setReadMoreFlag(
                                        true,
                                        isOpen,
                                        index,
                                        bannerFlag,
                                        title
                                      );
                                    }}
                                  >
                                    {common.READ_MORE}
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Image
                                    className="art-he-closebtn"
                                    src={closeIcon}
                                    onClick={() => {
                                      onClose();
                                    }}
                                    cursor={"pointer"}
                                  ></Image>
                                </HStack>
                              </Stack>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </Stack>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Container>
      )}
    </>
  );
};
