import { 
  Accordion, 
  AccordionButton, 
  AccordionIcon, 
  AccordionItem, 
  AccordionPanel, 
  Box, 
  Button, 
  Container, 
  HStack, 
  Image, 
  Input, 
  InputGroup, 
  InputRightElement, 
  SimpleGrid, 
  Stack, 
  Text 
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import * as getMonumentsData from "../../../../services/Location";

//Component files
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import Small from "../../../../components/layouts/Typography/Small";
import { FilterButton } from "../../../../CommonComponents/FilterButton";

//Langugae file
import { button, common, explore, filterAttribute } from "../../../../constants/constants";

function GridView({aliasAPI }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [gridViewData, setGridViewData] = useState([]);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate=useNavigate();
  useEffect(() => {
    const getData = async () => {
      const result = await getMonumentsData.getMonuments("tableName", aliasAPI);
      if (result) {
        setGridViewData(result[0]);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [aliasAPI]);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleImage=(it)=>{
    navigate(`/${masterlistType}/${aliasAPI}/body-of-work/${it}`)
  }
  return (
    <Stack flexDirection={"row-reverse"} paddingTop={"4"}>
      <FilterButton marginRight={"-25px"} onClick={handleFilterClick} />
      <Container maxW="auto" py="4" px="8">
        <Stack py="4" flexDirection={"row"} gap={4} justifyContent="center">
          <HStack>
            <SimpleGrid
              columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
              spacing="40px"
            >
              {gridViewData?.value?.length > 0 && 
               gridViewData.value.map((it, index) => {
                  return (
                    <Box
                      height="auto"
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"start"}
                      alignContent={"center"}
                    >
                      {it.link ? (
                        <>
                          <Box
                            justifyItems="center"
                            display="flex"
                            height={"auto"}
                            bg="white"
                            width={"100%"}
                            justifyContent="space-around"
                            cursor={"pointer"}
                            onClick={() => handleImage(it.alias)}
                          >
                              <Image
                                src={it.image}
                                alt=""
                                width={"100%"}
                                transition="1s ease"
                                height="100%"
                                objectPosition={"center"}
                              />
                          </Box>
                          <Link to={it.link} cursor={"pointer"}>
                            <Text
                              textAlign="left"
                              color="#0066B3"
                              py="4"
                              fontSize={"16px"}
                              fontWeight={"400"}
                            >
                              {it.monument_name}{it.year}
                            </Text>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Box
                            justifyItems="center"
                            display="flex"
                            height={"auto"}
                            width={"100%"}
                            bg="white"
                            className="card1"
                            justifyContent="space-around"
                            cursor={"pointer"}
                          >
                              <Image
                                src={it.image}
                                alt=""
                                transition="1s ease"
                                height="100%"
                                objectPosition={"center"}
                              />
                          </Box>
                          <Text
                            textAlign="left"
                            color="#0066B3"
                            py="4"
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                           {it.monument_name},{it.year}
                          </Text>
                        </>
                      )}
                    </Box>
                  );
                })}
            </SimpleGrid>
          </HStack>
          <HStack className="mt-0" alignItems={"flex-start"}>
            {showFilterBox && (
              <Box marginRight={"-59px!important"}  className="box-filterArtHeritage" marginTop={"4"}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <HStack>
                    <SmallBold as="u" color={"black"}>
                    {button.CLEAR_ALL}
                    </SmallBold>
                  </HStack>
                  <HStack className="mt-0">
                    <RiCloseCircleFill
                      size={"22px"}
                      cursor="pointer"
                      onClick={(e) => setShowFilterBox(false)}
                    />
                  </HStack>
                </Stack>
                <HStack alignItems={"left"} flexDirection={"column"}>
                <InputGroup className="ml-0" py="4">
                                <Input
                                  placeholder="Search"
                                  height={"32px"}
                                  py={"4px"}
                                  _placeholder={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                />
                                <InputRightElement mt={"10px"}>
                                  <BiSearch
                                    color="green.500"
                                    fontSize={"16px"}
                                  />
                                </InputRightElement>
                              </InputGroup>
                </HStack>
                <Accordion allowToggle allowMultiple>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                         {filterAttribute.ARCHITECTURAL_STYLE}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                           {filterAttribute.ARCHITECTURAL_MATERIAL}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {filterAttribute.DATE}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {filterAttribute.COMISSIONED_BY}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <HStack marginTop={"10px"}>
                  <Button
                    color={"#ffffff"}
                    onClick={() => {
                    }}
                    width={"199px"}
                    height={"36px"}
                    bg={"#000000"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    variant={"unstyled"}
                  >
                    {explore.APPLY}
                  </Button>
                </HStack>
              </Box>
            )}
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
}

export default GridView;