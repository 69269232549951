//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Dummy1 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/1.png`
const Dummy2 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/2.png`
const Dummy3 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/3.png`
const Dummy4 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/4.png`
const Dummy5 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/5.png`
const Dummy6 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/6.png`
const Dummy7 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/7.png`
const Dummy8 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/8.png`
const Dummy9 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/9.png`
const Dummy10 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/10.png`
const Dummy11 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/11.png`
const Dummy12 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/12.png`
const Dummy13 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/13.png`
const Dummy14 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/14.png`
const Dummy15 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/15.png`
const Dummy16 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/16.png`
const Dummy17 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/17.png`
const Dummy18 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/18.png`
const Dummy19 = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/19.png`
const KGSubra = `${IMAGE_BASE_PATH}/ui/Explore/Explore/KGsubramanyan.png`
const pictorial = `${IMAGE_BASE_PATH}/ui/Explore/Explore/pictorial.png`
const ShammiKapoor = `${IMAGE_BASE_PATH}/ui/Explore/MasterList/shammiKapoor.jpg`

export const masterlistsData = [
  {
    id: 1,
    imgPath: Dummy1,
    title: "Sayed Haider Raza",
    published: true,
    link: "/person-artist/sayed-haider-raza",
  },
  {
    id: 2,
    imgPath: Dummy2,
    title: "Satyajit Ray",
    published: true,
    link: "/person-poly/satyajit-ray",
  },
  {
    id: 3,
    imgPath: Dummy8,
    title: "Shatranj Ke Khiladi",
    published: true,
    link: "/film/shatranj-ke-khilari-1977",
  },
  {
    id: 4,
    imgPath: Dummy17,
    title: "Lucknow",
    published: true,
    link: "/location/lucknow",
  },
  {
    id: 5,
    imgPath: Dummy16,
    title: "Art Heritage Annual",
    published: true,
    link: "/object-book-jou/art-heritage-annual",
  },
  {
    id: 6,
    imgPath: Dummy6,
    title: "Humayun’s Tomb",
    published: false,
    link: "",
  },
  {
    id: 7,
    imgPath: Dummy7,
    title: "New Delhi, India",
    published: false,
    link: "",
  },
  {
    id: 8,
    imgPath: Dummy3,
    title: "Mani Kaul",
    published: false,
    link: "",
  },
  {
    id: 9,
    imgPath: Dummy9,
    title: "Pather Panchali",
    published: false,
    link: "",
  },
  {
    id: 10,
    imgPath: Dummy10,
    title: "M.F. Husain",
    published: false,
    link: "",
  },
  {
    id: 11,
    imgPath: Dummy11,
    title: "Retromobile 2014",
    published: false,
    link: "",
  },
  {
    id: 12,
    imgPath: Dummy12,
    title: "Paris, France",
    published: false,
    link: "",
  },
  {
    id: 13,
    imgPath: Dummy13,
    title: "Samurai Helmet",
    published: false,
    link: "",
  },
  {
    id: 14,
    imgPath: Dummy14,
    title: "Rabindranath Tagore",
    published: false,
    link: "",
  },
  {
    id: 15,
    imgPath: Dummy15,
    title: "Okakura Kakuzō",
    published: false,
    link: "",
  },
  {
    id: 16,
    imgPath: Dummy4,
    title: "F.N.Souza",
    published: false,
    link: "",
  },
  {
    id: 17,
    imgPath: Dummy5,
    title: "Amithabh Bachchan",
    published: false,
    link: "/person-actor/amitabh-bachchan",
  },
  {
    id: 18,
    imgPath: Dummy19,
    title: "C.DT | CINE.SSB",
    published: false,
    link: "/object-book-ssb/song-synopsis-booklet",
  },
  {
    id: 19,
    imgPath: Dummy18,
    title: "C.DT | Book.exc",
    published: false,
    link: "",
  },
  {
    id: 20,
    imgPath: KGSubra,
    title: "K.G. Subramanyan",
    link: "/person-artist/k.g-Subramanyan",
    published: true,
  },
  {
    id: 21,
    imgPath: ShammiKapoor,
    title: "Shammi Kapoor",
    link: "/person-actor/shammi-kapoor",
    published: true,
  },
  {
    id: 21,
    imgPath: pictorial,
    title: "Pictorial Space",
    link: "/event-exhibition/pictorial-space",
    published: true,
  }
];
