import { 
  Box, 
  Tab, 
  TabList, 
  Tabs, 
  Text 
} from '@chakra-ui/react';

//Core file
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

//Component file
import Layout from '../../../../components/sections/SidebarLayout'
import ResearchCentre from './ResearchCenter'

//Language file
import { research_category } from '../../../../constants/constants';

export default function RCResearchCentreDetail() {
    const { alias } = useParams();
    const navigate = useNavigate();
    const url = window.location.pathname.lastIndexOf("/");
    const mainUrl = window.location.pathname.slice(0, url);
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

  return (
    <Layout>
      <Box
          position={"fixed"}
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "4" }}
          zIndex={999}
          bg="white"
          width={"1344px"}
        >
          <Tabs variant={"unstyled"} display={"flex"} align="end" pos={"none"} alignItems={"center"} justifyContent={"space-between"} defaultIndex={-1}>
            <Box>
            <Text
             borderBottom={"4px"}
             borderBottomColor={"black"}
             fontSize={{ base: "25px", md: "30px" }}
             fontWeight={700}
            >
              {research_category.RESEARCH_CENTRE_TAB_TITLE}
            </Text>
            </Box>
            <TabList gap={"25px"}>
              <Tab
                onClick={() => navigate(`${mainUrl}/Introduction`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INTRODUCTION_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.CHRONOLOGY_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.MASTERLISTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.POSTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INSIGHTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToResearchCentre`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.RESEARCH_CENTRE_TAB_TITLE}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <ResearchCentre rcAlias={alias} main={false} />
    </Layout>
  )
}
