export const personalityBibliographySchema = [
  { key: "id", display: "Id", seq: 1 },
  { key: "title", display: "Title", seq: 2 },
  { key: "alias", display: "Alias", seq: 3 },
  { key: "web_serial_number", display: "Web serial number", seq: 4 },
  { key: "reference_code", display: "Reference code", seq: 5 },
  { key: "web_display", display: "Web display", seq: 6 },
  {
    key: "date_of_library_publication",
    display: "Date of library publication",
    seq: 7,
  },
  { key: "place_of_publication", display: "Place of publication", seq: 8 },
  { key: "histographical_note", display: "Histographical note", seq: 9 },
  { key: "main_historical_note", display: "Main historical note", seq: 10 },
  { key: "binding_status", display: "Binding status", seq: 11 },
  { key: "accession_number", display: "Accession number", seq: 12 },
  { key: "cdt", display: "CDT", seq: 13 },
  { key: "country_of_publication", display: "Country of publication", seq: 14 },
  {
    key: "date_of_published_edition_in_library",
    display: "Date of published edition in library",
    seq: 15,
  },
  { key: "dates_of_first_edition", display: "Dates of first edition", seq: 16 },
  { key: "isbn_issn_number", display: "Isbn issn number", seq: 17 },
  {
    key: "language_of_publication",
    display: "Language of publication",
    seq: 18,
  },
  {
    key: "number_of_pages_in_publication_text",
    display: "Number of pages in publication text",
    seq: 19,
  },
  {
    key: "personality_film_visually_featured_on_cover",
    display: "Personality film visually featured on cover",
    seq: 20,
  },
  { key: "publisher", display: "Publisher", seq: 21 },
  { key: "serial_number", display: "Serial number", seq: 22 },
  {
    key: "sortable_year_date_of_publication",
    display: "Sortable year date of publication",
    seq: 23,
  },
  {
    key: "sub_title_of_document_book",
    display: "Sub title of document book",
    seq: 24,
  },
  { key: "title_of_document_book", display: "Title of document book", seq: 25 },
  { key: "book_title_of_document", display: "Book title of document", seq: 26 },
  {
    key: "book_sub_title_of_document",
    display: "Book sub title of document",
    seq: 27,
  },
  { key: "volume_issue_number", display: "Volume issue number", seq: 28 },
  { key: "masterlist", display: "Masterlist", seq: 29 },
  { key: "image", display: "Image", seq: 30 },
  { key: "document_type", display: "Document type", seq: 31 },
  {
    key: "title_and_type_of_creator",
    display: "Title and type of creator",
    seq: 32,
  },
  {
    key: "number_of_pages_in_publication",
    display: "Number of pages in publication",
    seq: 33,
  },
  {
    key: "researchCategories",
    display: "Research categories",
    processor: "aliasArray",
    seq: 34,
  },
  {
    key: "creator",
    display: "Creator",
    processor: "creatorAliasArray",
    seq: 35,
  },
];
