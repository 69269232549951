import React from "react";
import EducationDrawerSelfIntro from "./EducationDrawerSelfIntro";
import DrawerEducationDetails from "./DrawerEducationDetails";
import EducationDrawerModalDetails from "./EducationDrawerModalDetails";

export default function EducationDrawerBody({
  intro,
  showEducationImages,
  educationImgData,
  showFullEducationImages,
  educationFullScreenImage,
  closeIcon,
  data,
}) {
  return (
    <>
      <EducationDrawerSelfIntro
        data={data}
        showEducationImages={showEducationImages}
      />
      <DrawerEducationDetails
        educationImgData={educationImgData}
        showEducationImages={showEducationImages}
        showFullEducationImages={showFullEducationImages}
        closeIcon={closeIcon}
      />
      <EducationDrawerModalDetails
        educationImgData={educationImgData}
        showEducationImages={showEducationImages}
        showFullEducationImages={showFullEducationImages}
        educationFullScreenImage={educationFullScreenImage}
      />
    </>
  );
}
