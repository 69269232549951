import { Container, Image, Stack, Text } from "@chakra-ui/react";

//Core file
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

//Language file
import { artheritage, common } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/7.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/8.png`


export const Book = ({ bookReadmore, setBookReadmore }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAuthor, setIsExpandedAuthor] = useState(false);
  const data = [
    { image: Img1 },
    { image: Img2 },
    { image: Img3 },
    { image: Img4 },
    { image: Img5 },
    { image: Img6 },
    { image: Img7 },
    { image: Img8 },
  ];
  const [selectedImg, setSelectedImg] = useState({
    image: data[0].image,
    desc: "",
    index: 0,
    slideImage: data[0].image,
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  console.log(toggleExpand);
  const toggleExpandAuthor = () => {
    setIsExpandedAuthor(!isExpandedAuthor);
  };
  console.log(toggleExpandAuthor);

  let authorText =
    "Davierwalla was a thoughtful, introspective person. He was not cold and withdrawn, but his deep, reflective mind would not allow itself to be seduced by the trivial or the inconsequential.";
  const swiperRef = useRef(null);

  const essayText =
    "Clarity of thought, firmness of purpose, and an impeccable balance between reason and passion are characteristic of his work. These same qualities are to be found in his occasional talks and writings. Though a man of few words, when it came to expressing his ideas, Davierwalla was articulate as few artists of our times. We believe that the statements published here for the first time will give readers a deeper insight into his art and thought... ";
  const handleNextButtonClick = () => {
    const activeIndex = selectedImg.index + 1;
    const nextSlide = data[activeIndex];
    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };
  
  const handlePreviousButtonClick = () => {
    const activeIndex = selectedImg.index - 1;
    const nextSlide = data[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };
  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        py={"80px"}
        width="1140px"
        gap={30}
      >
        <Stack
          alignItems={"center"}
          height={"100%"}
          className="col-6"
          position={"relative"}
          id="allsliderskkBook"
        >
          <Image src={selectedImg.image} height={"450px"}></Image>
          {selectedImg.index === 7 && (
            <Text
              position={"absolute"}
              top={"35%"}
              left={"38%"}
              fontSize={"18px"}
            >
              {common.IMAGE_AWAITED}
            </Text>
          )}
          <Swiper
            ref={swiperRef}
            grabCursor={true}
            navigation={true}
            modules={[Navigation, Pagination]}
            style={{ marginTop: "30px" }}
            slidesPerView={4}
            slidesPerGroup={1}

            onSlideNextTransitionEnd={() => {
              handleNextButtonClick();
            }}
            onSlidePrevTransitionEnd={() => {
              handlePreviousButtonClick();
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide>
                  <Stack position={"relative"} className="mt-0">
                    <Image
                      height={"90px"}
                      width={"auto"}
                      src={item.image}
                      onClick={() => {
                        swiperRef.current.swiper.slideTo(
                          swiperRef.current.swiper.activeIndex
                        );
                        setSelectedImg({
                          image: item.image,
                          index: index,
                          slideImage: item.image,
                        });
                      }}
                    />
                    {index === 7 && (
                      <Text
                        position={"absolute"}
                        top={"35%"}
                        left={"11%"}
                        fontSize={"14px"}
                      >
                        {common.IMAGE_AWAITED}
                      </Text>
                    )}
                    {swiperRef !== null && index !== selectedImg.index && (
                      <Stack
                        className="mt-0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "15px",
                          width: "80px",
                          height: "100px",
                          background: "rgba(0, 0, 0, 0.8)",
                        }}
                        onClick={() => {
                          swiperRef.current.swiper.slideTo(
                            swiperRef.current.swiper.activeIndex
                          );
                          setSelectedImg({
                            image: item.image,
                            desc: item.desc,
                            index: index,
                          });
                        }}
                      />
                    )}
                  </Stack>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Stack>
        <Stack>
          <Text
            fontSize={"20px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            color="#333333"
          >
            A Sculptor's Vocation - A.M. Davierwalla
          </Text>
          <Container px="0">
            <Text
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"10px"}
              marginTop={"32px"}
            >
              <li>{artheritage.ABOUT_THE_ESSAY}</li>
            </Text>
            <Text
              pr="8"
              fontSize={"16px"}
              fontWeight={"400"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {authorText}
            </Text>
            <Text
              pr="8"
              fontSize={"16px"}
              fontWeight={"400"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {essayText}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                onClick={() => setBookReadmore(true)}
              >
                {common.READ_MORE}
              </span>
            </Text>
            <Text
              py="8"
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"10px"}
            >
              <li>{common.ABOUT_THE_AUTHOR}</li>
            </Text>
            <Text fontSize={"16px"} fontWeight={"700"} lineHeight={"22px"}>
              {common.CONTENTS}-2
            </Text>
            <Text
              fontSize={"16px"}
              fontWeight={"700"}
              // lineHeight={"22px"}
              cursor={"pointer"}
              color={"#0066B3"}
              marginTop={"20px"}
              textAlign={"justify"}
              onClick={() => setBookReadmore(true)}
            >
              {common.READ_MORE}
            </Text>
          </Container>
          <Container py={5} px="0"></Container>
        </Stack>
      </Stack>
    </>
  );
};
