import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { TbFoldersOff } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import { CopyPasteService } from "../../../../services/CopyPaste";
import * as getBibliographyData from "../../../../services/Bibliography";

//Component files
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import Small from "../../../../components/layouts/Typography/Small";
import ScrollToTop from "../../../../components/sections/ScrollToTop";
import ResearchImageGridList from "../../../Cine_MasterList/ResearchCentre/CommonComponents/ResearchImageGridList";
import ContentModal from "../../../Film/ResearchCentre/Library/ContentModal";
import SliderModal from "../../../Film/ResearchCentre/Library/SliderModal";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import { isTransformationNotAllowed } from "../../../../util/isTranformationAllowed";
import ResearchCentreDrawer from "../../../Film/ResearchCentre/Library/Components/ResearchCentreDrawer";

//Language files
import { common, explore, filterAttribute } from "../../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/sl1.jpg`
const Img2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/sl2.jpg`
const Img3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/sl3.jpg`


function GridView({
  handleBookClick,
  contentClicked,
  scanClicked,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  setleftFlag,
  tableName,
  masterlist,
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const sliderImages = [Img1, Img2, Img3];
  const [bibliographyObject, setBibliographyObject] = React.useState();
  const [filterOptions, setFilterOptions] = useState({
    documentType: [],
    year: [],
    creatorType: [],
    searches_v: [],
  });
  const [apply, setApply] = useState(false);
  const [filterCount, setfilterCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [FilterData, setFilterData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [indexValue, setIndexValue] = useState();
  const [swiper, setSwiper] = useState(null);
  const [drawerBook, setDrawerBook] = useState("");
  const navigate=useNavigate();
  const {bookAlias}=useParams();

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleDrawer = (item) => {
    setDrawerBook(item);
    setBibliographyObject(item);
    setOpenDrawer(true);
    setleftFlag(true);
    navigate(`${item?.alias}`)

  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type

    let selectedArray = [];
    switch (value) {
      case "docType":
        selectedArray = filterOptions.documentType;
        break;
      case "year":
        selectedArray = filterOptions.year;
        break;
      case "creatorType":
        selectedArray = filterOptions.creatorType;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "docType":
        setFilterOptions({
          ...filterOptions,
          documentType: selectedArray,
        });
        break;
      case "year":
        setFilterOptions({
          ...filterOptions,
          year: selectedArray,
        });
        break;
      case "creatorType":
        setFilterOptions({
          ...filterOptions,
          creatorType: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getBibliographyData.getBibliographyFilterOptions(
        tableName,
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, tableName]);

  // Fetch data function
  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getBibliographyData.bibliographyByMasterlist(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getBibliographyData.bibliographyByMasterlist(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }

    setLoading(false);
    const data = result || []; // Ensure data is not null or undefined
    setFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }
  const citationFactor = (data) => {
    return data?.map((item) => {
      const imagePath =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value[0] || null;
      const sliderImage =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value.length >
          1 &&
        item?.find((nestedItem) => nestedItem?.key === "image")?.value.slice(1);
      const creator =
        item?.find((nestedItem) => nestedItem?.key === "creator")?.value || [];
        const lastName = creator?.map(nestedItem => nestedItem?.last_name)?.join(", ") || null;
        const firstName = creator?.map(nestedItem => nestedItem?.first_name)?.join(", ") || null;
        const author = lastName && firstName ? `${lastName}, ${firstName}` : "";
        
      const fullname = lastName && firstName ? `${firstName} ${lastName}` : "";

      const bookKey =
        item?.find((nestedItem) => nestedItem?.key === "book_title_of_document")
          ?.value || null;
      const subbookKey =
        item?.find(
          (nestedItem) => nestedItem?.key === "book_sub_title_of_document"
        )?.value || null;
      const book = bookKey + " - " + subbookKey;
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        null;
      const publicationYear =
        item?.find(
          (nestedItem) => nestedItem?.key === "sortable_year_date_of_publication"
        )?.value || null;
      const publisher =
        item?.find((nestedItem) => nestedItem?.key === "publisher")?.value ||
        null;
      const documentType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")?.value ||
        null;
      const nameText =
        capitalizeWords(
          item
            .find((nestedItem) => nestedItem?.key === "masterlist")
            ?.value.replace(/-/g, " ")
        ) || null;
        const typeCreator =
        item?.find((nestedItem) => nestedItem?.key === "title_and_type_of_creator")?.value ||
        null;
      const header = nameText + " & " + book;

      const desc =  `${author && `${author} |`} ${publicationYear}. Book.${documentType}`;
      const alias=item?.find((nestedItem) => nestedItem?.key === "alias")?.value

      return {
        imagePath,
        desc,
        header,
        bookKey,
        typeCreator,
        link,
        author,
        book,
        publicationYear,
        publisher,
        nameText,
        sliderImage,
        fullname,
        alias,
        footer: [
          {
            text: "Contents Page →",
          },
          {
            text: "Index Page →",
          },
          {
            text: "Scanned Pages →",
          },
        ],
      };
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getBibliographyData.bibliographyByMasterlist(
            tableName,
            masterlist
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setLoading(false);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getBibliographyData.bibliographyByMasterlist(
              tableName,
              masterlist
            )) || {};
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  //drawer dynamic
  const handleCloseClick = () => {
    setContentClicked(false);
    setScanClicked(false);
  };
  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };
  const handleClose = () => {
    setBibliographyObject();
    handleCloseClick();
    setDrawerBook("");
    navigate(-1);
  };
  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    showSliderImage(2);
    if (text === "Contents Page →") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages →") {
      setScanClicked(true);
      setContentClicked(false);
    }
  };
  useEffect(()=>{
    if(FilterData){
      const item=FilterData.find((it)=>it.alias===bookAlias)
      setDrawerBook(item)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  },[FilterData,bookAlias])

  return (
    <>
      {!loading ? (
        <>
          <Stack
            flexDirection={"row-reverse"}
            marginTop={"20px"}
          >
            {bookAlias && drawerBook && (
              <ResearchCentreDrawer
                data={drawerBook && drawerBook}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                handleClose={handleClose}
                handleBookClick={handleBookClick}
                handleContentClicked={handleContentClicked}
                scanClicked={scanClicked}
                contentClicked={contentClicked}
                bibliographyObject={bibliographyObject}
                setSwiper={setSwiper}
                indexValue={indexValue}
                size={"sm"}
              />
            )}
            {!showFilterBox && (
              <FilterButton onClick={handleFilterClick} marginRight={"0px"} />
            )}
            <Container maxW="auto" className="songs" px="0" py="8">
              <Stack
                flexDirection={{base:"column",md:"row"}}
                gap={4}
                justifyContent={
                  FilterData.length > 0 ? "center" : "space-between"
                }
              >
                <HStack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  marginRight={!showFilterBox &&  {base:"0px",md:"44px"}}
                >
                  {FilterData.length > 0 ? (
                    <ResearchImageGridList
                      data={FilterData && FilterData}
                      handleDrawer={handleDrawer}
                      border={false}
                    />
                  ) : (
                    <Text
                      py={"40"}
                      fontSize={"45px"}
                      fontWeight="700"
                      textAlign="center"
                      display={"flex"}
                      alignItems={"center"}
                      marginLeft={"auto"}
                    >
                      <TbFoldersOff style={{ marginRight: "20px" }} />
                      {common.NO_DATA}
                    </Text>
                  )}
                </HStack>
                <HStack
                  marginRight={"0px!important"}
                  className="mt-0"
                  alignItems={"flex-start"}
                >
                  {showFilterBox && (
                    <Box className="box-filterArtHeritage">
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <HStack>
                          <SmallBold
                            as="u"
                            color={"black"}
                            cursor="pointer"
                            onClick={() => {
                              setFilterOptions({
                                documentType: [],
                                year: [],
                                creatorType: [],
                              });
                              setSearchTerm("");
                            }}
                          >
                            {explore.RESET}
                            <span style={{ color: "#004F79" }}>
                              {" "}
                              {filterCount > 0 && ` (${filterCount})`}
                            </span>
                          </SmallBold>
                        </HStack>
                        <HStack className="mt-0">
                          <RiCloseCircleFill
                            size={"22px"}
                            cursor="pointer"
                            onClick={(e) => setShowFilterBox(false)}
                          />
                        </HStack>
                      </Stack>
                      <HStack
                        alignItems={"left"}
                        flexDirection={"column"}
                        marginTop={"25px"}
                      >
                        <InputGroup className="ml-0" pb="4">
                          <Input
                            placeholder="Search"
                            height={"32px"}
                            pb={"4px"}
                            _placeholder={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                            onChange={handleSearch}
                            value={searchTerm}
                          />
                          <InputRightElement pb={"6px"}>
                            <BiSearch color="green.500" fontSize={"16px"} />
                          </InputRightElement>
                        </InputGroup>
                      </HStack>
                      <Accordion allowToggle allowMultiple>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small>
                              {filteroptionData.creatorTypeFilter.map(
                                (item) => {
                                  return (
                                    <Flex
                                      borderBottom={"1px"}
                                      borderBottomColor={"#f1f1f1"}
                                    >
                                      <Checkbox
                                        borderColor={"black"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                        isChecked={filterOptions.creatorType.includes(
                                          item
                                        )}
                                        onChange={() => {
                                          handleCheckboxChange(
                                            "creatorType",
                                            item
                                          );
                                        }}
                                      />
                                      <Box p="1">
                                        <Text
                                          fontSize={"14px"}
                                        >{`${item}`}</Text>
                                      </Box>
                                    </Flex>
                                  );
                                }
                              )}
                            </Small>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.DATE}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small>
                              {filteroptionData.yearFilter.map((item) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.year.includes(
                                        item
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("year", item);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{`${item}`}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}
                            </Small>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.DOCUMENT_TYPE}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <Box maxH="130px" overflowY="auto">
                            <AccordionPanel pb={4} className="px-1">
                              {filteroptionData.documentTypeFilter.map(
                                (item) => {
                                  return (
                                    <Flex
                                      borderBottom={"1px"}
                                      borderBottomColor={"#f1f1f1"}
                                    >
                                      <Checkbox
                                        borderColor={"black"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                        isChecked={filterOptions.documentType.includes(
                                          item
                                        )}
                                        onChange={() => {
                                          handleCheckboxChange("docType", item);
                                        }}
                                      />
                                      <Box p="1">
                                        <Text fontSize={"14px"}>
                                          {`Book.${item}`}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  );
                                }
                              )}
                            </AccordionPanel>
                          </Box>
                        </AccordionItem>
                      </Accordion>
                      <HStack marginTop={"10px"}>
                        <Button
                          color={"#ffffff"}
                          onClick={() => {
                            setApply((prev) => !prev);
                            setShowFilterBox(false);
                          }}
                          width={"199px"}
                          height={"36px"}
                          bg={"#000000"}
                          fontSize={"16px"}
                          fontWeight={"400"}
                          variant={"unstyled"}
                        >
                          {explore.APPLY}
                        </Button>
                      </HStack>
                    </Box>
                  )}
                </HStack>
              </Stack>
              {contentClicked && (
                <ContentModal
                  contentClicked={contentClicked}
                  setContentClicked={setContentClicked}
                />
              )}
              {scanClicked && (
                <SliderModal
                  scanClicked={scanClicked}
                  setScanClicked={setScanClicked}
                  sliderModalImg={sliderImages}
                  // width="746px"
                />
              )}
              <ScrollToTop />
            </Container>
          </Stack>
        </>
      ) : (
        <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
          {common.LOADING}
        </Text>
      )}
    </>
  );
}
export default GridView;