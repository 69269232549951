import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdHomeFilled } from "react-icons/md";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { useTranslation } from "react-i18next";

//Component file
import ExplorePosts from "../pages/Explore/ExplorePosts";
import MobileNav from "../components/Topheader";
import Masterlists from "./Explore/Masterlist";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import GlobalPost from "./Explore/GlobalPost";
import GlobalInsights from "./Explore/GlobalInsights";
import { WebpageComingSoon } from "../CommonComponents/WebpageComingSoon";

//Language file
import { explore } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`
const sub0 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe0.png`
const sub1 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe1.png`

export default function Explore() {
  const navigate = useNavigate();
  let { tabIndex } = useParams();
  const { t } = useTranslation("");

  useEffect(() => {
    if (tabIndex) {
      navigate("/explore", { replace: true });
    }
  }, [tabIndex, navigate]);

  const { isOpen, onClose } = useDisclosure();

  const [dummyData, setDummyData] = useState(false);
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  useEffect(() => {
    if (dummyDataEnabled.toLowerCase() === "yes") {
      setDummyData(true);
    } else {
      setDummyData(false);
    }
  }, [dummyDataEnabled]);
  console.log(dummyData, "dummyData");
  return (
    <SidebarWithHeader>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        bgOpacity="100%"
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack alignItems={"center"}>
              <HStack pt="16" pb="8">
                <Image src={sub0}></Image>
              </HStack>
              <HStack pb="40px">
                <Text fontSize={"35px"} fontWeight={"700"} textAlign="center">
                  Subscribe to Read More
                </Text>
              </HStack>
              <HStack pb="8">
                <Box bg="#FFC015" width={"421px"} p="3">
                  <Text fontSize={"25px"} fontWeight={"700"} textAlign="center">
                    Subscribe
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Image onClick={onClose} src={sub1}></Image>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Stack width={"100%"}>
        <Container maxW={"auto"} px={"0px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt="9"
            variant="unstyled"
            defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
          >
            <Stack
              px="0px"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
              width={"100%"}
            >
              <HStack
                width={"100%"}
                justifyContent={{ base: "center", md: "space-between" }}
              >
                <TabList
                  flexDirection={{ base: "column", md: "row" }}
                  pb="4"
                  mx="35px"
                  gap={"0px"}
                  className="explorepage-tablist"
                  alignItems={"center"}
                >
                  <Tab
                    p="0"
                    textStyle={"tertiary"}
                    fontSize={{ base: "35px", md: "50px" }}
                    fontWeight={"700"}
                    fontStyle="normal"
                    width={"fit-content"}
                    lineHeight={{ base: "55px", md: "67px" }}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                    }}
                    className="textWrap"
                    zIndex={1}
                  >
                    {t(explore.EXPLORE_TAB_TITLE)}
                  </Tab>
                  <Tab
                    p="0"
                    ml={{ base: "0px", md: "350px" }}
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    zIndex={1}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                    }}
                  >
                    <Text
                      mr="5px"
                    >
                     {t(explore.POSTS)}                      
                    </Text>
                  </Tab>
                  <Text display={{ base: "none", md: "block" }}>{" | "}</Text>
                  <Tab
                    p="0"
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    zIndex={1}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                    }}
                  >
                    <Text
                      mx="5px"
                    >
                      {t(explore.INSIGHTS)}
                    </Text>
                  </Tab>
                  <Text display={{ base: "none", md: "block" }}>{" | "}</Text>
                  <Tab
                    p="0"
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={"16px"}

                    fontWeight={"600"}
                    mr={{ base: "0px", md: "350px" }}
                    zIndex={1}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                    }}
                  >
                    <Text
                      mx="5px"
                    >
                      {t(explore.MASTERLISTS_TAB_TITLE)}
                    </Text>
                  </Tab>
                  <Tab
                    p="0"
                    color={"#333333"}
                    textStyle={"primary"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    width={"fit-content"}
                    _selected={{
                      borderBottom: "4px",
                      borderColor: "#333333",
                    }}
                    zIndex={1}
                  >
                    <Text
                      width={"max-content"}
                    >
                      {t(explore.GARDEN_OF_DELIGHTS)}{" "}
                    </Text>
                  </Tab>
                </TabList>
              </HStack>
            </Stack>
            <TabPanels>
              <TabPanel px={"0"} p="0px" margin={{ base: "24px", md: "35" }}>
                <ExplorePosts />
              </TabPanel>
              <TabPanel px={"0"} pt="0" margin={{ base: "24px", md: "35" }}>
                <GlobalPost />
              </TabPanel>
              <TabPanel px={"0"} py="0" width="100%">
                <GlobalInsights />
              </TabPanel>
              <TabPanel>
                <Masterlists />
              </TabPanel>
              <TabPanel px={"0"} pt="0" mx="35px">
                <Box py="20">
                 <WebpageComingSoon />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [setPage] = useState("banner");
  return (
    <Container maxW="1440px" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}

        <MobileNav onOpen={onOpen} setPage={setPage} />

        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
