// Core Files
import { Box, Image, Stack } from "@chakra-ui/react";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/3.png`
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function ContentModal({ contentClicked, setContentClicked ,width}) {
  return (
    <Box position={"relative"} left={-8}>
      <Box
        position={"fixed"}
        left={"auto"}
        right={{base:"0",md:"auto"}}
        top={"64px"}
        zIndex={99999}
        background={"white"}
        width={width ? width :{base:"100%",md:"795px"}}
        height={{base:"100%",md:"780px"}}
      >
        <Image
          src={closeIcon}
          paddingLeft="5px"
          position="absolute"
          top={"12px"}
          right="15px"
          cursor={"pointer"}
          onClick={() => {
            setContentClicked(false);
          }}
        />
        <Stack
          justifyContent={"space-evenly"}
          width={"100%"}
          alignItems={"flex-start"}
          // marginTop={"64px"}
          height={{base:"auto",md:"737px"}}
          flexDirection={"row"}
        >
          <Image src={Img3} className="demoContentImage" />
        </Stack>
      </Box>
    </Box>
  );
}

export default ContentModal;
