import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ChakraProvider } from "@chakra-ui/react";
import NewVision from "./pages/NewVision";
import { DataProvider } from "./context/DataContext";
import React from "react";
import Oldvision from "./pages/Oldvision";
import Research from "../src/pages/Research";
import Allsongs from "./pages/Allsongs";
import Allprojects from "./pages/Allprojects";
import Vision from "./pages/Vision";
import theme from "./Theme/Theme";
import ProjectPage from "./pages/ProjectPage";
import NewallSongs from "./pages/NewallSongs";
import SongPage from "./pages/SongPage";
import Explore from "./pages/Explore";
import "./index.css";
import Calendar from "./pages/Calendar";
import Map from "./pages/Map";
import PhilosophicalFramework from "./pages/PhilosophicalFramework";
import Manifesto from "./pages/Manifesto";
import ContentAwaited from "./pages/ContentAwaited";
import WhatsNewPage from "./pages/WhatsNewPage";
import OsianamaWorld from "./pages/OsianamaWorld";
import MasterlistsInbox from "./pages/MasterLists";
import TheIntutivelogic from "./pages/TheIntutivelogic";
import TheIntutivelogicII from "./pages/TheIntutivelogic-II";
import ArtSteps from "./pages/ArtSteps";
import Register from "./pages/Register";
import Login from "./pages/Login";
import MyProfile from "./pages/MyProfile"

// Services
import ForgotPassword from "./services/ForgotPassword";
import { getResearchCategories } from "./services/ResearchCategories";

// Redux
import { useDispatch } from "react-redux";
import { getCategoriesData } from "./redux/slices/researchCategories";

// Component
import WhatsNew from "./components/WhatsNew";

// Location
import LocationIntroduction from "./pages/Location/Introduction";
import LocationBodyOfWork from "./pages/Location/BodyOfWork";
import LocationDetailPage from "./pages/Location/BodyOfWork/MonumentsAndHeritage/DetailPage";
import LocationEconomicsOfCulture from "./pages/Location/EconomicsOfCulture";
import LocationResearchCentre from "./pages/Location/ResearchCentre";
import LocationPosts from "./pages/Location/Posts/PostDetails";
import LocationInsights from "./pages/Location/Insights/InsightDetails";
import LocationLandingPage from "./pages/Location/LandingPage"

import FilmLandingPage from "./pages/Film/LandingPage";
import FilmIntroduction from "./pages/Film/Introduction";
import FilmPostDetails from "./pages/Film/Posts/PostDetails";
import FilmInsightDetails from "./pages/Film/Insights/InsightDetails";
import FilmBodyOfWok from "./pages/Film/BodyOfWork";
import FilmEconomicsOfCinema from "./pages/Film/EconomicsOfCinema";
import FilmResearchCentre from "./pages/Film/ResearchCentre";
import FilmTrailer from "./pages/Film/Trailer";
import FilmMainIntroductionImageView from "./pages/Film/Introduction/IntroductionImageView";
import MainSureshJindalSKK from "./pages/Film/SureshJndalSKK";
import DetailPageShatranjKeKhilari from "./pages/Film/ResearchCentre/Library/DetailPage";
import InsightVideo from "./pages/Film/Insights/InsightVideo";

// Object-Book-Journal
import ObjBookJouLandingPage from "./pages/ObjectBookJou/LandingPage";
import ObjBookJouIntroduction from "./pages/ObjectBookJou/Introduction";
import ObjBookJouBodyOfWork from "./pages/ObjectBookJou/BodyOfWork";
import ObjBookJouEditionsTimeline from "./pages/ObjectBookJou/BodyOfWork/EditionsTimeline";
import ObjBookJouExhibitionCatlouge from "./pages/ObjectBookJou/BodyOfWork/ExhibitionCatlouge";
import ObjBookJouResearchCentre from "./pages/ObjectBookJou/ResearchCentre";
import ObjBookJouEconomicsArt from "./pages/ObjectBookJou/EconomicsArt";
import ObjBookJouArtHeritageGallery from "./pages/ObjectBookJou/ArtHeritageGallery";

// Ebrahim Alkazi
import EbrahimAlkazi from "./pages/A-Graphy-Ebrahim-Alkazi/EbrahimAlkazi";

// Sanjeev Kumar
import SanjeevKumar from "./pages/A-Graphy-Sanjev-Kumar/SanjeevKumar";
import CinePersonalityLandingPage from "./CommonComponents/LandingPageComponents/CinePersonalityLandingPage";

//Nemai ghosh
import NemaiGhosh from "./pages/A-Graph-Nemai-Ghosh/NemaiGhosh";

// Object Book SSB
import ObjBookSsbLandingPage from "./pages/ObjectBookSsb/LandingPage";
import ObjBookSsbIntroduction from "./pages/ObjectBookSsb/Introduction";
import ObjBookSsbIntegratedTimeline from "./pages/ObjectBookSsb/IntegratedTimeline";
import ObjBookSsbBodyOFWork from "./pages/ObjectBookSsb/BodyOfWork";
import SongSynopsisDetails from "./pages/ObjectBookSsb/BodyOfWork/SongSynopsisBookletDetails/SongSynopsisDetails";
import ObjBookSsbEconomicsofCinema from "./pages/ObjectBookSsb/EconomicsofCinema";
import ObjBookSsbResearchCentre from "./pages/ObjectBookSsb/ResearchCentre";


import RCMain from "./pages/ResearchCategoryDetailsPage/Main";
import RCChronolgyTimeline from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Chronology/Timeline";
import RCPosts from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Posts/Grid";
import RCPostDetails from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Posts/PostDetails";
import RCInsights from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Insights";
import RCInsightDetails from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Posts/PostDetails";
import RCMasterlists from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Masterlists/Grid";
import RCMasterlistDetailPage from "./pages/ResearchCategoryDetailsPage/ResearchCategory/Masterlists/DetailPage";
import RCResearchCentreDetail from "./pages/ResearchCategoryDetailsPage/ResearchCategory/ResearchCenter/ResearchCentreDetail";
import ReadMore from "./pages/ResearchCategoryDetailsPage/ReadMore";

//Personality Polymath
import PersonPolyLandingPage from "./pages/PersonalityPolymath/LandingPage";
import PersonalPolyIntroduction from "./pages/PersonalityPolymath/Introduction";
import PersonalPolyBodyofWork from "./pages/PersonalityPolymath/BodyOfWork";
import PersonalPolyEconomicsOfCinema from "./pages/PersonalityPolymath/EconomicsOfCinema";
import PersonalPolyResearchCentre from "./pages/PersonalityPolymath/ResearchCentre";

//Personality-Artist
import PersonArtist from "./pages/PersonalityArtist/LandingPage";
import PersonArtistIntroduction from "./pages/PersonalityArtist/Introduction";
import PersonArtistBodyofWork from "./pages/PersonalityArtist/BodyofWork";
import PersonArtistEconomicsofArt from "./pages/PersonalityArtist/EconomicsOfArt";
import PersonArtistResearchCentre from "./pages/PersonalityArtist/ResearchCentre";
import PersonArtistPostDetails from "./pages/PersonalityArtist/Posts/PostDetails";
import PersonArtistInsightDetails from "./pages/PersonalityArtist/Insights/InsightDetails";

//EventExhibition
import EventExhibitionLandingPage from "./pages/EventExhibition/LandingPage"
import EventExhibitionIntroduction from "./pages/EventExhibition/Introduction"
import EventExhibitionBodyOfWork from "./pages/EventExhibition/BodyofWork"
import EventExhibitionEconomicsofArts from "./pages/EventExhibition/EconomicsofArts"
import EventExhibitionResearchCentre from "./pages/EventExhibition/ResearchCentre"

//Personality Film Maker
import PersonFilmMakerLandingPage from "./pages/PersonalityFilmMaker/LandingPage";
import PersonFilmMakerIntroduction from "./pages/PersonalityFilmMaker/Introduction";
import PersonFilmMakerBodyofWork from "./pages/PersonalityFilmMaker/BodyOfWork";
import PersonFilmMakerEconomicsOfCinema from "./pages/PersonalityFilmMaker/EconomicsOfCinema";
import PersonFilmMakerResearchCentre from "./pages/PersonalityFilmMaker/ResearchCentre";

//Personality Pesron Actor
import PersonActorLandingPage from "./pages/PersonalityActor/LandingPage";
import PersonActorIntroduction from "./pages/PersonalityActor/Introduction";
import PersonActorBodyofWork from "./pages/PersonalityActor/BodyOfWork";
import PersonActorEconomicsOfCinema from "./pages/PersonalityActor/EconomicsOfCinema";
import PersonActorResearchCentre from "./pages/PersonalityActor/ResearchCentre";

//Categories
import ContentAwaitedNew from "./pages/ContentAwaitedNew";

function App() {
  const [categories, setCategories] = React.useState([]);
  const dispatch = useDispatch();

  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  React.useEffect(() => {
    document.addEventListener("contextmenu", disableContextMenu);

    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getResearchCategories();
      if (result) {
        const dataCategories = result;
        setCategories(dataCategories);
        dispatch(getCategoriesData(dataCategories));
      }
    };
    getData();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, [dispatch]);

  return (
    <ChakraProvider theme={theme}>
      <DataProvider value={{ categories }}>
        <BrowserRouter>
          <Routes>
            {/* Home Page */}
            <Route path="/" element={<Home />} />
            <Route path="/projects/all" element={<Allprojects />} />
            <Route path="/projects/:id" element={<ProjectPage />} />
            <Route path="/songs/all" element={<NewallSongs />} />

            {/* Site vision/mission pages */}
            <Route path="/vision" element={<Vision />} />
            <Route path="/philosophical-framework" element={<PhilosophicalFramework />} />
            <Route path="/philosophical-framework/vision-september-2022" element={<NewVision />} />
            <Route path="/philosophical-framework/vision-october-2021" element={<Oldvision />} />
            <Route path="/philosophical-framework/the-intutive-logic" element={<TheIntutivelogic />} />
            <Route path="/philosophical-framework/the-intutive-logic-2" element={<TheIntutivelogicII />} />           
            <Route path="/manifesto" element={<Manifesto />} />

            {/* User Interaction pages */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/explore/:tabIndex" element={<Explore />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/osianamaworld" element={<OsianamaWorld />} />
            <Route path="/map" element={<Map />} />

            {/* Research Categories all Routes */}
            <Route path="/research-categories" element={<Research />} />
            <Route path="/research-categories/:alias/:taboptions?" element={<RCMain />} />
            <Route path="/research-categories/:alias/chronology" element={<RCChronolgyTimeline />} />
            <Route path="/research-categories/:alias/masterlists" element={<RCMasterlists />} />
            <Route path="/research-categories/:alias/masterlists/:masterlistAlias" element={<RCMasterlistDetailPage />} />
            <Route path="/research-categories/:alias/posts" element={<RCPosts />} />
            <Route path="/research-categories/:alias/posts/:postAlias" element={<RCPostDetails />} />
            <Route path="/research-categories/:alias/insights" element={<RCInsights />} />
            <Route path="/research-categories/:alias/insights/insightAlias" element={<RCInsightDetails />} />
            <Route path="/research-categories/:alias/research-centre" element={<RCResearchCentreDetail />} />
            <Route path="/research-category/read-more" element={<ReadMore />} />

            {/* To do - Need to remove this once tested for 2 -3 masterlist pages  */}
            {/* <Route path="/categories/:alias" element={<ContentAwaitedNew />} /> */}

            <Route path="/newallsongs" element={<Allsongs />} />
            <Route path="/songpage/:id" element={<SongPage />} />
            <Route path="/artsteps" element={<ArtSteps />} />           
            <Route path="/whats-new" element={<WhatsNew />} />          
            <Route path="/content-awaited" element={<ContentAwaited />} />
            <Route path="/whats-new-page/:sliderNumber" element={<WhatsNewPage />} />
            <Route path="/masterlist" element={<MasterlistsInbox />} />

            <Route path="/sanjeev-kumar" element={<SanjeevKumar />} />

            <Route path="/ebrahim-alkazi" element={<EbrahimAlkazi />} />
            <Route path="/categories/:alias" element={<ContentAwaitedNew />} />
             
             {/* Object-Book-Journal */}
            <Route path="/object-book-jou/:alias/:tabIndex?" element={<ObjBookJouLandingPage />} />
            <Route path="/object-book-jou/:alias/introduction" element={<ObjBookJouIntroduction />} />
            <Route path="/object-book-jou/:alias/economics-of-art" element={<ObjBookJouEconomicsArt />} />
            <Route path="/object-book-jou/:alias/art-heritage-gallery" element={<ObjBookJouArtHeritageGallery />} />
            <Route path="/object-book-jou/:alias/body-of-work/" element={<ObjBookJouBodyOfWork />} />
            <Route path="/object-book-jou/:alias/timeline" element={<ObjBookJouEditionsTimeline />} />
            <Route path="/object-book-jou/:alias/body-of-work/timeline" element={<ObjBookJouEditionsTimeline />} />
            <Route path="/object-book-jou/:alias/body-of-work/timeline/exhibition-catlouge/:tabIndex?" element={<ObjBookJouExhibitionCatlouge />} />
            <Route path="/object-book-jou/:alias/research-centre" element={<ObjBookJouResearchCentre />} />

            {/* Location */}
            <Route path="/location/:alias/:tabIndex?" element={<LocationLandingPage />} />
            <Route path="/location/:alias/posts/:postAlias" element={<LocationPosts />} />
            <Route path="/location/:alias/insights/:insightAlias" element={<LocationInsights />} />
            <Route path="/location/:alias/introduction" element={<LocationIntroduction />} />
            <Route path="/location/:alias/body-of-work/" element={<LocationBodyOfWork />} />
            <Route path="/location/:alias/body-of-work/:monumentAlias?" element={<LocationDetailPage />} />
            <Route path="/location/:alias/economics-of-culture" element={<LocationEconomicsOfCulture />} />
            <Route path="/location/:alias/research-centre/:bookAlias?/:bookDetails?" element={<LocationResearchCentre />} />           

            <Route path="/:alias" element={<CinePersonalityLandingPage />} />
            <Route path="/nemai-ghosh" element={<NemaiGhosh />} />

            {/* Object Book SSB */}
            <Route path="/object-book-ssb/:alias/:tabIndex?" element={<ObjBookSsbLandingPage />} />
            <Route path="/object-book-ssb/:alias/introduction/:tabIndex?" element={<ObjBookSsbIntroduction />} />
            <Route path="/object-book-ssb/:alias/integrated-timeline" element={<ObjBookSsbIntegratedTimeline />} />
            <Route path="/object-book-ssb/:alias/body-of-work" element={<ObjBookSsbBodyOFWork />} />
            <Route path="/object-book-ssb/:alias/body-of-work/:songName?" element={<SongSynopsisDetails />} />
            <Route path="/object-book-ssb/:alias/economics-of-cinema" element={<ObjBookSsbEconomicsofCinema />} />
            <Route path="/object-book-ssb/:alias/research-centre" element={<ObjBookSsbResearchCentre />} />

            <Route path="/object-collection/:alias" element={<ReadMore />} />
            <Route path="/person-photographer/:alias" element={<ReadMore />} />
            <Route path="/song/:alias" element={<ReadMore />} />

            {/* Personality Polymath */}
            <Route path="/person-poly/:alias" element={<PersonPolyLandingPage />} />
            <Route path="/person-poly/:alias/:tabIndex?/:drawerId?" element={<PersonPolyLandingPage />} />
            <Route path="/person-poly/:alias/introduction/" element={<PersonalPolyIntroduction />} />
            <Route path="/person-poly/:alias/body-of-work/:gridview?/:drawerId?" element={<PersonalPolyBodyofWork />} />
            <Route path="/person-poly/:alias/economics-of-cinema" element={<PersonalPolyEconomicsOfCinema />} />
            <Route path="/person-poly/:alias/research-centre/:bookAlias?/:bookDetails?" element={<PersonalPolyResearchCentre />} />           

            {/* Personality Polymath ends here*/}

            {/* person-artist*/}
            <Route path="/person-artist/:alias" element={<PersonArtist />} />
            <Route path="/person-artist/:alias/posts" element={<PersonArtist />} />
            <Route path="/person-artist/:alias/insights" element={<PersonArtist />} />
            <Route path="/person-artist/:alias/introduction/" element={<PersonArtistIntroduction />} />
            <Route path="/person-artist/:alias/body-of-work" element={<PersonArtistBodyofWork />} />
            <Route path="/person-artist/:alias/body-of-work/:catalouge?" element={<PersonArtistBodyofWork />} />
            <Route path="/person-artist/:alias/economics-of-art" element={<PersonArtistEconomicsofArt />} />
            <Route path="/person-artist/:alias/research-centre/:bookAlias?/:bookDetails?" element={<PersonArtistResearchCentre />} />           
            <Route path="/person-artist/:alias/posts/:postAlias" element={<PersonArtistPostDetails />} />
            <Route path="/person-artist/:alias/insights/:insightAlias" element={<PersonArtistInsightDetails />} />
 
            {/* person-artist ends here*/}

            {/* Film */}
            <Route path="/film/:alias" element={<FilmLandingPage />} />
            <Route path="/film/:alias/:tabIndex/:drawerId?" element={<FilmLandingPage />} />
            <Route path="/film/:alias/posts/:postAlias" element={<FilmPostDetails />} />
            <Route path="/film/:alias/posts" element={<FilmLandingPage />} />
            <Route path="/film/:alias/insights" element={<FilmLandingPage />} />
            <Route path="/film/:alias/insights/:insightAlias" element={<FilmInsightDetails />} />
            <Route path="/film/:alias/insights/:insightAlias/insight-video" element={<InsightVideo />} />
            <Route path="/film/:alias/watch-film-trailer" element={<FilmTrailer />} />
            <Route path="/film/:alias/introduction/:tabIndex?" element={<FilmIntroduction />} />
            <Route path="/film/:alias/introduction/title-image" element={<FilmMainIntroductionImageView />} />
            <Route path="/film/:alias/body-of-work/:tabIndex?/:drawerId?"  element={<FilmBodyOfWok />} />
            <Route path="/film/:alias/economics-of-cinema/:tabIndex?" element={<FilmEconomicsOfCinema />} />
            <Route path="/film/:alias/research-centre/:bookAlias?/:bookDetails?" element={<FilmResearchCentre />} />           
         
            <Route path="/film/:alias/suresh-jindal" element={<MainSureshJindalSKK />} />
            <Route path="/film/:alias/library/:id" element={<DetailPageShatranjKeKhilari />} />

            {/* event-exhibition */}
            <Route path="/event-exhibition/:alias" element={<EventExhibitionLandingPage />} />
            <Route path="/event-exhibition/:alias/introduction" element={<EventExhibitionIntroduction />} />
            <Route path="/event-exhibition/:alias/body-of-work" element={<EventExhibitionBodyOfWork />} />
            <Route path="/event-exhibition/:alias/economics-of-art" element={<EventExhibitionEconomicsofArts />} />
            <Route path="/event-exhibition/:alias/research-centre" element={<EventExhibitionResearchCentre />} />

            {/* Personality Film Maker */}
            <Route path="/person-film-maker/:alias" element={<PersonFilmMakerLandingPage />} />
            <Route path="/person-film-maker/:alias/:tabIndex?/:drawerId?" element={<PersonFilmMakerLandingPage />} />
            <Route path="/person-film-maker/:alias/introduction/" element={<PersonFilmMakerIntroduction />} />
            <Route path="/person-film-maker/:alias/body-of-work/:gridview?/:drawerId?" element={<PersonFilmMakerBodyofWork />} />
            <Route path="/person-film-maker/:alias/economics-of-cinema" element={<PersonFilmMakerEconomicsOfCinema />} />
            <Route path="/person-film-maker/:alias/research-centre" element={<PersonFilmMakerResearchCentre />} />

            {/* Personality Actor */}
            <Route path="/person-actor/:alias" element={<PersonActorLandingPage />} />
            <Route path="/person-actor/:alias/:tabIndex?/:drawerId?" element={<PersonActorLandingPage />} />
            <Route path="/person-actor/:alias/introduction/" element={<PersonActorIntroduction />} />
            <Route path="/person-actor/:alias/body-of-work/:gridview?/:drawerId?" element={<PersonActorBodyofWork />} />
            <Route path="/person-actor/:alias/economics-of-cinema" element={<PersonActorEconomicsOfCinema />} />
            <Route path="/person-actor/:alias/research-centre" element={<PersonActorResearchCentre />} />
            <Route path="/person-actor/:alias/research-centre/:bookAlias?/:bookDetails?" element={<PersonActorResearchCentre />} />

          </Routes>
        </BrowserRouter>
      </DataProvider>
    </ChakraProvider>
  );
}

export default App;
