import {
  nemai_ghosh_main_insight,
  skk_insight,
  skk_main_insight,
  skk_insight_suggestedreading_list,
  skk_insight_essentialreading_list,
  skk_insightdetail_page_imagedata,
  skk_insight_quiz_data,
  kg_subramanayam_insight,
  kg_subramanayam_insightdetail_page_imagedata,
  kg_subramanayam_insight_essentialreading_list,
  kg_subramanayam_insight_suggestedreading_list,
  kg_subramanayam_main_insight,
} from "../MockData/InsightDetailsMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import axios from "axios";
const baseUrl = `${process.env.REACT_APP_MONGODB_URL}`;

/**
 * This function return total count of insights for respective masterlist.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias.
 * @returns {Promise<integer>} - Function return total count of insights.
 */
export const getInsightsCountByMasterlistAPI = async (tableName, masterlistAlias) => {
  const insightCount = 0;
  return insightCount;
};

export const getInsightsCountByMasterlist = async (tableName, masterlistAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getInsightsCountByMasterlistDummyData(masterlistAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getInsightsCountByMasterlistAPI(tableName, masterlistAlias);
    return response;
  }
};

export const getInsightsCountByMasterlistDummyData = async (masterlistAlias) => {
  const getInsightCount = {
    "shatranj-ke-khilari-1977": 1,
    "satyajit-ray": 0,
    "art-heritage-annual": 1,
    "k.g-Subramanyan": 1
  };
  const insightCount = getInsightCount[masterlistAlias] || 0;
  return insightCount;
};

/**
 * This function return insights of post for respective masterlist.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias (If user click on Insights tab from masterlist landing page then show all insights).
 * @param {string} postAlias - Post alias (If user click on Insight button located on Post detail page, then show insight associated with that post only).
 * @param {string} alias - Insight alias.
 * @returns {Promise<integer>} - Function return insights list as per condition(either by masterlist or by post).
 */
export const getInsightsByMasterlistAPI = async (tableName, masterlistAlias, postAlias, alias) => {
  const insights = [{}]
  return insights;
}

export const getInsightsByMasterlist = async (tableName, masterlistAlias, postAlias, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getInsightsByMasterlistDummy(tableName, masterlistAlias, postAlias, alias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getInsightsByMasterlistAPI(tableName, masterlistAlias, postAlias, alias);
    return response;
  }
};
export const getInsightsByMasterlistDummy = (tableName, masterlistAlias, postAlias, alias) => {
  const getInsightDataMap = {
    "shatranj-ke-khilari-1977": skk_main_insight,
    "nemai-ghosh": nemai_ghosh_main_insight,
    "k.g-Subramanyan": kg_subramanayam_main_insight
  };
  const insightData = getInsightDataMap[masterlistAlias] || skk_insight;
  return insightData;
};

/**
 * This function return insight detail of post for respective masterlist.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias
 * @param {string} alias - Insight alias.
 * @param {string} postAlias - Post alias 
 * @returns {Promise<integer>} - Function return specific insight details.
 */
export const getInsightAPI = async (tableName, masterlistAlias, postAlias, alias) => {
  const posts = [{}]
  return posts;
}

export const getInsight = async (tableName, masterlistAlias, alias,) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getInsightDummy(tableName, masterlistAlias, alias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getInsightAPI(tableName, masterlistAlias, alias);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getInsightDummy = (tableName, masterlistAlias, alias) => {
  const insightDetailsDataMap = {
    "shatranj-ke-khilari-1977": skk_insight,
    "k.g-Subramanyan": kg_subramanayam_insight
  };
  const insightDetailsData = insightDetailsDataMap[masterlistAlias] || skk_insight;

  return insightDetailsData.filter((item) => item.insight === alias);
};

/**
 * This function return insight essential reading doc list.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias
 * @param {string} postAlias - Post alias
 * @param {string} insightAlias - Insight alias
 * @returns {Promise<integer>} - This function return essential doc reading list of that insight
 */
export const getInsightEssentialReadingAPI = (tableName, masterlistAlias, postAlias, insightAlias) => {
  return [];
};

export const getInsightEssentialReading = (tableName, masterlistAlias, postAlias, insightAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = getInsightEssentialReadingDummyData(tableName, masterlistAlias, postAlias, insightAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = getInsightEssentialReadingAPI(tableName, masterlistAlias, postAlias, insightAlias);
    return response;
  }
};

export const getInsightEssentialReadingDummyData = (tableName, masterlistAlias, postAlias, insightAlias) => {
  const getInsightDataMap = {
    "shatranj-ke-khilari-1977": skk_insight_essentialreading_list,
    "k.g-Subramanyan": kg_subramanayam_insight_essentialreading_list

  };
  const skk_insight_essentialreading = getInsightDataMap[masterlistAlias] || [];
  return skk_insight_essentialreading;
};

/**
 * This function return insight suggested reading doc list.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias
 * @param {string} postAlias - Post alias
 * @param {string} insightAlias - Insight alias
 * @returns {Promise<integer>} - This function return suggested doc reading list of that insight
 */
export const getInsightSuggestingReadingAPI = (tableName, masterlistAlias, postAlias, insightAlias) => {
  return [];
};

export const getInsightSuggestingReading = (tableName, masterlistAlias, postAlias, insightAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = getInsightSuggestingReadingDummyData(tableName, masterlistAlias, postAlias, insightAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = getInsightSuggestingReadingAPI(tableName, masterlistAlias, postAlias, insightAlias);
    return response;
  }
};

export const getInsightSuggestingReadingDummyData = (tableName, masterlistAlias, postAlias, insightAlias) => {
  const getInsightDataMap = {
    "shatranj-ke-khilari-1977": skk_insight_suggestedreading_list,
    "k.g-Subramanyan": kg_subramanayam_insight_suggestedreading_list

  };
  const skk_insight_suggestedreading = getInsightDataMap[masterlistAlias] || [];
  return skk_insight_suggestedreading;
};

// This function return insight pegination images of insights for respective alias
export const getInsightImagesDataAPI = (alias) => {
  return [];
};

export const getInsightImagesData = (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = getInsightImagesDataDummyData(alias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = getInsightImagesDataAPI(alias);
    return response;
  }
};

export const getInsightImagesDataDummyData = (alias) => {
  const getInsightDataMap = {
    "shatranj-ke-khilari-1977": skk_insightdetail_page_imagedata,
    "k.g-Subramanyan": kg_subramanayam_insightdetail_page_imagedata
  };
  const skk_insightdetail_page_image_data = getInsightDataMap[alias] || skk_insightdetail_page_imagedata;
  return skk_insightdetail_page_image_data;
};

// This function return post images of insights for respective alias
export const getInsightQuizDataAPI = (alias) => {
  return [];
};

export const getInsightQuizData = (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = getInsightQuizDummyData(alias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
  }
};

export const getInsightQuizDummyData = (alias) => {
  const getInsightDataMap = {
    "shatranj-ke-khilari-1977": skk_insight_quiz_data,
  };
  const skk_insightdetail_quiz_data = getInsightDataMap[alias] || [];
  return skk_insightdetail_quiz_data;
};



/**
 * This function return insights of post for respective masterlist.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias (If user click on Insights tab from masterlist landing page then show all insights).
 * @param {string} postAlias - Post alias (If user click on Insight button located on Post detail page, then show insight associated with that post only).
 * @param {string} alias - Insight alias.
 * @returns {Promise<integer>} - Function return insights list as per condition(either by masterlist or by post).
 */
export const getInsightsAPI = async (tableName, masterlistAlias, postAlias, alias) => {
  const insights = [{}]
  return insights;
}

export const getInsights = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getInsightsDummy(tableName, alias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getInsightsAPI(tableName, alias);
    return response;
  }
};
export const getInsightsDummy = (tableName, alias) => {
  const getInsightDataMap = {
    "globalinsights": skk_main_insight,
  };
  const insightData = getInsightDataMap[alias] || skk_insight;
  return insightData;
};


/**
 * This function return Explore Insights data
 * @returns {Promise<Object>} - Function return Explore Insights data
 */

export const getExploreInsightsData = async () => {
  if (isTransformationNotAllowed() === true) {
    const response = await getExploreInsightsDummyData();
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getExploreInsightsAPIData();
    return response;
  }
};

export const getExploreInsightsDummyData = async () => {
  return [];
};

export const getExploreInsightsAPIData = async () => {
  const result = await axios.get(baseUrl + '/explore/insights');
  if (result) {
    return result?.data?.data;
  }
};

/**
 * This function return Research Categories Insights data
 * @param {String} alias - Alias of Research Category
 * @returns {Promise<Object>} - Function return Research Categories Insights data
 */

export const getRCInsightsData = async (alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getRCInsightsDummyData();
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getRCInsightsAPIData(alias);
    return response;
  }
};

export const getRCInsightsDummyData = async () => {
  return [];
};

export const getRCInsightsAPIData = async (alias) => {
  const result = await axios.get(baseUrl + '/research-categories/' + alias + '/insights');
  if (result) {
    return result?.data?.data;
  }
};