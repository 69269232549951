import {
  Box,
  HStack,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation,  } from "swiper";

//Service files
import { CopyPasteService } from "../../../../services/CopyPaste";

//Component files
import { TextComponent } from "../../../../CommonComponents/TextComponent";
import {ImagePopupModal} from "../../../Film/BodyOfWork/Synopsis"
import LyricsSoundtrack from "./Soundtrack";


//Language files
import { explore } from "../../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholder = `${IMAGE_BASE_PATH}/ui//PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/ImageAwaited.png`

function BookletSongs({songName}) {
    const mergeData = [
        {
          imagePath: placeholder,
          desc: "Conetnt Awaited",
          newDesc:"Conetnt Awaited"
        },
        {
          imagePath: placeholder,
          desc: "Caption Awaited",
          newDesc:"Conetnt Awaited"
        }
      ]; 

  return (
    <Tabs py={{base:1,md:4}}>
    <TabList
      position="sticky"
      zIndex={99}
      width={{base:"100%",md:"100%"}}
      top={{base:"64px",md:"90px"}}
      px="0px"
      py="2"
      bg="white"
    >
      <Tab
        className="Timeline"
        p="0"
        mr="4"
        fontSize={"16px"}
        fontWeight={"400"}
        _selected={{
          borderBottom: "4px",
          color: "black",
          borderBottomColor: "black",
          fontWeight: "700",
          borderRadius: "4px",
        }}
      >
        {explore.SYNOPSIS}
      </Tab>
      <Tab
        className="Timeline"
        p="0"
        ml="4"
        fontSize={"md"}
        fontWeight={"400"}
        _selected={{
          borderBottom: "4px",
          color: "black",
          borderBottomColor: "black",
          fontWeight: "700",
          borderRadius: "4px",
        }}
      >
        {explore.LYRICS_AND_SOUNDTRACK}
      </Tab>
    </TabList>
    <TabPanels height={{base:"100vh",md:"calc(100vh - 130px)"}}>
      <TabPanel px="0px" py={0}>
      <Synopsis/>
      </TabPanel>
      <TabPanel px="0px" py={0}>
      <LyricsSoundtrack winnerName={songName} data={true} mergeData={mergeData} marginLeft={{ base: "-20px !important", md: "-80px !important" }}/>
      </TabPanel>
    </TabPanels>
  </Tabs>
  );
}

export default BookletSongs;

const Synopsis=({ winnerName }) =>{
    const [clickedImageIndex, setClickedImageIndex] = useState(null);
  
    const mergeData = [
      {
        imagePath: placeholder,
        desc: "Conetnt Awaited",
        newDesc:"Conetnt Awaited"
      },
      {
        imagePath: placeholder,
        desc: "Conetnt Awaited",
        newDesc:"Conetnt Awaited"
      },
      {
        imagePath: placeholder,
        desc: "Conetnt Awaited",
        newDesc:"Conetnt Awaited"
      }
    ]; 

    const synopsisDesc = "Conetnt Awaited"
    const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
    const [synopsisImgData, setsynopsisImgData] = useState(null);
    const showSynopsisImages = (image, flag, index) => {
      if (image) {
        setClickedImageIndex(index);
        setSynopsisFullScreenImage(flag);
        setsynopsisImgData(image);
      } else {
        setSynopsisFullScreenImage(flag);
        setsynopsisImgData(null);
      }
    };
  
    return (
      <>
        <ImagePopupModal
          isOpen={synopsisFullScreenImage}
          onClose={() => showSynopsisImages(null, false)}
          imageData={synopsisImgData}
          data={mergeData}
          clickedImageIndex={clickedImageIndex}
        />
        <Stack
          pb="2"
          flexDirection={{base:"column",md:"row"}}
          alignItems={"flex-start"}
          gap={6}
          pt={{base:"0",md:"6"}}
        >
          <HStack
            marginTop="0.0rem!important"
            flexDirection={"column"}
            width={{base:"100%",md:"526px"}}
            px="0px"
            py="4"
            alignItems={"flex-start"}
          >
            <TextComponent text={synopsisDesc} fontSize={"16px"}
              textAlign={"justify"}
              fontWeight={"600"}
              color={"#333333"}
             
              lineHeight={"32px"}
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)} />
          </HStack>
          <HStack  width={{base:"100%",md:"526px"}}>
            <Stack className="col-12" id={"satyajitslidersynp"} position={"relative"}>
              <HStack py="4">
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation]}
                >
                  {mergeData.map((item, index) => (
                    <>
                      <SwiperSlide className="myslide">
                        <Box height={"100%"} width={{base:"100%",md:"484px"}}> 
                          <Image
                            height={"inherit"}
                            width={"100%"}
                            onClick={(e) =>
                              showSynopsisImages(item?.imagePath, true, index)
                            }
                            src={
                              index < 2
                                ? item
                                  ? 
                                    item?.imagePath
                                  : ""
                                : item?.imagePath
                            }
                            cursor={"pointer"}
                          />
  
                          <Text
                            pt={"16px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                            textAlign={"left"}
                          >
                            {item.desc}
                          </Text>
                        </Box>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              </HStack>
            </Stack>
          </HStack>
        </Stack>
      </>
    );
  }