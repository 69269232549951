import React from "react";
import H2 from "../../../../components/layouts/Typography/H2";

export default function TeachingDrawerHeader({headerData}) {
  return (
    <>
      <H2
        style={{ whiteSpace: "pre-wrap" }}
        width={"252px"}
        textStyle={"primary"}
        marginLeft={"10px"}
      >
        {headerData}
      </H2>
    </>
  );
}
