
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Image,
  Container,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";

//Services files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";
import { CopyPasteService } from "../../services/CopyPaste";

//Component files
import MobileNav from "../../components/Topheader";
import Heading from "../../components/layouts/Typography/Heading";
import H3 from "../../components/layouts/Typography/H3";
import Large from "../../components/layouts/Typography/Large";
import H2 from "../../components/layouts/Typography/H2";
import ReadMore from "../PersonalityPolymath/ReadMore";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import CommonDrawer from "../../FunctionalComponents/CommonDrawer/CommonDrawer";
import PersonaliaDrawerHeader from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/PersonaliaDrawerHeader";
import PersonaliaDrawerBody from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/PersonaliaDrawerBody";

//Language files
import { common, explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const play = `${IMAGE_BASE_PATH}/ui/Chevron/playIcon.png`

export default function Introduction({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const {alias}=useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});
  
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }`,
            title:it?.find((item) => item?.key === "landing_page_abbreviation")?.value
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <IntroductionBody
          setTitle={setTitle}
          setDate={setDate}
          setDateDod={setDateDod}
          readMoreText={readMoreText}
          setReadMore={setReadMore}
          aliasAPI={aliasAPI}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };

  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CommonLeftSidebar
        sections={introData}
        oSections={data.oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        backTo={data.backNavigation}
        handleNavigation={handleNavigation}
        leftFlag={true}
        readMoreText={readMoreText}
      />
    </>
  );
};

const IntroductionBody = ({
  setTitle,
  setDate,
  setDateDod,
  readMoreText,
  setReadMore,
  aliasAPI,
}) => {

  const btnRef = React.useRef();
  const [personaliaDrawer, setPersonaliaDrawer] = React.useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [educationFullScreenImage, setEducationFullScreenImage] =
    useState(false);
  const [educationImgData, setEducationImgData] = useState(null);
  const [familyFullScreenImage, setFamilyFullScreenImage] = useState(false);
  const [familyImgData, setFamilyImgData] = useState(null);
  const [contentAwaited, setContentAwaited] = useState(false);
  const masterlistTitle = aliasAPI;

  const [introData, setIntroData] = useState([{}]);
  const [personaliaData, setPersonaliaData] = useState([]);
  const [teachingData, setTeachingData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [masterlistImage, setMasterListImage] = useState("");

  const showEducationImages = (images, flag) => {
    if (images) {
      setEducationImgData(images);
    } else {
      setEducationFullScreenImage(flag);
      setEducationImgData(null);
    }
  };

  const showFamilyMemberImages = (images, flag) => {
    if (images) {
      setFamilyImgData(images);
    } else {
      setFamilyFullScreenImage(flag);
      setFamilyImgData(null);
    }
  };

  const showFullImages = (flag) => {
    setFamilyFullScreenImage(flag);
  };
  // const showFullEducationImages = (flag) => {
  //   setEducationFullScreenImage(flag);
  // };

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        masterlistTitle
      );
      const personalia = await getIntroductionData.getPersonaliaByAlias(
        "personality",
        masterlistTitle
      );
      const education = await getIntroductionData.getEducationByAlias(
        "personality",
        masterlistTitle
      );
      const teaching = await getIntroductionData.getTeachingInfraByAlias(
        "personality",
        masterlistTitle
      );

      if (result) {
        setContentAwaited(false);
        setMasterListImage(
          result[0].find((item) => item?.key === "image")?.value
        );
        setIntroData([
          {
            name: result[0].find((item) => item?.key === "full_name")?.value,
            date_of_birth: result[0].find(
              (item) => item?.key === "date_of_birth"
            )?.value,
            date_of_death: result[0].find(
              (item) => item?.key === "date_of_death"
            )?.value,
            description: result[0].find((item) => item?.key === "description")
              ?.value,
          },
        ]);
      } else {
        setContentAwaited(true);
      }

      if (personalia) {
        const dataIntro = personalia;
        setPersonaliaData(dataIntro);
      }

      if (education) {
        const dataIntro = education;
        setEducationData(dataIntro);
      }
      if (teaching) {
        const dataIntro = teaching;
        setTeachingData(dataIntro);
      }
    };
    getIntroData();
  }, [masterlistTitle]);

  return (
    <>
      {readMoreText ? (
        <ReadMore />
      ) : (
        <>
          <Stack>
            {contentAwaited ? (
              <Text
                py={"370px"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                {common.WEBPAGE_COMING_SOON}
              </Text>
            ) : (
              <>
              <Container maxW="auto" px="0" className="songs">
                {/* Personalia | Early Life, Family & Education */}
                <CommonDrawer
                  IntroDrawerHeader={
                    <PersonaliaDrawerHeader headerData={explore.PERSONALIA} />
                  }
                  IntroDrawerBody={
                    <PersonaliaDrawerBody
                      personaliaData={personaliaData}
                      showFamilyMemberImages={showFamilyMemberImages}
                      familyImgData={familyImgData}
                      showFullImages={showFullImages}
                      familyFullScreenImage={familyFullScreenImage}
                      closeIcon={closeIcon}
                      showEducationImages={showEducationImages}
                      data={educationData}
                      teachingData={teachingData}
                      personaliaDrawer={personaliaDrawer}
                      educationImgData={educationImgData}
                      educationFullScreenImage={educationFullScreenImage}
                      
                    />
                  }
                  isOpen={personaliaDrawer}
                  onClose={setPersonaliaDrawer}
                  ref={btnRef}
                  id="rightsidebar"
                  autoFocus="none"
                />
                
                <Stack
                  backgroundPosition="top"
                  backgroundImage={`linear-gradient(to bottom, rgba(245 246 252 / 5%), rgba(0 0 0 / 88%)), url('${masterlistImage}')`}
                  backgroundRepeat="no-repeat"
                  height="calc(100vh - 64px)"
                  backgroundSize="cover"
                  position={"relative"}
                >
                  <Stack
                    bottom={{base:"10%",md:"5%"}}
                    position={"absolute"}
                    px={{base:4,md:16}}
                    flexDirection={"row"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    {introData?.map((item, index) => (
                      <HStack
                        onCopy={(e) =>
                          CopyPasteService(e, window.location.href)
                        }
                      >
                        <Box mx={{base:"0",md:"8"}} my="4">
                          <Heading
                            color={"white"}
                            textStyle={"primary"}
                            fontFamily={"SEGOE UI Bold"}
                          >
                            {item?.name}
                          </Heading>
                          <H3
                            color={"white"}
                            fontWeight={900}
                            fontFamily={"SEGOE UI Bold"}
                          >
                            {`${item?.date_of_birth!=="" ? dateFormat(item?.date_of_birth, "d mmm yyyy") :"" } 
                            ${item?.date_of_death!=="" ? " - " + dateFormat(item?.date_of_death, "d mmm yyyy") :"" } `}
                          </H3>
                          <Large
                            width={{base:"100%",md:"437px"}}
                            display={"block"}
                            color="white"
                            py="3"
                          >
                            <Text
                              fontSize={"14px"}
                              fontWeight={400}
                              lineHeight={"16px"}
                              width={{base:"fit-content",md:"437px"}}
                            >
                              {item?.description}
                              <span
                                className="introReadmore"
                                onClick={() => setReadMore(true)}
                              >
                                {common.READ_MORE}
                              </span>
                            </Text>
                          </Large>
                          <Text
                            py="1"
                            color={"white"}
                            fontWeight={"700"}
                            fontSize="16px"
                            ref={btnRef}
                            cursor={"pointer"}
                            textStyle={"primary"}
                            onClick={(e) => masterlistTitle!=="shammi-kapoor" && setPersonaliaDrawer(explore.PERSONALIA)}
                          >
                            {explore.PERSONALIA}
                          </Text>
                          <Text
                            py="1"
                            color={"white"}
                            fontWeight={"700"}
                            fontSize="16px"
                            ref={btnRef}
                            cursor={"pointer"}
                            textStyle={"primary"}
                            onClick={(e) => masterlistTitle!=="shammi-kapoor" && setPersonaliaDrawer(explore.EDUCATION)}
                          >
                            {explore.EDUCATION}
                          </Text>
                          <Text
                            py="1"
                            color={"white"}
                            fontWeight={"700"}
                            fontSize="16px"
                            ref={btnRef}
                            cursor={"pointer"}
                            textStyle={"primary"}
                            onClick={(e) => masterlistTitle!=="shammi-kapoor" && setPersonaliaDrawer(explore.TEACHING_INFRA_B)}
                          >
                            {explore.TEACHING_INFRA_B}
                          </Text>
                        </Box>
                      </HStack>
                    ))}
                    <HStack>
                      <Box display={{base:"none",md:"block"}} mx="8" my="-4">
                        <Image src={play} />
                      </Box>
                      <Box mx="8">
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          borderRadius={"35px"}
                          cursor="pointer"
                          onClick={(e) => setFullscreen(true)}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </Box>
                      <Modal
                        size={"full"}
                        isCentered
                        isOpen={fullscreen}
                        onClose={(e) => setFullscreen(false)}
                        Id="modalfullscreen"
                      >
                        <ModalOverlay />
                        <ModalContent flexDirection={"row"} alignItems={"center"} zIndex={"999"}>
                          <ModalBody>
                            <Box bg="white">
                              <Stack>
                                <HStack justifyContent={"center"}>
                                  <Image
                                    src={masterlistImage
                                    }
                                    width={"auto"}
                                    height={{base:"100%",md:"100vh"}}
                                  ></Image>
                                </HStack>
                                <HStack justifyContent="flex-end">
                                  <Box
                                    position={"absolute"}
                                    bottom={"5%"}
                                    right={"10%"}
                                  >
                                    <Text
                                      fontSize={"xl"}
                                      bg={"#525252"}
                                      color={"white"}
                                      fontWeight={"400"}
                                      padding="2"
                                      borderRadius={"35px"}
                                      cursor="pointer"
                                      onClick={(e) => setFullscreen(false)}
                                    >
                                      <CgArrowsExpandRight />
                                    </Text>
                                  </Box>
                                </HStack>
                              </Stack>
                            </Box>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </HStack>
                  </Stack>
                </Stack>
              </Container>
              <Stack display={{base:"flex",md:"none"}}>
              <BottomMainMenu/>
              </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export function ArtistDetails({ artistDetails }) {
  return (
    <>
      <HStack justifyContent="left" py="1" px="4" marginTop="0.0rem">
        <H2>{artistDetails?.name}</H2>
      </HStack>
      <HStack justifyContent="left" px="4">
        <Large color={"black"}>
          {dateFormat(artistDetails?.date_of_birth, "d mmm yyyy")} -{" "}
          {dateFormat(artistDetails?.date_of_death, "d mmm  yyyy")}
        </Large>
      </HStack>
    </>
  );
}
