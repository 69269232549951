export const schema = [
  { key: "alias", display: "Alias", seq: 1 },
  { key: "landing_page_title", display: "Title", seq: 2 },
  { key: "landing_page_abbreviation", display: "Abbreviation", seq: 3 },
  { key: "description", display: "Description", seq: 4 },
  {
    key: "artwork",
    display: "Landing page image",
    processor: "imageAliasArray",
    seq: 5,
  },
  { key: "masterlist", display: "Masterlist", seq: 6 },
];
