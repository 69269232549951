import { 
  Accordion, 
  AccordionButton, 
  AccordionIcon, 
  AccordionItem, 
  AccordionPanel, 
  Box, 
  Button, 
  Container, 
  HStack, 
  Image, 
  Input, 
  InputGroup, 
  InputRightElement, 
  SimpleGrid, 
  Stack, 
  Tab, 
  TabList, 
  Tabs, 
  Text, 
  useColorModeValue, 
  useDisclosure 
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import { getMasterListsByResearchCategoryAlias } from "../../../../services/ResearchCategoryDetails";

//Component file
import LeftVmenu from "../../../../FunctionalComponents/LeftVmenu";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "react-icons/io";
import MobileNav from "../../../../components/Topheader";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import Small from "../../../../components/layouts/Typography/Small";

//Language file
import {button, common, explore, research_category } from "../../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function RCMasterlists() {
const [masterlistsData, setMasterlistsData] = useState([]);
const [showFilterBox, setShowFilterBox] = useState(false);
    const { alias } = useParams();
    const navigate = useNavigate();
    const url = window.location.pathname.lastIndexOf("/");
    const mainUrl = window.location.pathname.slice(0, url);
  useEffect(() => {
    const getData = async () => {
      const result = await getMasterListsByResearchCategoryAlias("", alias);
      if (result) {
        setMasterlistsData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [alias]);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  return (
    <SidebarWithHeader>
    <Stack>
      <Container maxW="auto" px="0" className="songs">
      <Box
          position={"fixed"}
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "4" }}
          zIndex={999}
          bg="white"
          width={"1344px"}
        >
          <Tabs variant={"unstyled"} display={"flex"} align="end" pos={"none"} alignItems={"center"} justifyContent={"space-between"} defaultIndex={-1}>
            <Box>
            <Text
              borderBottom={"4px"}
              borderBottomColor={"black"}
              fontSize={{ base: "25px", md: "30px" }}
              fontWeight={700}
            >
              {research_category.MASTERLISTS_TAB_TITLE}
            </Text>
            </Box>
            <TabList gap={"25px"}>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToIntroduction`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INTRODUCTION_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.CHRONOLOGY_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.MASTERLISTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.POSTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INSIGHTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToResearchCentre`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.RESEARCH_CENTRE_TAB_TITLE}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <Stack paddingTop={"20"} flexDirection={"row-reverse"}>
        <FilterButton paddingTop={"8"} onClick={handleFilterClick} />
        <Stack px={{ base: "4", md: "16" }} marginY={{ base: "10", md: "24" }}
        flexDirection={"row"} gap={4} justifyContent="center">
          <HStack >
            <SimpleGrid
              columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
              spacing="24px"
            >
              {masterlistsData.length > 0 &&
                masterlistsData?.map((it, index) => {
                  return (
                    <Stack className="content">
                    <Link to={`/research-categories/${alias}/masterlists/${it?.alias}`}>
                  
                   <Stack className="content-overlay"></Stack>
                   <Box display={"flex"} justifyContent={"center"} width={{base:"100%",md:"287px"}} height={"216px"}>
                  <Image
                      src={it?.image}
                      className="content-image"
                      height="100%"
                      width="auto"
                    /></Box>
                    <Stack className="content-details fadeIn-bottom" alignItems={"center"}>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"700"}
                          textStyle={"scondary"}
                          color="white"
                        >
                          {it?.name}
                        </Text>
                      </Stack>
                      
                  </Link>
                  </Stack>
                  );
                })}
            </SimpleGrid>
          </HStack>
          <HStack
              className="mt-0"
              marginRight={"-15px!important"}
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterArtHeritage" marginRight={"-51px!important"}>
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold as="u" color={"black"} cursor="pointer">
                        {button.CLEAR_ALL}
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {research_category.STUDIO}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {research_category.CLASSIFICATIONS}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {research_category.DATE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                           {research_category.MEDIUM}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                           {research_category.LOCATION}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <AccordionPanel pb={4} className="px-1">
                          <Small color="#035DA1">
                            {common.CONTENT_AWAITED}
                          </Small>
                        </AccordionPanel>
                      </AccordionPanel>
                    </AccordionItem>
                    <HStack marginTop={"10px"}>
                      <Button
                        color={"#ffffff"}
                        width={"199px"}
                        height={"36px"}
                        bg={"#000000"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        variant={"unstyled"}
                      >
                        {explore.APPLY}
                      </Button>
                    </HStack>
                  </Accordion>
                </Box>
              )}
            </HStack>
        </Stack>
        </Stack>
      </Container>
    </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
