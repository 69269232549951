import { Box, Image, SimpleGrid, Text, Stack } from "@chakra-ui/react";

//Core files
import React from "react";

//Language files
import { common } from "../../../constants/constants";

export default function ImageGridlist({ data, border,handleImageClick }) {
  return (
    <>
     {data && <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
        spacing={{ base: "5px", md: "24px" }}
      >
        {data.length > 0 ? (
          data?.map((item, index) => (
            <Box height="auto">
              <Box
                justifyItems="center"
                display="flex"
                height={"270px"}
                bg="white"
                justifyContent="space-around"
              >
                {item.link ? (
                  <Image
                    src={item?.imagePath ? item?.imagePath : item?.image}
                    alt=""
                    cursor={"pointer"}
                    transition="1s ease"
                    width={"auto"}
                    objectFit={"contain"}
                    onClick={()=>handleImageClick(item,index)}
                  />
                ) : (
                  <Image
                    src={item?.image}
                    alt=""
                    transition="1s ease"
                    width={"auto"}
                    objectFit={"contain"}
                  />
                )}
              </Box>
              <Text
                fontSize={"14px"}
                fontWeight={"400px"}
                lineHeight={"17px"}
                textAlign="left"
                color="#035DA1"
                py="4"
              >
                {item?.desc && item?.desc}
              </Text>
            </Box>
          ))
        ) : (
          <Stack alignItems={"center"} height={"100vh"}>
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {common.NO_DATA}
            </Text>
          </Stack>
        )}
      </SimpleGrid>}
    </>
  );
}
