import { bka_abdul_halim_the_lucknow_Omnibus_2001, bka_jindal_my_adventures_with_satyajit_ray_2017, bke_geeta_kapur_subramanyan_1987, bke_mukhopadhyay_pather_panchali_1984, sayed_haider_raza_modern_indian_painting } from "../MockData/BookData";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

export const getResearchCentreBookbyBookAliasAPI = async (tableName, bookAlias) => {
  return [];
};

export const getResearchCentreBookbyBookAlias = async (tableName, bookAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getResearchCentreBookbyBookAliasDummyData(bookAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getResearchCentreBookbyBookAliasAPI(tableName, bookAlias);
    try {
     return response;
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};
export const getResearchCentreBookbyBookAliasDummyData = async (bookAlias) => {
  const bookDataMap = {
    "bka-jindal-my-adventures-with-satyajit-ray-2017": bka_jindal_my_adventures_with_satyajit_ray_2017,
    "bke-a-sayed-haider-raza-modern-indian-painting":sayed_haider_raza_modern_indian_painting,
    "bke-geeta-kapur-subramanyan-1987":bke_geeta_kapur_subramanyan_1987,
    "bke-mukhopadhyay-pather-panchali-1984":bke_mukhopadhyay_pather_panchali_1984,
    "bka-abdul-halim-the-lucknow-Omnibus-2001":bka_abdul_halim_the_lucknow_Omnibus_2001

    // Add more cases as needed
  };
  // Check if the bookAlias exists in the map, if not, use a default value
  const bookData = bookDataMap[bookAlias] || bke_mukhopadhyay_pather_panchali_1984;
  return bookData;
};
