import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react";

// Core files
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";

// Subsection Files
import MobileNav from "../../components/Topheader";
import { common, explore } from "../../constants/constants";
import Preface from "./Introduction/Preface";
import Specifications from "./Introduction/Specifications";
import ReadMore from "./ReadMore";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { isArray } from "lodash";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

const artistLinksData = [
  {
    name: "Satyajit Ray",

  },
  {
    name: "Suresh Jindal",
  },
];
export default function MainIntroduction({ children }) {
  let { alias } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const tableName = "films";
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState({});
  const [filmDetails, setFilmDetails] = useState({});
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        winnerName
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist")?.value}/${
              it.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias("films", winnerName);
        if (result) {
          setName(
            () =>
              result?.find((item) => item?.key === "filmTitleImage")?.value
                .length > 0 &&
              result?.find((item) => item?.key === "filmTitleImage")?.value[0]
                ?.direct_url_of_preview_image
          );
          setAgraphyLink([
            {
              link: `/research-categories/${
                result?.find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`, // replace with the actual link
              title: result?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.abbreviation,
            },
            {
              link: `/categories/indian-cinema`,
              title: result?.find((item) => item?.key === "subject")?.value,
            },
          ]);
          setfilmMainImage(
            {
               logoImage:result?.find((item) => item?.key === "filmMainImage")?.value[0]
                 ?.direct_url_of_preview_image,
                 logoLink:`/${masterlistType}/${alias}/introduction/title-image`
               }
           );
           const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
          setFilmTrailer({
            link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
            display: result?.find((item) => item?.key === "trailer_link")
              .display,
            image: WatchTrailer,
          });
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
    window.scrollTo(0, 0);
  }, [masterlistType, winnerName, alias]);

  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const [hidden, setHidden] = useState(true);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - -64px)"
        winnerName={winnerName}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        hidden={hidden}
        setHidden={setHidden}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            winnerName={winnerName}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            hidden={hidden}
            setHidden={setHidden}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        {introData ? (
          <Introduction
            winnerName={winnerName}
            setReadmoreNavigation={setReadmoreNavigation}
            readmoreNavigation={readmoreNavigation}
            hidden={hidden}
            setHidden={setHidden}
            tableName={tableName}
            name={name}
            masterlistType={masterlistType}
          />
        ) : (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.WEBPAGE_COMING_SOON}
          </Text>
        )}
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  winnerName,
  onClose,
  display,
  readmoreNavigation,
  setReadmoreNavigation,
  hidden,
  setHidden,
  name,
  member,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    if (readmoreNavigation?.isReadMore) {
      setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
      return;
    } else {
      navigate(-1);
    }
  };
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];
  const trailerLink = {
    link:`/${masterlistType}/${winnerName}/watch-film-trailer`,
    image: WatchTrailer,
  };

  const oSections = [
    {
      to:`/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: explore.POSTS,
    },
    {
      to:`/${masterlistType}/${winnerName}/insights`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        hidden={hidden}
        setHidden={setHidden}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        artistLinksData={artistLinksData}
        drawerData={drawerData}
        drawerImage={SKK1}
      />
    </>
  );
};

const Introduction = ({
  winnerName,
  setReadmoreNavigation,
  readmoreNavigation,
  hidden,
  setHidden,
  tableName,
  name,
  masterlistType
}) => {
  const navigate = useNavigate();
  let { tabIndex } = useParams();

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${winnerName}/introduction`, { replace: true });
    }
  }, [tabIndex, navigate, winnerName, masterlistType]);
  const handleReadMoreNavigation = (flag, isOpen, index, bannerFlag, title) => {
    setReadmoreNavigation({
      isReadMore: flag,
      isOpen: isOpen,
      clickedImageIndex: index,
      bannerFlag: bannerFlag,
      title: title,
    });
  };

  return (
    <Stack>
      {readmoreNavigation?.isReadMore ? (
        <ReadMore />
      ) : (
        <Container
          minW={"100%"}
          pb="4"
          paddingX={{ base: "4", md: "0px" }}
          // marginLeft={hidden ? "30px" : "80px"}
          marginLeft={{ base: "0px", md: "30px" }}
        >
          <Stack>
            <Box
              // width={!hidden && "64%"}
              position={"fixed"}
              zIndex={999}
              bg="white"
              width={{ base: "100%", md: "1136px" }}
              height={{ base: "65px", sm: "85px", md: "170px" }}
            >
              <HStack paddingY={{ base: "20px", md: "0px" }}>
                <Box
                  marginRight={"0px!important"}
                  width={{ base: "93%", md: "1136px" }}
                  height={{ base: "100%", md: "160px" }}
                  display={"flex"}
                >
                  <Image
                    src={name}
                    width={"100%"}
                    objectFit={"contain"}
                    height={"auto"}
                  />
                </Box>
              </HStack>
            </Box>
            <HStack>
              <Tabs
                align={{ xs: "end", sm: "" }}
                w="full"
                defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
              >
                <Box
                  position={"fixed"}
                  marginTop={{ base: "55px", sm: "75px", md: "156px" }}
                  zIndex={999}
                  bg="white"
                  width={{ base: "100%", md: "1100px" }}
                >
                  <TabList
                    overflowX="auto"
                    borderBottom={"1px"}
                    borderBottomColor={"#f1f1f1"}
                    gap={12}
                    className="verticalscroll"
                  >
                    <Tab
                      className="Timeline"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                    >
                      {explore.PREFACE}
                    </Tab>
                    <Tab
                      className="Timeline"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                    >
                      {explore.SPECIFICATIONS}
                    </Tab>
                  </TabList>
                </Box>
                <TabPanels
                  marginTop={{ base: "65px", sm: "85px", md: "170px" }}
                >
                  <TabPanel px="0px">
                    <Preface
                      winnerName={winnerName}
                      hidden={hidden}
                      // setReadMoreFlag={setReadMoreFlag}
                      setReadMoreFlag={handleReadMoreNavigation}
                      readmoreNavigation={readmoreNavigation}
                      tableName={tableName}
                    />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Specifications
                      winnerName={winnerName}
                      hidden={hidden}
                      tableName={tableName}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          </Stack>
        </Container>
      )}
    </Stack>
  );
};
