//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const song1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/1.png`
const song2 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/2.png`
const song3 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/3.png`
const song4 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/4.png`
const song5 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/5.png`
const song6 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/6.png`
const song7 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/7.png`
const song8 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/8.png`
const song9 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/9.png`
const song10 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/10.png`
const song11 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/11.png`
const song12 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/12.png`
const song13 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/13.png`
const song14 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/14.png`
const song15 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/15.png`
const song16 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/16.png`
const song17 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/17.png`
const song18 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/18.png`
const song19 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/19.png`
const song20 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/20.png`
const song21 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/21.png`
const song22 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/22.png`
const song23 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/23.png`
const song24 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/24.png`
const song25 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/25.png`
const song26 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/26.png`
const song27 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/27.png`
const song28 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/28.png`
const song29 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/29.png`
const song30 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/30.png`
const song31 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/31.png`
const song32 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/32.png`
const song33 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/33.png`
const song34 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/34.png`
const song35 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/35.png`
const song36 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/36.png`
const song37 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/37.png`
const song38 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/38.png`
const song39 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/39.png`
const song40 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/40.png`
const song41 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/41.png`
const song42 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/42.png`
const song43 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/43.png`
const song44 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/44.png`
const song45 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/45.png`
const song46 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/46.png`
const song47 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/47.png`
const song48 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/48.png`
const song49 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/49.png`
const song50 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/50.png`
const song51 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/51.png`
const song52 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/52.png`
const song53 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/53.png`
const song54 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/54.png`
const song55 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/55.png`
const song56 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/56.png`

//silsila images
const silsila1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/1.png`
const silsila2 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/2.png`
const silsila3 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/3.png`
const silsila4 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/4.png`
const silsila5 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/5.png`
const silsila6 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/6.png`
const silsila7 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/7.png`
const silsila8 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/8.png`
const silsila9 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/9.png`
const silsila10 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/10.png`
const silsila11 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/11.png`
const silsila12 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Song/12.png`

const booklet1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/PatherPanchali_01.png`
const placeholder = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/ImageAwaited.png`


//body of work - Songs Synopsis Booklet.
export const songs_synopsis_booklets = [
  {
    image: song1,
    sortable_year_date_of_publication: "1931",
    book_title_of_document: "Daulat Ka Nasha",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song2,
    sortable_year_date_of_publication: "1932",
    book_title_of_document: "Ayodhaya Ka Raja",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song3,
    sortable_year_date_of_publication: "1934",
    book_title_of_document: "Indira M. A.",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song4,
    sortable_year_date_of_publication: "1934",
    book_title_of_document: "Majdoor",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song5,
    sortable_year_date_of_publication: "1935",
    book_title_of_document: "Devdas",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song6,
    sortable_year_date_of_publication: "1935",
    book_title_of_document: "Dr. Madhurika",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song7,
    sortable_year_date_of_publication: "1935",
    book_title_of_document: "Inqilab",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song8,
    sortable_year_date_of_publication: "1936",
    book_title_of_document: "Madam Fashion",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song9,
    sortable_year_date_of_publication: "1937",
    book_title_of_document: "Gangavataran",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song10,
    sortable_year_date_of_publication: "1939",
    book_title_of_document: "Swastik",
    document_type: "ssb",
    link: false,
    alias: "",
  },
 
  {
    image: song11,
    sortable_year_date_of_publication: "1940",
    book_title_of_document: "Achhut",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song12,
    sortable_year_date_of_publication: "1940",
    book_title_of_document: "Main Haari",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song13,
    sortable_year_date_of_publication: "1941",
    book_title_of_document: "Jhoola",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song14,
    sortable_year_date_of_publication: "1941",
    book_title_of_document: "Raj Nartaki",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song15,
    sortable_year_date_of_publication: "1941",
    book_title_of_document: "Sikandar",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song16,
    sortable_year_date_of_publication: "1941",
    book_title_of_document: "Padosi",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song17,
    sortable_year_date_of_publication: "1943",
    book_title_of_document: "Adab Arz",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song18,
    sortable_year_date_of_publication: "1948",
    book_title_of_document: "Anjangarh",
    document_type: "ssb",
    link: true,
    alias: "",
  },
  {
    image: song19,
    sortable_year_date_of_publication: "1948",
    book_title_of_document: "Gopinath",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song20,
    sortable_year_date_of_publication: "1948",
    book_title_of_document: "Kalpana",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song21,
    sortable_year_date_of_publication: "1949",
    book_title_of_document: "Barsaat",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song22,
    sortable_year_date_of_publication: "1949",
    book_title_of_document: "Sunhere Din",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song23,
    sortable_year_date_of_publication: "1950",
    book_title_of_document: "Afsar",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song24,
    sortable_year_date_of_publication: "1950",
    book_title_of_document: "Dastan",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song25,
    sortable_year_date_of_publication: "1951",
    book_title_of_document: "Sazaa",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song26,
    sortable_year_date_of_publication: "1952",
    book_title_of_document: "Anhonee",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song27,
    sortable_year_date_of_publication: "1952",
    book_title_of_document: "Sinbad Jahaaji",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song28,
    sortable_year_date_of_publication: "1953",
    book_title_of_document: "Do Bigha Zamin",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song29,
    sortable_year_date_of_publication: "1954",
    book_title_of_document: "Nagin",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song30,
    sortable_year_date_of_publication: "1955",
    book_title_of_document: "Jhanak Jhanak Payal Baaje",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song31,
    sortable_year_date_of_publication: "1959",
    book_title_of_document: "Duniya Na Mane",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song32,
    sortable_year_date_of_publication: "1959",
    book_title_of_document: "Main Nashe Men Hoon",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song33,
    sortable_year_date_of_publication: "1960",
    book_title_of_document: "Bambai Ki Billi",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song34,
    sortable_year_date_of_publication: "1960",
    book_title_of_document: "Chhalia",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song35,
    sortable_year_date_of_publication: "1960",
    book_title_of_document: "Girl Friend",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song36,
    sortable_year_date_of_publication: "1961",
    book_title_of_document: "Razia Sultana",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song37,
    sortable_year_date_of_publication: "1964",
    book_title_of_document: "Leader",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song38,
    sortable_year_date_of_publication: "1964",
    book_title_of_document: "Sanjh Aur Savera",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song39,
    sortable_year_date_of_publication: "1968",
    book_title_of_document: "Sunghursh",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song40,
    sortable_year_date_of_publication: "1969",
    book_title_of_document: "Itteffaq",
    document_type: "ssb",
    link: false,
    alias: "",
  }, {
    image: song41,
    sortable_year_date_of_publication: "1969",
    book_title_of_document: "Shatranj",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song42,
    sortable_year_date_of_publication: "1970",
    book_title_of_document: "Heer Raanjha",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song43,
    sortable_year_date_of_publication: "1970",
    book_title_of_document: "Mera Naam Joker",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song44,
    sortable_year_date_of_publication: "1970",
    book_title_of_document: "Mera Naam Joker",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song45,
    sortable_year_date_of_publication: "1971",
    book_title_of_document: "Pakeezah",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song46,
    sortable_year_date_of_publication: "1973",
    book_title_of_document: "Abhimaan",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song47,
    sortable_year_date_of_publication: "1973",
    book_title_of_document: "Bobby",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song48,
    sortable_year_date_of_publication: "1973",
    book_title_of_document: "Zanjeer",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song49,
    sortable_year_date_of_publication: "1973",
    book_title_of_document: "Phagun",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song50,
    sortable_year_date_of_publication: "1975",
    book_title_of_document: "Deewaar",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song51,
    sortable_year_date_of_publication: "1975",
    book_title_of_document: "Pratiggya",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song52,
    sortable_year_date_of_publication: "1978",
    book_title_of_document: "Don",
    document_type: "ssb",
    link: false,
    alias: "",
  },{
    image: song53,
    sortable_year_date_of_publication: "1981",
    book_title_of_document: "Silsila",
    document_type: "ssb",
    link: true,
    alias: "silsila",
  },{
    image: song54,
    sortable_year_date_of_publication: "1981",
    book_title_of_document: "Naseeb",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song55,
    sortable_year_date_of_publication: "1989",
    book_title_of_document: "Chandni",
    document_type: "ssb",
    link: false,
    alias: "",
  },
  {
    image: song56,
    sortable_year_date_of_publication: "1993",
    book_title_of_document: "Darr",
    document_type: "ssb",
    link: false,
    alias: "",
  },
];

//body of work=> Silsila - Songs Synopsis Booklet.

export const silsila_song_booklets =[
    {
        imgPath:silsila1
    },
    {
        imgPath:silsila2
    },
    {
        imgPath:silsila3
    },
    {
        imgPath:silsila4
    },
    {
        imgPath:silsila5
    },
    {
        imgPath:silsila6
    },
    {
        imgPath:silsila7
    },
    {
        imgPath:silsila8
    },
    {
        imgPath:silsila9
    },
    {
        imgPath:silsila10
    },
    {
        imgPath:silsila11
    },
    {
        imgPath:silsila12
    }
]

export const stayajit_ray_song_booklets =[
  {
    id: 1,
    image: booklet1,
    link: true,
  },
  {
    id: 2,
    image: placeholder,
  },
  {
    id: 3,
    image: placeholder,
  },
  {
    id: 4,
    image: placeholder,
  },
]
export const raj_kapoor_song_booklets =[
  {
    id: 1,
    image: placeholder,
    link: true,
  },
  {
    id: 2,
    image: placeholder,
  },
  {
    id: 3,
    image: placeholder,
  },
  {
    id: 4,
    image: placeholder,
  },
]