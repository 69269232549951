import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Link, useParams } from "react-router-dom";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import { getListView_data } from "../../../services/Exhibitions_data";

//Component file
import Small from "../../../components/layouts/Typography/Small";
import ScrollToTop from "../../../components/sections/ScrollToTop";
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import { FilterButton } from "../../../CommonComponents/FilterButton";

//Language file
import { button, common, explore, filterAttribute } from "../../../constants/constants";

function TabularView({ data }) {
  const btnRef = React.useRef();
  const [showFilterBox, setShowFilterBox] = useState(false);
  const {alias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const [listViewData, setListViewData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const result = await getListView_data();
      if (result) {
        setListViewData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [aliasAPI]);
  return (
    <>
      <Stack flexDirection={"row-reverse"} justifyContent={"space-between"}>
        <FilterButton onClick={handleFilterClick} />
        <Container maxW="auto" px="0" py={"6"} className="songs">
          <Stack
            flexDirection={"row"}
            py="2"
            mt={"2.5"}
            justifyContent="space-between"
          >
            <HStack
              flexDirection={"column"}
              width={{ base: "100%", md: "870px" }}
              height="100%"
              px="5"
            >
              <VerticalTimeline layout={"1-column-left"} className="layout">
                {listViewData &&
                  listViewData.map((it, index) => {
                    return (
                      <VerticalTimelineElement
                        key={1}
                        id="editionTimeline"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                          background: "#fff",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                        icon={it.year}
                      >
                        <Link
                         to={`/${masterlistType}/${aliasAPI}/body-of-work/timeline`}
                          cursor={"pointer"}
                        >
                          <Small
                            ref={btnRef}
                            cursor={"pointer"}
                            color="#035DA1"
                            pb={{ base: "2", md: "0" }}
                          >
                            {it.author} | {it.journal}. {it.location}:{" "}
                            {it.gallery}, {it.year}.
                          </Small>
                        </Link>
                      </VerticalTimelineElement>
                    );
                  })}
              </VerticalTimeline>
            </HStack>
            <ScrollToTop />

            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterArtHeritage">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold
                        as="u"
                        color={"black"}
                        cursor="pointer"
                      >
                        {button.CLEAR_ALL}
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {explore.FILTER_YEAR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Flex>
                          <Checkbox
                            borderColor={"black"}
                            _checked={{
                              "& .chakra-checkbox__control": {
                                background: "black",
                              },
                            }}
                            iconColor="white"
                            cursor="pointer"
                            marginRight={"5px"}
                          />
                          <Box p="1">
                            <HStack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                textStyle={"secondary"}
                              >
                                1979
                              </Text>
                            </HStack>
                          </Box>
                        </Flex>
                        <Flex>
                          <Checkbox
                            borderColor={"black"}
                            _checked={{
                              "& .chakra-checkbox__control": {
                                background: "black",
                              },
                            }}
                            iconColor="white"
                            cursor="pointer"
                            marginRight={"5px"}
                          />
                          <Box p="1">
                            <HStack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                textStyle={"secondary"}
                              >
                                1980
                              </Text>
                            </HStack>
                          </Box>
                        </Flex>
                        <Flex>
                          <Checkbox
                            borderColor={"black"}
                            _checked={{
                              "& .chakra-checkbox__control": {
                                background: "black",
                              },
                            }}
                            iconColor="white"
                            cursor="pointer"
                            marginRight={"5px"}
                          />
                          <Box p="1">
                            <HStack>
                              <Text
                                fontWeight={"500"}
                                fontSize={"14px"}
                                textStyle={"secondary"}
                              >
                                1981
                              </Text>
                            </HStack>
                          </Box>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                    </AccordionItem>
                    <HStack marginTop={"10px"}>
                      <Button
                        color={"#ffffff"}
                        onClick={() => {
                        }}
                        width={"199px"}
                        height={"36px"}
                        bg={"#000000"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        variant={"unstyled"}
                      >
                        {explore.APPLY}
                      </Button>
                    </HStack>
                  </Accordion>
                </Box>
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
      <ScrollToTop />
    </>
  );
}
export default TabularView;
