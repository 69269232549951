import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  DrawerOverlay,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import MobileNav from "../../components/Topheader";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language file
import { explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`

export default function ArtHeritageGallery({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "journal",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "journal_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        zIndex={"99999"}
        height="calc(100vh - 64px)"
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        introData={introData}
        masterlistType={masterlistType}
        name={name}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerOverlay maxW={"1400px"} />
        <DrawerContent>
          <SidebarContent 
           onClose={onClose}
           sections={introData}
           hMenu={horislinkData}
           journalDetails={journalDetails}
           />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <ArtHeritageGalleryBody />
      </Box>
    </Box>
  );
}
const SidebarContent = ({ 
  onClose,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType,  
  ...rest }) => {
  const drawerArtHeritage = [{
title:"Ebrahim Alkazi", name: "Art Heritage Annual & Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Webpage coming soon",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const [drawerName, setDrawerName] = useState("")

   const artistLinksData = [{
  name: "Ebrahim Alkazi",
 
},{
    name: "Art Heritage Gallery",
}

];;
  return <>
   <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        artistLinksData={artistLinksData}
        setDrawerName={setDrawerName}
        drawerName={drawerName}     
        handleNavigation={handleNavigation}
        drawerData={drawerArtHeritage}
      />
  {/* <LeftSideBar onClose={onClose} /> */}
  </>;
};
const ArtHeritageGalleryBody = () => {
  return (
    <Stack height={"100vh"} pb={4}>
      <Stack marginTop={"32px"}>
       <WebpageComingSoon />
      </Stack>
    </Stack>
  );
};
