//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Facebook = `${IMAGE_BASE_PATH}/ui/Chevron/facebook.png`
const Instagram = `${IMAGE_BASE_PATH}/ui/Chevron/instagram.png`
const Linkedin = `${IMAGE_BASE_PATH}/ui/Chevron/linkedin.png`
const Youtube = `${IMAGE_BASE_PATH}/ui/Chevron/youtube.png`

export const SocialMediaData = [
  {
    id: 1,
    name: "Facebook",
    image: Facebook,
    link: "",
  },
  {
    id: 2,
    name: "Instagram",
    image: Instagram,
    link: "",
  },
  {
    id: 3,
    name: "Linkedin",
    image: Linkedin,
    link: "",
  },
  {
    id: 4,
    name: "Youtube",
    image: Youtube,
    link: "",
  },
];
