import React from "react";
import DrawerSelfIntro from "./DrawerSelfIntro";
import DrawerFamilyDetails from "./DrawerFamilyDetails";
import DrawerModalDetails from "./DrawerModalDetails";

export default function PersonaliaDrawerBody({
  intro,
  showFamilyMemberImages,
  familyImgData,
  educationImgData,
  showFullImages,
  familyFullScreenImage,
  closeIcon,
  personaliaData,
  showEducationImages,
  educationFullScreenImage,
  data,
  teachingData,
  personaliaDrawer
}) {
  return (
    <>
      <DrawerSelfIntro
        intro={intro}
        personaliaData={personaliaData}
        showFamilyMemberImages={showFamilyMemberImages}
        data={data}
        showEducationImages={showEducationImages}
        teachingData={teachingData}
        personaliaDrawer={personaliaDrawer}
      />
      {/* Left image modal opens  */}
      <DrawerFamilyDetails
        familyImgData={familyImgData}
        educationImgData={educationImgData}
        showFamilyMemberImages={showFamilyMemberImages}
        showEducationImages={showEducationImages}
        showFullImages={showFullImages}
        closeIcon={closeIcon}
      />
      {/* FullScreenImage view */}
      <DrawerModalDetails
        familyImgData={familyImgData}
        educationImgData={educationImgData}
        showFullImages={showFullImages}
        familyFullScreenImage={familyFullScreenImage}

      />
    </>
  );
}
