import axios from "axios";
import { transformJSON } from "../util/transformer";
import { mughal_awardsData, raj_kapoor_awardsData, satyajit_awardsData, skk_awardsData } from "../MockData/AwardMockup";
import { schema } from "./schemas/AwardsHonours";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const awardshonoursByWinnerAPI = async (tableName, winner) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery($where: AwardsWhere) {
            ` +
      tableName +
      `(where: $where) {
        Exhibition
        award_name
        id
        issuing_authority
        sortable_date
        type
        winner
        awardcategory {
          name
          alias
        }
        awardImage {
          wnn
          accession_number
          alias
          name_of_publication
          volume_number
          publication_year
          sortable_year_of_publication
          artist_name
          date_of_artwork
          medium_of_artwork
          revised_medium
          id
          image
          height_cm
          width_cm
          title
          direct_url_of_preview_image
          medium
        }
      }
    }`,
    variables: {
      where: {
        winner_IN: winner,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);

  if (result?.data?.data?.awards) {
    return result?.data?.data?.awards;
  } else {
    return [];
  }
};

export const awardshonoursByWinner = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response=awardshonoursByWinnerdummydata(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await awardshonoursByWinnerAPI(tableName, masterlist);
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const awardshonoursByWinnerdummydata=(winner)=>{
  // switch (winner) {
  //   case "satyajit-ray":
  //       return satyajit_awardsData;
  //   case "shatranj-ke-khilari-1977":
  //       return skk_awardsData;
  //   default:
  //       // Handle the default case if needed
  //       return satyajit_awardsData;
  // }
  const awardsHonoursDataMap = {
    "satyajit-ray": satyajit_awardsData,
    "shatranj-ke-khilari-1977": skk_awardsData,
    "art-heritage-annual": satyajit_awardsData,
    "mughal-e-azam": mughal_awardsData,
    "sayed-haider-raza":satyajit_awardsData,
    "raj-kapoor": raj_kapoor_awardsData,
  }
  const mockData= awardsHonoursDataMap[winner];
  return mockData;
}

// Mughal-E-Azam Awards Data
export const getAwardsData = () => {
  const data = [
    {
      sortable_date: "1977",
      desc: "National Film Awards | Best Feature Film in Hindi | Directorate of Film Festivals",
      image:false
    },
    {
      sortable_date: "1978",
      desc: "Filmfare Awards: Critics Award for Best Movie. Best Director",
      image:false
    },
    {
      sortable_date: "1979",
      desc: "Filmfare Awards | Best Director | Filmfare",
      image:true
    },
    {
      sortable_date: "1979",
      desc: "Filmfare Awards | Best Supporting Actor | Filmfare",
      image:false
    },
  ];
  return data;
};
