export const schema = [
  { key: "id", display: "Id", seq: 1 },
  { key: "trailer_link", display: "Trailer link", seq: 1 },
  { key: "color", display: "Color", seq: 1 },
  { key: "shooting_period", display: "Shooting period", seq: 1 },
  { key: "full_movie_link", display: "Full movie link", seq: 1 },
  { key: "color_type", display: "Color type", seq: 1 },
  { key: "lab", display: "Lab", seq: 1 },
  { key: "length_of_reels", display: "Length of reels", seq: 1 },
  { key: "certificate_number", display: "Certificate number", seq: 1 },
  { key: "country_of_origin_of_film", display: "Country of origin", seq: 1 },
  { key: "number_of_reels", display: "Number of reels", seq: 1 },
  { key: "alternative_title", display: "Alternative title", seq: 1 },
  { key: "date_of_issue", display: "Date of issue", seq: 1 },
  { key: "film_alias", display: "Film alias", seq: 1 },
  { key: "alias", display: "Alias", seq: 1 },
  { key: "title_in_other_languages", display: "Title in other languages", seq: 1 },
  { key: "title_in_original_language", display: "Title in original language", seq: 1 },
  { key: "gauge", display: "Gauge", seq: 1 },
  { key: "sub_title", display: "Sub title", seq: 1 },
  { key: "film_type", display: "Film type", seq: 1 },
  { key: "budget_in_rupees", display: "Budget in rupees", seq: 1 },
  { key: "synopsis", display: "Synopsis", seq: 1 },
  { key: "certification", display: "Certification", seq: 1 },
  { key: "year_of_film", display: "Year of film", seq: 1 },
  { key: "duration_mins", display: "Duration", seq: 1 },
  { key: "date_of_expiry", display: "Date of expiry", seq: 1 },
  { key: "release_date", display: "Release date", seq: 1 },
  { key: "film_sub_type", display: "Film sub type", seq: 1 },
  { key: "film_length", display: "Film length", seq: 1 },
  { key: "region", display: "Region", seq: 1 },
  { key: "main_title", display: "Main title", seq: 1 },
  { key: "masterlist", display: "masterlist", seq: 1 },
  { key: "language", display: "Language", seq: 1 },
  { key: "banner", display: "Banner", seq: 1 },
  { key: "filmProducer", display: "Film Producer", seq: 1 },
  { key: "filmDirector", display: "Film Director", seq: 1 },
  { key: "scriptWriter", display: "Script", seq: 1 },
  { key: "screenPlay", display: "Screenplay", seq: 1 },
  { key: "storyWriter", display: "Story", seq: 1 },
  { key: "dialogueWriter", display: "Dialogue", seq: 1 },
  { key: "musicDirector", display: "Music Director", seq: 1 },
  { key: "playbackSinger", display: "Playback Singer", seq: 1 },
  { key: "lyricist", display: "Lyricist", seq: 1 },
  { key: "editor", display: "Editor", seq: 1 },
  { key: "cinematographer", display: "Cinematographer", seq: 1 },
  { key: "artDirector", display: "Art Director", seq: 1 },
  { key: "stillPhotographer", display: "Still Photographer", seq: 1 },
  { key: "posterDesigner", display: "Poster Designer", seq: 1 },
  { key: "materialDesigner", display: "Material Designer", seq: 1 },
  { key: "narrator", display: "Narrator", seq: 1 },
  { key: "artist", display: "Artist", seq: 1 },
  { key: "poet", display: "Poet", seq: 1 },
  { key: "commentryWriter", display: "Commentry Writer", seq: 1 },
  { key: "translator", display: "Translator", seq: 1 },
  { key: "makeUpArtist", display: "Make Up Artist", seq: 1 },
  { key: "assistantMakeUpArtist", display: "Assistant Make Up Artist", seq: 1 },
  { key: "costumeDesigner", display: "Costume Designer", seq: 1 },
  { key: "choreographer", display: "Choreographer", seq: 1 },
  { key: "fightDirector", display: "Fight Director", seq: 1 },
  { key: "assistantMusician", display: "Assistant Musician", seq: 1 },
  { key: "soundRecorder", display: "Sound Recorder", seq: 1 },
  { key: "soundDesigner", display: "Sound Designer", seq: 1 },
  { key: "soundMixer", display: "Sound Mixer", seq: 1 },
  { key: "soundEditor", display: "Sound Editor", seq: 1 },
  { key: "associateEditor", display: "Associate Editor", seq: 1 },
  { key: "assistantEditor", display: "Assistant Editor", seq: 1 },
  { key: "associateCameraman", display: "Associate Cameraman", seq: 1 },
  { key: "assistantCameraman", display: "Assistant Cameraman", seq: 1 },
  { key: "coProducer", display: "Co Producer", seq: 1 },
  { key: "productionExecuter", display: "Production Executer", seq: 1 },
  { key: "productionSecretary", display: "Production Secretary", seq: 1 },
  { key: "associateProducer", display: "Associate Producer", seq: 1 },
  { key: "productionDesigner", display: "Production Designer", seq: 1 },
  { key: "supervisingProducer", display: "Supervising Producer", seq: 1 },
  { key: "lineProducer", display: "Line Producer", seq: 1 },
  { key: "productionInCharge", display: "Production In Charge", seq: 1 },
  { key: "consultingProducer", display: "Consulting Producer", seq: 1 },
  { key: "executiveProducer", display: "Executive Producer", seq: 1 },
  { key: "associateDirector", display: "Associate Director", seq: 1 },
  { key: "assistantDirector", display: "Assistant Director", seq: 1 },
  { key: "executiveDirector", display: "Executive Director", seq: 1 },
  { key: "location", display: "Location", seq: 1 },
  { key: "studios", display: "Studios", seq: 1 },
  { key: "posterImages", display: "Poster images", processor: "imageAliasArray", seq: 1 },
  { key: "filmInfo", display:"filmInfo", seq: 1}
];
