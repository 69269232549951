import {
  Box,
  CloseButton,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useRef, useState } from "react";
import { MdFullscreen } from "react-icons/md";

//Service file
import * as getSongsSynopsisImages from "../../../../services/Booklet";

//Component file
import HTMLFlipBook from "react-pageflip";
import H2 from "../../../../components/layouts/Typography/H2";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const right = `${IMAGE_BASE_PATH}/ui/Chevron/right.png`
const left = `${IMAGE_BASE_PATH}/ui/Chevron/left.png`

export default function SongsBook({ songName }) {
  const [songsImages, setSongsImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0); // State to keep track of current page
  const [fullScreenView, setFullScreenView] = useState(false);
  const handlePrevPage = () => {
    if (book.current) {
      book.current.pageFlip().flipPrev();
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleNextPage = () => {
    if (book.current) {
      book.current.pageFlip().flipNext();
      setCurrentPage((prevPage) => prevPage + 1);
      // Hide cover page when flipping to previous page
    }
  };
  useEffect(() => {
    const fetchSongsImages = async () => {
      try {
        const result = await getSongsSynopsisImages.getSongsImages(
          "book",
          songName
        );
        if (result?.length > 0) {
          setSongsImages(result);
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (error) {
        console.error("Error fetching songs images:", error);
        setLoading(true);
      }
    };
    fetchSongsImages();
  }, [songName]);
  const book = useRef();
  return (
    <>
      {!loading && (
        <>
          <Box
            width={"806px"}
            height={"520px"}
            margin={(currentPage !== 0 && currentPage !== songsImages.length/2) ? "auto":"none"}
            marginLeft={currentPage === 0 ? "-70px" : currentPage === songsImages.length/2 && "350px !important"}
          >
            <HTMLFlipBook
              width={402}
              height={520}
              ref={book}
              maxShadowOpacity={0.5}
              showCover={true}
              useMouseEvents={false}
              mobileScrollSupport={true}
              className="demo-book"
              onPageChange={(e) => setCurrentPage(e.data.pageNumber)}
            >
              {songsImages.map((item, index) => (
                <div key={index} className="demoPage">
                  <Image
                    src={item.imgPath ? item.imgPath : item}
                    cursor="pointer"
                    width="auto"
                    height="520px"
                  />
                </div>
              ))}
            </HTMLFlipBook>
          </Box>
          <HStack id="allsliderahj" justifyContent={"space-between"} pt={4}>
            <Box
              pos={"relative"}
              top={"-290px"}
              left={currentPage === songsImages.length/2 ? "29%" : "9%"}
              cursor={"pointer"}
              onClick={handlePrevPage}
              pointerEvents={currentPage === 0 ? "none" : "auto"}
              opacity={currentPage === 0 ? 0 : 1}
            >
              <Image src={left} />
            </Box>
            <Box
              pos={"relative"}
              top={"-290px"}
              right={currentPage === 0 ? "29%" : "9%"}
              cursor={"pointer"}
              onClick={handleNextPage}
              pointerEvents={
                currentPage === songsImages.length - 1 ? "none" : "auto"
              }
              opacity={currentPage === songsImages.length / 2 ? 0 : 1}
            >
              <Image src={right} />
            </Box>
            
          </HStack>
          <HStack justifyContent={"center"} onClick={() => setFullScreenView(true)} cursor={"pointer"}>
              <MdFullscreen size={32} />
            </HStack>
          <HStack flexDirection={"column"} alignItems={"start"}>
            <Box>
              <H2>Overview</H2>
            </Box>
            <Box>
              <Text>
                {
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                }
              </Text>
            </Box>
          </HStack>
        </>
      )}
      {fullScreenView && (
        <FullScreenView
          isOpen={fullScreenView}
          onClose={() => setFullScreenView(false)}
          songsImages={songsImages}
        />
      )}
    </>
  );
}
export const FullScreenView = ({ isOpen, onClose, songsImages }) => {
  const [currentPage, setCurrentPage] = useState(0); // State to keep track of current page
  const book = useRef();

  const handlePrevPage = () => {
    if (book.current) {
      book.current.pageFlip().flipPrev();
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleNextPage = () => {
    if (book.current) {
      book.current.pageFlip().flipNext();
      setCurrentPage((prevPage) => prevPage + 1);
      // Hide cover page when flipping to previous page
    }
  };
  return (
    <>
      <Container maxW={"auto"} px="0px">
        <Modal
          size={"full"}
          isCentered
          isOpen={isOpen}
          onClick={onClose}
          Id="modalfullscreen"
          motionPreset="none"
        >
          <CloseButton
            size={"sm"}
            pos={"absolute"}
            top={"12%"}
            left={"auto"}
            right={"10%"}
            color={"white"}
            borderRadius={"50%"}
            bg={"#333333"}
            zIndex={99999}
            onClick={onClose}
          />
          <ModalOverlay />
          <ModalContent zIndex={"999"}>
            <ModalBody margin={{ base: "0", md: "16" }} overflow={"hidden"}>
              <Box
                width={"950px"}
                height={"620px"}
                margin={(currentPage !== 0 && currentPage !== songsImages.length/2) ? "auto":"none"}
                marginLeft={currentPage === 0 ? "0px" : currentPage === songsImages.length/2 && "450px !important"}
              >
                <HTMLFlipBook
                  width={475}
                  height={620}
                  ref={book}
                  maxShadowOpacity={0.5}
                  showCover={true}
                  useMouseEvents={false}
                  mobileScrollSupport={true}
                  className="demo-book"
                  onPageChange={(e) => setCurrentPage(e.data.pageNumber)}
                >
                  {songsImages.map((item, index) => (
                    <div key={index} className="demoPage">
                      <Image
                        src={item.imgPath ? item.imgPath : item}
                        cursor="pointer"
                        width="auto"
                        height="620px"
                      />
                    </div>
                  ))}
                </HTMLFlipBook>
              </Box>
              <HStack justifyContent={"center"} pt={4}>
                <Box
                  pos={"absolute"}
                  top={"350px"}
                  left={currentPage === songsImages.length/2 ? "30%" : "18%"}
                  cursor={"pointer"}
                  onClick={handlePrevPage}
                  pointerEvents={currentPage === 0 ? "none" : "auto"}
                  opacity={currentPage === 0 ? 0 : 1}
                >
                  <Image src={left} />
                </Box>
                <Box
                  pos={"absolute"}
                  top={"350px"}
                  right={currentPage === 0 ? "32%" : "18%"}
                  cursor={"pointer"}
                  onClick={handleNextPage}
                  pointerEvents={
                    currentPage === songsImages.length - 1 ? "none" : "auto"
                  }
                  opacity={currentPage === songsImages.length / 2 ? 0 : 1}
                >
                  <Image src={right} />
                </Box>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};
