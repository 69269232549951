import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles.css";

// import required modules
import { Navigation, Pagination } from "swiper";
import {
  AspectRatio,
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Small from "../layouts/Typography/Small";
import H5 from "../layouts/Typography/H5";
import { useTranslation } from "react-i18next";
import { common } from "../../constants/constants";

export default function App() {
  const [songs, setSongs] = React.useState([]);
  const navigate = useNavigate();
  const [url, setUrl] = React.useState();
  const { t } = useTranslation("");

  React.useEffect(() => {
    const getData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/rest/songs`
      );
      if (result?.data?.songs) {
        const dataCategories = result?.data?.songs.slice(0);
        setSongs(dataCategories);
      }
    };
    getData();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);

  return (
    <Stack width={"100%"} position={"relative"} id="allslider">
      {songs.length === 0 ? (
        <Text py={"20"} fontSize={"20px"} fontWeight="600" textAlign="center">
          {t(common.WEBPAGE_COMING_SOON)}
        </Text>
      ) : (
        <Container maxW="auto" className="songs">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: true,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper"
            bg="white"
          >
            {songs.map((item, index) => (
              <SwiperSlide key={index}>
                <HStack>
                  <Box
                    onClick={() => navigate("/songpage/" + item.id)}
                    // onClick={() => setUrl(item?.youtube_link)}
                    cursor={"pointer"}
                    border={"1px"}
                    // borderTopLeftRadius={"10px"}
                    // borderTopRightRadius={"10px"}
                    borderColor={"gray.100"}
                    bg={"gray.50"}
                    boxShadow={"sm"}
                    h={"100%"}
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.00)",
                    }}
                  >
                    <Box className="ds-sm-img">
                      <Image
                        boxSize="auto"
                        borderTopLeftRadius={"10px"}
                        borderTopRightRadius={"10px"}
                        w="100%"
                        height={"auto"}
                        objectPosition="center"
                        src={
                          item?.image?.croppedImage
                            ? "https://d3fsixjuo450s3.cloudfront.net/" +
                              item?.image?.croppedImage
                            : "https://asvs.in/wp-content/uploads/2017/08/dummy.png"
                        }
                        alt=""
                      />
                    </Box>
                    <Stack bg={"white"} className="min-max-height" p={4}>
                      <H5 color={"black"}>{item?.name}</H5>
                      <Small color={"black"}>{item?.song_code}</Small>
                      <Text
                        fontSize={"12px"}
                        fontWeight={"600"}
                        color={"black"}
                      >
                        {item?.film_id.name}
                      </Text>
                      <Small fontSize={"12px"} color={"black"}>
                        {new Date(item?.date).getFullYear()}
                      </Small>
                    </Stack>
                  </Box>
                </HStack>
              </SwiperSlide>
            ))}
          </Swiper>
          <Box>
            <Modal size={"3xl"} isOpen={url} onClose={() => setUrl()}>
              <ModalOverlay />
              <ModalContent alignContent={"center"}>
                <Box h={"auto"} bg={"white"} m={4} pos={"relative"}>
                  <AspectRatio w="100%" ratio={16 / 9}>
                    <iframe
                      title="All Songs"
                      src={
                        url
                          ? url.replace("watch?v=", "embed/") + "?autoplay=1"
                          : "https://www.youtube.com/embed/w_BmHDD8rSM"
                      }
                      allowFullScreen
                    />
                  </AspectRatio>
                </Box>
              </ModalContent>
            </Modal>
          </Box>
        </Container>
      )}
    </Stack>
  );
}
