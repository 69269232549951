import {
  IconButton,
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { useNavigate, useParams } from "react-router-dom";

//Services file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component files
import MobileNav from "../../components/Topheader";
import ScrollToTop from "../../components/sections/ScrollToTop";
import TabularView from "./BodyOfWork/Filmography/TabularView";
import GridView  from "./BodyOfWork/Filmography/GridView";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import SynopsisBooklet from "./BodyOfWork/SynopsisBooklet/SynopsisBooklet";
import BookletSongs from "./BodyOfWork/SynopsisBooklet/BookletSongs";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
//Language files
import { explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const printer = `${IMAGE_BASE_PATH}/ui/Chevron/printer.svg`

export default function BodyofWork({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bookletSong, setBookletSong] = React.useState(false);
  const [flag, setFlag] = useState(0);
  const {alias}=useParams();
  const masterlist = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        masterlist
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        masterlist
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }`,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    window.scrollTo(0, 0);
  }, [masterlistType, masterlist]);
  
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        bookletSong={bookletSong}
        setBookletSong={setBookletSong}
        flag={flag}
        setFlag={setFlag}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        alias={alias}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            bookletSong={bookletSong}
            setBookletSong={setBookletSong}
            flag={flag}
            setFlag={setFlag}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <BodyOfWorkBody
          bookletSong={bookletSong}
          flag={flag}
          setBookletSong={setBookletSong}
          masterlist={masterlist}
          masterlistType={masterlistType}
          aliasAPI={alias}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  bookletSong,
  setBookletSong,
  flag,
  setFlag,
  horislinkData,
  artistDetails,
  introData,
  alias,
  masterlistType,
  ...rest
}) => {
  const oSections = [
    {
      to: `/${masterlistType}/${alias}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${alias}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];

  const navigate = useNavigate();
  const handleNavigation = () => {
    if (bookletSong) {
      setFlag((prev) => prev + 1);
      setBookletSong(false);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
      sections={introData}
      oSections={oSections}
      hMenu={horislinkData}
      artistDetails={artistDetails}
      handleNavigation={handleNavigation}
      bookletSong={bookletSong}
      leftFlag={true}
    />
    </>
  );
};

const BodyOfWorkBody = ({ 
  bookletSong, 
  setBookletSong, 
  flag, 
  masterlist, 
  masterlistType,
  aliasAPI, 
}) => {
  // const [modalcard, setModalcard] = useState(false);
  const navigate = useNavigate();
  let { gridview } = useParams();
  const [visible, setVisible] = React.useState(false);
  const tableName = "filmographies";

  React.useEffect(() => {
    if (gridview) {
      navigate(`/${masterlistType}/${masterlist}/filmography`, { replace: true });
    }
    if (parseInt(gridview) === 1) {
      setVisible(true);
    }
  }, [gridview, masterlist, masterlistType, navigate]);

  const handlePrint = () => {
    window.print();
  };

  const handleImageClick = () => {
    setBookletSong(!bookletSong);
  };
  return (
    <Stack height={"100vh"}>
      <Container
        maxW="auto"
        px={{base:"4",md:"6"}}
        mx={"0"}
      >
        <Stack   px={{base:"0",md:"8"}} >
        {bookletSong ? (
          <BookletSongs songName={masterlist} />
        ) : (
          <Tabs variant="unstyled" defaultIndex={flag > 0 ? 1 : 0}>
            <TabList
              position="sticky"
              zIndex={9}
              width={{base:"100%",md:"1080px"}}
              top="64px"
              px="0px"
              gap={"30px"}
              bg="white"
            >
              <Tab
                className="Timeline"
                p="0"
                my="4"
                fontSize={"16px"}
                fontWeight={"400"}
                _selected={{
                  borderBottom: "4px",
                  color: "black",
                  borderBottomColor: "black",
                  fontWeight: "700",
                  borderRadius: "4px",
                }}
              >
                {explore.FILMOGRAPHY}
              </Tab>
              <Tab
                className="Timeline"
                p="0"
                my="4"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottom: "4px",
                  color: "black",
                  borderBottomColor: "black",
                  fontWeight: "700",
                  borderRadius: "4px",
                }}
              >
                {explore.SYNOPSIS_BOOKLET}
              </Tab>
              <Tab
                className="Timeline"
                p="0"
                my="4"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottom: "4px",
                  color: "black",
                  borderBottomColor: "black",
                  fontWeight: "700",
                  borderRadius: "4px",
                }}
              >
                 {explore.INTEGRATED_TIMELINE}
              </Tab>
            </TabList>
            <TabPanels bg="white">
              <TabPanel px="0px">
                <Stack position={"relative"}>
                  <Stack
                    zIndex={99}
                    position={"sticky"}
                    top={{base:"124px",md:"80px"}}
                    marginTop={"-60px"}
                    marginLeft={"auto"}
                    marginRight={"15px"}
                    flexDirection="row"
                    justifyContent={"flex-end"}
                    id="listgridviewbtn"
                    gap={0}
                    bg="white"
                  >
                    <HStack marginTop={"0px!important"}>
                      <IconButton
                       
                        variant="unstyled"
                        onClick={() => setVisible(false)}
                        color={!visible ? "black" : "#989898"}
                        fontSize="20px"
                        display="flex"
                        borderTopLeftRadius="4px"
                        borderBottomLeftRadius="4px"
                        borderTopRightRadius="0px"
                        borderBottomRightRadius="0px"
                        border="1px"
                        borderColor={"#989898"}
                        height="32px"
                      >
                        <RiMenuLine ml="4" />
                      </IconButton>
                    </HStack>
                    <HStack marginTop={"0px!important"}>
                      <IconButton
                        variant="unstyled"
                        onClick={() => setVisible(true)}
                        color={visible ? "black" : "#989898"}
                        fontSize="16px"
                        display="flex"
                        borderTopLeftRadius="0px"
                        borderBottomLeftRadius="0px"
                        borderTopRightRadius="4px"
                        borderBottomRightRadius="4px"
                        border="1px"
                        borderColor={"#989898"}
                        height="32px"
                      >
                        <BsGrid />
                      </IconButton>
                    </HStack>
                    {!visible && (
                      <HStack
                        paddingLeft={{base:"15px",md:"30px"}}
                        marginRight={{base:"-15px!important",md:"-45px!important"}}
                        marginTop={"0px!important"}
                      >
                        <Image
                          onClick={handlePrint}
                          cursor={"pointer"}
                          src={printer}
                          width={"24px"}
                          height={"24px"}
                        ></Image>
                      </HStack>
                    )}
                  </Stack>
                  {visible ? (
                    <GridView masterlist={masterlist} tableName={tableName} />
                  ) : (
                    <TabularView
                      masterlist={masterlist}
                      tableName={tableName}
                    />
                  )}
                </Stack>
              </TabPanel>
              <TabPanel height={{base:"max-content",md:"calc(100vh - 110px)"}} px="0px">
                <SynopsisBooklet handleImageClick={handleImageClick} masterlist={masterlist} />
              </TabPanel>
              <TabPanel py="40" px="0px" >
              <WebpageComingSoon />
              {/* Need for future use */}
              {/* <IntegratedTimelineBody
               modalcard={modalcard}
               setModalcard={setModalcard}
               aliasAPI={aliasAPI} /> */}
          </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        <ScrollToTop />
        </Stack>
      </Container>
    </Stack>
  );
};
