import { HStack, Stack, Text, Image } from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";

//Service files
import * as getAllData from "./../../../../services/EventExhibition";

export default function All({ winnerName }) {
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await getAllData.allData("event-exhibition", winnerName);

      setAllData(result[0]);
    };

    getData();
  }, [winnerName]);

  return (
    <Stack px={"68px"}>
      <HStack justifyContent={"end"} alignItems={"self-start"} mt="30px">
        <Image src={allData?.image} width={"auto"} height={"auto"} />
        <Text width={"286px"} color={"#035DA1"} pl={"20px"}>
          {allData?.desc}
        </Text>
      </HStack>
    </Stack>
  );
}
