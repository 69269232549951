import {
  Box,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import H4 from "../components/layouts/Typography/H4";
import { common, oExplorer } from "../constants/constants";
import { getOExplorerData } from "../services/OExplorerService";
import React from "react";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Vector = `${IMAGE_BASE_PATH}/ui/Chevron/Vector.png`
const joker = `${IMAGE_BASE_PATH}/ui/Chevron/joker.png`
const announce = `${IMAGE_BASE_PATH}/ui/Chevron/announce.png`

export const OExplorer = () => {
  const [oExplorerData, setOExplorerData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getOExplorerData();
      if (result?.length > 0) {
        let data = result.flatMap((cat) => {
          return cat.flatMap((it) => {
            const { key, value } = it;
            if (key === "id") {
              return {
                id: `${value}`,
                name: `${cat.find((item) => item.key === "name").value}`,
              };
            } else {
              return [];
            }
          });
        });
        setOExplorerData(data);
        setShowLabel(false);
      } else {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  console.log(oExplorerData, "oExplorerData");
  return (
    <>
      <Box
        border={"1px"}
        borderColor={"#DFDFDF"}
        borderRadius={"5px"}
        paddingY="0.4"
        paddingX={"1"}
        mx="4"
      >
        {showLabel ? (
          <Text py={"2"} fontSize={"12px"} fontWeight="400" textAlign="center">
            {common.CONTENT_AWAITED}
          </Text>
        ) : (
          <Menu>
            <MenuButton className="dropdownmenu">
              <H4>{oExplorer.O_EXPLORER}</H4>
            </MenuButton>

            <MenuList
              className="nav-menulist"
              border={"none"}
              borderRadius={"0px"}
              px="2"
            >
              <HStack px="4" py="4" justifyContent={"flex-end"}>
                <Text
                  fontSize={"15px"}
                  fontWeight={"700"}
                  lineHeight={"18px"}
                  textStyle={"primary"}
                  textDecoration={"none"}
                >
                  {oExplorer.COUNT} |
                </Text>
                <Image src={announce} />
              </HStack>
              {/* {oExplorerData.flatMap()?.map((item) => { */}
              {oExplorerData.map((item) => {
                return (
                  <MenuItem
                    py="2"
                    justifyContent={"space-between"}
                    _hover={{
                      bg: "white",
                    }}
                  >
                    <Text
                      fontSize={"15px"}
                      fontWeight={"700"}
                      lineHeight={"18px"}
                      textStyle={"primary"}
                      textDecoration={"none"}
                    >
                      {item?.id}_{item?.name}
                    </Text>

                    {item?.id === "1" && (
                      <>
                        <HStack>
                          <Image ml="10" src={Vector} />
                        </HStack>

                        <HStack>
                          <Image src={joker} width={"100%"} height={"35px"} />
                        </HStack>
                      </>
                    )}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}
      </Box>
    </>
  );
};
