//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Pre1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex1.png`
const Pre2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex2.png`
const Pre3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex3.png`
const Pre4 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex4.png`
const Pre5 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex5.png`
const Pre6 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex6.png`
const Pre7 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex7.png`
const Pre8 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/ex8.png`
const full1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/1.png`
const full2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/2.png`
const full3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/3.png`
const full4 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/4.png`
const full5 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/5.png`
const full6 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/6.png`
const full7 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/7.png`
const full8 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Exhibits/GridView/FullScreenView/8.png`
const Img1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/7.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/8.png`
const Img9 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/9.png`
const Img10 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/10.png`
const Img11 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/11.png`
const Img12 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/12.png`

const Cr11 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider1/1.png`
const Cr21 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider1/2.png`
const Cr31 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider1/3.png`
const Cr41 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider1/4.png`

const Cr12 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider2/1.png`
const Cr22 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider2/2.png`
const Cr32 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider2/3.png`
const Cr42 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider2/4.png`

const Cr13 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider3/1.png`
const Cr23 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider3/2.png`
const Cr33 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider3/3.png`
const Cr43 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider3/4.png`

const Cr14 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider4/1.png`
const Cr24 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider4/2.png`
const Cr34 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider4/3.png`
const Cr44 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/GridView/FullScreenView/slider4/4.png`
const imgPath = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Essays/1.png`

export const essaySliderdata = async () => {
  const essayData = [
    {
      key: "id",
      value: "1",
    },
    {
      key: "title_essay",
      value: "Dhanraj Bhagat - Sculptor",
      display: "Title of the Essay",
    },
    {
      key: "author",
      // value: "Krishna Chaitanya",
      value: [
        {
          key: "krishna-chaitanya",
          value: "Krishna Chaitanya",
        },
      ],
      display: "Author",
    },
    {
      key: "year_of_publication",
      value: "1979",
      display: "Year of Publication",
    },
    {
      key: "image",
      value: [
        {
          key: "art-heritage-annual-image",
          value: imgPath,
          date_of_artwork: "",
          wnn: "",
          accession_number: "",
          name_of_publication: "",
          artist_name: "",
          medium_of_artwork: "",
          revised_medium: "",
        },
      ],
      display: "image",
    },
  ];
  return essayData;
};

export const getSliderData = async () => {
  const sliderData = [
    {
      id: 1,
      imgPath: Pre1,
      imgfullPath: full1,
      desc: "Messaging the Horse",
      author: "Dhanraj Bhagat",
      art_work: "Wood",
      year: "1948",
      date_of_artwork: "Wood, 1948",
      date_of_artwork_size: "36 cm (25.98 in)",
      content: "Content -1",
    },
    {
      id: 2,
      imgPath: Pre2,
      imgfullPath: full2,
      art_work: "Papier Mache",
      year: "1953",
      desc: "Tree of Life",
      author: "Dhanraj Bhagat",
      date_of_artwork: "Papier Mache, 1953",
      date_of_artwork_size: "89 cm (14.17 in)",
      content: "Content -2",
    },
    {
      id: 3,
      imgPath: Pre3,
      art_work: "Cement Concrete",
      year: "1953",
      imgfullPath: full3,
      desc: "Veena player",
      author: "Dhanraj Bhagat",
      date_of_artwork: "Cement Concrete, 1953",
      date_of_artwork_size: "89 cm (1125 cm (35.03 in).17 in)",
      content: "Content -3",
      description1: "",
    },
    {
      id: 4,
      imgPath: Pre4,
      imgfullPath: full4,
      art_work: "Plaster of Paris",
      year: "1956",
      desc: "Shiva Dance",
      author: "Dhanraj Bhagat",
      date_of_artwork: "Plaster of Paris, 1956",
      date_of_artwork_size: "178cm",
      content: "Content -4",
      description1: "",
    },
    {
      id: 5,
      imgPath: Pre5,
      imgfullPath: full5,
      art_work: "Cement Concrete",
      year: "1959",
      desc: "Spirit of Work",
      author: "Dhanraj Bhagat",
      date_of_artwork: "Cement Concrete, 1959",
      date_of_artwork_size: "178 cm (49.21 in)",
      content: "Content -5",
      description1: "",
    },
    {
      id: 6,
      imgPath: Pre6,
      imgfullPath: full6,
      art_work: "Plaster of Paris with iron filings",
      year: "1960",
      desc: "Rajasthani Women",
      author: "Dhanraj Bhagat",
      date_of_artwork: "Plaster of Paris, 1960",
      date_of_artwork_size: "178 cm (70.07 in)",
      content: "Content -6",
      description1: "",
    },
    {
      id: 7,
      imgPath: Pre7,
      imgfullPath: full7,
      art_work: "Plaster of Paris with iron filings",
      year: "1962",
      desc: "Third Eye",
      author: "Dhanraj Bhagat",
      date_of_artwork: "Plaster of Paris with iron filings, 1962",
      date_of_artwork_size: "137 cm (46.06 in)",
      content: "Content -7",
      description1: "",
    },
    {
      id: 8,
      imgPath: Pre8,
      imgfullPath: full8,
      art_work: "Plaster of Paris with iron filings",
      year: "1962",
      desc: "Man",
      author: "Dhanraj Bhagat",
      title: "Original Release Poster, 1977_",
      date_of_artwork: "Plaster of Paris with iron filings, 1962",
      date_of_artwork_size: "153 cm (53.93 in)",
      content: "Content -8",
      description1: "",
    },
  ];
  return sliderData;
};
export const exhibition_data = async () => {
  const exhibition_data = [
    {
      id: 1,
      author: "Drawing and Linear Expression—Six Contemporary Artists",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: true,
      image: true,
    },
    {
      id: 2,
      author: "Arpita Singh",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 3,
      author: "V. Solanki",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 4,
      author: "Dhanraj Bhagat - A Reterospective",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Lalit Kala Akademi",
      page: "Art Heritage",
      group: false,
      image: false,
      link: "/object-book-jou/art-heritage-annual/body-of-work/timeline/exhibition-catlouge",
    },
    {
      id: 5,
      author: "K. Laxma Goud",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 6,
      author: "M.F. Husain: The Modern Artist and Tradition—A Retrospective",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Lalit Kala Akademi",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 7,
      author: "K.G. Subramanyan",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 8,
      author: "Spirit Worship and Ritual Dances",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "",
      page: "",
      group: false,
      image: false,
    },
    {
      id: 9,
      author: "Meera Devidayal",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 10,
      author: "Sudhir Patwardhan",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 11,
      author: "Benode Behari Mukherjee",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 12,
      author: "Ram Nath Pasricha",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 13,
      author: "A.M. Davierwalla—A Retrospective",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Jehangir Art Gallery",
      page: " Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 14,
      author: "Shamshad Husain",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 15,
      author: "Urmila Chadha",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
    {
      id: 16,
      author: "Akre Manoher",
      year: "1979",
      range: "1978-79",
      location: "Delhi, India.",
      gallery: "Art Heritage Gallery",
      page: "Art Heritage",
      group: false,
      image: false,
    },
  ];
  return exhibition_data;
};
export const getGridView_data = async () => {
  const gridview_data = [
    {
      id: 1,
      imgPath: Img1,
      author: "Ebrahim Alkazi",
      year: "1979",
      book: "BOOK.jou",
      link: "/object-book-jou/art-heritage-annual/body-of-work/timeline",
      fullImage: [Cr11, Cr21, Cr31, Cr41],
    },
    {
      id: 2,
      imgPath: Img2,
      author: "Ebrahim Alkazi",
      year: "1980",
      book: "BOOK.jou",
      fullImage: [Cr12, Cr22, Cr32, Cr42],
    },
    {
      id: 3,
      imgPath: Img3,
      author: "Ebrahim Alkazi",
      year: "1980",
      book: "BOOK.jou",
      fullImage: [Cr13, Cr23, Cr33, Cr43],
    },
    {
      id: 4,
      imgPath: Img4,
      author: "Ebrahim Alkazi",
      year: "1982",
      book: "BOOK.jou",
      fullImage: [Cr14, Cr24, Cr34, Cr44],
    },
    {
      id: 5,
      imgPath: Img5,
      author: "Ebrahim Alkazi",
      year: "1984",
      book: "BOOK.jou",
    },
    {
      id: 6,
      imgPath: Img6,
      author: "Ebrahim Alkazi",
      year: "1985",
      book: "BOOK.jou",
    },
    {
      id: 7,
      imgPath: Img7,
      author: "Ebrahim Alkazi",
      year: "1986",
      book: "BOOK.jou",
    },
    {
      id: 8,
      imgPath: Img8,
      author: "Ebrahim Alkazi",
      year: "1987",
      book: "BOOK.jou",
    },
    {
      id: 9,
      imgPath: Img9,
      author: "Ebrahim Alkazi",
      year: "1988",
      book: "BOOK.jou",
    },
    {
      id: 10,
      imgPath: Img10,
      author: "Ebrahim Alkazi",
      year: "1989",
      book: "BOOK.jou",
    },
    {
      id: 11,
      imgPath: Img11,
      author: "Ebrahim Alkazi",
      year: "1990",
      book: "BOOK.jou",
    },
    {
      id: 12,
      imgPath: Img12,
      author: "Ebrahim Alkazi",
      year: "1991",
      book: "BOOK.jou",
    },
  ];
  return gridview_data;
};
export const getListView_data = async () => {
  const listview_data = [
    {
      id: 1,
      author: "Alkazi, Ebrahim",
      journal: " Art Heritage Annual",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1979",
    },
    {
      id: 2,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Catalogue 1979-80",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1980",
    },
    {
      id: 3,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: zzz",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1980",
    },
    {
      id: 4,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Catalogue 1981-82",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1982",
    },
    {
      id: 5,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1983-84",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1984",
    },
    {
      id: 6,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1984-85",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1985",
    },
    {
      id: 7,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1984-86",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1986",
    },
    {
      id: 8,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1986-87",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1987",
    },
    {
      id: 9,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1987-88",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1988",
    },
    {
      id: 10,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: 1988-89",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1989",
    },
    {
      id: 11,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: 1989-90",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1990",
      book: "BOOK.jou",
    },
    {
      id: 12,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: 1990-91",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1991",
    },
    {
      id: 13,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1991-92",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1992",
    },
    {
      id: 14,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1993-94",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1994",
    },
    {
      id: 15,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1994-95",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1995",
    },
    {
      id: 16,
      author: "Alkazi, Ebrahim",
      journal: "Art Heritage Annual: Season 1995-96",
      location: "New Delhi",
      gallery: "Art Heritage Gallery",
      year: "1996",
    },
  ];
  return listview_data;
};
