import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import React from "react";

export default function DrawerModalDetails({
  educationFullScreenImage,
  showEducationImages,
  educationImgData,
  showFullEducationImages,
}) {
  return (
    <>
      <Modal
        size={"full"}
        isOpen={educationFullScreenImage}
        onClick={() => showEducationImages(null, false)}
        Id="modalfullscreen"
        educationImgData={educationImgData}
      >
        <ModalContent>
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box bg="white">
              <Stack>
                <HStack margin={"65px auto"}>
                  <Image
                    src={
                      educationImgData
                        ? // ? "https://d3fsixjuo450s3.cloudfront.net/" +
                          educationImgData
                        : ""
                    }
                    width={"auto"}
                    height={"100%"}
                    alt=""
                  />
                </HStack>
                <HStack justifyContent="flex-end">
                  <Box position={"absolute"} bottom={"10%"} right={"15%"}>
                    <Text
                      fontSize={"xl"}
                      bg={"#525252"}
                      color={"white"}
                      fontWeight={"400"}
                      padding="2"
                      borderRadius={"35px"}
                      cursor="pointer"
                      onClick={() => showFullEducationImages(false)}
                    >
                      <CgArrowsExpandRight />
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
