import { HStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const HorisLink = ({ links, px, fontSize, flexDirection, alignItems, aliasAPI }) => {
  console.log(aliasAPI,"aliasAPI")
  return (
    <HStack flexDirection={aliasAPI ? flexDirection : "none"} alignItems={aliasAPI ? alignItems : "none"} justifyContent="left" className="mt-0" px={px}>
      {/* <Link to="/research-categories/cinemae"> */}
      {links &&
        links.length !== 0 &&
        links.map((it, index) => {
          return (
            <Link to={it.link} key={index}>
              <Text
                fontSize={fontSize ? fontSize : "14px"}
                fontWeight="700"
                lineHeight={"16px"}
                // color={"#006CEA"}
                color="#035DA1"
                marginLeft={index !==0 && "-4px !important"}
              >
                {it.title}
                {index < links.length - 1 && (
                  <span style={{ color: "black" }}> | </span>
                )}
              </Text>
            </Link>
          );
        })}
    </HStack>
  );
};
