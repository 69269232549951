import { 
  Container, 
  Stack 
} from "@chakra-ui/react";

//Core file
import React from "react";

//Component file
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";

function Archive() {
  return (
      <Stack>
        <Container>
        <WebpageComingSoon />
        </Container>
      </Stack>
  );
}
export default Archive;