import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";

//Services files
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getIntroductionData from "../../services/EventExhibition";

import { CopyPasteService } from "../../services/CopyPaste";

//Component files
import MobileNav from "../../components/Topheader";
import Heading from "../../components/layouts/Typography/Heading";
import H3 from "../../components/layouts/Typography/H3";
import Large from "../../components/layouts/Typography/Large";
import H2 from "../../components/layouts/Typography/H2";
import ReadMore from "../PersonalityPolymath/ReadMore";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language files
import { explore } from "../../constants/constants";
import H4 from "../../components/layouts/Typography/H4";

//Image file

export default function Introduction({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const { alias } = useParams();
  const aliasAPI = alias;
  const masterlistType = "event-exhibition";
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "journal_from"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist")?.value
            }/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }`,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <IntroductionBody
          setTitle={setTitle}
          setDate={setDate}
          setDateDod={setDateDod}
          readMoreText={readMoreText}
          setReadMore={setReadMore}
          aliasAPI={aliasAPI}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };

  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CommonLeftSidebar
        sections={introData}
        oSections={data.oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        backTo={data.backNavigation}
        handleNavigation={handleNavigation}
        leftFlag={true}
        readMoreText={readMoreText}
      />
    </>
  );
};

const IntroductionBody = ({
  setTitle,
  setDate,
  setDateDod,
  readMoreText,
  setReadMore,
  aliasAPI,
}) => {
  const [contentAwaited, setContentAwaited] = useState(false);
  const masterlistTitle = aliasAPI;
  const { t } = useTranslation("");

  const [introData, setIntroData] = useState([{}]);
  const [masterlistImage, setMasterListImage] = useState("");

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "event-exhibition",
        masterlistTitle
      );

      if (result) {
        setContentAwaited(false);
        setMasterListImage(
          result[0].find((item) => item?.key === "image")?.value
        );
        setIntroData([
          {
            name: result[0].find((item) => item?.key === "name")?.value,
            journal_from: result[0].find((item) => item?.key === "journal_from")
              ?.value,
            journal_till: result[0].find((item) => item?.key === "journal_till")
              ?.value,
            description: result[0].find((item) => item?.key === "description")
              ?.value,
            caption: result[0].find((item) => item?.key === "caption")?.value,
            exhibition_type: result[0].find(
              (item) => item?.key === "exhibiton_type"
            )?.value,
            introdesc: result[0].find(
              (item) => item?.key === "introduction_desc"
            ),
            curator: result[0].find((item) => item?.key === "curator"),
            venue: result[0].find((item) => item?.key === "venue"),
            date_of_exhibt: result[0].find(
              (item) => item?.key === "date_of_exhibition"
            ),
            organizer: result[0].find((item) => item?.key === "organizers"),
            artist: result[0].find((item) => item?.key === "artists"),
          },
        ]);
      } else {
        setContentAwaited(true);
      }
    };
    getIntroData();
  }, [masterlistTitle]);
  return (
    <>
      {readMoreText ? (
        <ReadMore />
      ) : (
        <>
          <Stack>
            {contentAwaited ? (
              <Text
                py={"370px"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                Content Awaited
              </Text>
            ) : (
              <>
                <Container maxW="auto" px="0" className="songs">
                  <Stack
                    backgroundPosition="top"
                    backgroundImage={`linear-gradient(to bottom, rgba(245 246 252 / 5%), rgba(0 0 0 / 88%)), url('${masterlistImage}')`}
                    backgroundRepeat="no-repeat"
                    height="calc(100vh - 64px)"
                    backgroundSize="cover"
                    position={"relative"}
                    alignItems={"end"}
                  >
                    <Stack
                      bottom={{ base: "10%", md: "5%" }}
                      position={"absolute"}
                      px={{ base: 4, md: 3 }}
                      flexDirection={"row"}
                      alignItems={"flex-end"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      {introData?.map((item, index) => (
                        <HStack
                          onCopy={(e) =>
                            CopyPasteService(e, window.location.href)
                          }
                        >
                          <Box mx={{ base: "0", md: "8" }} my="10">
                            <Heading
                              color={"white"}
                              textStyle={"primary"}
                              fontFamily={"SEGOE UI Bold"}
                            >
                              {item?.name}
                            </Heading>
                            <Text
                              fontSize={"14px"}
                              fontWeight={400}
                              color={"white"}
                              lineHeight={"16px"}
                              width={{ base: "fit-content", md: "437px" }}
                            >
                              {item?.caption}
                            </Text>
                            <Text
                              color={"white"}
                              fontSize={"16px"}
                              lineHeight={"21.28px"}
                              py={2}
                              fontWeight={900}
                              fontFamily={"SEGOE UI Bold"}
                            >
                              {item?.exhibition_type}
                            </Text>
                            <H3
                              color={"white"}
                              fontWeight={900}
                              fontFamily={"SEGOE UI Bold"}
                            >
                              {dateFormat(item?.journal_from, "d mmmm  yyyy")}{" "}
                              {""} - {""}
                              {dateFormat(item?.journal_till, "d mmmm  yyyy")}
                            </H3>
                            <Large
                              width={{ base: "100%", md: "437px" }}
                              display={"block"}
                              color="white"
                              pt={6}
                            >
                              <Text
                                fontSize={"14px"}
                                fontWeight={400}
                                lineHeight={"17.61px"}
                                width={{ base: "fit-content", md: "602px" }}
                              >
                                {item?.description}
                              </Text>
                            </Large>
                          </Box>
                        </HStack>
                      ))}
                    </Stack>
                    <Stack className="mt-0" bg={"white"} width={"425px"} height={"100%"} overflowY={"scroll"}>
                      <Stack p={"16px 24px"}>
                        <Accordion allowMultiple>
                          <AccordionItem>
                            <AccordionButton className="px-0">
                              <Box flex="1" textAlign="left">
                                <H4
                                  style={{ whiteSpace: "pre-wrap" }}
                                  textStyle={"primary"}
                                  // marginLeft={"10px"}
                                >
                                  {t(introData[0]?.introdesc?.display)}
                                </H4>
                              </Box>
                              <AccordionIcon fontSize={"30px"} />
                            </AccordionButton>
                            <AccordionPanel>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"16.44px"}
                                color={"#000000"}
                              >
                                {introData[0]?.introdesc?.value}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionButton className="px-0">
                              <Box flex="1" textAlign="left">
                                <H4
                                  style={{ whiteSpace: "pre-wrap" }}
                                  textStyle={"primary"}
                                  // marginLeft={"10px"}
                                >
                                  {t(introData[0]?.curator?.display)}
                                </H4>
                              </Box>
                              <AccordionIcon fontSize={"30px"} />
                            </AccordionButton>
                            <AccordionPanel>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"16.44px"}
                                color={"#000000"}
                              >
                                {introData[0]?.curator?.value}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionButton className="px-0">
                              <Box flex="1" textAlign="left">
                                <H4
                                  style={{ whiteSpace: "pre-wrap" }}
                                  textStyle={"primary"}
                                  // marginLeft={"10px"}
                                >
                                  {t(introData[0]?.venue?.display)}
                                </H4>
                              </Box>
                              <AccordionIcon fontSize={"30px"} />
                            </AccordionButton>
                            <AccordionPanel>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"16.44px"}
                                color={"#000000"}
                              >
                                {introData[0]?.venue?.value}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionButton className="px-0">
                              <Box flex="1" textAlign="left">
                                <H4
                                  style={{ whiteSpace: "pre-wrap" }}
                                  textStyle={"primary"}
                                  // marginLeft={"10px"}
                                >
                                  {t(introData[0]?.date_of_exhibt?.display)}
                                </H4>
                              </Box>
                              <AccordionIcon fontSize={"30px"} />
                            </AccordionButton>
                            <AccordionPanel>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"16.44px"}
                                color={"#000000"}
                              >
                                {introData[0]?.date_of_exhibt?.value}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionButton className="px-0">
                              <Box flex="1" textAlign="left">
                                <H4
                                  style={{ whiteSpace: "pre-wrap" }}
                                  textStyle={"primary"}
                                  // marginLeft={"10px"}
                                >
                                  {t(introData[0]?.organizer?.display)}
                                </H4>
                              </Box>
                              <AccordionIcon fontSize={"30px"} />
                            </AccordionButton>
                            <AccordionPanel>
                              <Text
                                fontSize={"14px"}
                                lineHeight={"16.44px"}
                                color={"#000000"}
                              >
                                {introData[0]?.organizer?.value}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionButton className="px-0">
                              <Box flex="1" textAlign="left">
                                <H4
                                  style={{ whiteSpace: "pre-wrap" }}
                                  textStyle={"primary"}
                                  // marginLeft={"10px"}
                                >
                                  {t(introData[0]?.artist?.display)}
                                </H4>
                              </Box>
                              <AccordionIcon fontSize={"30px"} />
                            </AccordionButton>
                            <AccordionPanel p={0} overflowY={"scroll"} maxHeight={"300px"} >
                              {introData[0]?.artist?.value.map((it) => {
                                return (
                                  <Box borderBottom={"0.4px solid #B5B5B5"}>
                                    <Text
                                      fontSize={"14px"}
                                      lineHeight={"16.44px"}
                                      color={"#000000"}
                                      p={"12px 24px"}
                                    >
                                      {it.name}
                                    </Text>
                                  </Box>
                                );
                              })}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Stack>
                    </Stack>
                  </Stack>
                </Container>
                <Stack display={{ base: "flex", md: "none" }}>
                  <BottomMainMenu />
                </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export function ArtistDetails({ artistDetails }) {
  return (
    <>
      <HStack justifyContent="left" py="1" px="4" marginTop="0.0rem">
        <H2>{artistDetails?.name}</H2>
      </HStack>
      <HStack justifyContent="left" px="4">
        <Large color={"black"}>
          {dateFormat(artistDetails?.date_of_birth, "d mmm yyyy")} -{" "}
          {dateFormat(artistDetails?.date_of_death, "d mmm  yyyy")}
        </Large>
      </HStack>
    </>
  );
}
