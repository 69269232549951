import {
  Box,
  Container,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import { BsGrid } from "react-icons/bs";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { useTranslation } from "react-i18next";

//Component files
import Essay from "./ExhibitionCatlouge/Essay";
import All from "./ExhibitionCatlouge/All";
import { Exhibits } from "./ExhibitionCatlouge/Exhibits";

//Language files
import { explore } from "../../../constants/constants";

function ExhibitionCatlouge({ winnerName }) {
  const [visible, setVisible] = React.useState(true);
  const [listGridIcon, setListGridIcon] = useState(true);
  const { t } = useTranslation("");

  return (
    <Container minW={"100%"} pb="4" paddingX={{ base: "4", md: "0px" }}>
      <Stack>
        <HStack>
          <Tabs align={{ xs: "end", sm: "" }} w="100%">
            <Box
              position={"fixed"}
              zIndex={999}
              bg="white"
              width={{ base: "100%", md: "1106px" }}
            >
              <TabList
                overflowX="auto"
                borderBottom={"1px"}
                borderBottomColor={"#f1f1f1"}
                gap={12}
                className="verticalscroll"
              >
                <Tab
                  className="Timeline"
                  p="0"
                  color="#333333"
                  fontSize={"md"}
                  fontWeight={"400"}
                  _selected={{
                    borderBottomWidth: "6px",
                    color: "black",
                    borderBottomColor: "#333333",
                    fontWeight: "700",
                  }}
                  onClick={() => setListGridIcon(false)}
                >
                  {t(explore.ALL)}
                </Tab>
                <Tab
                  className="Timeline"
                  p="0"
                  color="#333333"
                  fontSize={"md"}
                  fontWeight={"400"}
                  _selected={{
                    borderBottomWidth: "6px",
                    color: "black",
                    borderBottomColor: "#333333",
                    fontWeight: "700",
                  }}
                  onClick={() => setListGridIcon(false)}
                >
                  {t(explore.ESSAYS)}
                </Tab>
                <Tab
                  className="Timeline"
                  p="0"
                  color="#333333"
                  fontSize={"md"}
                  fontWeight={"400"}
                  _selected={{
                    borderBottomWidth: "6px",
                    color: "black",
                    borderBottomColor: "#333333",
                    fontWeight: "700",
                  }}
                  onClick={() => setListGridIcon(true)}
                >
                  {t(explore.EXHIBITS)}
                </Tab>
              </TabList>
            </Box>
            {listGridIcon && (
              <Stack
                flexDirection={"row"}
                gap={0}
                zIndex={999}
                right={{ base: "5%", md: "13%" }}
                id="listgridviewbtn"
                marginTop={"-10px !important"}
                position={"fixed"}
              >
                <HStack marginTop={"0px!important"}>
                  <IconButton
                    variant="unstyled"
                    onClick={() => setVisible(false)}
                    color={!visible ? "black" : "#989898"}
                    fontSize="20px"
                    display="flex"
                    borderTopLeftRadius="6px"
                    borderBottomLeftRadius="6px"
                    borderTopRightRadius="0px"
                    borderBottomRightRadius="0px"
                    border="1px"
                    borderColor={"#989898"}
                    height="32px"
                  >
                    <RiMenuLine ml="4" />
                  </IconButton>
                </HStack>
                <HStack marginTop={"0px!important"}>
                  <IconButton
                    variant="unstyled"
                    onClick={() => setVisible(true)}
                    color={visible ? "black" : "#989898"}
                    fontSize="16px"
                    display="flex"
                    borderTopLeftRadius="0px"
                    borderBottomLeftRadius="0px"
                    borderTopRightRadius="6px"
                    borderBottomRightRadius="6px"
                    border="1px"
                    borderColor={"#989898"}
                    height="32px"
                  >
                    <BsGrid />
                  </IconButton>
                </HStack>
              </Stack>
            )}
            <TabPanels marginTop={{ base: "65px", sm: "85px", md: "30px" }}>
              <TabPanel px="0px">
                <All winnerName={winnerName}/>
              </TabPanel>
              <TabPanel px="0px">
                <Essay winnerName={winnerName} />
              </TabPanel>
              <TabPanel px="0px">
                <Exhibits visible={visible} winnerName={winnerName} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </HStack>
      </Stack>
    </Container>
  );
}

export default ExhibitionCatlouge;
