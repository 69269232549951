import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { FaDotCircle } from "react-icons/fa";


// Service Files
import * as getInsightDetailsData from "../../../services/Insight";

// Subsection Files
import { EffectCreative, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/satyajit-ray.png`;
const Img2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Introduction/intro.jpeg`;

function QuizSlider() {
  const { alias } = useParams();
  const masterlistTitle = alias;
  const [quizInsightData, setInsightQuizData] = useState([]);
  const [imageQuizArray, setImageQuizArray] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsightQuizData(
        "masterlist_table",
        masterlistTitle
      );
      if (result) {
        setInsightQuizData(result);
      }
    };
    getData();
  }, [masterlistTitle]);

  const handleAnswers = (idx) => {
    const newArr = [...imageQuizArray];
    newArr.push(idx);
    setImageQuizArray(newArr);
  };

  useEffect(() => {
    if (imageQuizArray.length === 4) {
    }
  }, [imageQuizArray]);

  const [isLastSlide, setIsLastSlide] = useState(false);
  const swiperRef=useRef();
  const handleSlideChange = (swiper) => {
    setIsLastSlide(swiper.isEnd);
  };

  return (
    <>
      <Container px="0px" maxW="auto" className="songs" id="quizslider">
        <Stack px="0" width={"100%"} position={"relative"} mb="20">
          <Swiper
            slidesPerView={1}
            navigation={true}
            height={"100%"}
            ref={swiperRef}
            id="subslidershraza"
            modules={[EffectCreative, Navigation, Pagination]}
            // onSlideNextTransitionEnd={(e) => {
            onSlideChange={(swiper) => handleSlideChange(swiper)}
          >
            {quizInsightData.map((item, index) => (
              <SwiperSlide key={index}>
                <Box>
                  {item.question && (
                    <Text fontSize={"16px"} fontWeight={"600"} pb="4">
                      {item.question}
                    </Text>
                  )}
                  {item.type === "button" && (
                    <Stack>
                      {item.options.map((option, idx) => (
                        <Box key={idx} py="2">
                          <Text
                            as={"button"}
                            fontWeight={"500"}
                            variant={"unstyled"}
                            px="8"
                            py="4"
                            textAlign={"left"}
                            borderRadius={"5px"}
                            fontSize={"12px"}
                            bg="#FFBF17"
                            width={"100%"}
                          >
                            {option}
                          </Text>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {item.type === "image" && (
                    <SimpleGrid
                      justifyItems={"center"}
                      columns={[2, null, 2]}
                      spacing="20px"
                    >
                      {item.options.map((item, idx) => (
                        <Box
                          className="quiz-img"
                          onClick={() => handleAnswers(idx)}
                          borderColor={
                            imageQuizArray.includes(idx) ? "green" : "white"
                          }
                        >
                          <Image
                            src={item.image}
                            width={"auto"}
                            height={"100%"}
                            alt={""}
                          />
                        </Box>
                      ))}
                    </SimpleGrid>
                  )}
                </Box>
              </SwiperSlide>
            ))}
            <SwiperSlide>
              <style>
                {`
          .swiper-button-prev, .swiper-button-next {
            display: ${isLastSlide ? "none" : "block"};
          }
        `}
              </style>
              <Stack>
                <HStack>
                  <Text fontSize={"16px"} fontWeight={"600"}>
                    You have scored{" "}
                    <Text as={"span"} color={"#FAD51C"}>
                      20 points
                    </Text>
                  </Text>
                </HStack>
                <HStack>
                  <VerticalTimeline layout={"1-column-left"}>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#d9d9d9",
                        fontSize: "16px",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Genius</Text>
                        <Text color="black">160</Text>
                      </Stack>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#d9d9d9",
                        fontSize: "16px",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Amazing</Text>
                        <Text color="black">110</Text>
                      </Stack>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#d9d9d9",
                        fontSize: "16px",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Nice</Text>
                        <Text color="black">59</Text>
                      </Stack>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#d9d9d9",
                        fontSize: "16px",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Nice</Text>
                        <Text color="black">59</Text>
                      </Stack>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#FAD51C",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                        borderRadius: "10px",
                        marginTop:"15px"
                      }}
                      className="score-iocn stage-score-bg"
                      icon={"31"}
                    >
                      <Box bg="#FAD51C">
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Text fontWeight={"700!important"} color="black">
                            Solid
                          </Text>
                          <Text color="black">160</Text>
                        </Stack>
                        <Text fontSize={"10px"} color="black">
                          18 points to next rank, 125 to Genius
                        </Text>
                      </Box>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#FAD51C",
                        fontSize: "16px",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Good</Text>
                        <Text color="black">160</Text>
                      </Stack>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#FAD51C",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Moving Up</Text>
                        <Text color="black">12</Text>
                      </Stack>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                      id="timeline4"
                      contentStyle={{ fontSize: "sm" }}
                      iconStyle={{
                        background: "#fff",
                        color: "#FAD51C",
                        fontSize: "16px",
                        fontWeight: "700",
                        maxWidth: "min-content",
                      }}
                      className="score-iocn"
                      icon={<FaDotCircle fontSize="22px" />}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Text color="black">Good Start</Text>
                        <Text color="black">12</Text>
                      </Stack>
                    </VerticalTimelineElement>
                  </VerticalTimeline>
                </HStack>
                <Stack py="4">
                  <Text
                    textAlign={"center"}
                    fontSize={"20px"}
                    fontWeight={"700"}
                  >
                    You’re 18 points away from the next rank in today puzzle
                  </Text>
                </Stack>
                <Stack
                  gap={"1"}
                  direction="row"
                  justifyContent={"center"}
                  spacing={4}
                  align="center"
                >
                  <Button
                    px="8"
                    fontSize={"12px"}
                    fontWeight={"400"}
                    textStyle={"secondary"}
                    borderColor="#244B78"
                    color="#244B78"
                    variant="outline"
                  >
                    Share
                  </Button>
                  <Button
                    px="8"
                    fontSize={"12px"}
                    fontWeight={"400"}
                    textStyle={"secondary"}
                    borderColor="#244B78"
                    color="#244B78"
                    variant="outline"
                    onClick={()=>swiperRef.slideTo(0)}
                  >
                    Retry
                  </Button>
                  <Button
                    px="8"
                    fontSize={"12px"}
                    fontWeight={"400"}
                    textStyle={"secondary"}
                    borderColor="#244B78"
                    color="#244B78"
                    variant="outline"
                  >
                    Profile
                  </Button>
                </Stack>
                <Stack py="4">
                  <Text textAlign={"left"} fontSize={"16px"} fontWeight={"700"}>
                    Read More about Shatranj Ke Khilari
                  </Text>
                </Stack>
                <Stack py="4">
                  <SimpleGrid columns={2} spacing={8}>
                    <Link to={`/person-poly/satyajit-ray`}>
                    <Box width={"100%"} height={"123px"}>
                      <Image src={Img1} width="100%" height="auto" />
                    </Box>
                    </Link>
                    <Link to={`/location/lucknow`}>
                    <Box width={"100%"} height={"123px"}>
                      <Image src={Img2} width="100%" height="auto" />
                    </Box>
                    </Link>
                  </SimpleGrid>
                </Stack>
              </Stack>
            </SwiperSlide>
          </Swiper>
        </Stack>
      </Container>
    </>
  );
}
export default QuizSlider;
