import {
  kg_subramanayam_main_post,
  kg_subramanayam_post,
  location_main_post,
  lucknow_post,
  nemai_ghosh_main_post,
  skk_main_post,
  skk_post,
} from "../MockData/PostDetailsMockup";
import { photographyInIndiaPosts } from "../MockData/ResearchCategoryDetail";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import axios from "axios";
const baseUrl = `${process.env.REACT_APP_MONGODB_URL}`;

export const getPostsCountByMasterlistAPI = async (tableName, masterlistAlias) => {
  const postCount = 0;
  return postCount;
};

/**
 * This function return total count of posts for respective masterlist.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias.
 * @returns {Promise<integer>} - Function return total count of posts.
 */
export const getPostsCountByMasterlist = async (tableName, masterlistAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getPostsCountByMasterlistDummyData(tableName, masterlistAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getPostsCountByMasterlistAPI(tableName, masterlistAlias);
    return response;
  }
};

export const getPostsCountByMasterlistDummyData = async (tableName, masterlistAlias) => {
  const getPostCount = {
    "shatranj-ke-khilari-1977": 2,
    "satyajit-ray": 1,
    "art-heritage-annual": 0,
    "sayed-haider-raza": 1,
    "k.g-Subramanyan": 1
  };
  const postCount = getPostCount[masterlistAlias] || 0;
  return postCount;
};

export const getPostsByMasterlistAPI = async (tableName, masterlistAlias) => {
  const posts = [{}]
  return posts;
}

/**
 * This function return list of posts of respective masterlist.
 * @param {string} tableName - Table name.
 * @param {string} masterlistAlias - Masterlist alias.
 * @returns {Promise<integer>} - Function return total count of posts.
 */
export const getPostsByMasterlist = async (tableName, masterlistAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getPostsByMasterlistDummy(masterlistAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getPostsByMasterlistAPI(tableName, masterlistAlias);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getPostsByMasterlistDummy = (masterlistAlias) => {
  const getPostDataMap = {
    "shatranj-ke-khilari-1977": skk_main_post,
    "nemai-ghosh": nemai_ghosh_main_post,
    "photography-in-india_3": photographyInIndiaPosts,
    "lucknow": location_main_post,
    "k.g-Subramanyan": kg_subramanayam_main_post
  };
  const postData = getPostDataMap[masterlistAlias] || skk_main_post;
  return postData;
};

export const getPostAPI = async (tableName, alias, masterlistAlias) => {
  const posts = [{}]
  return posts;
}

/**
 * This function return single post details.
 * @param {string} tableName - Table name.
 * @param {string} alias - Post alias.
 * * @param {string} masterlistAlias - Masterlist alias.
 * @returns {Promise<integer>} - Function return total count of posts.
 */
export const getPost = async (tableName, masterlistAlias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getPostDummy(tableName, masterlistAlias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getPostAPI(tableName, masterlistAlias);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getPostDummy = (tableName, masterlistAlias) => {
  const postDetailsDataMap = {
    "shatranj-ke-khilari-1977": skk_post,
    "lucknow": lucknow_post,
    "k.g-Subramanyan": kg_subramanayam_post,

  };
  const postDetailsData = postDetailsDataMap[masterlistAlias] || skk_post;
  return postDetailsData;
};



// RC Posts

export const getPostsByResearchCategoryAliasAPI = async (
  tableName,
  researchCategoryAlias
) => {
  return [];
};

/**
 * This Function return Research Category Posts data by using researchCategoryAlias.
 * @param {string} tableName - Table name.
 * @param {string} researchCategoryAlias - researchCategoryAlias.
 * @returns {Promise<integer>} - Function return Research Category Posts data.
 */
export const getPostsByResearchCategoryAlias = async (
  tableName,
  researchCategoryAlias
) => {
  if (isTransformationNotAllowed() === true) {
    return getPostsByResearchCategoryAliasDummy(researchCategoryAlias);
  } else if (isTransformationNotAllowed() === false) {
  }
};

export const getPostsByResearchCategoryAliasDummy = (researchCategoryAlias) => {
  const getPostsData = {
    "photography-in-india_3": photographyInIndiaPosts,
  };
  const PostsData = getPostsData[researchCategoryAlias] || [];
  return PostsData;
};

/**
 * This function return list of posts of Explore.
 * @param {string} tableName - Table name.
 * @param {string} Explore - Explore alias.
 * @returns {Promise<integer>} - Function return total count of posts.
 */
export const getPosts = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getPostsDummy(alias);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getPostsAPI(tableName, alias);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getPostsDummy = (alias) => {
  const getPostDataMap = {
    "globalposts": skk_main_post
  };
  const postData = getPostDataMap[alias] || skk_main_post;
  return postData;
};

export const getPostsAPI = async (tableName, alias, masterlistAlias) => {
  const posts = [{}]
  return posts;
}

/**
 * This function return Explore Posts data
 * @returns {Promise<Object>} - Function return Explore Posts data
 */

export const getExplorePostsData = async () => {
  if (isTransformationNotAllowed() === true) {
    const response = await getExplorePostsDummyData();
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getExplorePostsAPIData();
    return response;
  }
};

export const getExplorePostsDummyData = async () => {
  return [];
};

export const getExplorePostsAPIData = async () => {
  const result = await axios.get(baseUrl + '/explore/posts');
  if (result) {
    return result?.data?.data;
  }
};

/**
 * This function return Research Categories Posts data
 * @param {String} alias - Alias of Research Category
 * @returns {Promise<Object>} - Function return Research Categories Posts data
 */

export const getRCPostsData = async (alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getRCPostsDummyData();
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getRCPostsAPIData(alias);
    return response;
  }
};

export const getRCPostsDummyData = async () => {
  return [];
};

export const getRCPostsAPIData = async (alias) => {
  const result = await axios.get(baseUrl + '/research-categories/' + alias + '/posts');
  if (result) {
    return result?.data?.data;
  }
};