import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Flex,
  Checkbox,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import "react-vertical-timeline-component/style.min.css";
import { FaUserAlt } from "@react-icons/all-files/fa/FaUserAlt";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination } from "swiper";
import { IoTriangleOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { FiUser } from "react-icons/fi";

//Service file
import * as getIntroductionData from "../../../services/Introduction";
import * as getLandingMasterlistData from "../../../services/SectionService";
import { CopyPasteService } from "../../../services/CopyPaste";
import { exhibition_data } from "../../../services/Exhibitions_data";

//Component file
import MobileNav from "../../../components/Topheader";
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import Small from "../../../components/layouts/Typography/Small";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import ScrollToTop from "../../../components/sections/ScrollToTop";
import CommonLeftSidebar from "../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../../CommonComponents/BottomMainMenu";
// import { FilterButton } from "../../../CommonComponents/FilterButton";

//Language file
import { artheritage, button, common, explore, filterAttribute } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const pplImg = `${IMAGE_BASE_PATH}/ui/Chevron/people.png`
const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`
const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`

export default function EditionsTimeline({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "journal",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "journal_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        zIndex={"9999"}
        height="calc(100vh - 64px)"
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        introData={introData}
        masterlistType={masterlistType}
        name={name}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Stack width={"100%"}>
        <Container maxW={"auto"}>
          <Box ml={{ base: 0, md: 52 }} bg="white">
            {children}
            <TimelineBody />
          </Box>
        </Container>
      </Stack>
    </Box>
  );
}

const SidebarContent = ({ 
  onClose,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType,
  ...rest }) => {
  // const [intro, setIntro] = React.useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/rest/masterlist`
      );
      if (result?.data?.trcis_masterlist) {
        // const dataIntro = result?.data?.trcis_masterlist;
        // setIntro(dataIntro);
      }
    };
    getData();
  }, []);
  // const backTo = "/art-heritage-annual";
  const drawerArtHeritage = [{
title:"Ebrahim Alkazi", name: "Art Heritage Annual & Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Content Awaited",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const [drawerName, setDrawerName] = useState("")

   const artistLinksData = [{
  name: "Ebrahim Alkazi",
 
},{
    name: "Art Heritage Gallery",
}

];;
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        artistLinksData={artistLinksData}
        setDrawerName={setDrawerName}
        drawerName={drawerName}     
        handleNavigation={handleNavigation}
        drawerData={drawerArtHeritage}
      />
    </>
  );
};

const TimelineBody = () => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [iconClicked, setIconClicked] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  useEffect(() => {
    const getData = async () => {
      const result = await exhibition_data();
      if (result) {
        setTimelineData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);
  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };

  return (
    <>
      <Stack
        width={{ base: "100%", md: "1160px" }}
        flexDirection={{ base: "column", md: "row-reverse" }}
        justifyContent={"space-between"}
        marginTop={-2.5}
      >
        <Stack
          display={{ base: "none", md: "flex" }}
          position={"sticky"}
          marginTop={"100px"}
          zIndex={"99!important"}
        >
          <Stack
            id="filterstickywbtn"
            marginRight={"-15px!important"}
            onClick={handleFilterClick}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap="10px"
            >
              <Image transform={"rotate(360deg)"} src={Filter} />
              <Text
                as="button"
                transform={"rotate(90deg)"}
                fontSize={"14px"}
                fontWeight={"700"}
                marginTop={"4px"}
              >
                {explore.FILTER}
              </Text>
            </Box>
          </Stack>
        </Stack>
        {/* <FilterButton onClick={handleFilterClick} /> */}
        <Container maxW="auto" pb="4" px="0px">
          <Stack>
            <HStack>
              <Tabs align={{ xs: "end", sm: "" }} w="full">
                <Stack
                  flexDirection={"row-reverse"}
                  display={"flex"}
                >
                  <Box
                    zIndex={{ base: "0", md: "99" }}
                    bg="white"
                    width={{ base: "100%", md: "1160px" }}
                    marginleft="16px"
                  >
                    <Box
                      zIndex={99}
                      bg="white"
                      position={"fixed"}
                      width={{ base: "100%", md: "1160px" }}
                      paddingTop={"24px"}
                      paddingBottom={"20px"}
                      px={{ base: "0", md: "8" }}
                    >
                      <TabList
                        overflowX="auto"
                        borderBottom={"1px"}
                        gap={12}
                        className="verticalscroll"
                        borderColor={"white"}
                        marginTop={"10px"}
                        marginLeft={{ base: "10px", md: "20px" }}
                      >
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"18px"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                        >
                          {artheritage.EXHIBITIONS}
                        </Tab>
                      </TabList>
                    </Box>

                    <Stack
                      flexDirection={"row"}
                      py="2"
                      px={{ base: "0", md: "8" }}
                      justifyContent="space-between"
                      marginTop={"62px"}
                      marginLeft={"12px"}
                      gap={"24"}
                    >
                      <HStack
                        px={"0px"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-end"}
                      >
                        <Stack
                          py="4"
                          flexDirection={{ base: "column", md: "row" }}
                          px="0px"
                        >
                          <HStack
                            flexDirection={"column"}
                            alignItems={"baseline"}
                            onCopy={(e) =>
                              CopyPasteService(e, `${window.location.href}/1`)
                            }
                          >
                            {timelineData?.map((it, index) => (
                              <Stack flexDirection={"row"}>
                                <Box marginTop={"21px"} marginRight={"8px"}>
                                  {it.image ? (
                                    <AiOutlinePicture
                                      onClick={() => showImage(0)}
                                      cursor={"pointer"}
                                      color="#1E90FF"
                                      size={16}
                                    />
                                  ) : (
                                    <Box width={"16px"} height={"16px"}></Box>
                                  )}
                                </Box>
                                <VerticalTimeline
                                  layout={"1-column-left"}
                                  className="layout"
                                >
                                  <VerticalTimelineElement
                                    id="timeline2"
                                    className="exhibition-timeline"
                                    contentStyle={{ fontSize: "sm" }}
                                    date={
                                      it.link ? (
                                        <Text fontSize={"12px !important"}>
                                          {` ${it.range}, ${it.location}`}
                                          <Link
                                            to={`${it.link}`}
                                            cursor={"pointer"}
                                          >
                                            <span
                                              style={{
                                                color: "#0066B3",
                                                fontWeight: "700",
                                              }}
                                            >
                                              {" "}
                                              {artheritage.ESSAYS} |
                                            </span>
                                          </Link>
                                          <Link
                                            to={`${it.link}/1`}
                                            cursor={"pointer"}
                                          >
                                            <span
                                              style={{
                                                color: "#0066B3",
                                                fontWeight: "700",
                                              }}
                                            >
                                              {" "}
                                              {artheritage.EXHIBITS}
                                            </span>
                                          </Link>
                                        </Text>
                                      ) : (
                                        <Text fontSize={"12px !important"}>
                                          {` ${it.range}, ${it.location}`}
                                          <span
                                            style={{
                                              color: "#0066B3",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {" "}
                                            {artheritage.ESSAYS} | {artheritage.EXHIBITS}
                                          </span>
                                        </Text>
                                      )
                                    }
                                    iconStyle={{
                                      background: "#fff",
                                      color: "#333",
                                      fontSize: "16px",
                                      fontWeight: "700",
                                    }}
                                    icon={it.year}
                                  >
                                    {it.link ? (
                                      <Small color="#035DA1">
                                        {it.group ? (
                                          <Image
                                            src={pplImg}
                                            display={"inline"}
                                            marginRight={"8px"}
                                          />
                                        ) : (
                                          <FaUserAlt
                                            color="black"
                                            display={"inline"}
                                            fontSize={"11px"}
                                          />
                                        )}
                                        {`${it.author},${it.page},${it.gallery}`}
                                      </Small>
                                    ) : (
                                      <Small
                                        color="#035DA1"
                                      >
                                        {it.group ? (
                                          <Image
                                            src={pplImg}
                                            display={"inline"}
                                            marginRight={"8px"}
                                          />
                                        ) : (
                                          <FaUserAlt
                                            color="black"
                                            display={"inline"}
                                            fontSize={"11px"}
                                          />
                                        )}
                                        {`${it.author},${it.page},${it.gallery}`}
                                      </Small>
                                    )}
                                  </VerticalTimelineElement>
                                </VerticalTimeline>
                              </Stack>
                            ))}
                          </HStack>
                          <HStack
                            px={"0px"}
                            width={{ base: "300px", md: "350px" }}
                            alignItems={"flex-start"}
                            justifyContent={"flex-end"}
                          >
                            {!showFilterBox && (
                              <Stack
                                className="col-12 mt-0"
                                id="allsliderskkAward"
                                position={"relative"}
                              >
                                <HStack
                                  className={
                                    !iconClicked
                                      ? "imageZoomOut"
                                      : "imageZoomIn"
                                  }
                                >
                                  <Swiper
                                    grabCursor={true}
                                    navigation={true}
                                    modules={[
                                      EffectFlip,
                                      Navigation,
                                      Pagination,
                                    ]}
                                    onSwiper={setSwiper}
                                  >
                                    <SwiperSlide>
                                      <Box
                                        height={{ base: "352px", md: "352px" }}
                                        width={{ base: "100%", md: "280px" }}
                                        border={"1px solid #C4C4C4"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        textAlign={"center"}
                                        display={"flex"}
                                        bg={"#C4C4C4"}
                                      >
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                        >
                                          {common.IMAGE_AWAITED}
                                        </Text>
                                      </Box>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                      <Box
                                        height={"352px"}
                                        width={"280px"}
                                        border={"1px solid #C4C4C4"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        textAlign={"center"}
                                        display={"flex"}
                                        bg={"#C4C4C4"}
                                      >
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                        >
                                          {common.IMAGE_AWAITED}
                                        </Text>
                                      </Box>
                                    </SwiperSlide>
                                  </Swiper>
                                </HStack>
                              </Stack>
                            )}
                          </HStack>
                          <ScrollToTop />
                        </Stack>
                      </HStack>
                      <HStack
                        marginRight={"-78px!important"}
                        alignItems={"flex-start"}
                      >
                        {showFilterBox && (
                          <Box
                            className="box-filterArtHeritage"
                            backgroundColor={"#ffffff!important"}
                          >
                            <Stack
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                            >
                              <HStack>
                                <SmallBold
                                  as="u"
                                  color={"black"}
                                  cursor="pointer"
                                >
                                  {button.CLEAR_ALL}
                                </SmallBold>
                              </HStack>
                              <HStack className="mt-0">
                                <RiCloseCircleFill
                                  size={"22px"}
                                  cursor="pointer"
                                  onClick={(e) => setShowFilterBox(false)}
                                />
                              </HStack>
                            </Stack>
                            <HStack
                              alignItems={"left"}
                              flexDirection={"column"}
                              marginTop={"25px"}
                            >
                              <InputGroup className="ml-0" pb="4">
                                <Input
                                  placeholder="Search"
                                  height={"32px"}
                                  pb={"4px"}
                                  _placeholder={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                />
                                <InputRightElement pb={"6px"}>
                                  <BiSearch
                                    color="green.500"
                                    fontSize={"16px"}
                                  />
                                </InputRightElement>
                              </InputGroup>
                            </HStack>
                            <Accordion allowToggle>
                              <AccordionItem>
                                <AccordionButton className="px-0">
                                  <Box flex="1" textAlign="left">
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      lineHeight={"24px"}
                                    >
                                      {filterAttribute.TITLE_OF_EXHIBITIONS}
                                    </Text>
                                  </Box>
                                  <AccordionIcon fontSize={"30px"} />
                                </AccordionButton>
                                <AccordionPanel pb={4} className="px-1">
                                  <SmallBold color="#035DA1">
                                    {common.CONTENT_AWAITED}
                                  </SmallBold>
                                </AccordionPanel>
                                {/* Need to uncomment when author data added */}
                                {/* <AuthorAccordionPanel
                      data={data}
                      setAuthorName={setAuthorName}
                      authorName={authorName}
                    /> */}
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionButton className="px-0">
                                  <Box flex="1" textAlign="left">
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      lineHeight={"24px"}
                                    >
                                      {filterAttribute.ARTISTS}
                                    </Text>
                                  </Box>
                                  <AccordionIcon fontSize={"30px"} />
                                </AccordionButton>
                                <AccordionPanel pb={4} className="px-1">
                                  <Box maxH={"110px"} overflowY={"scroll"}>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            A.M. Davierwalla
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Akre Manoher
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Arpita Singh
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Benode Behari Mukherjee
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Dhanraj Bhagat
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            K.G. Subramanyan
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            K. Laxma Goud
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            M.F. Husain
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Meera Devidayal
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Ram Nath Pasricha
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                    <Flex>
                                      <Checkbox
                                        borderColor={"#B5B5B5"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                      />
                                      <Box py="1">
                                        <HStack>
                                          <Text fontSize={"14px"}>
                                            Shamshad Husain
                                          </Text>
                                        </HStack>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionButton className="px-0">
                                  <Box flex="1" textAlign="left">
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      lineHeight={"24px"}
                                    >
                                      {filterAttribute.TYPE_OF_EXHIBITION}
                                    </Text>
                                  </Box>
                                  <AccordionIcon fontSize={"30px"} />
                                </AccordionButton>
                                <AccordionPanel pb={4} className="px-1">
                                  <Flex>
                                    <Checkbox
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                    />
                                    <Box py="1">
                                      <HStack>
                                        <Text color={"#6B7094;"}>
                                          <FiUser />
                                        </Text>{" "}
                                        <Text fontSize={"14px"}>Solo</Text>
                                      </HStack>
                                    </Box>
                                  </Flex>
                                  <Flex>
                                    <Checkbox
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                    />
                                    <Box py="1">
                                      <HStack>
                                        <Text color={"#6B7094;"}>
                                          <FaUsers />
                                        </Text>
                                        <Text fontSize={"14px"}>Group</Text>
                                      </HStack>
                                    </Box>
                                  </Flex>
                                  <Flex>
                                    <Checkbox
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                    />
                                    <Box py="1">
                                      <HStack>
                                        <Text
                                          pl="1"
                                          color={"#6B7094;"}
                                          fontSize={"18px"}
                                        >
                                          R
                                        </Text>{" "}
                                        <Text fontSize={"14px"}>
                                          Retrospective
                                        </Text>
                                      </HStack>
                                    </Box>
                                  </Flex>
                                  <Flex>
                                    <Checkbox
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                    />
                                    <Box py="1">
                                      <HStack>
                                        <Text color={"#6B7094;"} pl="1">
                                          <IoTriangleOutline />
                                        </Text>
                                        <Text fontSize={"14px"}>Traveling</Text>
                                      </HStack>
                                    </Box>
                                  </Flex>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionButton className="px-0">
                                  <Box flex="1" textAlign="left">
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      lineHeight={"24px"}
                                    >
                                      {filterAttribute.VENUE_LOCATION}
                                    </Text>
                                  </Box>
                                  <AccordionIcon fontSize={"30px"} />
                                </AccordionButton>
                                <AccordionPanel pb={4} className="px-1">
                                  <SmallBold color="#035DA1">
                                    {common.CONTENT_AWAITED}
                                  </SmallBold>
                                </AccordionPanel>
                              </AccordionItem>
                              <AccordionItem>
                                {/* <Box maxH="200px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {documentTypeData.map((item) => {
                            return (
                              <Flex>
                              <Checkbox
                                  // isChecked={selections.includes(
                                  //   item?.sortable_year_date_of_publication
                                  // )}
                                  // onChange={() =>
                                  //   handleOptionClick(
                                  //     item?.sortable_year_date_of_publication
                                  //   )
                                  // }
                                  _checked={{
                                    "& .chakra-checkbox__control": { background: "black" }
                                  }}     
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  // bg={"black"}
                                  // colorScheme="black"
                                />
                                <Text fontSize={"14px"} pt={"4px"} pb={"4px"}>
                                  {item}
                                </Text>
                                <Divider color="#909090" />
                              </Flex>
                            );
                          })}
                        </AccordionPanel>
                      </Box> */}
                              </AccordionItem>
                              <HStack marginTop={"10px"}>
                                <Button
                                  // as="u"
                                  color={"#ffffff"}
                                  onClick={() => {
                                  }}
                                  width={"199px"}
                                  height={"36px"}
                                  bg={"#000000"}
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                  variant={"unstyled"}
                                >
                                  {explore.APPLY}
                                </Button>
                              </HStack>
                            </Accordion>
                          </Box>
                        )}
                      </HStack>
                    </Stack>
                  </Box>
                </Stack>
              </Tabs>
            </HStack>
          </Stack>
        </Container>
        <ScrollToTop />
      </Stack>
    </>
  );
};
