export const skk_archive_data=[
    [
        {
            "key": "accession_number",
            "value": "CINE.pos",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-pos-0000050",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "acfri9vr053sp17vlo67811g07",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/acfri9vr053sp17vlo67811g07/-OoiuKecP6oFspw2PBROwzJGd8w/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "16adf495-fc82-4eea-9f5c-514cab0ff78e",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000050",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Original Release Poster",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 120.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pos",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000050",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.pos",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-pos-0000051",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "qo20crpujd5jd6m0h03di7j51d",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/qo20crpujd5jd6m0h03di7j51d/IQ0u10yWZp_co5EUSD4XLjY5tFk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "fdef13c2-86d4-44a4-935c-e04e05f865fd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000051",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Original Release Poster",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 119.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pos",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000051",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.pos",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-pos-0000052",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "l1j8mv0hrp5hddgeko7nd4ol3v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/l1j8mv0hrp5hddgeko7nd4ol3v/p7Q2NLuDlPcYHqgM0ZtfdN99YCE/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "d5ab1558-defd-4b99-ad56-8ba1fe72217b",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000052",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Original Release Poster",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "shatranj-ke-khilari-pos-d02.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pos",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000052",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000054",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "q3o6cul2g16cp9qqs409dhii64",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/q3o6cul2g16cp9qqs409dhii64/1AibPt8QA4tPrqAn9tcwA0GjgF0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "cbd0f3a3-d22d-4411-8d9c-09ce439e7971",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000054",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "0815220.CINE.lby.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000054",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Costume",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "costume-worn-by-sanjeev-kumar-in-the-film-shatranj-ke-khilari-1977-past-auction_costume-0000055",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Shama Zaidi",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1v0j16av894t93bhrkuucj3a47",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1v0j16av894t93bhrkuucj3a47/iWYvxPMkWzfpocdLmvigauqaZEY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ff6f44be-9cd8-4506-8cc9-f12d7b1b7b6a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000055",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Silk from Benaras (COS), 1977",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Costume worn by Sanjeev Kumar in the film Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Costume_Shatranj Ke Khilari_Amjad Khan 122.psd",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.maq",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000055",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.phl",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-phl-0000056",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "tr005cqss56nn8bb9qmp897m12",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/tr005cqss56nn8bb9qmp897m12/v2iCDDugHHZhzR9p4o6jl_4oQqY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8f306c33-c38a-4255-9264-26b00c3c31fe",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000056",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still Mounted on Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "0864707.CINE.phl.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.phl",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000056",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Showcard",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "peter-strausfeld-1970s-showcard-0000057",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Peter Strausfeld",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Preface | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "l7aba5go8956v9iar2u6rls05n",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/l7aba5go8956v9iar2u6rls05n/4hBDpSyhYWpxewhYWTvQi36-2V4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "2029f352-ecf5-47ce-9070-1f589df2161a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000057",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Showcard",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Peter Strausfeld",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "the-chess-players-academy-cinema-london-1970s.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.shw",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1970s",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000057",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000059",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "b40ua7sle959d83jdc5mh3hu14",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/b40ua7sle959d83jdc5mh3hu14/GqcHySGXcKcMn5EzomCrStbXK_4/m100/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "e0e652f6-9cd4-462d-b695-4ceaed3828b6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000059",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "sanjeev-kumar-as-Mirza-Sajjad-Ali.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000059",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000060",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "j0et31ijtp30jbl5m99gevd56m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/j0et31ijtp30jbl5m99gevd56m/X8pJzW0rPY4JWSBEAckJXWMcwy4/m320/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "9ca967f0-b4cd-4270-a283-d71f92abb708",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000060",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "shabana-azmi-as-Khurshid-Mirzas-wife.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000060",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000061",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "6qtnc3vthd79l30of2icu48f6t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/6qtnc3vthd79l30of2icu48f6t/i70FEECcehtkQPCg5hFgljQhbWI/m240/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "1d6a9946-0b1d-45b6-9451-97e8ebf38d60",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000061",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "saeed-jaffrey-as-Mir-Roshan-Ali.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000061",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000062",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "c747m7s8790pfe26jrat70d96s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/c747m7s8790pfe26jrat70d96s/fgZVHyTiZs9AEGZnwkM4kdIgRfA/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farida Jalal",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "a4a589c2-afd8-40c0-9175-124f1c289188",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000062",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "farida-jalal-as-Nafeesa-Mirs-wife.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000062",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000063",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "fhbq2amh156kj105mm55ggl14t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/fhbq2amh156kj105mm55ggl14t/2MVbWEGcufMS_QINc1qMJTGd7a4/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "5ba22d20-0c5a-4ee7-8e2a-8e4cd41ed683",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000063",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "amjad-khan-as-Wajid-Ali-Shah-Nawab-king.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000063",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000064",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "jv6crp4hg11hffmp0gbft3ts3k",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/jv6crp4hg11hffmp0gbft3ts3k/CnzDxJvfk1WjvUtH4DF_cPq_GKk/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Victor Banerjee",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "44c1de81-5519-4679-ab0f-5e74d7cb98cd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000064",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "victor-banerjee-as-Ali-Naqi-Khan-the-prime-minister.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000064",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000065",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "9dq2gdsolp1ffe0akoub2ssh1g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/9dq2gdsolp1ffe0akoub2ssh1g/DikTBV2e_WjnGfpjO2fTsTujWHA/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "04f72a78-9b5c-4e77-bd11-9dbeebcdf4bd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000065",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "richard-attenborough-as-General-Outram.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000065",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000066",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "5nmjgh4kq53p78sl5m32ornt1g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/5nmjgh4kq53p78sl5m32ornt1g/5SFCgXw19kxtxZJvLkXs9WpFd20/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Tom Alter",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "1440ffe5-2799-433a-b503-d4770989c946",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000066",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "tom-alter-as-Captain-Weston.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000066",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000067",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "8skon4tijl53378up5ov1j8b54",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/8skon4tijl53378up5ov1j8b54/8tc-XSmqSvQhIVZLgZs1z6OHBNY/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farooq Sheikh",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "500a6add-d261-42a0-bb8c-d0a4b4e40563",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000067",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "farooq-shaikh-as-Aqueel.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000067",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000068",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "gkub9lksu15633741l42mr2150",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/gkub9lksu15633741l42mr2150/lBFHidmR5wlCVtzv_zZhE1IeeQM/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Barry John",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "289e0f26-2a91-4b2f-a469-f154e6992e03",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000068",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "barry-john-as-Doctor-Joseph-Fayrer.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000068",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000069",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "5rpvu8um31091ainvg80bibl7h",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/5rpvu8um31091ainvg80bibl7h/Ux-Pc4DCfB9mGo95vBcS7LpSYSw/m320/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Kamu Mukherjee",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "7832099b-ab8e-4562-9c96-e6d018f3df5c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000069",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "kamu-mukherjee-as-Bookie.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000069",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_c_credits",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_c_credits-0000070",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "5a6pbujn7517vdciukn5smlj35",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/5a6pbujn7517vdciukn5smlj35/mi6En9jhJvEAS-jNz_EuJTXoOs4/m500/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Agha",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "e591ae09-8000-4402-bff3-c6761e0a5e77",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_0000070",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "agha-as-Son-of-dying-lawyer.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000070",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Certificate",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-certificate-0000053",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Introduction_Specification | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "uj2kkmp8rh05v8s88dtlp3jk3s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/uj2kkmp8rh05v8s88dtlp3jk3s/t0cD2JWZGQKGfYIUkK76RXx9Tzk/m640/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "2853c1f0-8b76-4895-8143-b805cc4d3059",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_intro_cert_0000053",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj-Ke-Khilari-censor-certificate.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000053",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Cine.ssb",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-ssb-0000013",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Synopsis | Left Hand side | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1tf5g1jj251ap5kccpbrhlq45n",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4f22baf5-3a6e-44ef-9519-fb80a0b285d4",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000013",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Song Synopsis Booklet",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "1109158.CINE.ssb.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.ssb",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000013",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Cine.ssb",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-ssb-000013a",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Synopsis | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1o0af7bfm10oj37sl66n4ro65g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3bc5992a-9387-4dfb-9a14-cc850039add6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000013a",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Song Synopsis Booklet",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SHATRANJ KE KHILARI_1977_SSB_02.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.ssb",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "000013a",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Cine.ssb",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-ssb-000013b",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Synopsis | Body of work_Lyrics & Soundtrack | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "i2kmom2va978dc8oepssan7c21",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "465fc3a2-30c8-4937-b37b-29609070c482",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000013b",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Song Synopsis Booklet",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SHATRANJ KE KHILARI_1977_SSB_03.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.ssb",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "000013b",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Cine.ssb",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-ssb-000013c",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Synopsis | Body of work_Lyrics & Soundtrack | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ct9tlok12l7k99tvog0gtrj50u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ct9tlok12l7k99tvog0gtrj50u/scLrHQp8cqEkHBQxjGtjW89Se8A/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "dad4e0ae-b061-48ed-b74e-2e51b79dae08",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000013c",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Song Synopsis Booklet",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SHATRANJ KE KHILARI_1977_SSB_04.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.ssb",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "000013c",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Cine.ssb",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-ssb-000013d",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "lupdp08gtp4f703ffqom2l051s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/lupdp08gtp4f703ffqom2l051s/4pNMlGP7t2pZVVPJVJhZhgAtdhY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f91a9d59-b651-45a8-84e2-990b223dc3c6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000013d",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Song Synopsis Booklet",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SHATRANJ KE KHILARI_1977_SSB_05.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.ssb",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "000013d",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Cine.ssb",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-ssb-000013e",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "4eq7ffruq95o3b0v95ip16mo50",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/4eq7ffruq95o3b0v95ip16mo50/YxsUCcxYqAWUvNhTz2RrXWZSZeI/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8dd9b8d9-1099-4890-a7bb-aa797b4a02d1",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000013e",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Song Synopsis Booklet",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SHATRANJ KE KHILARI_1977_SSB_06.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.ssb",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "000013e",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000071",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "n53iqturnp3q1a324550hnj63q",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/n53iqturnp3q1a324550hnj63q/cNJGeczHIAhyUUZvmvg9WIQNCH8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4b7b497e-e2c5-4be1-bd18-30d3c456d837",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000071",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_01.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000071",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000072",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "jnavck4mv51ej2ljtirg9hdi5g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/jnavck4mv51ej2ljtirg9hdi5g/OcdWctmRevkMH8Uixo_bP6GGuFg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "cd7c7a46-0d3a-4781-98d8-3f15e6ae355f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000072",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_11.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000072",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000073",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "j6k9fpllfp2ed138qav5givt0k",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/j6k9fpllfp2ed138qav5givt0k/87_FEj1KeCTkoKEyOiP4ESeYT8Q/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Satyajit Ray | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "5fb58f4d-07ab-4d2f-bbb8-97fe468da8ad",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000073",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_08.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000073",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000074",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "g2s1s44tah253djbmdd99edf5n",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/g2s1s44tah253djbmdd99edf5n/ZSME5ER5PwPbwOBC6B5reP1861A/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3c70f40c-d8ed-4863-a646-4010dd1e9c70",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000074",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_02.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000074",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000075",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "fsi3cg788p5353n7ug043kkl74",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/fsi3cg788p5353n7ug043kkl74/zqcDhC1V_bnajCpc6g77bYt9CKg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "16b359b5-3dc3-4551-9642-947b86bf6b57",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000075",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_03.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000075",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000076",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "d87skdd2gt7ide6etmpekecs0s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/d87skdd2gt7ide6etmpekecs0s/Da407O3dFJ2bwvweTCa6O-E_E-0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ee2137fd-334f-46ad-a58b-1e618b477843",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000076",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_20.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000076",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000077",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ra54q2cgn920td7khpq90rug44",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ra54q2cgn920td7khpq90rug44/FSSQHVF2TEO9mT6EZiiH0UGiblg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey | Agha",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "2f5f27ce-561b-47a6-ac8d-e9411880fbd4",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000077",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_25.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000077",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000078",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "kkv3c7gho12sn815ib70nbdk2l",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/kkv3c7gho12sn815ib70nbdk2l/5NkPdeCszQ_sz4TbQP5vxtFWZK8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c89b1295-bfef-4c71-951d-aa0dc38d1af9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000078",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_16.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000078",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000079",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "57ck25udvd4p93nihodm7gnd3p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/57ck25udvd4p93nihodm7gnd3p/yJ5wVoGBwwKSUwQALb_fs2v6uws/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Victor Banerjee | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "9b7a21f5-7c2a-4cfa-a86c-bc67a56a86d6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000079",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_17.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000079",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000080",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "b2tjrlpqpd0uv4ao167tl71q4a",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/b2tjrlpqpd0uv4ao167tl71q4a/8gPaoFaMlQw-Zg_ONEwJR6FW0xQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b7a0131c-9f23-48a9-82cd-b870b67fb6a9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000080",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_24.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000080",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000081",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "476qf6augt1tt9or47iulht62e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/476qf6augt1tt9or47iulht62e/vnYlUZBnqn_t4WK22yQL-iRtarU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "d4069535-59fb-42bd-8a9e-c6db730bff6e",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000081",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_15.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000081",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000082",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1no8pm7kdh5bt6gtcb3ug8vl1o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1no8pm7kdh5bt6gtcb3ug8vl1o/qw0UC6NLpNWK4ZwDX_W--E1GLnI/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "6ea0d6c0-0cfa-4510-81e5-b3c061e267b3",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000082",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_27.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000082",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000083",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ifu7ujd9b95ndd1aauh8v3t56t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ifu7ujd9b95ndd1aauh8v3t56t/fx5U73rz8hTFJDiczyLPBEL67A0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "d1fdf841-87f0-43d9-9e80-6037e45b8c29",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000083",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_23.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000083",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000084",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "06jrtk2ghh3tjb78gsafikp452",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/06jrtk2ghh3tjb78gsafikp452/TWy1S70bjVEKQS_CTAdadvE2zj8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farida Jalal | Farooq Sheikh",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8d8de9b0-856f-4dff-9638-7668dc312a1b",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000084",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_14.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000084",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000085",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "kfd2m8t85t6b9e56vdv2lrfa5a",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/kfd2m8t85t6b9e56vdv2lrfa5a/fX8Tp1hjvJ6E0BkDUROapaEotkg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farida Jalal | Satyajit Ray | Farooq Sheikh",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "54d974a4-b7b6-4543-a2f1-b1e551305b48",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000085",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_09.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000085",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000086",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "fjbuiu90ah3blf1vi0rcpfmd0s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/fjbuiu90ah3blf1vi0rcpfmd0s/bx6Lkik-r82OfXkNZOJ50Ej8gMQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev  Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "1de1ed14-301d-44fe-84a5-25671cc15d86",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000086",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_22.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000086",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000087",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "e6055ibug96e94d98cqn457i19",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/e6055ibug96e94d98cqn457i19/R-JxnHsnuL5-9_MiHqFepJt1-aA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farooq Sheikh | Farida Jalal",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "6743c69a-81be-498a-b83d-be7cc0f56c19",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000087",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_13.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000087",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000088",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "u7a1u0tgs16jb05b4qjvu7ps7g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/u7a1u0tgs16jb05b4qjvu7ps7g/pv8iSLb8QiNOomodkM69xG8mWvc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c370c31a-e517-45a4-a2f8-82904b231297",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000088",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_26.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000088",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000089",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ptcuv8d53h0np032mbpg5k994j",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ptcuv8d53h0np032mbpg5k994j/JPRuy77lqjdLk-majUt_2DhQ3WQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Amjad Khan | Victor Banerjee",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f92326e7-ad61-4110-b358-b038615392bc",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000089",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_07.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000089",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000090",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "plqnf72ndh7kp0mapcv041js6m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/plqnf72ndh7kp0mapcv041js6m/o-aBVjimiBi6fp142qxOmQ3hZhQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Tom Alter | Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "85eac183-dad0-49ba-94e6-b2090dfe4e4a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000090",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_18.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000090",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000091",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "nkiu2fcn9d0gl316vqvaral84p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/nkiu2fcn9d0gl316vqvaral84p/3LNmmeAVRGPTfhqdXxWWDlVRWSU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Victor Banerjee | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b5c7fa2e-e6da-4937-b92f-853c0420aad8",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000091",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_28.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000091",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000092",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "hsmmo6cvl13rd8pbca5ipl8u3m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/hsmmo6cvl13rd8pbca5ipl8u3m/898Oro-h7IxUczCpFhxzZjuPvw8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "bf7d8d75-032a-4158-b216-bf4581f0eeae",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000092",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_06.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000092",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000093",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "bcllibqlpt65j86lnvh39di029",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/bcllibqlpt65j86lnvh39di029/bYRMSHBTQrr3xUpyAWIZty2z_Pk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "0d530445-021d-4808-9e3a-638099f3f30b",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000093",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_21.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000093",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000094",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "6u34t8higp6299b2prlghqh90l",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/6u34t8higp6299b2prlghqh90l/W1pkGBl_IRFeGvVfHY6pCs4Un18/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "7c62bad2-beff-4924-9a75-13e515d2c173",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000094",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_19.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000094",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000095",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "2fjeramr6h26j4c5o90b88be3g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/2fjeramr6h26j4c5o90b88be3g/R1CaQ8EwX0T1wK8FWif-c8t1cC8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough | Tom Alter",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "5c9e88b6-03cc-421b-aeb4-8b21133496e6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000095",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_30.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000095",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000096",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "gl8tvdelcl4pn9mb01kcn8tn14",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/gl8tvdelcl4pn9mb01kcn8tn14/zG3QCUDEphX7nekVGKr-4YsbwY4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "a550a91c-4e63-4b95-8d22-131e3bbee0e5",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000096",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_04.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000096",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000097",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "i3i6abcand2q3257skibmt5v38",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/i3i6abcand2q3257skibmt5v38/SF1RcGvbPD1saojdBWpw0CeoGFQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "6605ce8c-5396-43e7-b442-f0a27b9d5e81",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000097",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_05.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000097",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000098",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "dhr6tfr2qd4bv7eog0ofip503j",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/dhr6tfr2qd4bv7eog0ofip503j/BdCScCMfyY3uL4AIQWcBQHK8h1I/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "0b337768-9d0a-441f-8034-2037cc5860c9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000098",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_12.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000098",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000099",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ni80bqb3sl4nbbn9bnpcuhge5n",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ni80bqb3sl4nbbn9bnpcuhge5n/vhGsVXCw05-Sgi4xR2hQ23Eqv28/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "87a20f01-041c-45b6-8cdf-de82b167503f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000099",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_29.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000099",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000100",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "pea9qnqk392ij29h7r0ckf0128",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/pea9qnqk392ij29h7r0ckf0128/V0b5QyvzILcgpRLcZBwkQAGJjNY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "eae95ae1-36bc-42b3-b576-7abe3c57bc81",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000100",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Nemai Ghosh 005a.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000100",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000101",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "7go1fuqclh0vtc6ijdt25ic73t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/7go1fuqclh0vtc6ijdt25ic73t/SH0PNi1CbyZkxFlu4Bi4Un0kd_U/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "d28e5b3a-dfd6-4a08-bb1c-8e7d93bfabdf",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000101",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Nemai Ghosh 005b.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000101",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000102",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "vkha0vo83l7v55q7i55gp68447",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/vkha0vo83l7v55q7i55gp68447/tnZU2bf5zydqvLjyFZNoMDbaTos/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Amjad Khan | Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c54c4341-30f6-4b6d-8a42-5f86454af7dc",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000102",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Nemai Ghosh 021a.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000102",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000103",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "p8ftcfp2rh4c5ddm3ivar7qf6p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/p8ftcfp2rh4c5ddm3ivar7qf6p/0uWWBrgBcfwU3kSDjVQ4PHhKF3s/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan | Victor Banerjee | Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "9da4acf7-f181-4511-868f-429f1695eeab",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000103",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Nemai Ghosh 021b.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000103",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000104",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "34pc7tb0kd33fb416u0dfobr2v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/34pc7tb0kd33fb416u0dfobr2v/VefQg6goUA0MclBp-3OE-1QFOzc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Agha | Nasser | Saeed Jaffrey | Satyajit Ray | Bhudo  Advani",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "e89a8ac3-025e-4af8-a4c4-05b30f9ca4fe",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000104",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Working Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_10.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.wks",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000104",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000105",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "452qlusrd16t9c2a16npjeke0e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/452qlusrd16t9c2a16npjeke0e/SdrKqcrZgLNRHWVR7TiKtGnTh6k/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Kamu Mukherjee | Sanjeev Kumar | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "353225a2-5a33-4feb-9e8c-6013bd54edb1",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000105",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_31.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000105",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000106",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "l5gl8uf0d97qlb1egq556tk62l",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/l5gl8uf0d97qlb1egq556tk62l/3GeRAUszl3PFTJmEYSrrpVRwalM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Barry John | Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4941f920-d1f4-4aae-a94d-80c76d1fa9ad",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000106",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_32.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000106",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000107",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "c4vsh3r6h5247fbaggg74ggl3t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/c4vsh3r6h5247fbaggg74ggl3t/urD_6Ce7Cgzsz8F8dxZyrsruAjA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "1317971b-d77a-42c1-98d6-898a0f195407",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000107",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_33.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000107",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000108",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ruor2naspl6lt2g5k0o0b8bu2u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ruor2naspl6lt2g5k0o0b8bu2u/yEsNXid2TiRiqtIBtaSuCUW-r0s/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar | David",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "eccafb16-42a6-4c53-9459-4a297a4046aa",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000108",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_34.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000108",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000109",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "jk568tbujt329e0c2gvvdlnd50",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/jk568tbujt329e0c2gvvdlnd50/6osbhVoDgJ0TvnbAhn78JdFPv_M/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "0b30075e-c0e6-4eca-b6b1-18c14137672a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000109",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_35.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000109",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000110",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "s8egao1qel5o75076tikjfdl2u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/s8egao1qel5o75076tikjfdl2u/HVQ-9Wsm5x8rpZ1IdgOaql47JG8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Victor Banerjee | Amjad Khan | Richard Attenborough | Tom Alter",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "26444450-668b-4673-8084-15eefa1cf76c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000110",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_36.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000110",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000111",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "mdm4v7i4rt27ndgos9occu235a",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/mdm4v7i4rt27ndgos9occu235a/gANSoUHWph0UCcMjXCD5K2Mff5s/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan | Victor Banerjee | Kumar Bhatia",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3aa56ef6-0cc6-4bd8-aeac-b7726b33545a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000111",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_37.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000111",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Working Still",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-working-still-0000112",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Nemai Ghosh",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Body of work_Editorial | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "i1uadu7l9t7r77h9lburob1o3o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/i1uadu7l9t7r77h9lburob1o3o/XIZ5xYmSI0NHYlOV4-OYTRhGPtA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough | Tom Alter | Victor Banerjee | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "28be7d38-61ce-433e-9cb1-ef3d297ac1dd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_bow | 0000112",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Photographic Still",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_38.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.pho",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000112",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "0003_landing",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-0003_landing-0000011",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Landing page_introduction | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "grjvp7snud3sl6s03qm88t9r6j",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/grjvp7snud3sl6s03qm88t9r6j/ZhxfHEI-REn6gvygDk9KDyXxrRk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "bf6de722-0874-4504-94bb-4041cf6d4bf9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_intro | 0000011",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SKK-landing-pg.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000011",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000113",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "c0kj83lgfh5d1epmphhc7v1i6d",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/c0kj83lgfh5d1epmphhc7v1i6d/psvZ6WLuGRioSPfSIUV7e64Fe4s/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saswati Sen",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "cd84c61f-9b83-4e9e-aeae-7641c9fb7818",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000113",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "0815221.CINE.lby.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000113",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000114",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "kffmi88vp169d6dhr2gl71244n",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/kffmi88vp169d6dhr2gl71244n/YdPIHKf6C2FdC3apyDr_Td8thls/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saswati Sen",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "dcbbac66-e1ad-4e7e-8323-1382f1b6766f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000114",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "0815222.CINE.lby.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000114",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000115",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "5ijqcadqtp2ulanllrtckf7v3c",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/5ijqcadqtp2ulanllrtckf7v3c/v-WQZa9fhdnGELciKS74eWaijxY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "56a4ee4f-2905-4359-a978-51bf540bce3f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000115",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari (Ref)121.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000115",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000116",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "tgooitqeb52ot8epro043i691b",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/tgooitqeb52ot8epro043i691b/FPS_ajxMa7hmfWV1yJlRjAqCj3E/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "57da1080-834c-43d0-a051-b6005508d026",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000116",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164A.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000116",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000117",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "gbmbc0k9q95qv03b6qdqfjgr4s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/gbmbc0k9q95qv03b6qdqfjgr4s/JEvWxT_s_-ijSC8X1QVEiK_RcoM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Tom Alter | Amjad Khan | Victor Banerjee",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "abef4ab5-7ecb-4063-be13-619f85f19d96",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000117",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164B.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000117",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000118",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "209u7agjel1jrdktft26d8bc3e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/209u7agjel1jrdktft26d8bc3e/f-B1-FB9-_JkdEuwcvxXVTm3Wn4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Victor Banerjee | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "e9ae6c5e-4e41-457d-8ea8-6cca0de3c6fa",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000118",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164C.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000118",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000119",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "31sc6jdbgp1rjeibhhk188d103",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/31sc6jdbgp1rjeibhhk188d103/zGN5F0-6fUyVGTRzRuv3_nC01_k/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b1853753-6596-403e-b35c-b407b158b852",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000119",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164D.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000119",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000120",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "9riol01tm951r5ro2hhh1tl10v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/9riol01tm951r5ro2hhh1tl10v/mpi245WwWTIzq2ztygrmzxcLNSo/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Kamu Mukherjee | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ceab415d-3a82-4bab-854a-7a0a2e89311b",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000120",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164E.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000120",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000121",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "piash33rkd1fn8meohl5d4gi78",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/piash33rkd1fn8meohl5d4gi78/0qPd21PdMTm5khTEtyY46qHlSHg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "bf454918-c354-46df-bac2-aa2a9c34f346",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000121",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164F.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000121",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000122",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "dijr3cvnbl33j8r7delruo1r0e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/dijr3cvnbl33j8r7delruo1r0e/Nt7MXaHokFFtlYIoQdimchhGTGw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "0be4e47a-cf4e-404e-ab99-4b60bbd15693",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000122",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164G.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000122",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby-0000123",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "7fh3cvg98t1g3clocrvri32f2d",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/7fh3cvg98t1g3clocrvri32f2d/QLG355lHlF8EIhn483cekYVEcPw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8e33690c-21a5-48ba-8c74-d51985df1bb5",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000123",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari 164H.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000123",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000124",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "h40rvif8594jl07kp1em41o070",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/h40rvif8594jl07kp1em41o070/8s-Jd-BSzNc0itlApxjABvGX8wY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey | David",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "046a3bd6-6b20-4225-812e-617784911f4d",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000124",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_01.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000124",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000125",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "3ndk0tnr516et5nlf69s0hem3a",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/3ndk0tnr516et5nlf69s0hem3a/mz-qdn1f_29VxYOARajSOjXBVvg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "deb09685-04d7-42f1-9d2a-3e579433e9bd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000125",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_02.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000125",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000126",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "cu7hifn4k50frbsc9au0qur14a",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/cu7hifn4k50frbsc9au0qur14a/GY3Kx_Wm2pjH_n4nlkTiNFG2D_w/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "104f8af3-3a32-4294-85d6-968db190a8c6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000126",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_03.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000126",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000127",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "gcbrebe5il1q59ut36k0kaom23",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/gcbrebe5il1q59ut36k0kaom23/uKFW93LWvUnW3JCClPJD6CTZYow/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ff4783a2-9930-48bc-b9bb-0454d6f91a72",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000127",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_04.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000127",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000128",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "e5h220g3kl48jbbjfqk7d01k0i",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/e5h220g3kl48jbbjfqk7d01k0i/B5U45J3ZgO1hlYUkLX5bc-eIm1M/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "fce78d15-3d84-4500-b850-dfab0029f867",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000128",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_05.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000128",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000129",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "okvm51ue2l0kjfrlgl24oqsm2c",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/okvm51ue2l0kjfrlgl24oqsm2c/2E7k8mocLhaSe46XlKmRic2Puqk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Victor Banerjee | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4ad7f356-92ce-417c-8e24-510ff7d644cd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000129",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_06.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000129",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000130",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "t9qr5ro0q54gp6g99ho80tka36",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/t9qr5ro0q54gp6g99ho80tka36/AhzwI5haztX7xg-6WZhm1okZO6Q/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Veena",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "2ffce559-e2e7-4af0-9b1f-f2cb97cfaf27",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000130",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_07.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000130",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "CINE.lby_Upcoming Auction",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-cine-lby_upcoming-auction-0000131",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Satyajit Ray",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "6f99d1hbvd3ah8v4rln2r6507o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/6f99d1hbvd3ah8v4rln2r6507o/B6L8NhGDcnDEV2143oZqytSMXdM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saswati Sen",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "cc8ff880-a7da-4641-9975-50aa5aa5315b",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000131",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Lobby Card",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari_1977_LC_08.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.lby",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000131",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "ECONOMICS",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "economics-0000132",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "17ksdijd1h5mn3h5ee4va5jd3b",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/17ksdijd1h5mn3h5ee4va5jd3b/cub8O89-An-fIx8ABQS67vDPIgM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "69501226-eae8-409e-b69e-b3401bb20cf9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000132",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Newspaper Articles",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Satyajit Ray and Suresh Jindal in Hong Kong_Film_Information_30_06_1979.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.new",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000132",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "ECONOMICS",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "economics-0000133",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "d8ddrnkmet1p9auocnteafrg2v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/d8ddrnkmet1p9auocnteafrg2v/XD1wVBW1b4oBuywxc8vNFDZSk2Y/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "da364668-70bd-416e-a902-b81db19856d2",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000133",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Newspaper Articles",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Shatranj Ke Khilari Jubilee in London_Film_Information_14_07_1979.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.new",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000133",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Costume",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-past-auction_costume-0000134",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Shama Zaidi",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "a7d1qjj5sp61jclm584kg9ta34",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/a7d1qjj5sp61jclm584kg9ta34/WLtqjZEjy5wBfyisHwZ2apMTx4Y/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "dbf2a905-693b-465c-85fc-1c16a1526b27",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000134",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Costume",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Costume_DSC_5690.JPG",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.maq",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1977",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "Costume worn in the film Shatranj Ke Khilari",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000134",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Costume",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1978-past-auction_costume-0000135",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Shama Zaidi",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "lg75sbta3p3nf6rf4kcs91us3g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/lg75sbta3p3nf6rf4kcs91us3g/tPQoq0ATMH5QdgOGjcwsOtu7jU4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "511ba977-551a-45ec-afa4-81249b1a419c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000135",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Costume",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Costume_DSC_5695.JPG",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.maq",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1978",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "Costume worn in the film Shatranj Ke Khilari",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000135",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Costume",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1979-past-auction_costume-0000136",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Shama Zaidi",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "6qko9g51fh53hfkp0gavq9te12",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/6qko9g51fh53hfkp0gavq9te12/QKT50cJM0MJMuHEORdFYnyQnS68/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8110ce37-4bd2-47fe-b0ea-05811e3ae980",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000136",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Costume",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Costume_DSC_5716.JPG",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.maq",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1979",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "Costume worn in the film Shatranj Ke Khilari",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000136",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Costume",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1980-past-auction_costume-0000137",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Shama Zaidi",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "78ebevh3q16ft4hdu9m7gqln26",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "1977",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/78ebevh3q16ft4hdu9m7gqln26/2hwMxW0yW_G4tQ2Aj1Mmu3OprTM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c190ea2d-de1a-4fd9-bd10-a1a24d16abd9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000137",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Silk from Benaras (COS), 1977",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Costume_Shatranj Ke Khilari_Sanjeev Kumar 121.psd",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "CINE.maq",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1980",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "Costume worn by Amjad Khan in the film Shatranj Ke Khilari",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000137",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Event",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "past-auction_event-0000138",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Osian's",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "lh10i2scah4vhb26bq2r2a5q07",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2012",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/lh10i2scah4vhb26bq2r2a5q07/3vqXOCbfpH8MRJfCep_sjC8KhVo/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f56dfff6-440a-4f0d-94d8-7fbc815cff13",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000138",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "OCFF 12_0042.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "OCFF 12",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000138",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Event",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "past-auction_event-0000139",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Osian's",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "vua5p0lo1l691aolrfh6459i2f",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2012",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/vua5p0lo1l691aolrfh6459i2f/hHDfwlPP5o0r_9XCRAo2dGTehxg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c977fff4-82df-4f20-b05b-49356b61d670",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000139",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "OCFF 12_0043.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "OCFF 12",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000139",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Event",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "past-auction_event-0000140",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Osian's",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "c8as9km1r103dfsd0nf9qm7n5m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2012",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/c8as9km1r103dfsd0nf9qm7n5m/jyZQHcLI8wCp_gk84eJMfLfrv5Y/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ea96325b-4630-47b8-9995-2269f38a2700",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000140",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "OCFF 12_0044.JPG",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "OCFF 12",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000140",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Event",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "past-auction_event-0000141",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Osian's",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "b77nqn4kqp0pne28e79clid021",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2012",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/b77nqn4kqp0pne28e79clid021/RNqowu_jMKyp4xGJGR1fiMiFho0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "53356c76-8ac5-46a2-84f9-c823c2568286",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000141",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "OCFF 12_Auction_1003.JPG",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "OCFF 12",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000141",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Ring",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "past-auction_ring-0000142",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Osian's",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "g2ndp2s0tl54j939niud14kh0s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/g2ndp2s0tl54j939niud14kh0s/nWPgPozynn3hAqVQrQNcrtE7GNw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3e145ece-bbe7-4660-888e-24ff263a079a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000142",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Ring 144A.psd",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "This ring, a fine quality Neishapuri Shajri Feeroza is worn by Farooque Sheikh and is shown in a romantic sequence in Nawabganj when he is with Umrao Jaan",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000142",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Past Auction_Ring",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "past-auction_ring-0000143",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Osian's",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1s3ct7b6ed1331b3qf8eie1a0a",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1s3ct7b6ed1331b3qf8eie1a0a/jlmHXsKioJUF1jS7--E0e0HYUTA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "27d20f9e-3136-4612-8883-f880d10ba8b0",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000143",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Ring 144B.psd",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "This ring, a fine quality Neishapuri Shajri Feeroza is worn by Farooque Sheikh and is shown in a romantic sequence in Nawabganj when he is with Umrao Jaan",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000143",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "zzz-0000144",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "htkvbkcish43l22nplsm77tf4c",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/htkvbkcish43l22nplsm77tf4c/cOxC1DYZpe9LC-ad2YXNBDEYoM8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "107e9fae-075e-480b-8d7a-c6c721705b4e",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000144",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Zoffny, J 01.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000144",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "zzz-0000145",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "73gd16p2dt6t5466bb6miog158",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/73gd16p2dt6t5466bb6miog158/UmQ0-mt-1KwRZNBbEIRZPnJpKNA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "590927f2-874d-43a7-b3d6-48259c40710e",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000145",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Zoffny, J 02.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000145",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "zzz-0000146",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Collections | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "lqdtfuvfid6ah7a60mmcu3hi5r",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/lqdtfuvfid6ah7a60mmcu3hi5r/XzhNK0kXvt62TlpQUIXJAoza0b8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ec29f36f-c942-40ed-9954-d6804610643c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_ref | 0000146",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "Zoffny, J 03.tif",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000146",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000147",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "q67095kdnp5fp2r8d2c02rtu53",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/q67095kdnp5fp2r8d2c02rtu53/4EmN-s3VNr8sxXJJ9tvEH8xwGnQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev KumarSaeed JaffreyRichard AttenboroughAmjad KhanVictor Banerjee",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f27aa77b-ce89-4122-9cf3-443c23d4bfa3",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000147",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with Satyajit Ray_2017.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000147",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000148",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "kpj1v38rf93lrf0ulfqgg19q02",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/kpj1v38rf93lrf0ulfqgg19q02/z29SD0EfXxiSRYYbU6MEFrcKMSY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "171894fb-6904-4605-8380-c5af5075d970",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000148",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img01.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000148",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000149",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "s6r0ga0cll4vpdj5ek8ku6t73q",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/s6r0ga0cll4vpdj5ek8ku6t73q/ZIKN1Z693Y8zAbKL3hND1UkV5o8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "531201d8-ec31-41d5-83d8-dce9f777299e",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000149",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img02.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000149",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000150",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "8bigh473qt3nn0e2t76he6ja4s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/8bigh473qt3nn0e2t76he6ja4s/3KuIuCIh2rQoXn03FGTtI1P8rGA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "aa8abaaf-92fc-418e-a081-7fe7aaeb6fe7",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000150",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img03.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000150",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000151",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "d0spcevjv17uh42qt1iiai7713",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/d0spcevjv17uh42qt1iiai7713/SoiLew8ePVI3nimBQbH_ktl6vP4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "32458511-33f4-4798-916b-dcae47013633",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000151",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img04.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000151",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000152",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "5riflg5eth1472rhonmgkm3e64",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/5riflg5eth1472rhonmgkm3e64/cJvtQgx9PpAMHky0OXw2tAS8Dfw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Tom Alter",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "44878705-d2e1-4e5c-8e1e-a9d61fb807ed",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000152",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img05.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000152",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000153",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ghcthgr25d2410jfspdqgpf836",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ghcthgr25d2410jfspdqgpf836/pYAE8PPclOEQQrRzh8RrbmTUR7g/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3e2fab66-d677-46b9-8e60-eca3112dbad5",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000153",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img06.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000153",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000154",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "6kku0uqpdt7ov2a8ofdbb0vr51",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/6kku0uqpdt7ov2a8ofdbb0vr51/DGFKPif817NNUUDOlRUPf-S3RMk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "761772f3-a042-46e6-a837-0c11b6770bb9",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000154",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img07.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000154",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000155",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1utojs38ml21fbu8rv026fv66o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1utojs38ml21fbu8rv026fv66o/V9m_zJh3agwt3K9BQTJoL1-x2kk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8037753e-6a21-41c4-88e4-f7640c851260",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000155",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img08.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000155",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000156",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "dl48lmdelt0fvf2mll0eobsp60",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/dl48lmdelt0fvf2mll0eobsp60/7RqP_gHmyw6OqE5Qj1m69yrGATM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "93b50cc0-266f-46e9-802d-eac6fb2f08ac",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000156",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img09.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000156",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000157",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "8ql093fngh035951t0a0icr25m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/8ql093fngh035951t0a0icr25m/Mu5FRhpskUmA_LGGc_pPY_cjX98/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "621d48d5-7e2b-4cbc-a930-9385cf2b9ae5",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000157",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img10.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000157",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000158",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "o4ce3k8amt5tn0sdg8ilq3cd4l",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/o4ce3k8amt5tn0sdg8ilq3cd4l/-bJRKAI5YLp0LAqEPHN2ComxscI/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8cd2af43-120a-4ca8-95e0-74e718df38e2",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000158",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img11.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000158",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000159",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "e58ej2s4r95ph8nbdm0fs1h65u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/e58ej2s4r95ph8nbdm0fs1h65u/Ag431pusf4zFKB43XCJxxHHB9Qk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "d1d16519-8a7e-45cb-88ea-573344501709",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000159",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img12.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000159",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000160",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "l2mfskgtd96rr3j65kksp2a77r",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/l2mfskgtd96rr3j65kksp2a77r/JfATeYco_XqxF6VV5IAcsC7lm5Q/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "49ff171b-a2f8-4765-832f-d6f0b5f4016d",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000160",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img13.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000160",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000161",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "994pjh88vd3674ucrmt0gfqs41",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/994pjh88vd3674ucrmt0gfqs41/jT7mfulXywZ8lbQk0CTFYx1jPnw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "35955204-af58-4afc-8632-24472e6f1ed4",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000161",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img14.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000161",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000162",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ge300uauv12pff363gijd6et10",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ge300uauv12pff363gijd6et10/uNpI9HsV4aHwr-maNLaVlf5Ybew/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "0627fde2-a153-44e7-a4ea-ededd6d7428d",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000162",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img15.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000162",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000163",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "rvr7j2rpnh45v50oid13d2mj7m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/rvr7j2rpnh45v50oid13d2mj7m/p8pkZOwDjjhF7YBatnuwbFP-AGc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "652eb9de-46a9-4cb0-9025-9cefc055287a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000163",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img16.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000163",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000164",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "d7l94cukbd2gp1a252b3ufdu3u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/d7l94cukbd2gp1a252b3ufdu3u/4WELU7u908_sLKzsu8C-V_QSvM0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f358750f-f756-4f6d-8896-f8044d392185",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000164",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img17.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000164",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000165",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ndm4a99q910rb8491ur042n67o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ndm4a99q910rb8491ur042n67o/TSMqIidvxrnVKLgQPsOzmRkFLQs/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3c333d96-87d9-48a3-a02c-a3aac6a7a858",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000165",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img18.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000165",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000166",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "vaeev9ienh5ch1ojqv7uepml3k",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/vaeev9ienh5ch1ojqv7uepml3k/M6Mie7NJYWHUOn9fvBFkQ5ZGGL0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan | Victor Banerjee",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f84bb245-9312-4fce-b8e2-629f34dc18bc",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000166",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img19.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000166",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000167",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "kpt632poo95c91cappjpv5ib08",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/kpt632poo95c91cappjpv5ib08/3YGH2Tsq8ZOrME7euc0JzmH9T_w/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "fed2cd20-a8b7-4668-8acd-de1d50f43d5f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000167",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img20.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000167",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000168",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "c4ojq10rr96279bstf4bqg117d",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/c4ojq10rr96279bstf4bqg117d/xzwBmIU2p2GePi9m8nnMC5zr2Dk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "8542fcde-069e-4fa4-9fca-cd7294fb39ac",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000168",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img21.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000168",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000169",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1f3oo6cq1h4td75ev1a49m8e6s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1f3oo6cq1h4td75ev1a49m8e6s/tWPOc8ao12lPUZ3RG28suc-Yveg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough | Tom Alter | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "82f7cf97-4d38-45e3-a43c-8c348cd1ea57",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000169",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img22.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000169",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000170",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "cfsbk0444t1j95u0gq63jqtp5b",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/cfsbk0444t1j95u0gq63jqtp5b/DrTzS0onc7ZS5310JaQd5VpkrZ4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Akashadeep | Victor Banerjee | Amjad Khan | Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3373a20a-4f8a-4a86-bb51-ad39aea39365",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000170",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img23.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000170",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000171",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ajmp6g2tst3tde6ag6c00tlk2o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ajmp6g2tst3tde6ag6c00tlk2o/pP0Uoxm8ubKhKAzYgIQjYS_iKXE/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "72019a57-6d2a-483d-a011-cc0f6fd82209",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000171",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img24.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000171",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000172",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "qhtabts7td0q1eslrh6nnd0v6v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/qhtabts7td0q1eslrh6nnd0v6v/nuK-QbjHNWR6PQZ9h0iLEuRnbEE/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "John Barry",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4d5555dc-6c46-4607-94b1-b3c36114fafe",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000172",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img25.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000172",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000173",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "bt0h5kvb6t4t34kfhtpgtcir59",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/bt0h5kvb6t4t34kfhtpgtcir59/P0efa3qQyeM4b8xhUdDDKFAhoFU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4fc67734-085b-411b-80a8-c63167e036a4",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000173",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img26.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000173",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000174",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "iqm18n9vb943712jf4qde4bh2m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/iqm18n9vb943712jf4qde4bh2m/2vbQdxGs7QEc1fDCAEwuGMEWVBc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "4338ee1a-5d27-4b20-9eec-c17ca065c031",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000174",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img27.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000174",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000175",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "prl521f4ud2275or18jfhk875p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/prl521f4ud2275or18jfhk875p/BlD0yN2HJImyUFyJ9uO1OZ8C7TI/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Saeed Jaffrey | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "e96e2b35-f11e-400c-bb47-9492160a1728",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000175",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img28.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000175",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000176",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "tjve42rbih1cf6bf04fc7m5a27",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/tjve42rbih1cf6bf04fc7m5a27/RCTYqx9mIIFv7Q6xPsvQ8-Vpl_Y/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "bb6d5a08-798b-46b9-93fe-e9cc3cd4ac32",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000176",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img29.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000176",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000177",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "o5jjnj3lhh2nf6ccavraaqci4q",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/o5jjnj3lhh2nf6ccavraaqci4q/sKyKsVlMXA-QNpJRgXI7hZW7U2Y/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b1703b87-9a52-40cc-b96b-a8c74d5ccc09",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000177",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img30.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000177",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000178",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "9e6fmd7b1t0ct9bs22pi266t7u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/9e6fmd7b1t0ct9bs22pi266t7u/fDD61uEDlgxFhJYNEIFLSwzFk-A/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farooq Sheikh",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c4164561-9e2d-45ff-b336-235b6e7fbd41",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000178",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img31.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000178",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000179",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Body of work_Credits & Cast | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "9of1070ck15d3dds9rl0jn0b56",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/9of1070ck15d3dds9rl0jn0b56/D3r0vQoBswPtMpHWmIKPdgSBQkw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farooq Sheikh | Farida Jalal",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "a9c63225-0d69-4546-a9b4-37c43fc2e6e8",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000179",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img32.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000179",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000180",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "hsf8dh0pct07h9lsfaahheg76i",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/hsf8dh0pct07h9lsfaahheg76i/F_u4_kZQc-e1UTA6NJwzMo_dymU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f6940179-ab73-4467-98f7-7a272adf3436",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000180",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img33.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000180",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000181",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "8jfsmq8ufp3of75hhja038g72p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/8jfsmq8ufp3of75hhja038g72p/C_ekiGazLSzaaV8G5gNOhKbe1Zk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ef1f9920-5711-421c-8d6f-964afa5a9321",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000181",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img34.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000181",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000182",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "iq6p14p7ih4q7ftm0m0r06en4p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/iq6p14p7ih4q7ftm0m0r06en4p/n-SqwvnSlPcd6mLQhT1-TnjuJBo/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "39444a31-09a6-412f-8c9b-199c782425a8",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000182",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img35.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000182",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000183",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "oc5crtuqud4q11mk2b91lqnt2f",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/oc5crtuqud4q11mk2b91lqnt2f/gzVUIeOMYCXyLdqoReaYEdOT9Jw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "6ec09bd0-2258-4e7f-ba69-da3d896e0b2f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000183",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img36.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000183",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000184",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "j7s4f02tjt30be1emj8fh3lk5m",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/j7s4f02tjt30be1emj8fh3lk5m/3tL-ykNj-TVHpxzA15JqSCfeMfY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b530f6a2-2f8e-4978-a00d-e4e94c593489",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000184",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img37.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000184",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000185",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "v5opibfkk166p8kbfur8i63o6v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/v5opibfkk166p8kbfur8i63o6v/7jPb_J9dc506Vtm_RSxiXeLnppo/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "a4400933-ca19-424f-8238-95a7b80d2066",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000185",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img38.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000185",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000186",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ak3ngdtdi123765kgpbenlbf1u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ak3ngdtdi123765kgpbenlbf1u/pdgqGypaAjY4-bLrYOYQAFqFpes/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f933d187-e07d-4f6e-b4c8-a30cd25fc8c6",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000186",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img39.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000186",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000187",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "9jntln4kih779b2g4n2ufglh59",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/9jntln4kih779b2g4n2ufglh59/GuyCEmG3rr0-DZpxu_yQQluso88/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "d002bf2f-0834-4377-b791-3848753969ae",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000187",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img40.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000187",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000188",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "4klac27j2902l5up7nfd4tpm07",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/4klac27j2902l5up7nfd4tpm07/3YdLrcwNPi9SaBleiI9ZOM_chjU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "726b8ef2-fe3a-46a9-b731-b2be1e80237f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000188",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img41.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000188",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000189",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "un4rludti94dt7ipof25o88a24",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/un4rludti94dt7ipof25o88a24/oCQQiIhHWljIHBReZJryW-bjUlM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "501fef6c-162e-4c05-9a89-872c34e21c5c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000189",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img42.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000189",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000190",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "qr38ocm7dt3e5911ldqk3nhn79",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/qr38ocm7dt3e5911ldqk3nhn79/kE_QrtNea9EhU01KbBe5yjpg7gk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "802cfe60-42af-44fc-8631-21542a71e119",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000190",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img43.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000190",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000191",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "jdga8tstbh7of8gavei4uuk464",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/jdga8tstbh7of8gavei4uuk464/YQEnC2ViSpi9Hju7zcS5bDRMuLU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "2efbc180-23b9-4caf-8cfa-ff48116df25c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000191",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img44.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000191",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000192",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "eprv53lh510vn90u9cj4oe9439",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/eprv53lh510vn90u9cj4oe9439/SLEqUjHyGR_ZAILs1GzfvNAmO-I/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "84c89e9a-687f-45f5-bcfb-e5d1418fcffb",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000192",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img45.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000192",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000193",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "1dm6d0mimt3bl0qhtsjcvjqr3q",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/1dm6d0mimt3bl0qhtsjcvjqr3q/84fUzgDEUoGGRc8CG5isTzpD150/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "adb94cf1-4410-4018-aab5-0ff4606afdf3",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000193",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img46.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000193",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000194",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "9525cqtuh536lbufbngjus235e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/9525cqtuh536lbufbngjus235e/q7cx7BItRbophrxKpa-DYtMfgyM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough | Tom Alter",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "795b7b8a-0e76-49b5-b8a2-665ee39d49ad",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000194",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img47.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000194",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000195",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "i07maq12jd6vrfroddn0kvtg6v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/i07maq12jd6vrfroddn0kvtg6v/88-5pa1pOPKiDR1x2q7zqDmWlKs/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Richard Attenborough",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "196f7b10-6c61-4d69-a0d2-4a9e00731316",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000195",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img48.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000195",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000196",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "gkv1ohra252dpcokn8p2l07h3d",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/gkv1ohra252dpcokn8p2l07h3d/5c1WyTli3731939yPwWG4IQvYP0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farooq Sheikh | Farida Jalal | Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "cfc4476b-44ea-44e2-8b39-71612f81b854",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000196",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img49.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000196",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000197",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "uead3pvqbd2552kd4u4mmltk32",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/uead3pvqbd2552kd4u4mmltk32/-UdxyhjbZMoGEkIKU8tXRxnk7To/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi | Satyajit Ray | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b147c041-904c-4ed8-911a-4fb7802b9267",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000197",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img50.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000197",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000198",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "futlsnq4cd27n6ukhqkgnhmt3e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/futlsnq4cd27n6ukhqkgnhmt3e/eqAa5SNAD2lxhxqpXtsNgW43O1I/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "60d749c2-4f84-488a-913f-f18fd26308ab",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000198",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img51.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000198",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000199",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "6o0poocc4p34l636a1m79j9d15",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/6o0poocc4p34l636a1m79j9d15/WI9CdUPKR0ZrCZolV8ojwzXHUQQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Veena",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "229139bf-8d6c-420a-bf66-ea77186b8bf8",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000199",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img52.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000199",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000200",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "e1v3ok2dc154n1m5ipd495hg5d",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/e1v3ok2dc154n1m5ipd495hg5d/edtVa2iC6gMxBwxg454TQPpGqQQ/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "bb553e29-665f-4060-a072-dd042138c259",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000200",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img53.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000200",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000201",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ss8dr8lp3h3qb12bjgp1c75p5u",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ss8dr8lp3h3qb12bjgp1c75p5u/jL8ujovhCi81j6_nRFGLSLEYOSc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "649bf264-adf1-407c-91b1-42a87fd10e7f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000201",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img54.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000201",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000202",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "pqtbojerkl5pfdrkuhb591au4s",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/pqtbojerkl5pfdrkuhb591au4s/6ajgxqdBYGXyoNNPHptEFWirWuo/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "a1aaaf37-01d9-4f64-837b-618c5f2fad1e",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000202",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img55.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000202",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000203",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "chkovo1uup5abdetic5j99ui70",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/chkovo1uup5abdetic5j99ui70/hjoHOOYVMVcEQed5uQDXV_lMBcw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c4d194c9-d116-48f1-9690-5e276a413308",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000203",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img56.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000203",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000204",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "n2r775ojdh2jp3vgiqvou44c7q",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/n2r775ojdh2jp3vgiqvou44c7q/i4Vkld8YHQXPpVHMEubmPjvB4aY/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "fab49c22-dad7-41cc-ae31-68441b55ef8f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000204",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img57.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000204",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000205",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "4b91mvcl8p17v1iogn4gjup64e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/4b91mvcl8p17v1iogn4gjup64e/muMoo8aotzDzI3aHjjtOUG5JQl8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "3f6f8e04-1898-49a6-8d2d-640df0501690",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000205",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img58.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000205",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000206",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "hn7cq0uv6p3rt7gh7s0c05735p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/hn7cq0uv6p3rt7gh7s0c05735p/eiWAPlY8JT6xY_fCNRP7DXhaLmw/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "94a402c8-6190-42ee-baa8-fbf55036ea6a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000206",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img59.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000206",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000207",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "t0iug4vgf15150nh2lrpdi9a15",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/t0iug4vgf15150nh2lrpdi9a15/jAjxRa-QlwxtB44P4FJSeo4Ihw0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan | Sumantra Ghosal | Birju Maharaj",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "ed453053-ca56-40f3-a53f-74d16c35e2bf",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000207",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img60.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000207",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000208",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "elnvsc94lh73p35b2l2hg0665t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/elnvsc94lh73p35b2l2hg0665t/c2hMhhO-3w_ap-caUg3IS42ax6w/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "b094bd3a-a3cf-4546-afd4-2ec89da2761a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000208",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img61.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000208",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000209",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "sqf4v48b593r7c4kk7bdl4bg4i",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/sqf4v48b593r7c4kk7bdl4bg4i/hbJV-ULGfeuXvpP38rtn6ABMeGk/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Shabana Azmi | Sanjeev Kumar",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "046e8e62-6578-4186-8f9b-545b231a394f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000209",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img62.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000209",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000210",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "952ujactep5dvaecmp6b03ec4g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/952ujactep5dvaecmp6b03ec4g/Jt0u0bG9xNa0i5CuevkkS_hEiY8/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Suresh Jindal",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "02930e5c-8684-422e-b9ad-5ce6550e43af",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000210",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img63.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000210",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000211",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ng21jk8m4576p7g6cp6rutar7e",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ng21jk8m4576p7g6cp6rutar7e/kyaYIC0uJe3PbxYilEt-2ln60-Y/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough | Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "1731cbc5-04c5-4a0d-84c9-c5fe145e7f9f",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000211",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img64.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000211",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000212",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "eclkr9glo92jf3mi0sl7b9rh65",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/eclkr9glo92jf3mi0sl7b9rh65/v-hjGOYMQoXc5PMuxC3xDH4maBA/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Suresh Jindal | Satyajit Ray",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "73dc3750-bf08-4391-8ea3-cd8a3e47600a",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000212",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img65.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000212",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000213",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "t1vnv8b9rt10jfb603n0199d2t",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/t1vnv8b9rt10jfb603n0199d2t/l4wDoRihBMV9ZAdtntFWUQGce50/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Satyajit Ray | Richard Attenborough | Suresh Jindal",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c9892c44-f379-48fa-8c65-6a8c3795b382",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000213",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img66.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000213",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000214",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "s3bf8i5k0h05v38p8c9oevsp4k",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/s3bf8i5k0h05v38p8c9oevsp4k/IbpBxhWwARtkGEEtUssNg1Qin4M/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Farida Jalal | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "829e88b5-8894-4990-b18b-1a076e4032ee",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000214",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img67.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000214",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000215",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "p1demi51it2d941vg24j9mra68",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/p1demi51it2d941vg24j9mra68/O4hjsjPa50_rrgWbzQda68iTvng/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Richard Attenborough | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "5a47b3a7-eb22-439b-8557-f1e145a3f991",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000215",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img68.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000215",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000216",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "k3t91vbmdt18d10v8u56omcq62",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/k3t91vbmdt18d10v8u56omcq62/sAWjW8sXVA1iRTbXOLI-R2FuGV4/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "58b6b7e9-e939-4f16-8ae2-faa615903a49",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000216",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img69.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000216",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000217",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "smsp7vn8al23v0j513tdbcgg6p",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/smsp7vn8al23v0j513tdbcgg6p/TFG8FD2g1hqMSzIgS4nLzIZjGlg/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "1bc35641-d1de-4453-a8e0-a0d90d4d44cd",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000217",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img70.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000217",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000218",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "edece15e494nbb20jht6mje91g",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/edece15e494nbb20jht6mje91g/wOiqVVT3JrGYJX8sfsT02p4dOsc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "68cadb74-a01e-44c6-8802-fce8b1f15a8c",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000218",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img71.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000218",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "authored-jindal-my-adventures-with-satyajit-ray-2017-0000219",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "Suresh Jindal",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "vpmvupqh915sr56ch2nkrk9m0k",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "2017",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/vpmvupqh915sr56ch2nkrk9m0k/Rn3Sndu0aNQWEk4vPZQ2eMuLyqM/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "80e1693a-e723-44fd-bdda-15ca8afd5dc8",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000219",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "Book",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955_My Adventures with SR_2017_Img72.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "BOOK.bka",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000219",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "books-on-shatranj-ke-khilari-several-0000015",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Landing page_Bibliography | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "ag5qquih1l1kv9kgt1dv25dd6v",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/ag5qquih1l1kv9kgt1dv25dd6v/kfvgOj9XUd9mdsGhUjmGAEVSlsc/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "Sanjeev Kumar | Saeed Jaffrey | Shabana Azmi | Richard Attenborough | Amjad Khan",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "a6218f95-2950-495b-8b13-0f59c5c54464",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_L&A | 0000015",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Books on Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "SKK-biblio-pg.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "several",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "several",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000015",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "Filmfare",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "filmfare-a-times-of-india-publication-1978-0000222",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Economics of Cinema_Awards & Honours | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "7ue5385hed4nddkj6tgafo842b",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/7ue5385hed4nddkj6tgafo842b/qroioW-9j8uzgwqTzyLbPy80Wy0/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "5149e261-fe4d-4983-8ba8-f4c1ae280636",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_filmfare | 0000222",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Filmfare: A Times of India Publication",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "FilmFare_Vol-27-No-6-1-15-Apr-1978_01.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "1-15 April 1978",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "1978",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000222",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "my-adventures-with-satyajit-ray-the-making-of-shatranj-ke-khilari-2017-0000220",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "qqq94k540d1b71om0mh1ih7u3o",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/qqq94k540d1b71om0mh1ih7u3o/I3M-m3fofsbuj2SVUea8OP91RqI/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "f5a2c21e-628c-4e0d-89c6-a659a85ebf97",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000220",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955b_My Adventures with Satyajit Ray_2017.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000220",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "My Adventures with Satyajit Ray",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "my-adventures-with-satyajit-ray-the-making-of-shatranj-ke-khilari-2017-0000221",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "Research Centre_Library | Archive",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "dhn8btfnrh66p5de2j4mpbtp61",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/dhn8btfnrh66p5de2j4mpbtp61/qUTTZ5gTE5c-CXT6yEcxj2wHIUU/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "c5e7efb7-0c0b-46b8-85a5-b72df26bee5d",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "0003_shatranj-ke-khilari_my-adventures-with-satyajit-ray | 0000221",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "2955c_My Adventures with Satyajit Ray_2017.jpg",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "2017",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "2017",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000221",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ],
    [
        {
            "key": "accession_number",
            "value": "",
            "display": "accession_number"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-0000222",
            "display": "alias"
        },
        {
            "key": "artist_name",
            "value": "",
            "display": "artist_name"
        },
        {
            "key": "any_other_sales_in_auction",
            "value": "",
            "display": "any_other_sales_in_auction"
        },
        {
            "key": "artwork_sr_no_as_per_essay_publication",
            "value": "",
            "display": "artwork_sr_no_as_per_essay_publication"
        },
        {
            "key": "any_gallery_price_list_available_for_artwork",
            "value": "",
            "display": "any_gallery_price_list_available_for_artwork"
        },
        {
            "key": "any_collaborators_for_exhibition",
            "value": "",
            "display": "any_collaborators_for_exhibition"
        },
        {
            "key": "auction_code",
            "value": "",
            "display": "auction_code"
        },
        {
            "key": "auction_date_year",
            "value": "",
            "display": "auction_date_year"
        },
        {
            "key": "additional_information",
            "value": "",
            "display": "additional_information"
        },
        {
            "key": "artwork_in_a_graphy_section_sub_section",
            "value": "All pages",
            "display": "artwork_in_a_graphy_section_sub_section"
        },
        {
            "key": "content_id",
            "value": "5mk698cbop0ujejplih62dnb7h",
            "display": "content_id"
        },
        {
            "key": "conversion_rate",
            "value": "",
            "display": "conversion_rate"
        },
        {
            "key": "collection_of_image_links",
            "value": "",
            "display": "collection_of_image_links"
        },
        {
            "key": "current_collection",
            "value": "",
            "display": "current_collection"
        },
        {
            "key": "dates_for_exhibition",
            "value": "",
            "display": "dates_for_exhibition"
        },
        {
            "key": "date_of_artwork",
            "value": "",
            "display": "date_of_artwork"
        },
        {
            "key": "depth_in_cm",
            "value": "",
            "display": "depth_in_cm"
        },
        {
            "key": "depth_in_inches",
            "value": "",
            "display": "depth_in_inches"
        },
        {
            "key": "direct_url_of_preview_image",
            "value": "https://tuliresearchcentre.canto.global/direct/image/5mk698cbop0ujejplih62dnb7h/CJeYFZzC0Z5056Y7ayacls6GVlo/m800/800",
            "display": "direct_url_of_preview_image"
        },
        {
            "key": "featured_artist_or_facial_recognition_names",
            "value": "",
            "display": "featured_artist_or_facial_recognition_names"
        },
        {
            "key": "height_in_cm",
            "value": "",
            "display": "height_in_cm"
        },
        {
            "key": "height_in_inches",
            "value": "",
            "display": "height_in_inches"
        },
        {
            "key": "height_cm",
            "value": null,
            "display": "height_cm"
        },
        {
            "key": "id",
            "value": "5106b844-fcbb-497f-a028-201b14426164",
            "display": "id"
        },
        {
            "key": "image",
            "value": null,
            "display": "image"
        },
        {
            "key": "image_code_from_the_auction_econ_database",
            "value": "",
            "display": "image_code_from_the_auction_econ_database"
        },
        {
            "key": "image_file_name_code",
            "value": "SHATRANJ KE KHILARI_1977_01.png",
            "display": "image_file_name_code"
        },
        {
            "key": "location",
            "value": "",
            "display": "location"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "masterlist"
        },
        {
            "key": "medium_of_artwork",
            "value": "",
            "display": "medium_of_artwork"
        },
        {
            "key": "name_of_institution",
            "value": "",
            "display": "name_of_institution"
        },
        {
            "key": "name_of_exhibition",
            "value": "",
            "display": "name_of_exhibition"
        },
        {
            "key": "name_of_publication",
            "value": "Shatranj Ke Khilari",
            "display": "name_of_publication"
        },
        {
            "key": "notes6",
            "value": "",
            "display": "notes6"
        },
        {
            "key": "notes5",
            "value": "",
            "display": "notes5"
        },
        {
            "key": "notes4",
            "value": "",
            "display": "notes4"
        },
        {
            "key": "notes3",
            "value": "",
            "display": "notes3"
        },
        {
            "key": "notes2",
            "value": "",
            "display": "notes2"
        },
        {
            "key": "notes1",
            "value": "",
            "display": "notes1"
        },
        {
            "key": "old_image_name",
            "value": "",
            "display": "old_image_name"
        },
        {
            "key": "object_of_focus",
            "value": "",
            "display": "object_of_focus"
        },
        {
            "key": "publication_year",
            "value": "1977",
            "display": "publication_year"
        },
        {
            "key": "revised_medium",
            "value": "",
            "display": "revised_medium"
        },
        {
            "key": "section",
            "value": "",
            "display": "section"
        },
        {
            "key": "sp_home_currency",
            "value": "",
            "display": "sp_home_currency"
        },
        {
            "key": "sortable_year_of_publication",
            "value": "",
            "display": "sortable_year_of_publication"
        },
        {
            "key": "title",
            "value": null,
            "display": "title"
        },
        {
            "key": "title_of_artwork_listed_in_essay",
            "value": "",
            "display": "title_of_artwork_listed_in_essay"
        },
        {
            "key": "type_of_exhibition",
            "value": "",
            "display": "type_of_exhibition"
        },
        {
            "key": "venues",
            "value": "",
            "display": "venues"
        },
        {
            "key": "volume_number",
            "value": "",
            "display": "volume_number"
        },
        {
            "key": "width_in_inches",
            "value": "",
            "display": "width_in_inches"
        },
        {
            "key": "width_in_cm",
            "value": "",
            "display": "width_in_cm"
        },
        {
            "key": "width_cm",
            "value": null,
            "display": "width_cm"
        },
        {
            "key": "wnn",
            "value": "0000222",
            "display": "wnn"
        },
        {
            "key": "sp_usd",
            "value": "",
            "display": "sp_usd"
        },
        {
            "key": "medium",
            "value": null,
            "display": "medium"
        }
    ]
]
export const skk_filter_options = {

    documentTypeFilter : [
        "Original Release Poster",
        "Lobby Card",
        "Silk from Benaras (COS), 1977",
        "Photographic Still Mounted on Lobby Card",
        "Showcard",
        "Song Synopsis Booklet",
        "Working Still",
        "Photographic Still",
        "Newspaper Articles",
        "Costume",
        "Book"
      ]
  };