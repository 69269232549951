import {
  Box,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SideMenu from "./layouts/SidebarMenu/Menu";
import { DesktopNav } from "./sections/Navbar";
import DataContext from "../context/DataContext";
import { Link } from "react-router-dom";
import { OExplorer } from "../CommonComponents/OExplorer";
import { MdLanguage } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import { changeLanguage } from "i18next";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Logo = `${IMAGE_BASE_PATH}/ui/Logo/TRCFIS_logo.svg`
const TextLogo = `${IMAGE_BASE_PATH}/ui/Logo/TRCFIS_name.svg`

const MobileNav = ({ onOpen, props, ...rest }) => {
  const { categories } = React.useContext(DataContext);
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem("i18nextLng_localhost:3000"));
  const changeLanguageHandler = (lng) => {
    changeLanguage(lng);
    // save selected language in local storage
    const storageKey = `i18nextLng_${window.location.host}`;
    localStorage.setItem(storageKey, lng);
  };
  useEffect(() => {}, [selectedLang]);
  
  return (
    <Stack
      position="sticky"
      backgroundColor="white"
      w="100%"
      top="0px!important"
      zIndex={"9999"}
      boxShadow="sm"
      height={"64px"}
    >
      <Flex
        ml={{ base: 0, md: 0 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between" }}
        {...rest}
        zIndex={"99999"}
        // boxShadow="0px 3px 3px rgba(0, 0, 0, 0.16)"
      >
        <Box
          display={{ base: "flex" }}
          position={"relative"}
          alignItems={"center"}
        >
          <Flex>
            <SideMenu />
          </Flex>
          <Stack flexDirection={"row"}>
            <HStack>
          <Link to="/">
            <Image
              pl={{ base: 8, md: 2 }}
              src={Logo}
              width="auto"
              height={"63px"}
            />
          </Link>
          </HStack>
          <HStack>
          <Link to="/explore">
            <Box p="1" borderRadius={"10px"} border={"1px"} borderColor={"#e8e8e8"}>
            <Image
              pl={{ base: 0, md: 2 }}
              src={TextLogo}
              width="auto"
              height={"43px"}
            />
            </Box>
          </Link>
          </HStack>
          </Stack>
          <Text
            my="1"
            ml="240px"
            py="0px"
            border={"1px"}
            borderColor={"gray.900"}
            borderRadius={"1px"}
            fontWeight="bold"
            className="logoText"
            display={{ base: "none", md: "flex" }}
          >
            <DesktopNav
              navLinks={[
                {
                  attribute: "category",
                  label: "Research Categories For India Studies",
                  href: "/research-categories",
                  children: categories.flatMap((cat) => {
                    return cat.flatMap((it) => {
                      const { key, value } = it;
                      if (key === "abbreviation") {
                        return {
                          label: `${value}`,
                          href: `/research-categories/${
                            cat.find((item) => item.key === "alias").value
                          }`,
                          hoverTitle: cat.find((item) => item?.key === "name")
                            ?.value,
                          type: cat.find(
                            (item) =>
                              item?.key ===
                              "relevance_to_self_discovery_journey"
                          )?.value,
                          alias: cat.find((item) => item?.key === "alias")
                            ?.value,
                        };
                      } else {
                        return [];
                      }
                    });
                  }),
                },
              ]}
            />
          </Text>
        </Box>
        <Stack flexDirection="inherit" spacing={{ base: "0", md: "2" }}>
          <HStack marginTop={"0px!important"} className="Explorer" mr="4">
            <OExplorer />
          </HStack>
          <HStack
            display={{ base: "none", md: "flex" }}
            marginTop={"0px!important"}
            px="2"
            width="14.5rem"
          >
            {/* <Popover>
              <PopoverTrigger>
                <Image src={searchIcon} px="3"></Image>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />

                <PopoverBody>
                  <Input
                    bg="#f6f6f6"
                    id="search"
                    p="2"
                    variant={"unstyled"}
                    placeholder="Search the Knowledge-base"
                    autoComplete="off"
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover> */}
            <InputGroup size="sm">
              <Input
                height={"2.25rem"}
                borderRadius={"4"}
                // type={show ? 'text' : 'password'}
                placeholder="Search"
                _placeholder={{ color: "black" }}
              />
              <InputRightElement width="2.5rem">
                <IoSearchSharp fontSize={"20px"} />
              </InputRightElement>
            </InputGroup>
          </HStack>
          <HStack
            display={{ base: "flex", md: "none" }}
            marginTop={"0px!important"}
          >
            <Box px="4" display={"block"}>
              <IoSearchSharp fontSize={"24px"} />
            </Box>
            {/* <Popover>
              <PopoverTrigger>
                <Image src={searchIcon} px="3"></Image>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />

                <PopoverBody>
                  <Input
                    bg="#f6f6f6"
                    id="search"
                    p="2"
                    variant={"unstyled"}
                    placeholder="Search the Knowledge-base"
                    autoComplete="off"
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover> */}
          </HStack>
          <HStack position={"relative"} marginTop={"0px!important"}>
            <Popover>
              <PopoverTrigger>
                {/* <Image src={langIcon} pl="3" pr="3"></Image> */}
                <MdLanguage fontSize={"24px"} pl="3" pr="1" />
              </PopoverTrigger>
              <PopoverContent
                width={"11.5rem"}
                height={"2.5rem"}
                position={"absolute"}
                left={"auto"}
                right={"-15px"}
                top={"45px"}
              >
                <PopoverArrow />

                <PopoverBody>
                  <HStack>
                    <Text
                      pr="1"
                      fontSize="12px"
                      fontWeight={selectedLang === "en" ? "800" : "400"}
                      onClick={() => {
                        setSelectedLang("en");
                        changeLanguageHandler("en");
                      }}
                      cursor={"pointer"}
                    >
                      English{" "}
                    </Text>{" "}
                    <span>|</span>
                    <Text
                      pl="1"
                      fontSize="12px"
                      fontWeight={selectedLang === "fr" ? "700" : "400"}
                      onClick={() => {
                        setSelectedLang("fr");
                        changeLanguageHandler("fr");
                      }}
                      cursor={"pointer"}
                    >
                      {" "}
                      French
                    </Text>
                    <span>|</span>
                    <Text
                      pl="1"
                      fontSize="12px"
                      fontWeight={selectedLang === "hi" ? "700" : "400"}
                      onClick={() => {
                        setSelectedLang("hi");
                        changeLanguageHandler("hi");
                      }}
                      cursor={"pointer"}
                    >
                      {" "}
                      Hindi
                    </Text>
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        </Stack>
      </Flex>
    </Stack>
  );
};
export default MobileNav;
