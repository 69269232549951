import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import {
  economicsOfCultureIntroduction,
  economicsOfCultureMasterlist,
  photographyInIndiaChronology,
  photographyInIndiaIntroduction,
  photographyInIndiaMasterlist,
} from "../MockData/ResearchCategoryDetail";

// RC Introduction

export const getIntroductionByAliasAPI = async (
  tableName,
  researchCategoryAlias
) => {
  return [];
};

/**
 * This Function return Research Category Introduction data by using alias.
 * @param {string} tableName - Table name.
 * @param {string} researchCategoryAlias - alias.
 * @returns {Promise<integer>} - Function return Research Category Introduction data.
 */
export const getIntroductionByAlias = async (
  tableName,
  researchCategoryAlias
) => {
  if (isTransformationNotAllowed() === true) {
    return getIntroductionByAliasDummy(researchCategoryAlias);
  } else if (isTransformationNotAllowed() === false) {
  }
};

export const getIntroductionByAliasDummy = (researchCategoryAlias) => {
  const getIntroductionData = {
    "photography-in-india_3": photographyInIndiaIntroduction,
    "economics-of-art-cinema-and-culture_6":economicsOfCultureIntroduction
  };
  const introductionData = getIntroductionData[researchCategoryAlias] || [];
  return introductionData;
};

// RC Chronology

export const getChronologyByAliasAPI = async (
  tableName,
  researchCategoryAlias
) => {
  return [];
};

/**
 * This Function return Research Category Chronology data by using alias.
 * @param {string} tableName - Table name.
 * @param {string} researchCategoryAlias - alias.
 * @returns {Promise<integer>} - Function return Research Category Chronology data.
 */
export const getChronologyByAlias = async (
  tableName,
  researchCategoryAlias
) => {
  if (isTransformationNotAllowed() === true) {
    return getChronologyByAliasDummy(researchCategoryAlias);
  } else if (isTransformationNotAllowed() === false) {
  }
};

export const getChronologyByAliasDummy = (researchCategoryAlias) => {
  const getChronologyData = {
    "photography-in-india_3": photographyInIndiaChronology,
  };
  const chronologyData = getChronologyData[researchCategoryAlias] || [];
  return chronologyData;
};

// RC MasterLists

export const getMasterListsByResearchCategoryAliasAPI = async (
  tableName,
  researchCategoryAlias
) => {
  return [];
};

/**
 * This Function return Research Category Chronology data by using alias.
 * @param {string} tableName - Table name.
 * @param {string} researchCategoryAlias - alias.
 * @returns {Promise<integer>} - Function return Research Category Chronology data.
 */
export const getMasterListsByResearchCategoryAlias = async (
  tableName,
  researchCategoryAlias
) => {
  if (isTransformationNotAllowed() === true) {
    return getMasterListsByResearchCategoryAliasDummy(researchCategoryAlias);
  } else if (isTransformationNotAllowed() === false) {
  }
};

export const getMasterListsByResearchCategoryAliasDummy = (researchCategoryAlias) => {
  const getMasterListData = {
    "photography-in-india_3": photographyInIndiaMasterlist,
    "economics-of-art-cinema-and-culture_6":economicsOfCultureMasterlist

  };
  const masterlistData = getMasterListData[researchCategoryAlias] || [];
  return masterlistData;
};

export const getMasterListDataByAliasAPI = async (
  tableName,
  researchCategoryAlias,
  masterlistAlias
) => {
  return [];
};

/**
 * This Function return Masterlist detail information based on Research Category alias and masterlist alias.
 * @param {string} tableName - Table name.
 * @param {string} researchCategoryAlias - alias.
 * @returns {Promise<integer>} - Function return Research Category Chronology data.
 */
export const getMasterListDataByAlias = async (
  tableName,
  researchCategoryAlias,
  masterlistAlias
) => {
  if (isTransformationNotAllowed() === true) {
    return getMasterListDataByAliasDummy(researchCategoryAlias,masterlistAlias);
  } else if (isTransformationNotAllowed() === false) {
  }
};

export const getMasterListDataByAliasDummy = (researchCategoryAlias, masterlistAlias) => {
  const filteredData = photographyInIndiaMasterlist.filter(item =>
    item.alias === masterlistAlias && item.researchCategoryAlias === researchCategoryAlias
  );
 
  return filteredData ? filteredData : [];
};