import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
//Core file
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import { getGridView_data } from "../../../services/Exhibitions_data";

//Component file
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import Small from "../../../components/layouts/Typography/Small";
import ScrollToTop from "../../../components/sections/ScrollToTop";

//Language file
import { button, common, explore, filterAttribute } from "../../../constants/constants";

const GridView = ({ setShowCarousal, setcarousalData }) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [gridViewData, setGridViewData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const result = await getGridView_data();
      if (result) {
        setGridViewData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <Stack>
      <Container maxW="auto" py="2" className="songs">
        <Stack py="4" flexDirection={"row"} gap={4} justifyContent="center">
          <HStack>
            <SimpleGrid
              columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
              spacing="24px"
            >
              {gridViewData &&
                gridViewData.map((it, index) => {
                  return (
                    <Box
                      height="auto"
                      pt="4"
                      marginTop={"8px"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"start"}
                    >
                      {it.link ? (
                        <>
                          <Box
                            justifyItems="center"
                            display="flex"
                            height={"auto"}
                            bg="white"
                            justifyContent="space-around"
                            cursor={"pointer"}
                            onClick={() => {
                              setShowCarousal(true);
                              setcarousalData(it?.fullImage);
                            }}
                          >
                            <Box className="card1" height="auto">
                              <Image
                                src={it.imgPath}
                                alt=""
                                transition="1s ease"
                                height="100%"
                                objectPosition={"center"}
                              />
                            </Box>
                          </Box>
                          <Link to={it.link} cursor={"pointer"}>
                            <Text
                              textAlign="left"
                              color="#0066B3"
                              p="4"
                              fontSize={"16px"}
                              fontWeight={"400"}
                            >
                              {it.author} | {it.year}, {it.book}
                            </Text>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Box
                            justifyItems="center"
                            display="flex"
                            height={"auto"}
                            bg="white"
                            justifyContent="space-around"
                            cursor={"pointer"}
                            onClick={() => {
                              setShowCarousal(true);
                              setcarousalData(it?.fullImage);
                            }} 
                          >
                            <Box className="card1" height="auto">
                              <Image
                                src={it.imgPath}
                                alt=""
                                transition="1s ease"
                                height="100%"
                                objectPosition={"center"}
                              />
                            </Box>
                          </Box>
                          <Text
                            textAlign="left"
                            color="#0066B3"
                            p="4"
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {it.author} | {it.year}, {it.book}
                          </Text>
                        </>
                      )}
                    </Box>
                  );
                })}
            </SimpleGrid>
          </HStack>
          <HStack marginLeft={"4"} alignItems={"flex-start"}>
            {showFilterBox && (
              <Box className="box-filter" marginTop={"4"}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <HStack>
                    <SmallBold as="u" color={"black"}>
                    {button.CLEAR_ALL}
                    </SmallBold>
                  </HStack>
                  <HStack className="mt-0">
                    <RiCloseCircleFill
                      size={"22px"}
                      cursor="pointer"
                      onClick={(e) => setShowFilterBox(false)}
                    />
                  </HStack>
                </Stack>
                <HStack alignItems={"left"} flexDirection={"column"}>
                  <SmallBold py="4">{explore.SEARCH}</SmallBold>
                  <InputGroup className="ml-0" pb="4">
                    <Input />
                    <InputRightElement>
                      <BiSearch color="green.500" />
                    </InputRightElement>
                  </InputGroup>
                </HStack>
                <Accordion allowToggle allowMultiple>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                         {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                           {filterAttribute.YEAR}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <SmallBold> {filterAttribute.EDITOR}</SmallBold>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <HStack marginTop={"10px"}>
                  <Button
                    color={"#ffffff"}
                    onClick={() => {
                    }}
                    width={"199px"}
                    height={"36px"}
                    bg={"#000000"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    variant={"unstyled"}
                  >
                    {explore.APPLY}
                  </Button>
                </HStack>
              </Box>
            )}
          </HStack>
        </Stack>
        <ScrollToTop />
      </Container>
    </Stack>
  );
};
export default GridView;
