import axios from "axios";
import {
  key_value_raj_kapoor_filmography_data,
  key_value_satyajit_filmography_data,
  satyajit_filter_options,
} from "../MockData/FilmographyMockup";
import _ from "lodash";
import { transformJSON } from "../util/transformer";
import { schema } from "./schemas/Filmography";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

// To do need to confirm with hitesh and remove this
export const filmography = async (tableName) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery($options: FilmographyOptions) {
            ` +
      tableName +
      ` (options: $options){
                alias
                alternative_title
                budget_in_rupees
                certificate_number
                certification
                color_type
                country_of_origin_of_film
                date_of_expiry
                date_of_issue
                duration_mins
                film_sub_type
                film_length
                film_type
                full_movie_link
                gauge
                lab
                length_of_reels
                main_title
                number_of_reels
                region
                release_date
                shooting_period
                studios {
                  id
                  name
                  alias
                }
                sub_title
                synopsis
                title_in_original_language
                title_in_other_languages
                trailer_link
                year_of_film
            }
          }
        `,
    variables: {
      options: {
        sort: [
          {
            year_of_film: "ASC",
          },
        ],
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.filmographies) {
    return result?.data?.data?.filmographies;
  } else {
    return [];
  }
};

// Get Filmography data of skk but check it once and not required remove it
export const filmographyByAlias = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmographyWhere){
            ` +
      tableName +
      ` (where: $where){
              alias
              alternative_title
              budget_in_rupees
              certificate_number
              certification
              color_type
              country_of_origin_of_film
              date_of_expiry
              date_of_issue
              duration_mins
              film_length
              film_sub_type
              film_type
              full_movie_link
              gauge
              lab
              length_of_reels
              main_title
              number_of_reels
              region
              release_date
              shooting_period
              studios {
                id
                name
                alias
              }
              sub_title
              synopsis
              title_in_original_language
              title_in_other_languages
              trailer_link
              year_of_film
              language {
                id
                name
                alias
                country
              }
            }
          }
        `,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.filmographies) {
    return result?.data?.data?.filmographies;
  } else {
    return [];
  }
};

// Get filmography data of Personality like Satyajit Ray, S.H. Raza
export const getFilmographyDataByMasterlistAPI = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  const whereCondition = {
    masterlist_IN: masterlist,
  };

  // Conditionally add film_type_IN property if filterOptions?.filmTypeFilter is set and not empty
  if (filterOptions?.filmTypes_v && filterOptions?.filmTypes_v.length > 0) {
    whereCondition.film_type_IN = filterOptions.filmTypes_v;
  }

  // Conditionally add film_length_IN property if filterOptions?.filmLengthFilter is set and not empty
  if (filterOptions?.filmLength_v && filterOptions?.filmLength_v.length > 0) {
    whereCondition.film_length_IN = filterOptions.filmLength_v;
  }

  // Conditionally add language_IN property if languageFilter is set and not empty
  if (filterOptions?.languages_v && filterOptions?.languages_v.length > 0) {
    whereCondition.language_SOME = { name_IN: filterOptions?.languages_v };
  }

  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmographyWhere){
            ` +
      tableName +
      ` (where: $where){
            id
            trailer_link
            color
            shooting_period
            full_movie_link
            color_type
            lab
            length_of_reels
            certificate_number
            country_of_origin_of_film
            number_of_reels
            alternative_title
            date_of_issue
            alias
            title_in_other_languages
            title_in_original_language
            gauge
            sub_title
            film_type
            budget_in_rupees
            synopsis
            certification
            year_of_film
            duration_mins
            date_of_expiry
            release_date
            film_sub_type
            film_length
            region
            main_title
            masterlist
            language {
              id
              name
              alias
              country
            }
            banner {
              id
              alias
              name
            }
            filmProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            filmDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            scriptWriter {
              alias
              first_name
              last_name
              full_name
              type
            }
            screenPlay {
              alias
              first_name
              last_name
              full_name
              type
            }
            storyWriter {
              alias
              first_name
              last_name
              full_name
              type
            }
            dialogueWriter {
              alias
              first_name
              last_name
              full_name
              type
            }
            musicDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            playbackSinger {
              alias
              first_name
              last_name
              full_name
              type
            }
            lyricist {
              alias
              first_name
              last_name
              full_name
              type
            }
            editor {
              alias
              first_name
              last_name
              full_name
              type
            }
            cinematographer {
              alias
              first_name
              last_name
              full_name
              type
            }
            artDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            stillPhotographer {
              alias
              first_name
              last_name
              full_name
              type
            }
            posterDesigner {
              alias
              first_name
              last_name
              full_name
              type
            }
            materialDesigner {
              alias
              first_name
              last_name
              full_name
              type
            }
            narrator {
              alias
              first_name
              last_name
              full_name
              type
            }
            artist {
              alias
              first_name
              last_name
              full_name
              type
            }
            poet {
              alias
              first_name
              last_name
              full_name
              type
            }
            commentryWriter {
              alias
              first_name
              last_name
              full_name
              type
            }
            translator {
              alias
              first_name
              last_name
              full_name
              type
            }
            makeUpArtist {
              alias
              first_name
              last_name
              full_name
              type
            }
            assistantMakeUpArtist {
              alias
              first_name
              last_name
              full_name
              type
            }
            costumeDesigner {
              alias
              first_name
              last_name
              full_name
              type
            }
            choreographer {
              alias
              first_name
              last_name
              full_name
              type
            }
            fightDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            assistantMusician {
              alias
              first_name
              last_name
              full_name
              type
            }
            soundRecorder {
              alias
              first_name
              last_name
              full_name
              type
            }
            soundDesigner {
              alias
              first_name
              last_name
              full_name
              type
            }
            soundMixer {
              alias
              first_name
              last_name
              full_name
              type
            }
            soundEditor {
              alias
              first_name
              last_name
              full_name
              type
            }
            associateEditor {
              alias
              first_name
              last_name
              full_name
              type
            }
            assistantEditor {
              alias
              first_name
              last_name
              full_name
              type
            }
            associateCameraman {
              alias
              first_name
              last_name
              full_name
              type
            }
            assistantCameraman {
              alias
              first_name
              last_name
              full_name
              type
            }
            coProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            productionExecuter {
              alias
              first_name
              last_name
              full_name
              type
            }
            productionSecretary {
              alias
              first_name
              last_name
              full_name
              type
            }
            associateProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            productionDesigner {
              alias
              first_name
              last_name
              full_name
              type
            }
            supervisingProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            lineProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            productionInCharge {
              alias
              first_name
              last_name
              full_name
              type
            }
            consultingProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            executiveProducer {
              alias
              first_name
              last_name
              full_name
              type
            }
            associateDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            assistantDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            executiveDirector {
              alias
              first_name
              last_name
              full_name
              type
            }
            location {
              id
              name
              alias
            }
            studios {
              id
              name
              alias
            }
            posterImages {
              wnn
              accession_number
              alias
              artist_name
              any_other_sales_in_auction
              artwork_sr_no_as_per_essay_publication
              any_gallery_price_list_available_for_artwork
              any_collaborators_for_exhibition
              auction_code
              auction_date_year
              additional_information
              artwork_in_a_graphy_section_sub_section
              content_id
              conversion_rate
              collection_of_image_links
              current_collection
              dates_for_exhibition
              date_of_artwork
              depth_in_cm
              depth_in_inches
              direct_url_of_preview_image
              height_in_cm
              height_in_inches
              height_cm
              id
              image
              image_code_from_the_auction_econ_database
              image_file_name_code
              location
              masterlist
              medium_of_artwork
              name_of_institution
              name_of_exhibition
              name_of_publication
              notes6
              notes5
              notes4
              notes3
              notes2
              notes1
              old_image_name
              object_of_focus
              publication_year
              revised_medium
              section
              sp_home_currency
              sortable_year_of_publication
              title
              title_of_artwork_listed_in_essay
              type_of_exhibition
              venues
              volume_number
              width_in_inches
              width_in_cm
              width_cm
              wnn
              sp_usd
              medium
              artist {
                alias
                first_name
                last_name
                full_name
              }
              featuredArtist {
                alias
                first_name
                last_name
                full_name
              }
            }
            filmInfo {
              alias
            }
            }
          }
        `,
    variables: {
      where: whereCondition,
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.filmographies) {
    return result?.data?.data?.filmographies;
  } else {
    return [];
  }
};

export const getFilmographyDataByMasterlist = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } =
      await getFilmographyDataByMasterlistDummy(masterlist);
    return { mockData, filterMockData };
  } else {
    const filteredData = Object.fromEntries(Object.entries(filterOptions).filter(([_, v]) => v.length > 0));
    const response = await getFilmographyDataByMasterlistAPI(
      tableName,
      masterlist,
      filteredData,
      searchTerm
    );

    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getFilmographyDataByMasterlistDummy = async (masterlist) => {
    const filmographyDataMap = {
      "satyajit-ray":key_value_satyajit_filmography_data,
      "raj-kapoor":key_value_raj_kapoor_filmography_data
  
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const mockData = filmographyDataMap[masterlist] || key_value_satyajit_filmography_data;
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories.languages_v.length > 0 ||
      filterCategories.filmTypes_v.length > 0 ||
      filterCategories.filmLength_v.length > 0 ||
      searchTerm !== "" ||
      searchTerm !== undefined
    ) {
      const filteredData = _.filter(mockData, (entry) => {
        const languageCheck =
          filterCategories.languages_v.length > 0
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) => filterCategories.languages_v.includes(lang))
            : true;
        const filmTypeCheck =
          filterCategories.filmTypes_v.length > 0
            ? filterCategories.filmTypes_v.includes(
                entry.find((item) => item.key === "film_type").value
              )
            : true;

        const filmLengthCheck =
          filterCategories.filmLength_v.length > 0
            ? filterCategories.filmLength_v.includes(
                entry.find((item) => item.key === "film_length").value
              )
            : true;

        const searchTermCheck =
          searchTerm !== ""
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) =>
                  lang.toLowerCase().includes(searchTerm.toLowerCase())
                ) ||
              entry
                .find((item) => item.key === "film_type")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find((item) => item.key === "film_length")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase())
            : true;
        return (
          languageCheck && filmTypeCheck && filmLengthCheck && searchTermCheck
        );
      });
      return filteredData;
    } else {
      return mockData;
    }
  };

  return {
    mockData,
    filterMockData,
  };
};

export const getFilmographyFilterOptionsAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmographyWhere){
            ` +
      tableName +
      ` (where: $where){
              film_type
              film_length
              language {
                id
                name
                alias
                country
              }              
            }
          }
        `,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.filmographies) {
    return result?.data?.data?.filmographies;
  } else {
    return [];
  }
};

export const getFilmographyFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const FilterOptions = await getFilmographyFilterOptionsDummyData(
      masterlist
    );
    return FilterOptions;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getFilmographyFilterOptionsAPI(
      tableName,
      masterlist
    );
    const FilterOptions = {
      filmTypeFilter: [
        ...new Set(response.map((item) => item.film_type.trim())),
      ].filter((value) => value !== ""),
      filmLengthFilter: [
        ...new Set(response.map((item) => item.film_length.trim())),
      ].filter((value) => value !== ""),
      languageFilter: [
        ...new Set(
          response.flatMap((item) =>
            item.language.map((lang) => lang.name.trim())
          )
        ),
      ].filter((value) => value !== ""),
    };

    return FilterOptions;
  }
};

const getFilmographyFilterOptionsDummyData = (tableName, masterlist) => {
  switch (masterlist) {
    case "satyajit-ray":
      return satyajit_filter_options;
    default:
      // Handle the default case if needed
      return satyajit_filter_options;
  }
};
