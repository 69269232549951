import { Text } from "@chakra-ui/react";
import React from "react";

const H2 = ({ children, ...props }) => {
  return (
    <Text
      as="h2"
      {...props}
      fontSize="20px"
      fontWeight="700"
      lineHeight={"24px"}
      color={"black"}
    >
      {children}
    </Text>
  );
};
export default React.memo(H2);
