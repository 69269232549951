import {
  Box,
  Container,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import { common } from "../constants/constants";

const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

function ProjectPage() {
  const [projects, setProjects] = React.useState([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    const getData = async () => {
      const graphqlQuery = {
        query:
          `query Query {
            projects {
              published
              venue
              name
              id
              link
              display_date
              alias
              image
            }
          }
            `
      };
    
      const result = await axios.post(baseUrl, graphqlQuery);
      if (result?.data?.data) {
        const dataProjects = result?.data?.data?.projects;
        setProjects(dataProjects);
      }
    };
    getData();
  }, []);

  return (
    <Layout>
      <Stack m="5" pr={"8"} pl={"8"} className="mobileviewpadding">
        <Container maxW="auto">
          {projects.length === 0 ? (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          ) : (
            <SimpleGrid columns={[1, null, 4]} spacing="10px">
              {projects?.map((item, index) => (
                <Box
                  onClick={() => {
                    if (
                      item.name ===
                      "OsianamaWorld | 1st Festival for India Studies"
                    ) {
                      navigate("/songs/all");
                    } else {
                      navigate("/projects/" + item.id);
                    }
                  }}
                  key={index}
                  maxW={"auto"}
                  w={"full"}
                  bg={"gray.50"}
                  border={"1px"}
                  borderColor={"gray.100"}
                  rounded={"md"}
                  p={6}
                  overflow={"hidden"}
                  cursor={"pointer"}
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                >
                  <Link href={item?.link} style={{ textDecoration: "none" }}>
                    <Box
                      h={"auto"}
                      bg={"white"}
                      mt={-6}
                      mx={-6}
                      mb={6}
                      pos={"relative"}
                    >
                      <Image
                        src={
                          item?.image?.image
                            ? "https://d3fsixjuo450s3.cloudfront.net/" +
                              item?.image?.image
                            : "https://asvs.in/wp-content/uploads/2017/08/dummy.png"
                        }
                        alt=""
                        w={"100%"}
                      />
                    </Box>
                  </Link>
                  <Stack>
                    <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                      <Text pb="2" color={"gray.500"}>
                        {item?.display_date}
                      </Text>
                      <Text pb="2" fontWeight={600}>
                        {item?.name}
                      </Text>
                      <Text pb="2" color={"gray.500"}>
                        {item?.venue}
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Container>
      </Stack>
    </Layout>
  );
}
export default ProjectPage;
