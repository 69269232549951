import {
  Box,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//Service file
import { getSliderData } from "../../../services/Exhibitions_data";

//Component file
import ScrollToTop from "../../../components/sections/ScrollToTop";
import { FullScreenImage } from "./FullImageScreen";

function Exhibit({ readmoreNavigation, setReadMoreFlag }) {
  const [data, setSliderData] = useState([]);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const result = await getSliderData();
      if (result) {
        setSliderData(result);
      }
    };
    getData();
  }, []);

  const [isFullScreenImage, setFullScreenImage] = useState(false);
  const [isImgData, setImgData] = useState(null);
  const showFullImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);
  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);

      if (readmoreNavigation?.title === "banner") {
        setFullScreenImage(true);
      }
    }
  }, [readmoreNavigation]);
  return (
    <Container maxW={"100%"} className="songs" id="allslider">
      <Stack position={"relative"} mb="20">
        <Stack py="4" flexDirection={"row"} gap={4} justifyContent="center">
          <HStack>
            <SimpleGrid
              columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
              spacing="24px"
            >
              {data &&
                data.map((it, index) => {
                  return (
                    <Box
                      height="auto"
                      pt="4"
                      marginTop={"8px"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"start"}
                    >
                      <Box
                        justifyItems="center"
                        display="flex"
                        height={"auto"}
                        bg="white"
                        justifyContent="space-around"
                      >
                        <Link
                          href="/art-heritage-annual/timeline"
                          cursor={"pointer"}
                        >
                          <Box className="card1" height="auto">
                            <Image
                              src={it.imgPath}
                              alt=""
                              transition="1s ease"
                              height="100%"
                              objectPosition={"center"}
                              onClick={() =>
                                showFullImages(it.imgfullPath, true, index)
                              }
                            />
                          </Box>
                        </Link>
                      </Box>
                      <Text
                        textAlign="left"
                        color="#035DA1"
                        fontSize={"16px"}
                        fontWeight={"400"}
                        pt={4}
                      >
                        {`${it.author} | ${it.desc} | ${it.art_work} | ARTS.scu_${it.year}`}
                      </Text>
                    </Box>
                  );
                })}
            </SimpleGrid>
          </HStack>
        </Stack>
        <ScrollToTop />
      </Stack>
      <FullScreenImage
        isOpen={isFullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={isImgData}
        data={data}
        bannerFlag={true}
        title="banner"
        setReadMoreFlag={setReadMoreFlag}
        clickedImageIndex={clickedImageIndex}
        setClickedImageIndex={setClickedImageIndex}
      />
    </Container>
  );
}
export default Exhibit;
