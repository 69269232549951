import {
  Box,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import MobileNav from "../../components/Topheader";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import GridView from "./BodyOfWork/MonumentsAndHeritage/GridView";

//Language file
import {explore, location } from "../../constants/constants";
import { IntegratedTimelineBody } from "./BodyOfWork/IntegratedTimeline";

export default function BodyOfWork({ children }) {
  const {onOpen, onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (result) {
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <MobileNav onOpen={onOpen} />
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex={"99999"}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <BodyOfWorkBody aliasAPI={aliasAPI} />
        </Box>
      </Box>
    </Container>
  );
}
const SidebarContent = ({ 
  onClose,
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
   ...rest }) => {

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        leftFlag={true}
      />
    </>
  );
};
const BodyOfWorkBody = ({aliasAPI}) => {
  const [modalcard, setModalcard] = useState(false);
  return (
            <>
             <Stack height={"100%"}>
              <Container maxW="auto" px={{base:"4",md:"6"}} mx={"0"}>
                <Tabs
                  align={{ xs: "", sm: "" }}
                  w="full"
                >
                  <Stack
                    display={"flex"}
                  >
                    <Box
                      position={"fixed"}
                      zIndex={999}
                      bg="white"
                      width={{ base: "100%", md: "1165px" }}
                      paddingTop={"32px"}
                      paddingBottom={"18px"}
                      px="30px"
                    >
                      <TabList
                        overflowX="auto"
                        borderBottom={"1px"}
                        borderBottomColor={"#f1f1f1"}
                        gap={12}
                        className="verticalscroll"
                      >
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"md"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                        >
                          {location.MONUMNETS_AND_HERITAGE}
                        </Tab>
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"md"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                        >
                            {explore.INTEGRATED_TIMELINE}
                        </Tab>
                      </TabList>
                    </Box>
                  </Stack>
                  <TabPanels>
                    <TabPanel px="0px" pt={"40px"}>
                      <GridView aliasAPI={aliasAPI} />
                    </TabPanel>
                    <TabPanel pt="75px" px="30px">
                    <IntegratedTimelineBody
                    modalcard={modalcard}
                    setModalcard={setModalcard}
                    aliasAPI={aliasAPI}
                    />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Container>
              </Stack>
            </>
  );
};