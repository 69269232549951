import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//Service file
import { getPostsByResearchCategoryAlias } from "../../../services/Post";

function Posts() {
  const url=window.location.pathname
  const parts = url.split("/");
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const result = await getPostsByResearchCategoryAlias("", parts[2]);
      if (result) {
        setPosts(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Stack my="8">
      <Box>
        <Grid
          h="auto"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap={4}
          justifyItems={"end"}
        >
          {posts?.length > 0 && posts?.map((item, index) => (
            <GridItem
              key={item?.id}
              rowSpan={index === 0 ? 2 : 1}
              colSpan={index === 0 ? 1 : 2}
              style={
                index === 0
                  ? {
                      width: "595px",
                      height: "420px",
                      borderRadius: "10px",
                    }
                  : {
                      width: "278px",
                      height: "191px",
                      borderRadius: "10px",
                    }
              }
            >
              <Link to={`${url}/posts/${item?.alias}`}>
              <Image src={item?.image[0]} />
              <HStack
                flexDirection={"column"}
                pos={"relative"}
                bottom={index === 0 ? "24%":"34%"}
                alignItems={"flex-start"}
                color={"white"}
                pl={index === 0 ? "24px":"8px"}
              >
                <Text
                  style={
                    index === 0
                      ? {
                          fontSize: "30px",
                        }
                      : {
                          fontSize: "20px",
                        }
                  }
                  fontWeight={700}
                >
                  {item?.heading1}
                </Text>
                <Text
                  style={
                    index === 0
                      ? {
                          fontSize: "24px",
                        }
                      : {
                          fontSize: "16px",
                        }
                  }
                  f
                  fontWeight={500}
                >
                  {item?.heading2}
                </Text>
              </HStack>
              </Link>

            </GridItem>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}
export default Posts;
