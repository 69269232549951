import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Image,
  HStack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";

// Service Files
import * as getFilmData from "../../../services/Film";
import * as getLandingMasterlistData from "../../../services/SectionService";

// Subsection Files
import MobileNav from "../../../components/Topheader";
import CommonLeftSidebar from "../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { explore } from "../../../constants/constants";
import { isArray } from "lodash";
import { BottomMainMenu } from "../../../CommonComponents/BottomMainMenu";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

const artistLinksData = [
  {
    name: "Satyajit Ray",

  },
  {
    name: "Suresh Jindal",
  },
];
export default function MainIntroductionImageView({ children }) {
  let { alias } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState("");
  const [filmDetails, setFilmDetails] = useState({});
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias("sections", winnerName);
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item.key === "masterlist").value}/${
              it.find((item) => item.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias("films", winnerName);
        if (result) {
          if (result) {
            setName(
              () =>
                result.find((item) => item.key === "filmTitleImage").value[0]
                  ?.direct_url_of_preview_image
            );
            setAgraphyLink([
              {
                link: `/research-categories/${
                  result.find((item) => item.key === "researchCategories")
                    .value[0]?.link
                }`, // replace with the actual link
                title: result.find((item) => item.key === "researchCategories")
                  .value[0]?.value,
              },
              {
                link: `/categories/indian-cinema`,
                title: result.find((item) => item.key === "subject").value,
              },
            ]);
            setfilmMainImage(
              {
                 logoImage:result?.find((item) => item?.key === "filmMainImage")?.value[0]
                   ?.direct_url_of_preview_image,
                   logoLink:`/${masterlistType}/${alias}/introduction/title-image`
                 }
             );
             const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
            setFilmTrailer({
              link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
              display: result.find((item) => item.key === "trailer_link")
                .display,
              image: WatchTrailer,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
    window.scrollTo(0, 0);
  }, [masterlistType, winnerName, alias]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        winnerName={winnerName}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} winnerName={winnerName} />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <IntroductionImageView
          winnerName={winnerName}
          filmMainImage={filmMainImage}
          masterlistType={masterlistType}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  filmographyData,
  winnerName,
  onClose,
  name,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  ...rest
}) => {
  const backTo = `/${masterlistType}/${winnerName}`;
  const navigate=useNavigate();
  const handleNavigation = () => {
      navigate(-1);
  };
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];

  const oSections = [
    {
     to:`/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: explore.POSTS,
    },
    {
     to:`/${masterlistType}/${winnerName}/insights`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  
  const trailerLink = {
    link:`/${masterlistType}/${winnerName}/watch-film-trailer`,
    image: WatchTrailer,
  };
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        artistLinksData={artistLinksData}
        backTo={backTo}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        drawerData={drawerData}
        drawerImage={SKK1}
      />
    </>
  );
};

const IntroductionImageView = ({ filmMainImage, winnerName, masterlistType }) => {
  const navigate = useNavigate();
  let { imageView } = useParams();
  useEffect(() => {
    if (imageView) {
      navigate(`/${masterlistType}/${winnerName}/introduction`, { replace: true });
    }
  }, [imageView, navigate, masterlistType, winnerName]);
  return (
    <Stack
      height={"calc(100vh - 64px)"}
      justifyContent={{ base: "center", md: "flex-start" }}
      py={2}
      bg={"white"}
    >
      <HStack
        justifyContent={"center"}
        flexDirection={"column"}
        marginTop={"15px"}
      >
        <Box width={"auto"} height={{ base: "auto", md: "672px" }}>
          <Image
            src={ filmMainImage?.logoImage }
            width={"auto"}
            height={"inherit"}
            alt=""
          />
        </Box>
      </HStack>
      <HStack justifyContent="flex-end">
        <Text
          fontSize={"xl"}
          bg={"black"}
          opacity={"0.6"}
          color={"white"}
          fontWeight={"400"}
          padding="2"
          cursor={"pointer"}
          borderRadius={"35px"}
          width="max-content"
          marginTop={{ base: "15px", md: "-160px" }}
          marginRight={{ base: "15px", md: "155px" }}
          onClick={() => navigate(`/${masterlistType}/${winnerName}/introduction`)}
        >
          <CgArrowsExpandRight />
        </Text>
      </HStack>
    </Stack>
  );
};
