import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import React from "react";

export default function TeachingDrawerModalDetails({
  familyFullScreenImage,
  familyImgData,
  showFullImages,
}) {
  return (
    <>
      <Modal
        size={"full"}
        isCentered
        isOpen={familyFullScreenImage}
        onClick={() => showFullImages(null, false)}
        Id="modalfullscreen"
        familyImgData={familyImgData}
      >
        <ModalContent zIndex={"99999"}>
          <ModalBody>
            <Box bg="white">
              <Stack>
                <HStack justifyContent={"center"} margin={"65px auto"}>
                  <Image
                    src={
                      familyImgData !== null ||
                      familyImgData !== "undefined" ||
                      familyImgData !== undefined
                        ? /*  "https://d3fsixjuo450s3.cloudfront.net/" + */
                          familyImgData
                        : ""
                    }
                    width={"auto"}
                    height={"100%"}
                    alt=""
                  />
                </HStack>
                <HStack justifyContent="flex-end">
                  <Box position={"absolute"} bottom={"10%"} right={"15%"}>
                    <Text
                      fontSize={"xl"}
                      bg={"#525252"}
                      color={"white"}
                      fontWeight={"400"}
                      padding="2"
                      cursor={"pointer"}
                      borderRadius={"35px"}
                      width="max-content"
                      onClick={() => showFullImages(false)}
                    >
                      <CgArrowsExpandRight />
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
