import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Heading from "../components/layouts/Typography/Heading";
import Weekly from "./Calendar/Weekly";
import Monthly from "./Calendar/Monthly";
import Daily from "./Calendar/Daily";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import MobileNav from "../components/Topheader";
import { calendar } from "../constants/constants";
import { MdHomeFilled } from "react-icons/md";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import { useNavigate } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function Calendar() {
  // const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const durationArr = {
    daily: "DAILY_TAB_TITLE",
    weekly: "WEEKLY_TAB_TITLE",
    monthly: "MONTHLY_TAB_TITLE",
  };

  return (
    <SidebarWithHeader>
      <Stack className="exploretabs">
        <Container maxW={"1080px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            my="6"
            variant="unstyled"
            defaultIndex={0}
          >
            <Heading
              mb={"-75px"}
              textStyle="primary"
              textAlign={"left"}
              className="calendarheading"
            >
              {calendar.CALENDAR_TAB_TITLE}
            </Heading>
            <TabList
              py="4"
              textStyle="primary"
              overflowX="auto"
              className="exploretablist"
            >
              {Object.keys(durationArr).map((item, index) => (
                <Tab
                  key={index}
                  p="0"
                  m="4"
                  className="calendar-tabs"
                  fontSize={"md"}
                  fontWeight={"700"}
                  _selected={{
                    borderBottom: "4px",
                    borderColor: "#333333",
                    borderRadius: "4px",
                    height: "30px",
                  }}
                >
                  {calendar[durationArr[item]]}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {Object.keys(durationArr).map((item, index) => (
                <TabPanel key={index} px={"0"} className="tab-panel-body">
                  {item === "daily" ? <Daily /> : null}
                  {item === "weekly" ? <Weekly /> : null}
                  {item === "monthly" ? <Monthly /> : null}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="1440px" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
      active: true,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
