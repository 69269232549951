import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { useNavigate, useParams } from "react-router-dom";
import { A11y, EffectCreative, Navigation, Pagination } from "swiper";

// Service Files
import * as getPostDetailsData from "../../../services/Post";

// Subsection Files
import { SocialMediaButton } from "../../../CommonComponents/SocialMediaButton";
import Caption from "../../../components/layouts/Typography/Caption";
import H2 from "../../../components/layouts/Typography/H2";
import Large from "../../../components/layouts/Typography/Large";
import Layout from "../../../components/sections/SidebarLayout";
import { common } from "../../../constants/constants";

// Image files

function PostDetails() {
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [postDetailsData, setPostDetailsData] = useState([]);
  const { sliderIndex, alias, postAlias } = useParams();
  const masterlistTitle = alias;
  const post = postAlias;

  const extractPost = (data, post) => {
    return data.filter((item) => item.post === post);
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getPostDetailsData.getPost(
        "masterlist_table",
        masterlistTitle
      );
      if (result) {
        setPostDetailsData(extractPost(result, post));
      }
    };
    getData();
  }, [masterlistTitle, post]);

  const handleSlideChange = (swiper) => {};
  const navigate = useNavigate();
  const showFullImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  
  return (
    <Layout>
      <Container maxW={"1400px"} id="allslider">
        <Stack>
          <Stack
            width={"100%"}
            paddingBottom={{base:12,md:4}}
            alignItems={"center"}
          >
            <Stack width={"100%"} position={"relative"} id="postdetailslider">
              <Swiper
                modules={[Navigation, Pagination, EffectCreative, A11y]}
                slidesPerView={1}
                loop={false}
                effect="creative"
                navigation
                height={"100%"}
                onSlideChange={handleSlideChange}
                onSlideChangeTransitionEnd={handleSlideChange}
                initialSlide={sliderIndex ? parseInt(sliderIndex) : 0}
              >
                {postDetailsData.length > 0 &&
                  postDetailsData?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <Stack flexDirection={"column"}>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            width={"100%"}
                            height={{ base: "100%", md: "533px" }}
                          >
                            <Image
                              src={item.imgPath}
                              alt=""
                              onClick={(e) => {
                                showFullImages(item.imgPath, true);
                              }}
                              width={"auto"}
                              cursor={"pointer"}
                              height={"100%"}
                            />
                          </Box>
                          <Stack
                            flexDirection={"row"}
                            paddingY={"8"}
                            className="row"
                          >
                            <HStack
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              className="col-sm-12 col-md-4"
                              px={{ base: "0", md: "4" }}
                            >
                              <H2 pb="1">{" "} {item.title!==""?item.title:common.CONTENT_AWAITED}</H2>
                              <Caption pb="2">
                                {item.description0}
                              </Caption>

                              <Large marginInlineStart={"0rem!important"}>
                                {item.description1}
                              </Large>
                            </HStack>
                            <HStack
                              marginTop={"0rem!important"}
                              paddingTop={{ base: "0", md: "8" }}
                              alignItems={"flex-start"}
                              className="col-sm-12 col-md-3"
                              px={{ base: "0", md: "4" }}
                            >
                              {" "}
                              <Large>{item.description2}</Large>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              className="col-sm-12 col-md-5"
                              px={{ base: "0", md: "4" }}
                            >
                              <HStack py={{base:"2",md:"0"}} gap={"10px"} wrap={"wrap"}>
                              {item.button8 && (
                                  <Button
                                    className="keyword-btn blob-button"
                                    marginInlineStart={"0rem!important"}
                                    onClick={() => navigate(`/film/${masterlistTitle}/insights/${post === item.post && item.insight}`)}
                                  >
                                    {item.button8}
                                  </Button>
                                )}
                                {item.primaryButton &&
                                  item.primaryButton.map((it) => (
                                    <Button
                                      className={`keyword-btn ${
                                        it.link ? `primary-color` : ``
                                      }`}
                                      marginInlineStart={"0rem!important"}
                                      onClick={() => navigate(`${it.link}`)}
                                    >
                                      {it.name}
                                    </Button>
                                  ))}
                                {item.secondaryButton &&
                                  item.secondaryButton.map((it) => (
                                    <Button
                                      className={`keyword-btn ${
                                        it.link ? `secondary-color` : ``
                                      }`}
                                      marginInlineStart={"0rem!important"}
                                      onClick={() => navigate(`${it.link}`)}
                                    >
                                      {it.name}
                                    </Button>
                                  ))}
                                {item.keyWordButtons &&
                                  item.keyWordButtons.map((it) => (
                                    <Button
                                      className={`keyword-btn ${
                                        it.link ? `keyword-color` : ``
                                      }`}
                                      marginInlineStart={"0rem!important"}
                                      onClick={() => navigate(`${it.link}`)}
                                    >
                                      {it.name}
                                    </Button>
                                  ))}
                              </HStack>
                              <HStack
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                                marginInlineStart={"0rem!important"}
                              >
                                <Text
                                  py="4"
                                  fontSize={"18px"}
                                  fontWeight={"600"}
                                >
                                  {common.SHARE}
                                </Text>
                                <SocialMediaButton />
                              </HStack>
                            </HStack>
                          </Stack>
                        </Stack>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imgData}
      />
    </Layout>
  );
}
export default PostDetails;

const ImagePopupModal = ({ isOpen, onClose, imageData }) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody>
          <Box bg="white">
            <Stack>
              <HStack justifyContent={"center"} marginTop={"20px"}>
                <Image
                  src={imageData}
                  height={{ base: "100%", mf: "95vh" }}
                ></Image>
              </HStack>
              <HStack justifyContent={"flex-end"}>
                <Text
                  fontSize={"xl"}
                  bg={"black"}
                  opacity={0.7}
                  color={"white"}
                  fontWeight={"400"}
                  padding="2"
                  borderRadius={"35px"}
                  width="max-content"
                  marginTop="-200px"
                  marginRight={"15%"}
                  cursor="pointer"
                  position={"absolute"}
                  onClick={onClose}
                >
                  <CgArrowsExpandRight />
                </Text>
              </HStack>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
