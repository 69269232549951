import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import { isTransformationNotAllowed } from "../../../../../util/isTranformationAllowed";
import * as getBibliographyData from "../../../../../services/Bibliography";
import { CopyPasteService } from "../../../../../services/CopyPaste";

//Component file
import SmallBold from "../../../../../components/layouts/Typography/SmallBold";
import ScrollToTop from "../../../../../components/sections/ScrollToTop";
import ContentModal from "../../../../Film/ResearchCentre/Library/ContentModal";
import SliderModal from "../../../../Film/ResearchCentre/Library/SliderModal";
import ResearchImageGridList from "../../../../Cine_MasterList/ResearchCentre/CommonComponents/ResearchImageGridList";
import ResearchCentreDrawer from "./Components/ResearchCentreDrawer";
import Small from "../../../../../components/layouts/Typography/Small";
import { FilterButton } from "../../../../../CommonComponents/FilterButton";

//Language file
import { common, explore, filterAttribute } from "../../../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/7.png`


function GridView({
  data,
  handleBookClick,
  contentClicked,
  scanClicked,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  tableName,
  rcAlias,
  main
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const sliderModalImg = [Img5, Img6, Img7];
  const [swiper, setSwiper] = useState(null);
  const [bibliographyObject, setBibliographyObject] = React.useState();
  const [indexValue, setIndexValue] = useState();
  const [apply, setApply] = useState(false);
  const [filterCount, setfilterCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteroptionData, setFilteroptionsData] = useState({});

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };

  const handleCloseClick = () => {
    setContentClicked(false);
    setScanClicked(false);
  };

  const handleDrawer = (item) => {
    setBibliographyObject(item);
    setOpenDrawer(true);
    setDrawerBook(item.bookKey);
  };

  const handleClose = () => {
    setBibliographyObject();
    handleCloseClick();
    setOpenDrawer(false);
    setDrawerBook("");
  };

  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    showSliderImage(2);
    if (text === "Contents Page →") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages →") {
      setScanClicked(true);
      setContentClicked(false);
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    documentType: [],
    year: [],
    creatorType: [],
    publisher: [],
    searches_v: [],
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type

    let selectedArray = [];
    switch (value) {
      case "docType":
        selectedArray = filterOptions.documentType;
        break;
      case "year":
        selectedArray = filterOptions.year;
        break;
      case "creatorType":
        selectedArray = filterOptions.creatorType;
        break;
      case "publisher":
        selectedArray = filterOptions.publisher;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "docType":
        setFilterOptions({
          ...filterOptions,
          documentType: selectedArray,
        });
        break;
      case "year":
        setFilterOptions({
          ...filterOptions,
          year: selectedArray,
        });
        break;
      case "creatorType":
        setFilterOptions({
          ...filterOptions,
          creatorType: selectedArray,
        });
        break;
      case "publisher":
        setFilterOptions({
          ...filterOptions,
          publisher: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getBibliographyData.getBibliographyFilterOptions(
        tableName,
        rcAlias
      );
      if (result) {
        setFilteroptionsData(result);
      }
    };

    getFilterOptionsData();
  }, [rcAlias, tableName]);

  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getBibliographyData.bibliographyByResearchCategory(
        tableName,
        rcAlias
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getBibliographyData.bibliographyByResearchCategory(
        tableName,
        rcAlias,
        filterOptions,
        searchTerm
      );
    }

    const data = result || []; // Ensure data is not null or undefined
    // setScreeningsData(citationFactor(data));
    setFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  const [FilterData, setFilterData] = useState([{}]);


  const citationFactor = (data) => {
    return data.map((item) => {
      const imagePath =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value || null;

      const creator =
        item?.find((nestedItem) => nestedItem?.key === "creator")?.value || [];
      const lastName = creator?.map((nestedItem) => nestedItem?.last_name) || "";
      const firstName =
        creator?.map((nestedItem) => nestedItem?.first_name) || "";
      const author = lastName && firstName ? `${lastName}, ${firstName}` : "";
      const bookKey =
        item.find((nestedItem) => nestedItem?.key === "book_title_of_document")
          ?.value || null;
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        null;
      const publicationYear =
        item?.find(
          (nestedItem) => nestedItem?.key === "sortable_year_date_of_publication"
        )?.value || null;
      const documentType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")?.value ||
        null;
      const desc = `${author} ,${bookKey}, ${publicationYear}.`;

      return {
        imagePath,
        desc,
        bookKey,
        link,
        creator,
        documentType
      };
    });
  };
  // for drawerdata api call
  // const [drawerBook,setDrawerBook]=useState("");
  // console.log(drawerBook,"drawerBook")\

  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getBibliographyData.bibliographyByResearchCategory(
            tableName,
            rcAlias
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getBibliographyData.bibliographyByResearchCategory(
              tableName,
              rcAlias
            )) || {};
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
  }, [apply, filterOptions, rcAlias, searchTerm, tableName]);

  //drawer dynamic
  const [drawerBook, setDrawerBook] = useState("");
  const [drawerDatas, setDrawerDatas] = useState();
  useEffect(() => {
    const getDrawerBookData = async () => {
      const result = await getBibliographyData.getBibliographyBookData(
        drawerBook,
        rcAlias
      );
      if (result) {
        setDrawerDatas(result);
      }
    };
    getDrawerBookData();
  }, [drawerBook, rcAlias]);

  return (
    <>
      <Stack flexDirection={"row-reverse"} width={{base:"100%",md:"1280px"}} >
        {!main && drawerDatas && (
          <ResearchCentreDrawer
            data={drawerDatas && drawerDatas}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleClose={handleClose}
            handleBookClick={handleBookClick}
            handleContentClicked={handleContentClicked}
            scanClicked={scanClicked}
            contentClicked={contentClicked}
            bibliographyObject={bibliographyObject}
            setSwiper={setSwiper}
            indexValue={indexValue}
          />
        )}
        {!showFilterBox && !main && <FilterButton onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="nemaicont"
          px="0"
          py="8"
          height={"100vh"}
          overflowY={"scroll"}
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="center">
            {FilterData.length > 0 ? (
              <HStack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                marginRight={"30px"}
              >
                <ResearchImageGridList
                  data={FilterData}
                  handleDrawer={handleDrawer}
                  border={true}
                />
              </HStack>
            ) : (
              <>
                <HStack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                  >
                    {common.NO_DATA}
                  </Text>
                </HStack>
              </>
            )}
            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterNemai">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold
                        as="u"
                        color={"black"}
                        cursor="pointer"
                        onClick={() => {
                          setFilterOptions({
                            documentType: [],
                            year: [],
                            creatorType: [],
                            publisher: [],
                          });
                          setSearchTerm("");
                        }}
                      >
                        {explore.RESET}
                        <span style={{ color: "#004F79" }}>
                          {" "}
                          {filterCount > 0 && ` (${filterCount})`}
                        </span>
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                        onChange={handleSearch}
                        value={searchTerm}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle allowMultiple>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.creatorTypeFilter.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.creatorType.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("creatorType", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.DATE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.yearFilter.length>0 ? filteroptionData.yearFilter.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.year.includes(item)}
                                  onChange={() => {
                                    handleCheckboxChange("year", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          }):"Content Awaited"}
                        </Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.DOCUMENT_TYPE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <Box maxH="130px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {filteroptionData.documentTypeFilter.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.documentType.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("docType", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>
                                    {`Book.${item}`}
                                  </Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.PUBLISHER}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <Box maxH="130px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {filteroptionData?.publisher.length>0 ? filteroptionData?.publisher.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions?.publisher.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("publisher", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          }):"Content Awaited"}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                  </Accordion>
                  <HStack marginTop={"10px"}>
                    <Button
                      color={"#ffffff"}
                      onClick={() => {
                        setApply((prev) => !prev);
                        setShowFilterBox(false);
                      }}
                      width={"199px"}
                      height={"36px"}
                      bg={"#000000"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      variant={"unstyled"}
                    >
                      {explore.APPLY}
                    </Button>
                  </HStack>
                </Box>
              )}
            </HStack>
          </Stack>
          {contentClicked && (
            <ContentModal
              contentClicked={contentClicked}
              setContentClicked={setContentClicked}
            />
          )}
          {scanClicked && (
            <SliderModal
              scanClicked={scanClicked}
              setScanClicked={setScanClicked}
              sliderModalImg={sliderModalImg}
            />
          )}
          <ScrollToTop />
        </Container>
      </Stack>
    </>
  );
}
export default GridView;
