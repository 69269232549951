import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";

// Core Files
import { useNavigate, useParams } from "react-router-dom";
import { isArray } from "lodash";
import React, { useState } from "react";

// Service Files
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";

// Subsection Files
import MobileNav from "../../components/Topheader";
import { explore } from "../../constants/constants";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`

const artistLinks={
  name:"Satyajit Ray",
  link:{
    name:"Suresh Jindal",
    link:"/shatranj-ke-khilari-1977/suresh-jundal"
  }
}
export default function MainSureshJindalSKK({ children }) {
  let { alias } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const tableName = "films";
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState({});
  const [filmDetails, setFilmDetails] = useState({});
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        winnerName
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist")?.value}/${
              it.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias(tableName, winnerName);
        if (result) {
          setName(
            () =>
              result?.find((item) => item?.key === "filmTitleImage")?.value
                .length > 0 &&
              result?.find((item) => item?.key === "filmTitleImage")?.value[0]
                ?.direct_url_of_preview_image
          );
          setAgraphyLink([
            {
              link: `/research-categories/${
                result?.find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`, // replace with the actual link
              title: result?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.abbreviation,
            },
            {
              link: `/categories/indian-cinema`,
              title: result?.find((item) => item?.key === "subject")?.value,
            },
          ]);
          setfilmMainImage(
            {
               logoImage:result?.find((item) => item?.key === "filmMainImage")?.value[0]
                 ?.direct_url_of_preview_image,
                 logoLink:`/${masterlistType}/${alias}/introduction/imageView`
               }
           );
           const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
          setFilmTrailer({
            link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
            display: result?.find((item) => item?.key === "trailer_link")
              .display,
            image: WatchTrailer,
          });
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
    window.scrollTo(0, 0);
  }, [masterlistType, winnerName, alias]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <SureshJindalSKK />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ filmographyData, winnerName, onClose,
  
  readmoreNavigation,
  setReadmoreNavigation,
  hidden,
  setHidden,
  name,
  member,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  ...rest }) => {
    const navigate = useNavigate();
    const handleNavigation = () => {
      if (readmoreNavigation?.isReadMore) {
        setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
        return;
      } else {
        navigate(-1);
      }
    };
    const drawerSatyajitRay = {
      name: " Satyajit Ray",
      links: [
        { link_name: "Working Still", link: "" },
        { link_name: "Film Stills", link: "" },
        {
          link_name: "Books",
          link: "/shatranj-ke-khilari-1977/research-centre/",
        },
      ],
      Image: SatyajitRayImg,
      desc: "Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working Still | CINE.wks",
      masterlist: {
        text: "Visit Satyajit Ray Masterlist →",
        link: "/satyajit-ray",
      },
    };
    const trailerLink = {
      link:`/${masterlistType}/${winnerName}/watch-film-trailer`,
      image: WatchTrailer,
    };
  
    const oSections = [
      {
        to:`/${masterlistType}/${winnerName}/posts`,
        title: "",
        text: explore.POSTS,
      },
      {
        to:`/${masterlistType}/${winnerName}/insights`,
        title: "",
        text: explore.INSIGHTS,
      },
    ];
  return <CommonLeftSidebar
  sections={introData}
  oSections={oSections} 
  hMenu={agraphyLinks}
  LogoLink={filmMainImage}
  filmReleaseDetails={filmDetails}
  trailerLink={trailerLink}
   artistLinks={artistLinks}
   handleNavigation={handleNavigation}
   drawerData={drawerSatyajitRay}
   drawerImage={SKK1}
   />;
};

const SureshJindalSKK = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        paddingTop: "20%",
        paddingBottom: "50%",
      }}
    >
      <div style={{ alignItems: "center", justifyContent: "center" }}>
        <WebpageComingSoon />
      </div>
    </div>
  );
};