import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core Files
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Subsection Files
import H2 from "../../../../../components/layouts/Typography/H2";
import { EffectFade, Navigation } from "swiper";
import Small from "../../../../../components/layouts/Typography/Small";
import { bibliography } from "../../../../../constants/constants";
import { isArray } from "lodash";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function ResearchCentreDrawer({
  data,
  openDrawer,
  setOpenDrawer,
  handleClose,
  handleBookClick,
  handleContentClicked,
  scanClicked,
  contentClicked,
  setSwiper,
  bibliographyObject,
  indexValue,
  imageTitle,
}) {
  return (
    <>
      <Drawer
        isOpen={openDrawer}
        placement="right"
        onClose={()=>handleClose()}
        id={"rightsidebarsmall"}
        maxW={"400px!important"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack
              mt={"12px"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Stack>
                <HStack>
                  {imageTitle !== undefined ? (
                    <Image width={"265px"} height={"auto"} src={imageTitle} />
                  ) : (
                    <H2 width={"300px"}>{data?.header}</H2>
                  )}
                </HStack>
                {imageTitle && <H2 className="mt-0">{data?.header}</H2>}
              </Stack>
              <HStack>
                <Image
                  src={closeIcon}
                  paddingLeft="5px"
                  cursor={"pointer"}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </HStack>
            </HStack>
          </DrawerHeader>
          <DrawerBody px={"5"} overflowY={"hidden"}>
            <Stack position={"relative"} id="allsliderskkArchive">
              <Swiper
                effect={"fade"}
                grabCursor={true}
                navigation={true}
                modules={[EffectFade, Navigation]}
                style={{ marginTop: "30px" }}
                onSwiper={setSwiper}
              >
                {isArray(data?.sliderImage) &&
                  data?.sliderImage.map((it) => (
                    <SwiperSlide>
                      <Box width={"auto"} height={"303px"}>
                        <Image
                          src={it}
                          onClick={() =>
                            handleBookClick(true, bibliographyObject)
                          }
                          cursor={"pointer"}
                          width={"100%"}
                          height={"auto"}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
            <HStack pt={"44px"}>
              <Small className="autherlink">
                <>
                  {data?.typeCreator}
                  {": "}
                  <Link to={`it.link`} color="#035DA1">
                    {data?.fullname}{" "}
                  </Link>
                  {bibliography.BOOK + ": "}
                  <Link to={`it.link`} color="#035DA1">
                    {data?.book}{" "}
                  </Link>
                  {bibliography.PUBLISHER + ": "}
                  <Link to={`it.link`} color="#035DA1">
                    {data?.publisher}{" "}
                  </Link>
                  {bibliography.YEAR_OF_PUBLICATION + ": "}
                  <Link to={`it.link`} color="#035DA1">
                    {data?.publicationYear}{" "}
                  </Link>
                </>
              </Small>
            </HStack>
          </DrawerBody>
          <DrawerFooter justifyContent={"flex-start"}>
            <Stack justifyContent={"flex-start"}>
              {data.footer &&
                data.footer.map((it, index) => (
                  <HStack cursor={"pointer"}>
                    <Text
                      fontSize="12px"
                      className="autherlink"
                      color="#035DA1"
                      lineHeight={"15px"}
                      onClick={(e) => {
                        handleContentClicked(index, it.text);
                      }}
                      fontWeight={
                        (contentClicked || scanClicked) && indexValue === index
                          ? "700"
                          : "400"
                      }
                    >
                      {it.text}
                    </Text>
                  </HStack>
                ))}
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ResearchCentreDrawer;
