//Core files
import React, { useEffect, useState } from 'react'

//Service files
import { getBookletSongsData } from '../../../services/Booklet';

//Component files
import ImageGridList from "../../Film/BodyOfWork/CrewAndCast/ImageGridList";

function SynopsisBooklet({handleImageClick}) {

 const [bookletSongsData,setBookletSongsData]=useState([])
   useEffect(() => {
    const getData = async () => {
      const result = await getBookletSongsData();
      if (result) {
        setBookletSongsData(result);
      }
    };
    getData();
  window.scrollTo(0, 0);
  }, []);

  return (
    <>
        <ImageGridList columns={[1, null, 4]} spacing={"38px"} data={bookletSongsData} text={false} handleImageClick={handleImageClick}/>
    </>
    )
}

export default SynopsisBooklet;