import {
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { TbFoldersOff } from "react-icons/tb";

//Service files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getExhibitions from "../../../services/PersonArtist";

//Language files
import { common } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SHrazaPainter = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/AwardsnHonours/SHRaza.png`

function AwardsHonours({ tableName, masterlist }) {
  const [FilterData, setFilterData] = useState([{}]);
  const [loading, setLoading] = useState(true);

  const citationFactor = (data) => {
    return data.map((item) => ({
      year: item?.sortable_year,
      desc: `${item?.award_name} | ${item?.issuing_authority} ${
        item?.sortable_date ? "| " + item?.sortable_date :""
      }`,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      let result;
      result = await getExhibitions.getAwardHonoursData(tableName, masterlist);

      const data = result || []; // Ensure data is not null or undefined
      setLoading(false);
      setFilterData(citationFactor(data));
    };
    fetchData();
  }, [masterlist, tableName]);

  return (
    <>
      {!loading && FilterData?.length !== 0 ? (
        <Stack flexDirection={"row-reverse"} justifyContent="space-between">
          {FilterData?.length !== 0 ? (
            <Container
              position={"relative"}
              maxW="auto"
              px="40px"
              className=""
              paddingTop={{ base: "40px", md: "0px" }}
            >
              <Stack
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
                gap={4}
                minH={{ base: "100vh", md: "82vh" }}
              >
                <HStack
                  flexDirection={"column"}
                  flex="1"
                  onCopy={(e) => CopyPasteService(e, window.location.href)}
                >
                  <VerticalTimeline layout={"1-column-left"} className="layout">
                    {FilterData?.map((item, index) => (
                      <VerticalTimelineElement
                        id="satyajit_timeline"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                          background: "#fff",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                        icon={item?.year}
                      >
                        <Stack
                          p="0"
                          flexDirection={"inherit"}
                          flexWrap="wrap"
                          gap="1"
                        >
                          <HStack>
                            <Text
                              color="#000000"
                              m="0"
                              fontSize={"16px"}
                              width={{ base: "100%", md: "650px" }}
                            >
                              {`${item?.desc} `}
                            </Text>
                          </HStack>
                        </Stack>
                      </VerticalTimelineElement>
                    ))}
                  </VerticalTimeline>
                </HStack>
                <HStack
                  px={"0px"}
                  className="col-4"
                  alignItems={"flex-start"}
                  justifyContent={"flex-end"}
                  paddingLeft={"50px"}
                >
                  <Stack
                    className="col-12 mt-4"
                    id="allslidersatyajitAward"
                    position={"relative"}
                    flexDirection={"row-reverse"}
                  >
                    <HStack>
                      <Image
                        height={"auto"}
                        width={"auto"}
                        src={SHrazaPainter}
                      />
                    </HStack>
                  </Stack>
                </HStack>
              </Stack>
            </Container>
          ) : (
            <Container
              position={"relative"}
              maxW="auto"
              px="0px"
              className="songs"
              mt={"0px !important"}
            >
              {" "}
              <Stack
                flexDirection={"row"}
                justifyContent="space-between"
                height={"100vh"}
                gap={4}
              >
                <HStack
                  flexDirection={"column"}
                  flex="1"
                  onCopy={(e) => CopyPasteService(e, window.location.href)}
                >
                  {" "}
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <TbFoldersOff style={{ marginRight: "20px" }} />
                    {common.NO_DATA}
                  </Text>
                </HStack>
                <HStack
                  alignItems={"flex-start"}
                  marginRight={"-40px!important"}
                  marginTop={"0px!important"}
                ></HStack>
              </Stack>
            </Container>
          )}
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.LOADING}
          </Text>
        </Stack>
      )}
    </>
  );
}
export default AwardsHonours;
