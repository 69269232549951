import {
  Box,
  CloseButton,
  Container,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import MobileNav from "../../components/Topheader";
import Slider from "./Slider";
import {
  common,
  explore,
  masterpersonalityName,
  personalityType,
} from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`
const ycircle = `${IMAGE_BASE_PATH}/ui/Chevron/sidecircle.png`
const sub0 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe0.png`
const sub1 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe1.png`
const Hline = `${IMAGE_BASE_PATH}/ui/Chevron/Hline2.png`
const HomeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/home.png`

export default function EbrahimAlkazi() {
  const { isOpen, onClose } = useDisclosure();

  return (
    <SidebarWithHeader>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        bgOpacity="100%"
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack alignItems={"center"}>
              <HStack pt="16" pb="8">
                <Image src={sub0}></Image>
              </HStack>
              <HStack pb="40px">
                <Text fontSize={"35px"} fontWeight={"700"} textAlign="center">
                  {common.SUBSCRIBE_TO_READ_MORE}
                </Text>
              </HStack>
              <HStack pb="8">
                <Box bg="#FFC015" width={"421px"} p="3">
                  <Text fontSize={"25px"} fontWeight={"700"} textAlign="center">
                    {common.SUBSCRIBE}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Image onClick={onClose} src={sub1}></Image>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Stack width={"auto"}>
        <Container maxW={"1400"} px={"0px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt="9"
            variant="unstyled"
          >
            <TabList
              overflowX="auto"
              justify-content={"flex-end"}
              pb="4"
              className="exploretabs"
              gap={"72px"}
            >
              <Tab
                p="0"
                className="title-on-slide"
                textStyle={"tertiary"}
                fontSize={"45px"}
                fontWeight={"700"}
                fontStyle="normal"
                lineHeight={"67px"}
                color={"#333333"}
                _selected={{
                  borderBlockEnd: "4px",
                  borderColor: "#333333",
                  variant: "unstyled",
                  borderRadius: "4px",
                }}
              >
                {masterpersonalityName.EBRAHIMALKAZI}
              </Tab>

              {/* Objects of Focus (02) Tab */}
              <Tab
                p="0"
                Id="object-of-focus"
                className="selected-tab"
                color={"#333333"}
                textStyle={"primary"}
                fontSize={"16px"}
                fontWeight={"600"}
                _selected={{
                  borderBottom: "4px",
                  borderColor: "#333333",
                  borderRadius: "4px",
                  height: "30px",
                  marginTop: "22px",
                  fontWeight: "700",
                }}
              >
                {explore.OBJECTOFFOCUS_TAB_TITLE} (01)
              </Tab>

              {/* Collections (00) Tab */}
              <Tab
                p="0"
                className="selected-tab"
                color={"#333333"}
                textStyle={"primary"}
                fontSize={"16px"}
                fontWeight={"600"}
                _selected={{
                  borderBottom: "4px",
                  borderColor: "#333333",
                  borderRadius: "4px",
                  height: "30px",
                  marginTop: "22px",
                  fontWeight: "700",
                }}
              >
                {explore.COLLECTIONS_TAB_TITLE} (0)
              </Tab>

              {/* Curated Themes (00) Tab */}
              <Tab
                p="0"
                className="selected-tab"
                color={"#333333"}
                textStyle={"primary"}
                fontSize={"16px"}
                fontWeight={"600"}
                _selected={{
                  borderBottom: "4px",
                  borderColor: "#333333",
                  borderRadius: "4px",
                  height: "30px",
                  marginTop: "22px",
                  fontWeight: "700",
                }}
              >
                {explore.CURATEDTHEMES_TAB_TITLE} (0)
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={"0"} pt="30px" p="0px" className="masterlistslider">
                <Slider />
                <Stack
                  marginTop={"-85px"}
                  justifyContent="space-between"
                  flexDirection={"row"}
                  // px="10"
                  paddingLeft={"30px"}
                >
                  <HStack zIndex={"9999"}>
                    {/* <Link href="/research-categories/cinemae"> */}
                    <Text fontSize={"15px"} fontWeight={"600"} color="black">
                      {personalityType.FINEARTS} |
                    </Text>
                    {/* </Link> */}
                    {/* <Link href="/research-categories/indian-cinema"> */}
                    <Text
                      fontSize={"15px"}
                      fontWeight={"600"}
                      color="black"
                      marginLeft={"-4px"}
                    >
                      {explore.INDIAN_ART} | {personalityType.ARTS}
                    </Text>
                    {/* </Link> */}
                    <Image src={Hline}></Image>
                  </HStack>
                  <HStack>
                    <Link my="5px" py="3">
                      <Image
                        src={ycircle}
                        width="auto"
                        height={"100%"}
                        px="1"
                        pb="1"
                      ></Image>
                      <Text
                        fontSize={"10px"}
                        fontWeight="700"
                        textStyle={"secondary"}
                        color={"#0066B3"}
                        textAlign="center"
                      >
                        {common.SUBSCRIBE}
                      </Text>
                    </Link>
                  </HStack>
                </Stack>
                <Stack alignItems={"center"}>
                  <Box>
                    <Text
                      py={"40"}
                      fontSize={"50px"}
                      fontWeight="900"
                      textAlign="center"
                    >
                      {common.CONTENT_AWAITED}
                    </Text>
                  </Box>
                </Stack>
              </TabPanel>
              <TabPanel px={"0"} py="0" width="100%">
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {common.CONTENT_AWAITED}
                </Text>
              </TabPanel>
              <TabPanel px={"0"} py="0" width="100%">
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                 {common.WEBPAGE_COMING_SOON}
                </Text>
              </TabPanel>
              <TabPanel>
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {common.WEBPAGE_COMING_SOON}
                </Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [setPage] = useState("banner");
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}

      <MobileNav onOpen={onOpen} setPage={setPage} />
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("black", "gray.900")}
      // borderRight="1px"
      // borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 14 }}
      pos="fixed"
      h="full"
      {...rest}
      color={"white"}
    >
      <Flex
        h="20"
        alignItems="center"
        m="8"
        justifyContent="space-between"
        flexDirection="row-reverse"
      >
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <HStack justifyContent="center">
        <Link
          href="/"
          _hover={{
            textDecoration: "none",
          }}
          py="4"
        >
          <Image
            filter={"opacity(0.34)"}
            _hover={{
              filter: "opacity(1)",
            }}
            src={HomeIcon}
            width="16px"
            height={"17px"}
          ></Image>
        </Link>
      </HStack>
      <HStack
        justifyContent="flex-start"
        borderLeft="4px"
        borderColor="#FF4E4E"
        borderRadius="0px"
      >
        <Link href="/explore">
          <IconButton
            my="6px"
            py="3"
            pl="2"
            fontSize="22px"
            bg={"black"}
            color={"white"}
            _hover={{
              color: "white",
            }}
          >
            <RiLayoutGridFill />
          </IconButton>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link href="/calendar">
          <IconButton
            my="2"
            py="4"
            fontSize="24px"
            bg={"black"}
            color={"#4C4C4C"}
            _hover={{
              color: "white",
            }}
          >
            <IoMdCalendar />
          </IconButton>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link href="/map">
          <IconButton
            my="2"
            py="4"
            fontSize="22px"
            bg={"black"}
            color={"#4C4C4C"}
            _hover={{
              color: "white",
            }}
          >
            <MdMap />
          </IconButton>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link my="5px">
          {/* <Text fontSize={"10px"} color={"white"}>
                Welcome, {userName}!
              </Text> */}
          <Image
            src={member}
            onClick={toggleModal}
            width="auto"
            height={"100%"}
            py="3"
            px="3"
          ></Image>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link href="/osianamaworld" my="5px" py="3" id="DivForHoverItem">
          <Image
            src={circle}
            // onClick={toggleModal}
            width="auto"
            height={"100%"}
            px="1"
            pb="1"
          ></Image>
          {/* <Text
                fontSize={"10px"}
                fontWeight="700"
                textStyle={"secondary"}
                color={"#0066B3"}
                textAlign="center"
                id="HiddenText"
              >
                Subscribe
              </Text> */}
        </Link>
      </HStack>
    </Box>
  );
};
