import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { TbFoldersOff } from "react-icons/tb";
import { FaUserAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

//Service files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getExhibitions from "../../../services/PersonArtist";

//Component files
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import CommonDrawer from "../../Film/BodyOfWork/Components/CommonDrawer";
import { isTransformationNotAllowed } from "../../../util/isTranformationAllowed";

//Language files
import { common, explore, filterAttribute } from "../../../constants/constants";
import PersonArtistCatlouge from "./PersonArtistCatlouge";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const drawerImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/drawerImg.jpg`

function TabularView({ tableName, masterlist,masterlistType }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    languages_v: [],
    filmTypes_v: [],
    filmLength_v: [],
    searches_v: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [apply, setApply] = useState(false);
  const [FilterData, setFilterData] = useState([{}]);
  const [filterCount, setfilterCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [catalogue, setCatalogue] = useState(null);
  const {catalouge}=useParams();

  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getExhibitions.getExhibitionsFilterOptions(
        "tableName",
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, tableName]);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "languages":
        selectedArray = filterOptions.languages_v;
        break;
      case "film_type":
        selectedArray = filterOptions.filmTypes_v;
        break;
      case "film_length":
        selectedArray = filterOptions.filmLength_v;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "languages":
        setFilterOptions({
          ...filterOptions,
          languages_v: selectedArray,
        });
        break;
      case "film_type":
        setFilterOptions({
          ...filterOptions,
          filmTypes_v: selectedArray,
        });
        break;
      case "film_length":
        setFilterOptions({
          ...filterOptions,
          filmLength_v: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const drawerShatranjkeKhilari = {
    name: " Sayed Haider Raza & The Bombay Art Society",
    links: [
      { link_name: "Exhibitions", link: "" },
      {
        link_name: "Books",
        link: "",
      },
      {
        link_name: "Artworks",
        link: "",
      },
      {
        link_name: "Posts",
        link: "",
      },
    ],
    Image: drawerImg,
    desc: "The Bombay Art Society is a non-profit art organization based in Mumbai. The institution was founded in 1888 for encouraging & promoting art.",
    masterlist: {
      text: "Visit the Bombay Art Society Masterlist →",
      link: "",
    },
  };

  // Fetch data function
  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getExhibitions.getExhibitionsDataByMasterlist(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getExhibitions.getExhibitionsDataByMasterlist(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }

    const data = result || []; // Ensure data is not null or undefined
    setLoading(false);
    setFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
      }
      setApply(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const citationFactor = (data) => {
    return data.map((item) => ({
      year: item?.year,
      desc: `${item?.exhibition} - ${item?.exhibition_centre}`,
      shortdesc: `${item?.exhibit_date ? item?.exhibit_date + "," : ""} ${
        item?.city ? item?.city + "," : ""
      } ${item?.country}`,
      image: item?.img,
      link: item?.link,
      catalogue: item?.catalogue,
      catalogueTitle: `${item?.exhibition} ${
        item?.exhibit_date ? item?.exhibit_date + "," : ""
      } ${item?.exhibition_centre} ${item?.city ? item?.city + "," : ""} ${
        item?.country
      }`,
      exhibition_centre:item?.exhibition_centre
    }));
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          await getExhibitions.getExhibitionsDataByMasterlist(
            tableName,
            masterlist
          );
        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setLoading(false);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            await getExhibitions.getExhibitionsDataByMasterlist(
              tableName,
              masterlist
            );
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setLoading(false);
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
    window.scrollTo(0, 0);
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  useEffect(() => {}, [catalogue]);
  return (
    <>
      {!loading && FilterData?.length !== 0 ? (
        <>
          {catalogue !== null && catalouge !== undefined  ? (
            <PersonArtistCatlouge data={catalogue} masterlist={masterlist} />
          ) : (
            <Stack flexDirection={"row-reverse"} justifyContent="space-between">
              {!showFilterBox && <FilterButton onClick={handleFilterClick} />}
              {FilterData?.length !== 0 ? (
                <Container
                  position={"relative"}
                  maxW="auto"
                  px="40px"
                  className=""
                  paddingTop={{ base: "40px", md: "0px" }}
                >
                  <Stack
                    flexDirection={{ base: "column", md: "row" }}
                    justifyContent="space-between"
                    gap={4}
                    minH={{ base: "100vh", md: "82vh" }}
                  >
                    <HStack
                      flexDirection={"column"}
                      flex="1"
                      onCopy={(e) => CopyPasteService(e, window.location.href)}
                    >
                      <VerticalTimeline
                        layout={"1-column-left"}
                        className="layout"
                      >
                        <CommonDrawer
                          setDrawerOpen={setOpenDrawer}
                          drawerOpen={openDrawer}
                          data={drawerShatranjkeKhilari}
                        />

                        {FilterData?.map((item, index) => (
                          <VerticalTimelineElement
                            id="satyajit_timeline"
                            contentStyle={{ fontSize: "sm" }}
                            iconStyle={{
                              background: "#fff",
                              color: "black",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                            icon={item.year}
                          >
                            <Stack
                              p="0"
                              flexDirection={"inherit"}
                              flexWrap="wrap"
                              gap="1"
                            >
                              <HStack>
                                {item.link ? (
                                  <Text
                                    color="#035DA1"
                                    m="0"
                                    width={{ base: "100%", md: "650px" }}
                                    onClick={() => {
                                      setOpenDrawer(true);
                                    }}
                                    cursor={"pointer"}
                                  >
                                    {item?.image ? (
                                      <Image
                                        src={item?.image}
                                        display={"inline"}
                                        marginRight={"8px"}
                                      />
                                    ) : (
                                      <FaUserAlt
                                        color="black"
                                        display={"inline"}
                                        fontSize={"11px"}
                                      />
                                    )}
                                    {`${item?.desc} `}
                                  </Text>
                                ) : (
                                  <Text
                                    color="#035DA1"
                                    m="0"
                                    fontSize={"16px"}
                                    width={{ base: "100%", md: "650px" }}
                                  >
                                    {item?.image ? (
                                      <Image
                                        src={item?.image}
                                        display={"inline"}
                                        marginRight={"8px"}
                                      />
                                    ) : (
                                      <FaUserAlt
                                        color="black"
                                        display={"inline"}
                                        fontSize={"11px"}
                                      />
                                    )}
                                    {`${item?.desc} `}
                                  </Text>
                                )}
                              </HStack>
                              <HStack mt={"0px !important"}>
                                {item.catalogue ? (
                             <Link to={`/${masterlistType}/${masterlist}/body-of-work/${item.catalogue.toLowerCase()}`}>
                                  <Text
                                    color="#000000"
                                    mt="0"
                                    width={{ base: "100%", md: "650px" }}
                                    cursor={"pointer"}
                                    fontWeight={400}
                                    fontSize={"12px !important"}
                                    onClick={() => setCatalogue(item)}
                                  >
                                    {" "}
                                    {`${item?.shortdesc} `}
                                    <span
                                     style={{color:"#035DA1",
                                     fontWeight:700}}
                                    >{`${
                                      item?.catalogue ? item?.catalogue : ""
                                    }`}</span>
                                  </Text>
                                  </Link>
                                ) : (
                                  <Text
                                    color="#000000"
                                    mt="0"
                                    width={{ base: "100%", md: "650px" }}
                                    fontSize={"12px !important"}
                                  >
                                    {/* For reference use */}
                                    {/* {item?.film_length && (
                                    <>{`${item?.film_length} Film | `}</>
                                  )}
                                  {item?.film_type && <>{`${item?.film_type}`}</>}
  
                                  {item?.colour && <>{` | ${item?.colour} `}</>}
                                  {item?.duration && (
                                    <>{` | ${item?.duration} `}</>
                                  )}
                                  {item?.region && <>{` | ${item?.region} `}</>} */}
                                    {item?.shortdesc}
                                  </Text>
                                )}
                              </HStack>
                            </Stack>
                          </VerticalTimelineElement>
                        ))}
                      </VerticalTimeline>
                    </HStack>
                    <HStack
                      alignItems={"flex-start"}
                      marginRight={"-40px!important"}
                      marginTop={"0px!important"}
                    >
                      {showFilterBox && (
                        <Box className="box-filterArtHeritage">
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <HStack>
                              <SmallBold
                                as="u"
                                color={"black"}
                                cursor={"pointer"}
                                onClick={() => {
                                  setFilterOptions({
                                    languages_v: [],
                                    filmLength_v: [],
                                    filmTypes_v: [],
                                  });
                                  setSearchTerm("");
                                }}
                              >
                                {explore.RESET}{" "}
                                <span style={{ color: "#004F79" }}>
                                  {" "}
                                  {filterCount > 0 && ` (${filterCount})`}
                                </span>
                              </SmallBold>
                            </HStack>
                            <HStack className="mt-0">
                              <RiCloseCircleFill
                                size={"22px"}
                                cursor="pointer"
                                onClick={(e) => setShowFilterBox(false)}
                              />
                            </HStack>
                          </Stack>
                          <HStack alignItems={"left"} flexDirection={"column"}>
                            <SmallBold py="4">Search</SmallBold>
                            <InputGroup className="ml-0" pb="4">
                              <Input
                                onChange={handleSearch}
                                placeholder="Search"
                                value={searchTerm}
                              />

                              <InputRightElement>
                                <BiSearch />
                              </InputRightElement>
                            </InputGroup>
                          </HStack>
                          <Accordion allowToggle allowMultiple>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.CATEGORIES}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel
                                pb={4}
                                className="px-1"
                                height={"144px"}
                                overflowY={"scroll"}
                              >
                                {filteroptionData.categoriesFilter &&
                                  filteroptionData.categoriesFilter.map(
                                    (it) => {
                                      return (
                                        <Flex
                                          borderBottom={"1px"}
                                          borderBottomColor={"#f1f1f1"}
                                        >
                                          <Checkbox
                                            borderColor={"black"}
                                            _checked={{
                                              "& .chakra-checkbox__control": {
                                                background: "black",
                                              },
                                            }}
                                            iconColor="white"
                                            cursor="pointer"
                                            marginRight={"5px"}
                                            // isChecked={filterOptions.categories_v.includes(
                                            //   it
                                            // )}
                                            // onChange={() => {
                                            //   handleCheckboxChange("categories", it);
                                            // }}
                                          />
                                          <Box p="1">
                                            <Text fontSize={"14px"}>{it}</Text>
                                          </Box>
                                        </Flex>
                                      );
                                    }
                                  )}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.LANGUAGES}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel pb={4} className="px-1">
                                {filteroptionData.languageFilter &&
                                  filteroptionData.languageFilter.map((it) => {
                                    return (
                                      <Flex
                                        borderBottom={"1px"}
                                        borderBottomColor={"#f1f1f1"}
                                      >
                                        <Checkbox
                                          borderColor={"black"}
                                          _checked={{
                                            "& .chakra-checkbox__control": {
                                              background: "black",
                                            },
                                          }}
                                          iconColor="white"
                                          cursor="pointer"
                                          marginRight={"5px"}
                                          isChecked={filterOptions.languages_v.includes(
                                            it
                                          )}
                                          onChange={() => {
                                            handleCheckboxChange(
                                              "languages",
                                              it
                                            );
                                          }}
                                        />
                                        <Box p="1">
                                          <Text fontSize={"14px"}>{it}</Text>
                                        </Box>
                                      </Flex>
                                    );
                                  })}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.FILM_TYPE}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel pb={4} className="px-1">
                                {filteroptionData.filmTypeFilter &&
                                  filteroptionData.filmTypeFilter.map((it) => {
                                    return (
                                      <Flex
                                        borderBottom={"1px"}
                                        borderBottomColor={"#f1f1f1"}
                                      >
                                        <Checkbox
                                          borderColor={"black"}
                                          _checked={{
                                            "& .chakra-checkbox__control": {
                                              background: "black",
                                            },
                                          }}
                                          iconColor="white"
                                          cursor="pointer"
                                          marginRight={"5px"}
                                          isChecked={filterOptions.filmTypes_v.includes(
                                            it
                                          )}
                                          onChange={() => {
                                            handleCheckboxChange(
                                              "film_type",
                                              it
                                            );
                                          }}
                                        />
                                        <Box p="1">
                                          <Text fontSize={"14px"}>{it}</Text>
                                        </Box>
                                      </Flex>
                                    );
                                  })}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.FILM_LENGTH}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel pb={4} className="px-1">
                                {filteroptionData.filmLengthFilter &&
                                  filteroptionData.filmLengthFilter.map(
                                    (it) => {
                                      return (
                                        <Flex
                                          borderBottom={"1px"}
                                          borderBottomColor={"#f1f1f1"}
                                        >
                                          <Checkbox
                                            borderColor={"black"}
                                            _checked={{
                                              "& .chakra-checkbox__control": {
                                                background: "black",
                                              },
                                            }}
                                            iconColor="white"
                                            cursor="pointer"
                                            marginRight={"5px"}
                                            isChecked={filterOptions.filmLength_v.includes(
                                              it
                                            )}
                                            onChange={() => {
                                              handleCheckboxChange(
                                                "film_length",
                                                it
                                              );
                                            }}
                                          />
                                          <Box p="1">
                                            <Text fontSize={"14px"}>{it}</Text>
                                          </Box>
                                        </Flex>
                                      );
                                    }
                                  )}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                          <HStack marginTop={"10px"}>
                            <Button
                              color={"#ffffff"}
                              onClick={() => {
                                setApply((prev) => !prev);
                                setShowFilterBox(false);
                              }}
                              width={"199px"}
                              height={"36px"}
                              bg={"#000000"}
                              fontSize={"16px"}
                              fontWeight={"400"}
                              variant={"unstyled"}
                            >
                              {explore.APPLY}
                            </Button>
                          </HStack>
                        </Box>
                      )}
                    </HStack>
                  </Stack>
                </Container>
              ) : (
                <Container
                  position={"relative"}
                  maxW="auto"
                  px="0px"
                  className="songs"
                  mt={"0px !important"}
                >
                  {" "}
                  <Stack
                    flexDirection={"row"}
                    justifyContent="space-between"
                    height={"100vh"}
                    gap={4}
                  >
                    <HStack
                      flexDirection={"column"}
                      flex="1"
                      onCopy={(e) => CopyPasteService(e, window.location.href)}
                    >
                      {" "}
                      <Text
                        py={"40"}
                        fontSize={"45px"}
                        fontWeight="700"
                        textAlign="center"
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <TbFoldersOff style={{ marginRight: "20px" }} />
                        {common.NO_DATA}
                      </Text>
                    </HStack>
                    <HStack
                      alignItems={"flex-start"}
                      marginRight={"-40px!important"}
                      marginTop={"0px!important"}
                    >
                      {showFilterBox && (
                        <Box className="box-filterArtHeritage">
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <HStack>
                              <SmallBold
                                as="u"
                                color={"black"}
                                cursor={"pointer"}
                                onClick={() => {
                                  setFilterOptions({
                                    languages_v: [],
                                    filmLength_v: [],
                                    filmTypes_v: [],
                                  });
                                  setSearchTerm("");
                                }}
                              >
                                {explore.RESET}
                                <span style={{ color: "#004F79" }}>
                                  {" "}
                                  {filterCount > 0 && ` (${filterCount})`}
                                </span>
                              </SmallBold>
                            </HStack>
                            <HStack className="mt-0">
                              <RiCloseCircleFill
                                size={"22px"}
                                cursor="pointer"
                                onClick={(e) => setShowFilterBox(false)}
                              />
                            </HStack>
                          </Stack>
                          <HStack
                            alignItems={"left"}
                            flexDirection={"column"}
                            marginTop={"25px"}
                          >
                            <InputGroup className="ml-0" pb="4">
                              <Input
                                placeholder="Search"
                                height={"32px"}
                                pb={"4px"}
                                value={searchTerm}
                                _placeholder={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              />
                              <InputRightElement pb={"6px"}>
                                <BiSearch color="green.500" fontSize={"16px"} />
                              </InputRightElement>
                            </InputGroup>
                          </HStack>
                          <Accordion allowToggle allowMultiple>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.TYPE_OF_EXHIBITION}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel
                                pb={4}
                                className="px-1"
                                height={"144px"}
                                overflowY={"scroll"}
                              >
                                {filteroptionData.typeOfExhibition.length > 0
                                  ? filteroptionData.typeOfExhibition.map(
                                      (it) => {
                                        return (
                                          <Flex
                                            borderBottom={"1px"}
                                            borderBottomColor={"#f1f1f1"}
                                          >
                                            <Checkbox
                                              borderColor={"black"}
                                              _checked={{
                                                "& .chakra-checkbox__control": {
                                                  background: "black",
                                                },
                                              }}
                                              iconColor="white"
                                              cursor="pointer"
                                              marginRight={"5px"}
                                              // isChecked={filterOptions.categories_v.includes(
                                              //   it
                                              // )}
                                              // onChange={() => {
                                              //   handleCheckboxChange("categories", it);
                                              // }}
                                            />
                                            <Box p="1">
                                              <Text fontSize={"14px"}>
                                                {it}
                                              </Text>
                                            </Box>
                                          </Flex>
                                        );
                                      }
                                    )
                                  : common.CONTENT_AWAITED}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.VENUE_LOCATION}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel pb={4} className="px-1">
                                {filteroptionData.venueLocation.length > 0
                                  ? filteroptionData.venueLocation.map((it) => {
                                      return (
                                        <Flex
                                          borderBottom={"1px"}
                                          borderBottomColor={"#f1f1f1"}
                                        >
                                          <Checkbox
                                            borderColor={"black"}
                                            _checked={{
                                              "& .chakra-checkbox__control": {
                                                background: "black",
                                              },
                                            }}
                                            iconColor="white"
                                            cursor="pointer"
                                            marginRight={"5px"}
                                            isChecked={filterOptions.languages_v.includes(
                                              it
                                            )}
                                            onChange={() => {
                                              handleCheckboxChange(
                                                "languages",
                                                it
                                              );
                                            }}
                                          />
                                          <Box p="1">
                                            <Text fontSize={"14px"}>{it}</Text>
                                          </Box>
                                        </Flex>
                                      );
                                    })
                                  : common.CONTENT_AWAITED}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.YEAR}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel pb={4} className="px-1">
                                {filteroptionData.year.length > 0
                                  ? filteroptionData.year.map((it) => {
                                      return (
                                        <Flex
                                          borderBottom={"1px"}
                                          borderBottomColor={"#f1f1f1"}
                                        >
                                          <Checkbox
                                            borderColor={"black"}
                                            _checked={{
                                              "& .chakra-checkbox__control": {
                                                background: "black",
                                              },
                                            }}
                                            iconColor="white"
                                            cursor="pointer"
                                            marginRight={"5px"}
                                            isChecked={filterOptions.filmTypes_v.includes(
                                              it
                                            )}
                                            onChange={() => {
                                              handleCheckboxChange(
                                                "film_type",
                                                it
                                              );
                                            }}
                                          />
                                          <Box p="1">
                                            <Text fontSize={"14px"}>{it}</Text>
                                          </Box>
                                        </Flex>
                                      );
                                    })
                                  : common.CONTENT_AWAITED}{" "}
                              </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionButton className="px-0">
                                <Box flex="1" textAlign="left">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    lineHeight={"24px"}
                                  >
                                    {filterAttribute.MEDIUM}
                                  </Text>
                                </Box>
                                <AccordionIcon fontSize={"30px"} />
                              </AccordionButton>
                              <AccordionPanel pb={4} className="px-1">
                                {filteroptionData.medium.length > 0
                                  ? filteroptionData.medium.map((it) => {
                                      return (
                                        <Flex
                                          borderBottom={"1px"}
                                          borderBottomColor={"#f1f1f1"}
                                        >
                                          <Checkbox
                                            borderColor={"black"}
                                            _checked={{
                                              "& .chakra-checkbox__control": {
                                                background: "black",
                                              },
                                            }}
                                            iconColor="white"
                                            cursor="pointer"
                                            marginRight={"5px"}
                                            isChecked={filterOptions.filmLength_v.includes(
                                              it
                                            )}
                                            onChange={() => {
                                              handleCheckboxChange(
                                                "film_length",
                                                it
                                              );
                                            }}
                                          />
                                          <Box p="1">
                                            <Text fontSize={"14px"}>{it}</Text>
                                          </Box>
                                        </Flex>
                                      );
                                    })
                                  : common.CONTENT_AWAITED}{" "}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                          <HStack marginTop={"10px"}>
                            <Button
                              color={"#ffffff"}
                              onClick={() => {
                                setApply((prev) => !prev);
                                setShowFilterBox(false);
                              }}
                              width={"199px"}
                              height={"36px"}
                              bg={"#000000"}
                              fontSize={"16px"}
                              fontWeight={"400"}
                              variant={"unstyled"}
                            >
                              {explore.APPLY}
                            </Button>
                          </HStack>
                        </Box>
                      )}
                    </HStack>
                  </Stack>
                </Container>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.LOADING}
          </Text>
        </Stack>
      )}
    </>
  );
}
export default TabularView;
