import { masterlistsData } from "../MockData/MastrlistsData";

export const getMasterlistData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return masterlistsData;
  } else {
    return [];
  }
};
