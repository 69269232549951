
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import { BookDetails } from "./ResearchCentre/Library/BookDetails";
import MobileNav from "../../components/Topheader";
import { Library } from "./ResearchCentre/Library";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import ReadMore from "./ReadMore";
import Archive from "./ResearchCentre/Archive";
import All from "./ResearchCentre/All";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

//Language files
import { explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg4.png`

export default function ResearchCentre({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [journalDetails, setJournalDetails] = useState({});

  const {alias}=useParams();
  const masterlist = alias;
  const masterlistType="location"
  const [researchCentreFlag, setResearchCentreFlag] = React.useState(true);
  const [bookFlag, setBookFlag] = useState(false);
  const [leftFlag, setleftFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bookReadmore, setBookReadmore] = useState(false);
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        masterlist
      );
      if (result) {
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        masterlist
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }`,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlist]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        bookReadmore={bookReadmore}
        setBookReadmore={setBookReadmore}
        bookFlag={bookFlag}
        openDrawer={openDrawer}
        leftFlag={leftFlag}
        setBookFlag={setBookFlag}
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        alias={alias}
        masterlistType={masterlistType}
        journalDetails={journalDetails}
        name={name}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            leftFlag={leftFlag}
            bookFlag={bookFlag}
            setBookFlag={setBookFlag}
            researchCentreFlag={researchCentreFlag}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <LibraryAndArchive
          masterlist={masterlist}
          masterlistType={masterlistType}
          setResearchCentreFlag={setResearchCentreFlag}
          bookFlag={bookFlag}
          setBookFlag={setBookFlag}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setleftFlag={setleftFlag}
          bookReadmore={bookReadmore}
          setBookReadmore={setBookReadmore}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  leftFlag,
  researchCentreFlag,
  openDrawer,
  bookFlag,
  setBookFlag,
  bookReadmore,
  setBookReadmore,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  alias,
  journalDetails,
  ...rest
}) => {
  const navigate = useNavigate();

   /* Need to work :To handle back navigation from the drawer */
  // const handleNavigation = (alias) => {
  //   if (alias) {
  //     navigate(`/categories/${alias}`);
  //   } else if (bookFlag) {
  //     setBookFlag(false);
  //   } else {
  //     navigate(-1);
  //   }
  // };
  const handleNavigation = (alias) => {
    if (bookFlag) {
      if (bookReadmore) {
        setBookReadmore(false);
      } else {
        setBookFlag(false);
      }
    } else {
      navigate(-1);
    }
  };

   /* Need to work :To handle back nivagation from the drawer */
  // const handleBackNavigation = () => {
  //   if (readmoreNavigation?.isReadMore) {
  //     setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
  //     return;
  //   } else {
  //     navigate("/art-heritage-annual/body-of-work/timeline/exhibition_catlouge");
  //   }
  // };

  const oSections = [
    {
      to: `/${masterlistType}/${alias}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${alias}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];

  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
      sections={introData}
      oSections={oSections}
      handleNavigation={handleNavigation}
      bookFlag={bookFlag}
      setBookFlag={setBookFlag}
      openDrawer={openDrawer}
      leftFlag={leftFlag}
      journalDetails={journalDetails}
      researchCentreFlag={researchCentreFlag}
    />
    </>
  );
};

const LibraryAndArchive = ({
  masterlist,
  setResearchCentreFlag,
  bookFlag,
  setBookFlag,
  openDrawer,
  setOpenDrawer,
  setleftFlag,
  bookReadmore,
  setBookReadmore,
  masterlistType,
}) => {
  const [visible, setVisible] = React.useState(true);
  const [listGridIcon, setListGridIcon] = useState(true);
  const navigate = useNavigate();
  let { tabIndex, gridview } = useParams();
  let {bookDetails}=useParams();

  /* Need to check after dynamic book data */

  const BookDetail = {
    Title: "Lucknow & The Lucknow Omnibus",
    sliderData: [
      { image: Img1 },
      { image: Img2 },
      { image: Img3 },
      { image: Img4 },
      { image: Img1 },
    ],
    Book: [
      {
        title: "About the Book",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        readmore: true,
      },
      {
        title: "About the Author",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id Read More",
        readmore: true,
      },
    ],
    accordion: [
      {
        title: "Content",
        content: null,
      },
      {
        title: "Index",
        content: null,
      },
    ],
  };

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${masterlist}/research-centre`, { replace: true });


      if (parseInt(tabIndex) === 1) {
        setListGridIcon(true);
      }
      if (parseInt(gridview) === 0) {
        setVisible(false);
      }
      if (parseInt(gridview) === 1) {
        setVisible(true);
      }
    }
  }, [tabIndex, navigate, gridview, masterlistType, masterlist]);

  const handleBookClick = (flag, data) => {
    navigate(`bookDetails`)
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };

  return (
    <Stack width={{base:"100%",md:"1160px"}}>
      <Container minW={"100%"} pb="4" px={{base:"4",md:"0px"}} marginX={{base:"0px",md:"30px"}}>
        <Stack>
        {bookDetails==="bookDetails" ? (
            <>
              {bookReadmore ? (
                <ReadMore />
              ) : (
                <BookDetails
                  BookDetail={BookDetail}
                  bookReadmore={bookReadmore}
                  setBookReadmore={setBookReadmore}
                />
              )}
            </>
          ) : (
            <HStack>
              <Tabs
                  align={{ xs: "end", sm: "" }}
                  width={{ base: "100%", md:"1160px"}}
                  className="verticalscroll"
                defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 1}
              >
                <Stack
                  flexDirection={{base:"row",md:"row-reverse"}}
                  display={"flex"}
                >
                  <Box
                   position={"fixed"}
                   zIndex={999}
                   bg="white"
                   width={{ base: "100%", md: "1160px" }}
                   paddingX={{ base: "10px", md: "20px" }}
                   overflowX={"auto"}
                  >
                    <TabList
                      overflowX="auto"
                      borderBottom={"1px"}
                      borderBottomColor={"#f1f1f1"}
                      gap={12}
                      paddingTop={"30px"}
                      className="verticalscroll"
                      paddingBottom={"4"}
                    >
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(false)}
                      >
                        {explore.ALL}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.LIBRARY}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.ARCHIVE}
                      </Tab>
                    </TabList>
                  </Box>
                  {listGridIcon && (
                    <Stack
                    flexDirection={"row"}
                    gap={0}
                    zIndex={999}
                    position={"fixed"}
                    marginRight={"65px !important"}
                    top={"80px"} 
                    id="listgridviewbtn"
                    >
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(false)}
                          color={!visible ? "black" : "#989898"}
                          fontSize="20px"
                          display="flex"
                          borderTopLeftRadius="6px"
                          borderBottomLeftRadius="6px"
                          borderTopRightRadius="0px"
                          borderBottomRightRadius="0px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <RiMenuLine ml="4" />
                        </IconButton>
                      </HStack>
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(true)}
                          color={visible ? "black" : "#989898"}
                          fontSize="16px"
                          display="flex"
                          borderTopLeftRadius="0px"
                          borderBottomLeftRadius="0px"
                          borderTopRightRadius="6px"
                          borderBottomRightRadius="6px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <BsGrid />
                        </IconButton>
                      </HStack>
                    </Stack>
                  )}
                </Stack>
                <TabPanels paddingTop={"4"}>
                  <TabPanel px="0px">
                    <All visible={visible} />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Library
                      visible={visible}
                      handleBookClick={handleBookClick}
                      openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      setleftFlag={setleftFlag}
                      tableName={"bibliographies"}
                      masterlist={masterlist}
                    />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Archive visible={visible} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
