import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

  //Core file
import React, { useEffect, useRef, useState } from "react";
import { A11y, EffectCreative, EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

//Service file
import { essaySliderdata } from "../../../services/Exhibitions_data";

//Component file
import H2 from "../../../components/layouts/Typography/H2";
import Small from "../../../components/layouts/Typography/Small";

//Language file
import { common } from "../../../constants/constants";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const slimg1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/BodyOfWork/AnnualEditions/Exhibitions/Essays/1.png`
const placeholder = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/8.png`

function Essay({ setleftFlag, setReadMoreFlag, readmoreNavigation }) {
  const refs = useRef();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };
  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);
  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);

      if (readmoreNavigation?.title === "poster") {
        setSynopsisFullScreenImage(true);
      }
    }
  }, [readmoreNavigation]);
  return (
    <div>
      <Container
        width={{ base: "100%", md: "1145px" }}
        height={"100%"}
        className="songs"
        d="esaaySlider"
      >
        <Stack width={"100%"} position={"relative"}>
          <Drawer
            isOpen={openDrawer}
            placement="right"
            finalFocusRef={refs}
            id="rightsidebarsmall"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>
                <HStack mt={"12px"} px="20px" alignItems={"center"}>
                  <Stack>
                    <Image
                      src={closeIcon}
                      paddingLeft="5px"
                      width={"24px"}
                      cursor={"pointer"}
                      onClick={() => {
                        setOpenDrawer(false);
                        setleftFlag(false);
                      }}
                      position={"absolute"}
                      top={"5%"}
                      left={"89%"}
                    />
                    <H2>Art Heritage Annual & Krishna Chaitanya</H2>
                    <VerticalTimeline
                      layout={"1-column-left"}
                      className="layoutDrawer"
                    >
                      <VerticalTimelineElement
                        key={1}
                        id="timeline2"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                          background: "#fff",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                        icon={"1979"}
                      >
                        <Small
                          cursor={"pointer"}
                          color="#035DA1"
                        >
                          Chaitanya, Krishna | Dhanraj Bhagat - Sculptor:
                          Sculptor. New Delhi: Art Heritage Gallery, 1979
                        </Small>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        key={1}
                        id="timeline2"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                          background: "#fff",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                        icon={"1982"}
                      >
                        <Small
                          cursor={"pointer"}
                          color="#035DA1"
                        >
                          Chaitanya, Krishna | Gouri Khosla, 1982{" "}
                        </Small>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        key={1}
                        id="timeline2"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                          background: "#fff",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                        icon={"1992"}
                      >
                        <Small
                          cursor={"pointer"}
                          color="#035DA1"
                        >
                          Chaitanya, Krishna | Rediscovering the World of Self:
                          Discovering the World and the Self, 1992{" "}
                        </Small>
                      </VerticalTimelineElement>
                    </VerticalTimeline>
                  </Stack>
                </HStack>
              </DrawerHeader>

              <DrawerBody px={"5"}>
                <Stack position={"relative"} id="allsliderskkArchive"></Stack>
              </DrawerBody>
              <DrawerFooter justifyContent={"flex-start"}></DrawerFooter>
            </DrawerContent>
          </Drawer>
          <HStack id="esaaySlider" mt="14" position={"relative"}>
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              slidesPerView={1}
              navigation
              height={"100%"}
            >
              <SwiperSlide>
                <Box className="Bgimage">
                  <Image
                    width={"100%"}
                    height={"510px"}
                    src={slimg1}
                    alt=""
                    cursor={"pointer"}
                    onClick={() => showSynopsisImages(slimg1, true, 0)}
                  />
                  <Text
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#035DA1"}
                    textAlign={"left"}
                    cursor={"pointer"}
                    onClick={() => {
                      setOpenDrawer((prev) => !prev);
                      setleftFlag(true);
                    }}
                  >
                    Krishna Chaitanya | 1979, BOOK.ess
                  </Text>
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box display={"block"}>
                  <Box className="Bgimage">
                    <Image
                      width={"100%"}
                      src={placeholder}
                      height={"510px"}
                      alt=""
                      cursor={"pointer"}
                      onClick={() => showSynopsisImages(slimg1, true, 1)}
                    />
                    <H2 pos={"absolute"} top="50%" left={"35%"}>
                    {common.IMAGE_AWAITED}
                    </H2>
                  </Box>
                  <HStack>
                    <Text
                      fontSize={"14px"}
                      fontWeight={"400"}
                      color={"#035DA1"}
                      textAlign={"left"}
                      cursor={"pointer"}
                      onClick={() => {
                        setOpenDrawer((prev) => !prev);
                      }}
                    >
                      Krishna Chaitanya | 1979, BOOK.ess
                    </Text>
                  </HStack>
                </Box>
              </SwiperSlide>
            </Swiper>
          </HStack>
          <Stack alignItems={"center"}>
           <WebpageComingSoon />
          </Stack>
        </Stack>
        {(readmoreNavigation.posterFlag || synopsisFullScreenImage) && (
          <FullScreenImage
            isOpen={synopsisFullScreenImage}
            onClose={() => showSynopsisImages(null, false)}
            imageData={synopsisImgData}
            clickedImageIndex={clickedImageIndex}
            setReadMoreFlag={setReadMoreFlag}
            title="poster"
            posterFlag={true}
            setSynopsisFullScreenImage={setSynopsisFullScreenImage}
          />
        )}
      </Container>
    </div>
  );
}

export default Essay;
export const FullScreenImage = ({
  isOpen,
  onClose,
  imageData,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  posterFlag,
  title,
  setSynopsisFullScreenImage,
}) => {
  const [essaydata, setEssayData] = React.useState([{}]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await essaySliderdata();
      if (result?.length > 0) {
        setEssayData([
          {
            display: result.find((item) => item.key === "title_essay").display,
            title: result.find((item) => item.key === "title_essay").value,
          },
          {
            display: result.find((item) => item.key === "author").display,
            title: result.find((item) => item.key === "author").value[0].value,
          },
          {
            display: result.find((item) => item.key === "year_of_publication")
              .display,
            title: result.find((item) => item.key === "year_of_publication")
              .value,
          },
        ]);

        setShowLabel(false);
      } else {
        setShowLabel(true);
      }
    };
    getData();
  }, []);

  return (
    <Container maxW={"auto"} px="0px">
      {showLabel ? (
        <Text py={"2"} fontSize={"12px"} fontWeight="400" textAlign="center">
          {common.CONTENT_AWAITED}
        </Text>
      ) : (
        <Modal
          size={"full"}
          isCentered
          isOpen={isOpen}
          onClick={onClose}
          Id="modalfullscreen"
        >
          <ModalOverlay />
          <ModalContent zIndex={"999"}>
            <ModalBody margin={{ base: "0", md: "16" }}>
              <Box
                bg={"white"}
                justifyContent={"space-between"}
                position={"relative"}
              >
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  loop={true}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  <SwiperSlide>
                    <Stack
                      maxW={"1180px"}
                      marginTop={{ base: "50px", md: "0" }}
                      flexDirection={{ base: "column", md: "row" }}
                      className="row"
                      alignItems={"flex-start"}
                      gap={"0"}
                    >
                      <HStack
                        className="col-sm-12 col-md-6"
                        justifyContent={"center"}
                        border={"0.01rem solid #d7d7d7"}
                        borderRadius={"5px"}
                        background={"#eeeeee"}
                      >
                        <Stack m="8">
                          <Box
                            display={"block"}
                            justifyContent={"center"}
                            width={"100%"}
                            height={{ base: "auto", md: "auto" }}
                          >
                            <Image
                              src={slimg1}
                              width={"100%"}
                              height={"inherit"}
                              objectFit={"contain"}
                              alt=""
                            />
                          </Box>
                        </Stack>
                      </HStack>
                      <HStack
                        alignItems={"flex-start"}
                        className="col-sm-12 col-md-6"
                        flexDirection={"column"}
                      >
                        <Box
                          paddingBottom={"10px"}
                          width={"100%"}
                          borderBottom={"1px"}
                          borderColor={"#e8e8e8"}
                        >
                          <Text
                            fontSize={"16px"}
                            fontWeight={"700"}
                          >
                            {common.CONTENT_AWAITED}
                          </Text>
                        </Box>
                        <Stack
                          marginInlineStart={"0px!important"}
                          alignItems={"flex-start"}
                        >
                          <SimpleGrid
                            columns={[0, null, 1]}
                            spacingY="5px"
                            py="2"
                          >
                            {essaydata.map((item) => {
                              return (
                                <>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {item?.display}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {item?.title}
                                  </Text>
                                </>
                              );
                            })}
                          </SimpleGrid>
                          <Text
                            pt="4"
                            fontSize={"14px"}
                            fontWeight={"700"}
                            color="#035DA1"
                            cursor={"pointer"}
                            onClick={() => {
                              onClose();
                              setReadMoreFlag(
                                true,
                                isOpen,
                                clickedImageIndex,
                                posterFlag,
                                title
                              );
                            }}
                          >
                            {common.READ_MORE}
                          </Text>
                        </Stack>
                      </HStack>
                    </Stack>
                  </SwiperSlide>

                  <HStack>
                    <Image
                      className="art-he-closebtn"
                      zIndex={999}
                      src={closeIcon}
                      onClick={() => {
                        onClose();
                      }}
                      cursor={"pointer"}
                    ></Image>
                  </HStack>
                </Swiper>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};
