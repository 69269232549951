import { Container, HStack, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { CopyPasteService } from "../../../../services/CopyPaste";
import ImageGridlist from "../../../EventExhibition/Component/ImageGridlist";
import { FullScreenImage } from "../../Component/FullScreenImage";

export default function Exhibits({ data }) {
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        py="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          <HStack
            onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
            marginRight={{ base: "0px", md: "30px" }}
          >
            <ImageGridlist
              data={data}
              handleImageClick={handleImageClick}
            />
          </HStack>
        </Stack>
        {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={data.slice(0,1)}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title=""
        />
      )}
      </Container>
    </Stack>
  );
}
