import { Stack } from "@chakra-ui/react";
//Core files
import React, { useEffect, useState } from "react";
import { EffectFlip, Navigation, Pagination } from "swiper";

//Service files
import * as getEssayData from "./../../../../services/EventExhibition";

//Component file
import ImageSlider from "../../Component/ImageSlider";
import { FullScreenImage } from "../../Component/FullScreenImage";

export default function Essay({ winnerName }) {
  const [essayData, setEssayData] = useState([{}]);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);


  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const createDataObject = (dataArray) => {
    const dataObject = {};
    if (dataArray.length > 0) {
      const dataItem = dataArray[0];
      for (const key in dataItem) {
        dataObject[key] = dataItem[key];
      }
    }
    return dataObject;
  };

  const citationFactor = (data) => {
    return data.map((item, index) => {
      const author = createDataObject(item.author);
      const titleOfEssay = createDataObject(item.title_of_essay);
      const dateofArt = createDataObject(item.date_of_artwork);
      return {
        desc:
          `${author?.value}.` +
          `${titleOfEssay?.value},` +
          ` ${dateofArt?.value}`,
        designerdesc: `${item.artist_name} | ${item.medium_of_artwork}, ${dateofArt.value}_${item.revised_medium} | ${item.wnn}`,
        imgPath: item.value,
        dateofArt,
        author,
        titleOfEssay,
        content: item.content !== "" ? item.content : "Content Awaited",
        title: `${author?.last_name}, ${author?.first_name}(${author?.profession}),${item?.exhibition_detail}, ${dateofArt} | ${item?.revised_medium} | ${item?.research_category}`,
        fullImg: item?.painters,
        essayistData: [titleOfEssay, author, dateofArt],
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getEssayData.essayData(
        "event-exhibition",
        winnerName
      );
      result.map(
        (item) =>
          item.key === "essayImages" &&
          setEssayData(citationFactor(item.value))
      );
    };

    getData();
    // eslint-disable-next-line
  }, []);
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack position={"relative"} id="allslideressay" pt={8}>
    <ImageSlider
        sliderData={essayData}
        handleImageClick={handleImageClick}
        modules={[EffectFlip, Navigation, Pagination]}
        width={{ base: "100%", md: "auto" }}
        height={"100%"}
        sheight={"511px"}
      />
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={essayData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="banner"
        />
      )}
    </Stack>
  );
}
