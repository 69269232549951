import React, { useState, useEffect } from "react";
import { ArrowUpIcon } from "@chakra-ui/icons";

import "../../styles.css";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="top-to-btm">
        {showTopBtn && (
          <ArrowUpIcon className="icon-position icon-style" onClick={goToTop} right={
            windowWidth >= 1400 && windowWidth <= 1600
              ? "5%"
              : windowWidth >= 1800
              ? "14%"
              : "9%"}/>
        )}
    </div>
  );
};
export default ScrollToTop;
