import {
  Box,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { explore } from "../../../constants/constants";
import Essay from "./Scholarship/Essay";
import Publications from "./Scholarship/Publications";

export default function Scholarship({ masterlist }) {
  return (
    <>
      <Tabs align={{ xs: "", sm: "" }} w="full">
        <Stack display={"flex"}>
          <Box
            position={"fixed"}
            bg="white"
            width={{ base: "100%", md: "1192px" }}
            paddingTop={"34px"}
            paddingBottom={"18px"}
            px="40px"
          >
            <TabList
              overflowX="auto"
              borderBottom={"1px"}
              borderBottomColor={"#f1f1f1"}
              gap={12}
              className="verticalscroll"
            >
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.ESSAYS}
              </Tab>

              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.LETTERS_SCRIBBLES_POETRY}
              </Tab>
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.PUBLICATIONS}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="80px" px="0px">
                <Essay masterlist={masterlist}/>
              </TabPanel>
              <TabPanel pt="80px" px="0px">
              </TabPanel>
              <TabPanel pt="0px" px="0px" >
              <Publications masterlist={masterlist}/>
              </TabPanel>
            </TabPanels>
          </Box>
        </Stack>
      </Tabs>
    </>
  );
}
