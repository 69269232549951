import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import { useState } from "react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function FullCarousalImage({ setShowCarousal, showCarousal, data }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(data[0]);
  console.log(selectedImage, "selectedImage");
  return (
    <Container maxW={{ base: "100%", md: "1400px" }} px="0px">
      <Modal
        size={"full"}
        isCentered
        isOpen={showCarousal}
        Id="modalfullscreen"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"}>
          <ModalBody m={{ base: 4, md: 16 }}>
            <Box
              bg={"white"}
              justifyContent={"space-between"}
              position={"relative"}
            >
              <HStack
                flexDirection={"row"}
                justifyContent={"center"}
              >
                <Stack
                  alignItems={"center"}
                  height={"100%"}
                  width={{ base: "100%", md: "1335px" }}
                  id="artHeriatgeEdition"
                >
                  <Image
                    src={data[selectedIndex]}
                    height={{ base: "auto", md: "471px" }}
                  ></Image>
                  <Text
                    alignItems={"left"}
                    color={"#035DA1"}
                    marginRight={{ base: "0px", md: "300px !important" }}
                  >
                    {data[selectedIndex].desc}
                  </Text>
                  <HStack>
                    <FaChevronLeft
                      onFocus={"true"}
                      onClick={() => {
                        selectedIndex >= 1 &&
                          setSelectedIndex((prev) => prev - 1);
                        selectedIndex >= 1 &&
                          setSelectedImage(data[selectedIndex]);
                      }}
                      cursor={"pointer"}
                      style={{
                        position: "absolute",
                        bottom: { base: "20%", md: "10%" },
                        left: { base: "0%", md: "20%" },
                        zIndex: "9999",
                      }}
                    />
                    <HStack cursor={"pointer"}>
                      {data?.map((item, index) => {
                        return (
                          <Box
                            justifyItems="center"
                            display="flex"
                            height={"151px"}
                            bg="white"
                            justifyContent="space-around"
                          >
                            <Image
                              width={"175px"}
                              objectFit={"contain"}
                              objectPosition={"center"}
                              src={item}
                              alt=""
                              onClick={() => setSelectedIndex(index)}
                            />
                          </Box>
                        );
                      })}
                    </HStack>
                    <FaChevronRight
                      onClick={() => {
                        selectedIndex < data.length - 1 &&
                          setSelectedIndex((prev) => prev + 1);
                        selectedIndex < data.length - 1 &&
                          setSelectedImage(data[selectedIndex]);
                      }}
                      cursor={"pointer"}
                      position="absolute"
                      bottom={{ base: "20%", md: "10%" }}
                      sss
                      right={{ base: "0%", md: "20%" }}
                    />
                  </HStack>
                </Stack>
                <Stack>
                  <Text
                    fontSize={"xl"}
                    bg={"black"}
                    opacity={"0.6"}
                    color={"white"}
                    fontWeight={"400"}
                    padding="2"
                    cursor={"pointer"}
                    borderRadius={"35px"}
                    width="max-content"
                    position={"absolute"}
                    bottom={{ base: "140px", md: "50px" }}
                    right={{ base: "15px", md: "130px" }}
                    transform={""}
                    onClick={() => setShowCarousal(false)}
                  >
                    <CgArrowsExpandRight />
                  </Text>
                </Stack>
              </HStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default FullCarousalImage;
