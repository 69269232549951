import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { explore } from "../constants/constants";
import { useTranslation } from "react-i18next";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`

export const FilterButton = ({
  onClick,
  marginRight,
  paddingTop
}) => {
  const { t } = useTranslation("");

  return (
    <>
      <Stack
          position={"relative"}
          pos={"fixed"}
          marginTop={"100px"}
          marginRight={marginRight}
          paddingTop={paddingTop}
          zIndex={"99!important"}
          display={{base:"none",md:"flex"}}
        >
          <Stack
            id="filterstickywbtn"
            marginRight={"0px!important"}
            onClick={onClick}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap="10px"
            >
              <Image transform={"rotate(360deg)"} src={Filter} />
              <Text
                as="button"
                transform={"rotate(90deg)"}
                fontSize={"14px"}
                fontWeight={"700"}
                marginTop={"4px"}
              >
                {t(explore.FILTER)}
              </Text>
            </Box>
          </Stack>
        </Stack>
    </>
  );
};
