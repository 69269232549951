import React from "react";
import ReactEcharts from "echarts-for-react";

export default function HighsBar() {
  const data = [
    300, 1500, 800, 1200, 900, 1000, 300, 15000, 800, 1200, 900, 2000, 300,
    1500, 800, 1200, 900, 2000, 300, 1500, 800, 1200, 900, 20000, 300, 1500,
    8000, 1200, 900, 2000, 300, 1500, 800, 1200, 900, 2000, 300, 1500, 800,
    30000,
  ];

  // Calculate record-breaking prices and corresponding years
  const recordBreakingPrices = [];
  const years = [];
  let maxPrice = 0;
  let previousMaxPrice = 0; // Track the previous maximum price
  for (let i = 0; i < data.length; i++) {
    if (data[i] > maxPrice) {
      maxPrice = data[i];
      years.push(1950 + i); // Assuming the data starts from 1990
    } else if (data[i] === previousMaxPrice) {
      years.push(""); // Mark the repeated record-breaking value as a year
    } else {
      years.push(""); // Show empty string for non-record-breaking years
    }
    recordBreakingPrices.push(maxPrice);
    previousMaxPrice = maxPrice; // Update the previous maximum price
  }
  // Create option for ECharts
  const option = {
    title: {
      text: " Timeline of Sale Price Highs (1950-1989)",
      left: "left",
    },
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        return (
          params[0].name +
          "<br/>" +
          params[0].seriesName +
          ": " +
          params[0].value
        );
      },
    },
    xAxis: {
      data: years.map((item, index) => {
        if (recordBreakingPrices[index] === data[index]) {
          return item.toString();
        } else {
          return "";
        }
      }),
      name: "Year/Date",
      nameLocation: "middle",
      nameRotate: 0,
      nameGap: 40,
      nameTextStyle: {
        fontSize: 16,
      },
      axisLabel: {
        rotate: 90,
        fontSize: 12,
      },
    },
    yAxis: {
      type: "value",
      name: "Sale Price (INR)",
      nameTextStyle: {
        fontSize: 16,
      },
      nameLocation: "middle",
      nameRotate: 90,
      nameGap: 80,
    },
    series: [
      {
        name: "Record Breaking Price",
        type: "bar",
        data: data,
        itemStyle: {
          color: function (params) {
            // Get the index of the current data point
            const dataIndex = params.dataIndex;
            // Compare the current data point with the previous record-breaking prices

            if (data[dataIndex] === recordBreakingPrices[dataIndex]) {
              for (let i = 0; i < dataIndex; i++) {
                if (data[dataIndex] === data[i]) {
                  return "blue";
                }
              }
              return "red"; // Red color for record-breaking price
            } else {
              return "blue"; // Blue color for non-record-breaking prices
            }
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactEcharts
        option={option}
        style={{ height: "570px", width: "1060px" }}
      />
    </div>
  );
}
