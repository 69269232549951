import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import { CopyPasteService } from "../../../../services/CopyPaste";
import * as getBibliographyData from "../../../../services/Bibliography";

// Subsection Files
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import Small from "../../../../components/layouts/Typography/Small";
import { common, explore, filterAttribute } from "../../../../constants/constants";
import ScrollToTop from "../../../../components/sections/ScrollToTop";
import ContentModal from "./ContentModal";
import SliderModal from "./SliderModal";
import ResearchImageGridList from "../../../Cine_MasterList/ResearchCentre/CommonComponents/ResearchImageGridList";
import ResearchCentreDrawer from "./Components/ResearchCentreDrawer";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import { isTransformationNotAllowed } from "../../../../util/isTranformationAllowed";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/7.png`

function GridView({
  handleBookClick,
  contentClicked,
  scanClicked,
  setleftFlag,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
  imageTitle
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const sliderModalImg = [Img5, Img6, Img7];
  const [swiper, setSwiper] = useState(null);
  const [bibliographyObject, setBibliographyObject] = React.useState();
  const [indexValue, setIndexValue] = useState();
  const [apply, setApply] = useState(false);
  const [filterCount, setfilterCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteroptionData, setFilteroptionsData] = useState({});
  const navigate=useNavigate();
  const {bookAlias}=useParams();
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };

  const handleCloseClick = () => {
    setContentClicked(false);
    setScanClicked(false);
  };

  const handleDrawer = (item) => {
    setBibliographyObject(item);
    setleftFlag(true);
    setOpenDrawer(true);
    setDrawerBook(item)
    navigate(`${item?.alias}`)
  };

  const handleClose = () => {
    setBibliographyObject();
    handleCloseClick();
    setDrawerBook('')
    navigate(-1);

  };

  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    showSliderImage(2);
    if (text === "Contents Page →") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages →") {
      setScanClicked(true);
      setContentClicked(false);
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    documentType: [],
    year: [],
    creatorType: [],
    publisher: [],
    searches_v: [],
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
  
    let selectedArray = [];
    switch (value) {
      case "docType":
        selectedArray = filterOptions.documentType;
        break;
      case "year":
          selectedArray = filterOptions.year;
          break;
      case "creatorType":
          selectedArray = filterOptions.creatorType;
          break;
      case "publisher":
        selectedArray = filterOptions.publisher;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;    
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "docType":
        setFilterOptions({
          ...filterOptions,
          documentType: selectedArray,
        });
        break;
        case "year":
          setFilterOptions({
            ...filterOptions,
            year: selectedArray,
          });
        break;
        case "creatorType":
          setFilterOptions({
            ...filterOptions,
            creatorType: selectedArray,
          });
        break;
      case "publisher":
        setFilterOptions({
          ...filterOptions,
          publisher: selectedArray,
        });
      break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
          });
        break;        
      default:
        break;
    }
  };

  React.useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getBibliographyData.getBibliographyFilterOptions(
        tableName,
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, tableName]);

  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getBibliographyData.bibliographyByMasterlist(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getBibliographyData.bibliographyByMasterlist(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }

    const data = result || []; // Ensure data is not null or undefined
    // setScreeningsData(citationFactor(data));
    setFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  const [FilterData, setFilterData] = useState([{}]);
  // const { mockData, filterMockData } = getBibliographyData.bibliographyByMasterlist( tableName, masterlist ) || {};

  // const { loading: loadingStatus, error: errorStatus, message: messageStatus } = sentEmailStatus || {};

  const citationFactor = (data) => {
    return data.map((item) => {
      const imagePath =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value[0] || null;

      const creator =
        item?.find((nestedItem) => nestedItem?.key === "creator")?.value || [];
      const lastName = creator?.map((nestedItem) => nestedItem?.last_name) || "";
      console.log(lastName,"lastName")
      const firstName =
        creator?.map((nestedItem) => nestedItem?.first_name) || "";
      const author = lastName && firstName ? `${lastName}, ${firstName}` : "";
      const bookKey =
        item?.find((nestedItem) => nestedItem?.key === "book_title_of_document")
          ?.value || null;
      const book = bookKey;
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        null;
      const publicationYear =
        item?.find(
          (nestedItem) => nestedItem?.key === "sortable_year_date_of_publication"
        )?.value || null;

      const documentType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")?.value ||
        null;
        const typeCreator =
        item?.find((nestedItem) => nestedItem?.key === "title_and_type_of_creator")?.value ||
        null;
      const header =  " & " + bookKey;
      const fullname = lastName && firstName ? `${firstName} ${lastName}` : "";
      const sliderImage =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value.length >
          1 &&
        item?.find((nestedItem) => nestedItem?.key === "image")?.value.slice(1);
      const desc = `${lastName}. ${bookKey}, ${publicationYear}`;
        const alias=item?.find((nestedItem) => nestedItem?.key === "alias")?.value
      return {
        imagePath,
        desc,
        header,
        bookKey,
        link,
        typeCreator,
        creator,
        book,
        author,
        imageTitle,
        documentType,
        sliderImage,
        fullname,
        alias,
        footer: [
          {
            text: "Contents Page →",
          },
          {
            text: "Index Page →",
          },
          {
            text: "Scanned Pages →",
          },
        ],
      };
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getBibliographyData.bibliographyByMasterlist(
            tableName,
            masterlist
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getBibliographyData.bibliographyByMasterlist(
              tableName,
              masterlist
            )) || {};
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  //drawer dynamic

  const [drawerBook,setDrawerBook]=useState("");
  useEffect(()=>{
    if(FilterData){
      const item=FilterData.find((it)=>it.alias===bookAlias)
      setDrawerBook(item)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  },[FilterData,bookAlias])

  return (
    <>
      <Stack flexDirection={"row-reverse"} minH={"100vh"}>
        {bookAlias && drawerBook && <ResearchCentreDrawer
          data={drawerBook && drawerBook}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleClose={handleClose}
          handleBookClick={handleBookClick}
          handleContentClicked={handleContentClicked}
          scanClicked={scanClicked}
          contentClicked={contentClicked}
          bibliographyObject={bibliographyObject}
          setSwiper={setSwiper}
          indexValue={indexValue}
          imageTitle={imageTitle}
        />}
        {!showFilterBox && <FilterButton onClick={handleFilterClick} />}
        <Container maxW="auto" className="songs" px={{base:"4",md:"0px"}} py="8">
          <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {FilterData.length > 0 ? (

            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{base:"0px",md:"30px"}}
            >
                <ResearchImageGridList
                  data={FilterData}
                  handleDrawer={handleDrawer}
                  border={true}
                />
             
            </HStack>
            ) : (
              <>
              <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              margin={"auto"}
            >
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {common.NO_DATA}
                </Text>
                </HStack>
              </>
            )}
            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterSKK">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold
                        as="u"
                        color={"black"}
                        // onClick={() => handleClearAll()}
                        cursor="pointer"
                        onClick={() => {
                          setFilterOptions({
                            documentType: [],
                            year: [],
                            creatorType: [],
                            publisher: [],
                          });
                          setSearchTerm("");
                        }}
                      >
                        {explore.RESET}
                        <span style={{ color: "#004F79" }}>
                          {" "}
                          {filterCount > 0 && ` (${filterCount})`}
                        </span>
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    {/* <SmallBold py="4">Search</SmallBold> */}
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        // value={searchQuery}
                        // onChange={handleSearch}
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                        onChange={handleSearch}
                        value={searchTerm}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle allowMultiple>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.creatorTypeFilter.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.creatorType.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("creatorType", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </Small>
                      </AccordionPanel>
                      {/* Need to uncomment when author data added */}
                      {/* <AuthorAccordionPanel
                      data={data}
                      setAuthorName={setAuthorName}
                      authorName={authorName}
                    /> */}
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.DATE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.yearFilter.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.year.includes(item)}
                                  onChange={() => {
                                    handleCheckboxChange("year", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.DOCUMENT_TYPE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <Box maxH="130px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {filteroptionData.documentTypeFilter.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions.documentType.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("docType", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>
                                    {`Book.${item}`}
                                  </Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </AccordionPanel>
                      </Box>
                      {/* <DocumentTypeAccordionPanel
                      data={data}
                      documentType={documentType}
                      setDocumentType={setDocumentType}
                    /> */}
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.PUBLISHER}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <Box maxH="130px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {filteroptionData?.publisher.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                  isChecked={filterOptions?.publisher.includes(
                                    item
                                  )}
                                  onChange={() => {
                                    handleCheckboxChange("publisher", item);
                                  }}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{`${item}`}</Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </AccordionPanel>
                      </Box>
                      {/* <DocumentTypeAccordionPanel
                      data={data}
                      documentType={documentType}
                      setDocumentType={setDocumentType}
                    /> */}
                    </AccordionItem>
                  </Accordion>
                  <HStack marginTop={"10px"}>
                    <Button
                      // as="u"
                      color={"#ffffff"}
                      onClick={() => {
                        setApply((prev) => !prev);
                        setShowFilterBox(false);
                      }}
                      width={"199px"}
                      height={"36px"}
                      bg={"#000000"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      variant={"unstyled"}
                    >
                      {explore.APPLY}
                    </Button>
                  </HStack>
                </Box>
              )}
            </HStack>
            {/* {
          contentClicked ? <DemoModal contentClicked={contentClicked} setContentClicked={setContentClicked}/>:
          scanClicked && <SliderModal scanClicked={scanClicked} setScanClicked={setScanClicked}/>
        } */}
          </Stack>
          {contentClicked && (
            <ContentModal
              contentClicked={contentClicked}
              setContentClicked={setContentClicked}
            />
          )}
          {scanClicked && (
            <SliderModal
              scanClicked={scanClicked}
              setScanClicked={setScanClicked}
              sliderModalImg={sliderModalImg}
            />
          )}
          <ScrollToTop />
        </Container>
      </Stack>
    </>
  );
}
export default GridView;
