export const filmCrewSchema = [
  { key: "artist_name", display: "Film Producer", seq: 1 },
  { key: "character_name", display: "Film Director", seq: 2 },
  {
    key: "characterImage",
    display: "Image",
    processor: "imageAliasArray",
    seq: 3,
  },
];
