// Core Files
import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

// Subsection Files
import Large from "../../../components/layouts/Typography/Large";
import Small from "../../../components/layouts/Typography/Small";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholder = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

export default function ImageGridList({
  data,
  text,
  columns,
  spacing,
  handleImageClick,
  handleImage,
  width,
  height,
  transition,
  className,
  archive,
  showArchiveImages,
  ...rest
}) {
  return (
    <>
      <SimpleGrid columns={columns} spacing={spacing} justifyItems={"center"}>
        {data?.map((item, index) => (
          <Box
            height="100%"
            justifyItems="center"
            justifyContent="space-around"
            width={width && width}
          >
            <Box height={height ? height : "100%"} width={width && width} className={className && className}>
              <Image
                src={item?.image}
                fallbackSrc={placeholder}
                width={"100%"}
                height={height && "100%"}
                objectFit={"contain"}
                cursor={"pointer"}
                transition={transition && transition}
                onClick={(e) =>
                    showArchiveImages(item?.image, true, index)
                  }
              />
            </Box>
            {text && (
              <Box py="2">
                <Large
                  color="#035DA1"
                  cursor="pointer"
                  onClick={(e) =>
                    showArchiveImages(item?.image, true, index)
                  }
                >
                  {item?.title}
                </Large>
                {item?.subTitle && (
                  <Small
                    py="1"
                    marginInlineStart={"0.0rem !important"}
                  >{`${item?.subTitle}`}</Small>
                )}
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
}
