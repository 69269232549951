import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import MobileNav from "../components/Topheader";
import GMap from "./GMap";
import { MdHomeFilled } from "react-icons/md";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import { useNavigate } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function Map() {
  // const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();export const getHomeData = async () => {
  // const [dummyData, setDummyData]= useState (false)
  // const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  // useEffect (()=>{
  //   if (dummyDataEnabled.toLowerCase() === "yes") {
  //     setDummyData(true)
  //   } else {
  //     setDummyData(false)
  //   }
  // },[dummyDataEnabled])

  return (
    <SidebarWithHeader>
      <Container maxW={"1400px"} position={"relative"} px="0px">
        <GMap />
      </Container>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [setPage] = useState("banner");

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}

      <MobileNav onOpen={onOpen} setPage={setPage} />
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose,display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
      active: true,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu handleNavigation={handleNavigation} backArrow={true} display={display} onClose={onClose} toggleModal={toggleModal} member={member} circle={circle} LeftVmenuData={LeftVmenuData}/>

  );
};
