import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Container,
  Stack,
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  InputRightElement,
  InputGroup,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  UnorderedList,
  ListItem,
  CloseButton,
  AlertDescription,
} from "@chakra-ui/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import ReCAPTCHA from "react-google-recaptcha";
import Layout from "../components/sections/SidebarLayout";
import H2 from "../components/layouts/Typography/H2";
import { signup } from "../services/User";
import { userRegistrationForm } from "../constants/constants";

const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITEKEY || "";

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showCpassword, setShowCpassword] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState([]);
  const [successMsgFlag, setSuccessMsgFlag] = useState(false);
  const [errorMsgFlag, setErrorMsgFlag] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const recaptchaRef = useRef(null);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowCPassword = () => {
    setShowCpassword(!showCpassword);
  };

  const validateForm = (data) => {
    const errors = [];

    if (!data?.name || data?.name?.length < 4) {
      errors.push("Name is required and should be at least 4 characters long");
    }

    if (!data?.birthdate) {
      errors.push("Date of Birth is required");
    }

    if (!data?.age || Date.age === 0) {
      errors.push("Age is required");
    }

    if (
      !data?.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data?.email)
    ) {
      errors.push("Invalid email address");
    }

    if (!data?.password || data?.password?.length < 8) {
      errors.push(
        "Password is required and should be at least 8 characters long"
      );
    }

    if (data?.password !== data?.confirm_password) {
      errors.push("Passwords do not match");
    }

    // Check if CAPTCHA value is empty
    if (!captchaValue) {
      errors.push("CAPTCHA value is required.");
      // return;
    }
    return errors;
  };

  const onSubmit = async (data) => {
    setErrorMsg([]);
    const validationErrors = validateForm(data);
    if (validationErrors.length > 0) {
      setErrorMsg(validationErrors);
      setErrorMsgFlag(true);
      return;
    }

    signup(data)
      .then((response) => {
        if (response?.success) {
          setMessage(response?.message);
          setSuccessMsgFlag(true);
          reset();
          recaptchaRef.current.reset();
        } else {
          setErrorMsg(
            response?.errors || ["Registration failed. Please try again."]
          );
          setErrorMsgFlag(true);
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        setErrorMsg(["Registration failed. Please try again."]);
      });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <Layout>
      <Stack className="mobileviewpadding">
        <Container mt="16">
          <Box borderWidth="1px" borderRadius="lg" bg="#f7f7f7" p="4" maxW="md">
            <H2 py="4" textAlign="center">
              {userRegistrationForm.REGISTRATION_FORM_TITLE}
            </H2>

            {errorMsgFlag && errorMsg && (
              <Alert status="error" justifyContent={"space-between"}>
                <Box>
                  <UnorderedList>
                    {errorMsg.map((errorMessage, index) => (
                      <ListItem key={index}>{errorMessage}</ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={() => setErrorMsgFlag(false)}
                />
              </Alert>
            )}
            {successMsgFlag && message && (
              <Alert status="success">
                <AlertIcon />
                <Box>
                  <AlertTitle>Congratulation!</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={() => setSuccessMsgFlag(false)}
                />
              </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Name Field */}
              <FormControl isInvalid={errors?.name} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {userRegistrationForm.REGISTRATION_FORM_FIELD_NAME_LABEL}
                </FormLabel>
                <Input id="name" bg="white" name="name" {...register("name")} />
                <FormErrorMessage>
                  {errors?.name && errors?.name?.message}
                </FormErrorMessage>
              </FormControl>
              {/* Birth date Field */}
              <FormControl isInvalid={errors?.birthdate} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {userRegistrationForm.REGISTRATION_FORM_BIRTHDATE_LABEL}
                </FormLabel>
                <Input
                  type="date"
                  id="birthdate"
                  bg="white"
                  name="birthdate"
                  {...register("birthdate")}
                />
                <FormErrorMessage>
                  {errors?.birthdate && errors?.birthdate?.message}
                </FormErrorMessage>
              </FormControl>

              {/* Age Field */}
              <FormControl isInvalid={errors?.age} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {userRegistrationForm.REGISTRATION_FORM_FIELD_AGE_LABEL}
                </FormLabel>
                <Input id="age" bg="white" name="age" {...register("age")} />
                <FormErrorMessage>
                  {errors.age && errors?.age?.message}
                </FormErrorMessage>
              </FormControl>

              {/* Email Field */}
              <FormControl isInvalid={errors?.email} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {
                    userRegistrationForm.REGISTRATION_FORM_FIELD_EMAIL_ADDRESS_LABEL
                  }
                </FormLabel>
                <Input
                  id="email"
                  bg="white"
                  name="email"
                  {...register("email")}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
              {/* Password Field */}
              <FormControl isInvalid={errors?.password} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {userRegistrationForm.REGISTRATION_FORM_FIELD_PASSWORD_LABEL}
                </FormLabel>
                <InputGroup>
                  <Input
                    id="passowrd"
                    bg="white"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <InputRightElement>
                    <Text onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FiEye size={"18px"} />
                      ) : (
                        <FiEyeOff size={"18px"} />
                      )}
                    </Text>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors?.password && <p>{errors?.password?.message}</p>}
                </FormErrorMessage>
              </FormControl>
              {/*Confirm Password Field used here */}
              <FormControl isInvalid={errors?.confirm_password} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {
                    userRegistrationForm.REGISTRATION_FORM_FIELD_CONFIRM_PASSWORD_LABEL
                  }
                </FormLabel>
                <InputGroup>
                  <Input
                    id="confirm_password"
                    bg="white"
                    name="confirm_password"
                    type={showCpassword ? "text" : "password"}
                    {...register("confirm_password")}
                  />
                  <InputRightElement>
                    <Text onClick={toggleShowCPassword}>
                      {showCpassword ? (
                        <FiEye size={"18px"} />
                      ) : (
                        <FiEyeOff size={"18px"} />
                      )}
                    </Text>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors?.confirm_password && (
                    <p>{errors?.confirm_password?.message}</p>
                  )}
                </FormErrorMessage>
              </FormControl>
              {/* Captcha Field */}
              <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={handleCaptchaChange}
              />
              <Button mt={4} colorScheme="blue" type="submit">
                {userRegistrationForm.REGISTRATION_FORM_BUTTON_SUBMIT_LABEL}
              </Button>
            </form>
            <Box py="4">
              {userRegistrationForm.REGISTRATION_FORM_ALREADY_HAVE_ACCOUNT}
              <Link href="/login">
                {userRegistrationForm.REGISTRATION_FORM_BUTTON_LOGIN_LABEL}
              </Link>
            </Box>
          </Box>
        </Container>
      </Stack>
    </Layout>
  );
}

export default Register;
