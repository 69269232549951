import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Flex,
} from "@chakra-ui/react";

// Core files
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";

// Subsection Files
import MobileNav from "../../components/Topheader";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import { common, explore, filmography } from "../../constants/constants";
import { isArray } from "lodash";

import Synopsis from "./BodyOfWork/Synopsis";
import { LyricsSoundtrack } from "./BodyOfWork/Soundtrack";
import Editorial from "./BodyOfWork/Editorial";
import HorizontalLayout from "./BodyOfWork/CrewAndCast/HorizontalLayout";
import VerticalLayout from "./BodyOfWork/CrewAndCast/VerticalLayout";
import ReadMore from "./ReadMore";
import WatchFullFilm from "./BodyOfWork/WatchFullFilm";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

const artistLinksData = [
  {
    name: "Satyajit Ray",

  },
  {
    name: "Suresh Jindal",
  },
];
export default function MainBodyOfWok({ children }) {
  let { alias } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const [castTabFlag, setCastTabFlag] = useState(false);
  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState("");
  const [filmDetails, setFilmDetails] = useState({});
  const [watchFilm, setWatchFilm] = useState("");
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        winnerName
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item.key === "masterlist").value}/${
              it.find((item) => item.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    // Get film intro data-need to refactor due to redundant code in every section.
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias("films", winnerName);
        if (result) {
          setName(
            () =>
              result?.find((item) => item?.key === "filmTitleImage")?.value
                .length > 0 &&
              result?.find((item) => item?.key === "filmTitleImage")?.value[0]
                ?.direct_url_of_preview_image
          );
          setAgraphyLink([
            {
              link: `/research-categories/${
                result?.find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`, // replace with the actual link
              title: result?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.abbreviation,
            },
            {
              link: `/categories/indian-cinema`,
              title: result?.find((item) => item?.key === "subject")?.value,
            },
          ]);
          setfilmMainImage(
            {
               logoImage:result?.find((item) => item?.key === "filmMainImage")?.value[0]
                 ?.direct_url_of_preview_image,
                 logoLink:`/${masterlistType}/${alias}/introduction/title-image`
               }
           );
           const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
          setFilmTrailer({
            link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
            display: result?.find((item) => item?.key === "trailer_link")
              ?.display,
            image: WatchTrailer,
          });
          setWatchFilm(
            result?.find((item) => item?.key === "full_movie_link")?.value
          );
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
  }, [masterlistType, winnerName, alias]);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        winnerName={winnerName}
        castTabFlag={castTabFlag}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
        artistLinksData={artistLinksData}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            winnerName={winnerName}
            castTabFlag={castTabFlag}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            artistLinksData={artistLinksData}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <BodyOfWork
          winnerName={winnerName}
          setCastTabFlag={setCastTabFlag}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
          name={name}
          watchFilm={watchFilm}
          masterlistType={masterlistType}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  filmographyData,
  winnerName,
  onClose,
  castTabFlag,
  readmoreNavigation,
  setReadmoreNavigation,
  name,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  artistLinksData,

  ...rest
}) => {
  let { drawerId } = useParams();
  const navigate = useNavigate();
  const [nav, setNav] = useState();

  useEffect(() => {
    if (drawerId) {
      setNav(drawerId);
    }
  }, [drawerId, navigate]);
  const handleNavigation = () => {
    if (readmoreNavigation?.isReadMore) {
      setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
      return;
    } else if (nav) {
      navigate(`/satyajit-ray/body-of-work/0/${nav}`);
    } else {
      navigate(-1);
    }
  };
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];

  const oSections = [
    {
     to:`/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: explore.POSTS,
    },
    {
     to:`/${masterlistType}/${winnerName}/insights`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];

  const trailerLink = {
    link:`/${masterlistType}/${winnerName}/watch-film-trailer`,
    image: WatchTrailer,
  };
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        castTabFlag={castTabFlag}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        artistLinksData={artistLinksData}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        leftFlag={true}
        drawerData={drawerData}
        drawerImage={SKK1}        
      />
    </>
  );
};

const BodyOfWork = ({
  winnerName,
  setCastTabFlag,
  setReadmoreNavigation,
  readmoreNavigation,
  name,
  watchFilm,
  masterlistType
}) => {
  const [creditsView, setCreditsView] = useState(false);
  const [fullCreditsView, setFullCreditsView] = useState(false);

  const masterlist = winnerName;
  const tableName = "films";

  const navigate = useNavigate();
  let { tabIndex } = useParams();
  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${masterlist}/body-of-work`, { replace: true });
    }
  }, [tabIndex, navigate,masterlistType,masterlist]);

  const handleCreditsView = (flag) => {
    setCreditsView(flag);
    setCastTabFlag(flag);
  };

  const handleReadMoreNavigation = (flag, isOpen, index, title) => {
    setReadmoreNavigation({
      isReadMore: flag,
      isOpen: isOpen,
      clickedImageIndex: index,
      title: title,
    });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack>
      {readmoreNavigation?.isReadMore ? (
        <ReadMore />
      ) : (
        <Container
          minW={"100%"}
          pb="12"
          px="0px"
          // marginLeft={"30px"}
          position={"relative"}
          bottom={"0"}
          height="auto"
          bg={"white"}
        >
          <Stack>
            <Box
              position={"fixed"}
              zIndex={999}
              bg="white"
              width={{ base: "100%", md: "1192px" }}
              height={
                windowWidth >= 1400 && windowWidth <= 1700
                  ? "17.8vh"
                  : windowWidth >= 1800
                  ? "14.8vh"
                  : "16.8vh"
              }
            >
              <HStack
                paddingY={{ base: "20px", md: "30px" }}
                paddingX={{ base: "4", md: "30px" }}
              >
                <Image
                  src={name}
                  width={{ base: "100%", md: "656px" }}
                  height={"auto"}
                />
              </HStack>
            </Box>
            <HStack>
              <Tabs
                align={{ xs: "end", sm: "" }}
                width={{ base: "100%", md: "1192px" }}
                paddingX={{ base: "20px", md: "30px" }}
                overflowX={"auto"}
                defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
                className="verticalscroll"
              >
                <Box
                  position={"fixed"}
                  marginTop={{ base: "65px", md: "110px" }}
                  zIndex={999}
                  bg="white"
                  width={{ base: "100%", md: "1136px" }}
                >
                  <TabList
                    overflowX="auto"
                    borderBottom={"1px"}
                    borderBottomColor={"#f1f1f1"}
                    gap={{ base: 8, md: 12 }}
                    paddingRight={{ base: "50px", md: "0px" }}
                    className="verticalscroll"
                  >
                    <Tab
                      className="no-wrap"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}
                    >
                      {explore.EDITORIAL}
                    </Tab>
                    <Tab
                      className="no-wrap"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}
                    >
                      {explore.SYNOPSIS}
                    </Tab>
                    <Tab
                      className="no-wrap"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(true)}
                    >
                      {explore.CREDITS_CAST}
                    </Tab>
                    <Tab
                      className="no-wrap"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}
                    >
                      {explore.LYRICS_AND_SOUNDTRACK}
                    </Tab>
                    <Tab
                      className="no-wrap"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}
                    >
                      <HStack
                        marginTop={"0px!important"}
                        border={"1px"}
                        borderColor={"#C1C1C1"}
                        px={2}
                        flexDirection={"row"}
                        display={"flex"}
                      >
                        <Flex
                          alignItems="center"
                          width={{ base: "130px", md: "100%" }}
                        >
                          <Text
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"16px"}
                            py={2}
                            marginRight="8px"
                            color={"black"}
                          >
                            {filmography.WATCH_FULL_FILM}
                          </Text>

                          <Image src={WatchTrailer} />
                        </Flex>
                      </HStack>
                    </Tab>
                  </TabList>
                </Box>
                {creditsView && (
                  <Stack flexDirection={"row"} gap={0} id="listgridviewbtn1">
                    <HStack
                      marginTop={"0px!important"}
                      border={"1px"}
                      borderColor={"#C1C1C1"}
                      borderRadius={"6px"}
                      px={2}
                      flexDirection={"row"}
                      display={"flex"}
                    >
                      <Text
                        fontSize={"14px"}
                        fontWeight={!fullCreditsView ? "700" : "400"}
                        lineHeight={"16px"}
                        py={2}
                        color={"black"}
                        onClick={() => {
                          setFullCreditsView(false);
                          setCastTabFlag(true);
                        }}
                        cursor={"pointer"}
                      >
                        {filmography.CAST_VIEW}
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"400"}
                        lineHeight={"16px"}
                        py={2}
                        marginRight="8px"
                        color={"black"}
                      >
                        |
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={fullCreditsView ? "700" : "400"}
                        lineHeight={"16px"}
                        py={2}
                        marginRight="8px"
                        color={"black"}
                        onClick={() => {
                          setFullCreditsView(true);
                          setCastTabFlag(true);
                        }}
                        cursor={"pointer"}
                      >
                        {filmography.FULL_CREDITS_VIEW}
                      </Text>
                    </HStack>
                  </Stack>
                )}
                <TabPanels marginTop={{ base: "90px", md: "130px" }}>
                  <TabPanel px="0px">
                    <Editorial
                      // setReadMoreFlag={setReadMoreFlag}
                      setReadMoreFlag={handleReadMoreNavigation}
                      readmoreNavigation={readmoreNavigation}
                      masterlist={masterlist}
                    />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Synopsis masterlist={masterlist} tableName={tableName} />
                  </TabPanel>
                  <TabPanel px="0px">
                    {fullCreditsView ? (
                      <VerticalLayout
                        winnerName={winnerName}
                        setCastTabFlag={setCastTabFlag}
                        tableName={tableName}
                        masterlistType={masterlistType}
                        bg="white"
                      />
                    ) : (
                      <HorizontalLayout
                        winnerName={winnerName}
                        tableName={tableName}
                        masterlistType={masterlistType}
                      />
                    )}
                  </TabPanel>
                  <TabPanel px="0px">
                    <LyricsSoundtrack winnerName={winnerName} />
                  </TabPanel>
                  <TabPanel height="calc(100vh)" px="0px">
                    {watchFilm ? (
                      <WatchFullFilm watchFilm={watchFilm} />
                    ) : (
                      <Text
                        py={"40"}
                        fontSize={"45px"}
                        fontWeight="700"
                        textAlign="center"
                      >
                        {common.WEBPAGE_COMING_SOON}
                      </Text>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          </Stack>
        </Container>
      )}
    </Stack>
  );
};
