import {
  Box,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import { useState } from "react";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "react-icons/io";
import { useNavigate } from "react-router-dom";

//Component file
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import MobileNav from "../../components/Topheader";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`
const ReadMoreImg = `${IMAGE_BASE_PATH}/ui/ReadMore/readmore.png`

export default function ReadMore() {
  return (
    <>
      <SidebarWithHeader>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          py={4}
        >
          <Text
            fontSize={{ base: "24px", md: "43px" }}
            fontWeight={"600"}
            color="#000000"
            align={"center"}
          >
            For you to <span style={{ color: "#035DA1" }}>Read More</span> you
            will need to be at
            <br />
            Stage.Phase
          </Text>
          <Box
            height={{ base: "100%", md: "550px" }}
            width={{ base: "100%", md: "700px" }}
          >
            <Image src={ReadMoreImg} width={"100%"} height={"inherit"} />
          </Box>
        </Stack>
      </SidebarWithHeader>
    </>
  );
}

const SidebarWithHeader = ({ children }) => {
  const { onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
