import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import dateFormat from "dateformat";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component files
import MobileNav from "../../components/Topheader";
import Large from "../../components/layouts/Typography/Large";
import H2 from "../../components/layouts/Typography/H2";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import AwardsHonours from "./EconomicsOfArt/AwardsHonours";
import TopAuctionSales from "./EconomicsOfArt/TopAuctionSales";
import ArtMarketAnalysis from "./EconomicsOfArt/ArtMarketAnalysis";
import HistoryofHighs from "./EconomicsOfArt/HistoryofHighs";

//Language files
import { economicsarttabs, explore } from "../../constants/constants";

export default function SHRazaBodyOfWork({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const { alias } = useParams();
  const aliasAPI = alias;
  const masterlistType = "person-artist";
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist")?.value
            }/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }`,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
            masterlistType={masterlistType}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <EconomicsofArtBody
          setTitle={setTitle}
          setDate={setDate}
          setDateDod={setDateDod}
          aliasAPI={aliasAPI}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/posts`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/insights`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };
  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CommonLeftSidebar
        sections={introData}
        oSections={data.oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        backTo={data.backNavigation}
        handleNavigation={handleNavigation}
        leftFlag={true}
        readMoreText={readMoreText}
      />
    </>
  );
};

const EconomicsofArtBody = ({ setTitle, setDate, setDateDod, aliasAPI }) => {
  return (
    <>
      <Tabs align={{ xs: "", sm: "" }} w="full">
        <Stack display={"flex"}>
          <Box
            position={"fixed"}
            bg="white"
            width={{ base: "100%", md: "1192px" }}
            paddingTop={"34px"}
            paddingBottom={"18px"}
            px="40px"
            zIndex={99}
          >
            <TabList
              overflowX="auto"
              borderBottom={"1px"}
              borderBottomColor={"#f1f1f1"}
              gap={12}
              className="verticalscroll"
            >
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.AWARDS_AND_HONOURS}
              </Tab>
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.TOP_AUCTION_SALES}
              </Tab>
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {economicsarttabs.ART_MARKET_ANALYSIS}
              </Tab>
              <Tab
                className="Timeline"
                p="0"
                color="#333333"
                fontSize={"md"}
                fontWeight={"400"}
                _selected={{
                  borderBottomWidth: "6px",
                  color: "black",
                  borderBottomColor: "#333333",
                  fontWeight: "700",
                }}
              >
                {explore.HISTORY_OF_HIGHS}
              </Tab>
            </TabList>
          </Box>
        </Stack>
        <TabPanels>
          <TabPanel pt="80px" px="0px">
          <AwardsHonours tableName={""} masterlist={aliasAPI}/>
          </TabPanel>
          <TabPanel pt="80px" px="0px">
            <TopAuctionSales tableName={""} masterlist={aliasAPI}/>
          </TabPanel>
          <TabPanel pt="80px" px="0px">
            <ArtMarketAnalysis tableName={""} masterlist={aliasAPI}/>
          </TabPanel>
          <TabPanel pt="80px" px="0px">
            <HistoryofHighs tableName={""} masterlist={aliasAPI}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export function ArtistDetails({ artistDetails }) {
  return (
    <>
      <HStack justifyContent="left" py="1" px="4" marginTop="0.0rem">
        <H2>{artistDetails?.name}</H2>
      </HStack>
      <HStack justifyContent="left" px="4">
        <Large color={"black"}>
          {dateFormat(artistDetails?.date_of_birth, "d mmm yyyy")} -{" "}
          {dateFormat(artistDetails?.date_of_death, "d mmm  yyyy")}
        </Large>
      </HStack>
    </>
  );
}
