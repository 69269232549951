import axios from "axios";
import { transformJSON } from "../util/transformer";
import { amitabh_bachchan_landing_page_data, art_heritage_landing_page_data, bansi_chandragupta_landing_page_data, birju_maharaj_landing_page_data, calcutta_landing_page_data, default_landing_page_data, dulal_dutta_landing_page_data, javed_siddiqi_landing_page_data, kg_subramanayam_landing_page_data, lucknow_landing_page_data, mughal_e_azam_landing_page_data, nemai_ghosh_landing_page_data, pablo_bartholomew_landing_page_data, pictorial_space_landing_page_data, raj_kapoor_landing_page_data, sandip_ray_landing_page_data, sanjeev_kumar_landing_page_data, saswati_sen_landing_page_data, satyajit_landing_page_data, sh_raza_landing_page_data, shama_zaidi_landing_page_data, shammi_kapoor_landing_page_data, skk_landing_page_data, song_synopsis_booklet_landing_page_data, soumendu_roy_landing_page_data, sumatra_ghoshal_landing_page_data, suresh_jindal_landing_page_data } from "../MockData/LandingSectionMockup";
import { schema } from "./schemas/Section";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const getLandingPageInfoByAliasAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query: `query MyQuery($where: SectionWhere, $options: SectionOptions) {` +
      tableName + `(where: $where, options: $options) {
            sr_no
            alias
            landing_page_title
            landing_page_abbreviation
            masterlist
            description
            artwork {
                wnn
                accession_number
                alias
                name_of_publication
                volume_number
                publication_year
                sortable_year_of_publication
                artist_name
                date_of_artwork
                medium_of_artwork
                revised_medium
                id
                image
                height_cm
                width_cm
                title
                direct_url_of_preview_image
                medium            
            }
          }
        }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
      options: {
        sort: [
          {
            sr_no: "ASC",
          },
        ],
      }
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);

  if (result?.data?.data?.sections) {
    return result?.data?.data?.sections;
  } else {
    return [];
  }
};

export const getLandingPageInfoByAlias = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getLandingPageInfoByAliasDummyData(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getLandingPageInfoByAliasAPI(tableName, masterlist);
    try{
      if (response) {  
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const getLandingPageInfoByAliasDummyData = async (masterlist) => {
  const landingPageDataMap = {
    "satyajit-ray": satyajit_landing_page_data,
    "shatranj-ke-khilari-1977": skk_landing_page_data,
    "art-heritage-annual": art_heritage_landing_page_data,
    "mughal-e-azam": mughal_e_azam_landing_page_data,
    "sayed-haider-raza":sh_raza_landing_page_data,
    "suresh-jindal":suresh_jindal_landing_page_data,
    "javed-siddiqi":javed_siddiqi_landing_page_data,
    "shama-zaidi":shama_zaidi_landing_page_data,
    "birju-maharaj":birju_maharaj_landing_page_data,
    "dulal-dutta":dulal_dutta_landing_page_data,
    "soumendu-roy":soumendu_roy_landing_page_data,
    "bansi-chandragupta":bansi_chandragupta_landing_page_data,
    "nemai-ghosh":nemai_ghosh_landing_page_data,
    "sandip-ray":sandip_ray_landing_page_data,
    "sumatra-ghoshal":sumatra_ghoshal_landing_page_data,
    "pablo-bartholomew":pablo_bartholomew_landing_page_data,
    "amitabh-bachchan":amitabh_bachchan_landing_page_data,
    "saswati-sen":saswati_sen_landing_page_data,
    "song-synopsis-booklet":song_synopsis_booklet_landing_page_data,
    "pictorial-space":pictorial_space_landing_page_data,
    "lucknow":lucknow_landing_page_data,
    "raj-kapoor":raj_kapoor_landing_page_data,
    "k.g-Subramanyan":kg_subramanayam_landing_page_data,
    "calcutta":calcutta_landing_page_data,
    "sanjeev-kumar": sanjeev_kumar_landing_page_data,
    "shammi-kapoor": shammi_kapoor_landing_page_data,
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const landingPageData = landingPageDataMap[masterlist] || default_landing_page_data;
  return landingPageData;
};
