// Core Files
import { Box } from "@chakra-ui/react";
import YouTube from "react-youtube";

function WatchFullFilm({ watchFilm }) {
  const onReady = (event) => {
    event.target.pauseVideo();
  };
  const opts = {
    height: "630px",
    width: "100%",
    playerVars: {
      autoplay: 2, // Change 2 to 1 for boolean value
      rel: 0,
    },
  };
  return (
    <Box
      paddingTop={{ base: "30px", md: "30px" }}
      marginRight={{ base: "0px", md: "30px" }}
    >
      <YouTube videoId={watchFilm.slice(-11)} opts={opts} onReady={onReady} />
    </Box>
  );
}

export default WatchFullFilm;
