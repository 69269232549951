import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
} from "@chakra-ui/react";

// Core Files
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFlimographyData from "../../services/Filmography";
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";
import { explore } from "../../constants/constants";

// Subsection Files
import MobileNav from "../../components/Topheader";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import All from "../Film/ResearchCentre/All";
import { Book } from "./ResearchCentre/Library/Book";
import { Archive } from "../Film/ResearchCentre/Archive";
import { Library } from "./ResearchCentre/Library/Library";
import { isArray } from "lodash";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`


const artistLinksData = [
  {
    name: "Satyajit Ray",

  },
  {
    name: "Suresh Jindal",
  },
];

export default function MainLibraryAndArchive({ children }) {
  let { alias } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [leftFlag, setleftFlag] = useState(false);
  const [filmographyData, setFilmographyData] = React.useState([]);
  const [researchCentreFlag, setResearchCentreFlag] = React.useState(true);
  const [bookFlag, setBookFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState("");
  const [filmDetails, setFilmDetails] = useState({});
  const winnerName = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getData = async () => {
      const result = await getFlimographyData.filmographyByAlias("filmographies", winnerName);
      if (result) {
        const data = result;
        setFilmographyData(data);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [winnerName]);

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias("sections", winnerName);
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item.key === "masterlist").value}/${
              it.find((item) => item.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    // Get film intro data
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias("films", winnerName);
        if (result) {
          if (result) {
            setName(
              () =>
                result.find((item) => item.key === "filmTitleImage").value[0]
                  ?.direct_url_of_preview_image
            );
            setAgraphyLink([
              {
                link: `/research-categories/${
                  result.find((item) => item.key === "researchCategories")
                    .value[0]?.alias
                }`, // replace with the actual link
                title: result.find((item) => item.key === "researchCategories")
                  .value[0]?.abbreviation,
              },
              {
                link: `/categories/indian-cinema`,
                title: result.find((item) => item.key === "subject").value,
              },
            ]);
            setfilmMainImage(
              {
                 logoImage:result?.find((item) => item?.key === "filmMainImage")?.value[0]
                   ?.direct_url_of_preview_image,
                   logoLink:`/${masterlistType}/${alias}/introduction/title-image`
                 }
             );
              const loc=result?.find((item) => item?.key === "first_release_location")
           ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                  ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                  ?.value,
                  link:`location/${loc.toLowerCase()}`
          });
            setFilmTrailer({
              link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
              display: result.find((item) => item.key === "trailer_link")
                .display,
              image: WatchTrailer,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
  }, [masterlistType, winnerName, alias]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        filmographyData={filmographyData}
        winnerName={winnerName}
        researchCentreFlag={researchCentreFlag}
        bookFlag={bookFlag}
        leftFlag={leftFlag}
        openDrawer={openDrawer}
        setBookFlag={setBookFlag}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            leftFlag={leftFlag}
            filmographyData={filmographyData}
            winnerName={winnerName}
            researchCentreFlag={researchCentreFlag}
            bookFlag={bookFlag}
            setBookFlag={setBookFlag}
            openDrawer={openDrawer}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <LibraryAndArchive
          filmographyData={filmographyData}
          winnerName={winnerName}
          setResearchCentreFlag={setResearchCentreFlag}
          bookFlag={bookFlag}
          setleftFlag={setleftFlag}
          setBookFlag={setBookFlag}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          name={name}
          masterlistType={masterlistType}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  filmographyData,
  winnerName,
  onClose,
  leftFlag,
  researchCentreFlag,
  bookFlag,
  setBookFlag,
  openDrawer,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  let { drawerId } = useParams();
  const [nav, setNav] = useState();
  useEffect(() => {
    if (drawerId) {
      setNav(drawerId);
    }
  }, [drawerId, navigate]);
  const handleNavigation = () => {
    if (bookFlag) {
      setBookFlag(false);
    } else if (nav) {
      navigate(`/${masterlistType}/${winnerName}/body-of-work/2/${nav}`);
    } else {
      navigate(-1);
    }
  };
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
    name: "Satyajit Ray",
    title:"Satyajit Ray",
    links: [
      { link_name: "Working Still", link: "" },
      { link_name: "Film Stills", link: "" },
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
    ],
    Image: SatyajitRayImg,
    desc: "",
    masterlist: {
      text: "Visit Satyajit Ray Masterlist →",
      link: "/person-poly/satyajit-ray",
    },
  },{
    name: "Suresh Jindal",
    title:"Suresh Jindal",
    links: [
      {
        link_name: "Books",
        link: "/film/shatranj-ke-khilari-1977/research-centre/",
      },
      { link_name: "Interviews", link: "" },
      { link_name: "Newspaper Clippings", link: "" },
    ],
    Image: SureshJindal,
    desc: "",
    masterlist: {
      text: "Visit Suresh Jindal Masterlist →",
      link: "",
    },
  }];
  const oSections = [
    {
     to:`/${masterlistType}/${winnerName}/posts`,
      title: "",
      text: explore.POSTS,
    },
    {
     to:`/${masterlistType}/${winnerName}/insights`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  
  const trailerLink = {
    link:`/${masterlistType}/${winnerName}/watch-film-trailer`,
    image: WatchTrailer,
  };

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        leftFlag={leftFlag}
        researchCentreFlag={researchCentreFlag}
        bookFlag={bookFlag}
        setBookFlag={setBookFlag}
        openDrawer={openDrawer}
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        artistLinksData={artistLinksData}
        drawerData={drawerData}
        drawerImage={SKK1}
      />
    </>
  );
};

const LibraryAndArchive = ({
  winnerName,
  setResearchCentreFlag,
  setleftFlag,
  bookFlag,
  setBookFlag,
  openDrawer,
  setOpenDrawer,
  name,
  masterlistType
}) => {
  const [visible, setVisible] = React.useState(true);
  const [bookData, setBookData] = useState({});
  const [listGridIcon, setListGridIcon] = useState(true);
  const navigate = useNavigate();

  let {bookDetails}=useParams();

    /* Reference for back navigation on tab */
  // let { tabIndex, gridview } = useParams();
  // useEffect(() => {
  //   if (tabIndex) {
  //     // navigate(`/${masterlistType}/${winnerName}/research-centre`, { replace: true });

  //     if (parseInt(tabIndex) === 1) {
  //       setListGridIcon(true);
  //     }
  //     if (parseInt(gridview) === 0) {
  //       setVisible(false);
  //     }
  //     if (parseInt(gridview) === 1) {
  //       setVisible(true);
  //     }
  //   }
  // }, [tabIndex, navigate, gridview, masterlistType, winnerName]);

  const handleBookClick = (flag, data) => {
    navigate(`bookDetails`)
    setBookData(data);
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Stack width={{ base: "100%", md: "1160px" }}>
      <Container minW={"100%"} pb="4" px="0px" marginLeft={{base:"0px",md:"30px"}}>
        <Stack>
          <Box position={"fixed"} zIndex={999} bg="white"  width={{ base: "100%", md: "1150px" }}>
            <HStack  paddingY={{ base: "20px", md: "30px" }}
                paddingX={{ base: "4", md: "0px" }}>
            <Image
                  src={name}
                  width={{ base: "100%", md: "656px" }}
                  height={"auto"}
                />
            </HStack>
          </Box>
          {bookDetails==="bookDetails" ? (
            <Book bookData={bookData} />
          ) : (
            <HStack>
              <Tabs
               align={{ xs: "end", sm: "" }}
               width={{ base: "100%", md: "1192px" }}
               defaultIndex={1}
               className="verticalscroll"
              >
                <Stack
                  flexDirection={"row-reverse"}
                  display={"flex"}
                  marginTop={{ base: "62px", md: "105px" }}
                >
                  <Box
                    position={"fixed"}
                    zIndex={999}
                    bg="white"
                    width={{ base: "100%", md: "1192px" }}
                    paddingX={{ base: "20px", md: "30px" }}
                    overflowX={"auto"}
                  >
                    <TabList
                      overflowX="auto"
                      borderBottom={"1px"}
                      borderBottomColor={"#f1f1f1"}
                      gap={12}
                      pb="4"
                      className="verticalscroll"
                    >
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "2px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(false)}
                      >
                        {explore.ALL}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.LIBRARY}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.ARCHIVE}
                      </Tab>
                    </TabList>
                  </Box>
                  {listGridIcon && (
                    <Stack
                      flexDirection={"row"}
                      gap={0}
                      zIndex={999}
                      id="listgridviewbtn"
                      marginRight={"20px !important"}
                      marginTop={{base:"-5px !important",md:"-16px !important"}}
                      position={"fixed"}
                    >
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(false)}
                          color={!visible ? "black" : "#989898"}
                          fontSize="20px"
                          display="flex"
                          borderTopLeftRadius="6px"
                          borderBottomLeftRadius="6px"
                          borderTopRightRadius="0px"
                          borderBottomRightRadius="0px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <RiMenuLine ml="4" />
                        </IconButton>
                      </HStack>
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(true)}
                          color={visible ? "black" : "#989898"}
                          fontSize="16px"
                          display="flex"
                          borderTopLeftRadius="0px"
                          borderBottomLeftRadius="0px"
                          borderTopRightRadius="6px"
                          borderBottomRightRadius="6px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <BsGrid />
                        </IconButton>
                      </HStack>
                    </Stack>
                  )}
                </Stack>
                <TabPanels>
                  <TabPanel px="0px">
                    <All visible={visible} />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Library
                      visible={visible}
                      handleBookClick={handleBookClick}
                      openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      setleftFlag={setleftFlag}
                      tableName={"bibliographies"}
                      masterlist={winnerName}
                      name={name}
                    />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Archive
                      visible={visible}
                      tableName={"artworks"}
                      masterlist = {winnerName}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
