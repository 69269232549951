import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import React from "react";
import DataContext from "../context/DataContext";
import Large from "../components/layouts/Typography/Large";
import { common } from "../constants/constants";

function Research() {
  const { categories } = React.useContext(DataContext);
  const [categoriesData, setCategoriesData] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    setCategoriesData(
      categories
        .map((item) => {
          return {
            alias: item?.find((nestedItem) => nestedItem?.key === "alias")?.value,
            name: item?.find((nestedItem) => nestedItem?.key === "name")?.value,
          };
        })
        .filter(Boolean)
    );
  }, [categories]);

  return (
    <Layout>
      <Stack m="5" pr={"8"} pl={"8"} className="mobileviewpadding">
        <Container maxW="auto">
          {categoriesData.length === 0 ? (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          ) : (
            <SimpleGrid columns={[1, null, 4]} spacing="20px">
              {categoriesData ? (
                categoriesData.map((item, index) => (
                  <Box
                    onClick={() =>
                      navigate("/research-categories/" + item.alias)
                    }
                    key={index}
                    maxW={"auto"}
                    w={"full"}
                    bg={"gray.50"}
                    border={"1px"}
                    borderColor={"gray.100"}
                    rounded={"md"}
                    p={2}
                    overflow={"hidden"}
                    cursor={"pointer"}
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.05)",
                    }}
                  >
                    <Box
                      h={"auto"}
                      bg={"white"}
                      mt={-6}
                      mx={-6}
                      mb={6}
                      pos={"relative"}
                    >
                      <Image
                        src={
                          item?.image
                            ? item?.image
                            : "https://asvs.in/wp-content/uploads/2017/08/dummy.png"
                        }
                        alt=""
                        w={"100%"}
                      />
                    </Box>
                    <Stack>
                      <Large color="black">{item?.name}</Large>
                    </Stack>
                  </Box>
                ))
              ) : (
                <React.Fragment />
              )}
            </SimpleGrid>
          )}
        </Container>
      </Stack>
    </Layout>
  );
}
export default Research;
