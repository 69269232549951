import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Checkbox,
    Container,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    Image,
  } from "@chakra-ui/react";

  //Core files
  import React, { useEffect, useState } from "react";
  import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
  import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
  import { useTranslation } from "react-i18next";

  //Service files
  import * as getExhibitData from "./../../../../../services/EventExhibition";
  import { CopyPasteService } from "../../../../../services/CopyPaste";

  //Component files
  import ImageGridlist from "../../../Component/ImageGridlist";
  import SmallBold from "../../../../../components/layouts/Typography/SmallBold";
  import Small from "../../../../../components/layouts/Typography/Small";
  import ScrollToTop from "../../../../../components/sections/ScrollToTop";
  import { FullScreenImage } from "../../../Component/FullScreenImage";

  //Language files
  import {
    common,
    explore,
    filterAttribute,
  } from "../../../../../constants/constants";

  //S3 Bucket osw-dev-images
  const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

  const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`
  
  export default function GridView({ winnerName }) {
    const [showFilterBox, setShowFilterBox] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteroptionData, setFilteroptionsData] = useState({});
    const [FilterData, setFilterData] = useState([{}]);
    const [filterOptions, setFilterOptions] = useState({
      artist: [],
      year: [],
      medium: [],
    });
    const [fullScreenImage, setFullScreenImage] = useState(false);
    const [clickedImageIndex, setClickedImageIndex] = useState(null);
    const [ImgData, setImgData] = useState(null);
  
  
    const showImages = (image, flag) => {
      if (image) {
        setFullScreenImage(flag);
        setImgData(image);
      } else {
        setFullScreenImage(flag);
        setImgData(null);
      }
    };
    useEffect(() => {
      const getFilterOptionsData = async () => {
        const result = await getExhibitData.getExhibitsFilterOptions(
          "event-exhibition",
          winnerName
        );
        if (result) {
          const dataIntro = result;
          setFilteroptionsData(dataIntro);
        }
      };
  
      getFilterOptionsData();
    }, [winnerName]);
  
    const createDataObject = (dataArray) => {
      const dataObject = {};
      if (dataArray.length > 0) {
        const dataItem = dataArray[0];
        for (const key in dataItem) {
          dataObject[key] = dataItem[key];
        }
      }
      return dataObject;
    };
    const citationFactor = (data) => {
      return data.map((item, index) => {
        const artist = createDataObject([item.artist]);
        const medium_artwork = createDataObject([item.medium_artwork]);
        const title_of_artwork = createDataObject([item.title_of_artwork]);
        return {
          image: item?.image,
          artist,
          medium_artwork,
          title_of_artwork,
          content: item.desc !== "" ? item.desc : "Content Awaited",
          title: `${artist?.name}`,
          fullImg: item?.fullimg,
          essayistData: [title_of_artwork,medium_artwork],
          link:item?.link
        };
      });
    };
  
    useEffect(() => {
      const getData = async () => {
        const result = await getExhibitData.exhibitData(
          "event-exhibition",
          winnerName
        );
        setFilterData(citationFactor(result));
      };
  
      getData();
      // eslint-disable-next-line
    }, []);
    const handleFilterClick = () => {
      setShowFilterBox(!showFilterBox);
    };
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
    const handleCheckboxChange = (value, type) => {
      // Determine the array based on the type
  
      let selectedArray = [];
      switch (value) {
        case "artist":
          selectedArray = filterOptions.artist;
          break;
        case "year":
          selectedArray = filterOptions.year;
          break;
        case "medium":
          selectedArray = filterOptions.medium;
          break;
        case "searches":
          selectedArray = filterOptions.searches_v;
          break;
        default:
          break;
      }
      if (selectedArray.includes(type)) {
        selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
      } else {
        selectedArray = [...selectedArray, type];
      }
      switch (value) {
        case "artist":
          setFilterOptions({
            ...filterOptions,
            artist: selectedArray,
          });
          break;
        case "year":
          setFilterOptions({
            ...filterOptions,
            year: selectedArray,
          });
          break;
        case "medium":
          setFilterOptions({
            ...filterOptions,
            medium: selectedArray,
          });
          break;
        case "searches":
          setFilterOptions({
            ...filterOptions,
            searches_v: selectedArray,
          });
          break;
        default:
          break;
      }
    };
    const handleImageClick = (item, index) => {
      setClickedImageIndex(index);
      setFullScreenImage(true);
    };
    return (
      <Stack flexDirection={"row-reverse"} minH={"100vh"}>
        {!showFilterBox && <FilterButton onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py="8"
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="center">
            {FilterData && FilterData.length > 0 ? (
              <HStack
                onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
                marginRight={{ base: "0px", md: "30px" }}
              >
                <ImageGridlist data={FilterData} handleImageClick={handleImageClick} />
              </HStack>
            ) : (
              <>
                <HStack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                  >
                    {common.NO_DATA}
                  </Text>
                </HStack>
              </>
            )}
            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterSKK">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold
                        as="u"
                        color={"black"}
                        cursor="pointer"
                        onClick={() => {
                          setFilterOptions({
                            documentType: [],
                            year: [],
                            creatorType: [],
                            publisher: [],
                          });
                          setSearchTerm("");
                        }}
                      >
                        {explore.RESET}
                        <span style={{ color: "#004F79" }}>
                          {" "}
                          {/* For future reference */}
                          {/* {filterCount > 0 && ` (${filterCount})`} */}
                        </span>
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                        onChange={handleSearch}
                        value={searchTerm}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle allowMultiple>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>
                          {filteroptionData.artist.length > 0
                            ? filteroptionData.artist.map((item) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.creatorType.includes(
                                        item
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("creatorType", item);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{`${item}`}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })
                            : "Content Awaited"}
                        </Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.MEDIUM}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        {filteroptionData.medium.length > 0
                          ? filteroptionData.medium.map((item) => {
                              return (
                                <Flex
                                  borderBottom={"1px"}
                                  borderBottomColor={"#f1f1f1"}
                                >
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.documentType.includes(
                                      item
                                    )}
                                    onChange={() => {
                                      handleCheckboxChange("docType", item);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>
                                      {`Book.${item}`}
                                    </Text>
                                  </Box>
                                </Flex>
                              );
                            })
                          : "Content Awaited"}
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.YEAR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <Box maxH="130px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          <Small>
                            {filteroptionData.yearFilter.length > 0
                              ? filteroptionData.yearFilter.map((item) => {
                                  return (
                                    <Flex
                                      borderBottom={"1px"}
                                      borderBottomColor={"#f1f1f1"}
                                    >
                                      <Checkbox
                                        borderColor={"black"}
                                        _checked={{
                                          "& .chakra-checkbox__control": {
                                            background: "black",
                                          },
                                        }}
                                        iconColor="white"
                                        cursor="pointer"
                                        marginRight={"5px"}
                                        isChecked={filterOptions.year.includes(
                                          item
                                        )}
                                        onChange={() => {
                                          handleCheckboxChange("year", item);
                                        }}
                                      />
                                      <Box p="1">
                                        <Text fontSize={"14px"}>{`${item}`}</Text>
                                      </Box>
                                    </Flex>
                                  );
                                })
                              : "Content Awaited"}
                          </Small>
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                  </Accordion>
                  <HStack marginTop={"10px"}>
                    <Button
                      color={"#ffffff"}
                      onClick={() => {
                        setShowFilterBox(false);
                      }}
                      width={"199px"}
                      height={"36px"}
                      bg={"#000000"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      variant={"unstyled"}
                    >
                      {explore.APPLY}
                    </Button>
                  </HStack>
                </Box>
              )}
            </HStack>
          </Stack>
          <ScrollToTop />
        </Container>
        {fullScreenImage && (
          <FullScreenImage
            isOpen={fullScreenImage}
            onClose={() => showImages(null, false)}
            imageData={ImgData}
            data={FilterData.slice(0,4)}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
            bannerFlag={false}
            title="banner"
          />
        )}
      </Stack>
    );
  }
  
  export const FilterButton = ({ onClick, marginRight, paddingTop }) => {
    const { t } = useTranslation("");

    return (
      <>
        <Stack
          position={"relative"}
          pos={"fixed"}
          marginTop={"100px"}
          marginRight={marginRight}
          paddingTop={paddingTop}
          zIndex={"99!important"}
          display={{ base: "none", md: "flex" }}
        >
          <Stack
            id="filterstickywbtn"
            marginRight={"0px!important"}
            onClick={onClick}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap="10px"
            >
              <Image transform={"rotate(360deg)"} src={Filter} />
              <Text
                as="button"
                transform={"rotate(90deg)"}
                fontSize={"14px"}
                fontWeight={"700"}
                marginTop={"4px"}
              >
                {t(explore.FILTER)}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };
  