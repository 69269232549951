import { Box, Container, Image, Stack, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import React from "react";

import axios from "axios";

import Layout from "../components/sections/SidebarLayout";
import { common } from "../constants/constants";
function ProjectPage(props) {
  const { id } = useParams();
  const [project, setProject] = React.useState({});

  const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

  React.useEffect(() => {
    const getData = async () => {
      const graphqlQuery = {
        query:
          `query Query {
            projects {
              published
              venue
              name
              id
              link
              display_date
              alias
            }
          }
            `
      };
    
      const result = await axios.post(baseUrl, graphqlQuery);

      if (result?.data?.data?.projects) {
        const dataProjects = result?.data?.data?.projects;
        const dataProject = dataProjects.find((e) => e.id === id);
        setProject(dataProject);
      }
    };
    getData();
    return () => {};
  }, [id, baseUrl]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Stack m="5" py="4" pr={"8"} pl={"8"} className="mobileviewpadding">
        <Container maxW="auto">
          {project.length === 0 ? (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          ) : (
            <Box>
              <Box bg="gray.100" alignItems={"center"}>
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={
                    project?.image?.image
                      ? "https://d3fsixjuo450s3.cloudfront.net/" +
                        project?.image?.image
                      : "https://asvs.in/wp-content/uploads/2017/08/dummy.png"
                  }
                  alt=""
                />
              </Box>
              <Box w="100%">
                <Text pt="4">{project?.display_date}</Text>
              </Box>
              <Box w="100%">
                <Text fontSize={"2xl"} fontWeight={"700"} pt="4">
                  {project?.name}
                </Text>
              </Box>
              <Box w="100%">
                <Text pt="4">{project?.venue}</Text>
              </Box>
            </Box>
          )}
        </Container>
      </Stack>
    </Layout>
  );
}
export default ProjectPage;
