import { Box, HStack, Stack, Text } from "@chakra-ui/react";

//Core files
import React, { Fragment, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination } from "swiper";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";

// Service files
import * as getAwardData from "../../../services/AwardsHonours";

//Component files
import ScrollToTop from "../../../components/sections/ScrollToTop";

//Language files
import { common } from "../../../constants/constants";

function AwardsHonours({ CopyPasteService, tableName, masterlist }) {
  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const [imageData, setImageData] = useState([]);

  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };
  
  const extractedImages = (data) => {
    let extractImg = data?.flatMap((entry) => {
      const awardImage = entry?.find(
        (item) => item.key === "awardImage"
      );
      if (awardImage && awardImage?.value.length > 0) {
        return awardImage?.value?.map((image) => image);
      }
      return [];
    });
    return extractImg;
  };
  const [awardsHonours, setAwardsHonours] = useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getAwardData.awardshonoursByWinner(
        tableName,
        masterlist
      );
      if (result) {
        setAwardsHonours(result);
        setImageData(extractedImages(result));
      }
    };
    getData();
  }, [tableName, masterlist]);

  return (
    <>
      {awardsHonours.length !== 0 ? (
        <Stack py="4" justifyContent={"space-between"} flexDirection={{base:"column",md:"row"}} px="0px">
          <HStack
            flexDirection={"column"}
            className="col-8"
            width={"100%"}
            alignItems={"baseline"}
            onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
          >
            <Stack width={"100%"} flexDirection={"row"}>
              <VerticalTimeline layout={"1-column-left"}>
                {awardsHonours?.map((item, index) => {
                  return (
                    <>
                      {item?.find((item) => item?.key === "awardImage").value
                        .length > 0 ? (
                        <Box pos={"relative"} right={"20px"} top={"20px"}>
                          <AiOutlinePicture
                            onClick={() => showImage(0, true)}
                            cursor={"pointer"}
                            color="#1E90FF"
                            size={16}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <>
                        <VerticalTimelineElement
                          key={index}
                          id="timeline1"
                          contentStyle={{ fontSize: "sm" }}
                          iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                          icon={
                            item?.find((item) => item.key === "sortable_date")
                              .value
                          }
                        >
                          <Text className="fs-14" cursor={"pointer"}>
                            {item?.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it.key === "award_name" && (
                                    <span>{`${it.value} `}</span>
                                  )}
                                </Fragment>
                              );
                            })}
                            {item.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it.key === "awardcategory" &&
                                    it?.value.length > 0 && (
                                      <span>{`| ${it?.value} `}</span>
                                    )}
                                    
                                </Fragment>
                              );
                            })}
                            {item.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it?.key === "issuing_authority" && (
                                    <span>{`| ${it?.value} `}</span>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Text>
                        </VerticalTimelineElement>
                      </>
                    </>
                  );
                })}
              </VerticalTimeline>
            </Stack>
          </HStack>
          <HStack
            px={"0px"}
            className="col-4"
            alignItems={"flex-start"}
            justifyContent={"flex-end"}
            paddingLeft={"50px"}
          >
            <Stack
              className="col-12 mt-2"
              id="allslidersatyajitAward"
              position={"relative"}
            >
              <HStack className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation, Pagination]}
                  onSwiper={setSwiper}
                >
                  {imageData?.length>0 &&
                    imageData?.map((it) => {
                      return (
                        <SwiperSlide key={it}>
                          <Box
                            height={"428px"}
                            width={"297px"}
                            border={"1px solid #C4C4C4"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            textAlign={"center"}
                            display={"flex"}
                            bg={"#C4C4C4"}
                          >
                            <Text fontSize={"14px"} fontWeight={"700"}>
                              {common.IMAGE_AWAITED}
                            </Text>
                          </Box>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </HStack>
            </Stack>
          </HStack>
          <ScrollToTop />
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </>
  );
}

export default AwardsHonours;
