import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import { TbFoldersOff } from "react-icons/tb";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";

// Service Files
import { CopyPasteService } from "../../../../services/CopyPaste";
import * as getScreeningMasterlistData from "../../../../services/Screenings";
import { isTransformationNotAllowed } from "../../../../util/isTranformationAllowed";

// Subsection Files
import ScrollToTop from "../../../../components/sections/ScrollToTop";
import { common, explore } from "../../../../constants/constants";
import { EffectFlip, Navigation, Pagination } from "swiper";
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import dateFormat from "dateformat";

function Timeline({ tableName, masterlist }) {
  const btnRef = React.useRef();
  const [screeningsData, setScreeningsData] = useState([{}]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const [apply, setApply] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    typeOfScreening: [],
    years: [],
    searches_v: [],
  });

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const showFullImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "Type_of_Screening":
        selectedArray = filterOptions.typeOfScreening;
        break;
      case "Years":
        selectedArray = filterOptions.years;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "Type_of_Screening":
        setFilterOptions({
          ...filterOptions,
          typeOfScreening: selectedArray,
        });
        break;
      case "Years":
        setFilterOptions({
          ...filterOptions,
          years: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  // This function return Filter data options to showing in filter box
  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result =
        await getScreeningMasterlistData.getScreeningTypeFilterOptions(
          tableName,
          masterlist
        );
      if (result) {
        setFilteroptionsData(result);
      }
    };

    getFilterOptionsData();
  }, [tableName, masterlist]);

  // This function return user applied filter counts.
  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getScreeningMasterlistData.getScreeningByMasterList(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getScreeningMasterlistData.getScreeningByMasterList(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }

    const data = result || []; // Ensure data is not null or undefined
    setScreeningsData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  // need to refactor the function to bring real data for filter.
  const [imageData, setImageData] = useState([]);

  const citationFactor = (data) => {
    return data.map((item) => {
      let imgData = item.find(
        (nestedItem) => nestedItem.key === "screeningImages"
      )?.value.length
        ? item.find((nestedItem) => nestedItem.key === "screeningImages")
            ?.value[0].value
        : null;

      return {
        year:
          item.find((nestedItem) => nestedItem.key === "sortable_date")
            ?.value || null,
        value:
          `${
            item.find((nestedItem) => nestedItem.key === "event_name")?.value
          } ` +
          `| ${
            item.find((nestedItem) => nestedItem.key === "organiser")?.value || ""
          } | ${
            item.find((nestedItem) => nestedItem.key === "venue_location")
              ?.value || ""
          }` +
          (item.find((nestedItem) => nestedItem.key === "type")?.value !== ""
            ? ` | ${
                item.find((nestedItem) => nestedItem.key === "type")?.value
              }`
            : ``) +
          (item.find((nestedItem) => nestedItem.key === "opening_date")
            ?.value !== ""
            ? ` | ${dateFormat(
                item.find((nestedItem) => nestedItem.key === "opening_date")
                  ?.value,
                "d mmm yyyy"
              )}`
            : ``) +
          ` to` +
          (item.find((nestedItem) => nestedItem.key === "closing_date")
            ?.value !== ""
            ? ` ${dateFormat(
                item.find((nestedItem) => nestedItem.key === "closing_date")
                  ?.value,
                "d mmm yyyy"
              )}`
            : ``),
        images: imgData,
        wnn: item.find((nestedItem) => nestedItem.key === "wnn")?.value || null,
      };
    });
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getScreeningMasterlistData.getScreeningByMasterList(
            tableName,
            masterlist
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setImageData(extractedImages(mockData));
          setScreeningsData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extractedImages = (data) => {
    let extractImg = data.flatMap((entry) => {
      const screeningImages = entry.find(
        (item) => item.key === "screeningImages"
      );
      if (screeningImages && screeningImages.value.length > 0) {
        return screeningImages.value.map((image) => image.value);
      }
      return [];
    });
    return extractImg;
  };

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getScreeningMasterlistData.getScreeningByMasterList(
              tableName,
              masterlist
            )) || {};
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setImageData(extractedImages(filteredMockDataResult));
          setScreeningsData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };

  return (
    <>
      <Stack
        width={"1160px"}
        flexDirection={"row-reverse"}
        justifyContent={"space-between"}
      >
        <FilterButton onClick={handleFilterClick} />
        <Container maxW="auto" pb="4" px="0px">
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            // marginLeft={"24px !important"}
            marginTop={"0px !important"}
            width={"100%"}
            alignItems={"flex-start"}
            minH={"100vh"}
          >
            <HStack
              flexDirection={"column"}
              alignItems={"baseline"}
              p={"0px !important"}
              onCopy={(e) => CopyPasteService(e, window.location.href)}
              className="col-7"
              margin={!screeningsData.length > 0 && "0px auto"}
              ml={8}
            >
              {screeningsData.length > 0 ? (
                <>
                  <VerticalTimeline
                    layout={"1-column-left"}
                    className="layout mt-0"
                  >
                    {screeningsData?.map((item) => (
                      // <Stack flexDirection={"row"}>
                      <>
                        {item.images ? (
                          <Box pos={"relative"} right={"20px"} top={"20px"}>
                            <AiOutlinePicture
                              onClick={() => showImage(0, true)}
                              cursor={"pointer"}
                              color="#1E90FF"
                              size={16}
                            />
                          </Box>
                        ) : (
                          <Box width={"16px"} height={"16px"}></Box>
                        )}

                        <VerticalTimelineElement
                          id="skkScreening_timeline"
                          contentStyle={{ fontSize: "sm" }}
                          iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                          icon={item?.year}
                        >
                          <Text
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#000000"
                            ref={btnRef}
                            width={"90%"}
                          >
                            {item.value}
                          </Text>
                        </VerticalTimelineElement>
                        {/* </Stack> */}
                      </>
                    ))}
                  </VerticalTimeline>
                </>
              ) : (
                <HStack w={"100%"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    w={"inherit"}
                    justifyContent={"right"}
                    gap={4}
                  >
                    <TbFoldersOff size={40} />
                    <Text
                      py={"40"}
                      fontSize={"30px"}
                      fontWeight="700"
                      textAlign="center"
                      display={"flex"}
                      alignItems={"center"}
                    >
                      {common.NO_DATA}
                    </Text>
                  </Box>
                </HStack>
              )}
            </HStack>
            {!showFilterBox && imageData.length > 0 && (
              <HStack
                px={"0px"}
                alignItems={"flex-start"}
                justifyContent={"flex-end"}
                id="allsliderskkScreening"
                className={
                  !iconClicked ? "col-4 imageZoomOut" : "col-4 imageZoomIn"
                }
              >
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation, Pagination]}
                  onSwiper={setSwiper}
                >
                  {imageData.map((it) => (
                    <SwiperSlide key={it}>
                      <Box
                        height={"395px"}
                        width={"322px"}
                        bg={"#C4C4C4"}
                        border={"1px solid #C4C4C4"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Text fontSize={"15px"} fontWeight={"700"}>
                          {common.IMAGE_AWAITED}
                        </Text>
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </HStack>
            )}
            <HStack
              className="col-4"
              alignItems={"flex-start"}
              flexDirection={"row-reverse"}
              p={"0px !important"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={filteroptionData}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                />
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
      <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imgData}
      />
      <Stack marginRight={"20px"}>
        <ScrollToTop />
      </Stack>
    </>
  );
}
export default Timeline;

const ImagePopupModal = ({ isOpen, onClose, imageData }) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      // onClose={(e) => setFullscreen(false)}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody>
          <Box bg="white">
            <Stack>
              <HStack justifyContent={"center"}>
                <Image src={imageData} height={"100vh"}></Image>
              </HStack>
              <HStack justifyContent={"flex-end"}>
                <Text
                  fontSize={"xl"}
                  bg={"black"}
                  opacity={0.7}
                  color={"white"}
                  fontWeight={"400"}
                  padding="2"
                  borderRadius={"35px"}
                  width="max-content"
                  marginTop="-200px"
                  marginRight={"15%"}
                  cursor="pointer"
                  position={"absolute"}
                  onClick={onClose}
                  // onClick={(e) => setFullscreen(false)}
                >
                  <CgArrowsExpandRight />
                </Text>
              </HStack>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
const FilterBox = ({
  setShowFilterBox,
  setApply,
  handleCheckboxChange,
  handleSearch,
  filteroptionData,
  filterOptions,
  setFilterOptions,
  setSearchTerm,
  searchTerm,
  filterCount,
}) => {
  return (
    <Box className="box-filterscreening">
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <HStack>
          <SmallBold
            as="u"
            color={"black"}
            onClick={() => {
              setFilterOptions({
                typeOfScreening: [],
                years: [],
              });
              setSearchTerm("");
              // setMockFilterData(mockdata)
              // setShowFilterBox(false)
            }}
            cursor="pointer"
          >
            {explore.RESET}
            <span style={{ color: "#004F79" }}>
              {" "}
              {filterCount > 0 && ` (${filterCount})`}
            </span>
          </SmallBold>
        </HStack>
        <HStack className="mt-0">
          <RiCloseCircleFill
            size={"22px"}
            cursor="pointer"
            onClick={(e) => setShowFilterBox(false)}
          />
        </HStack>
      </Stack>
      <HStack alignItems={"left"} flexDirection={"column"}>
        <SmallBold py="4">{explore.SEARCH}</SmallBold>
        <InputGroup className="ml-0" pb="4">
          <Input
            placeholder="Search"
            onChange={handleSearch}
            value={searchTerm}
          />
          <InputRightElement>
            <BiSearch color="green.500" />
          </InputRightElement>
        </InputGroup>
      </HStack>
      {/* <Accordion allowToggle allowMultiple> */}
      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <Text fontSize={"14px"} fontWeight={"700"} lineHeight={"24px"}>
                Type of Screening
              </Text>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel
            pb={4}
            className="px-1"
            height={"144px"}
            overflowY={"scroll"}
          >
            {filteroptionData &&
              filteroptionData?.screeningTypeFilter.map((it, index) => {
                return (
                  <>
                    <Flex borderBottom={"1px"} borderBottomColor={"#f1f1f1"}>
                      <Checkbox
                        borderColor={"black"}
                        _checked={{
                          "& .chakra-checkbox__control": {
                            background: "black",
                          },
                        }}
                        iconColor="white"
                        cursor="pointer"
                        marginRight={"5px"}
                        isChecked={filterOptions.typeOfScreening.includes(it)}
                        onChange={() => {
                          handleCheckboxChange("Type_of_Screening", it);
                        }}
                      />
                      <Box p="1">
                        <Text fontSize={"14px"}>{it}</Text>
                      </Box>
                    </Flex>
                  </>
                );
              })}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <Text fontSize={"14px"} fontWeight={"700"} lineHeight={"24px"}>
                Year
              </Text>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel
            pb={4}
            className="px-1"
            height={"144px"}
            overflowY={"scroll"}
          >
            {filteroptionData &&
              filteroptionData?.yearFilter.map((it, index) => {
                return (
                  <>
                    <Flex borderBottom={"1px"} borderBottomColor={"#f1f1f1"}>
                      <Checkbox
                        borderColor={"black"}
                        _checked={{
                          "& .chakra-checkbox__control": {
                            background: "black",
                          },
                        }}
                        iconColor="white"
                        cursor="pointer"
                        marginRight={"5px"}
                        isChecked={filterOptions.years.includes(it)}
                        onChange={() => {
                          handleCheckboxChange("Years", it);
                        }}
                      />
                      <Box p="1">
                        <Text fontSize={"14px"}>{it}</Text>
                      </Box>
                    </Flex>
                  </>
                );
              })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <HStack marginTop={"10px"}>
        <Button
          // as="u"
          color={"#ffffff"}
          onClick={() => {
            setApply((prev) => !prev);
            setShowFilterBox(false);
          }}
          width={"199px"}
          height={"36px"}
          bg={"#000000"}
          fontSize={"16px"}
          fontWeight={"400"}
          variant={"unstyled"}
        >
          {explore.APPLY}
        </Button>
      </HStack>
    </Box>
  );
};
