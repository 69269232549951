import { ChakraProvider, Container } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import {  HelmetProvider } from "react-helmet-async";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import translation_En from "./MultilingualJson/en_language.json";
import translation_Fr from "./MultilingualJson/fr_language.json";
import translation_Hi from "./MultilingualJson/hi_language.json";

// import "./styles.css";
const resources = {
  en: {
    translation: translation_En,
  },
  fr: {
    translation: translation_Fr,
  },
  hi: {
    translation: translation_Hi,
  },
};
i18next
  .use(initReactI18next)
  .init({
    resources:resources,
    lng: localStorage.getItem("i18nextLng_dev.osianamaworld.org") || "en",
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissingTo: (lng, ns) => {
      const storageKey = `i18nextLng_${window.location.host}`;
      localStorage.setItem(storageKey, lng);
    }
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
      <ChakraProvider>
      <I18nextProvider i18n={i18next}>
        <Container maxW="1400px" px="0px">
          <App />
        </Container>
        </I18nextProvider>
      </ChakraProvider>
    </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
