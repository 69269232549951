import {Stack} from "@chakra-ui/react";
//Core file
import React, { useState } from "react";

//Component file
import GridView from "./GridView";
import TabularView from "./TabularView";

export const Library = ({
  visible,
  handleBookClick,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
  tableName,
  rcAlias,
  main
}) => {
  const [contentClicked, setContentClicked] = useState(false);
  const [scanClicked, setScanClicked] = useState(false);

  return (
    <Stack position={"relative"}>
      {visible ? (
        <>
          <GridView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            main={main}
            rcAlias={rcAlias}
          />
        </>
      ) : (
        <>
          <TabularView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            rcAlias={rcAlias}
            main={main}
          />
        </>
      )}
    </Stack>
  );
};
