export const schema = [
  { key: "id", display: "Id", seq: 1 },
  { key: "alias", display: "Alias", seq: 2 },
  { key: "event_name", display: "Event name", seq: 3 },
  { key: "type", display: "Type", seq: 4 },
  { key: "sortable_date", display: "Sortable date", seq: 5 },
  { key: "opening_date", display: "Opening date", seq: 6 },
  { key: "closing_date", display: "Closing date", seq: 7 },
  { key: "masterlist", display: "Masterlist", seq: 8 },
  { key: "organiser", display: "Organiser", seq: 9 },
  { key: "venue_location", display: "Venue location", seq: 10 },
  { key: "wnn", display: "Wnn", seq: 11 },
  {
    key: "screeningImages",
    display: "screeningImages",
    processor: "imageAliasArray",
    seq: 12,
  },
];
