import { Text } from "@chakra-ui/react";
import React from "react";

const H4 = ({ children, ...props }) => {
  return (
    <Text
      as="h4"
      {...props}
      fontSize="16px"
      fontWeight="700"
      lineHeight={"19px"}
    >
      {children}
    </Text>
  );
};
export default React.memo(H4);
