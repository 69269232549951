import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
} from "@chakra-ui/react";
import { explore } from "../../../../constants/constants";
import { Library } from "./Library/Library";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { useNavigate, useParams } from "react-router-dom";
import Archive from "./Archive";
import All from "./All";
import { Book } from "./Library/Book";

export default function ResearchCentre({ children, rcAlias, main }) {
  const [bookFlag, setBookFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [researchCentreFlag, setResearchCentreFlag] = useState(true);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <Box bg="white">
        {children}
        <LibraryAndArchive
          rcAlias={rcAlias}
          bookFlag={bookFlag}
          setBookFlag={setBookFlag}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setResearchCentreFlag={setResearchCentreFlag}
          researchCentreFlag={researchCentreFlag}
          main={main}
        />
      </Box>
    </Box>
  );
}

const LibraryAndArchive = ({
  filmographyData,
  rcAlias,
  setResearchCentreFlag,
  setleftFlag,
  bookFlag,
  setBookFlag,
  openDrawer,
  setOpenDrawer,
  main,
  name
}) => {
  const [visible, setVisible] = React.useState(true);
  // const [bookFlag, setBookFlag] = useState(false);
  const [bookData, setBookData] = useState({});
  const [listGridIcon, setListGridIcon] = useState(true);
  const navigate = useNavigate();
  let { tabIndex, gridview } = useParams();
  const url = window.location.pathname;
  console.log(bookData);
  useEffect(() => {
    if (tabIndex) {
      navigate(`${url}`, { replace: true });
      if (parseInt(tabIndex) === 1) {
        setListGridIcon(true);
      }
      parseInt(gridview) === 1 ? setVisible(true) : setVisible(false);
    }
  }, [tabIndex, navigate, gridview, url]);

  const handleBookClick = (flag, data) => {
    setBookData(data);
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };

  return (
    <Stack maxW={"auto"} px={!main && "62px"} pt={!main && "72px"}>
      <Container minW={"100%"} pb="4" px="0px">
        <Stack>
          <HStack pb={"20px"}></HStack>
          {bookFlag ? (
            <>
              <Book bookData={bookData} />
            </>
          ) : (
            <HStack>
              <Tabs
                align={{ xs: "end", sm: "" }}
                w={"100%"}
                defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 1}
              >
                <Stack
                  flexDirection={"row"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  w={"100%"}
                >
                  <HStack bg="white" w={"100%"}>
                    <TabList
                      overflowX="auto"
                      borderBottom={"1px"}
                      borderBottomColor={"#f1f1f1"}
                      gap={12}
                      className="verticalscroll"
                    >
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(false)}
                      >
                        {explore.ALL}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.LIBRARY}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.ARCHIVE}
                      </Tab>
                    </TabList>
                  </HStack>
                  <HStack>
                    {listGridIcon && (
                      <Stack
                        flexDirection={"row"}
                        gap={0}
                        id="listgridviewbtn"
                        marginTop={"-16px !important"}
                      >
                        <HStack marginTop={"0px!important"}>
                          <IconButton
                            variant="unstyled"
                            onClick={() => setVisible(false)}
                            color={!visible ? "black" : "#989898"}
                            fontSize="20px"
                            display="flex"
                            borderTopLeftRadius="6px"
                            borderBottomLeftRadius="6px"
                            borderTopRightRadius="0px"
                            borderBottomRightRadius="0px"
                            border="1px"
                            borderColor={"#989898"}
                            height="32px"
                          >
                            <RiMenuLine ml="4" />
                          </IconButton>
                        </HStack>
                        <HStack marginTop={"0px!important"}>
                          <IconButton
                            variant="unstyled"
                            onClick={() => setVisible(true)}
                            color={visible ? "black" : "#989898"}
                            fontSize="16px"
                            display="flex"
                            borderTopLeftRadius="0px"
                            borderBottomLeftRadius="0px"
                            borderTopRightRadius="6px"
                            borderBottomRightRadius="6px"
                            border="1px"
                            borderColor={"#989898"}
                            height="32px"
                          >
                            <BsGrid />
                          </IconButton>
                        </HStack>
                      </Stack>
                    )}
                  </HStack>
                </Stack>
                <TabPanels>
                  <TabPanel px="0px">
                    <All visible={visible} />
                  </TabPanel>
                  <TabPanel px="0px">
                    <HStack minH={"60vh"}>
                      <Library
                        visible={visible}
                        handleBookClick={handleBookClick}
                        openDrawer={openDrawer}
                        setOpenDrawer={setOpenDrawer}
                        setleftFlag={setleftFlag}
                        tableName={"bibliographies"}
                        rcAlias={rcAlias}
                        main={main}
                      />
                    </HStack>
                  </TabPanel>
                  <TabPanel px="0px">
                    <Archive
                      visible={visible}
                      tableName={"artworks"}
                      rcAlias={rcAlias}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
