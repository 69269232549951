import { Box, Image, SimpleGrid, Text , Stack} from "@chakra-ui/react";
import React from "react";
import { common } from "../../../../constants/constants";

export default function ResearchImageGridList({
  data,
  handleDrawer,
  alignLeft,
  border,
}) {
  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
        spacing={{base:"5px",md:"24px"}}
      >
        { data.length > 0  ? ( data?.map((item, index) => (
            <Box height="auto">
              {item.link ? (
                <Box
                justifyItems="center"
                display="flex"
                height={"270px"}
                bg="white"
                justifyContent="space-around"
              >
                <Image
                fallbackSrc='https://via.placeholder.com/150'
                src={item?.imagePath ? item?.imagePath : item?.image}
                alt=""
                  cursor={"pointer"}
                  transition="1s ease"
                  width={"auto"}
                  objectFit={"contain"}
                  onClick={() => 
                    handleDrawer(item)
                  }
                  _hover={{transform: "scale(1.1)"}}
                />
                  </Box>
              ) : (
                <Box
              justifyItems="center"
              display="flex"
              height={"270px"}
              bg="white"
              justifyContent="space-around"
            >
                <Image
                  src={item?.imagePath ? item?.imagePath:item?.image}
                  alt=""
                  transition="1s ease"
                  width={"auto"}
                  objectFit={"contain"}
                  onClick={() => 
                    handleDrawer(item)
                  }
                />
                   </Box>
              )}
            <Text
              fontSize={"14px"}
              fontWeight={"400px"}
              lineHeight={"17px"}
              textAlign={alignLeft ? alignLeft:"center"}
              color="#035DA1"
              p="4"
            >
            {  !item.desc && <>
              {item?.creator && item?.creator.creator && (
                <>{` ${item?.creator.creator.lastname}, `}</>
              )}

              {item?.creator && item?.creator.creator && (
                <>
                  {` ${item?.creator.creator.firstname}`}
                  {item?.creator.creator.lastname !== "Alkazi" && ` | `}
                </>
              )}

              {item?.sortable_year_date_of_publication &&
              item?.creator.creator &&
              item?.creator.creator.lastname === "Alkazi" ? (
                <>{` (Ed). (${item?.sortable_year_date_of_publication}), `}</>
              ) : (
                <>{`${item?.sortable_year_date_of_publication}. `}</>
              )}
              {item?.creator &&
                item?.creator.creator &&
                item?.creator.creator.lastname === "Alkazi" && (
                  <>{`Art Heritage Annual. ${item?.location}:${item?.gallery}. `}</>
                )}
              {item?.document_type &&
              item?.document_type &&
              item?.creator.creator &&
              item?.creator.creator.lastname !== "Alkazi" ? (
                <>{`BOOK.${item?.document_type} `}</>
              ) : (
                <>{`(BOOK.${item?.document_type})`}</>
              )}
              </>}
              {
                item.desc && item.desc
              }
            </Text>
          </Box>
        ))) : (
          <Stack alignItems={"center"} height={"100vh"}>
            <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
              {common.NO_DATA}
            </Text>
          </Stack>
        )}
      </SimpleGrid>
    </>
  );
}
