//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/7.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/8.png`
const Img9 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/9.png`

const Img21 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/1.png`
const Img22 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/2.png`
const Img23 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/3.png`
const Img24 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/4.png`
const Img25 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/12.png`

const Img31 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/5.png`
const Img32 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/6.png`
const Img33 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/7.png`
const Img34 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/8.png`
const Img35 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/9.png`
const Img36 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/10.png`
const Img37 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/11.png`
const Img38 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/12.png`

const fullImg1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/fullImg1.png`
const fullImg2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Bodyofwork/Monuments/DetailPage/fullImg2.png`

export const lucknow_monuments_data = [
    {
  key:"monuments-heritage-sites",
  value:[{
    image:Img1,
    monument_name:"Bara Imambara",
    year:"1784",
    link:true,
    alias:"bara-imambara"
  },
  {
    image:Img2,
    monument_name:"The Residency",
    year:"1800",
    link:true,
    alias:"the-residency"
  },
  {
    image:Img3,
    monument_name:"La Martiniere College",
    year:"1800",
    link:false,
    alias:"la-martiniere-college"
  },
  {
    image:Img4,
    monument_name:"Chhatar Manzil",
    year:"",
    link:false,
    alias:"chhatar-manzil"
  },
  {
    image:Img5,
    monument_name:"Hussainabad Clock Tower",
    year:"1881",
    link:false,
    alias:"hussainabad-clock-tower"
  },
  {
    image:Img6,
    monument_name:"Gateway of Bara Imambara",
    year:"1785",
    link:false,
    alias:"gateway-of-bara-imambara"
  },
  {
    image:Img7,
    monument_name:"Rumi Darwaza",
    year:"1784",
    link:false,
    alias:"rumi-darwaza"
  },
  {
    image:Img8,
    monument_name:"All Saints Church,",
    year:"1913",
    link:false,
  },
  {
    image:Img9,
    monument_name:"Content Awaited",
    year:"",
    link:false,
  },
  {
    image:Img9,
    monument_name:"Content Awaited",
    year:"",
    link:false,
  },
  {
    image:Img9,
    monument_name:"Content Awaited",
    year:"",
    link:false,
  },
  {
    image:Img9,
    monument_name:"Content Awaited",
    year:"",
    link:false,
  },
],
  display:"Monuments & Heritage Sites"
    },
];

export const lucknow_bara_imamabara_data = [
  {
  name:"Bara Imambara, 1784",
  comissioned_by:"Lucknow Cantonment, Cantonment area, Lucknow",  
  architectural_material:"Centrally protected",
  architectural_style:"Nawabi Awadh Architectural Style",
  description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit.",
  gridImages:[
  {
    image:Img21,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img22,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img23,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img24,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  },{
    image:Img25,
    citation_name:"Citation Awaited",
    link:""
  }
  ],
  patron:[
    {
      list_content:"Comissioned by Nawab Asaf-ud-Daula",
    },
    {
      list_content:"Moheb-ullah and Kefait-ullah, father and son, were the architects",
    },
    ],
    structure:[
      {
        list_content:"Rectangular plan divided into nine chambers.",
      },
      {
        list_content:"The central chamber is the largest and measures 164 feet by 52 feet (50 by 16 meters) and is over 49 feet (15 meters) high.",
      },
      {
        list_content:"The eight chambers surrounding the central bay are smaller in both area and height and are more for circulatory purpose.",
      },
      {
        list_content:"The long central chamber has a concrete vault",
      },
      {
        list_content:"Remaining bays are treated in a variety of roofing techniques.",
      },
      {
        list_content:"The arched roof of the central vault is built without beams, making it one of the largest concrete shells in the world.",
      },
      ],
      layout:[
        {
          list_content:"The numerous openings, however, have made it possible to create a unique feature in the form of a labyrinth. The imambara is popular amongst its visitors for the 'Bhool Bhooliya' or labyrinth that is formed by the many balconies and passages that branch off from 489 identical doorways.",
        },
        {
          list_content:"The central chamber contains the grave of Nawab Asaf al-Daula",
        },
        {
          list_content:"Only imambara that has six entrances into the central bay as opposed to the conventional five.",
        },
        ],
        materialsUsed:[
          {
            list_content:"Brick and mortar structure.",
          },
          {
            list_content:"Finials made of green glazed ware",
          },
          ],
          event:[
            {
              list_content:"It is used for ceremonies performed by shia muslims to commemorate the death of Hussain, the grandson of Prophet Muhammad, at Karbala in 680 AD.",
            },
            ],
            relatedArtworks:[
              {
                fullImg:fullImg1,
                title:"Studio V. & E. Pont_Great Mosque inside Imambara, Lucknow (India)",
                title_of_photograph:"Great Mosque inside Imambara, Lucknow (India)",
                photographer:"Studio V. & E. Pont",
                medium:"Albumen print pasted on mount",
                date_of_photograph:"1870",
                size:"11.1 x 9.7 in (28.2 x 24.7cm;282.0 x 247.0 mm)",
                width_in_cm:"",
                height_in_inch:"",
                width_in_inch:"",
                height_in_mm:"",
                width_in_mm:"",
                inscription:"Inscribed in English in pen 'The Emambara- Lucknow' l.c.on mount.",
                location:"Hussainbad, Lucknow",
                quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
              },
              {
                fullImg:fullImg1,
                title:"Studio V. & E. Pont_Great Mosque inside Imambara, Lucknow (India)",
                title_of_photograph:"Great Mosque inside Imambara, Lucknow (India)",
                photographer:"Studio V. & E. Pont",
                medium:"Albumen print pasted on mount",
                date_of_photograph:"1870",
                size:"11.1 x 9.7 in (28.2 x 24.7cm;282.0 x 247.0 mm)",
                width_in_cm:"",
                height_in_inch:"",
                width_in_inch:"",
                height_in_mm:"",
                width_in_mm:"",
                inscription:"Inscribed in English in pen 'The Emambara- Lucknow' l.c.on mount.",
                location:"Hussainbad, Lucknow",
                quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
              },
            ],
  display:"Bara Imamabara, 1784"
  },
];


export const lucknow_the_residency_data = [
  {
  name:"The Residency",
  comissioned_by:"Qaisar Bagh, Lucknow",
  architectural_material:"Centrally protected",
  architectural_style:"Colonial Architectural Style",
  description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit.",
  gridImages:[
  {
    image:Img31,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img32,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img33,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img34,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img35,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img36,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img37,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  {
    image:Img38,
    citation_name:"Citation Awaited",
    link:""
  },
  ],
  patron:[
    {
      list_content:"Content Awaited",
    },
    ],
    structure:[
      {
        list_content:"Content Awaited",
      },
      ],
      layout:[
        {
          list_content:"Content Awaited",
        },
        ],
        materialsUsed:[
          {
            list_content:"Content Awaited",
          },
          ],
          event:[
            {
              list_content:"Content Awaited",
            },
            ],
            relatedArtworks:[
              {
                fullImg:fullImg2,
                title:"John Edward Sache_Residency Ruines, Lucknow",
                title_of_photograph:"Residency Ruins, Lucknow: Eleven views at Lucknow and elsewhere (India)",
                photographer:"John Edward Sache",
                medium:"Albumen print pasted on mount",
                date_of_photograph:"1875",
                size:"8.3 x 10.4 in (21 x 26.4cm; 210.0 x 264.0 mm)",
                width_in_cm:"",
                height_in_inch:"",
                width_in_inch:"",
                height_in_mm:"",
                width_in_mm:"",
                inscription:"Inscribed in Devanagari in pen 'Beli Garad, Lucknow'. Inscribed in English in pencil 'Lucknow Residency Ruins' and in pen 'La Martiniere, Lucknow.' on lower edge of mount.",
                location:"Qaisar Bagh, Lucknow",
                quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
              },
              {
                fullImg:fullImg2,
                title:"John Edward Sache_Residency Ruines, Lucknow",
                title_of_photograph:"Residency Ruins, Lucknow: Eleven views at Lucknow and elsewhere (India)",
                photographer:"John Edward Sache",
                medium:"Albumen print pasted on mount",
                date_of_photograph:"1875",
                size:"8.3 x 10.4 in (21 x 26.4cm; 210.0 x 264.0 mm)",
                width_in_cm:"",
                height_in_inch:"",
                width_in_inch:"",
                height_in_mm:"",
                width_in_mm:"",
                inscription:"Inscribed in Devanagari in pen 'Beli Garad, Lucknow'. Inscribed in English in pencil 'Lucknow Residency Ruins' and in pen 'La Martiniere, Lucknow.' on lower edge of mount.",
                location:"Qaisar Bagh, Lucknow",
                quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
              },
            ],
  display:"The Residency"
  },
];