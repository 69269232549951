import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { Swiper, SwiperSlide } from "swiper/react";

// Service Files
import * as getAwardsHonoursData from "../../../services/AwardsHonours";
import { CopyPasteService } from "../../../services/CopyPaste";

// Subsection Files
import ScrollToTop from "../../../components/sections/ScrollToTop";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";

function AwardsHonours({ tableName, masterlist }) {
  const [awardsHonours, setAwardsHonours] = React.useState([]);
  const [imageData, setImageData] = useState([]);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const result = await getAwardsHonoursData.awardshonoursByWinner(
        tableName,
        masterlist
      );
      if (result) {
        setAwardsHonours(citationFactor(result));
        setImageData(extractedImages(result));
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [tableName, masterlist]);

  const extractedImages = (data) => {
    let extractImg = data.flatMap((entry) => {
      const screeningImages = entry.find((item) => item.key === "awardImage");
      if (screeningImages && screeningImages.value.length > 0) {
        return screeningImages.value.flatMap((image) => image.value);
      }
      return [];
    });
    return extractImg;
  };
  const citationFactor = (data) => {
    return data.map((item) => ({
      year:
        item.find((nestedItem) => nestedItem.key === "sortable_date")?.value ||
        null,
      value: `${
        item.find((nestedItem) => nestedItem.key === "award_name")?.value
      } | ${
        item.find((nestedItem) => nestedItem.key === "awardcategory")?.value
          .length > 0
          ? `${item
              .find((nestedItem) => nestedItem.key === "awardcategory")
              ?.value.map((it) => it.value)
              .join(", ")}`
          : ""
      } | ${
        item.find((nestedItem) => nestedItem.key === "issuing_authority")?.value
      }`,
      images:
        item.find((nestedItem) => nestedItem.key === "awardImage")?.value
          .length > 0
          ? item.find((nestedItem) => nestedItem.key === "awardImage")?.value[0]
              .value
          : null,
      wnn: item.find((nestedItem) => nestedItem.key === "wnn")?.value || null,
    }));
  };
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const showFullImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };

  return (
    <Stack
      py="4"
      flexDirection={{ base: "column", md: "row" }}
      px="0px"
      width={"100%"}
      minHeight={"100vh"}
      justifyContent={{ base: "unset", md: "space-between" }}
    >
      <HStack
        flexDirection={"column"}
        alignItems={"baseline"}
        width={{ base: "100%", md: "590px" }}
        pl={"32px"}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
      >
        <Stack flexDirection={"row"}>
          <VerticalTimeline layout={"1-column-left"} className="layout">
            {awardsHonours?.map((item, index) => (
              <>
                {item.images ? (
                  <Box marginTop={"4px"} marginLeft={"-20px"} pos={"absolute"}>
                    <AiOutlinePicture
                      onClick={() => showImage(0, true)}
                      cursor={"pointer"}
                      color="#1E90FF"
                      size={16}
                    />
                  </Box>
                ) : (
                  <></>
                )}
                <VerticalTimelineElement
                  key={index}
                  id="timeline1"
                  contentStyle={{ fontSize: "sm" }}
                  iconStyle={{
                    background: "#fff",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                  icon={item.year}
                >
                  <Text className="fs-14" cursor={"pointer"}>
                    {item.value}
                  </Text>
                </VerticalTimelineElement>
              </>
            ))}
          </VerticalTimeline>
        </Stack>
      </HStack>
      <HStack
        px={"0px"}
        width={{ base: "100%", md: "400px" }}
        alignItems={"flex-start"}
        justifyContent={"flex-end"}
        paddingLeft={{ base: "0px", md: "50px" }}
      >
        <Stack
          className="col-12 mt-0"
          id="allsliderskkAward"
          position={"relative"}
        >
          {imageData.length > 0 && (
            <HStack className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
              <Swiper
                grabCursor={true}
                navigation={true}
                modules={[EffectFlip, Navigation, Pagination]}
                onSwiper={setSwiper}
              >
                {imageData.length > 0 &&
                  imageData.map((it,id) => (
                    <SwiperSlide>
                      <Box height={"352px"} width={"244px"}>
                        <Image
                          onClick={(e) => showFullImages(it, true, id)}
                          src={it}
                          cursor={"pointer"}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </HStack>
          )}
        </Stack>
      </HStack>
      <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imageData}
        imgData={imgData}
        clickedImageIndex={clickedImageIndex}
        imageHeight={{ base: "100%", md: "90vh" }}
      />
      <ScrollToTop />
    </Stack>
  );
}
export default AwardsHonours;

export const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  imageHeight,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {imageData?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                        height={"100vh"}
                      >
                        <Image
                          src={
                            item
                          }
                          height={imageHeight ? imageHeight : "100%"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.title}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};