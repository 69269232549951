import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { getHomeData } from "../../services/HomeService";
import { common } from "../../constants/constants";
import { useTranslation } from "react-i18next";

function Slider({ sliderNumber }) {
  const swiperRef = useRef(null);
  const [sliderData, setSliderData] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const { t } = useTranslation("");

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper && sliderNumber) {
      const number = parseInt(sliderNumber);
      swiperRef.current.swiper.slideTo(number);
    }
  }, [sliderNumber]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getHomeData();
      if (result?.length > 0) {
        setSliderData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container
        maxW="auto"
        pr="0px"
        pl="0px"
        className="songs"
        id="allpostsslider"
      >
        <Stack width={"100%"} id="homeslider" position={"relative"}>
          {showLabel ? (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {t(common.WEBPAGE_COMING_SOON)}
            </Text>
          ) : (
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              slidesPerView={1}
              loop={true}
              navigation
              height={"100%"}
              ref={swiperRef}
            >
              {sliderData.map((item, index) => {
                return (
                  <SwiperSlide>
                    {item?.subSlider?.length > 0 ? (
                      <Stack width={"100%"}>
                        <Swiper
                          slidesPerView={1}
                          // loop={true}
                          pagination={{
                            type: "fraction",
                          }}
                          navigation={true}
                          height={"100%"}
                          modules={[Navigation, Pagination]}
                          id="slideshowinside"
                        >
                          <SwiperSlide>
                            <HStack justifyContent="space-evenly">
                              <Image src={item.imgPath} alt="" />
                            </HStack>
                            <HStack>
                              <Box
                                className="centered bottom-center"
                                zIndex={1}
                              >
                                <Stack
                                  spacing={4}
                                  direction="row"
                                  gap={"3"}
                                  align="center"
                                >
                                  <Link className="ml-0" href="">
                                    <Button
                                      color={"black"}
                                      size={"md"}
                                      bg="#EEEEEE"
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      px="2"
                                      className="btn-on-slide"
                                    >
                                      {item.button1}
                                    </Button>
                                  </Link>
                                  <Link className="ml-0" href="">
                                    <Button
                                      color={"black"}
                                      size={"md"}
                                      bg="#EEEEEE"
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      px="2"
                                      className="btn-on-slide"
                                    >
                                      {item.button2}
                                    </Button>
                                  </Link>
                                  <Link className="ml-0" href="">
                                    <Button
                                      color={"black"}
                                      size={"md"}
                                      bg="#EEEEEE"
                                      fontSize={"14px"}
                                      fontWeight={"700"}
                                      px="2"
                                      className="btn-on-slide"
                                    >
                                      {item.button3}
                                    </Button>
                                  </Link>
                                </Stack>
                              </Box>
                            </HStack>
                          </SwiperSlide>
                          {item?.subSlider.length > 0 && (
                            <>
                              {item?.subSlider.map((subSlider, key) => (
                                <SwiperSlide key={key}>
                                  <HStack justifyContent="space-evenly">
                                    <Image
                                      src={item.subSlider[key].imgPath}
                                      alt=""
                                    />
                                  </HStack>
                                </SwiperSlide>
                              ))}
                            </>
                          )}
                        </Swiper>
                      </Stack>
                    ) : (
                      <>
                        <HStack justifyContent="space-evenly">
                          <Link
                            href={
                              item.id === 1 || item.id === 2 ? null : item.link1
                            }
                            cursor={
                              item.id === 1 || item.id === 2 ? "" : "pointer"
                            }
                          >
                            <Image src={item.imgPath} alt="" />
                          </Link>
                        </HStack>
                        {index === 0 ? (
                          <Box
                            className="bottom-right"
                            zIndex={1}
                            pt="8"
                            pr="5"
                            pb="20"
                            width={"auto"}
                            height="22%"
                            position={"absolute"}
                          >
                            <Stack spacing={2} direction="row" align="center">
                              <Link href="">
                                <Button
                                  className="btn-on-slide"
                                  color={"black"}
                                  size={"md"}
                                  bg="#EEEEEE"
                                  fontSize={"14px"}
                                  fontWeight={"700"}
                                  px="2"
                                >
                                  {item.button1}
                                </Button>
                              </Link>
                              <Link href="">
                                <Button
                                  className="btn-on-slide"
                                  color={"black"}
                                  size={"md"}
                                  bg="#EEEEEE"
                                  fontSize={"14px"}
                                  fontWeight={"700"}
                                  px="2"
                                >
                                  {item.button2}
                                </Button>
                              </Link>
                              <Link href="">
                                <Button
                                  className="btn-on-slide"
                                  color={"black"}
                                  size={"md"}
                                  bg="#EEEEEE"
                                  fontSize={"14px"}
                                  fontWeight={"700"}
                                  px="2"
                                >
                                  {item.button3}
                                </Button>
                              </Link>
                            </Stack>
                          </Box>
                        ) : (
                          <Box
                            className="top-right"
                            zIndex={1}
                            pt="8"
                            pr="8"
                            pb="16"
                            pl="16"
                            id="DivForHoverItem"
                            width={"500px"}
                            height="500px"
                          >
                            <Text
                              className="title-on-slide"
                              fontSize={"40px"}
                              fontWeight={"900"}
                              lineHeight={"54px"}
                              teststyle="secondary"
                              textAlign={"left"}
                              id="HiddenText"
                              color={"white"}
                            >
                              {item.title}
                            </Text>
                            <Text
                              fontSize={"16px"}
                              fontWeight={"400"}
                              lineHeight={"22px"}
                              teststyle="secondary"
                              textAlign={"left"}
                              py="2"
                              id="HiddenText"
                              color={"white"}
                            >
                              {item.description}
                            </Text>
                            <Stack
                              spacing={4}
                              direction="row"
                              className="btn-wrap-onmobile"
                              gap={"3"}
                              align="center"
                            >
                              <Link href={item.link2}>
                                <Button
                                  className="btn-on-slide"
                                  id="HiddenText"
                                  color={"black"}
                                  size={"md"}
                                  bg="#ffffffb5"
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                >
                                  {item.button1}
                                </Button>
                              </Link>
                              <Link className="ml-0" href={item.link3}>
                                <Button
                                  className="btn-on-slide"
                                  id="HiddenText"
                                  color={"black"}
                                  size={"md"}
                                  bg="#ffffffb5"
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                >
                                  {item.button2}
                                </Button>
                              </Link>
                              <Link className="ml-0" href={item.link4}>
                                <Button
                                  className="btn-on-slide"
                                  id="HiddenText"
                                  color={"black"}
                                  size={"md"}
                                  bg="#ffffffb5"
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                >
                                  {item.button3}
                                </Button>
                              </Link>
                            </Stack>
                          </Box>
                        )}
                      </>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </Stack>
      </Container>
    </>
  );
}
export default Slider;
