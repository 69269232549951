import axios from "axios";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_MONGODB_URL}`;

/**
 * This function return editions data
 * @returns {Promise<Object>} - Function return Editions data
 */

export const getEditionsData = async () => {
  if (isTransformationNotAllowed() === true) {
    const response= await getEditionsDummyData();
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response= await getEditionsAPIData();
    return response;
  }
};

export const getEditionsDummyData = () => {
  return;
};

export const getEditionsAPIData = async () => {
  const result = await axios.get(baseUrl + '/editions');
    if (result) {
      return result?.data?.data;
  }
};
