// Core Files
import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";

// Subsection Files
import Large from "../../../../components/layouts/Typography/Large";
import Small from "../../../../components/layouts/Typography/Small";

export default function ImageGridList({
  data,
  setDrawerOpen,
  text,
  columns,
  spacing,
  handleImageClick,
  handleImage,
  width,
  height,
  transition,
  className,
  archive,
  ...rest
}) {
  return (
    <>
      <SimpleGrid columns={columns} spacing={spacing} justifyItems={"center"}>
        {data?.map((item, index) => (
          <Box
            height="100%"
            justifyItems="center"
            justifyContent="space-around"
            width={width && width}
          >
            <Box height={height ? height : "100%"} width={width && width} className={className && className}>
              <Image
                src={item?.image}
                width={"100%"}
                height={height && "100%"}
                objectFit={"contain"}
                cursor={item.link ? "pointer" : ""}
                transition={transition && transition}
                onClick={
                  setDrawerOpen
                    ? 
                    () => {
                          (index === 0
                            ?
                            setDrawerOpen(item)
                            : archive?setDrawerOpen(item): setDrawerOpen(false));
                      }
                    : item.link && ((handleImageClick && handleImageClick) || (handleImage && handleImage(item?.alias)))
                }
              />
            </Box>
            {text && (
              <Box py="2">
                <Large
                  color="#035DA1"
                  cursor="pointer"
                  onClick={
                    setDrawerOpen
                      ? () => {
                            (index === 0
                              ?
                              setDrawerOpen(item)
                              : setDrawerOpen(false));
                        }
                      : handleImageClick
                  }
                >
                  {item?.title}
                </Large>
                {item?.subTitle && (
                  <Small
                    py="1"
                    marginInlineStart={"0.0rem !important"}
                  >{`${item?.subTitle}`}</Small>
                )}
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
}
