import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import React from "react";

export default function DrawerEducationDetails({
  educationImgData,
  showEducationImages,
  showFullEducationImages,
  closeIcon,
}) {
  return (
    <>
      {educationImgData && (
        <>
          <Box
            position={"absolute"}
            right={{base:"0",md:"400px"}}
            top={0}
            zIndex={99999}
            background={"white"}
            width={{base:"100%",md:"794px"}}
            height={"100vh"}
            display={"flex"}
          >
            <Image
              src={closeIcon}
              paddingLeft="5px"
              position="absolute"
              top={"12px"}
              right={{base:"18",md:"40px"}}
              cursor={"pointer"}
              onClick={() => showEducationImages(null, false)}
            />
            <Stack
              justifyContent={"space-evenly"}
              width={"100%"}
              alignItems={"center"}
              flexDirection={"row"}
            >
              <HStack
                justifyContent={"center"}
                position="absolute"
                top={"10%"}
                margin={"auto"}
              >
                <Image
                  src={
                    educationImgData !== null ||
                    educationImgData !== "undefined" ||
                    educationImgData !== undefined
                      ? /* "https://d3fsixjuo450s3.cloudfront.net/" + */
                        educationImgData
                      : ""
                  }
                  width={"auto"}
                  height={"100%"}
                  alt=""
                />
              </HStack>
              <HStack justifyContent="flex-end">
                <Box position={"absolute"} bottom={"10%"} right={"5%"}>
                  <Text
                    fontSize={"xl"}
                    bg={"#525252"}
                    color={"white"}
                    fontWeight={"400"}
                    padding="2"
                    borderRadius={"35px"}
                    cursor="pointer"
                    onClick={() => showFullEducationImages(true)}
                  >
                    <CgArrowsExpandRight />
                  </Text>
                </Box>
              </HStack>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
}
