
// Core files
import { SimpleGrid, Box, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination } from "swiper";

// Service Files
import * as getFilmPrefaceData from "../../../services/Film";
import { CopyPasteService } from "../../../services/CopyPaste";

// Subsection Files
import { common } from "../../../constants/constants";
import { FullScreenImage } from "../../../FunctionalComponents/FullScreenImage";
import ImageSlider from "../../../FunctionalComponents/ImageSlider/ImageSlider";
import { TextComponent } from "../../../CommonComponents/TextComponent";

function Preface({
  winnerName,
  setReadMoreFlag,
  readmoreNavigation,
  tableName,
}) {
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [bannerFullScreenImage, setBannerFullScreenImage] = useState(false);
  const [bannerImgData, setBannerImgData] = useState(null);
  const [posterFullScreenImage, setPosterFullScreenImage] = useState(false);
  const [posterImgData, setposterImgData] = useState(null);
  const [description, setDescription] = useState("");
  const [posterData, setPosterData] = useState([{}]);
  const [publicityData, setPublicityData] = useState([{}]);

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);

  const showBannerImages = (image, flag) => {
    if (image) {
      setBannerFullScreenImage(flag);
      setBannerImgData(image);
    } else {
      setBannerFullScreenImage(flag);
      setBannerImgData(null);
    }
  };

  const handleBannerImageClick = (item, index) => {
    setClickedImageIndex(index);
    setBannerFullScreenImage(true);
  };

  const showPosterImages = (image, flag) => {
    if (image) {
      setPosterFullScreenImage(flag);
      setposterImgData(image);
    } else {
      setPosterFullScreenImage(flag);
      setposterImgData(null);
    }
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setPosterFullScreenImage(true);
  };

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
      if (readmoreNavigation?.title === "poster") {
        setPosterFullScreenImage(true);
      }
      if (readmoreNavigation?.title === "banner") {
        setBannerFullScreenImage(true);
      }
    }
  }, [readmoreNavigation]);

  const createDataObject = (dataArray) => ({
    display: dataArray[0]?.display,
    value: dataArray[0]?.value,
  });

  const getDesigner = (designer) => {
    if (designer !== undefined && designer.length > 0) {
      return createDataObject(designer);
    }

    return { display: "Designer", value: "Unknown" }; // Default value if no designer is found
  };
  const citationFactor = (data) => {
    return data.map((item, index) => {
      const designer = getDesigner(
        item.poster_designer !== undefined
          ? item.poster_designer
          : item.publicity_designer !== undefined
          ? item.publicity_designer
          : item.costume_designer
      );
      const artDirector = createDataObject(item.art_director);
      const stillPhotographer = createDataObject(item.still_photographer);
      const printer = createDataObject(item.printer);
      const dateofArt = createDataObject(item.date_of_artwork);
      return {
        desc:
          `${item.name_of_publication}` +
          ` | ${dateofArt.value}` +
          ` | ${item.medium_of_artwork}` +
          ` | ${item.revised_medium}`,
        designerdesc: `${item.artist_name} | ${item.medium_of_artwork}, ${dateofArt.value}_${item.revised_medium} | ${item.wnn}`,
        imgPath: item.value,
        wnn: item.wnn,
        dateofArt,
        content: item.content !== "" ? item.content : "Content Awaited",
        desc1: item.description1 !== "" ? item.description1 : "Content Awaited",
        filmTitle: `${item.name_of_publication} (${dateofArt.value})`,
        fullImg: item.value,
        designerData: [dateofArt,designer, printer, artDirector, stillPhotographer],
      };
    });
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getFilmPrefaceData.filmPrefaceData(
        tableName,
        winnerName
      );

      if (result.length > 0) {
        setDescription(
          result.find((nestedItem) => nestedItem.key === "short_description")
            ?.value
        );

        result.map(
          (item) =>
            item.key === "posterImages" &&
            setPosterData(citationFactor(item.value))
        );

        result.map(
          (item) =>
            item.key === "publicityImages" &&
            setPublicityData(citationFactor(item.value))
        );
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Stack width={{ base: "100%", md: "1100px" }}>
        <Stack
          pb={"50px"}
        >
          <SimpleGrid
            columns={[1, null, 3]}
            spacing={{ base: "40px", md: "70px" }}
            pt={"16px"}
          >
            <Stack>
              <TextComponent
                text={description}
                fontSize={"15px"}
                fontWeight="400"
                textAlign="justify"
                onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
              />
            </Stack>
            <Stack position={"relative"} id="allsliderskkPre">
              {posterData && (
                <ImageSlider
                  sliderData={posterData}
                  handleImageClick={handleImageClick}
                  modules={[EffectFlip, Navigation, Pagination]}
                  width={{ base: "100%", md: "auto" }}
                  height={"100%"}
                />
              )}
            </Stack>
            <Stack position={"relative"} id="allsliderskkPre">
              {publicityData && (
                <ImageSlider
                  sliderData={publicityData}
                  handleImageClick={handleBannerImageClick}
                  modules={[EffectFlip, Navigation, Pagination]}
                  width={"auto"}
                  height={{ base: "100%", md: "fit-content" }}
                />
              )}
            </Stack>
          </SimpleGrid>
          <Stack py={{ base: 10, md: 20 }} alignItems={"center"}>
            <Swiper
              grabCursor={true}
              modules={[EffectFlip, Navigation]}
            >
              {[0]?.map((item, key) => {
                return (
                  <>
                    <SwiperSlide>
                      <Box
                        height={{ base: "275px", md: "480px" }}
                        width={{ base: "100%", md: "900px" }}
                        border={"1px solid #C4C4C4"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        textAlign={"center"}
                        display={"flex"}
                        bg={"#C4C4C4"}
                      >
                        <Text fontSize={"15px"} fontWeight={"700"}>
                          {common.IMAGE_AWAITED}
                        </Text>
                      </Box>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </Stack>
        </Stack>
      </Stack>
      {/* <ImagePopupModal */}
      {posterFullScreenImage && (
        <FullScreenImage
          isOpen={posterFullScreenImage}
          onClose={() => showPosterImages(null, false)}
          imageData={posterImgData}
          data={posterData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          setReadMoreFlag={setReadMoreFlag}
          bannerFlag={false}
          title="poster"
        />
      )}
      {bannerFullScreenImage && (
        <FullScreenImage
          isOpen={bannerFullScreenImage}
          onClose={() => showBannerImages(null, false)}
          imageData={bannerImgData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          setReadMoreFlag={setReadMoreFlag}
          bannerFlag={true}
          title="banner"
          data={publicityData}
        />
      )}
    </>
  );
}
export default Preface;
