import { exploreData } from "../MockData/ExploreImageData";

export const getExploreData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return exploreData;
  } else {
    return [];
  }
};
