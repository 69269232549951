import {
  Box,
  Container,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

// Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";

// Service file
import * as getMasterlistTypes from "../../services/MasterlistTypes";
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getIntroductionData from "../../services/Introduction";
import * as getPostData from "../../services/Post";
import * as getInsightsData from "../../services/Insight";

//Component file
import MobileNav from "../../components/Topheader";
import LandingPageSlider from "../../FunctionalComponents/LandingPageSlider/LandingPageSlider";
import { AgraphyLinks } from "../../FunctionalComponents/AgraphyLinks";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import { MasterListType } from "../../CommonComponents/MasterListType";
import Insights from "./Insights";
import Posts from "./Posts";

//Language file
import { explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function LandingPage() {
  const navigate = useNavigate();
  let { tabIndex, drawerId, alias } = useParams();
  const [showMasterlistType, setShowMasterlistType] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [masterlistOptions, setMasterlistOptions] = useState([]);
  const [postsCount, setPostsCount] = useState(0);
  const [insightsCount, setInsightsCount] = useState(0);
  const [landingData, setLandingData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks,setAgraphyLink]=useState([])
  const masterlistTitle = alias
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${alias}`, { replace: true });
      if (parseInt(tabIndex) === 1 || parseInt(tabIndex) === 2) {
        setShowMasterlistType(true);
      }
    }
  }, [masterlistType, tabIndex, navigate, drawerId, alias]);

  useEffect(() => {
    const getData = async () => {
      const result = await getMasterlistTypes.getMasterlistTypeOptions(
        "masterlistType",
        masterlistTitle
      );
      if (result) {
        setMasterlistOptions(result);
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        masterlistTitle
      );
      if (result) {
        setLandingData(result);
      }
    };
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "",
        masterlistTitle
      );

      if (result.length > 0) {
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setAgraphyLink([
          {
            link: `/research-categories/${result[0]?.find((item) => item?.key === "researchCategories")?.value[0]?.link}`,  // replace with the actual link
            title: result[0]?.find((item) => item?.key === "researchCategories")?.value[0]?.value,
          },
          // Need use for future use
          // {
          //   link: ``,  
          //   title: result[0]?.find((item) => item?.key === "subject")?.value,
          // },
          {
            link: ``,
            title: result[0]?.find((item) => item?.key === "masterlist_type")?.value,
          }
        ]);
      }
    };

    const getPostsCount = async () => {
      const result = await getPostData.getPostsCountByMasterlist("postTable", masterlistTitle);
      if (result) {
        setPostsCount(result);
      }
    };

    const getInsightsCount = async () => {
      const result = await getInsightsData.getInsightsCountByMasterlist("insightTable", masterlistTitle);
      if (result) {
        setInsightsCount(result);
      }
    };
    getData();
    getIntroData();
    getLandingData();
    getPostsCount();
    getInsightsCount();
  }, [masterlistTitle]);

  return (
    <SidebarWithHeader>
      <Stack width={"100%"}>
        <Container maxW={"auto"} px={"0px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt="9"
            variant="unstyled"
            defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
          >
            <HStack
              position={"fixed"}
              background={"white"}
              zIndex={9}
              paddingTop={"16px"}
              paddingBottom={"16px"}
              top={"64px"}
              width={{base:"100%", md:"container.xl"}}
              paddingX={{base:"0px", md:"130.5px"}}
            >
              <TabList
                width={{base:"100%", md:"container.xl"}}
                justify-content={"flex-end"}
                flexDirection={{base: "column", md: "row"}}
                pb={{base: "0", md: "4"}}
                alignItems={"center"}
                className="explorepage-tablist"
              >
                <Tab
                  p="0"
                  textStyle={"tertiary"}
                  fontSize={{base:"35px", md:"45px"}}
                  fontWeight={"700"}
                  className="textWrap"
                  fontStyle="normal"
                  lineHeight={{base:"55px", md:"60px"}}
                  _selected={{
                    borderBottom: "4px",
                    borderColor: "#333333",
                  }}
                  onClick={() => {
                    setInitialSlide(initialSlide + 1);
                    setShowMasterlistType(false);
                  }}
                >
                  {name}
                </Tab>
                <Tab
                  p="0"
                  marginLeft={{base :showMasterlistType ? "" : "", md:showMasterlistType ?  "32rem" : "42rem" }}
                  className="textWrap"
                  color={"#333333"}
                  textStyle={"secondary"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  _selected={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginRight:{base:"50px", md:"0px"},
                    borderBottom: "4px",
                    borderColor: "#333333",
                  }}
                  onClick={() => {
                    setInitialSlide(0);
                    // Need to confirm and remove
                    // setShowMasterlistType(true);
                  }}
                >
                  {`${explore.POSTS} (${String(postsCount).padStart(2, '0')})`}
                </Tab>

                <Text
                  className="px-2"
                  fontSize="18px"
                  align-items="center"
                  fontWeight="700"
                  display={{base:"none", md:"flex"}}
                >
                  |
                </Text>
                <Tab
                  p="0"
                  width={"max-content"}
                  color={"#333333"}
                  textStyle={"secondary"}
                  fontSize={"16px"}
                  className="textWrap"
                  fontWeight={"600"}
                  _selected={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "4px",
                    borderColor: "#333333",
                  }}
                  onClick={() => {
                    setInitialSlide(0);
                    setShowMasterlistType(false);
                  }}
                >
                  {`${explore.INSIGHTS} (${String(insightsCount).padStart(2, '0')})`}
                </Tab>
                {showMasterlistType && (
                  <MasterListType masterlistOptions={masterlistOptions} top={{base:"155px", md:"92px"}}/>
                )}
              </TabList>
            </HStack>

            <TabPanels paddingTop={{base:"160px", md:"90px"}}>
              <TabPanel px={"0"} p="0px" className="masterlistslider">
                {landingData.length > 0 && (
                  <LandingPageSlider
                    initialSlide={initialSlide}
                    data={
                      landingData &&
                      landingData.flatMap((cat) => {
                        return cat.flatMap((it) => {
                          const { key, value } = it;
                          if (key === "landing_page_title") {
                            return {
                              label: `${value}`,
                              to: `/${masterlistType}/${
                                cat.find((item) => item.key === "masterlist")
                                  .value
                              }/${
                                cat.find(
                                  (item) =>
                                    item.key === "landing_page_abbreviation"
                                ).value
                              }`,
                              image: `${
                                cat.find((item) => item.key === "artwork")?.value[0]?.value
                              }`,
                            };
                          } else {
                            return [];
                          }
                        });
                      })
                    }
                    masterListType={masterlistType}
                    className={"subleftsliderlocation"}
                  />
                )}

                {agraphyLinks.length > 0 && (<AgraphyLinks mx="-30px" links={agraphyLinks && agraphyLinks} />)}
              </TabPanel>
              <TabPanel
                    px={"0"}
                    py="0"
                    width="100%"
                  >
                    <Posts masterlist={masterlistTitle} />
                  </TabPanel>
                  <TabPanel
                    px={"0"}
                    py="0"
                    width="100%"
                  >
                    <Insights masterlist={masterlistTitle} />
                  </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { onOpen, onClose } = useDisclosure();
  const [setPage] = useState("banner");
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <MobileNav onOpen={onOpen} setPage={setPage} />
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
      handleNavigation={handleNavigation}
    />
  );
};
