import {
  Box,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { IoMdCalendar } from "react-icons/io";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";

//Service files
import { getMasterListDataByAlias } from "../../../../services/ResearchCategoryDetails";

//Component files
import LeftVmenu from "../../../../FunctionalComponents/LeftVmenu";
import MobileNav from "../../../../components/Topheader";
import { common, research_category } from "../../../../constants/constants";
import { FullScreenView } from "./FullScreenView";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function RCMasterlistDetailPage() {
  const [masterlistData, setMasterlistData] = useState([]);
  const [description, setDescription] = useState("");
  const [masterlistname, setMasterlistName] = useState("");
  const [content, setContent] = useState("");
  const { alias, masterlistAlias } = useParams();

  useEffect(() => {
    const getData = async () => {
      const result = await getMasterListDataByAlias("", alias, masterlistAlias);

      if (result) {
        setMasterlistData(result[0]?.images);
        setContent(result[0]?.content);
        setDescription(result[0]?.description);
        setMasterlistName(result[0]?.name);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [alias, masterlistAlias]);

  // full screen images
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [FullScreenImage, setFullScreenImage] = useState(false);
  const [ImgData, setImgData] = useState(null);
  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  return (
    <SidebarWithHeader>
      <Stack>
        <Container maxW="auto" className="songs">
          <Box
            position={"fixed"}
            px={{ base: "4", md: "20" }}
            py={{ base: "4", md: "8" }}
            zIndex={999}
            bg="white"
            width={{ base: "100%", md: "1310px" }}
          >
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <HStack borderBottom={"4px"} borderBottomColor={"black"}>
                <Text fontSize={"35px"} fontWeight={700} lineHeight={"46px"}>
                  {masterlistname}
                </Text>
              </HStack>
              <HStack>
                {/* To-do after reciveing the masterlist data will have to update below masterlist link */}
                <Link to="">
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    lineHeight={"20px"}
                    color={"#035DA1"}
                    cursor={"pointer"}
                  >
                    {research_category.VISIT_MASTERLIST}
                  </Text>
                </Link>
              </HStack>
            </Stack>
          </Box>
          <Stack
            marginTop={{ base: "10", md: "28" }}
            flexDirection={"column"}
            gap={4}
            justifyContent="center"
          >
            <HStack>
              <HStack alignItems={"flex-start"} px={{ base: "4", md: "20" }}>
                <Text fontSize={"16px"} marginInlineStart={"0rem!important"}>
                  {description}
                  <Link to="/research-category/read-more">
                    <Text
                      px="1"
                      as={"span"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                      color={"#035DA1"}
                      marginInlineStart={"0rem!important"}
                    >
                      {common.READ_MORE}
                    </Text>
                  </Link>
                </Text>
              </HStack>
            </HStack>
            <HStack px={{ base: "4", md: "20" }}>
              <SimpleGrid
                columns={{ base: 2, md: 3, lg: 3, xl: 3 }}
                spacing="48px"
              >
                {masterlistData.length > 0 &&
                  masterlistData?.map((it, index) => {
                    return (
                      <>
                        <Box
                          width={"100%"}
                          borderRadius={"none"}
                          justifyContent={"center"}
                          height="100%"
                        >
                          <Image
                            src={it?.img}
                            height="auto"
                            width="100%"
                            onClick={() => handleImageClick(it, index)}
                          />
                          <Text
                            py="2"
                            color={"#035DA1"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {content}
                          </Text>
                        </Box>
                      </>
                    );
                  })}
              </SimpleGrid>
            </HStack>
          </Stack>
          {FullScreenImage && <FullScreenView
            isOpen={FullScreenImage}
            onClose={() => showImages(null, false)}
            ImgData={ImgData}
            imageData={masterlistData}
            bannerFlag={true}
            title="banner"
            setReadMoreFlag={""}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
          />}
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}

const SidebarWithHeader = ({ children }) => {
  const { onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
