import React, { useEffect, useState } from "react";
import * as getEssayData from "./../../../../services/EventExhibition";
import { Container, HStack, Stack } from "@chakra-ui/react";
import { CopyPasteService } from "../../../../services/CopyPaste";
import ImageGridlist from "../../Component/ImageGridlist";
import { FullScreenImage } from "../../Component/FullScreenImage";

export default function Essay({ masterlist }) {
  const [essayData, setEssayData] = useState([{}]);

  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const createDataObject = (dataArray) => {
    const dataObject = {};
    if (dataArray.length > 0) {
      const dataItem = dataArray[0];
      for (const key in dataItem) {
        dataObject[key] = dataItem[key];
      }
    }
    return dataObject;
  };
  const citationFactor = (data) => {
    
    return data.map((item, index) => {
        const author = createDataObject(item?.author);
        const titleOfEssay = createDataObject(item?.title_of_essay);
        const year_of_publication = createDataObject(item?.year_of_publication);
      return {
        image: item?.img,
        desc: `${author?.value}, ${titleOfEssay?.value}, ${year_of_publication?.value}`,
        fullImg:item?.fullImg,
        link:item?.link,
        essayistData: [titleOfEssay, author,year_of_publication],

      };
    });
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getEssayData.essayData("essay", masterlist);
      setEssayData(citationFactor(result));
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {essayData.length > 0 && (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              <ImageGridlist
                data={essayData}
                handleImageClick={handleImageClick}
              />
            </HStack>
          )}
        </Stack>
      </Container>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={essayData.slice(0,3)}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="easayimages"
        />
      )}
    </Stack>
  );
}
