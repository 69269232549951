export const schema = [
  { key: "alias", display: "Alias", seq: 1 },
  { key: "id", display: "Id", seq: 2 },
  { key: "name", display: "Name", seq: 3 },
  { key: "abbreviation", display: "Abbreviation", seq: 4 },
  { key: "relevance_to_self_discovery_journey", display: "Type", seq: 5 },
  {
    key: "research_category_as_in_website",
    display: "Title for website",
    seq: 6,
  },
];
