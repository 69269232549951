import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
    Stack,
    Container,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  } from "@chakra-ui/react";
  
  //Core file
  import { useNavigate, useParams } from "react-router-dom";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";

  //Service file
  import * as getIntroductionData from "../../services/EventExhibition";
  import * as getLandingMasterlistData from "../../services/SectionService";
  
  //Component file
  import MobileNav from "../../components/Topheader";
  import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
  import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
  import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
  
  //Language file
  import { explore } from "../../constants/constants";
import ExhibitionCatlouge from "./BodyofWork/ExhibitionCatlouge";
  
  
  export default function BodyOFWork({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const [introData, setIntroData] = useState([]);
    const [horislinkData, setHorislinkData] = useState([]);
    const [artistDetails, setArtistDetails] = useState({});
  
    const {alias} = useParams();
    const aliasAPI = alias;
    const masterlistType="event-exhibition"
    React.useEffect(() => {
      const getIntroData = async () => {
        const result = await getIntroductionData.introductionByAlias(
          "personality",
          aliasAPI
        );
        if (result) {
          setHorislinkData([
            {
              link: `/research-categories/${
                result[0].find((item) => item?.key === "researchCategories")
                  ?.value[0].link
              }`,
              title: result[0].find((item) => item?.key === "researchCategories")
                ?.value[0]?.value,
            },
            {
              link: `/categories/indian-cinema`,
              title: result[0].find((item) => item?.key === "subject")?.value,
            },
          ]);
          setArtistDetails(
            result.reduce((acc, it) => {
              acc.name = it?.find((item) => item?.key === "name")?.value;
              acc.date_of_birth = it?.find(
                (item) => item?.key === "journal_from"
              )?.value;
              acc.date_of_death = it?.find(
                (item) => item?.key === "journal_till"
              )?.value;
              return acc;
            }, {})
          );
        }
      };
      const getLandingData = async () => {
        const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
          "sections",
          aliasAPI
        );
        if (result) {
            setIntroData(
                result.map((it) => ({
                  text: it?.find((item) => item?.key === "landing_page_title")?.value,
                  to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
                    it?.find((item) => item?.key === "landing_page_abbreviation")?.value
                  }`,
                  title:it?.find((item) => item?.key === "landing_page_abbreviation")?.value
                }))
              );
        }
      };
      getIntroData();
      getLandingData();
    }, [aliasAPI]);
  
    const [readmoreNavigation, setReadmoreNavigation] = useState({});
    return (
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <MobileNav onOpen={onOpen} />
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          height="calc(100vh - -64px)"
          readmoreNavigation={readmoreNavigation}
          setReadmoreNavigation={setReadmoreNavigation}
          introData={introData}
          aliasAPI={aliasAPI}
          horislinkData={horislinkData}
          artistDetails={artistDetails}
          masterlistType={masterlistType}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              readmoreNavigation={readmoreNavigation}
              setReadmoreNavigation={setReadmoreNavigation}
              aliasAPI={aliasAPI}
              horislinkData={horislinkData}
              artistDetails={artistDetails}
              introData={introData}
              masterlistType={masterlistType}
            />
          </DrawerContent>
        </Drawer>
  
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
         {introData? <BodyOfWorkBody
            winnerName={aliasAPI}
            setReadmoreNavigation={setReadmoreNavigation}
            readmoreNavigation={readmoreNavigation}
          />
        :
        (
         <WebpageComingSoon />
        )
        }
        </Box>
      </Box>
    );
  }
  
  const SidebarContent = ({
    winnerName,
    onClose,
    aliasAPI,
    horislinkData,
    artistDetails,
    introData,
    masterlistType,
    ...rest
  }) => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(-1);
      }
      const oSections = [
        {
          to: `/${masterlistType}/${aliasAPI}/1`,
          title: "",
          text: explore.POSTS,
        },
        {
          to: `/${masterlistType}/${aliasAPI}/2`,
          title: "",
          text: explore.INSIGHTS,
        },
      ];
    return (
      <>
        <BottomMainMenu />
        <CommonLeftSidebar
          sections={introData}
          oSections={oSections}
          onClose={onClose}
          hMenu={horislinkData}
          artistDetails={artistDetails}
          leftFlag={true}
          handleNavigation={handleNavigation}
        />
      </>
    );
  };
  
  const BodyOfWorkBody = ({
    winnerName,
    setReadmoreNavigation,
    readmoreNavigation,
    hidden,
    setHidden,
    tableName,
    name,
  }) => {
    const navigate = useNavigate();
    let { tabIndex } = useParams();
    const {alias} = useParams();
    const aliasAPI = alias;
    const masterlistType = "object-book-ssb"
    const { t } = useTranslation("");

    useEffect(() => {
      if (tabIndex) {
        navigate(`/${masterlistType}/${aliasAPI}/body-of-work`, { replace: true });
      }
    }, [tabIndex, navigate,aliasAPI]);
    return (
      <Stack width={"100%"}>
          <Container
            maxW={"auto"}
            pb="4"
            paddingX={{ base: "4", md: "0px" }}
            paddingLeft={{ base: "0px", md: "84px" }}
          >
            <Stack width={"100%"}>
                <Tabs
                  align={{ xs: "end", sm: "" }}
                  w="100%"
                  defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
                >
                  <Box
                    position={"fixed"}
                    padding={{ base: "60px 0px", sm: "80px 0px", md: "30px 0px" }}
                    zIndex={999}
                    bg="white"
                    width={{ base: "100%", md: "1106px" }}
                  >
                    <TabList
                      overflowX="auto"
                      borderBottom={"none"}
                      borderBottomColor={"#f1f1f1"}
                      gap={12}
                      className="verticalscroll"
                    >
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                      >
                        {t(explore.EXHIBITION_CATALOGUE)}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                      >
                        {t(explore.MEDIA_REPORTAGE)}
                      </Tab>
                    </TabList>
                  </Box>
                  <TabPanels
                    paddingTop={{ base: "65px", sm: "85px", md: "70px" }}
                  >
                    <TabPanel px="0px">
                      <ExhibitionCatlouge
                        winnerName={winnerName}
                      />
                    </TabPanel>
                    <TabPanel px="0px">
                    Media Reportage
                    </TabPanel>
                  </TabPanels>
                </Tabs>
            </Stack>
          </Container>
      </Stack>
    );
  };
  