import {
  Box,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//Service file
import * as getPostData from "../../../../services/Post";

//Component file
import Layout from "../../../../components/sections/SidebarLayout";

//Language file
import { common, research_category } from "../../../../constants/constants";

function RCPosts() {
  const [postsData, setPostsData] = useState([]);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const rcAlias = parts[2];
  const navigate = useNavigate();
  const url = currentEndpoint.lastIndexOf("/");
  const mainUrl = window.location.pathname.slice(0, url);
  useEffect(() => {
    const getData = async () => {
      const result = await getPostData.getPostsByResearchCategoryAlias(
        "posts",
        rcAlias
      );
      if (result) {
        setPostsData(result);
      }
    };
    getData();
    window.scrollTo(0,0)
  }, [rcAlias]);
  return (
    <Layout>
      <Box
          position={"fixed"}
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "4" }}
          zIndex={999}
          bg="white"
          width={"1344px"}
        >
          <Tabs variant={"unstyled"} display={"flex"} align="end" pos={"none"} alignItems={"center"} justifyContent={"space-between"} defaultIndex={-1}>
            <Box>
            <Text
            borderBottom={"4px"}
            borderBottomColor={"black"}
            fontSize={{ base: "25px", md: "30px" }}
            fontWeight={700}
            >
              {research_category.POSTS_TAB_TITLE}
            </Text>
            </Box>
            <TabList gap={"25px"}>
              <Tab
                onClick={() => navigate(`${mainUrl}/Introduction`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INTRODUCTION_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.CHRONOLOGY_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.MASTERLISTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.POSTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INSIGHTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToResearchCentre`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.RESEARCH_CENTRE_TAB_TITLE}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
      {postsData?.length>0 ? (
        <Stack  height={"100%"} background={"white"}>
          <Container
            maxW="auto"
            pr="0px"
            pl="0px"
            height={"100%"}
            marginTop={{ base: "10", md: "24" }}
            // className="songs exploretabs"
            // id="allpostsslider"
          >
            <Stack width={"100%"} position={"relative"} id="allsliderskkObject">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                slidesPerView={1}
                // loop={true}
                navigation
                height={"100%"}
                className="subslider"
              >
                {postsData.length > 0 &&
                  postsData.map((it, index) => (
                    <SwiperSlide>
                      <Box
                        width={"auto"}
                        height={{ base: "auto", md: "570px" }}
                        className="Bgimage"
                      >
                        <Link to={`${currentEndpoint}/${it?.alias}`}>
                          <Image
                            src={it?.fullImage}
                            width={"auto"}
                            height={"100%"}
                            alt=""
                          />
                        </Link>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
          </Container>
          <Container maxW="auto" marginTop={{ base: "0px", md: "40px" }}>
            <Stack className="exploretabs">
              <SimpleGrid columns={[1, null, 3]} w={"auto"} spacing="30px">
                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                  return (
                    <Box height="auto" key={index}>
                      <Link to="">
                        <Box
                          height="272px"
                          width={"auto"}
                          bg={"#C4C4C4"}
                          display="flex"
                        ></Box>
                      </Link>
                      <Stack pb="8" mt="8px">
                        <HStack>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color="#035DA1"
                          >
                            {common.WEBPAGE_COMING_SOON}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color="#333333"
                          >
                            {common.WEBPAGE_COMING_SOON}
                          </Text>
                        </HStack>
                      </Stack>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Stack>
          </Container>
        </Stack>
      ) : (
        <Text py={"20"} fontSize={"20px"} fontWeight="600" textAlign="center">
          {common.WEBPAGE_COMING_SOON}
        </Text>
      )}
    </Layout>
  );
}
export default RCPosts;
