import { 
  Accordion, 
  AccordionButton, 
  AccordionIcon, 
  AccordionItem, 
  AccordionPanel, 
  Box, 
  Button, 
  HStack, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Stack, 
  Tab, 
  TabList, 
  Tabs, 
  Text 
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import { getChronologyByAlias } from "../../../services/ResearchCategoryDetails";

//Component file
import Small from "../../../components/layouts/Typography/Small";
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import { FilterButton } from "../../../CommonComponents/FilterButton";

//Language file
import { button, common, explore, research_category } from "../../../constants/constants";

function Chronology({ main }) {
  const [chronologyData, setChronologyData] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);

  const { alias } = useParams();
  const navigate = useNavigate();
  const url = window.location.pathname.lastIndexOf("/");
  const mainUrl = window.location.pathname.slice(0, url);
  useEffect(() => {
    const getData = async () => {
      const result = await getChronologyByAlias("", alias);
      if (result) {
        if (main) {
          setChronologyData(result?.slice(0, 8));
        } else {
          setChronologyData(result);
        }
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  return (
    <>
      {!main && (
        <Box
          position={"fixed"}
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "4" }}
          zIndex={999}
          bg="white"
          width={"1344px"}
        >
          <Tabs variant={"unstyled"} display={"flex"} align="end" pos={"none"} alignItems={"center"} justifyContent={"space-between"} defaultIndex={-1}>
            <Box>
            <Text
              borderBottom={"4px"}
              borderBottomColor={"black"}
              fontSize={{ base: "25px", md: "30px" }}
              fontWeight={700}
            >
              {research_category.CHRONOLOGY_TAB_TITLE}
            </Text>
            </Box>
            <TabList gap={"25px"}>
              <Tab
                onClick={() => navigate(`${mainUrl}/Introduction`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INTRODUCTION_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.CHRONOLOGY_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.MASTERLISTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.POSTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INSIGHTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToResearchCentre`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.RESEARCH_CENTRE_TAB_TITLE}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
      )}
      <Stack flexDirection={"row-reverse"}>
      <FilterButton paddingTop={"8"} onClick={handleFilterClick} />
      <Stack
        marginTop={!main && { base: "10", md: "20" }}
        flexDirection={"row"}
        gap={4}
      >
        <HStack flexDirection={"column"} px={!main && { base: "4", md: "16" }} pt={!main &&"20"}>
          {chronologyData.length > 0 &&
            chronologyData?.map((it, index) => (
              <VerticalTimeline layout={"1-column-left"} className="layout">
                <VerticalTimelineElement
                  id="timeline2"
                  className="exhibition-timeline"
                  date={`${it?.place},${it?.country}`}
                  iconStyle={{
                    background: "#fff",
                    color: "#333",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                  icon={
                    <>
                      <Text fontSize={"16px"} fontWeight={"600"}>
                        {`${it?.sortable_year}`}
                      </Text>
                      <Text fontSize={"11px"} fontWeight={"600"}>
                        {`${it?.sortable_date}`}
                      </Text>
                    </>
                  }
                >
                  <Small color="#035DA1">
                    {`${it?.title},${it?.subtitle},${it?.description}`}
                  </Small>
                </VerticalTimelineElement>
              </VerticalTimeline>
            ))}
        </HStack>
        <HStack
              className="mt-0"
              marginRight={"-15px!important"}
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterArtHeritage" marginRight={"12px"}>
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold as="u" color={"black"} cursor="pointer">
                        {button.CLEAR_ALL}
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {research_category.YEAR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {research_category.MONTH}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {research_category.PERSONALITIES}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                           {research_category.INSTITUTIONS}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                           {research_category.EVENTS}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <AccordionPanel pb={4} className="px-1">
                          <Small color="#035DA1">
                            {common.CONTENT_AWAITED}
                          </Small>
                        </AccordionPanel>
                      </AccordionPanel>
                    </AccordionItem>
                    <HStack marginTop={"10px"}>
                      <Button
                        color={"#ffffff"}
                        width={"199px"}
                        height={"36px"}
                        bg={"#000000"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        variant={"unstyled"}
                      >
                        {explore.APPLY}
                      </Button>
                    </HStack>
                  </Accordion>
                </Box>
              )}
            </HStack>
      </Stack>
      </Stack>
    </>
  );
}
export default Chronology;
