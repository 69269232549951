import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useRef, useState } from "react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";

//Component file
import Small from "../../../../../components/layouts/Typography/Small";

//Language file
import { common } from "../../../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/book1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/book2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/book3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/book4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/thumbnail1.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/thumbnail2.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/thumbnail3.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/thumbnail4.png`



export const Book = ({ bookData }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAuthor, setIsExpandedAuthor] = useState(false);
  const data = [
    { image: Img1, desc: "" },
    { image: Img2, desc: "" },
    { image: Img3, desc: "" },
    { image: Img4, desc: "" },
    { image: Img5, desc: "" },
    { image: Img6, desc: "" },
    { image: Img7, desc: "" },
    { image: Img8, desc: "" },
  ];
  const [selectedImg, setSelectedImg] = useState({
    image: data[0].image,
    desc: "",
    index: 0,
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleExpandAuthor = () => {
    setIsExpandedAuthor(!isExpandedAuthor);
  };

  let bookText ="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

  const authorText ="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

  const contentsData = [
  ];
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };
  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    const activeIndex = selectedImg.index + 1;
    const nextSlide = data[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    const activeIndex = selectedImg.index - 1;
    const nextSlide = data[activeIndex];
    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
      });
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ImagePopupModal
        isOpen={synopsisFullScreenImage}
        onClose={() => showSynopsisImages(null, false)}
        imageData={synopsisImgData}
        data={data}
        clickedImageIndex={clickedImageIndex}
      />
      <Stack
        flexDirection={{base:"column",md:"row"}}
        justifyContent={"space-between"}
        py={{base:"40px",md:"0px"}}
        gap={"20px"}
        width={{base:"100%",md:"1140px"}}
      >
        <HStack
          alignItems={"center"}
          flexDirection={"column"}
          width={{base:"100%",md:"567px"}}
          id="allsliderskkBook"
        >
          <Box width={"100%"} height={{base:"100%",md:"450px"}}>
          <Image
            src={selectedImg.image}
            width={"auto"}
            height={"inherit"}
           
            onClick={() =>
              showSynopsisImages(selectedImg.image, true, selectedImg.index)
            }
          ></Image></Box>
          <Stack width={"100%"} position={"relative"}  id="allsliderskkBook">
          <Swiper
            ref={swiperRef}
            grabCursor={true}
            navigation={true}
            modules={[EffectFade, Navigation, Pagination]}
            style={{ marginTop: "30px" }}
            slidesPerView={4}
            slidesPerGroup={1}
            onSlideNextTransitionEnd={() => {
              handleNextButtonClick();
            }}
            onSlidePrevTransitionEnd={() => {
              handlePreviousButtonClick();
            }}
          >
            {data?.length !== 0 &&
              data?.map((item, index) => {
                return (
                  <SwiperSlide>
                    <Stack position={"relative"} className="mt-0">
                      <Image
                        height={"90px"}
                        width={"auto"}
                        src={item.image}
                        onClick={() => {
                          swiperRef.current.swiper.slideTo(
                            swiperRef.current.swiper.activeIndex
                          );
                          setSelectedImg({
                            image: item.image,
                            desc: item.desc,
                            index: index,
                          });
                        }}
                      />
                      {swiperRef !== null && index !== selectedImg.index && (
                        <Stack
                          className="mt-0"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: "21px",
                            width: "60px",
                            height: "105px",
                            background: "rgba(0, 0, 0, 0.8)",
                          }}
                          onClick={() => {
                            swiperRef.current.swiper.slideTo(
                              swiperRef.current.swiper.activeIndex
                            );
                            setSelectedImg({
                              image: item.image,
                              desc: item.desc,
                              index: index,
                            });
                          }}
                        />
                      )}
                    </Stack>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          </Stack>
        </HStack>
        <HStack   flexDirection={"column"} height={"100%"} width={"100%"} px={{base:"4",md:"8"}} alignItems={"start"}>
          <Text
            fontSize={"20px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            color="#333333"
          >
            Henri Cartier Bresson. China in Transition, 1956 </Text>
          <Box width={{base:"100%",md:"392px"}} py={5}>
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                     {common.CONTENTS}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Box maxH="160px" overflowY="auto">
                  <AccordionPanel pb={4} className="px-1">
                    {contentsData.length>0 ?contentsData.map((item) => {
                      return (
                        <>
                          <Text fontSize={"14px"} pt={"4px"} pb={"4px"} px={5}>
                            {item.title}
                          </Text>
                          <Divider color="#909090" />
                        </>
                      );
                    }):
                    <Small px={5}>{common.CONTENT_AWAITED}</Small>
                    }
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.INDEX}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} className="px-1">
                  <Small px={5}>{common.CONTENT_AWAITED}</Small>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Container px="0px">
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_BOOK}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpanded ? bookText : `${bookText.slice(0, 360)}`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpand}
              >
                {isExpanded ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
          <Container px="0px" py={5}>
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_AUTHOR}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpandedAuthor ? authorText : `${authorText.slice(0, 263)}`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpandAuthor}
              >
                {isExpandedAuthor ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
        </HStack>
      </Stack>
    </>
  );
};

const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
      zIndex={"9999"}
    >
      <ModalOverlay />
      <ModalContent  overflowY="hidden" alignItems="center">
        <ModalBody >
          <Box bg="white" width={{base:"350px",md:"1250px"}}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                      >
                        <Box width={"auto"} height={{base:"100%",md:"90vh"}}>
                        <Image src={item.image} width={"auto"} height={"inherit"} alt="" />
                        </Box>
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={400}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.desc}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right={{base:"95%",md:"15%"}}
                        top={{base:"85%",md:"95%"}}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"50px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={"0.6"}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          transform={""}
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
