import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file 
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";

//Component file
import H2 from "../../../../../../components/layouts/Typography/H2";
import Small from "../../../../../../components/layouts/Typography/Small";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function ResearchCentreDrawer({
  data,
  openDrawer,
  setOpenDrawer,
  handleClose,
  handleBookClick,
  handleContentClicked,
  scanClicked,
  contentClicked,
  setSwiper,
  bibliographyObject,
  indexValue,
  photoGraphy
}) {
  return (
    <>
      <Drawer
        isOpen={openDrawer}
        placement="right"
        onClose={(e) => setOpenDrawer(false)}
        id="rightsidebarsmall"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack
              mt={"12px"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Stack>
                <HStack>
                  {data?.header?.Image &&<Image
                    width={"265px"}
                    height={"auto"}
                    src={data?.header?.Image}
                  />}
                  <H2>{data?.header?.text}</H2>
                </HStack>
                <H2 className="mt-0">{data?.header?.desc}</H2>
              </Stack>
              <HStack>
                <Image
                  src={closeIcon}
                  paddingLeft="0px"
                  cursor={"pointer"}
                  width={"26px"}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </HStack>
            </HStack>
          </DrawerHeader>

          <DrawerBody px={"5"} overflowY={"hidden"}>
            <Stack position={"relative"} id="allsliderskkArchive">
              <Swiper
                effect={"fade"}
                grabCursor={true}
                navigation={true}
                modules={[EffectFade, Navigation]}
                style={{ marginTop: "30px" }}
                onSwiper={setSwiper}
              >
                {data?.body?.sliderImage &&
                  data?.body?.sliderImage.map((it) => (
                    <SwiperSlide>
                      {" "}
                      <Image
                        src={it}
                        onClick={() =>
                          handleBookClick(true, bibliographyObject)
                        }
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
            <HStack pt={"44px"}>
              <Small className="autherlink">
                {data?.body?.details &&
                  data?.body?.details.map((it) => (
                    <>
                      {it.key}
                      <Link to={it.link} color="#035DA1">
                        {it.text}{" "}
                      </Link>
                    </>
                  ))}
              </Small>
            </HStack>
          </DrawerBody>
          <DrawerFooter justifyContent={"flex-start"}>
            <Stack justifyContent={"flex-start"}>
              {data?.footer &&
                data?.footer.map((it, index) => (
                  <HStack>
                    <Text
                      fontSize="12px"
                      className="autherlink"
                      color="#035DA1"
                      lineHeight={"15px"}
                      // Need for future use
                      // onClick={(e) => {
                      //   handleContentClicked(index, it.text);
                      // }}
                      fontWeight={
                        (contentClicked || scanClicked) && indexValue === index
                          ? "700"
                          : "400"
                      }
                    >
                      {it.text}
                    </Text>
                  </HStack>
                ))}
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ResearchCentreDrawer;
