// Core files
import React from "react";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

function All() {
  return (
    <>
    <WebpageComingSoon />
    </>
  );
}

export default All;
