//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const pplImg = `${IMAGE_BASE_PATH}/ui/Chevron/people.png`;

const auction1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/1.jpg`
const auction2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/2.jpg`
const auction3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/3.jpg`
const auction4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/4.png`
const auction5 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/5.png`
const auction6 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/6.png`
const auction7 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/7.png`
const auction8 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/8.png`
const fullauction1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/EconomicsofArt/TopAuctionSales/full1.png`

const image1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/1.png`
const image2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/2.png`
const image3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/3.png`
const image4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/4.png`
const book1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/DetailPage/Book1.png`
const book2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/DetailPage/Book2.png`

const SHCatalougeimage1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/1.png`
const SHCatalougeimage2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/2.png`
const SHCatalougeimage3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/3.png`
const SHCatalougeimage4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/4.png`
const SHCatalougeimage5 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/5.png`
const SHCatalougeimage6 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/6.png`
const SHCatalougeimage7 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/7.png`
const SHCatalougeimage8 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Catalouge/8.png`

const SHExhibitimage1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Exhibits/1.png`
const SHExhibitimage2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Exhibits/2.png`
const SHExhibitimage3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Exhibits/3.png`
const SHExhibitimagefull1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Exhibits/full1.png`
const SHExhibitimagefull2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Exhibits/full2.png`
const SHExhibitimagefull3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/Exhibitions/Exhibits/full3.png`


const KGEssayimg1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Essay/1.png`
const KGEssayimg2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Essay/2.png`
const KGEssayimg3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Essay/3.png`
const KGEssayfullimg1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Essay/FullImageView/1.png`
const KGEssayfullimg2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Essay/FullImageView/2.png`
const KGEssayfullimg3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Essay/FullImageView/3.png`

const KGIllustratedBook1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Publications/IllustratedBooks/1.png`
const KGIllustratedBook2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Publications/IllustratedBooks/2.jpg`
const KGIllustratedBook3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Publications/IllustratedBooks/3.png`
const KGIllustratedBook4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Publications/IllustratedBooks/4.png`

const KGIAuthoredEditedBook1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Publications/AuthoredEditedBooks/1.png`
const KGIAuthoredEditedBook2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Scholarship/Publications/AuthoredEditedBooks/2.png`

const KGCatalougeimage1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Exhibitions/Catalouge/1.png`
const KGExhibitimage1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Exhibitions/Exhibit/1.png`
const KGExhibitimage2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Exhibitions/Exhibit/2.png`
const KGExhibitimage3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Exhibitions/Exhibit/3.png`
const KGExhibitimage4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Exhibitions/Exhibit/4.png`
const KGExhibitimagefull1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/Exhibitions/Exhibit/full1.jpg`

const KGauction1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/EconomicsofArt/TopAuctionSales/1.png`
const KGauction2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/EconomicsofArt/TopAuctionSales/2.png`
const KGauction3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/EconomicsofArt/TopAuctionSales/3.png`
const fullKGauction1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/EconomicsofArt/TopAuctionSales/full1.png`

const KGRCimage1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/ResearchCentre/1.jpg`
const KGRCimage2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/ResearchCentre/2.png`
const KGRCimage3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/ResearchCentre/3.png`
const KGbook1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/ResearchCentre/Book/book1.png`

export const sh_raza_exhibitions_data = [
  {
    year: "1943",
    exhibition: "Two Watercolours",
    exhibition_centre:
      "Bombay Street Scene & Parsi Fire Temple, Bombar Art Society",
    exhibit_date: "November 1943",
    city: "Bombay",
    country: "India",
    img: pplImg,
    link: true,

  },
  {
    year: "1947",
    exhibition: "Landscape in Watercolour",
    exhibition_centre: "Bombar Art Society",
    exhibit_date: "29th November 1947 to 1948",
    city: "Bombay",
    country: "India",
  },
  {
    year: "1948",
    exhibition: "Kashmir Series",
    exhibition_centre: "K.A. Abbas's Houseboat",
    exhibit_date: "June-July-August 1948",
    city: "Srinagar",
    country: "India",
  },
  {
    year: "1948",
    exhibition: "100 Kashmir Paintings",
    exhibition_centre: "Exhibition Hall, Parliament Street",
    exhibit_date: "1st to 6th September 1948",
    city: "Delhi",
    country: "India",
  },
  {
    year: "1948",
    exhibition: "Kashmir Paintings Exhibition",
    exhibition_centre: "Bombar Art Society",
    exhibit_date: "Opens: Tuesday 19th October 1948",
    city: "Bombay",
    country: "India",
  },
  {
    year: "1949",
    exhibition: "P.A.G. Inaugral Exhibition",
    exhibition_centre: "Baroda Museum, Picture Gallery, Baroda Museum",
    exhibit_date: "21st February to 21st March 1949",
    city: "Baroda & Ahmedabad",
    country: "India",
    img: pplImg,
  },
  {
    year: "1949",
    exhibition: "P.A.G. Bombay Exhibition",
    exhibition_centre: "Bombar Art Society",
    exhibit_date: "8th to 13th July 1949",
    city: "Bombay",
    country: "India",
    img: pplImg,
  },
  {
    year: "1949",
    exhibition: "Scholarship Competition Exhibition",
    exhibition_centre: "Government School of Art, Calcutta",
    exhibit_date: "Calcutta",
    city: "India",
    img: pplImg,
  },
  {
    year: "1950",
    exhibition: "S.H. Raza: Farewell Exhibition of Paintings",
    exhibition_centre: "Charles Petras Institute of Foreign Languages (IFL)",
    exhibit_date: "14th to 19th September 1950",
    city: "Bombay",
    country: "India",
  },
  {
    year: "1952",
    exhibition: "First Solo Exhibition",
    exhibition_centre: "Palais Carnoles",
    exhibit_date: "1952",
    city: "Menton",
    country: "France",
  },
  {
    year: "1953",
    exhibition: "Group Exhibition",
    exhibition_centre: "Galerie Raymond Creuze",
    exhibit_date: "26th February to 12th March 1953",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1953",
    exhibition: "Galerie Raymond Creuze",
    exhibition_centre: "3rd to 16th November 1953",
    exhibit_date: "3rd to 16th November 1953",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1955",
    exhibition: "Huit Peintres Indiens a Paris",
    exhibition_centre: "Centre Latin",
    exhibit_date: "14th to 31st March 1955",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1955",
    exhibition: "Galerie Lara Vincy",
    exhibition_centre: "20th September to 6th October 1955",
    exhibit_date: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1955",
    exhibition: "Discoveries: Americans/Europeans",
    exhibition_centre: "Serigraph Galleries",
    exhibit_date: "25th January to 7th March 1955",
    city: "New York",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1956",
    exhibition: "Galerie Lara Vincy",
    exhibition_centre: "14th May to 5th June 1956",
    exhibit_date: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1956",
    exhibition: "28th Biennale Internazionnale d'Arte di Venezia",
    exhibition_centre: "Venice",
    exhibit_date: "Italy",
    img: pplImg,
  },
  {
    year: "1956",
    exhibition: "Les Arts en France et dans Le Monde",
    exhibition_centre: "Musee d'Art Moderne",
    exhibit_date: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1956",
    exhibition: "Saint-Germain-des-Prés vu par Vingt Peintures",
    exhibition_centre: "Galerie Barbizon",
    exhibit_date: "6th to 31st December 1956",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1956",
    exhibition: "Galerie Saint-Placide",
    exhibition_centre: "October 1956",
    city: "Paris",
    country: "France",
  },
  {
    year: "1957",
    exhibition: "Biennale 57",
    exhibition_centre: "Journal Asahi, Tokyo, Pavillon de Marsan",
    exhibit_date: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1958",
    exhibition: "Zwemmer Gallery",
    exhibition_centre: "June to July 1958",
    city: "London",
    country: "UK",
    img: pplImg,
  },
  {
    year: "1958",
    exhibition: "Despierre - Raza & Zendel",
    exhibition_centre: "Galerie Dresdnere",
    exhibit_date: "May to June 1958",
    city: "Montreal",
    country: "Canada",
    img: pplImg,
  },
  {
    year: "1958",
    exhibition: "Biennale de Bruges",
    exhibition_centre: "Bruges",
    country: "Belgium",
    img: pplImg,
  },
  {
    year: "1958",
    exhibition: "Biennale Sao Paulo",
    exhibition_centre: "Sao Paulo",
    country: "Brazil",
    img: pplImg,
  },
  {
    year: "1958",
    exhibition: "Peintures et Gouaches",
    exhibition_centre: "Galerie Lara Vincy",
    exhibit_date: "18th April to 14th May 1958",
    city: "Paris",
    country: "France",
  },
  {
    year: "1959",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Graham Gallery",
    exhibit_date: "10th to 26th February 1959",
    city: "New York",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1959",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "American University",
    exhibit_date: "8th to 29th March 1959",
    city: "Washington D.C.",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1959",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "West Virginia Institute of Technology",
    exhibit_date: "7th to 28th April 1959",
    city: "West Virginia",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1959",
    exhibition: "Jehangir Art Gallery",
    exhibition_centre: "April 1959",
    city: "Bombay",
    country: "India",
  },
  {
    year: "1959",
    exhibition: "Raza: Peintures et Gouaches",
    exhibition_centre: "Galerie Dresdnere",
    exhibit_date: "5th to 19th May 1959",
    city: "Montreal",
    country: "Canada",
  },
  {
    year: "1959",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Speed Museum",
    exhibit_date: "5th to 25th July 1959",
    city: "Kentucky",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1959",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Dorothy Yepez Gallery",
    exhibit_date: "25th August to 5th September",
    city: "New York",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1959",
    exhibition: "Gallery 59",
    exhibition_centre: "1959",
    city: "Bombay",
    country: "India",
    img: pplImg,
  },
  {
    year: "1959",
    exhibition: "All India Fine Arts and Crafts Society",
    exhibition_centre: "1959",
    city: "Delhi",
    country: "India",
  },
  {
    year: "1960",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Chatham College",
    exhibit_date: "12th April to 3rd May 1960",
    city: "Pennsylvania",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1960",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Denison College",
    exhibit_date: "3rd to 23rd March 1960",
    city: "Ohio",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1960",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Western Michigan University",
    exhibit_date: "4th to 25th January 1960",
    city: "Michigan",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1960",
    exhibition: "Trends in Contemporary Painting from India",
    exhibition_centre: "Eastern Tennessee State College",
    exhibit_date: "6th to 27th February 1960",
    city: "Tennesse",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1960",
    exhibition: "Modern Indian Art 1960",
    exhibition_centre: "Santiago",
    country: "Mexico",
    img: pplImg,
  },
  {
    year: "1960",
    exhibition: "École de Paris",
    exhibition_centre: "Galerie Charpentier",
    exhibit_date: "1960",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1960",
    exhibition: "Galerie Dresdnere",
    exhibition_centre: "1960",
    city: "Montreal",
    country: "Canada",
  },
  {
    year: "1961",
    exhibition: "École de Paris",
    exhibition_centre: "Galerie Charpentier",
    exhibit_date: "1961",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1961",
    exhibition: "Galerie Semiha Huber",
    exhibition_centre: "1961",
    city: "Zurich",
    country: "Switzerland",
    img: pplImg,
  },
  {
    year: "1961",
    exhibition: "Galerie Lara Vincy",
    exhibition_centre: "19th April to 18th May 1961",
    city: "Paris",
    country: "France",
  },
  {
    year: "1961",
    exhibition: "Aquarelles et Gouaches de Maitres Contemporains",
    exhibition_centre:
      "L'union des Arts Plastiques, Maison de la Pensée Francaise",
    exhibit_date: "21st December 1961 to 21st January 1962",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "École de Paris",
    exhibition_centre: "Galerie Charpentier",
    exhibit_date: "1962",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "8th National Exhibition of Art",
    exhibition_centre: "Lalit Kala Akademi, Rabindra Bhawan Galleries",
    exhibit_date: "1962",
    city: "New Delhi",
    country: "India",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "Commonwealth Exhibition",
    exhibition_centre: "Commonwealth Institute",
    exhibit_date: "1962",
    city: "London",
    country: "UK",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "Gallery 63",
    exhibition_centre: "1962",
    city: "New York",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "Salon Comparaisons",
    exhibition_centre: "1962",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "Berkeley University",
    exhibition_centre: "Worth Ryder Art Gallery",
    exhibit_date: "1962",
    city: "California",
    img: pplImg,
    country: "USA",
  },
  {
    year: "1962",
    exhibition: "Werke der École de Paris",
    exhibition_centre: "Dom Galerie",
    exhibit_date: "7th December 1962 to January 1963",
    city: "Cologne",
    country: "Germany",
    img: pplImg,
  },
  {
    year: "1963",
    exhibition: "Salon Grands et Jeunes d' Aujourd' hui",
    exhibition_centre: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1963",
    exhibition: "Salon Comparaisons",
    exhibition_centre: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1963",
    exhibition: "Biennale du Maroc",
    exhibition_centre: "Rabat",
    country: "Morocco",
    img: pplImg,
  },
  {
    year: "1962",
    exhibition: "Dom Gallery",
    exhibition_centre: "June to July 1963",
    city: "Cologne",
    country: "Germany",
  },
  {
    year: "1965",
    exhibition: "Promises Unbroken",
    exhibition_centre: "Musée Galleria",
    exhibit_date: "9th September to 11th October 1965",
    city: "Paris",
    country: "France",
    img: pplImg,
  },
  {
    year: "1965",
    exhibition: "Art Now in India 1965",
    exhibition_centre: "Newcastle",
    country: "UK",
    img: pplImg,
  },
  {
    year: "1965",
    exhibition: "Raza: Peintures Récents",
    exhibition_centre: "Galerie Lara Vincy",
    exhibit_date: "18th November 1964 to 10th January 1965",
    city: "Paris",
    country: "France",
  },
  {
    year: "1966",
    exhibition: "Biennale de Menton",
    exhibition_centre: "Menton",
    country: "France",
    img: pplImg,
  },
  {
    year: "1966",
    exhibition: "Raza: Paris - 25 Oil Paintings from 1962-1966",
    exhibition_centre: "Tecta Galerie",
    exhibit_date: "6th October to 10th November 1966",
    city: "Dusseldorf",
    country: "Germany",
    img: pplImg,
  },
  {
    year: "1967",
    exhibition: "Silver Jubilee Exhibition",
    exhibition_centre: "Gallery Chemould",
    exhibit_date: "1967",
    city: "Bombay",
    country: "India",
    img: pplImg,
  },
  {
    year: "1967",
    exhibition: "Galerie Lara Vincy",
    exhibition_centre: "1967",
    city: "Paris",
    country: "France",
  },
  {
    year: "1968",
    exhibition: "First Triennale India",
    exhibition_centre: "Lalit Kala Akademi, Rabindra Bhawan Galleries",
    exhibit_date: "1968",
    city: "New Delhi",
    country: "India",
    img: pplImg,
  },
  {
    year: "1968",
    exhibition: "Biennale de Menton",
    exhibition_centre: "Menton",
    country: "France",
    img: pplImg,
  },
  {
    year: "1968",
    exhibition: "Terres des Hommes",
    exhibition_centre: "Festival International 1968",
    city: "Montreal",
    country: "Canada",
    img: pplImg,
  },
  {
    year: "1968",
    exhibition: "Raza",
    exhibition_centre: "Gallery Chemould",
    exhibit_date: "15th to 27th April 1968",
    city: "Bombay",
    country: "India",
    catalogue: "Catalogue",
  },
];
export const sh_raza_exhibitions_filter_options = {
  typeOfExhibition: [],
  venueLocation: [],
  year: [],
  medium: [],
};

export const sh_raza_awards_data = [
  {
    sortable_year: "1946",
    award_name: "Silver Medal",
    issuing_authority: "Bombay Art Society, Mumbai, India",
    sortable_date: null,
  },
  {
    sortable_year: "1948",
    award_name: "Gold Medal",
    issuing_authority: "Bombay Art Society, Mumbai, India",
    sortable_date: null,
  },
  {
    sortable_year: "1956",
    award_name: "Prix de la Critique",
    issuing_authority: "Paris, France",
    sortable_date: null,
  },
  {
    sortable_year: "1978",
    award_name: "Rajkeeya Samman",
    issuing_authority: "Government of Madhya Pradesh, India",
    sortable_date: null,
  },
  {
    sortable_year: "1981",
    award_name: "Padma Shri",
    issuing_authority: "Government of India",
    sortable_date: null,
  },
  {
    sortable_year: "1997",
    award_name: "Kalidas Samman",
    issuing_authority: "Government of Madhya Pradesh, India",
    sortable_date: null,
  },
  {
    sortable_year: "2002",
    award_name: "Officier de L'Ordre des Arts et des Lettres",
    issuing_authority: "Government of France",
    sortable_date: null,
  },
  {
    sortable_year: "2002",
    award_name: "Dayawati Modi Award",
    issuing_authority: "Modi Enterprises, India",
    sortable_date: null,
  },
  {
    sortable_year: "2004",
    award_name: "Lalit Kala Ratna Puraskar",
    issuing_authority: "Lalit Kala Akademi, New Delhi, India",
    sortable_date: "2004/08/09",
  },
  {
    sortable_year: "2007",
    award_name: "Padma Bhushan",
    issuing_authority: "Government of India",
    sortable_date: null,
  },
  {
    sortable_year: "2013",
    award_name: "Padma Vibhushan",
    issuing_authority: "Government of India",
    sortable_date: null,
  },
  {
    sortable_year: "2014",
    award_name: "D.Lit (Honoris Causa)",
    issuing_authority:
      "Indira Kala Sangeet Vishwavidyalaya, Khairagarh, Chattisgarh",
    sortable_date: null,
  },
  {
    sortable_year: "2015",
    award_name:
      "Commandeur de la Legion d'honneur (Commander of the Legion of Honour)",
    issuing_authority: "Government of France",
    sortable_date: null,
  },
  {
    sortable_year: "2015",
    award_name: "Honorary Degree",
    issuing_authority:
      "Shiv Nadar University, Greater Noida, Uttar Pradesh, India",
    sortable_date: null,
  }
];

export const sh_raza_top_auctions_data = [
    {
      image: auction1,
      medium_artwork: {
        key: "medium",
        value: "Acrylic on canvas",
        display: "Medium, Date",
        date_of_artwork: "1972",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "Tapovan",
        display: "Title of the artwork",
      },
      desc: {
        value: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: fullauction1,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "New York",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      link: true,
    },
    {
      image: auction2,
      medium_artwork: {
        key: "medium",
        value: "Acrylic on canvas",
        display: "Medium, Date",
        date_of_artwork: "1977",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "La Terre",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction2,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "New York",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
    {
      image: auction3,
      medium_artwork: {
        key: "medium",
        value: "Oil on canvas",
        display: "Medium, Date",
        date_of_artwork: "1973",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "La Terre",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction3,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "New York",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
    {
      image: auction4,
      medium_artwork: {
        key: "medium",
        value: "Acrylic on canvas",
        display: "Medium, Date",
        date_of_artwork: "1983",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "Saurashtra",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction4,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "London",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
    {
      image: auction5,
      medium_artwork: {
        key: "medium",
        value: "Acrylic on canvas",
        display: "Medium, Date",
        date_of_artwork: "1976",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "Jaipur & Jodhpur",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction5,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Saffronart.com",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
    {
      image: auction6,
      medium_artwork: {
        key: "medium",
        value: "Oil on canvas",
        display: "Medium, Date",
        date_of_artwork: "1973",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "La Terre",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction6,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "London",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
    {
      image: auction7,
      medium_artwork: {
        key: "medium",
        value: "Oil on canvas",
        display: "Medium, Date",
        date_of_artwork: "",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "Village en Fête",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction7,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "New York",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
    {
      image: auction8,
      medium_artwork: {
        key: "medium",
        value: "Acrylic on canvas",
        display: "Medium, Date",
        date_of_artwork: "1989",
      },
      artist: {
        name: "S.H Raza",
        full_name: "S.H Raza",
        first_name: "S.H",
        last_name: "Raza",
        alias: "s.h-raza",
      },
      title_of_artwork: {
        key: "title-of-artwork",
        value: "Gestation",
        display: "Title of the artwork",
      },
      desc: {
        value: "",
        key: "quotation",
        display: "Quotation",
      },
      fullImage: auction8,
      size: {
        key: "size",
        value: "",
        display: "Size",
      },
      lot_number: {
        key: "lot_number",
        value: "",
        display: "Lot Number",
      },
      auction_house: {
        key: "auction_house",
        value: "Christie's",
        display: "Auction House",
      },
      venue_location: {
        key: "venue_location",
        value: "Hong Kong",
        display: "Venue, Location",
      },
      date_of_auction: {
        key: "date_of_auction",
        value: "",
        display: "Date of Auction",
      },
      type_of_auction: {
        key: "type_of_auction",
        value: "",
        display: "Type of Auction",
      },
      number_of_bids_received: {
        key: "number_of_bids_received",
        value: "",
        display: "No. of Bids received",
      },
      percentage_over_under_mid_estimate: {
        key: "percentage_over_under_mid_estimate",
        value: "",
        display: "Percentage over/under mid-estimate",
      },
      artwork_square_inch_rate: {
        key: "artwork_square_inch_rate",
        value: "",
        display: "Artwork Square inch rate (S.I.R)",
      },
      previous_auction_sales: {
        key: "previous_auction_sales",
        value: "",
        display: "Previous Auction Sales (Repeat Sale)",
      },
      cagr_based_on_previous_sale: {
        key: "cagr_based_on_previous_sale",
        value: "",
        display: "CAGR (based on previous sale)",
      },
      estimates: {
        key: "estimates",
        value_in_inr: "₹1,600,000 - 2,400,000",
        valuein_dollar: "$20,000 - 30,000",
        display: "Estimates",
      },
      winning_bid: {
        key: "winning-bid",
        value_in_inr: "₹2,464,000",
        valuein_dollar: "$30,800",
        display: "Winning Bid",
      },
      
    },
  ];
  export const sh_raza_top_auctions_filter_options = {
    price: [],
    yearOfArtwork: [],
    yearOfSale: [],
    medium: [],
    venueLocation: [],
  };


export const sh_raza_Art_Market_Analysis=[
  {
    key:"volume-value-of-artwork-sold",
    title:"Volume and Value of Artwork Sold (1987 - present)",
    subtitle:"A Glimpse of data points to understand the Public Auction sales for all S.H. Raza Artworks from 1987 to the present day.",
    heading:"Out of a total of 40,000 public Auction sales artworks, 1300 sales are by S.H. Raza. A substantial share in the market of Public Auction sales of Indian Modern and Contemporary art.",
    data:[
      { year: 1924, numOfPaintings: 10 },
      { year: 1926, numOfPaintings: 15 },
      { year: 1928, numOfPaintings: 8 },
      { year: 1932, numOfPaintings: 30 },
      { year: 1934, numOfPaintings: 35 },
      { year: 1936, numOfPaintings: 40 },
      { year: 1938, numOfPaintings: 45 },
      { year: 1940, numOfPaintings: 61 },
      { year: 1942, numOfPaintings: 10 },
      { year: 1944, numOfPaintings: 15 },
      { year: 1946, numOfPaintings: 8 },
      { year: 1948, numOfPaintings: 30 },
      { year: 1950, numOfPaintings: 35 },
      { year: 1952, numOfPaintings: 40 },
      { year: 1954, numOfPaintings: 45 },
      { year: 1956, numOfPaintings: 61 },
      { year: 1958, numOfPaintings: 10 },
      { year: 1960, numOfPaintings: 15 },
      { year: 1962, numOfPaintings: 8 },
      { year: 1964, numOfPaintings: 30 },
      { year: 1968, numOfPaintings: 35 },
      { year: 1970, numOfPaintings: 40 },
    ],
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  },
  {
    key:"average-artwork-sale-value-over-years",
    title:"Average Artwork Sale Value over the years",
    heading:"Out of a total of 40,000 public Auction sales artworks 1300 sales are by S.H. Raza. A substantial smile in the market both in terms of Value and volume sold.",
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  },
  {
    key:"artwork-sales-vs-medium-of-artwork",
    title:"Artwork sales vs Medium of Artworks",
    heading:"Out of a total of 40,000 public Auction sales artworks 1300 sales are by S.H. Raza. A substantial smile in the market both in terms of Value and volume sold.",
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    data:[
      { name: "Andy Warhol", value: 590.21 },
      { name: "Claude Monet", value: 494.57 },
      { name: "Pablo Picasso", value: 539.29 },
      { name: "Francis Bacon", value: 255.18 },
      { name: "René Magritte", value: 226.55 },
      { name: "Gerhard Richter", value: 225.62 },
      { name: "Jean-Michel Basquiat", value: 221.02 },
      { name: "Mark Rothko", value: 198.3 },
      { name: "Willem De Kooning", value: 195.05 },
      { name: "Yayoi Kusama", value: 194.6 },
      { name: "Paul Cézanne", value: 193.48 },
    ]
  },
  {
    key:"percentage-difference-mid-estimate",
    title:"Percentage Difference from Mid Estimate",
    heading:"Out of a total of 40,000 public Auction sales artworks 1300 sales are by S.H. Raza. A substantial smile in the market both in terms of Value and volume sold.",
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    percentage_diff:"180%"
  }
]


export const shraza_research_centre = [
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788037",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-a-sayed-haider-raza-modern-indian-painting",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Madras",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rachna Sagar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1949",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Modern Indian Painting",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "sayed-haider-raza ",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [image1,book1,book2],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "p.-r.-ramachandra-rao",
          value: "P.-R.-Ramachandra-Rao",
          first_name: "P.-Ramachandra-",
          last_name: "Rao",
        },
      ],
      display: "Creator",
    },
    {
        key: "drawer_link",
        value: true,
      },
  ],
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788038",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dutta-a-sayed-haider-raza-trilogy-of-film-masterpieces-1960",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "The Publication Division, Ministry of Information and Broadcasting, Govt. of India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1951",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Art - Through the Ages",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: null,
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "sayed-haider-raza ",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [image2],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788039",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dutta-a-sayed-haider-raza-trilogy-of-film-masterpieces-1960",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "The Publication Division, Ministry of Information and Broadcasting, Govt. of India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1954",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Contemporary Indian Painters",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: null,
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "sayed-haider-raza ",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [image3],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788040",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dutta-a-sayed-haider-raza-trilogy-of-film-masterpieces-1960",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Sudhangshu",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1960",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Present-Day Painters of India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: null,
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "sayed-haider-raza ",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [image4],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
            "key": "manu-thacker",
            "value": "Manu-Thacker",
            "first_name": "Manu",
            "last_name": "Thacker"
        }
      ],
      display: "Creator",
    },
  ],

];

//SHRaza Catlouge Data

export const gallery_Chemould_catalouge_data=[
  {
    key:"catalogue",
    value:[
      {
      imgPath:SHCatalougeimage1
    },{
      imgPath:SHCatalougeimage2
    },{
      imgPath:SHCatalougeimage3
    },{
      imgPath:SHCatalougeimage4
    },{
      imgPath:SHCatalougeimage5
    },{
      imgPath:SHCatalougeimage6
    },{
      imgPath:SHCatalougeimage7
    },{
      imgPath:SHCatalougeimage8
    }
  ]


  },
  {
    key:"exhibits",
    value:[
      {
        image: SHExhibitimage1,
        medium_artwork: [{
          key: "medium",
          value: "Oil on Canvas",
          display: "Medium, Date",
          date_of_artwork: "1949",
        }],
        artist: [{
          name: "S. H. Raza",
          full_name: "S. H. Raza",
          first_name: "S. H.",
          last_name: "Raza",
          alias: "sh-raza",
        }],
        title_of_artwork: [{
          key: "title-of-artwork",
          value: "Black Sun",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: SHExhibitimagefull1,
        size: {
          key: "size",
          value: "150 x 150 cm (59.05 x 59.05 cm)",
          display: "Size",
        },
        link:true
      },
      {
        image: SHExhibitimage2,
        medium_artwork: [{
          key: "medium",
          value: "Oil on Canvas",
          display: "Medium, Date",
          date_of_artwork: "1951",
        }],
        artist: [{
          name: "S. H. Raza",
          full_name: "S. H. Raza",
          first_name: "S. H.",
          last_name: "Raza",
          alias: "sh-raza",
        }],
        title_of_artwork:[ {
          key: "title-of-artwork",
          value: "Summer",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: SHExhibitimagefull2,
        size: {
          key: "size",
          value: "39.37 x 39.37 in (100 x 100 cm )",
          display: "Size",
        },
        link:true
      },
      {
        image: SHExhibitimage3,
        medium_artwork:[ {
          key: "medium",
          value: "Oil on Canvas",
          display: "Medium, Date",
          date_of_artwork: "1952",
        }],
        artist: [{
          name: "S. H. Raza",
          full_name: "S. H. Raza",
          first_name: "S. H.",
          last_name: "Raza",
          alias: "sh-raza",
        }],
        title_of_artwork: [{
          key: "title-of-artwork",
          value: "Red Sun",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: SHExhibitimagefull3,
        size: {
          key: "size",
          value: "39.37 x 39.37 in (100 x 100 cm)",
          display: "Size",
        },
        link:true
      },
    ]
    
  },{
    key:"exhibition-photographs",
    value:[]

  },{
    key:"art-criticism-media-reportage",
    value:[]
  }
]


//KG subramanaym Exhibitions Data
export const kg_subramanym_exhibitions_data = [
  {
    year: "1964",
    exhibition: "Ten Indian Artists",
    exhibition_centre: "",
    exhibit_date: "",
    city: "",
    country: "USA",
    img: pplImg,
  },
  {
    year: "1971",
    exhibition: "Exhibition of Polytichs",
    exhibition_centre: "Kunika Art Gallery",
    exhibit_date: "",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "1972",
    exhibition: "Exhibition of Terracotta Reliefs",
    exhibition_centre: "Kunika Art Gallery",
    exhibit_date: "",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "1972",
    exhibition: "Exhibition of Terracotta Reliefs",
    exhibition_centre: "Kunika Art Gallery",
    exhibit_date: "",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "1975",
    exhibition: "Third Triennale",
    exhibition_centre: "Lalit Kala Akademi",
    exhibit_date: "February 3 - 5, 1975",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "1978",
    exhibition: "Exhibition of Terracotta Reliefs and Paintings",
    exhibition_centre: "Nandan Gallery",
    exhibit_date: "",
    city: "Santiniketan",
    country: "India",
  },
  {
    year: "1978",
    exhibition: "Exhibition of Terracotta Reliefs",
    exhibition_centre: "Art Heritage",
    exhibit_date: "",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "1979",
    exhibition: "Exhibition of Prints",
    exhibition_centre: "Art Heritage",
    exhibit_date: "",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "1982",
    exhibition: "Exhibition of Glass and Acrylic Sheet Paintings",
    exhibition_centre: "Nandan Gallery",
    exhibit_date: "",
    city: "Shantiniketan",
    country: "India",
  },
  {
    year: "1982",
    exhibition: "Exhibition of Glass and Acrylic Sheet Paintings",
    exhibition_centre: "Art Heritage",
    exhibit_date: "",
    city: "New Delhi",
    country: "India",
  },
  {
    year: "2003",
    exhibition: "K. G. Subramanyan, A Retrospective",
    exhibition_centre: "National Gallery of Modern Art",
    exhibit_date: "30 January 2003 - 16 March 2003",
    city: "New Delhi",
    country: "India",
    catalogue: "Catalogue",
  },
];

export const kg_subramanym_essay_data = [
  {
    year_of_publication: [
      { key: "year_of_publication", display: "Year of Publication", value: "" },
    ],
    fullImg: KGEssayfullimg1,
    img: KGEssayimg1,
    link: true,
    author: [
      {
        key: "author",
        display: "Author",
        first_name: "K. G.",
        last_name: "Subramanyan",
        profession: "Essayist",
        value: "K. G. Subramanyan",
      },
    ],
    title_of_essay: [
      {
        key: "title_of_essay",
        display: "Title of the Essay",
        value: "The Indian Artist and the Socio-cultural Context",
      },
    ],
  },
  {
    year_of_publication: [
      {
        key: "year_of_publication",
        display: "Year of Publication",
        value: "1973",
      },
    ],
    fullImg: KGEssayfullimg2,
    img: KGEssayimg2,
    link: true,
    author: [
      {
        key: "author",
        display: "Author",
        first_name: "K. G.",
        last_name: "Subramanyan",
        profession: "Essayist",
        value: "K. G. Subramanyan",
      },
    ],
    title_of_essay: [
      {
        key: "title_of_essay",
        display: "Title of the Essay",
        value: "The Use of Art Criticism",
      },
    ],
  },
  {
    year_of_publication: [
      {
        key: "year_of_publication",
        display: "Year of Publication",
        value: "1972",
      },
    ],
    fullImg: KGEssayfullimg3,
    img: KGEssayimg3,
    link: true,
    author: [
      {
        key: "author",
        display: "Author",
        first_name: "K. G.",
        last_name: "Subramanyan",
        profession: "Essayist",
        value: "K. G. Subramanyan",
      },
    ],
    title_of_essay: [
      {
        key: "title_of_essay",
        display: "Title of the Essay",
        value: "Thoughts on Murals",
      },
    ],
  },
];

export const kg_subramanym_illustrated_data=[
{
  year_of_publication: [
    {
      key: "year_of_publication",
      display: "Year of Publication",
      value: "1985",
    },
  ],
  author: [
    {
      key: "author",
      display: "Author",
      first_name: "K. G.",
      last_name: "Subramanyan",
      profession: "",
      value: "K. G. Subramanyan",
    }
  ],
  
    name_of_publication: [
      {
        key: "name_of_publication",
        display: "Title of the Essay",
        value: "When God First Made the Animals He Made Them All Alike",
      },
    ],
    img:KGIllustratedBook1
},
{
  year_of_publication: [
    {
      key: "year_of_publication",
      display: "Year of Publication",
      value: "1998",
    },
  ],
  author: [
    {
      key: "author",
      display: "Author",
      first_name: "K. G.",
      last_name: "Subramanyan",
      profession: "",
      value: "K. G. Subramanyan",
    }
  ],
  
    name_of_publication: [
      {
        key: "name_of_publication",
        display: "Title of the Essay",
        value: "The Tale of The Talking Face",
      },
    ],
    img:KGIllustratedBook2

},
{
  year_of_publication: [
    {
      key: "year_of_publication",
      display: "Year of Publication",
      value: "1985",
    },
  ],
  author: [
    {
      key: "author",
      display: "Author",
      first_name: "K. G.",
      last_name: "Subramanyan",
      profession: "",
      value: "K. G. Subramanyan",
    }
  ],
  
    name_of_publication: [
      {
        key: "name_of_publication",
        display: "Title of the Essay",
        value: "Our Friends, The Ogres",
      },
    ],
    img:KGIllustratedBook3

},
{
  year_of_publication: [
    {
      key: "year_of_publication",
      display: "Year of Publication",
      value: "1996",
    },
  ],
  author: [
    {
      key: "author",
      display: "Author",
      first_name: "K. G.",
      last_name: "Subramanyan",
      profession: "",
      value: "K. G. Subramanyan",
    }
  ],
  
    name_of_publication: [
      {
        key: "name_of_publication",
        display: "Title of the Essay",
        value: "Death  In Eden",
      },
    ],
    img:KGIllustratedBook4

}
]

export const kg_subramanym_Authored_Edited_Books_data=[
  {
    year_of_publication: [
      {
        key: "year_of_publication",
        display: "Year of Publication",
        value: "2007",
      },
    ],
    author: [
      {
        key: "author",
        display: "Author",
        first_name: "K. G.",
        last_name: "Subramanyan",
        profession: "",
        value: "K. G. Subramanyan",
      }
    ],
    
      name_of_publication: [
        {
          key: "name_of_publication",
          display: "Title of the Essay",
          value: "The Magic of Making",
        },
      ],
      img:KGIAuthoredEditedBook1
  },
  {
    year_of_publication: [
      {
        key: "year_of_publication",
        display: "Year of Publication",
        value: "1978",
      },
    ],
    author: [
      {
        key: "author",
        display: "Author",
        first_name: "K. G.",
        last_name: "Subramanyan",
        profession: "",
        value: "K. G. Subramanyan",
      }
    ],
    
      name_of_publication: [
        {
          key: "name_of_publication",
          display: "Title of the Essay",
          value: "Moving Focus",
        },
      ],
      img:KGIAuthoredEditedBook2
  
  },
]

//KG subramanym Catlouge Data

export const national_galley_of_moder_art_catalouge_data=[
  {
    key:"catalogue",
    value:[
      {
      imgPath:KGCatalougeimage1
    },
  ]


  },
  {
    key:"exhibits",
    value:[
      {
        image: KGExhibitimage1,
        medium_artwork: [{
          key: "medium",
          value: "Watercolour on Paper",
          display: "Medium, Date",
          date_of_artwork: "1952",
        }],
        artist: [{
          name: "K. G. Subramanyan",
          full_name: "K. G. Subramanyan",
          first_name: "K. G. ",
          last_name: "Subramanyan",
          alias: "sh-raza",
        }],
        title_of_artwork: [{
          key: "title-of-artwork",
          value: "Girl with Aunflower",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: KGExhibitimagefull1,
        size: {
          key: "size",
          value: "150 x 150 cm (59.05 x 59.05 cm)",
          display: "Size",
        },
        link:true
      },
      {
        image: KGExhibitimage2,
        medium_artwork: [{
          key: "medium",
          value: "Oil on Board",
          display: "Medium, Date",
          date_of_artwork: "1954",
        }],
        artist: [{
          name: "K. G. Subramanyan",
          full_name: "K. G. Subramanyan",
          first_name: "K. G. ",
          last_name: "Subramanyan",
          alias: "sh-raza",
        }],
        title_of_artwork:[ {
          key: "title-of-artwork",
          value: "Bird",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: SHExhibitimagefull2,
        size: {
          key: "size",
          value: "39.37 x 39.37 in (100 x 100 cm )",
          display: "Size",
        },
      },
      {
        image: KGExhibitimage3,
        medium_artwork:[ {
          key: "medium",
          value: "Gouache on Paper",
          display: "Medium, Date",
          date_of_artwork: "1956",
        }],
        artist: [{
          name: "K. G. Subramanyan",
          full_name: "K. G. Subramanyan",
          first_name: "K. G. ",
          last_name: "Subramanyan",
          alias: "sh-raza",
        }],
        title_of_artwork: [{
          key: "title-of-artwork",
          value: "Dog",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: SHExhibitimagefull3,
        size: {
          key: "size",
          value: "39.37 x 39.37 in (100 x 100 cm)",
          display: "Size",
        },
      },
      {
        image: KGExhibitimage4,
        medium_artwork:[ {
          key: "medium",
          value: "Gouache on Paper",
          display: "Medium, Date",
          date_of_artwork: "1959",
        }],
        artist: [{
          name: "K. G. Subramanyan",
          full_name: "K. G. Subramanyan",
          first_name: "K. G. ",
          last_name: "Subramanyan",
          alias: "sh-raza",
        }],
        title_of_artwork: [{
          key: "title-of-artwork",
          value: "Woman with News paper",
          display: "Title of the artwork",
        }],
        desc: {
          value:
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          key: "quotation",
          display: "",
        },
        fullImage: SHExhibitimagefull3,
        size: {
          key: "size",
          value: "39.37 x 39.37 in (100 x 100 cm)",
          display: "Size",
        },
      },
    ]
    
  },{
    key:"exhibition-photographs",
    value:[]

  },{
    key:"art-criticism-media-reportage",
    value:[]
  }
]

// KG  Catlouge Data award data

export const kg_subramanym_awards_data = [
  {
    sortable_year: "1957",
    award_name: "Bombay Art Society Award",
    issuing_authority: "Bombay Art Society, Mumbai, India",
    sortable_date: null,
  },
  {
    sortable_year: "1959",
    award_name: "Bombay Art Society Award",
    issuing_authority: "Bombay Art Society, Mumbai, India",
    sortable_date: null,
  },
  {
    sortable_year: "1961",
    award_name: "Maharashtra State Award",
    issuing_authority: "Government of Maharshtra, India",
    sortable_date: null,
  },
  {
    sortable_year: "1961",
    award_name: "Medallion of Honourable Mention",
    issuing_authority: "Sao Paulo Biennale, Brazil",
    sortable_date: null,
  },
  {
    sortable_year: "1965",
    award_name: "National Award",
    issuing_authority: "Lalit Kala Akademi, New Delhi",
    sortable_date: null,
  },
  {
    sortable_year: "1968",
    award_name: "Gold Medal",
    issuing_authority: "I International Triennale, New Delhi",
    sortable_date: null,
  },
  {
    sortable_year: "1975",
    award_name: "Padma Shree",
    issuing_authority: "Government of India",
    sortable_date: null,
  },
  {
    sortable_year: "1981",
    award_name: "Kalidas Samman",
    issuing_authority: "Government of Madhya Pradesh",
    sortable_date: null,
  },
  {
    sortable_year: "1985",
    award_name: "Fellow",
    issuing_authority: "Lalit Kala Akademi, New Delhi",
    sortable_date: null,
  },
  {
    sortable_year: "1991",
    award_name: "Gagan-Aban Puraskar",
    issuing_authority: "Visva-Bharati University, Santiniketan",
    sortable_date: null,
  },
  {
    sortable_year: "1992",
    award_name: "D. Litt (Honoris Causa)",
    issuing_authority: "Rabindra Bharati University, Calcutta, India",
    sortable_date: null,
  },
  {
    sortable_year: "1993",
    award_name: "Fellow",
    issuing_authority: "Kerala Lalit Kala Akademi",
    sortable_date: null,
  },
  {
    sortable_year: "1994",
    award_name: "Shiromani Puraskar",
    issuing_authority: "Calcutta",
    sortable_date: null,
  },
  {
    sortable_year: "1997",
    award_name: "D. Litt (Honoris Causa)",
    issuing_authority: "Benaras Hindu University, Benaras, India",
    sortable_date: null,
  },
  {
    sortable_year: "1999",
    award_name: "Kala Ratna",
    issuing_authority: "All India Fine Arts and Crafts Society, New Delhi",
    sortable_date: null,
  },
  {
    sortable_year: "2000",
    award_name: "Jadunath Sarkar Gold Medal",
    issuing_authority: "Asiatic Society, Calcutta, India",
    sortable_date: null,
  },
  {
    sortable_year: "2000",
    award_name: "Abanindra Puraskar",
    issuing_authority: "Calcutta",
    sortable_date: null,
  },
  {
    sortable_year: "2001",
    award_name: "Gana Krishti Puraskar",
    issuing_authority: "Kerala",
    sortable_date: null,
  },
  {
    sortable_year: "2001",
    award_name: "Manaviyam Ravi Varma Award",
    issuing_authority: "Government of Kerala",
    sortable_date: null,
  },
];

// KG Subramanym top_auctions data
export const kg_subramanym_top_auctions_data = [
  {
    image: KGauction1,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas",
      display: "Medium, Date",
      date_of_artwork: "1976",
    },
    artist: {
      name: "K. G. Subramanyan",
      full_name: "K. G. Subramanyan",
      first_name: "K. G.",
      last_name: "Subramanyan",
      alias: "k-g-subramanyan",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Terrace II",
      display: "Title of the artwork",
    },
    desc: {
      value: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      key: "quotation",
      display: "Quotation",
    },
    fullImage: fullKGauction1,
    size: {
      key: "size",
      value: "",
      display: "Size",
    },
    lot_number: {
      key: "lot_number",
      value: "",
      display: "Lot Number",
    },
    auction_house: {
      key: "auction_house",
      value: "",
      display: "Auction House",
    },
    venue_location: {
      key: "venue_location",
      value: "",
      display: "Venue, Location",
    },
    date_of_auction: {
      key: "date_of_auction",
      value: "",
      display: "Date of Auction",
    },
    type_of_auction: {
      key: "type_of_auction",
      value: "",
      display: "Type of Auction",
    },
    number_of_bids_received: {
      key: "number_of_bids_received",
      value: "",
      display: "No. of Bids received",
    },
    percentage_over_under_mid_estimate: {
      key: "percentage_over_under_mid_estimate",
      value: "",
      display: "Percentage over/under mid-estimate",
    },
    artwork_square_inch_rate: {
      key: "artwork_square_inch_rate",
      value: "",
      display: "Artwork Square inch rate (S.I.R)",
    },
    previous_auction_sales: {
      key: "previous_auction_sales",
      value: "",
      display: "Previous Auction Sales (Repeat Sale)",
    },
    cagr_based_on_previous_sale: {
      key: "cagr_based_on_previous_sale",
      value: "",
      display: "CAGR (based on previous sale)",
    },
    estimates: {
      key: "estimates",
      value_in_inr: "₹1,600,000 - 2,400,000",
      valuein_dollar: "$20,000 - 30,000",
      display: "Estimates",
    },
    winning_bid: {
      key: "winning-bid",
      value_in_inr: "₹2,464,000",
      valuein_dollar: "$30,800",
      display: "Winning Bid",
    },
    link: true,
  },
  {
    image: KGauction2,
    medium_artwork: {
      key: "medium",
      value: "Terracotta Relief",
      display: "Medium, Date",
      date_of_artwork: "1971",
    },
    artist: {
      name: "K. G. Subramanyan",
      full_name: "K. G. Subramanyan",
      first_name: "K. G.",
      last_name: "Subramanyan",
      alias: "k-g-subramanyan",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Hunter",
      display: "Title of the artwork",
    },
    desc: {
      value: "",
      key: "quotation",
      display: "Quotation",
    },
    fullImage: KGauction2,
    size: {
      key: "size",
      value: "",
      display: "Size",
    },
    lot_number: {
      key: "lot_number",
      value: "",
      display: "Lot Number",
    },
    auction_house: {
      key: "auction_house",
      value: "",
      display: "Auction House",
    },
    venue_location: {
      key: "venue_location",
      value: "",
      display: "Venue, Location",
    },
    date_of_auction: {
      key: "date_of_auction",
      value: "",
      display: "Date of Auction",
    },
    type_of_auction: {
      key: "type_of_auction",
      value: "",
      display: "Type of Auction",
    },
    number_of_bids_received: {
      key: "number_of_bids_received",
      value: "",
      display: "No. of Bids received",
    },
    percentage_over_under_mid_estimate: {
      key: "percentage_over_under_mid_estimate",
      value: "",
      display: "Percentage over/under mid-estimate",
    },
    artwork_square_inch_rate: {
      key: "artwork_square_inch_rate",
      value: "",
      display: "Artwork Square inch rate (S.I.R)",
    },
    previous_auction_sales: {
      key: "previous_auction_sales",
      value: "",
      display: "Previous Auction Sales (Repeat Sale)",
    },
    cagr_based_on_previous_sale: {
      key: "cagr_based_on_previous_sale",
      value: "",
      display: "CAGR (based on previous sale)",
    },
    estimates: {
      key: "estimates",
      value_in_inr: "₹1,600,000 - 2,400,000",
      valuein_dollar: "$20,000 - 30,000",
      display: "Estimates",
    },
    winning_bid: {
      key: "winning-bid",
      value_in_inr: "₹2,464,000",
      valuein_dollar: "$30,800",
      display: "Winning Bid",
    },
    link: true,
  },
  {
    image: KGauction3,
    medium_artwork: {
      key: "medium",
      value: "Watercolour and Oil on Acrylic Sheet",
      display: "Medium, Date",
      date_of_artwork: "1987",
    },
    artist: {
      name: "K. G. Subramanyan",
      full_name: "K. G. Subramanyan",
      first_name: "K. G.",
      last_name: "Subramanyan",
      alias: "k-g-subramanyan",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Inayat Khan Looks at Oxford",
      display: "Title of the artwork",
    },
    desc: {
      value: "",
      key: "quotation",
      display: "Quotation",
    },
    fullImage: KGauction3,
    size: {
      key: "size",
      value: "",
      display: "Size",
    },
    lot_number: {
      key: "lot_number",
      value: "",
      display: "Lot Number",
    },
    auction_house: {
      key: "auction_house",
      value: "",
      display: "Auction House",
    },
    venue_location: {
      key: "venue_location",
      value: "",
      display: "Venue, Location",
    },
    date_of_auction: {
      key: "date_of_auction",
      value: "",
      display: "Date of Auction",
    },
    type_of_auction: {
      key: "type_of_auction",
      value: "",
      display: "Type of Auction",
    },
    number_of_bids_received: {
      key: "number_of_bids_received",
      value: "",
      display: "No. of Bids received",
    },
    percentage_over_under_mid_estimate: {
      key: "percentage_over_under_mid_estimate",
      value: "",
      display: "Percentage over/under mid-estimate",
    },
    artwork_square_inch_rate: {
      key: "artwork_square_inch_rate",
      value: "",
      display: "Artwork Square inch rate (S.I.R)",
    },
    previous_auction_sales: {
      key: "previous_auction_sales",
      value: "",
      display: "Previous Auction Sales (Repeat Sale)",
    },
    cagr_based_on_previous_sale: {
      key: "cagr_based_on_previous_sale",
      value: "",
      display: "CAGR (based on previous sale)",
    },
    estimates: {
      key: "estimates",
      value_in_inr: "₹1,600,000 - 2,400,000",
      valuein_dollar: "$20,000 - 30,000",
      display: "Estimates",
    },
    winning_bid: {
      key: "winning-bid",
      value_in_inr: "₹2,464,000",
      valuein_dollar: "$30,800",
      display: "Winning Bid",
    },
    link: true,
  },
];

// KG subramanym research_centre

export const kg_subramanym_research_centre = [
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788037",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-geeta-kapur-subramanyan-1987",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Lalit Kala Akademi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1987",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "K.G. Subramanyan",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "k.g-Subramanyan",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [KGRCimage1,KGbook1],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
          {
              "key": "geeta-kapur",
              "value": "Geeta Kapur",
              "first_name": "Geeta",
              "last_name": "Kapur"
          },
      ],
      display: "Creator",
    },
    {
        key: "drawer_link",
        value: true,
      },
  ],
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788038",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dutta-a-k.g-Subramanyan-trilogy-of-film-masterpieces-1960",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:"National Gallery of Modern Art",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1997",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "K.G. Subramanyan",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Reteospective",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "k.g-Subramanyan",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [KGRCimage2],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
          {
              "key": "r-siva-kumar",
              "value": "Siva Kumar",
              "first_name": "Siva",
              "last_name": "Kumar"
          },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e4cdd9b0-9779-4e1e-8456-32613c788039",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dutta-a-k.g-Subramanyan-trilogy-of-film-masterpieces-1960",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Mumbai",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "zzz",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:"The Guild Art Gallery",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2009",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "K.G. Subramanyan",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Painted Platters",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "k.g-Subramanyan",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [KGRCimage3],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "FineArts",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "FineArts",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
          {
              "key": "r-siva-kumar",
              "value": "Siva Kumar",
              "first_name": "Siva",
              "last_name": "Kumar"
          },
      ],
      display: "Creator",
    },
  ],

];