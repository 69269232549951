import { HomeData } from "../MockData/HomeData";
// import { transformJSON } from "../util/transformer";
// import { schema } from "./schemas/Home";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

export const getHomeDataAPI = async (tableName, masterlist) => {
};

export const getHomeData = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getHomeDataDummy(tableName, masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    // To do after receving real data plz remove it
    const response1 = await getHomeDataDummy(tableName, masterlist);
    return response1;
    // const response = await getHomeDataAPI(tableName, masterlist);
    // try {
    //   if (response) {
    //     let transformedData = transformJSON(schema, true, response);
    //     return transformedData;
    //   } else {
    //     console.error("Invalid response data structure:", response);
    //     return [];
    //   }
    // } catch (e) {
    //   console.error("Something is wrong", e);
    // }
  }  
};

export const getHomeDataDummy = async (tableName, masterlist) => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return HomeData;
  } else {
    return [];
  }
};
