import {
  Box,
  Container,
  HStack,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";

import ScrollToTop from "../components/sections/ScrollToTop";
import Layout from "../components/sections/SidebarLayout";
import Large from "../components/layouts/Typography/Large";
import { Link } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionSept2022/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionSept2022/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionSept2022/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionSept2022/4.jpg`
const Img5 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionSept2022/5.jpg`
const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`

function NewVision() {
  return (
    <Layout>
      <Stack m="5" pr={"1"} pl={"1"} >
        <Container paddingX={{base:"0px", md:"4"}} maxW="auto" mb="8">
          <Box display={{base:"content", md:"none"}} position={"sticky"} top={"90px"} zIndex={1}>
            <Link to="/philosophical-framework">
              <Image src={BackArrow}></Image>
            </Link>
          </Box>
          <Box pt="4" pb="4">
            <Text fontSize={"28"} fontWeight="700">
              Tuli Research Centre for India Studies
            </Text>
          </Box>
          <Stack flexDirection="column">
            <HStack pb="6">
              <Box flex={1} alignItems={"center"}>
                <Image
                  boxSize="auto"
                  w="2200px"
                  objectPosition="center"
                  src={Img1}
                  alt=""
                />
              </Box>
            </HStack>
            <HStack>
              <Box>
                <Large pb="1">
                  The Tuli Research Centre for India Studies (T.R.I.S.), a
                  Charitable Society & Trust, is entrusted with the
                  responsibility of being the custodian of the vast archives and
                  libraries which one has obsessively built piece by piece since
                  1992.
                </Large>
                <Large pb="2">
                  www.osianamaworld.org will be T.R.I.S.’ main online platform
                  and the Do Bigha Zamin Gallery for India Studies its public
                  Exhibition Space. OsianamaWorld-Mapin Publishing House will
                  become our global outreach platform for creating top-quality
                  publications for each Research Category of Focus (Table 1).
                </Large>
              </Box>
            </HStack>
          </Stack>
          <Box>
            <Text fontSize={"28"} fontWeight="700">
              Table 1
            </Text>
            <Text fontSize={"28"} fontWeight="700" pb="2">
              Index of Research Categories for India Studies | 2023-25
            </Text>
          </Box>
          <Stack flexDirection="column">
            <HStack>
              <Box>
                <OrderedList fontSize={"14px"}>
                  <ListItem>Animal-Human-Nature Continuum</ListItem>
                  <ListItem>Changing Smile of Childhood</ListItem>
                  <ListItem>Cinema as the Key Educational Resource</ListItem>
                  <ListItem>
                    Contemporary Innovation from the History of the Automobile,
                    Transport, Travel & Tourism Heritage
                  </ListItem>
                  <ListItem>
                    Inspiration for India’s Developmental Framework from the
                    History of her Architectural Heritage
                  </ListItem>
                  <ListItem>Economics of Art, Cinema & Culture</ListItem>
                  <ListItem>
                    The History of the Fine & Popular Indian Arts, Cinema &
                    Photography
                  </ListItem>
                  <ListItem>
                    Historiographic Studies of Indian Scholarship in the Arts,
                    Social Sciences & Humanities
                  </ListItem>
                  <ListItem>
                    India and her Relationship with the World, 1850-2020s
                  </ListItem>
                  <ListItem>
                    Allowing Uncertainty to be Itself: Mystery | Magic | Horror
                    | Sci-Fi
                  </ListItem>
                  <ListItem>
                    Seeking Political Integrity & the History of Indian
                    Political Thought, 1850s-2020s
                  </ListItem>
                  <ListItem>
                    Social Responsibility of the Creative Mind
                  </ListItem>
                  <ListItem>Subalterns Seeking Justice Again & Again</ListItem>
                  <ListItem>The Erotic Discipline within Creativity.</ListItem>
                </OrderedList>
              </Box>
            </HStack>
            <HStack py="4" flexDirection={"column-reverse"}>
              <Box flex={1} alignItems={"center"}>
                <Image
                  boxSize="auto"
                  w="800px"
                  objectPosition="center"
                  src={Img2}
                  alt="Dan Abramov"
                />
              </Box>
            </HStack>
          </Stack>
          <Box>
            <Large py="2">
              ‘OsianamaWorld – 1st Festival for India Studies’ will herald the
              opening of the Centre at a formal and global level, also allowing
              systematic access of the archives and libraries to the academic
              and scholarly fraternity for the first time. This event will also
              formally take forward the building of a permanent in-house
              academic faculty and its international exchange and fellowship
              programs by March 2023.
            </Large>
            <Large py="2">
              One key short-term objective is to conceptualize and facilitate a
              new framework for India Studies, in which hopefully the world’s
              first three-year undergraduate course will also be created by some
              forward looking educational institution with our support and
              vision.
            </Large>
          </Box>
          <Box>
            <Text fontSize={"28"} fontWeight={"700"} pb="2">
              2
            </Text>
            <Large py="2">
              For nearly thirty years one has focused on building the conceptual
              and institutional frameworks for creating a financially
              self-sufficient infrastructure for a handful of India’s
              contemporary cultural disciplines. From this the writing of a new
              blueprint towards creating a credible cultural industry and its
              eco-system which can play a significant role within India’s
              developmental journey is emerging.
            </Large>
            <Large py="2">
              Along this path a deep diversity of institutional platforms have
              been built by my colleagues and myself – charitable, non-profit,
              corporate & other hybrid forms - ranging from the archive and
              library to the auction house, the film festival to the art fund,
              the conservation laboratory to the publishing and design house,
              the literary agency to animal welfare sanctuary, the gallery to
              the research centre.
            </Large>
            <Large py="2">
              Each clarified the integrated, disconnected, and seamless nature
              of the holistic links required to build something relevant to the
              scale and diversity for India’s needs, her unique circumstances,
              stages of development and value systems.
            </Large>
            <Large py="2">
              Fortunately or unfortunately, India still struggles with a basic
              grasp and mutual respect towards Saraswati and Lakshmi in the same
              breath, where each representative is unable or unwilling to sit
              under the same tree and discuss with mutual purpose and rigorous
              to-and-fro, let alone build together. This is a basic requirement
              to nurture credible learning institutional frameworks and internal
              bridges, free from patronage and yet uncompromisingly respectful
              of the brilliance and freedoms human expression and expectations
              must demand.
            </Large>
            <Large py="2">
              The journey is marked with as much failure as success,
              irrespective of the path and specific forms developed, hence
              sustaining the processes with grit, integrity, and joy is the most
              important feature for an individual trying to institutionalize
              their life’s work. The key is to be standing with whatever relics
              of the previous battle you still command to take on the next. In
              most cases, something deeply precious needs to be created,
              nurtured, and protected at the cost of all else.
            </Large>
          </Box>
          <Box flex={1} alignItems={"center"} py="4">
            <Image w="100%" objectPosition="center" src={Img3} />
          </Box>
          <Box>
            <Text fontSize={"28"} fontWeight={"700"} pb="2">
              3
            </Text>
            <Large py="2">
              For one’s journey, it was evident very early on, near 1993-94,
              that a vast integrated tangible knowledge-base, rooted as much in
              the process of building it as the end results, rooted as much as
              in the visual as textual, as much in the fine as popular, as much
              in the experiential as theoretical, as much in the aesthetic as
              archival, as much in the material as spiritual, was required to be
              at the heart of this institution-building if any long term
              developmental objective was to succeed.
            </Large>
            <Large py="2">
              Now as 2023 approaches, and many fundamental stages of the
              rollercoaster ride are rooted, it is imperative we seek
              uncluttered and specialized forms for each institutional cog of
              this organic sponge-like river of knowledge which grows daily and
              yet remains fragile.
            </Large>
            <Large py="2">
              Much has been lost and rebuilt many times. During the pandemic
              when Osian’s – Connoisseurs of Art was also taken away by the NCLT
              process, a strange reawakening and clarity emerged which only
              genuine loss inspires.
            </Large>
            <Large py="2">
              One has been privileged to have had so many opportunities to
              rebuild, to have the trust of so many to experiment again, each
              time wiser and ironically even more compassionate and idealistic
              in a simple way. In a system so governed by misinformation,
              randomly
            </Large>
          </Box>
          <Box flex={1} alignItems={"center"} py="4">
            <Image boxSize="auto" w="100%" objectPosition="center" src={Img4} />
          </Box>
          <Box>
            <Large py="2">
              formulated perceptions, the unfair price of broken expectations,
              it is very difficult for most creative and scholarly minds to
              effectively straddle the material responsibilities that a vision
              will demand. Creating great cultural institutional teams and
              processes that survive the individual are very few to see and
              study. Today, many can clearly see that something is deeply amiss
              in the manner with which institutions of learning and education
              are still being built.
            </Large>
            <Text fontSize={"28px"} fontWeight={"700"} pb="2">
              4
            </Text>
            <Large py="2">
              At every stage of learning there is a mismatch. From the pre-KG to
              early primary one today can see the immense inequality brewing and
              embedding itself in millions of children not privileged with a
              genuine learning space through playfulness, deep one to one
              interaction with gentle and secure teaching minds, let alone the
              various gifts of childhood which today are becoming the exception
              rather than the norm across the world, let alone in India.
            </Large>
            <Large py="2">
              From the changing smile of childhood, we enter an adolescence
              which today is in constant conflict with existing curricula and
              virtually every facet of related pedagogy, from the manner of
              absorbing new technologies and their effect on attitudes to learn,
              memorize, concentrate, and research, to issues of vocation versus
              the need to learn for the sake of the mind’s joy and a respect for
              various forms of knowledge irrespective of their immediate and
              obvious material applicability.
            </Large>

            <Large py="2">
              The redefining role and expectations of the university is an
              ongoing site for an immense war. From many quarters salvos are
              being fired on the nature of these temples of learning. Over the
              months and years hopefully we will be able to support and nurture
              such institutions in a small way, making available knowledge,
              archives, libraries, and ideas like never before, at least for
              subjects and research categories which I place under the umbrella
              of India Studies.
            </Large>
            <Large py="2">
              This near thirty-year journey of trying to grasp the very essence,
              rhythm, needs, and aspirations of India and our people, through a
              vast terrain of knowledge and experience, now needs consolidating
              in a formal educational institutional structure with a wider
              supporting infrastructure within an eco-system which is being
              directly nurtured on many levels.
            </Large>
            <Large py="2">
              Without this trinity of support-systems it will be difficult to
              bring a change in the mindsets and old habits of learning. Many
              changes which inter-disciplinarity sought to evolve remain
              untouched, instead confusion reigns as neither a specialization is
              possible nor the attempts at a seamless integration of ideas and
              subjects gained credibility. At the heart of this failure lies the
              manner with which a macro-holistic wisdom is being sought from
              inadequate cut and paste specialized inter-disciplinary
              structures, which fail to connect the streams of knowledge into a
              cohesive living energy which young minds can absorb with joy,
              clarity, and wisdom.
            </Large>
            <Large py="2">
              I am not sure if a paradigm shift can be inspired by T.R.I.S. but
              we will try our best, and if nothing else, at least the student
              and academic bodies will finally gain access to the single finest
              cohesive archive and library built to study contemporary India and
              her relationships with the world, post 1850s, with a special focus
              on the modern and contemporary fine and popular arts, cinema,
              political history and thought, architecture and its heritage, and
              various facets of animal welfare, ecological and environmental
              studies.
            </Large>
            <Large pb="2">It is a gift long overdue.</Large>
          </Box>
          <Box flex={1} alignItems={"center"} py="4">
            <Image boxSize="auto" w="100%" objectPosition="center" src={Img5} />
          </Box>
          <ScrollToTop />
        </Container>
      </Stack>
      {/* <Footer /> */}
    </Layout>
  );
}
export default NewVision;
