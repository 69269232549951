//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/7.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/8.png`
const Img9 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/9.png`
const Img10 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/10.png`
const Img11 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/11.png`
const Img12 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/12.png`
const Img13 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/13.png`
const Img14 = `${IMAGE_BASE_PATH}/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/14.png`
const Img15 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/15.png`
const Img16 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/16.png`

const SHImg1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/DetailPage/fullbook1.png`
const SHImg2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/DetailPage/placeholder.png`
const SHImg3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/DetailPage/placeholder.png`
const SHImg4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/ResearchCentre/Library/GridView/DetailPage/placeholder.png`

const KGImg1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/ResearchCentre/Book/book1.png`

const satyaImg1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/1.png`
const satyaImg2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/2.png`
const satyaImg3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/3.png`
const satyaImg4 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/4.png`
const satyaImg5 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/5.png`
const satyaImg6 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/6.png`
const satyaImg7 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/7.png`
const satyaImg8 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/8.png`

const lucknowImg1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg1.png`
const lucknowImg2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg2.png`
const lucknowImg3 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg3.png`
const lucknowImg4 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/DetailPage/fullImg4.png`

export const bka_jindal_my_adventures_with_satyajit_ray_2017 = [
  {
    bookName:"My Adventures with Satyajit Ray - Making of Shatranj Ke Khilari",
    slider_data: [
      { image: Img1, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img2, desc: "Content Awaited" },
      { image: Img3, desc: "Content Awaited" },
      { image: Img4, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img5, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img6, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img7, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img8, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img9, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img10, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img11, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img12, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img13, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img14, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img15, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
      { image: Img16, desc: "Suresh Jindal | Book, 2017_BOOK.bka |" },
    ],
    bookText:
      "The book, presents a walk through the making of Shatranj Ke Khilari, a periodic drama set in nineteenth-century Lucknow, capital of the state of Oudh. Directed by one of the most celebrated Indian film makers, Satyajit Ray, the very many interesting facets of the book include his maneuvering through the challenges of creating a feature film in Hindi/Urdu, as he took up its directorial reins despite his definitive declaration of never wanting to write and direct a film that was not in Bengali. The book also captures the beauty of both the lavish stage design set against the backdrop of the East India Company's avaricious annexation of Oudh in 1856 and recreation of a complex character of Wajid Ali Shah in a different light. Wajid Ali's character trajectory from a decadent ruler to a connoisseur with refined artistic sensibilities also shows Ray's way of finding a common ground with its moral and ethical complexities. as he did not feel any empathy for the character in the beginning. Besides, the book wholly encapsulates the Bengali maestro's relationship with his young and artistically-driven producer, Suresh Jindal. Extracting from his unpublished letters to Jindal, the book gives the readers access to Ray's fanatical passion, original historical research, trademark attention to detail and energy with which he created this productin; his most expensive project till date.",
    authorText:
      "An electronics engineer by training. Suresh Jindal got interested in cinema while studying at the University of California. Los Angeles, US, in the late 1960s. He was among the producers who made possible the dreams of film-makers working outside the mainstream. He has produced films that are considered milestones in Indian arthouse cinema, including Rajnigandha, Shatranj Ke Khilari, Gandhi and Katha. He is a recipient of the Chevalier des Ordre des Arts et des Lettres given by the French government.",
    contentsData: [
      { title: "Foreword by Jean-Claude Carriere" },
      { title: "Introduction by Andrew Robinson" },
      { title: "1. The Meeting" },
      { title: "2. Satyajit Ray: A Brief Biography" },
      { title: "3. Shatranj Ke Khilari: The Beginnings" },
      { title: "4. Research and Scheduling" },
      { title: "5. Casting" },
      { title: "6. Final Preparations" },
      { title: "7. Filming" },
      { title: "8. Post-production" },
      { title: "9. The Release and After" },
      { title: "10. Looking Back" },
      { title: "Acknowledgements" },
    ],
  },
];

export const sayed_haider_raza_modern_indian_painting=[
  {
    bookName:"Modern Indian Painting",
    slider_data: [
      { image: SHImg1 ,desc:""},
      { image: SHImg2 ,desc:""},
      { image: SHImg3 ,desc:""},
      { image: SHImg4 ,desc:""}
    ],
    bookText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    authorText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    contentsData: [
      { title: "" },
    ],
  },
];
export const bke_geeta_kapur_subramanyan_1987=[
  {
    bookName:"Modern Indian Painting",
    slider_data: [
      { image: KGImg1 ,desc:""},
      { image: SHImg2 ,desc:""},
      { image: SHImg3 ,desc:""},
      { image: SHImg4 ,desc:""}
    ],
    bookText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    authorText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    contentsData: [
      { title: "" },
    ],
  },
];
export const bke_mukhopadhyay_pather_panchali_1984=[
  {
    bookName:"Pather Panchali - A Film by Satyajit Ray",
    slider_data: [
      { image: satyaImg1 },
      { image: satyaImg2 },
      { image: satyaImg3 },
      { image: satyaImg4 },
      { image: satyaImg5 },
      { image: satyaImg6 },
      { image: satyaImg7 },
      { image: satyaImg8 },
    ],
    bookText:
      "Contents-1",
    authorText:"Contents-2",
    contentsData: [
      { title: "" },
    ],
  },
];  
export const bka_abdul_halim_the_lucknow_Omnibus_2001=[
  {
    bookName:"Lucknow & The Lucknow Omnibus",
    slider_data: [
      { image: lucknowImg1 },
      { image: lucknowImg2 },
      { image: lucknowImg3 },
      { image: lucknowImg4 }
    ],
    bookText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    authorText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    contentsData: [
      { title: "" },
    ],
  },
];  