//Core files
import React from "react";

//Component files
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

function AwardsHonours() {
  return (
    <>
    <WebpageComingSoon/>
    </>
  );
}

export default AwardsHonours;