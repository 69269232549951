import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  TabList,
  Tab,
  Tabs,
  IconButton,
} from "@chakra-ui/react";
//Core file
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import MobileNav from "../../components/Topheader";
import GridView from "./BodyOfWork/GridView";
import TabularView from "./BodyOfWork/TabularView";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import FullCarousalImage from "./BodyOfWork/FullCarousalImage";
import * as editionsData from "../../services/Editions";

//Language file
import { artheritage, explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`

export default function BodyOfWork({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visible, setVisible] = useState(true);
  const [showCarousal, setShowCarousal] = useState(false);
  const [carousalData, setcarousalData] = useState([]);
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  const [data, setData] = useState('');

  useEffect(() => {
    const getData = async () => {
      const result = await editionsData.getEditionsData();
      if (result) {
        setData(result)
      }
    };
    getData();
  }, []);
  console.log(data)


  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "journal",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "journal_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        zIndex={"9999"}
        height="calc(100vh - 64px)"
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        introData={introData}
        masterlistType={masterlistType}
        name={name}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        <Stack width={{ base: "100%", md: "1160px" }}>
          <Container
            minW={"100%"}
            pb="4"
            px="0px"
            marginLeft={{ base: "0px", md: "30px" }}
          >
            <Stack>
              <Box position={"fixed"} zIndex={999} bg="white" width={"1150px"}>
                <HStack py={"30px"}></HStack>
              </Box>
              <HStack>
                <Tabs align={{ xs: "end", sm: "" }} w="full">
                  <Stack
                    flexDirection={"row-reverse"}
                    display={"flex"}
                    marginTop={"32px"}
                  >
                    <Box
                      position={"fixed"}
                      zIndex={999}
                      bg="white"
                      width={{ base: "100%", md: "1160px" }}
                    >
                      <TabList
                        overflowX="auto"
                        border={"none"}
                        gap={12}
                        className="verticalscroll"
                        marginLeft={"20px"}
                      >
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"18px"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                        >
                          {artheritage.ANNUAL_EDITION}
                        </Tab>
                      </TabList>
                    </Box>

                    <Stack
                      flexDirection={"row"}
                      gap={0}
                      zIndex={999}
                      id="listgridviewbtn"
                      marginRight={"20px !important"}
                      marginTop={{
                        base: "-2px !important",
                        md: "-16px !important",
                      }}
                      position={"fixed"}
                    >
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(false)}
                          color={!visible ? "black" : "#989898"}
                          fontSize="20px"
                          display="flex"
                          borderTopLeftRadius="6px"
                          borderBottomLeftRadius="6px"
                          borderTopRightRadius="0px"
                          borderBottomRightRadius="0px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <RiMenuLine ml="4" />
                        </IconButton>
                      </HStack>
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(true)}
                          color={visible ? "black" : "#989898"}
                          fontSize="16px"
                          display="flex"
                          borderTopLeftRadius="0px"
                          borderBottomLeftRadius="0px"
                          borderTopRightRadius="6px"
                          borderBottomRightRadius="6px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <BsGrid />
                        </IconButton>
                      </HStack>
                    </Stack>
                  </Stack>
                </Tabs>
              </HStack>
              {visible ? (
                <GridView
                  setShowCarousal={setShowCarousal}
                  setcarousalData={setcarousalData}
                />
              ) : (
                <TabularView />
              )}
            </Stack>
            {showCarousal && carousalData.length > 0 && (
              <FullCarousalImage
                setShowCarousal={setShowCarousal}
                showCarousal={showCarousal}
                data={carousalData}
              />
            )}
          </Container>
        </Stack>
      </Box>
    </Box>
  );
}

const SidebarContent = ({ 
  onClose, 
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType,  ...rest }) => {
  const drawerArtHeritage = [{
title:"Ebrahim Alkazi", name: "Art Heritage Annual & Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Content Awaited",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const [drawerName, setDrawerName] = useState("")

   const artistLinksData = [{
  name: "Ebrahim Alkazi",
 
},{
    name: "Art Heritage Gallery",
}

];;
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        artistLinksData={artistLinksData}
        setDrawerName={setDrawerName}
        drawerName={drawerName}     
        handleNavigation={handleNavigation}
        drawerData={drawerArtHeritage}
      />
    </>
  );
};
