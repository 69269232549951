import {
  Box,
  Stack,
  Container,
  useDisclosure,
  Drawer,
  DrawerContent,
  useColorModeValue,
  Text,
  Tabs,
  TabList,
  Tab,
  Divider,
  HStack,
} from "@chakra-ui/react";

//Core files
import React, { useState, useEffect } from "react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";

//Service files
import { CopyPasteService } from "../../services/CopyPaste";
import { getIntroductionByAlias } from "../../services/ResearchCategoryDetails";

//Component files
import MobileNav from "../../components/Topheader";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import Introduction from "./ResearchCategory/Introduction";
import Chronology from "./ResearchCategory/Chronology";
import Masterlist from "./ResearchCategory/Masterlist";
import ResearchCenter from "./ResearchCategory/ResearchCenter/ResearchCenter";
import Posts from "./ResearchCategory/Posts";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";

//Language files
import { button, research_category } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function RCMain() {
  const { alias, taboptions } = useParams();
  const navigate = useNavigate();
  const [rcIntroduction, setRCIntroduction] = useState([]);
  function scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }

  useEffect(() => {
    if (taboptions) {
      scrollToSection(taboptions);
      navigate(`/research-categories/${alias}/`, { replace: true });
    }
  }, [navigate, taboptions, alias]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getIntroductionByAlias("", alias);
        if (result) {
          setRCIntroduction(result[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [alias]);
  
  const tabIndexs = {
    Introduction: 0,
    scrollToChronology: 1,
    scrollToMasterlists: 2,
    scrollToPosts: 3,
    scrollToInsights: 4,
    scrollToResearchCentre: 5,
  };
  return (
    <>
      <SidebarWithHeader>
        {rcIntroduction ?
          <Stack margin={"auto"}>
          <Container maxW={"100%"} px="0px">
            <Stack>
              <Box
                position={"fixed"}
                px={{ base: "4", md: "16" }}
                zIndex={999}
                bg="white"
                width={"1344px"}
                pb="4"
              >
                <Box marginTop={"8"} paddingY="20px">
                  <Tabs
                    variant={"unstyled"}
                    align="end"
                    pos={"none"}
                    defaultIndex={tabIndexs[taboptions]}
                  >
                    <Text
                      pos={"absolute"}
                      top={"30px"}
                      borderBottom={"4px"}
                      borderBottomColor={"black"}
                      textAlign={"left"}
                      fontSize={{ base: "25px", md: "45px" }}
                      fontWeight={700}
                      cursor={"pointer"}
                      onClick={() => scrollToSection("scrollToIntroduction")}
                    >
                      {rcIntroduction?.title}
                    </Text>
                    <TabList gap={"25px"}>
                      <Tab
                        onClick={() => scrollToSection("scrollToIntroduction")}
                        p="0px"
                        fontSize={"16"}
                        fontWeight={600}
                        _selected={{ borderBottom: "2px" }}
                      >
                        {research_category.INTRODUCTION_TAB_TITLE}
                      </Tab>
                      <Tab
                        onClick={() => scrollToSection("scrollToChronology")}
                        p="0px"
                        fontSize={"16"}
                        fontWeight={600}
                        _selected={{ borderBottom: "2px" }}
                      >
                        {research_category.CHRONOLOGY_TAB_TITLE}
                      </Tab>
                      <Tab
                        onClick={() => scrollToSection("scrollToMasterlists")}
                        p="0px"
                        fontSize={"16"}
                        fontWeight={600}
                        _selected={{ borderBottom: "2px" }}
                      >
                        {research_category.MASTERLISTS_TAB_TITLE}
                      </Tab>
                      <Tab
                        onClick={() => scrollToSection("scrollToPosts")}
                        p="0px"
                        fontSize={"16"}
                        fontWeight={600}
                        _selected={{ borderBottom: "2px" }}
                      >
                        {research_category.POSTS_TAB_TITLE}
                      </Tab>
                      <Tab
                        onClick={() => scrollToSection("scrollToInsights")}
                        p="0px"
                        fontSize={"16"}
                        fontWeight={600}
                        _selected={{ borderBottom: "2px" }}
                        cursor={"pointer"}

                      >
                        {research_category.INSIGHTS_TAB_TITLE}
                      </Tab>
                      <Tab
                        onClick={() =>
                          scrollToSection("scrollToResearchCentre")
                        }
                        p="0px"
                        fontSize={"16"}
                        fontWeight={600}
                        _selected={{ borderBottom: "2px" }}
                      >
                        {research_category.RESEARCH_CENTRE_TAB_TITLE}
                      </Tab>
                    </TabList>
                  </Tabs>
                </Box>
              </Box>
              <Stack>
                <Box
                  id="scrollToIntroduction"
                  width={"100%!important"}
                  height="100%"
                  marginTop="110px"
                >
                  <Introduction rcAlias={alias} />
                </Box>
                <Divider mx={{ base: "4", md: "16" }} />
                <Box
                  id="scrollToChronology"
                  height={{ base: "100%", md: "550px" }}
                  paddingY={"8"}
                  px={{ base: "4", md: "16" }}
                  marginTop="20px"
                >
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <Link
                        color="black"
                        to={`/research-categories/${alias}/chronology`}
                      >
                        <Text
                          pb="4"
                          fontSize={"30px"}
                          fontWeight={700}
                          lineHeight={"28px"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {research_category.CHRONOLOGY_TAB_TITLE}
                        </Text>
                      </Link>
                    </HStack>
                    <HStack>
                      <Link to={`/research-categories/${alias}/chronology`}>
                        <Text
                          pb="4"
                          fontSize={"18px"}
                          fontWeight={700}
                          lineHeight={"20px"}
                          cursor={"pointer"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {button.VIEW_ALL}
                        </Text>
                      </Link>
                    </HStack>
                  </Stack>
                  <Stack width={"100%"} flexDirection={"row"}>
                    <Stack
                      pb="4"
                      flexDirection={"column"}
                      width={{base:"100%", md:"1000px"}}
                      height={{ base: "100%", md: "450px" }}
                      className="chronology"
                      overflowY={"auto"}
                      overflowX={"hidden"}
                      px="0px"
                      onCopy={(e) =>
                        CopyPasteService(e, `${window.location.href}/1`)
                      }
                    >
                      <Chronology main={true} />
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  id="scrollToMasterlists"
                  height={{ base: "100%", md: "450px" }}
                  px={{ base: "4", md: "16" }}
                  marginTop="20px"
                  paddingY={"4"}
                >
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <Link to={`/research-categories/${alias}/masterlists`}>
                        <Text
                          fontSize={"30px"}
                          fontWeight={700}
                          lineHeight={"28px"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {research_category.MASTERLISTS_TAB_TITLE}
                        </Text>
                      </Link>
                    </HStack>
                    <HStack>
                      <Link to={`/research-categories/${alias}/masterlists`}>
                        <Text
                          pb="4"
                          fontSize={"18px"}
                          fontWeight={700}
                          lineHeight={"20px"}
                          cursor={"pointer"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {button.VIEW_ALL}
                        </Text>
                      </Link>
                    </HStack>
                  </Stack>
                  <Masterlist rcAlias={alias} />
                </Box>
                <Box
                  id="scrollToPosts"
                  height="500px"
                  px={{ base: "4", md: "16" }}
                  marginTop="20px"
                  paddingY={"4"}
                >
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <Link to={`/research-categories/${alias}/posts`}>
                        <Text
                          fontSize={"30px"}
                          fontWeight={700}
                          lineHeight={"28px"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {research_category.POSTS_TAB_TITLE}
                        </Text>
                      </Link>
                    </HStack>
                    <HStack>
                      <Link to={`/research-categories/${alias}/posts`}>
                        <Text
                          pb="4"
                          fontSize={"18px"}
                          fontWeight={700}
                          lineHeight={"20px"}
                          cursor={"pointer"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {button.VIEW_ALL}
                        </Text>
                      </Link>
                    </HStack>
                  </Stack>
                  <Posts />
                </Box>
                <Box
                  id="scrollToInsights"
                  px={{ base: "4", md: "16" }}
                  marginTop="20px"
                  paddingY={"4"}
                >
                  <Stack pt="8" flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <Link to={`/research-categories/${alias}/insights`}>
                        <Text
                          fontSize={"30px"}
                          fontWeight={700}
                          lineHeight={"28px"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {research_category.INSIGHTS_TAB_TITLE}
                        </Text>
                      </Link>
                    </HStack>
                    <HStack>
                      <Link to={`/research-categories/${alias}/insights`}>
                        <Text
                          pb="4"
                          fontSize={"18px"}
                          fontWeight={700}
                          lineHeight={"20px"}
                          cursor={"pointer"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {button.VIEW_ALL}
                        </Text>
                      </Link>
                    </HStack>
                  </Stack>
                  <Posts />
                </Box>
                <Box
                  id="scrollToResearchCentre"
                  height="100%"
                  px={{ base: "4", md: "16" }}
                  marginTop="20px"
                >
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <Link
                        color="black"
                        to={`/research-categories/${alias}/research-centre`}
                      >
                        <Text
                          pb="4"
                          fontSize={"30px"}
                          fontWeight={700}
                          lineHeight={"28px"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                           {research_category.RESEARCH_CENTRE_TAB_TITLE}
                        </Text>
                      </Link>
                    </HStack>
                    <HStack>
                      <Link to={`/research-categories/${alias}/research-centre`}>
                        <Text
                          pb="4"
                          fontSize={"18px"}
                          fontWeight={700}
                          lineHeight={"20px"}
                          cursor={"pointer"}
                          color="black"
                          _hover={{
                            color: "#035DA1",
                          }}
                        >
                          {button.VIEW_ALL}
                        </Text>
                      </Link>
                    </HStack>
                  </Stack>
                    <Stack
                      pb="4"
                      flexDirection={"column"}
                      overflowY={"auto"}
                      overflowX={"hidden"}
                      px="0px"
                      onCopy={(e) =>
                        CopyPasteService(e, `${window.location.href}/1`)
                      }
                    >
                      <ResearchCenter rcAlias={alias} main={true} />
                    </Stack>
                </Box>
              </Stack>
            </Stack>
          </Container>
        </Stack>
        : (
          <WebpageComingSoon />
        )
        }
      </SidebarWithHeader>
    </>
  );
}

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
