//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const bijoyRayImg = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/Personalia/Bijoy_Ray.png`
const parentsImg = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/Personalia/parents.jpg`
const grandFatherImg = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/Personalia/grandfather.png`
const satyajit_1949 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/TeachingInfra/satyajit_1949.png`
const satyajit_1966 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/TeachingInfra/satyajit_1966.png`
const satyajit_ray_intro = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Introduction/satyajit-ray.png`
const nagpurImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/Introduction/Education/nagpur_school.png`
const diplomaImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/Introduction/Education/diploma.png`
const frenchGovImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/Introduction/Education/french_gov.png`
const songIntro1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/1.png`
const songIntro2 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/2.png`
const songIntro3 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/3.png`
const songIntro4 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/4.png`
const songIntro5 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/5.png`
const songIntro6 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/6.png`
const songIntro7 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/7.png`
const songIntro8 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/8.png`
const songIntro9 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/9.png`
const songIntro10 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/Introduction/SliderImage/10.png`
const pictorial_intro = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/Introduction/Intro.jpg`
const kg_intro = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Introduction/intro.jpg`
const kg_child1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Introduction/Personalia/child1.png`
const kg_edu1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Introduction/Education/1.png`
const kg_edu2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Introduction/Education/2.png`
const kg_teaching1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Introduction/TeachingInfra/1.png`
const rajkapoor_intro = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/raj-kapoor.jpg`
const rk_cinelogo = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/TeachingInfra/RKlogoCineTrophy.png`
const prithviraj_kapoor = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/PrithvirajKapoor.jpg`
const randhir_kapoor = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/RandhirKapoor.jpg`
const ritu_nanda = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/RituNanda.jpg`
const rishi_kapoor = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/RishiKapoor.jpg`
const rajiv_kapoor = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/RajivKapoor.jpg`
const rk_spouse = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/spouse.jpg`
const shammi_kapoor = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/ShammiKapoor.jpg`
const shashi_kapoor = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/Introduction/Personalia/ShashiKapoor.jpg`


export const IntroductionData = [
  {
    id: 1,
    title: "Art Heritage Publications",
    year: "1978 -",
    description:
      "Founded by Ebrahim and Roshen Alkazi in 1977, Art Heritage Annual Published its first edition in 1978-79. The Journal focuses on the exhibitions and reterospectives curated for the Art Heritage Gallery supplemented with the essays written by eminent art scholar and writers to compile data on the contemporary art movement in India.",
    description1: "Art Heritage Publications 1, 79",
    description2: "Art Heritage Publications, Catalogue, 80",
    description3: "Art Heritage Publications 2, 80",
    description4: "Art Heritage Publications, Catalogue, 82",
    image: "",
    founder: "Ebrahim Alkazi | Roshen Alkazi",
    publishers: "Roshen Alkazi | Art Heritage Foundation",
    editors: "Ebrahim Alkazi",
    currentDirectors: "Amal Allana",
  },
];

export const satyajit_introduction_data = [
  [
    {
      key: "alias",
      value: "satyajit-ray",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "Calcutta",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "https:--satyajitrayworld.org-",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "Taurus",
      display: "Zodiac sign",
    },
    {
      key: "time_of_birth",
      value: "04:36:00",
      display: "Time of Birth",
    },
    {
      key: "date_of_birth",
      value: "1921-05-02",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "1992-04-23",
      display: "Date of Death",
    },
    {
      key: "description",
      value:
        "India's pioneering polymath & filmmaker who provided Indian cinema that essential burst of international critical acclaim starting with Pather Panchali (1955) and thereafter sustaining a near forty year cinematic journey of rare creativity and brilliance, encompassing virtually every aspect of film-making and various interrelated subjects from literature to design, from music to science fiction.",
      display: "description",
    },
    {
      key: "id",
      value: "aa9f61e4-3de4-4f2e-a38c-ada1c5c6d6de",
      display:"id"
    },
    {
      key: "image",
      value: satyajit_ray_intro,
      display: "image",
    },
    {
      key: "full_name",
      value: "Satyajit Ray",
      display: "name",
    },
    {
      key: "nationality",
      value: "Indian",
      display: "Nationality"
    },
    {
      key: "age",
      value: "70",
      display: "Age"
    },
    {
      key: "marriageregistrationdate",
      value: "1948-10-20",
      display: "Year Marriage Registered",
    },
    {
      key: "subject",
      value: "Personality.Polymath",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [
        {
          key: "bijoya-ray",
          value: "Bijoya Ray",
          birth_date: "1917-10-27",
          death_date: "2015-06-02",
        },
      ],
      display: "Spouse(s)",
    },
    {
      key: "children",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          birth_date: "1948-10-20",
          death_date: "",
        },
      ],
      display: "Children"
    },
    {
      key: "parents",
      value: [
        {
          key: "suprabha-ray",
          value: "Suprabha Ray",
        },
        {
          key: "sukumar-ray",
          value: "Sukumar Ray",
        },
      ],
      display: "Parent(s)"
    },
    {
      key: "grandparents",
      value: [
        {
          key: "upendrakishore-ray-chowdhury",
          value: "upendrakishore ray chowdhury",
        },
        {
          key: "bidhumukhi-devi",
          value: "Bidhumukhi Devi",
        },
      ],
      display: "Grandparent(s)"
    },
    {
      key: "ancestors",
      value: [
        {
          key: "kalinath-ray",
          value: "Kalinath Ray",
          birth_date: "",
          death_date: "",
        },
        {
          key: "dwarkanath-ganguly",
          value: "Dwarkanath Ganguly",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Ancestor(s)"
    },
    {
      key: "education",
      value: [
        {
          key: "ballygunge-government-high-school-calcutta",
          value: "Ballygunge Government High School Calcutta",
          year: "1929",
          id: "88f3e6db-876f-475a-b8ec-54a3d3e5f5ff",
          image: "",
        },
        {
          key: "presidency-college-calcutta",
          value: "Presidency College Calcutta",
          year: "1936-1939",
          id: "e183825f-dd47-4060-af3b-12e5721377a7",
          image: "",
        },
        {
          key: "visva-bharati-university-santiniketan",
          value: "Visva-Bharati University Santiniketan",
          year: "1940-1942",
          id: "f25c4c9c-5433-4571-a3f8-930524cae501",
          image: "",
        },
      ],
      display: "Education",
    },
    {
      key: "teaching_infrastructure",
      value: [
        {
          key: "indian-new-wave-cinema-and-parallel-cinema",
          value: "Indian New Wave Cinema and Parallel Cinema",
          display_date: "NA",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "jury-manila-international-film-festival",
          value: "Jury Manila International Film Festival",
          display_date: "1982",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-vienna-film-festival",
          value: "Jury Vienna Film Festival",
          display_date: "1960",
          post: "Official Position",
          image: "",
        },
        {
          key: "president-sci-fi-cine-club-calcutta",
          value: "President Sci-Fi Cine Club Calcutta",
          display_date: "1966-01-26",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "ftii-pune",
          value: "FTII Pune",
          display_date: "NA",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "jury-chairman-for-feature-films-3rd-international-film-festival-of-india-new-delhi",
          value:
            "Jury Chairman for Feature Films 3rd International Film Festival of India New Delhi",
          display_date: "1965",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-venice-film-festival",
          value: "Jury Venice Film Festival",
          display_date: "1982",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-film-festival-in-tunisia",
          value: "Jury Film Festival in Tunisia",
          display_date: "1968",
          post: "Official Position",
          image: "",
        },
        {
          key: "founder-calcutta-film-society-calcutta",
          value: "Founder Calcutta Film Society Calcutta",
          display_date: "10 May 1947 - Till Death",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "advisor-and-founding-member-chitrabani-calcutta",
          value: "Advisor and Founding Member Chitrabani Calcutta",
          display_date: "1970",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "jury-tehran-film-festival",
          value: "Jury Tehran Film Festival",
          display_date: "1972",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-3rd-moscow-international-film-festival",
          value: "Jury 3rd Moscow International Film Festival",
          display_date: "1963",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-chairman-6th-international-film-festival-of-india",
          value: "Jury Chairman 6th International Film Festival of India",
          display_date: "1977",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-chairman-5th-international-film-festival-of-india-new-delhi",
          value:
            "Jury Chairman 5th International Film Festival of India New Delhi",
          display_date: "1974-1975",
          post: "Official Position",
          image: "",
        },
        {
          key: "international-feature-film-jury-berlin-film-festival",
          value: "International Feature Film Jury Berlin Film Festival",
          display_date: "1961",
          post: "Official Position",
          image: "",
        },
      ],
      display: "Teaching & Infrastructure-Building"
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "1_CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
export const satyajit_education_data = [
  {
    key: "ballygunge-government-high-school-calcutta",
    value: "Ballygunge Government High School Calcutta",
    year: "1929",
    id: "1b0669c7-72fc-4a49-a9a0-206205a1acfa",
    image: "",
  },
  {
    key: "presidency-college-calcutta",
    value: "Presidency College Calcutta",
    year: "1936-1939",
    id: "599e55bf-7d83-47e9-b897-facf2eb782b2",
    image: "",
  },
  {
    key: "visva-bharati-university-santiniketan",
    value: "Visva-Bharati University Santiniketan",
    year: "1940-1942",
    id: "db929bdd-d4e7-4fd0-a78f-30c76bcc76a1",
    image: "",
  },
];
export const satyajit_teaching_data = [
  {
    key: "jury-vienna-film-festival",
    value: "Jury Vienna Film Festival",
    display_date: "1960",
    post: "Official Position",
    image: "",
  },
  {
    key: "international-feature-film-jury-berlin-film-festival",
    value: "International Feature Film Jury Berlin Film Festival",
    display_date: "1961",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-3rd-moscow-international-film-festival",
    value: "Jury 3rd Moscow International Film Festival",
    display_date: "1963",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-chairman-for-feature-films-3rd-international-film-festival-of-india-new-delhi",
    value:
      "Jury Chairman for Feature Films 3rd International Film Festival of India New Delhi",
    display_date: "1965",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-film-festival-in-tunisia",
    value: "Jury Film Festival in Tunisia",
    display_date: "1968",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-tehran-film-festival",
    value: "Jury Tehran Film Festival",
    display_date: "1972",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-chairman-5th-international-film-festival-of-india-new-delhi",
    value: "Jury Chairman 5th International Film Festival of India New Delhi",
    display_date: "1974",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-chairman-6th-international-film-festival-of-india",
    value: "Jury Chairman 6th International Film Festival of India",
    display_date: "1977",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-venice-film-festival",
    value: "Jury Venice Film Festival",
    display_date: "1982",
    post: "Official Position",
    image: "",
  },
  {
    key: "jury-manila-international-film-festival",
    value: "Jury Manila International Film Festival",
    display_date: "1982",
    post: "Official Position",
    image: "",
  },
  {
    key: "founder-calcutta-film-society-calcutta",
    value: "Founder Calcutta Film Society Calcutta",
    display_date: "1947",
    post: "Associated Institution",
    image: satyajit_1949,
  },
  {
    key: "president-sci-fi-cine-club-calcutta",
    value: "President Sci-Fi Cine Club Calcutta",
    display_date: "1966",
    post: "Associated Institution",
    image: satyajit_1966,
  },
  {
    key: "advisor-and-founding-member-chitrabani-calcutta",
    value: "Advisor and Founding Member Chitrabani Calcutta",
    display_date: "1970",
    post: "Associated Institution",
    image: "",
  },
  {
    key: "ftii-pune",
    value: "FTII Pune",
    display_date: "NA",
    post: "Associated Institution",
    image: "",
  },
  {
    key: "indian-new-wave-cinema-and-parallel-cinema",
    value: "Indian New Wave Cinema and Parallel Cinema",
    display_date: "NA",
    post: "Associated Movements",
    image: "",
  },
];

export const satyajit_personalia_data=[
  {
    key: "date_of_birth",
    value: "1921-05-02",
    display: "Date of Birth"
},
  {
      key: "time_of_birth",
      value: "04:36:00",
      display: "Time of Birth"
  },
  {
      key: "zodiac_sign",
      value: "Taurus",
      display: "Zodiac Sign"
  },
  {
      key: "date_of_death",
      value: "1992-04-23",
      display: "Date of Death"
  },
  {
      key: "age",
      value: "70",
      display: "Age"
  },
  {
      key: "nationality",
      value: "Indian",
      display: "Nationality"
  },
  {
      key: "place_of_birth",
      value: "Calcutta",
      display: "Place of Birth"
  },
  {
      key: "spouse",
      value: [
          {
              key: "bijoya-ray",
              value: "Bijoya Ray",
              birth_date: "1917-10-27",
              death_date: "2015-06-02",
              image:bijoyRayImg
          }
      ],
      display: "Spouse(s)"
  },
  {
      key: "marriageregistrationdate",
      value: "1948-10-20",
      display: "Year Marriage Registered"
  },
  {
      key: "children",
      value: [
          {
              key: "sandip-ray",
              value: "Sandip Ray",
              birth_date: "1948-10-20",
              death_date: ""
          }
      ],
      display: "Children"
  },
  {
      key: "parents",
      value: [
          {
              key: "sukumar-ray",
              value: "Sukumar Ray",
              birth_date: "",
              death_date: "",
              image:parentsImg
          },
          {
              key: "suprabha-ray",
              value: "Suprabha Ray",
              birth_date: "",
              death_date: ""
          }
      ],
      display: "Parent(s)"
  },
  {
      key: "grandparents",
      value: [
          {
              key: "upendrakishore-ray-chowdhury",
              value: "Upendrakishore Ray Chowdhury",
              birth_date: null,
              death_date: null,
              image:grandFatherImg
          },
          {
              key: "bidhumukhi-devi",
              value: "Bidhumukhi Devi",
              birth_date: "",
              death_date: "",
          }
      ],
      display: "Grand Parent(s)"
  },
  {
      key: "ancestors",
      value: [
          {
              key: "kalinath-ray",
              value: "Kalinath Ray",
              birth_date: "",
              death_date: ""
          },
          {
              key: "dwarkanath-ganguly",
              value: "Dwarkanath Ganguly",
              birth_date: "",
              death_date: ""
          }
      ],
      display: "Ancestor(s)"
  }
]

//sh-raza
export const sh_raza_introduction_data = [
  [
    {
      key: "alias",
      value: "sh-raza",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "Kakkaiya, Madhya Pradesh",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "https:--shrazaworld.org-",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "Pisces",
      display: "Zodiac sign",
    },
    {
      key: "date_of_birth",
      value: "1922-02-22",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "2016-07-23",
      display: "Date of Death",
    },
    {
      key: "description",
      value:
        "Sayed Haider Raza is one of the most prominent and groundbreaking Indian painters of his generation. During his training in France, Raza experimented with a variety of Modernist styles",
      display: "description",
    },
    {
      key: "id",
      value: "aa9f61e4-3de4-4f2e-a38c-ada1c5c6d6de",
      display:"id"
    },
    {
      key: "image",
      value: 'https://d3fsixjuo450s3.cloudfront.net/introduction/Raza-SH_bw-with-colour-painting%2001.jpg',
      display: "image",
    },
    {
      key: "full_name",
      value: "Sayed Haider Raza",
      display: "name",
    },
    {
      key: "nationality",
      value: "Indian",
      display: "Nationality"
    },
    {
      key: "age",
      value: "94",
      display: "Age"
    },
    {
      key: "subject",
      value: "Modern Indian Art",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "ARTS.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [
        {
          key: "bijoya-ray",
          value: "Bijoya Ray",
          birth_date: "1917-10-27",
          death_date: "2015-06-02",
        },
      ],
      display: "Spouse(s)",
    },
    {
      key: "parents",
      value: [
        {
          key: "suprabha-ray",
          value: "Suprabha Ray",
        },
        {
          key: "sukumar-ray",
          value: "Sukumar Ray",
        },
      ],
      display: "Parent(s)"
    },
    {
      key: "education",
      value: [
        {
          key: "ballygunge-government-high-school-calcutta",
          value: "Ballygunge Government High School Calcutta",
          year: "1929",
          id: "88f3e6db-876f-475a-b8ec-54a3d3e5f5ff",
          image: "",
        },
        {
          key: "presidency-college-calcutta",
          value: "Presidency College Calcutta",
          year: "1936-1939",
          id: "e183825f-dd47-4060-af3b-12e5721377a7",
          image: "",
        },
        {
          key: "visva-bharati-university-santiniketan",
          value: "Visva-Bharati University Santiniketan",
          year: "1940-1942",
          id: "f25c4c9c-5433-4571-a3f8-930524cae501",
          image: "",
        },
      ],
      display: "Education",
    },
    {
      key: "teaching_infrastructure",
      value: [
        {
          key: "indian-new-wave-cinema-and-parallel-cinema",
          value: "Indian New Wave Cinema and Parallel Cinema",
          display_date: "NA",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "jury-manila-international-film-festival",
          value: "Jury Manila International Film Festival",
          display_date: "1982",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-vienna-film-festival",
          value: "Jury Vienna Film Festival",
          display_date: "1960",
          post: "Official Position",
          image: "",
        },
        {
          key: "president-sci-fi-cine-club-calcutta",
          value: "President Sci-Fi Cine Club Calcutta",
          display_date: "1966-01-26",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "ftii-pune",
          value: "FTII Pune",
          display_date: "NA",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "jury-chairman-for-feature-films-3rd-international-film-festival-of-india-new-delhi",
          value:
            "Jury Chairman for Feature Films 3rd International Film Festival of India New Delhi",
          display_date: "1965",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-venice-film-festival",
          value: "Jury Venice Film Festival",
          display_date: "1982",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-film-festival-in-tunisia",
          value: "Jury Film Festival in Tunisia",
          display_date: "1968",
          post: "Official Position",
          image: "",
        },
        {
          key: "founder-calcutta-film-society-calcutta",
          value: "Founder Calcutta Film Society Calcutta",
          display_date: "10 May 1947 - Till Death",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "advisor-and-founding-member-chitrabani-calcutta",
          value: "Advisor and Founding Member Chitrabani Calcutta",
          display_date: "1970",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "jury-tehran-film-festival",
          value: "Jury Tehran Film Festival",
          display_date: "1972",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-3rd-moscow-international-film-festival",
          value: "Jury 3rd Moscow International Film Festival",
          display_date: "1963",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-chairman-6th-international-film-festival-of-india",
          value: "Jury Chairman 6th International Film Festival of India",
          display_date: "1977",
          post: "Official Position",
          image: "",
        },
        {
          key: "jury-chairman-5th-international-film-festival-of-india-new-delhi",
          value:
            "Jury Chairman 5th International Film Festival of India New Delhi",
          display_date: "1974-1975",
          post: "Official Position",
          image: "",
        },
        {
          key: "international-feature-film-jury-berlin-film-festival",
          value: "International Feature Film Jury Berlin Film Festival",
          display_date: "1961",
          post: "Official Position",
          image: "",
        },
      ],
      display: "Teaching & Infrastructure-Building"
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "FineArts as a Key Educational Resource",
          value: "FineArts",
          link: "fine-arts-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
export const sh_raza_personalia_data=[
  {
    key: "date_of_birth",
    value: "1923-02-22",
    display: "Date of Birth"
},
  {
      key: "zodiac_sign",
      value: "Pisces",
      display: "Zodiac Sign"
  },
  {
      key: "date_of_death",
      value: "2016-07-23",
      display: "Date of Death"
  },
  {
      key: "age",
      value: "94",
      display: "Age"
  },
  {
      key: "nationality",
      value: "Indian",
      display: "Nationality"
  },
  {
      key: "place_of_birth",
      value: "Kakkaiya, Madhya Pradesh",
      display: "Place of Birth"
  },
  {
      key: "spouse",
      value: [
          {
              key: "janine-mongillat",
              value: "Janine Mongillat",
              birth_date: "",
              death_date: "",
              image:""
          }
      ],
      display: "Spouse(s)"
  },
  {
      key: "parents",
      value: [
          {
              key: "sayed-mohammed-razi",
              value: "Sayed Mohammed Razi",
              birth_date: "",
              death_date: "",
              image:""
          },
          {
              key: "tahira-begum",
              value: "Tahira Begum",
              birth_date: "",
              death_date: ""
          }
      ],
      display: "Parent(s)"
  }
]
export const sh_raza_education_data = [
  {
    key: "Nagpur School of Art, Nagpur",
    value: "Nagpur School of Art, Nagpur",
    year: "1939",
    id: "1b0669c7-72fc-4a49-a9a0-206205a1acfa",
    image: nagpurImg,
  },
  {
    key: "Diploma of Paintings and Drawings from Sir J.J. School of Art, Mumbai, India",
    value: "Diploma of Paintings and Drawings from Sir J.J. School of Art, Mumbai, India",
    year: "1943-47",
    id: "599e55bf-7d83-47e9-b897-facf2eb782b2",
    image: diplomaImg,
  },
  {
    key: "Scholarship from French Government, Ecole Nationale des Beaux-Arts, Paris, France",
    value: "Scholarship from French Government, Ecole Nationale des Beaux-Arts, Paris, France",
    year: "1950-1953",
    id: "db929bdd-d4e7-4fd0-a78f-30c76bcc76a1",
    image: frenchGovImg,
  },
];
export const sh_raza_teaching_data = [
  {
    key: "Visiting Lecturer at the Art Department University of California, Berkeley, USA",
    value: "Visiting Lecturer at the Art Department University of California, Berkeley, USA",
    display_date: "1962",
    post: "Associated Institution",
    image: "",
  },
  {
    key: "Fellowship, Lalit Kala Akademi, New Delhi, India",
    value: "Fellowship, Lalit Kala Akademi, New Delhi, India",
    display_date: "1961",
    post: "Associated Institution",
    image: "",
  }
];


//suresh jindal
export const suresh_jindal_introduction_data = [
  [
    {
      key: "alias",
      value: "suresh-jindal",
      display:"Alias",
    },
    {
      key: "name",
      value: "Suresh Jindal",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];

//javed-siddiqi
export const javed_siddiqi_introduction_data = [
  [
    {
      key: "alias",
      value: "javed-siddiqi",
      display:"Alias",
    },
    {
      key: "name",
      value: "Javed Siddiqi",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
//shama-zaidi
export const shama_zaidi_introduction_data = [
  [
    {
      key: "alias",
      value: "shama-zaidi",
      display:"Alias",
    },
    {
      key: "name",
      value: "Shama Zaidi",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
//Birju Maharaj
export const birju_maharaj_introduction_data = [
  [
    {
      key: "alias",
      value: "birju-maharaj",
      display:"Alias",
    },
    {
      key: "name",
      value: "Birju Maharaj",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
// Dulal Dutta 
export const dulal_dutta_introduction_data = [
  [
    {
      key: "alias",
      value: "dulal-dutta",
      display:"Alias",
    },
    {
      key: "name",
      value: "Dulal Dutta",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//Soumendu Roy
export const soumendu_roy_introduction_data = [
  [
    {
      key: "alias",
      value: "soumendu-roy",
      display:"Alias",
    },
    {
      key: "name",
      value: "Soumendu Roy",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//Bansi Chandragupta
export const bansi_chandragupta_introduction_data = [
  [
    {
      key: "alias",
      value: "bansi-chandragupta",
      display:"Alias",
    },
    {
      key: "name",
      value: "Bansi Chandragupta",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//Nemai Ghosh 
export const nemai_ghosh_introduction_data = [
  [
    {
      key: "alias",
      value: "nemai-ghosh",
      display:"Alias",
    },
    {
      key: "name",
      value: "Nemai Ghosh",
      display: "name",
    },
    {
      key: "description",
      value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit ",
      display: "description",
    },
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//Sandip Ray 
export const sandip_ray_introduction_data = [
  [
    {
      key: "alias",
      value: "sandip-ray",
      display:"Alias",
    },
    {
      key: "name",
      value: "Sandip Ray",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//sumatra_ghoshal
export const sumatra_ghoshal_introduction_data = [
  [
    {
      key: "alias",
      value: "sumatra-ghoshal",
      display:"Alias",
    },
    {
      key: "name",
      value: "Sumatra Ghoshal",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//pablo-bartholomew
export const pablo_bartholomew_introduction_data = [
  [
    {
      key: "alias",
      value: "pablo-bartholomew",
      display:"Alias",
    },
    {
      key: "name",
      value: "Pablo Bartholomew",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
]; 
//saswati-sen
export const saswati_sen_introduction_data = [
  [
    {
      key: "alias",
      value: "saswati-sen",
      display:"Alias",
    },
    {
      key: "name",
      value: "Saswati Sen",
      display: "name",
    },
    
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
//song-synopsis-booklet
export const song_synopsis_booklet_data = [
  [
    {
      key: "alias",
      value: "song-synopsis-booklet",
      display:"Alias",
    },
    {
      key: "name",
      value: "Song Synopsis Booklet",
      display: "name",
    },
    {
      key: "song_from",
      value: "1931",
      display: "Time of Birth",
    },
    {
      key: "song_till",
      value: "2023",
      display: "Date of Birth",
    },
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "Object.CINE.ssb",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
//Art heritage annual
export const art_heritage_annual_data = [
  [
    {
      key: "alias",
      value: "art-heritage-annual",
      display:"Alias",
    },
    {
      key: "name",
      value: "Art Heritage Annual",
      display: "name",
    },
    {
      key: "journal_from",
      value: "1978",
      display: "",
    },
    {
      key: "journal_till",
      value: "Going concern",
      display: "",
    },
    {
      key: "subject",
      value: "Journal",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "object-book-jou",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Modern & Contemporary Fine Arts in India",
          value: "FineArts",
          link: "modern-&-contemporary-fine-arts-in-india",
        },
      ],
      display: "researchCategories",
    },
  ],
];
export const songsSynopsisBookletData=[
  {
    text:"Accredited as one of the most comprehensive documents to understand the history and evolution of Indian Cinema, the Song Synopsis Booklet, was produced as the promotional material for the distributors to provide an insight into the film, including cast and credits, a short synopsis and the song lyrics. Gathering influences from across the world and different kinds of cinema promotional booklets and objects, the SSB evolved into its own, earning the status of a rare collectible over the course of time. Traversing the history of over eight decades, the booklet has evolved from theatre advertisements to Hollywood cinema handbills to the synopsis booklets of the silent era to the song synopsis booklet, an object intrinsically unique to the Indian sensibilities.",
    sliderImage:[songIntro1,
      songIntro2,
      songIntro3,
      songIntro4,
      songIntro5,
      songIntro6,
      songIntro7,
      songIntro8,
      songIntro9,
      songIntro10,]
  }
]

//Pictorial_Space
export const pictorial_space_annual_data = [
  [
    {
      key: "alias",
      value: "pictorial_space",
      display:"Alias",
    },
    {
      key: "description",
      value:
        "A work of Art is situated in space. But it will not do to say it simply exists in space; a work of art treats space according to it own needs, defines space and even creates such space as may be necessary to it. The space of life is a known quantity to which life readily submits; the space of art is a plastic and changing material; (Henri Focillon)",
      display: "description",
    },
    {
      key: "name",
      value: "Pictorial Space",
      display: "name",
    },
    {
      key: "journal_from",
      value: "14 Dec 1977",
      display: "",
    },
    {
      key: "journal_till",
      value: "03 Jan 1978",
      display: "",
    },
    {
      key: "introduction_desc",
      value: `"Pictorial Space" presented a unique perspective on contemporary Indian art, showcasing the diverse range of artistic expression and the evolution of visual aesthetics in India during the late 1970s. This exhibition, conceived and compiled by the eminent art critic and historian Geeta Kapur, offered an unparalleled exploration of the dynamic and evolving landscape of Indian art."`,
      display: "Introduction",
    },
    {
      key: "curator",
      value: "Geeta Kapur",
      display: "Curator",
    },
    {
      key: "venue",
      value: "Lalit Kala Akademi Rabindra Bhavan Galleries, New Delhi",
      display: "Venue",
    },
    {
      key: "date_of_exhibition",
      value: "14 December 1977 - 03 January 1978",
      display: "Date of Exhibition",
    },
    {
      key: "organizers",
      value: "Lalit Kala Akademi",
      display: "Organizers",
    },
    {
      key: "subject",
      value: "BOOK.exc.group",
      display: "subject",
    },
    {
      key: "artists",
      value: [
        {
          name: "Agarwal",
          image: "",
        },
        {
          name: "Jaikrishna",
          image: "",
        },
        {
          name: "Barwe, Prabhakar",
          image: "",
        },
        {
          name: "Bhaskaran",
          image: "",
        },
        {
          name: "Bhattacharjee, Bikash",
          image: "",
        },
        {
          name: "Chaudhury, Jogen",
          image: "",
        },
        {
          name: " Das, AmitavaDhavan",
          image: "",
        },
        {
          name: "Gopinath, P.",
          image: "",
        },
        {
          name: "Goud. Laxma",
          image: "",
        },
        {
          name: "Gujral. Satish",
          image: "",
        },
        {
          name: "Hore, Somnath",
          image: "",
        },
        {
          name: "Husain, M.F",
          image: "",
        },
        {
          name: "Kaloka. R.S",
          image: "",
        },
        {
          name: "Khakhar, Bhupen",
          image: "",
        },
        {
          name: "Khanna, Krishen",
          image: "",
        },
        {
          name: "Malani, Nalini",
          image: "",
        },
        {
          name: "Mehra, Rajesh",
          image: "",
        },
        {
          name: "Mehta, Tyeb",
          image: "",
        },
        {
          name: "Mohamedi, Nasreen",
          image: "",
        },
        {
          name: "Nayar, Ved",
          image: "",
        },
        {
          name: "Padamsee. Akbar",
          image: "",
        },
        {
          name: "Paniker. K.C.S.",
          image: "",
        },
        {
          name: "Parekh, Madhvi",
          image: "",
        },
        {
          name: "Parekh, Manu",
          image: "",
        },
        {
          name: "Patel, Gieve",
          image: "",
        },
        {
          name: "Patel, Jeram",
          image: "",
        },
        {
          name: "Pyne, Ganesh",
          image: "",
        },
        {
          name: "Raj Mona",
          image: "",
        },
        {
          name: "Ram Kumar",
          image: "",
        },
        {
          name: "A. Ramachandran",
          image: "",
        },
        {
          name: "Ramanujam",
          image: "",
        },
        {
          name: "Raza, S.H.",
          image: "",
        },
        {
          name: "Reddy Gagan",
          image: "",
        },
      ],
      display: "Artists",
    },

    {
      key: "masterlist_type",
      value: "Event Exhibition",
      display: "masterlist_type",
    },
    {
      key: "caption",
      value: "A Point of View on Contemporary Indian Art",
      display: "caption",
    },
    {
      key: "exhibiton_type",
      value: "Group Exhibition",
      display: "exhibiton_type",
    },
    {
      key: "image",
      value: pictorial_intro,
      display: "image",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Modern & Contemporary Fine Arts in India",
          value: "FineArts",
          link: "modern-&-contemporary-fine-arts-in-india",
        },
      ],
      display: "researchCategories",
    },
  ],
];

//Location - Lucknow
export const lucknow_data = [
  [
    {
      key: "alias",
      value: "Lucknow",
      display:"Alias",
    },
    {
      key: "name",
      value: "Lucknow",
      display: "name",
    },
    {
      key: "masterlist_type",
      value: "Lucknow",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Location",
          value: "Location",
          link: "locaction",
        },
      ],
      display: "researchCategories",
    },
  ],
];
export const locationIntroductionData=[
  {
    key:"geographicaldata",
    value:[{
      key: "indian_state",
      value: "Uttar Pradesh",
      display: "Indian State",
    },
    {
      key: "capital_city",
      value: "Yes",
      display: "Capital City",
    },
    {
      key:"longitude",
      value:"80.9462° E",
      display:"Longitude"
    },
    {
      key:"latitude",
      value:"26.8467° N",
      display:"Latitude"
    },
    {
      key:"population",
      value:"631 km²",
      display:"Population"
    },
    {
      key:"current_area",
      value:"3,500,000",
      display:"Current Area"
    },
    {
      key:"language",
      value:["Hindi","Awadhi","Urdu"],
      display:"Language"
    },
    {
      key:"rivers",
      value:"Gomati River (Tributary of Ganga)",
      display:"Rivers"
    },
    {
      key:"Lakes",
      value:["Kathauta Jheel","Butler Lake","Ghanta Ghar Talab"],
      display:"Lakes"
    }
  ],
    display:"Geographical Details"
  },
  {
    key:"landmark",
    value:[],
    display:"Landmark"
  }
]

//Persoanlity Film-Maker - Raj-Kapoor
export const raj_kapoor_introduction_data = [
  [
    {
      key: "alias",
      value: "raj-kapoor",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "Peshawar",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "Saggitarius",
      display: "Zodiac sign",
    },
    {
      key: "time_of_birth",
      value: " ",
      display: "Time of Birth",
    },
    {
      key: "date_of_birth",
      value: "1924-12-14",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "1988-02-06",
      display: "Date of Death",
    },
    {
      key: "description",
      value:
        "",
      display: "description",
    },
    {
      key: "id",
      value: "aa9f61e4-3de4-4f2e-a38c-ada1c5c6d6de",
      display:"id"
    },
    {
      key: "image",
      value: rajkapoor_intro,
      display: "image",
    },
    {
      key: "full_name",
      value: "Raj Kapoor",
      display: "name",
    },
    {
      key: "nationality",
      value: "British Indian | Indian",
      display: "Nationality"
    },
    {
      key: "age",
      value: "63",
      display: "Age"
    },
    {
      key: "marriageregistrationdate",
      value: " ",
      display: "Year Marriage Registered",
    },
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [
        {
          key: "krishna-raj-kapoor",
          value: "Krishna Raj Kapoor",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Spouse(s)",
    },
    {
      key: "children",
      value: [
        {
          key: "randhir-kapoor",
          value: "Randhir Kapoor",
          birth_date: "",
          death_date: "",
        },
        {
          key: "ritu-nanda",
          value: "Ritu Nanda",
          birth_date: "",
          death_date: "",
        },
        {
          key: "rishi-kapoor",
          value: "Rishi Kapoor",
          birth_date: "",
          death_date: "",
        },
        {
          key: "rima-jain",
          value: "Rima Jain | rajiv-kapoor",
          birth_date: "",
          death_date: "",
        },
        {
          key: "rajiv-kapoor",
          value: "Rajiv Kapoor",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Children"
    },
    {
      key: "parents",
      value: [
        {
          key: "prithviraj-kapoor",
          value: "PrithvirajKapoor",
        },
      ],
      display: "Parent(s)"
    },
    {
      key: "grandparents",
      value: [
        {
          key: "lala-bisheshwar-nath",
          value: "Lala Bisheshwar Nath",
        },
      ],
      display: "Grandparent(s)"
    },
    {
      key: "ancestors",
      value: [
        {
          key: "tehsildar-dewan-keshomal",
          value: "Tehsildar Dewan Keshomal",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Ancestor(s)"
    },
    {
      key: "education",
      value: [
        {
          key: "colonel-browns-school-dehradun",
          value: "Colonel Browns School Dehradun",
          year: "",
          id: "",
          image: "",
        },
        {
          key: "antonio-de-souza-school-byculla",
          value: "Antonio de Souza School Byculla",
          year: "",
          id: "",
          image: "",
        },
        {
          key: "new-era-boys-school-hughes-road-bombay",
          value: "New Era Boys School Hughes Road Bombay",
          year: "",
          id: "",
          image: "",
        },
      ],
      display: "Education",
    },
    {
      key: "teaching_infrastructure",
      value: [
        {
          key: "r-k-films",
          value: "R K Films",
          display_date: "1948",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "",
          value: "",
          display_date: "",
          post: "Official Position",
          image: "",
        },
      ],
      display: "Teaching & Infrastructure-Building"
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];

export const raj_kapoor_education_data = [
      {
        key: "colonel-browns-school-dehradun",
        value: "Colonel Browns School Dehradun",
        year: "",
        id: "",
        image: "",
      },
      {
        key: "antonio-de-souza-school-byculla",
        value: "Antonio de Souza School Byculla",
        year: "",
        id: "",
        image: "",
      },
      {
        key: "new-era-boys-school-hughes-road-bombay",
        value: "New Era Boys School Hughes Road Bombay",
        year: "",
        id: "",
        image: "",
      },
];
export const raj_kapoor_teaching_data = [
  {
    key: "r-k-films",
    value: "R K Films",
    display_date: "1948",
    post: "Associated Institution",
    image: rk_cinelogo,
  },
];

export const raj_kapoor_personalia_data=[
  {
    key: "date_of_birth",
    value: "1924-12-14",
    display: "Date of Birth"
 },
  {
      key: "time_of_birth",
      value: "",
      display: "Time of Birth"
  },
  {
      key: "zodiac_sign",
      value: "Saggitarius",
      display: "Zodiac Sign"
  },
  {
      key: "date_of_death",
      value: "1988-02-06",
      display: "Date of Death"
  },
  {
    key: "age",
    value: "63",
    display: "Age"
  },
  {
    key: "nationality",
    value: "British Indian | Indian",
    display: "Nationality"
  },
  {
      key: "place_of_birth",
      value: "Peshawar",
      display: "Place of Birth"
  },
  {
    key: "spouse",
    value: [
      {
        key: "krishna-raj-kapoor",
        value: "krishna Raj Kapoor",
        birth_date: "",
        death_date: "",
        image:rk_spouse,
      },
    ],
    display: "Spouse(s)",
  },
  {
    key: "children",
    value: [
      {
        key: "randhir-kapoor",
        value: "Randhir Kapoor",
        birth_date: "",
        death_date: "",
        image:randhir_kapoor,
      },
      {
        key: "ritu-nanda",
        value: "Ritu Nanda",
        birth_date: "",
        death_date: "",
        image:ritu_nanda,
      },
      {
        key: "rishi-kapoor",
        value: "Rishi Kapoor",
        birth_date: "",
        death_date: "",
        image:rishi_kapoor,
      },
      {
        key: "rima-jain",
        value: "Rima Jain | rajiv-kapoor",
        birth_date: "",
        death_date: "",
      },
      {
        key: "rajiv-kapoor",
        value: "Rajiv Kapoor",
        birth_date: "",
        death_date: "",
        image:rajiv_kapoor,
      },
    ],
    display: "Children"
  },
  {
    key: "siblings",
    value: [
        {
            key: "shammi-kapoor,",
            value: "Shammi Kapoor,",
            birth_date: "",
            death_date: "",
            image:shammi_kapoor,
        },
        {
          key: "shashi-kapoor,",
          value: "Shashi Kapoor,",
          birth_date: "",
          death_date: "",
          image:shashi_kapoor,
      }
    ],
    display: "Siblings"
  },
  {
    key: "parents",
    value: [
      {
        key: "prithviraj-kapoor",
        value: "Prithviraj Kapoor",
        image:prithviraj_kapoor,
      },
    ],
    display: "Parent(s)"
  },
  {
    key: "grandparents",
    value: [
      {
        key: "lala-bisheshwar-nath",
        value: "Lala Bisheshwar Nath",
      },
    ],
    display: "Grandparent(s)"
  },
  {
    key: "ancestors",
    value: [
      {
        key: "tehsildar-dewan-keshomal",
        value: "Tehsildar Dewan Keshomal",
        birth_date: "",
        death_date: "",
      },
    ],
    display: "Ancestor(s)"
  },
]


//Personality-Artist - kg_subramanym
export const kg_subramanym_introduction_data = [
  [
    {
      key: "alias",
      value: "kg_subramanym",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "Kuthuparambu, North Kerala",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "Aquarius",
      display: "Zodiac sign",
    },
    {
      key: "date_of_birth",
      value: "1924-02-15",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "2016-06-29",
      display: "Date of Death",
    },
    {
      key: "description",
      value:"K. G. Subramanyan was one of the most exciting, versatile and polymathic artists of India. He was, among other things, a seminal thinker and intellectual, a prolific writer and critic of art and an artist encompassing an ecclectic range of medium.",
      display: "description",
    },
    {
      key: "id",
      value: "aa9f61e4-3de4-4f2e-a38c-ada1c5c6d6de",
      display:"id"
    },
    {
      key: "image",
      value: kg_intro,
      display: "image",
    },
    {
      key: "full_name",
      value: "K. G. Subramanyan",
      display: "name",
    },
    {
      key: "nationality",
      value: "Indian",
      display: "Nationality"
    },
    {
      key: "age",
      value: "92",
      display: "Age"
    },
    {
      key: "subject",
      value: "Modern Indian Art",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "ARTS.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [
        {
          key: "Susheela-jasra",
          value: "Susheela Jasra",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Spouse(s)",
    },
    {
      key: "parents",
      value: [
        {
          key: "Alamellu",
          value: "Alamellu",
        },
        {
          key: "Ganapati-Iyer",
          value: "Ganapati Iyer",
        },
      ],
      display: "Parent(s)"
    }
    ,{
      key: "researchCategories",
      value: [
        {
          key: "FineArts as a Key Educational Resource",
          value: "FineArts",
          link: "fine-arts-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];
export const kg_subramanym_personalia_data=[
  {
    key: "zodiac_sign",
    value: "Aquarius",
    display: "Zodiac sign",
  },
  {
    key: "date_of_birth",
    value: "1924-02-15",
    display: "Date of Birth",
  },
  {
    key: "date_of_death",
    value: "2016-06-29",
    display: "Date of Death",
  },
  {
      key: "age",
      value: "92",
      display: "Age"
  },
  {
      key: "nationality",
      value: "Indian",
      display: "Nationality"
  },
  {
      key: "place_of_birth",
      value: "Kuthuparambu, North Kerala",
      display: "Place of Birth"
  },
  {
    key: "marriageregistrationdate",
    value: "1950",
    display: "Year of Wedding"
},
{
    key: "children",
    value: [
        {
            key: "Uma-Padmanabhan",
            value: "Uma Padmanabhan",
            birth_date: "",
            death_date: "",
            image:kg_child1
        }
    ],
    display: "Children"
},
{
  key: "siblings",
  value: [
      {
          key: "Parameshwara-Iyer,",
          value: "Parameshwara Iyer,",
          birth_date: "",
          death_date: ""
      },
      {
        key: "Narayanaswamy,",
        value: "Narayanaswamy,",
        birth_date: "",
        death_date: ""
    },{
      key: "Meenakshi",
      value: "Meenakshi",
      birth_date: "",
      death_date: ""
  }
  ],
  display: "siblings"
},
  {
      key: "spouse",
      value: [
          {
              key: "Susheela-Jasra",
              value: "Susheela Jasra",
              birth_date: "",
              death_date: "",
              image:""
          }
      ],
      display: "Spouse(s)"
  },
  {
      key: "parents",
      value: [
          {
              key: "Alamellu",
              value: "Alamellu",
              birth_date: "",
              death_date: "",
              image:""
          },
          {
              key: "Ganapati-Iyer",
              value: "Ganapati Iyer",
              birth_date: "",
              death_date: ""
          }
      ],
      display: "Parent(s)"
  }
]
export const kg_subramanym_education_data = [
  {
    key: "Presidency College, Madras",
    value: "Presidency College, Madras",
    year: "1942-43",
    id: "1b0669c7-72fc-4a49-a9a0-206205a1acfa",
    image: "",
  },
  {
    key: "Kala Bhavan, Visva Bharati, Shantniketan, West Bengal",
    value: "Kala Bhavan, Visva Bharati, Shantniketan, West Bengal",
    year: "1944-48",
    id: "599e55bf-7d83-47e9-b897-facf2eb782b2",
    image: kg_edu1,
  },
  {
    key: "British Council Research Scholarship to SladeSchool of Art, London, UK",
    value: "British Council Research Scholarship to SladeSchool of Art, London, UK",
    year: "1955-1956",
    id: "db929bdd-d4e7-4fd0-a78f-30c76bcc76a1",
    image: kg_edu2,
  },
];
export const kg_subramanym_teaching_data = [
  {
    key: "Lecturer in Painting, Faculty of Fine Arts, Baroda",
    value: "Lecturer in Painting, Faculty of Fine Arts, Baroda",
    display_date: "1951-59",
    post: "Associated Institution",
    image: "",
  },
  {
    key: "Deputy Director (Design), All India Handloom Board, Bombay",
    value: "Deputy Director (Design), All India Handloom Board, Bombay",
    display_date: "1959-61",
    post: "Associated Institution",
    image: "",
    },
    {
    key: "Reader in Painting, Faculty of Fine Arts, Baroda",
    value: "Reader in Painting, Faculty of Fine Arts, Baroda",
    display_date: "1961-65",
    post: "Associated Institution",
    image: "",
    },
    {
    key: "Design Consultant, All India Handloom Board",
    value: "Design Consultant, All India Handloom Board",
    display_date: "1961-66",
    post: "Associated Institution",
    image: "",
    },
    {
    key: "Professor of Painting, Faculty of Fine Arts, Baroda",
    value: "Professor of Painting, Faculty of Fine Arts, Baroda",
    display_date: "1966-80",
    post: "Associated Institution",
    image: "",
    },
    {
    key: "Fellowship of The J.D. Rockefeller III Fund, New York",
    value: "Fellowship of The J.D. Rockefeller III Fund, New York",
    display_date: "1966-67",
    post: "Associated Institution",
    image: kg_teaching1,
    },
    {
    key: "Dean, Faculty of Fine Arts, Baroda",
    value: "Dean, Faculty of Fine Arts, Baroda",
    display_date: "1968-74",
    post: "Associated Institution",
    image: "",
    },
    {
    key: "Elected to the World Crafts Council",
    value: "Elected to the World Crafts Council",
    display_date: "1975",
    post: "Associated Institution",
    image: "",
    },
    {
    key: "Delegate, Asian Assembly, World Crafts Council, Sydney",
    value: "Delegate, Asian Assembly, World Crafts Council, Sydney",
    display_date: "1975",
    post: "Associated Institution",
    image: "",
    },
{
  key: "All India Board of Technical Students in Applied Art",
  value: "All India Board of Technical Students in Applied Art",
  display_date: "1961-67",
  post: "Memberships/Associated Institutions",
  image: "",
  },
  {
  key: "Gujarat Lalit Kala Akademi",
  value: "Gujarat Lalit Kala Akademi",
  display_date: "1961-1965",
  post: "Memberships/Associated Institutions",
  image: "",
  },
  {
  key: "Lalit Kala Akademi",
  value: "Lalit Kala Akademi",
  display_date: "1967-79",
  post: "Memberships/Associated Institutions",
  image: "",
  }
];

//Location - Lucknow
export const calcutta_data = [
  [
    {
      key: "alias",
      value: "Calcutta",
      display:"Alias",
    },
    {
      key: "name",
      value: "Calcutta",
      display: "name",
    },
    {
      key: "masterlist_type",
      value: "Calcutta",
      display: "masterlist_type",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Location",
          value: "Location",
          link: "locaction",
        },
      ],
      display: "researchCategories",
    },
  ],
];

////Persoanlity Actor - Amitabh Bachchan
export const amitabh_bachchan_introduction_data = [
  [
    {
      key: "alias",
      value: "amitabh-bachchan",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "",
      display: "Zodiac sign",
    },
    {
      key: "time_of_birth",
      value: " ",
      display: "Time of Birth",
    },
    {
      key: "date_of_birth",
      value: "",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "",
      display: "Date of Death",
    },
    {
      key: "description",
      value:
        "",
      display: "description",
    },
    {
      key: "id",
      value: "aa9f61e4-3de4-4f2e-a38c-ada1c5c6d6de",
      display:"id"
    },
    {
      key: "image",
      value: "",
      display: "image",
    },
    {
      key: "full_name",
      value: "Amitabh Bachchan",
      display: "name",
    },
    {
      key: "nationality",
      value: "",
      display: "Nationality"
    },
    {
      key: "age",
      value: "",
      display: "Age"
    },
    {
      key: "marriageregistrationdate",
      value: " ",
      display: "Year Marriage Registered",
    },
    {
      key: "subject",
      value: "Indian Cinema",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Spouse(s)",
    },
    {
      key: "children",
      value: [
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Children"
    },
    {
      key: "parents",
      value: [
        {
          key: "",
          value: "",
        },
      ],
      display: "Parent(s)"
    },
    {
      key: "grandparents",
      value: [
        {
          key: "",
          value: "",
        },
      ],
      display: "Grandparent(s)"
    },
    {
      key: "ancestors",
      value: [
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Ancestor(s)"
    },
    {
      key: "education",
      value: [
        {
          key: "",
          value: "",
          year: "",
          id: "",
          image: "",
        },
        {
          key: "",
          value: "",
          year: "",
          id: "",
          image: "",
        },
        {
          key: "",
          value: "",
          year: "",
          id: "",
          image: "",
        },
      ],
      display: "Education",
    },
    {
      key: "teaching_infrastructure",
      value: [
        {
          key: "",
          value: "",
          display_date: "",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "",
          value: "",
          display_date: "",
          post: "Official Position",
          image: "",
        },
      ],
      display: "Teaching & Infrastructure-Building"
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];

export const amitabh_bachchan_education_data = [
  {
    key: "",
    value: "",
    year: "",
    id: "",
    image: "",
  },
  {
    key: "",
    value: "",
    year: "",
    id: "",
    image: "",
  },
  {
    key: "",
    value: "",
    year: "",
    id: "",
    image: "",
  },
];
export const amitabh_bachchan_teaching_data = [
{
key: "",
value: "",
display_date: "",
post: "Associated Institution",
image: "",
},
];

export const amitabh_bachchan_personalia_data=[
{
key: "date_of_birth",
value: "",
display: "Date of Birth"
},
{
  key: "time_of_birth",
  value: "",
  display: "Time of Birth"
},
{
  key: "zodiac_sign",
  value: "",
  display: "Zodiac Sign"
},
{
  key: "date_of_death",
  value: "",
  display: "Date of Death"
},
{
key: "age",
value: "",
display: "Age"
},
{
key: "nationality",
value: "",
display: "Nationality"
},
{
  key: "place_of_birth",
  value: "",
  display: "Place of Birth"
},
{
key: "spouse",
value: [
  {
    key: "bijoya-ray",
    value: "",
    birth_date: "",
    death_date: "",
  },
],
display: "Spouse(s)",
},
{
key: "children",
value: [
  {
    key: "",
    value: "",
    birth_date: "",
    death_date: "",
  },
  {
    key: "",
    value: "",
    birth_date: "",
    death_date: "",
  },
  {
    key: "",
    value: "",
    birth_date: "",
    death_date: "",
  },
  {
    key: "",
    value: "",
    birth_date: "",
    death_date: "",
  },
  {
    key: "",
    value: "",
    birth_date: "",
    death_date: "",
  },
],
display: "Children"
},
{
key: "parents",
value: [
  {
    key: "",
    value: "",
  },
],
display: "Parent(s)"
},
{
key: "grandparents",
value: [
  {
    key: "",
    value: "",
  },
],
display: "Grandparent(s)"
},
{
key: "ancestors",
value: [
  {
    key: "",
    value: "",
    birth_date: "",
    death_date: "",
  },
],
display: "Ancestor(s)"
},
]
export const sanjeev_kumar_introduction_data = [
  [
    {
      key: "alias",
      value: "sanjeev-kumar",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "",
      display: "Zodiac sign",
    },
    {
      key: "time_of_birth",
      value: "",
      display: "Time of Birth",
    },
    {
      key: "date_of_birth",
      value: "",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "",
      display: "Date of Death",
    },
    {
      key: "description",
      value:
        "",
      display: "description",
    },
    {
      key: "id",
      value: "",
      display:"id"
    },
    {
      key: "image",
      value: "",
      display: "image",
    },
    {
      key: "full_name",
      value: "Sanjeev Kumar",
      display: "name",
    },
    {
      key: "nationality",
      value: "",
      display: "Nationality"
    },
    {
      key: "age",
      value: "",
      display: "Age"
    },
    {
      key: "marriageregistrationdate",
      value: "",
      display: "Year Marriage Registered",
    },
    {
      key: "subject",
      value: "Personality.Polymath",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [],
      display: "Spouse(s)",
    },
    {
      key: "children",
      value: [ ],
      display: "Children"
    },
    {
      key: "parents",
      value: [],
      display: "Parent(s)"
    },
    {
      key: "grandparents",
      value: [],
      display: "Grandparent(s)"
    },
    {
      key: "ancestors",
      value: [],
      display: "Ancestor(s)"
    },
    {
      key: "education",
      value: [],
      display: "Education",
    },
    {
      key: "teaching_infrastructure",
      value: [],
      display: "Teaching & Infrastructure-Building"
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "1_CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];

// Personality Actor- Shammi Kapoor

export const shammi_kapoor_introduction_data = [
  [
    {
      key: "alias",
      value: "shammi-kapoor",
      display:"Alias",
    },
    {
      key: "place_of_birth",
      value: "",
      display:"Place of Birth",
    },
    {
      key: "official_website",
      value: "",
      display:"Official Website",
    },
    {
      key: "zodiac_sign",
      value: "",
      display: "Zodiac sign",
    },
    {
      key: "time_of_birth",
      value: "",
      display: "Time of Birth",
    },
    {
      key: "date_of_birth",
      value: "21 October 1931",
      display: "Date of Birth",
    },
    {
      key: "date_of_death",
      value: "14 August 2011",
      display: "Date of Death",
    },
    {
      key: "description",
      value:
        "",
      display: "description",
    },
    {
      key: "id",
      value: "aa9f61e4-3de4-4f2e-a38c-ada1c5c6d6de",
      display:"id"
    },
    {
      key: "image",
      value: "https://tuliresearchcentre.canto.global/direct/image/69oiu428055rb4c7ufornnee5c/nt6Dd2NnBjhheXjfp7TCbEWAKVU/m800/800",
      display: "image",
    },
    {
      key: "full_name",
      value: "Shammi Kapoor",
      display: "name",
    },
    {
      key: "nationality",
      value: "",
      display: "Nationality"
    },
    {
      key: "age",
      value: "",
      display: "Age"
    },
    {
      key: "marriageregistrationdate",
      value: " ",
      display: "Year Marriage Registered",
    },
    {
      key: "subject",
      value: "Personality.Actor",
      display: "subject",
    },
    {
      key: "masterlist_type",
      value: "CINE.Personality",
      display: "masterlist_type",
    },
    {
      key: "spouse",
      value: [
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Spouse(s)",
    },
    {
      key: "children",
      value: [
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Children"
    },
    {
      key: "parents",
      value: [
        {
          key: "",
          value: "",
        },
      ],
      display: "Parent(s)"
    },
    {
      key: "grandparents",
      value: [
        {
          key: "",
          value: "",
        },
      ],
      display: "Grandparent(s)"
    },
    {
      key: "ancestors",
      value: [
        {
          key: "",
          value: "",
          birth_date: "",
          death_date: "",
        },
      ],
      display: "Ancestor(s)"
    },
    {
      key: "education",
      value: [
        {
          key: "",
          value: "",
          year: "",
          id: "",
          image: "",
        },
        {
          key: "",
          value: "",
          year: "",
          id: "",
          image: "",
        },
        {
          key: "",
          value: "",
          year: "",
          id: "",
          image: "",
        },
      ],
      display: "Education",
    },
    {
      key: "teaching_infrastructure",
      value: [
        {
          key: "",
          value: "",
          display_date: "",
          post: "Associated Institution",
          image: "",
        },
        {
          key: "",
          value: "",
          display_date: "",
          post: "Official Position",
          image: "",
        },
      ],
      display: "Teaching & Infrastructure-Building"
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "1_CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "researchCategories",
    },
  ],
];