export const schema = [
  { key: "alias", display: "Alias", seq: 1 },
  { key: "place_of_birth", display: "Place of Birth", seq: 2 },
  { key: "official_website", display: "Official Website", seq: 3 },
  { key: "zodiac_sign", display: "Zodiac Sign", seq: 4 },
  { key: "time_of_birth", display: "Time of Birth", seq: 5 },
  { key: "date_of_birth", display: "Date of Birth", seq: 6 },
  { key: "date_of_death", display: "Date of Death", seq: 7 },
  { key: "description", display: "Description", seq: 8 },
  { key: "id", display: "Id", seq: 9 },
  { key: "image", display: "Image", seq: 10 },
  { key: "full_name", display: "Full Name", seq: 11 },
  { key: "nationality", display: "Nationality", seq: 12 },
  { key: "age", display: "Age", seq: 13 },
  {
    key: "marriageregistrationdate",
    display: "Year Marriage Registered",
    seq: 14,
  },
  { key: "subject", display: "Subject", seq: 15 },
  { key: "masterlist_type", display: "Masterlist Type", seq: 16 },
  {
    key: "spouse",
    display: "Spouse(s)",
    processor: "familyAliasArray",
    seq: 17,
  },
  {
    key: "children",
    display: "Children",
    processor: "familyAliasArray",
    seq: 18,
  },
  {
    key: "parents",
    display: "Parent(s)",
    processor: "familyAliasArray",
    seq: 19,
  },
  {
    key: "grandparents",
    display: "Grand Parent(s)",
    processor: "familyAliasArray",
    seq: 20,
  },
  {
    key: "ancestors",
    display: "Ancestor(s)",
    processor: "familyAliasArray",
    seq: 21,
  },
  {
    key: "education",
    display: "Education",
    processor: "educationAliasArray",
    seq: 22,
  },
  {
    key: "teaching_infrastructure",
    display: "Teaching & Infrastructure-Building",
    processor: "teachingInfraAliasArray",
    seq: 23,
  },
  {
    key: "researchCategories",
    display: "Research Categories",
    processor: "aliasArray",
    seq: 24,
  },
];

export const educationSchema = [
  { key: "alias", display: "Alias", seq: 1 },
  {
    key: "education",
    display: "Education",
    processor: "educationAliasArray",
    seq: 22,
  }
];

export const teachingInfraSchema = [
  { key: "alias", display: "Alias", seq: 1 },
  {
    key: "teaching_infrastructure",
    display: "Teaching & Infrastructure-Building",
    processor: "teachingInfraAliasArray",
    seq: 23,
  },
];
