import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import dateFormat from "dateformat";

// Service Files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getFilmSpecificationData from "../../../services/Film";

// Subsection Files
import { EffectFlip, Navigation } from "swiper";
import ImageSlider from "../../../FunctionalComponents/ImageSlider/ImageSlider";
import { isArray } from "lodash";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

function Specifications({ winnerName, tableName }) {
  const [showLabel, setShowLabel] = useState(true);
  const [certificateFullScreenImage, setCertificateFullScreenImage] = useState(false);
  const [certificateImgData, setCertificateImgData] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);

  const showCertificateImages = (image, flag) => {
    if (image) {
      setCertificateFullScreenImage(flag);
      setCertificateImgData(image);
    } else {
      setCertificateFullScreenImage(flag);
      setCertificateImgData(null);
    }
  };

  const showCertificateImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 200);
  };

  const [specificationData, setSpecificationData] = useState([{}]);
  useEffect(() => {
    const getData = async () => {
      const result = await getFilmSpecificationData.filmSpecificationData(
        tableName,
        winnerName
      );
      if (result.length > 0) {
        setShowLabel(false);
        setSpecificationData(
          result
            ?.map((item) => {
              if (
                item?.key !== "alias" &&
                item?.display !== "certificateImages"
              ) {
                return {
                  display:
                    item?.value !== null && item?.value.length > 0
                      ? item?.display
                      : "",
                  value: isArray(item?.value)
                    ? item?.value.map((it) => it.name)
                    :(item?.key==="date_of_issue" || item?.key==="date_of_expiry") ? dateFormat(item?.value,"dd mmmm yyyy") :item?.value,
                };
              }
              return null;
            })
            .filter(Boolean)
        );
        setCertificateImgData(
          result
            ?.map((item) => {
              if (item?.display === "certificateImages") {
                return {
                  display: item?.display,
                  image: isArray(item?.value)
                    ? item?.value.map((it) => {
                      return{
                      imgPath:it?.value,
                      desc:`${it?.name_of_publication} | ${it?.date_of_artwork} | ${it?.accession_number}`
                    }})
                    : item?.value,
                };
              }
              return null;
            })
            .filter(Boolean)
        );
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return showLabel ? (
    <Stack height={"100vh"}>
     <WebpageComingSoon />
    </Stack>
  ) : (
    <>
      <ImagePopupModal
        isOpen={certificateFullScreenImage}
        onClose={() => showCertificateImages(null, false)}
        imageData={certificateImgData}
        filmographyData={specificationData}
      />
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={10}
        marginTop={"20px"}
        height={"100vh"}
        width={{ base: "100%", md: "1145px" }}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
      >
        <HStack
          alignItems={"flex-start"}
          marginTop="0.0rem!important"
          flexDirection={"column"}
          className="col-sm-12 col-md-6"
          px="0px"
          gap={0}
        >
          {specificationData &&
            specificationData?.map((it) => {
              return (
                <Stack
                  flexDirection={"row"}
                  alignItems={"baseline"}
                  paddingBottom={"4px"}
                  margin={"0px !important"}
                >
                  {it.display === "Certificate Number" &&
                    certificateImgData[0]?.image?.length > 0 && (
                      <AiOutlinePicture
                        onClick={() => showCertificateImage(0)}
                        cursor={"pointer"}
                        color="#1E90FF"
                        size={20}
                        style={{ position: "absolute", marginTop: "4px" }}
                      />
                    )}
                  <HStack paddingLeft="24px" width={"202px"}>
                    <Text
                      fontSize={"15px"}
                      fontWeight={600}
                      lineHeight={"17px"}
                    >
                      {it.display}
                    </Text>
                  </HStack>
                  <HStack paddingLeft="4px">
                    <Text
                      fontSize={"15px"}
                      fontWeight={400}
                      lineHeight={"17px"}
                    >
                      {isArray(it.value) ? it.value.join(" , ") : it.value}
                    </Text>
                  </HStack>
                </Stack>
              );
            })}
        </HStack>
        {/* new code end */}
        <HStack className="mt-0">
          <Stack
            className="col-12 mt-0"
            id="allsliderskk"
            position={"relative"}
          >
            <HStack  className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
              {certificateImgData[0]?.image?.length > 0 && (
                <ImageSlider
                  sliderData={certificateImgData[0].image}
                  width={"100%"}
                  height={{ base: "100%", md: "auto" }}
                  classname={"myslide"}
                  setSwiper={setSwiper}
                  grabCursor={false}
                  modules={[EffectFlip, Navigation]}
                />
              )}
            </HStack>
          </Stack>
        </HStack>
      </Stack>
    </>
  );
}
export default Specifications;

const ImagePopupModal = ({ isOpen, onClose, imageData }) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody>
          <Box bg="white" mx="10">
            <Stack>
              <HStack
                justifyContent={"center"}
                flexDirection={"column"}
                marginTop={"15px"}
              >
                <Image src={imageData} height={"90vh"} alt="" />
              </HStack>
              <HStack justifyContent="flex-end">
                <Text
                  fontSize={"xl"}
                  bg={"black"}
                  opacity={"0.6"}
                  color={"white"}
                  fontWeight={"400"}
                  padding="2"
                  cursor={"pointer"}
                  borderRadius={"35px"}
                  width="max-content"
                  marginTop="-200px"
                  marginRight={"100px"}
                  onClick={onClose}
                >
                  <CgArrowsExpandRight />
                </Text>
              </HStack>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
