//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner1 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner1.jpg`
const Banner2 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner2.jpg`
const Banner3 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner3.jpg`
const Banner4 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner4.jpg`
const Banner5 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner5.jpg`
const Banner6 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner6.jpg`
const Img1 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/1.jpg`
const Img2 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/2.jpg`
const Img3 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/3.jpg`
const Img4 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/4.jpg`
const Img5 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/5.jpg`
const Img6 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/6.jpg`
const Img7 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/7.jpg`
const Img8 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/8.jpg`

export const HomeData = [
  {
    id: 1,
    imgPath: Banner1,
    title: "",
    link1: "",
    link2: "",
    link3: "",
    link4: "",
    button1: "Tuli Research Centre",
    button2: "Students",
    button3: "OsianamaWorld.org",
    description: "",
    subSlider: [],
  },
  {
    id: 2,
    imgPath: Img1,
    title: "",
    link1: "",
    link2: "",
    link3: "",
    link4: "",
    button1: "Keyword",
    button2: "Keyword",
    button3: "Keyword",
    description: "",
    subSlider: [
      { id: 1, imgPath: Img2 },
      { id: 2, imgPath: Img3 },
      { id: 3, imgPath: Img4 },
      { id: 4, imgPath: Img5 },
      { id: 5, imgPath: Img6 },
      { id: 6, imgPath: Img7 },
      { id: 7, imgPath: Img8 },
    ],
  },
  {
    id: 3,
    imgPath: Banner2,
    link1: "/whats-new-page/3",
    link2: "/sayed-haider-raza/introduction",
    link3: "#",
    link4: "#",
    title: "Carcassonne",
    button1: "S.H. Raza",
    button2: "1956",
    button3: "Indian Artist in France",
    description:
      "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
  },
  {
    id: 4,
    imgPath: Banner3,
    link1: "/whats-new-page/4",
    link2: "/satyajit-ray/introduction",
    link3: "/content-awaited",
    link4: "/content-awaited",
    title: "The Big City Poster",
    button1: "Satyajit Ray",
    button2: "1963",
    button3: "Indian Cinema",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam… Read More",
  },
  {
    id: 5,
    imgPath: Banner4,
    link1: "/whats-new-page/5",
    link2: "/content-awaited",
    link3: "/content-awaited",
    link4: "/content-awaited",
    title: "Gandhiji Visiting Political Prisoners At Dum Dum Jail In 1947",
    button1: "M.K. Gandhi",
    button2: "1960",
    button3: "Historical Epic",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam… Read More",
  },
  {
    id: 6,
    imgPath: Banner5,
    link1: "/whats-new-page/6",
    link2: "/location/introduction",
    link3: "/content-awaited",
    link4: "/content-awaited",
    title: "Rumi Darwaaza",
    button1: "Lucknow",
    button2: "1784",
    button3: "Indian Architecure",
    description:
      "Rumi Darwaaza is an imposing gateway which was built by Nawab Asaf-Ud-Daula in 1784.",
  },
  {
    id: 7,
    imgPath: Banner6,
    link1: "/whats-new-page/7",
    link2: "/location/introduction",
    link3: "/content-awaited",
    link4: "/content-awaited",
    title: "The Swan Car",
    button1: "Calcutta",
    button2: "1910",
    button3: "Automobilia",
    description:
      "In 1910, a wealthy Scotsman, Robert Nicholl Matthewson, took a swan-shaped…",
  },
];
