import axios from "axios";
import { transformJSON } from "../util/transformer";
import { ResearchCategorydummyData } from "../MockData/Research_Category";
import { schema } from "./schemas/ResearchCategory";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const getResearchCategoriesAPI = async () => {
  const graphqlQuery = {
    query: `query Query {researchCategories {
            alias
            id
            name
            abbreviation
            relevance_to_self_discovery_journey
            research_category_as_in_website
          }
        }`,
  };

  const result = await axios.post(baseUrl, graphqlQuery);

  if (result?.data?.data?.researchCategories) {
    return result?.data?.data?.researchCategories;
  } else {
    return [];
  }
};

export const getResearchCategories = async () => {
  if (isTransformationNotAllowed() === true) {
    const response = await getResearchCategoriesDummyData();
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getResearchCategoriesAPI();
    try{
      if (response) {  
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const getResearchCategoriesDummyData = async () => {
  return ResearchCategorydummyData;
}
