//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const PreFull3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Full3.png`
const MughalEAzamTitle = `${IMAGE_BASE_PATH}/ui/Film/MughalEAzam/TitleImage/mu-e-azam.png`
const MughalEAzamFilmmain = `${IMAGE_BASE_PATH}/ui/Film/MughalEAzam/TitleImage/filmMain.png`
const synp1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Synopsis/1.png`
const synp2 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Synopsis/2.png`
const synp3 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/Synopsis/3.png`
const placeholderImg = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/place.png`
const Img1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/7.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/8.png`

export const skk_preface_data = [
  {
    key: "short_description",
    value:
      "The historical period drama Shatranj Ke Khilari (The Chess Players), is Satyajit Ray's only full-length Hindi-language feature film, based on literary giant Munshi Premchand's short story rooted amid the glorious, yet seemingly decadent, Awadhi culture just before the 1857 Mutiny (Rebellion), during the rule of Nawab Wajid Ali of Lucknow to the entrenchment of a colonial British Rule which follows post 1858. Starring stalwarts Saeed Jaffrey, Amjad Khan, Sanjeev Kumar, and Shabana Azmi, the film was shot on location in Jaipur and Lucknow. ",
    display: "Short Description",
  },
  {
    key: "posterImages",
    value: [
      {
        key: "silsila-cine-pos-0000051",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/qo20crpujd5jd6m0h03di7j51d/IQ0u10yWZp_co5EUSD4XLjY5tFk/m800/800",
        date_of_artwork: [{key:"date_of_artwork", display:"Date of Artwork",value:"1977"}],
        wnn: "0000051",
        accession_number: "CINE.pos",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
        description1:"",
        content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        poster_designer:[{
          key:"poster_designer",
          display:"Poster Designer",
          value:"Satyajit Ray"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Delight Offset Works"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Satyajit Ray"
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Technica"
        }]
      },
      {
        key: "shatranj-ke-khilari-1977-cine-pos-0000050",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/acfri9vr053sp17vlo67811g07/-OoiuKecP6oFspw2PBROwzJGd8w/m800/800",
          date_of_artwork: [{display:"Date of Artwork",value:"1977"}],
          wnn: "0000050",
        accession_number: "CINE.pos",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
        description1:"",
        content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        poster_designer:[{
          key:"poster_designer",
          display:"Poster Designer",
          value:"Satyajit Ray"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Delight Offset Works"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Satyajit Ray"
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Technica"
        }]
      },
      {
        key: "shatranj-ke-khilari-1977-cine-pos-0000052",
        value:"https://tuliresearchcentre.canto.global/direct/image/l1j8mv0hrp5hddgeko7nd4ol3v/p7Q2NLuDlPcYHqgM0ZtfdN99YCE/m800/800",
        date_of_artwork: [{display:"Date of Artwork",value:"1977"}],
          description1:"Zehra Tyabji designed letterheads, handlooms for me, all the stationery and so on. Zehra did these designs, but of course the final say in a Ray film was always Mr. Ray. Because he himself was a phenomenal designer. Normally in his film he did everything. So he conveyed the ideas to Zehra, she came with the sketches and designs and Ray approved the final poster.",
          content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
          wnn: "0000052",
        accession_number: "CINE.pos",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
        poster_designer:[{
          key:"poster_designer",
          display:"Poster Designer",
          value:"Satyajit Ray"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Delight Offset Works"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Satyajit Ray"
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Technica"
        }]
      },
    ],
    display: "posterImages",
  },
  {
    key: "publicityImages",
    value: [
      {
        key: "shatranj-ke-khilari-1977-cine-lby-0000054",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/q3o6cul2g16cp9qqs409dhii64/1AibPt8QA4tPrqAn9tcwA0GjgF0/m800/800",
          date_of_artwork: [{display:"Date of Artwork",value:"1977"}],
          wnn: "0000054",
        accession_number: "CINE.lby",
        content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Lobby Card",
        revised_medium: "CINE.lby",
        publicity_designer:[{
          key:"publicity_designer",
          display:"Publicity Designer",
          value:"Satyajit Ray"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Delight Offset Works"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Bansi Chandragupta "
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Technica"
        }]
      },
      {
        key: "costume-worn-by-sanjeev-kumar-in-the-film-shatranj-ke-khilari-1977-past-auction_costume-0000055",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/1v0j16av894t93bhrkuucj3a47/iWYvxPMkWzfpocdLmvigauqaZEY/m800/800",
          date_of_artwork: [{display:"Date of Artwork",value:"1977"}],
          wnn: "0000055",
          content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        accession_number: "Past Auction_Costume",
        name_of_publication:
          "Costume worn by Sanjeev Kumar in the film Shatranj Ke Khilari",
        artist_name: "Shama Zaidi",
        medium_of_artwork: "Silk from Benaras (COS), 1977",
        revised_medium: "CINE.maq",
        costume_designer:[{
          key:"costume_designer",
          display:"Costume Designer",
          value:"Shama Zaidi"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Delight Offset Works"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Content Awaited"
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Content Awaited"
        }]
      },
      {
        key: "shatranj-ke-khilari-1977-showcard-0000057",
        value: PreFull3,
        date_of_artwork: [{display:"Date of Artwork",value:"1977"}],
        wnn: null,
        accession_number: null,
        content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        name_of_publication: "Peter Strausfeld",
        artist_name: "1977",
        medium_of_artwork: "Showcard",
        revised_medium: "CINE.shw",
        publicity_designer:[{
          key:"publicity_designer",
          display:"Publicity Designer",
          value:"Peter Strausfeld"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Content Awaited"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Content Awaited"
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Content Awaited"
        }]
      },
      {
        key: "shatranj-ke-khilari-1977-cine-phl-0000056",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/tr005cqss56nn8bb9qmp897m12/v2iCDDugHHZhzR9p4o6jl_4oQqY/m800/800",
          date_of_artwork: [{display:"Date of Artwork",value:"1977"}],
          wnn: "0000056",
        accession_number: "CINE.phl",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
        medium_of_artwork: "Photographic Still Mounted on Lobby Card",
        revised_medium: "CINE.phl",
        publicity_designer:[{
          key:"publicity_designer",
          display:"Publicity Designer",
          value:"Satyajit Ray"
        }],
        printer:[{
          key:"printer",
          display:"Printer",
          value:"Delight Offset Works"
        }],
        art_director:[{
          key:"art_director",
          display:"Art Director",
          value:"Content Awaited"
        }],
        still_photographer:[{
          key:"still_photographer",
          display:"Still Photographer",
          value:"Content Awaited"
        }]
      },
    ],
    display: "publicityImages",
  },
  {
    key: "alias",
    value: "shatranj-ke-khilari-1977",
    display: "Alias",
  },
];

export const skk_specification_data = [
  {
    key: "alias",
    value: "shatranj-ke-khilari-1977",
    display: "Alias",
  },
  {
    key: "rated",
    value: "U",
    display: "Rated",
  },
  {
    key: "language",
    value: [
      {
        name: "English",
        alias: "English",
      },
      {
        name: "Urdu",
        alias: "Urdu",
      },
      {
        name: "Hindi",
        alias: "Hindi",
      },
    ],
    display: "Languages",
  },
  {
    key: "duration_mins",
    value: "115 mins",
    display: "Duration",
  },
  {
    key: "color",
    value: "Eastman Color",
    display: "Color",
  },
  {
    key: "lab",
    value: "Gemini Color Lab, Madras",
    display: "LAB",
  },
  {
    key: "gauge",
    value: "35 mm",
    display: "Gauge",
  },
  {
    key: "length_of_reels",
    value: "3434.18 m",
    display: "Length of Reels",
  },
  {
    key: "number_of_reels",
    value: "14",
    display: "Number of Reels",
  },
  {
    key: "shooting_period",
    value: "December 1976 - June 1977",
    display: "Shooting Period",
  },
  {
    key: "Studio",
    value: [
      {
        name: "Indrapuri, Calcutta",
        alias: "Indrapuri, Calcutta",
      },
      {
        name: "Rajkamal Kalamandir, Bombay",
        alias: "Rajkamal Kalamandir, Bombay",
      },
    ],
    display: "Studio",
  },
  {
    key: "location",
    value: [
      {
        name: "jaipur",
        alias: "jaipur",
      },
      {
        name: "lucknow",
        alias: "lucknow",
      },
    ],
    display: "On Location",
  },
  {
    key: "certificate_number",
    value: "84526",
    display: "Certificate Number",
  },
  {
    key: "certificateImages",
    value: [
      {
        key: "shatranj-ke-khilari-1977-certificate-0000053",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/uj2kkmp8rh05v8s88dtlp3jk3s/t0cD2JWZGQKGfYIUkK76RXx9Tzk/m640/800",
        date_of_artwork: "1977",
        wnn: "0000053",
        accession_number: "Certificate",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "",
        revised_medium: "",
      },
      {
        key: "shatranj-ke-khilari-1977-certificate-0000053",
        value:
          "https://d3f7r9v35rq5qh.cloudfront.net/ui/Film/ShatranjKeKhilari/DummyImage/1.png",
        date_of_artwork: "Content Awaited",
        wnn: "0000053",
        accession_number: "Content Awaited",
        name_of_publication: "Content Awaited",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "",
        revised_medium: "",
      },
    ],
    display: "certificateImages",
  },
  {
    key: "date_of_issue",
    value: "1977-10-03",
    display: "Date of Issue",
  },
  {
    key: "date_of_expiry",
    value: "1987-10-02",
    display: "Date of Expiry",
  },
];

export const skk_synopsis_data = [
  {
    key: "alias",
    value: "shatranj-ke-khilari-1977",
    display: "Alias",
  },
  {
    key: "synopsis",
    value:
      "Wajid Ali Shah is the ruler of one of the last independent kingdoms of India. The British, intent on controlling India, send General Outram on a secret mission to clear the way for an annexation. While pressure is mounting amidst intrigue and political manoeuvres, Wajid Ali Shah, secluded in his palace, composes poems and listens to music. The court is of no help, as exemplified by two slightly eccentric noblemen, Mir and Mirza who, ignoring the situation in the country and their duties towards their families, spend their days playing endless rounds of chess. The imminent threat of the British takeover of their region becomes a harsh reality. Even when they are forced to leave their comfortable homes, they continue to play chess in the mosquito ridden outdoors, with two loaded pistols, ready to oppose the British, or even themselves, if need be.",
    display: "Synopsis",
  },
  {
    key: "synopsisImages",
    value: [
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        date_of_artwork: "1977",
        wnn: "0000013",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-000013a",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
        date_of_artwork: "1977",
        wnn: "000013a",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-000013b",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
        date_of_artwork: "1977",
        wnn: "000013b",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-000013c",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/ct9tlok12l7k99tvog0gtrj50u/scLrHQp8cqEkHBQxjGtjW89Se8A/m800/800",
        date_of_artwork: "1977",
        wnn: "000013c",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Synopsis Images",
  },
];


export const skk_credits_view_data=[
  {
    key: "banner",
    value: [
      {
        key: "devki-chitra",
        value: "Devki chitra",
      },
    ],
    display: "Banner",
  },
  {
    key: "storyWriter",
    value: [
      {
        key: "premchand",
        value: "Munshi Premchand",
        first_name: "Munshi",
        last_name: "Premchand",
      },
    ],
    display: "Story",
  },
  {
    key: "leadcast",
    value: [
      {
        key: "Kumar",
        value: "Sanjeev Kumar",
        first_name: "Sanjeev",
        last_name: "Kumar",
        link: true,
      },
      {
        key: "Jaffrey",
        value: "Saeed Jaffrey",
        first_name: "Saeed",
        last_name: "Jaffrey",
      },
      {
        key: "Azmi",
        value: "Shabana Azmi",
        first_name: "Shabana",
        last_name: "Azmi",
      },
      {
        key: "Jalal",
        value: "Farida Jalal",
        first_name: "Farida",
        last_name: "Jalal",
      },
      {
        key: "Veena",
        value: "Veena",
        first_name: "Veena",
        last_name: "",
      },
      {
        key: "Abraham",
        value: "David Abraham",
        first_name: "David",
        last_name: "Abraham",
      },
      {
        key: "Bannerji",
        value: "Victor Bannerji",
        first_name: "Victor",
        last_name: "Bannerji",
      },
    ],
    display: "Lead Cast",
  },
  {
    key: "cameraman",
    value: [
      {
        key: "soumendu-roy",
        value: "Soumendu Roy",
        first_name: "Soumendu",
        last_name: "Roy",
      },
    ],
    display: "Camera",
  },
  {
    key: "assistant_of_cameraman",
    value: [
      {
        key: "anil-ghosh",
        value: "Anil Ghosh",
        first_name: "Anil",
        last_name: "Ghosh",
      },
      {
        key: "purnendu-bose",
        value: "Purnendu Bose",
        first_name: "Purnendu",
        last_name: "Bose",
      },
      {
        key: "dilip-bannerji",
        value: "Dilip Bannerji",
        first_name: "Dilip",
        last_name: "Bannerji",
      },
    ],
    display: "Assistants",
  },
  {
    key: "art_director",
    value: [
      {
        key: "bansi-chandragupta",
        value: "Bansi Chandragupta",
        first_name: "Bansi",
        last_name: "Chandragupta",
      },
    ],
    display: "Art Direction",
  },
  {
    key: "associate_of_art_director",
    value: [
      {
        key: "ashok-bose",
        value: "Ashok Bose",
        first_name: "Ashok",
        last_name: "Bose",
      },
    ],
    display: "Associate",
  },
  {
    key: "costume_designer",
    value: [
      {
        key: "shama-zaidi",
        value: "Shama Zaidi",
        first_name: "Shama",
        last_name: "Zaidi",
      },
    ],
    display: "Costumes",
  },
  {
    key: "associate_of_costume_designer",
    value: [
      {
        key: "saba-zaidi",
        value: "Saba Zaidi",
        first_name: "Saba",
        last_name: "Zaidi",
      },
      {
        key: "sitara-jaffri",
        value: "Sitara Jaffri",
        first_name: "Sitara",
        last_name: "Jaffri",
      },
      {
        key: "rabab-jaffri",
        value: "Rabab Jaffri",
        first_name: "Rabab",
        last_name: "Jaffri",
      },
    ],
    display: "Associates",
  },
  {
    key: "sound",
    value: [
      {
        key: "narinder-singh",
        value: "Narinder Singh",
        first_name: "Narinder",
        last_name: "Singh",
      },
    ],
    display: "Sound",
  },
  {
    key: "editor",
    value: [
      {
        key: "dulal-dutta",
        value: "Dulal-Dutta",
        first_name: "Dulal",
        last_name: "Dutta",
      },
      {
        key: "dulal-dutta",
        value: "Dulal Dutta",
        first_name: "Dulal",
        last_name: "Dutta",
      },
    ],
    display: "Editing",
  },
  {
    key: "assistant_of_editor",
    value: [
      {
        key: "kashinath-bose",
        value: "Kashinath Bose",
        first_name: "Kashinath",
        last_name: "Bose",
      },
    ],
    display: "Assistant",
  },
  {
    key: "make_up_artist",
    value: [
      {
        key: "ananta-das",
        value: "Ananta Das",
        first_name: "Ananta",
        last_name: "Das",
      },
    ],
    display: "Make up",
  },
  {
    key: "assistant_of_make_up_artist",
    value: [
      {
        key: "pradip-bag",
        value: "Pradip Bag",
        first_name: "Pradip",
        last_name: "Bag",
      },
    ],
    display: "Assistant",
  },
  {
    key: "wardrobe",
    value: [
      {
        key: "haru-das",
        value: "Haru Das",
        first_name: "Haru",
        last_name: "Das",
      },
    ],
    display: "Wardrobe",
  },
  {
    key: "backdrop",
    value: [
      {
        key: "kabi-dasgupta",
        value: "Kabi Dasgupta",
        first_name: "Kabi",
        last_name: "Dasgupta",
      },
    ],
    display: "Backdrop",
  },
  {
    key: "assistant_director",
    value: [
      {
        key: "ramesh-sen",
        value: "Ramesh Sen",
        first_name: "Ramesh",
        last_name: "Sen",
      },
      {
        key: "santi-kumar-chatterji",
        value: "santi kumar chatterji",
        first_name: null,
        last_name: null,
      },
      {
        key: "sandip-ray",
        value: "Sandip-Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
      {
        key: "sandip-ray",
        value: "Sandip Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
    ],
    display: "Assistant Directors",
  },
  {
    key: "special_assistant",
    value: [
      {
        key: "javed-siddiqi",
        value: "Javed Siddiqi",
        first_name: "Javed",
        last_name: "Siddiqi",
      },
    ],
    display: "Special Assistant",
  },
  {
    key: "production_executive",
    value: [
      {
        key: "anil-chowdhury",
        value: "Anil Chowdhury",
        first_name: "Anil",
        last_name: "Chowdhury",
      },
    ],
    display: "Production Executive",
  },
  {
    key: "production_manager",
    value: [
      {
        key: "bhanu-ghosh",
        value: "Bhanu Ghosh",
        first_name: "Bhanu",
        last_name: "Ghosh",
      },
    ],
    display: "Production Manager",
  },
  {
    key: "location_manager",
    value: [
      {
        key: "j-n-srivastava",
        value: "J-N Srivastava",
        first_name: "J. N.",
        last_name: "Srivastava",
      },
    ],
    display: "Location Management",
  },
  {
    key: "production_assistant",
    value: [
      {
        key: "trailokya-das",
        value: "Trailokya Das",
        first_name: "Trailokya",
        last_name: "Das",
      },
      {
        key: "bolai-addy",
        value: "Bolai Addy",
        first_name: "Bolai",
        last_name: "Addy",
      },
    ],
    display: "Production Assistants",
  },
  {
    key: "studios",
    value: [
      {
        key: "Rajkamal Kalamandir, Bombay",
        value: "Rajkamal Kalamandir, Bombay",
      },
      {
        key: "Indrapuri, Calcutta",
        value: "Indrapuri, Calcutta",
      },
    ],
    display: "Studios",
  },
  {
    key: "eastmancolor_by",
    value: "gemini-colour-laboratory-madras",
    display: "Eastmancolor By",
  },
  {
    key: "equipment_manager",
    value: [
      {
        key: "n-c-cine-service",
        value: "n c cine service",
        first_name: null,
        last_name: null,
      },
      {
        key: "cinequip",
        value: "cinequip",
        first_name: null,
        last_name: null,
      },
      {
        key: "uma-deep-enterprises",
        value: "uma deep enterprises",
        first_name: null,
        last_name: null,
      },
      {
        key: "shree-krisna-films",
        value: "shree krisna films",
        first_name: null,
        last_name: null,
      },
    ],
    display: "Equipment",
  },
  {
    key: "office_manager",
    value: [
      {
        key: "ana-maria-de-souza",
        value: "Ana-Maria-De Souza",
        first_name: "Ana Maria",
        last_name: "De Souza",
      },
      {
        key: "n-b-sukhwani",
        value: "N-B Sukhwani",
        first_name: "N. B.",
        last_name: "Sukhwani",
      },
    ],
    display: "Office Management",
  },
  {
    key: "animator",
    value: [
      {
        key: "ram-mohan",
        value: "Ram Mohan",
        first_name: "Ram ",
        last_name: "Mohan",
      },
    ],
    display: "Animation",
  },
  {
    key: "graphic_designer",
    value: [
      {
        key: "zehra-tyabji",
        value: "Zehra Tyabji",
        first_name: "Zehra",
        last_name: "Tyabji",
      },
    ],
    display: "Graphic Design",
  },
  {
    key: "publicity_liasion_officer",
    value: [
      {
        key: "uma-da-cunhe",
        value: "Uma-Da Cunhe",
        first_name: "Uma",
        last_name: "Da Cunhe",
      },
    ],
    display: "Publicity & Liasion",
  },
  {
    key: "stills_photographer",
    value: [
      {
        key: "nemai-ghosh",
        value: "Nemai-Ghosh",
        first_name: "Nemai",
        last_name: "Ghosh",
      },
      {
        key: "sandip-ray",
        value: "Sandip Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
      {
        key: "nemai-ghosh",
        value: "Nemai Ghosh",
        first_name: "Nemai",
        last_name: "Ghosh",
      },
      {
        key: "pablo-bartholomew",
        value: "pablo bartholomew",
        first_name: null,
        last_name: null,
      },
      {
        key: "sandip-ray",
        value: "Sandip-Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
      {
        key: "sumatra-ghoshal",
        value: "Sumatra Ghoshal",
        first_name: "Sumatra",
        last_name: "Ghoshal",
      },
    ],
    display: "Stills",
  },
  {
    key: "dancer",
    value: [
      {
        key: "saswati-sen",
        value: "saswati sen",
        first_name: null,
        last_name: null,
      },
      {
        key: "gitanjali",
        value: "gitanjali",
        first_name: null,
        last_name: null,
      },
      {
        key: "kathak-kalakendra-ballet-troupe-new-delhi",
        value: "kathak kalakendra ballet troupe new delhi",
        first_name: null,
        last_name: null,
      },
    ],
    display: "Dances",
  },
  {
    key: "choreographer",
    value: [
      {
        key: "birju-maharaj",
        value: "Birju Maharaj",
        first_name: "Birju",
        last_name: "Maharaj",
      },
    ],
    display: "Dances composed and choreographed by",
  },
  {
    key: "re_recorder",
    value: [
      {
        key: "mangesh-desai",
        value: "Mangesh Desai",
        first_name: "Mangesh",
        last_name: "Desai",
      },
    ],
    display: "Re-recording",
  },
  {
    key: "music_recorder",
    value: [
      {
        key: "samir-majumdar",
        value: "Samir Majumdar",
        first_name: "Samir",
        last_name: "Majumdar",
      },
    ],
    display: "Music Recording",
  },
  {
    key: "dialogue_writer",
    value: [
      {
        key: "satyajit-ray",
        value: "Satyajit Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
      {
        key: "satyajit-ray",
        value: "Satyajit-Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
      {
        key: "javed-siddiqi",
        value: "Javed Siddiqi",
        first_name: "Javed",
        last_name: "Siddiqi",
      },
      {
        key: "shama-zaidi",
        value: "Shama Zaidi",
        first_name: "Shama",
        last_name: "Zaidi",
      },
    ],
    display: "Dialogue",
  },
  {
    key: "narrator",
    value: [
      {
        key: "amitabh-bachchan",
        value: "Amitabh Bachchan",
        first_name: "Amitabh",
        last_name: "Bachchan",
      },
    ],
    display: "Narration",
  },
  {
    key: "film_producer",
    value: [
      {
        key: "suresh-jindal",
        value: "Suresh-Jindal",
        first_name: "Suresh",
        last_name: "Jindal",
      },
      {
        key: "suresh-jindal",
        value: "Suresh Jindal",
        first_name: "Suresh",
        last_name: "Jindal",
      },
    ],
    display: "Producer",
  },
  {
    key: "screenplay",
    value: [
      {
        key: "satyajit-ray",
        value: "Satyajit-Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
      {
        key: "satyajit-ray",
        value: "Satyajit Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
    ],
    display: "Screenplay, Music & Direction",
  },
];

export const skk_film_intro_data = [
  {
    key: "id",
    value: "9e42cdac-e1de-4882-b556-0fb9c9d683c8",
    display: "Id",
  },
  {
    key: "sl_no",
    value: "1",
    display: "No.",
  },
  {
    key: "alias",
    value: "shatranj-ke-khilari-1977",
    display: "Alias",
  },
  {
    key: "subject",
    value: "Film.Indian",
    display: "Subject",
  },
  {
    key: "masterlist_type",
    value: "Film",
    display: "Masterlist Type",
  },
  {
    key: "short_description",
    value:
      "The historical period drama Shatranj Ke Khilari (The Chess Players), is Satyajit Ray's only full-length Hindi-language feature film, based on literary giant Munshi Premchand's short story rooted amid the glorious, yet seemingly decadent, Awadhi culture just before the 1857 Mutiny (Rebellion), during the rule of Nawab Wajid Ali of Lucknow to the entrenchment of a colonial British Rule which follows post 1858. Starring stalwarts Saeed Jaffrey, Amjad Khan, Sanjeev Kumar, and Shabana Azmi, the film was shot on location in Jaipur and Lucknow. ",
    display: "Short Description",
  },
  {
    key: "rated",
    value: "U",
    display: "Rated",
  },
  {
    key: "duration_mins",
    value: "115 mins",
    display: "Duration",
  },
  {
    key: "certificate_number",
    value: "84526",
    display: "Certificate Number",
  },
  {
    key: "date_of_issue",
    value: "1977-10-03",
    display: "Date of Issue",
  },
  {
    key: "date_of_expiry",
    value: "1987-10-02",
    display: "Date of Expiry",
  },
  {
    key: "color",
    value: "Eastman Color",
    display: "Color",
  },
  {
    key: "lab",
    value: "Gemini Color Lab, Madras",
    display: "LAB",
  },
  {
    key: "gauge",
    value: "35 mm",
    display: "Gauge",
  },
  {
    key: "length_of_reels",
    value: "3434.18 m",
    display: "Length of Reels",
  },
  {
    key: "number_of_reels",
    value: "14",
    display: "Number of Reels",
  },
  {
    key: "shooting_period",
    value: "December 1976 - June 1977",
    display: "Shooting Period",
  },
  {
    key: "synopsis",
    value:
      "Wajid Ali Shah is the ruler of one of the last independent kingdoms of India. The British, intent on controlling India, send General Outram on a secret mission to clear the way for an annexation. While pressure is mounting amidst intrigue and political manoeuvres, Wajid Ali Shah, secluded in his palace, composes poems and listens to music. The court is of no help, as exemplified by two slightly eccentric noblemen, Mir and Mirza who, ignoring the situation in the country and their duties towards their families, spend their days playing endless rounds of chess. The imminent threat of the British takeover of their region becomes a harsh reality. Even when they are forced to leave their comfortable homes, they continue to play chess in the mosquito ridden outdoors, with two loaded pistols, ready to oppose the British, or even themselves, if need be.",
    display: "Synopsis",
  },
  {
    key: "release_date",
    value: "1977-03-11",
    display: "Release Date",
  },
  {
    key: "first_release_location",
    value: "Calcutta",
    display: "First Release Location",
    link:true,
  },
  {
    key: "trailer_link",
    value: "https://www.youtube.com/watch?v=nrU4irG-_QY",
    display: "Watch Film Trailer",
  },
  {
    key: "full_movie_link",
    value: "https://www.youtube.com/watch?v=A3Fgm0yaWbA",
    display: "Watch Full Film",
  },
  {
    key: "eastmancolor_by",
    value: "gemini-colour-laboratory-madras",
    display: "Eastmancolor By",
  },
  {
    key: "researchCategories",
    value: [
      {
        key: "Cinema as a Key Educational Resource",
        abbreviation: "1_CinemaE",
        alias: "cinema-as-a-key-educational-resource",
      },
    ],
    display: "Research Categories",
  },
  {
     key:"editorial_text",
     value:"The following is a collection of photographs, captured by Nemai Ghosh, seen in the sequential order of the film. The collection includes both photographic stills and working stills."
  },
  {
    key: "filmTitleImage",
    value: [
      {
        wnn: "0000222",
        accession_number: "",
        alias: "shatranj-ke-khilari-0000222",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "",
        date_of_artwork: "",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/5mk698cbop0ujejplih62dnb7h/CJeYFZzC0Z5056Y7ayacls6GVlo/m800/800",
        medium_of_artwork: "",
        revised_medium: "",
      },
    ],
    display: "Film Title Image",
  },
  {
    key: "posterImages",
    value: [
      {
        wnn: "0000052",
        accession_number: "CINE.pos",
        alias: "shatranj-ke-khilari-1977-cine-pos-0000052",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/l1j8mv0hrp5hddgeko7nd4ol3v/p7Q2NLuDlPcYHqgM0ZtfdN99YCE/m800/800",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
      },
      {
        wnn: "0000050",
        accession_number: "CINE.pos",
        alias: "shatranj-ke-khilari-1977-cine-pos-0000050",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/acfri9vr053sp17vlo67811g07/-OoiuKecP6oFspw2PBROwzJGd8w/m800/800",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
      },
      {
        wnn: "0000051",
        accession_number: "CINE.pos",
        alias: "shatranj-ke-khilari-1977-cine-pos-0000051",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/qo20crpujd5jd6m0h03di7j51d/IQ0u10yWZp_co5EUSD4XLjY5tFk/m800/800",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
      },
    ],
    display: "Poster Images",
  },
  {
    key: "publicityImages",
    value: [
      {
        wnn: null,
        accession_number: null,
        alias: "shatranj-ke-khilari-1977-showcard-0000057",
        name_of_publication: null,
        artist_name: null,
        date_of_artwork: null,
        image: null,
        title: null,
        direct_url_of_preview_image: null,
        medium_of_artwork: null,
        revised_medium: null,
      },
      {
        wnn: "0000056",
        accession_number: "CINE.phl",
        alias: "shatranj-ke-khilari-1977-cine-phl-0000056",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/tr005cqss56nn8bb9qmp897m12/v2iCDDugHHZhzR9p4o6jl_4oQqY/m800/800",
        medium_of_artwork: "Photographic Still Mounted on Lobby Card",
        revised_medium: "CINE.phl",
      },
      {
        wnn: "0000055",
        accession_number: "Past Auction_Costume",
        alias:
          "costume-worn-by-sanjeev-kumar-in-the-film-shatranj-ke-khilari-1977-past-auction_costume-0000055",
        name_of_publication:
          "Costume worn by Sanjeev Kumar in the film Shatranj Ke Khilari",
        artist_name: "Shama Zaidi",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1v0j16av894t93bhrkuucj3a47/iWYvxPMkWzfpocdLmvigauqaZEY/m800/800",
        medium_of_artwork: "Silk from Benaras (COS), 1977",
        revised_medium: "CINE.maq",
      },
      {
        wnn: "0000054",
        accession_number: "CINE.lby",
        alias: "shatranj-ke-khilari-1977-cine-lby-0000054",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/q3o6cul2g16cp9qqs409dhii64/1AibPt8QA4tPrqAn9tcwA0GjgF0/m800/800",
        medium_of_artwork: "Lobby Card",
        revised_medium: "CINE.lby",
      },
    ],
    display: "Publicity Images",
  },
  {
    key: "certificateImages",
    value: [
      {
        wnn: "0000053",
        accession_number: "Certificate",
        alias: "shatranj-ke-khilari-1977-certificate-0000053",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/uj2kkmp8rh05v8s88dtlp3jk3s/t0cD2JWZGQKGfYIUkK76RXx9Tzk/m640/800",
        medium_of_artwork: "",
        revised_medium: "",
      },
    ],
    display: "Certificate Images",
  },
  {
    key: "language",
    value: [
      {
        name: "Urdu",
        alias: "Urdu",
      },
      {
        name: "English",
        alias: "English",
      },
      {
        name: "Hindi",
        alias: "Hindi",
      },
    ],
    display: "Languages",
  },
  {
    key: "studios",
    value: [
      {
        name: "Rajkamal Kalamandir, Bombay",
        alias: "Rajkamal Kalamandir, Bombay",
      },
      {
        name: "Indrapuri, Calcutta",
        alias: "Indrapuri, Calcutta",
      },
    ],
    display: "Studios",
  },
  {
    key: "location",
    value: [
      {
        name: "jaipur",
        alias: "jaipur",
      },
      {
        name: "lucknow",
        alias: "lucknow",
      },
    ],
    display: "On Location",
  },
  {
    key: "synopsisImages",
    value: [
      {
        wnn: "000013a",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013a",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "000013c",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013c",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/ct9tlok12l7k99tvog0gtrj50u/scLrHQp8cqEkHBQxjGtjW89Se8A/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "0000013",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "000013b",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013b",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Synopsis Images",
  },
  {
    key: "filmMainImage",
    value: [
      {
        wnn: "0000013",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Film Main Image",
  },
  {
    key: "filmBowCreditImage",
    value: [
      {
        wnn: "000013a",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013a",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "000013b",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013b",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "0000013",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Film Bow Credit Image",
  },
  {
    key: "banner",
    value: [
      {
        name: "Devki chitra",
        alias: "devki-chitra",
      },
    ],
    display: "Banner",
  },
  {
    key: "storyWriter",
    value: [
      {
        name: null,
        alias: "premchand",
        type: "story_writer",
      },
    ],
    display: "Story Writer",
  },
  {
    key: "cameraman",
    value: [
      {
        name: null,
        alias: "soumendu-roy",
        type: "cameraman",
      },
    ],
    display: "Cameraman",
  },
  {
    key: "assistant_of_cameraman",
    value: [
      {
        name: null,
        alias: "dilip-bannerji",
        type: "assistant_of_cameraman",
      },
      {
        name: null,
        alias: "purnendu-bose",
        type: "assistant_of_cameraman",
      },
      {
        name: null,
        alias: "anil-ghosh",
        type: "assistant_of_cameraman",
      },
    ],
    display: "assistant_of_cameraman",
  },
  {
    key: "art_director",
    value: [
      {
        name: null,
        alias: "bansi-chandragupta",
        type: "art_director",
      },
    ],
    display: "art_director",
  },
  {
    key: "associate_of_art_director",
    value: [
      {
        name: null,
        alias: "ashok-bose",
        type: "associate_of_art_director",
      },
    ],
    display: "associate_of_art_director",
  },
  {
    key: "costume_designer",
    value: [
      {
        name: null,
        alias: "shama-zaidi",
        type: "dialogue_writer",
      },
    ],
    display: "costume_designer",
  },
  {
    key: "associate_of_costume_designer",
    value: [
      {
        name: null,
        alias: "saba-zaidi",
        type: "associate_of_costume_designer",
      },
      {
        name: null,
        alias: "sitara-jaffri",
        type: "associate_of_costume_designer",
      },
      {
        name: null,
        alias: "rabab-jaffri",
        type: "associate_of_costume_designer",
      },
    ],
    display: "associate_of_costume_designer",
  },
  {
    key: "sound",
    value: [
      {
        name: null,
        alias: "narinder-singh",
        type: "sound",
      },
    ],
    display: "sound",
  },
  {
    key: "assistant_of_sound",
    value: [
      {
        name: null,
        alias: "arun-chakravarty",
        type: "assistant_of_sound",
      },
      {
        name: null,
        alias: "dulal-das",
        type: "assistant_of_sound",
      },
    ],
    display: "assistant_of_sound",
  },
  {
    key: "editor",
    value: [
      {
        name: null,
        alias: "dulal-dutta",
        type: "editor",
      },
      {
        name: null,
        alias: "dulal-dutta",
        type: "editor",
      },
    ],
    display: "editor",
  },
  {
    key: "assistant_of_editor",
    value: [
      {
        name: null,
        alias: "kashinath-bose",
        type: "assistant_of_editor",
      },
    ],
    display: "assistant_of_editor",
  },
  {
    key: "make_up_artist",
    value: [
      {
        name: null,
        alias: "ananta-das",
        type: "make_up_artist",
      },
    ],
    display: "make_up_artist",
  },
  {
    key: "assistant_of_make_up_artist",
    value: [
      {
        name: null,
        alias: "pradip-bag",
        type: "assistant_of_make_up_artist",
      },
    ],
    display: "assistant_of_make_up_artist",
  },
  {
    key: "wardrobe",
    value: [
      {
        name: null,
        alias: "haru-das",
        type: "wardrobe",
      },
    ],
    display: "wardrobe",
  },
  {
    key: "backdrop",
    value: [
      {
        name: null,
        alias: "kabi-dasgupta",
        type: "backdrop",
      },
    ],
    display: "backdrop",
  },
  {
    key: "assistant_director",
    value: [
      {
        name: "santi kumar chatterji",
        alias: "santi-kumar-chatterji",
        type: "assistant_director",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "ramesh-sen",
        type: "assistant_director",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
    ],
    display: "assistant_director",
  },
  {
    key: "special_assistant",
    value: [
      {
        name: null,
        alias: "javed-siddiqi",
        type: "dialogue_writer",
      },
    ],
    display: "special_assistant",
  },
  {
    key: "production_executive",
    value: [
      {
        name: null,
        alias: "anil-chowdhury",
        type: "production_executive",
      },
    ],
    display: "production_executive",
  },
  {
    key: "production_manager",
    value: [
      {
        name: null,
        alias: "bhanu-ghosh",
        type: "production_manager",
      },
    ],
    display: "production_manager",
  },
  {
    key: "production_assistant",
    value: [
      {
        name: null,
        alias: "bolai-addy",
        type: "production_assistant",
      },
      {
        name: null,
        alias: "trailokya-das",
        type: "production_assistant",
      },
    ],
    display: "production_assistant",
  },
  {
    key: "studio",
    value: [
      {
        name: "rajkamal kalamandir",
        alias: "rajkamal-kalamandir",
      },
      {
        name: "indrapuri studios",
        alias: "indrapuri-studios",
      },
    ],
    display: "studio",
  },
  {
    key: "equipment_manager",
    value: [
      {
        name: "shree krisna films",
        alias: "shree-krisna-films",
        type: "equipment_manager",
      },
      {
        name: "uma deep enterprises",
        alias: "uma-deep-enterprises",
        type: "equipment_manager",
      },
      {
        name: "n c cine service",
        alias: "n-c-cine-service",
        type: "equipment_manager",
      },
      {
        name: "cinequip",
        alias: "cinequip",
        type: "equipment_manager",
      },
    ],
    display: "equipment_manager",
  },
  {
    key: "office_manager",
    value: [
      {
        name: null,
        alias: "n-b-sukhwani",
        type: "office_manager",
      },
      {
        name: null,
        alias: "ana-maria-de-souza",
        type: "office_manager",
      },
    ],
    display: "office_manager",
  },
  {
    key: "assistant_of_office_management",
    value: [
      {
        name: null,
        alias: "jagdish-shetty",
        type: "assistant_of_office_management",
      },
    ],
    display: "assistant_of_office_management",
  },
  {
    key: "animator",
    value: [
      {
        name: null,
        alias: "ram-mohan",
        type: "animator",
      },
    ],
    display: "animator",
  },
  {
    key: "graphic_designer",
    value: [
      {
        name: null,
        alias: "zehra-tyabji",
        type: "graphic_designer",
      },
    ],
    display: "graphic_designer",
  },
  {
    key: "assistant_of_graphic_designer",
    value: [
      {
        name: null,
        alias: "pradeep-powale",
        type: "assistant_of_graphic_designer",
      },
    ],
    display: "assistant_of_graphic_designer",
  },
  {
    key: "associate_of_graphic_designer",
    value: [
      {
        name: null,
        alias: "joseph-st-anne",
        type: "associate_of_graphic_designer",
      },
    ],
    display: "associate_of_graphic_designer",
  },
  {
    key: "publicity_liasion_officer",
    value: [
      {
        name: null,
        alias: "uma-da-cunhe",
        type: "publicity_liasion_officer",
      },
    ],
    display: "publicity_liasion_officer",
  },
  {
    key: "stills_photographer",
    value: [
      {
        name: "pablo bartholomew",
        alias: "pablo-bartholomew",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "nemai-ghosh",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "sumatra-ghoshal",
        type: "stills_photographer",
      },
    ],
    display: "stills_photographer",
  },
  {
    key: "music_director",
    value: [
      {
        name: "calcutta youth choir",
        alias: "calcutta-youth-choir",
        type: "music_director",
      },
      {
        name: "reba muhuri",
        alias: "reba-muhuri",
        type: "music_director",
      },
      {
        name: null,
        alias: "birju-maharaj",
        type: "choreographer",
      },
    ],
    display: "music_director",
  },
  {
    key: "dancer",
    value: [
      {
        name: "kathak kalakendra ballet troupe new delhi",
        alias: "kathak-kalakendra-ballet-troupe-new-delhi",
        type: "dancer",
      },
      {
        name: "gitanjali",
        alias: "gitanjali",
        type: "dancer",
      },
      {
        name: "saswati sen",
        alias: "saswati-sen",
        type: "dancer",
      },
    ],
    display: "dancer",
  },
  {
    key: "choreographer",
    value: [
      {
        name: null,
        alias: "birju-maharaj",
        type: "choreographer",
      },
    ],
    display: "choreographer",
  },
  {
    key: "re_recorder",
    value: [
      {
        name: null,
        alias: "mangesh-desai",
        type: "re_recorder",
      },
    ],
    display: "re_recorder",
  },
  {
    key: "music_recorder",
    value: [
      {
        name: null,
        alias: "samir-majumdar",
        type: "music_recorder",
      },
    ],
    display: "music_recorder",
  },
  {
    key: "dialogue_writer",
    value: [
      {
        name: null,
        alias: "javed-siddiqi",
        type: "dialogue_writer",
      },
      {
        name: null,
        alias: "satyajit-ray",
        type: "screenplay",
      },
      {
        name: null,
        alias: "shama-zaidi",
        type: "dialogue_writer",
      },
    ],
    display: "dialogue_writer",
  },
  {
    key: "narrator",
    value: [
      {
        name: null,
        alias: "amitabh-bachchan",
        type: "narrator",
      },
    ],
    display: "narrator",
  },
  {
    key: "film_producer",
    value: [
      {
        name: null,
        alias: "suresh-jindal",
        type: "film_producer",
      },
    ],
    display: "film_producer",
  },
  {
    key: "screenplay",
    value: [
      {
        name: null,
        alias: "satyajit-ray",
        type: "screenplay",
      },
    ],
    display: "screenplay",
  },
  {
    key: "location_manager",
    value: [
      {
        name: null,
        alias: "j-n-srivastava",
        type: "location_manager",
      },
    ],
    display: "location_manager",
  },
];

export const skk_cast_view_data = [
  {
      "key": "film_producer",
      "value": [
          {
              "name": null,
              "alias": "suresh-jindal",
              "type": "film_producer",
              "first_name": "Suresh",
              "last_name": "Jindal",
              "full_name": "Suresh Jindal",
              "link":"/person-poly/suresh-jindal"

          }
      ],
      "display": "Film Producer"
  },
  {
      "key": "film_director",
      "display": "Film Director",
      "value": [
        {
          "name": null,
          "alias": "satyajit-ray",
          "type": "screenplay",
          "first_name": "Satyajit",
          "last_name": "Ray",
          "full_name": "Satyajit Ray",
          "link":"/person-poly/satyajit-ray"
      }
    ]
  },
  {
      "key": "script",
      "display": "Script",
      "value": [
        {
          "name": null,
          "alias": "satyajit-ray",
          "type": "screenplay",
          "first_name": "Satyajit",
          "last_name": "Ray",
          "full_name": "Satyajit Ray",
          "link":"/person-poly/satyajit-ray"
      }
    ]
  },
  {
      "key": "screenplay",
      "value": [
          {
              "name": null,
              "alias": "satyajit-ray",
              "type": "screenplay",
              "first_name": "Satyajit",
              "last_name": "Ray",
              "full_name": "Satyajit Ray",
              "link":"/person-poly/satyajit-ray"
          }
      ],
      "display": "Screenplay"
  },
  {
      "key": "dialogue_writer",
      "value": [
          {
              "name": null,
              "alias": "satyajit-ray",
              "type": "screenplay",
              "first_name": "Satyajit",
              "last_name": "Ray",
              "full_name": "Satyajit Ray",
              "link":"/person-poly/satyajit-ray"
          },
          {
              "name": null,
              "alias": "javed-siddiqi",
              "type": "dialogue_writer",
              "first_name": "Javed",
              "last_name": "Siddiqi",
              "full_name": "Javed Siddiqi",
              "link":"/person-poly/javed-siddiqi"
          },
          {
              "name": null,
              "alias": "shama-zaidi",
              "type": "dialogue_writer",
              "first_name": "Shama",
              "last_name": "Zaidi",
              "full_name": "Shama Zaidi",
              "link":"/person-poly/shama-zaidi"
          }
      ],
      "display": "Dialogue"
  },
  {
      "key": "music_director",
      "value": [
        
          {
            "name": null,
            "alias": "satyajit-ray",
            "type": "screenplay",
            "first_name": "Satyajit",
            "last_name": "Ray",
            "full_name": "Satyajit Ray",
            "link":"/person-poly/satyajit-ray"
        },
          {
              "name": null,
              "alias": "birju-maharaj",
              "type": "choreographer",
              "first_name": "Birju",
              "last_name": "Maharaj",
              "full_name": "Birju Maharaj",
              "link":"/person-poly/satyajit-ray"
          }
      ],
      "display": "Music Director"
  },
  {
      "key": "editor",
      "value": [
          {
              "name": null,
              "alias": "dulal-dutta",
              "type": "editor",
              "first_name": "Dulal",
              "last_name": "Dutta",
              "full_name": "Dulal Dutta",
              "link":"/person-poly/satyajit-ray"
          }
      ],
      "display": "Editing"
  },
  {
      "key": "cameraman",
      "display": "Cinematography",
      "value": [
        {
          "name": null,
          "alias": "soumendu-roy",
          "type": "cameraman",
          "first_name": "Soumendu",
          "last_name": "Roy",
          "full_name": "Soumendu Roy",
          "link":"/person-poly/soumendu-roy"
      }
    ]
  },
  {
      "key": "art_director",
      "value": [
          {
              "name": null,
              "alias": "bansi-chandragupta",
              "type": "art_director",
              "first_name": "Bansi",
              "last_name": "Chandragupta",
              "full_name": "Bansi Chandragupta",
              "link":"/person-poly/bansi-chandragupta"
          }
      ],
      "display": "Art Direction"
  },
  {
      "key": "stills_photographer",
      "value": [
          {
              "name": null,
              "alias": "nemai-ghosh",
              "type": "stills_photographer",
              "first_name": "Nemai",
              "last_name": "Ghosh",
              "full_name": "Nemai Ghosh",
              "link":"/nemai-ghosh"
          },
          {
              "name": null,
              "alias": "sandip-ray",
              "type": "stills_photographer",
              "first_name": "Sandip",
              "last_name": "Ray",
              "full_name": "Sandip Ray",
              "link":"/person-poly/sandip-ray"
          },
          {
              "name": null,
              "alias": "sumatra-ghoshal",
              "type": "stills_photographer",
              "first_name": "Sumatra",
              "last_name": "Ghoshal",
              "full_name": "Sumatra Ghoshal",
              "link":"/person-poly/sumatra-ghoshal"
          },
          {
              "name": "pablo bartholomew",
              "alias": "pablo-bartholomew",
              "type": "stills_photographer",
              "first_name": "Pablo",
              "last_name": "Bartholomew",
              "full_name": "Pablo Bartholomew",
              "link":"/person-poly/pablo-bartholomew"
          }
      ],
      "display": "Still Photographer"
  },
  {
      "key": "poster_designer",
      "display": "Poster Designer",
      "value": [
        
        {
          "name": null,
          "alias": "satyajit-ray",
          "type": "poster_designer",
          "first_name": "Satyajit",
          "last_name": "Ray",
          "full_name": "Satyajit Ray",
          "link":"/person-poly/satyajit-ray"
      }]
  },
  {
      "key": "narrator",
      "value": [
          {
              "name": null,
              "alias": "amitabh-bachchan",
              "type": "narrator",
              "first_name": "Amitabh",
              "last_name": "Bachchan",
              "full_name": "Amitabh Bachchan",
              "link":"/person-actor/amitabh-bachchan"
          }
      ],
      "display": "Narrator"
  },
  {
      "key": "costume_designer",
      "value": [
          {
              "name": null,
              "alias": "shama-zaidi",
              "type": "dialogue_writer",
              "first_name": "Shama",
              "last_name": "Zaidi",
              "full_name": "Shama Zaidi",
              "link":"/person-poly/shama-zaidi"
          },
          {
            "name": null,
            "alias": "satyajit-ray",
            "type": "dialogue_writer",
            "first_name": "Satyajit",
            "last_name": "Ray",
            "full_name": "Satyajit Ray",
            "link":"/person-poly/satyajit-ray"
        }
      ],
      "display": "Costume Designer"
  },
  {
      "key": "choreographer",
      "value": [
        {
          "name": null,
          "alias": "saswati-sen",
          "type": "choreographer",
          "first_name": "Saswati",
          "last_name": "Sen",
          "full_name": "Saswati Sen",
          "link":"/person-poly/saswati-sen"
      },
          {
              "name": null,
              "alias": "birju-maharaj",
              "type": "choreographer",
              "first_name": "Birju",
              "last_name": "Maharaj",
              "full_name": "Birju Maharaj",
              "link":"/person-poly/birju-maharaj"
          }
      ],
      "display": "Choreography"
  }
];

export const skk_horizontal_images_data=[
  [
      {
          "key": "artist_name",
          "value": "Sanjeev Kumar",
          "alias":"sanjeev-kumar",
          "display": "Film Producer",
          "link":"/person-poly/sanjeev-kumar"
      },
      {
          "key": "character_name",
          "value": "Mirza Sajjad Ali",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img1,
                  "date_of_artwork": "1977",
                  "accession_number": "Working Still",
                  "name_of_publication": "Shatranj Ke Khilari",
                  "publication_year": "",
                  "sortable_year_of_publication": "1977",
                  "artist_name": "Nemai Ghosh",
                  "medium_of_artwork": "Photographic Still",
                  "revised_medium": "CINE.pho"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Shabana Azmi",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Khurshid, Mirza’s wife",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img2,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Saeed Jaffrey",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Mir Roshan Ali",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img3,
                  "date_of_artwork": "1977",
                  "accession_number": "Working Still",
                  "name_of_publication": "Shatranj Ke Khilari",
                  "publication_year": "",
                  "sortable_year_of_publication": "1977",
                  "artist_name": "Nemai Ghosh",
                  "medium_of_artwork": "Photographic Still",
                  "revised_medium": "CINE.pho"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Farida Jalal",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Nafeesa, Mir’s wife",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img4,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Amjad Khan",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Wajid Ali Shah, Nawab (king)",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img5,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Victor Banerjee",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Ali Naqi Khan, the prime minister",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img6,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Richard Attenborough",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "General Outram",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img7,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Farooq Shaikh",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Aqueel",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value": Img8,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  // [
  //     {
  //         "key": "artist_name",
  //         "value": "Barry John",
  //         "display": "Film Producer"
  //     },
  //     {
  //         "key": "character_name",
  //         "value": "Doctor Joseph Fayrer",
  //         "display": "Film Director"
  //     },
  //     {
  //         "key": "characterImage",
  //         "value": [
  //             {
  //                 "value": "https://tuliresearchcentre.canto.global/direct/image/qhtabts7td0q1eslrh6nnd0v6v/nuK-QbjHNWR6PQZ9h0iLEuRnbEE/m800/800",
  //                 "date_of_artwork": "2017",
  //                 "accession_number": "My Adventures with Satyajit Ray",
  //                 "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
  //                 "publication_year": "2017",
  //                 "sortable_year_of_publication": "2017",
  //                 "artist_name": "Suresh Jindal",
  //                 "medium_of_artwork": "Book",
  //                 "revised_medium": "BOOK.bka"
  //             }
  //         ],
  //         "display": "Image"
  //     }
  // ]
]

//mughal-e-azam
export const mughal_film_intro_data = [
  {
    key: "id",
    value: "9e42cdac-e1de-4882-b556-0fb9c9d683c8",
    display: "Id",
  },
  {
    key: "sl_no",
    value: "1",
    display: "No.",
  },
  {
    key: "alias",
    value: "mughal-e-azam",
    display: "Alias",
  },
  {
    key: "subject",
    value: "Indian Cinema",
    display: "Subject",
  },
  {
    key: "masterlist_type",
    value: "Film",
    display: "Masterlist Type",
  },
  {
    key: "short_description",
    value:
      "The historical period drama Shatranj Ke Khilari (The Chess Players), is Satyajit Ray's only full-length Hindi-language feature film, based on literary giant Munshi Premchand's short story rooted amid the glorious, yet seemingly decadent, Awadhi culture just before the 1857 Mutiny (Rebellion), during the rule of Nawab Wajid Ali of Lucknow to the entrenchment of a colonial British Rule which follows post 1858. Starring stalwarts Saeed Jaffrey, Amjad Khan, Sanjeev Kumar, and Shabana Azmi, the film was shot on location in Jaipur and Lucknow. ",
    display: "Short Description",
  },
  {
    key: "rated",
    value: "U",
    display: "Rated",
  },
  {
    key: "duration_mins",
    value: "115 mins",
    display: "Duration",
  },
  {
    key: "certificate_number",
    value: "84526",
    display: "Certificate Number",
  },
  {
    key: "date_of_issue",
    value: "1977-10-03",
    display: "Date of Issue",
  },
  {
    key: "date_of_expiry",
    value: "1987-10-02",
    display: "Date of Expiry",
  },
  {
    key: "color",
    value: "Eastman Color",
    display: "Color",
  },
  {
    key: "lab",
    value: "Gemini Color Lab, Madras",
    display: "LAB",
  },
  {
    key: "gauge",
    value: "35 mm",
    display: "Gauge",
  },
  {
    key: "length_of_reels",
    value: "3434.18 m",
    display: "Length of Reels",
  },
  {
    key: "number_of_reels",
    value: "14",
    display: "Number of Reels",
  },
  {
    key: "shooting_period",
    value: "December 1976 - June 1977",
    display: "Shooting Period",
  },
  {
    key: "synopsis",
    value:
      "Wajid Ali Shah is the ruler of one of the last independent kingdoms of India. The British, intent on controlling India, send General Outram on a secret mission to clear the way for an annexation. While pressure is mounting amidst intrigue and political manoeuvres, Wajid Ali Shah, secluded in his palace, composes poems and listens to music. The court is of no help, as exemplified by two slightly eccentric noblemen, Mir and Mirza who, ignoring the situation in the country and their duties towards their families, spend their days playing endless rounds of chess. The imminent threat of the British takeover of their region becomes a harsh reality. Even when they are forced to leave their comfortable homes, they continue to play chess in the mosquito ridden outdoors, with two loaded pistols, ready to oppose the British, or even themselves, if need be.",
    display: "Synopsis",
  },
  {
    key: "release_date",
    value: "1977-03-11",
    display: "Release Date",
  },
  {
    key: "first_release_location",
    value: "Calcutta",
    display: "First Release Location",
  },
  {
    key: "trailer_link",
    value: "https://www.youtube.com/watch?v=nrU4irG-_QY",
    display: "Watch Film Trailer",
  },
  {
    key: "full_movie_link",
    value: "https://www.youtube.com/watch?v=A3Fgm0yaWbA",
    display: "Watch Full Film",
  },
  {
    key: "eastmancolor_by",
    value: "gemini-colour-laboratory-madras",
    display: "Eastmancolor By",
  },
  {
    key: "researchCategories",
    value: [
      {
        key: "Cinema as a Key Educational Resource",
        value: "CinemaE",
        link: "cinema-as-a-key-educational-resource",
      },
    ],
    display: "Research Categories",
  },
  {
     key:"editorial_text",
     value:"The following is a collection of photographs, captured by Nemai Ghosh, seen in the sequential order of the film. The collection includes both photographic stills and working stills."
  },
  {
    key: "filmTitleImage",
    value: [
      {
        wnn: "0000222",
        accession_number: "",
        alias: "shatranj-ke-khilari-0000222",
        name_of_publication: "Mughal-e-azam",
        artist_name: "",
        date_of_artwork: "",
        image: null,
        title: null,
        direct_url_of_preview_image:MughalEAzamTitle,
        medium_of_artwork: "",
        revised_medium: "",
      },
    ],
    display: "Film Title Image",
  },
  {
    key: "posterImages",
    value: [
      {
        wnn: "0000052",
        accession_number: "CINE.pos",
        alias: "shatranj-ke-khilari-1977-cine-pos-0000052",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/l1j8mv0hrp5hddgeko7nd4ol3v/p7Q2NLuDlPcYHqgM0ZtfdN99YCE/m800/800",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
      },
      {
        wnn: "0000050",
        accession_number: "CINE.pos",
        alias: "shatranj-ke-khilari-1977-cine-pos-0000050",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/acfri9vr053sp17vlo67811g07/-OoiuKecP6oFspw2PBROwzJGd8w/m800/800",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
      },
      {
        wnn: "0000051",
        accession_number: "CINE.pos",
        alias: "shatranj-ke-khilari-1977-cine-pos-0000051",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/qo20crpujd5jd6m0h03di7j51d/IQ0u10yWZp_co5EUSD4XLjY5tFk/m800/800",
        medium_of_artwork: "Original Release Poster",
        revised_medium: "CINE.pos",
      },
    ],
    display: "Poster Images",
  },
  {
    key: "publicityImages",
    value: [
      {
        wnn: null,
        accession_number: null,
        alias: "shatranj-ke-khilari-1977-showcard-0000057",
        name_of_publication: null,
        artist_name: null,
        date_of_artwork: null,
        image: null,
        title: null,
        direct_url_of_preview_image: null,
        medium_of_artwork: null,
        revised_medium: null,
      },
      {
        wnn: "0000056",
        accession_number: "CINE.phl",
        alias: "shatranj-ke-khilari-1977-cine-phl-0000056",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/tr005cqss56nn8bb9qmp897m12/v2iCDDugHHZhzR9p4o6jl_4oQqY/m800/800",
        medium_of_artwork: "Photographic Still Mounted on Lobby Card",
        revised_medium: "CINE.phl",
      },
      {
        wnn: "0000055",
        accession_number: "Past Auction_Costume",
        alias:
          "costume-worn-by-sanjeev-kumar-in-the-film-shatranj-ke-khilari-1977-past-auction_costume-0000055",
        name_of_publication:
          "Costume worn by Sanjeev Kumar in the film Shatranj Ke Khilari",
        artist_name: "Shama Zaidi",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1v0j16av894t93bhrkuucj3a47/iWYvxPMkWzfpocdLmvigauqaZEY/m800/800",
        medium_of_artwork: "Silk from Benaras (COS), 1977",
        revised_medium: "CINE.maq",
      },
      {
        wnn: "0000054",
        accession_number: "CINE.lby",
        alias: "shatranj-ke-khilari-1977-cine-lby-0000054",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/q3o6cul2g16cp9qqs409dhii64/1AibPt8QA4tPrqAn9tcwA0GjgF0/m800/800",
        medium_of_artwork: "Lobby Card",
        revised_medium: "CINE.lby",
      },
    ],
    display: "Publicity Images",
  },
  {
    key: "certificateImages",
    value: [
      {
        wnn: "0000053",
        accession_number: "Certificate",
        alias: "shatranj-ke-khilari-1977-certificate-0000053",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/uj2kkmp8rh05v8s88dtlp3jk3s/t0cD2JWZGQKGfYIUkK76RXx9Tzk/m640/800",
        medium_of_artwork: "",
        revised_medium: "",
      },
    ],
    display: "Certificate Images",
  },
  {
    key: "language",
    value: [
      {
        name: "Urdu",
        alias: "Urdu",
      },
      {
        name: "English",
        alias: "English",
      },
      {
        name: "Hindi",
        alias: "Hindi",
      },
    ],
    display: "Languages",
  },
  {
    key: "studios",
    value: [
      {
        name: "Rajkamal Kalamandir, Bombay",
        alias: "Rajkamal Kalamandir, Bombay",
      },
      {
        name: "Indrapuri, Calcutta",
        alias: "Indrapuri, Calcutta",
      },
    ],
    display: "Studios",
  },
  {
    key: "location",
    value: [
      {
        name: "jaipur",
        alias: "jaipur",
      },
      {
        name: "lucknow",
        alias: "lucknow",
      },
    ],
    display: "On Location",
  },
  {
    key: "synopsisImages",
    value: [
      {
        wnn: "000013a",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013a",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "000013c",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013c",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/ct9tlok12l7k99tvog0gtrj50u/scLrHQp8cqEkHBQxjGtjW89Se8A/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "0000013",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "000013b",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013b",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Synopsis Images",
  },
  {
    key: "filmMainImage",
    value: [
      {
        wnn: "0000013",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:MughalEAzamFilmmain,
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Film Main Image",
  },
  {
    key: "filmBowCreditImage",
    value: [
      {
        wnn: "000013a",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013a",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "000013b",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-000013b",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        wnn: "0000013",
        accession_number: "Cine.ssb",
        alias: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        date_of_artwork: "1977",
        image: null,
        title: null,
        direct_url_of_preview_image:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Film Bow Credit Image",
  },
  {
    key: "banner",
    value: [
      {
        name: "Devki chitra",
        alias: "devki-chitra",
      },
    ],
    display: "Banner",
  },
  {
    key: "storyWriter",
    value: [
      {
        name: null,
        alias: "premchand",
        type: "story_writer",
      },
    ],
    display: "Story Writer",
  },
  {
    key: "cameraman",
    value: [
      {
        name: null,
        alias: "soumendu-roy",
        type: "cameraman",
      },
    ],
    display: "Cameraman",
  },
  {
    key: "assistant_of_cameraman",
    value: [
      {
        name: null,
        alias: "dilip-bannerji",
        type: "assistant_of_cameraman",
      },
      {
        name: null,
        alias: "purnendu-bose",
        type: "assistant_of_cameraman",
      },
      {
        name: null,
        alias: "anil-ghosh",
        type: "assistant_of_cameraman",
      },
    ],
    display: "assistant_of_cameraman",
  },
  {
    key: "art_director",
    value: [
      {
        name: null,
        alias: "bansi-chandragupta",
        type: "art_director",
      },
    ],
    display: "art_director",
  },
  {
    key: "associate_of_art_director",
    value: [
      {
        name: null,
        alias: "ashok-bose",
        type: "associate_of_art_director",
      },
    ],
    display: "associate_of_art_director",
  },
  {
    key: "costume_designer",
    value: [
      {
        name: null,
        alias: "shama-zaidi",
        type: "dialogue_writer",
      },
    ],
    display: "costume_designer",
  },
  {
    key: "associate_of_costume_designer",
    value: [
      {
        name: null,
        alias: "saba-zaidi",
        type: "associate_of_costume_designer",
      },
      {
        name: null,
        alias: "sitara-jaffri",
        type: "associate_of_costume_designer",
      },
      {
        name: null,
        alias: "rabab-jaffri",
        type: "associate_of_costume_designer",
      },
    ],
    display: "associate_of_costume_designer",
  },
  {
    key: "sound",
    value: [
      {
        name: null,
        alias: "narinder-singh",
        type: "sound",
      },
    ],
    display: "sound",
  },
  {
    key: "assistant_of_sound",
    value: [
      {
        name: null,
        alias: "arun-chakravarty",
        type: "assistant_of_sound",
      },
      {
        name: null,
        alias: "dulal-das",
        type: "assistant_of_sound",
      },
    ],
    display: "assistant_of_sound",
  },
  {
    key: "editor",
    value: [
      {
        name: null,
        alias: "dulal-dutta",
        type: "editor",
      },
      {
        name: null,
        alias: "dulal-dutta",
        type: "editor",
      },
    ],
    display: "editor",
  },
  {
    key: "assistant_of_editor",
    value: [
      {
        name: null,
        alias: "kashinath-bose",
        type: "assistant_of_editor",
      },
    ],
    display: "assistant_of_editor",
  },
  {
    key: "make_up_artist",
    value: [
      {
        name: null,
        alias: "ananta-das",
        type: "make_up_artist",
      },
    ],
    display: "make_up_artist",
  },
  {
    key: "assistant_of_make_up_artist",
    value: [
      {
        name: null,
        alias: "pradip-bag",
        type: "assistant_of_make_up_artist",
      },
    ],
    display: "assistant_of_make_up_artist",
  },
  {
    key: "wardrobe",
    value: [
      {
        name: null,
        alias: "haru-das",
        type: "wardrobe",
      },
    ],
    display: "wardrobe",
  },
  {
    key: "backdrop",
    value: [
      {
        name: null,
        alias: "kabi-dasgupta",
        type: "backdrop",
      },
    ],
    display: "backdrop",
  },
  {
    key: "assistant_director",
    value: [
      {
        name: "santi kumar chatterji",
        alias: "santi-kumar-chatterji",
        type: "assistant_director",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "ramesh-sen",
        type: "assistant_director",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
    ],
    display: "assistant_director",
  },
  {
    key: "special_assistant",
    value: [
      {
        name: null,
        alias: "javed-siddiqi",
        type: "dialogue_writer",
      },
    ],
    display: "special_assistant",
  },
  {
    key: "production_executive",
    value: [
      {
        name: null,
        alias: "anil-chowdhury",
        type: "production_executive",
      },
    ],
    display: "production_executive",
  },
  {
    key: "production_manager",
    value: [
      {
        name: null,
        alias: "bhanu-ghosh",
        type: "production_manager",
      },
    ],
    display: "production_manager",
  },
  {
    key: "production_assistant",
    value: [
      {
        name: null,
        alias: "bolai-addy",
        type: "production_assistant",
      },
      {
        name: null,
        alias: "trailokya-das",
        type: "production_assistant",
      },
    ],
    display: "production_assistant",
  },
  {
    key: "studio",
    value: [
      {
        name: "rajkamal kalamandir",
        alias: "rajkamal-kalamandir",
      },
      {
        name: "indrapuri studios",
        alias: "indrapuri-studios",
      },
    ],
    display: "studio",
  },
  {
    key: "equipment_manager",
    value: [
      {
        name: "shree krisna films",
        alias: "shree-krisna-films",
        type: "equipment_manager",
      },
      {
        name: "uma deep enterprises",
        alias: "uma-deep-enterprises",
        type: "equipment_manager",
      },
      {
        name: "n c cine service",
        alias: "n-c-cine-service",
        type: "equipment_manager",
      },
      {
        name: "cinequip",
        alias: "cinequip",
        type: "equipment_manager",
      },
    ],
    display: "equipment_manager",
  },
  {
    key: "office_manager",
    value: [
      {
        name: null,
        alias: "n-b-sukhwani",
        type: "office_manager",
      },
      {
        name: null,
        alias: "ana-maria-de-souza",
        type: "office_manager",
      },
    ],
    display: "office_manager",
  },
  {
    key: "assistant_of_office_management",
    value: [
      {
        name: null,
        alias: "jagdish-shetty",
        type: "assistant_of_office_management",
      },
    ],
    display: "assistant_of_office_management",
  },
  {
    key: "animator",
    value: [
      {
        name: null,
        alias: "ram-mohan",
        type: "animator",
      },
    ],
    display: "animator",
  },
  {
    key: "graphic_designer",
    value: [
      {
        name: null,
        alias: "zehra-tyabji",
        type: "graphic_designer",
      },
    ],
    display: "graphic_designer",
  },
  {
    key: "assistant_of_graphic_designer",
    value: [
      {
        name: null,
        alias: "pradeep-powale",
        type: "assistant_of_graphic_designer",
      },
    ],
    display: "assistant_of_graphic_designer",
  },
  {
    key: "associate_of_graphic_designer",
    value: [
      {
        name: null,
        alias: "joseph-st-anne",
        type: "associate_of_graphic_designer",
      },
    ],
    display: "associate_of_graphic_designer",
  },
  {
    key: "publicity_liasion_officer",
    value: [
      {
        name: null,
        alias: "uma-da-cunhe",
        type: "publicity_liasion_officer",
      },
    ],
    display: "publicity_liasion_officer",
  },
  {
    key: "stills_photographer",
    value: [
      {
        name: "pablo bartholomew",
        alias: "pablo-bartholomew",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "nemai-ghosh",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "sandip-ray",
        type: "stills_photographer",
      },
      {
        name: null,
        alias: "sumatra-ghoshal",
        type: "stills_photographer",
      },
    ],
    display: "stills_photographer",
  },
  {
    key: "music_director",
    value: [
      {
        name: "calcutta youth choir",
        alias: "calcutta-youth-choir",
        type: "music_director",
      },
      {
        name: "reba muhuri",
        alias: "reba-muhuri",
        type: "music_director",
      },
      {
        name: null,
        alias: "birju-maharaj",
        type: "choreographer",
      },
    ],
    display: "music_director",
  },
  {
    key: "dancer",
    value: [
      {
        name: "kathak kalakendra ballet troupe new delhi",
        alias: "kathak-kalakendra-ballet-troupe-new-delhi",
        type: "dancer",
      },
      {
        name: "gitanjali",
        alias: "gitanjali",
        type: "dancer",
      },
      {
        name: "saswati sen",
        alias: "saswati-sen",
        type: "dancer",
      },
    ],
    display: "dancer",
  },
  {
    key: "choreographer",
    value: [
      {
        name: null,
        alias: "birju-maharaj",
        type: "choreographer",
      },
    ],
    display: "choreographer",
  },
  {
    key: "re_recorder",
    value: [
      {
        name: null,
        alias: "mangesh-desai",
        type: "re_recorder",
      },
    ],
    display: "re_recorder",
  },
  {
    key: "music_recorder",
    value: [
      {
        name: null,
        alias: "samir-majumdar",
        type: "music_recorder",
      },
    ],
    display: "music_recorder",
  },
  {
    key: "dialogue_writer",
    value: [
      {
        name: null,
        alias: "javed-siddiqi",
        type: "dialogue_writer",
      },
      {
        name: null,
        alias: "satyajit-ray",
        type: "screenplay",
      },
      {
        name: null,
        alias: "shama-zaidi",
        type: "dialogue_writer",
      },
    ],
    display: "dialogue_writer",
  },
  {
    key: "narrator",
    value: [
      {
        name: null,
        alias: "amitabh-bachchan",
        type: "narrator",
      },
    ],
    display: "narrator",
  },
  {
    key: "film_producer",
    value: [
      {
        name: null,
        alias: "suresh-jindal",
        type: "film_producer",
      },
    ],
    display: "film_producer",
  },
  {
    key: "screenplay",
    value: [
      {
        name: null,
        alias: "satyajit-ray",
        type: "screenplay",
      },
    ],
    display: "screenplay",
  },
  {
    key: "location_manager",
    value: [
      {
        name: null,
        alias: "j-n-srivastava",
        type: "location_manager",
      },
    ],
    display: "location_manager",
  },
];
export const mughal_synopsis_data = [
  {
    key: "alias",
    value: "shatranj-ke-khilari-1977",
    display: "Alias",
  },
  {
    key: "synopsis",
    value:
      "Wajid Ali Shah is the ruler of one of the last independent kingdoms of India. The British, intent on controlling India, send General Outram on a secret mission to clear the way for an annexation. While pressure is mounting amidst intrigue and political manoeuvres, Wajid Ali Shah, secluded in his palace, composes poems and listens to music. The court is of no help, as exemplified by two slightly eccentric noblemen, Mir and Mirza who, ignoring the situation in the country and their duties towards their families, spend their days playing endless rounds of chess. The imminent threat of the British takeover of their region becomes a harsh reality. Even when they are forced to leave their comfortable homes, they continue to play chess in the mosquito ridden outdoors, with two loaded pistols, ready to oppose the British, or even themselves, if need be.",
    display: "Synopsis",
  },
  {
    key: "synopsisImages",
    value: [
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-000013a",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/1o0af7bfm10oj37sl66n4ro65g/GCekuRJzyDqEjMIR56gvDgDzpZQ/m800/800",
        date_of_artwork: "1977",
        wnn: "000013a",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-000013c",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/ct9tlok12l7k99tvog0gtrj50u/scLrHQp8cqEkHBQxjGtjW89Se8A/m800/800",
        date_of_artwork: "1977",
        wnn: "000013c",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-000013b",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
        date_of_artwork: "1977",
        wnn: "000013b",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
      {
        key: "shatranj-ke-khilari-1977-cine-ssb-0000013",
        value:
          "https://tuliresearchcentre.canto.global/direct/image/1tf5g1jj251ap5kccpbrhlq45n/PeLFRGpkH1jA5xYzGK7PEUNT7Rc/m800/800",
        date_of_artwork: "1977",
        wnn: "0000013",
        accession_number: "Cine.ssb",
        name_of_publication: "Shatranj Ke Khilari",
        artist_name: "Satyajit Ray",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "CINE.ssb",
      },
    ],
    display: "Synopsis Images",
  },
];
export const mughal_cast_view_data = [
  {
      "key": "film_producer",
      "value": [
          {
              "name": null,
              "alias": "suresh-jindal",
              "type": "film_producer",
              "first_name": "Suresh",
              "last_name": "Jindal",
              "full_name": "Suresh Jindal"
          }
      ],
      "display": "Film Producer"
  },
  {
      "key": "film_director",
      "display": "Film Director",
      "value": [
        {
          "name": null,
          "alias": "satyajit-ray",
          "type": "screenplay",
          "first_name": "Satyajit",
          "last_name": "Ray",
          "full_name": "Satyajit Ray",
          "link":true
      }
    ]
  },
  {
      "key": "script",
      "display": "Script",
      "value": [
        {
          "name": null,
          "alias": "satyajit-ray",
          "type": "screenplay",
          "first_name": "Satyajit",
          "last_name": "Ray",
          "full_name": "Satyajit Ray"
      }
    ]
  },
  {
      "key": "screenplay",
      "value": [
          {
              "name": null,
              "alias": "satyajit-ray",
              "type": "screenplay",
              "first_name": "Satyajit",
              "last_name": "Ray",
              "full_name": "Satyajit Ray"
          }
      ],
      "display": "Screenplay"
  },
  {
      "key": "dialogue_writer",
      "value": [
          {
              "name": null,
              "alias": "satyajit-ray",
              "type": "screenplay",
              "first_name": "Satyajit",
              "last_name": "Ray",
              "full_name": "Satyajit Ray"
          },
          {
              "name": null,
              "alias": "javed-siddiqi",
              "type": "dialogue_writer",
              "first_name": "Javed",
              "last_name": "Siddiqi",
              "full_name": "Javed Siddiqi"
          },
          {
              "name": null,
              "alias": "shama-zaidi",
              "type": "dialogue_writer",
              "first_name": "Shama",
              "last_name": "Zaidi",
              "full_name": "Shama Zaidi"
          }
      ],
      "display": "Dialogue"
  },
  {
      "key": "music_director",
      "value": [
        
          {
            "name": null,
            "alias": "satyajit-ray",
            "type": "screenplay",
            "first_name": "Satyajit",
            "last_name": "Ray",
            "full_name": "Satyajit Ray"
        },
          {
              "name": null,
              "alias": "birju-maharaj",
              "type": "choreographer",
              "first_name": "Birju",
              "last_name": "Maharaj",
              "full_name": "Birju Maharaj"
          }
      ],
      "display": "Music Director"
  },
  {
      "key": "editor",
      "value": [
          {
              "name": null,
              "alias": "dulal-dutta",
              "type": "editor",
              "first_name": "Dulal",
              "last_name": "Dutta",
              "full_name": "Dulal Dutta"
          }
      ],
      "display": "Editing"
  },
  {
      "key": "cameraman",
      "display": "Cinematography",
      "value": [
        {
          "name": null,
          "alias": "Soumendu-roy",
          "type": "cameraman",
          "first_name": "Soumendu",
          "last_name": "Roy",
          "full_name": "Soumendu Roy"
      }
    ]
  },
  {
      "key": "art_director",
      "value": [
          {
              "name": null,
              "alias": "bansi-chandragupta",
              "type": "art_director",
              "first_name": "Bansi",
              "last_name": "Chandragupta",
              "full_name": "Bansi Chandragupta"
          }
      ],
      "display": "Art Direction"
  },
  {
      "key": "stills_photographer",
      "value": [
          {
              "name": null,
              "alias": "nemai-ghosh",
              "type": "stills_photographer",
              "first_name": "Nemai",
              "last_name": "Ghosh",
              "full_name": "Nemai Ghosh"
          },
          {
              "name": null,
              "alias": "sandip-ray",
              "type": "stills_photographer",
              "first_name": "Sandip",
              "last_name": "Ray",
              "full_name": "Sandip Ray"
          },
          {
              "name": null,
              "alias": "sumatra-ghoshal",
              "type": "stills_photographer",
              "first_name": "Sumatra",
              "last_name": "Ghoshal",
              "full_name": "Sumatra Ghoshal"
          },
          {
              "name": "pablo bartholomew",
              "alias": "pablo-bartholomew",
              "type": "stills_photographer",
              "first_name": "Pablo",
              "last_name": "Bartholomew",
              "full_name": "Pablo Bartholomew"
          }
      ],
      "display": "Still Photographer"
  },
  {
      "key": "poster_designer",
      "display": "Poster Designer",
      "value": [
        
        {
          "name": null,
          "alias": "satyajit-ray",
          "type": "poster_designer",
          "first_name": "Satyajit",
          "last_name": "Ray",
          "full_name": "Satyajit Ray"
      }]
  },
  {
      "key": "narrator",
      "value": [
          {
              "name": null,
              "alias": "amitabh-bachchan",
              "type": "narrator",
              "first_name": "Amitabh",
              "last_name": "Bachchan",
              "full_name": "Amitabh Bachchan"
          }
      ],
      "display": "Narrator"
  },
  {
      "key": "costume_designer",
      "value": [
          {
              "name": null,
              "alias": "shama-zaidi",
              "type": "dialogue_writer",
              "first_name": "Shama",
              "last_name": "Zaidi",
              "full_name": "Shama Zaidi"
          },
          {
            "name": null,
            "alias": "satyajit-ray",
            "type": "dialogue_writer",
            "first_name": "Satyajit",
            "last_name": "Ray",
            "full_name": "Satyajit Ray"
        }
      ],
      "display": "Costume Designer"
  },
  {
      "key": "choreographer",
      "value": [
        {
          "name": null,
          "alias": "saswati-sen",
          "type": "choreographer",
          "first_name": "Saswati",
          "last_name": "Sen",
          "full_name": "Saswati Sen"
      },
          {
              "name": null,
              "alias": "birju-maharaj",
              "type": "choreographer",
              "first_name": "Birju",
              "last_name": "Maharaj",
              "full_name": "Birju Maharaj"
          }
      ],
      "display": "Choreography"
  }
];
export const mughal_credits_view_data=[
  {
    key: "banner",
    value: [
      {
        key: "devki-chitra",
        value: "Devki chitra",
      },
    ],
    display: "Banner",
  },
  {
    key: "storyWriter",
    value: [
      {
        key: "premchand",
        value: "Munshi Premchand",
        first_name: "Munshi",
        last_name: "Premchand",
      },
    ],
    display: "Story",
  },
  {
    key: "leadcast",
    value: [
      {
        key: "Kumar",
        value: "Sanjeev Kumar",
        first_name: "Sanjeev",
        last_name: "Kumar",
        link: true,
      },
      {
        key: "Jaffrey",
        value: "Saeed Jaffrey",
        first_name: "Saeed",
        last_name: "Jaffrey",
      },
      {
        key: "Azmi",
        value: "Shabana Azmi",
        first_name: "Shabana",
        last_name: "Azmi",
      },
      {
        key: "Jalal",
        value: "Farida Jalal",
        first_name: "Farida",
        last_name: "Jalal",
      },
      {
        key: "Veena",
        value: "Veena",
        first_name: "Veena",
        last_name: "",
      },
      {
        key: "Abraham",
        value: "David Abraham",
        first_name: "David",
        last_name: "Abraham",
      },
      {
        key: "Bannerji",
        value: "Victor Bannerji",
        first_name: "Victor",
        last_name: "Bannerji",
      },
    ],
    display: "Lead Cast",
  },
  {
    key: "cameraman",
    value: [
      {
        key: "soumendu-roy",
        value: "Soumendu Roy",
        first_name: "Soumendu",
        last_name: "Roy",
      },
    ],
    display: "Camera",
  },
  {
    key: "assistant_of_cameraman",
    value: [
      {
        key: "anil-ghosh",
        value: "Anil Ghosh",
        first_name: "Anil",
        last_name: "Ghosh",
      },
      {
        key: "purnendu-bose",
        value: "Purnendu Bose",
        first_name: "Purnendu",
        last_name: "Bose",
      },
      {
        key: "dilip-bannerji",
        value: "Dilip Bannerji",
        first_name: "Dilip",
        last_name: "Bannerji",
      },
    ],
    display: "Assistants",
  },
  {
    key: "art_director",
    value: [
      {
        key: "bansi-chandragupta",
        value: "Bansi Chandragupta",
        first_name: "Bansi",
        last_name: "Chandragupta",
      },
    ],
    display: "Art Direction",
  },
  {
    key: "associate_of_art_director",
    value: [
      {
        key: "ashok-bose",
        value: "Ashok Bose",
        first_name: "Ashok",
        last_name: "Bose",
      },
    ],
    display: "Associate",
  },
  {
    key: "costume_designer",
    value: [
      {
        key: "shama-zaidi",
        value: "Shama Zaidi",
        first_name: "Shama",
        last_name: "Zaidi",
      },
    ],
    display: "Costumes",
  },
  {
    key: "associate_of_costume_designer",
    value: [
      {
        key: "saba-zaidi",
        value: "Saba Zaidi",
        first_name: "Saba",
        last_name: "Zaidi",
      },
      {
        key: "sitara-jaffri",
        value: "Sitara Jaffri",
        first_name: "Sitara",
        last_name: "Jaffri",
      },
      {
        key: "rabab-jaffri",
        value: "Rabab Jaffri",
        first_name: "Rabab",
        last_name: "Jaffri",
      },
    ],
    display: "Associates",
  },
  {
    key: "sound",
    value: [
      {
        key: "narinder-singh",
        value: "Narinder Singh",
        first_name: "Narinder",
        last_name: "Singh",
      },
    ],
    display: "Sound",
  },
  {
    key: "editor",
    value: [
      {
        key: "dulal-dutta",
        value: "Dulal-Dutta",
        first_name: "Dulal",
        last_name: "Dutta",
      },
      {
        key: "dulal-dutta",
        value: "Dulal Dutta",
        first_name: "Dulal",
        last_name: "Dutta",
      },
    ],
    display: "Editing",
  },
  {
    key: "assistant_of_editor",
    value: [
      {
        key: "kashinath-bose",
        value: "Kashinath Bose",
        first_name: "Kashinath",
        last_name: "Bose",
      },
    ],
    display: "Assistant",
  },
  {
    key: "make_up_artist",
    value: [
      {
        key: "ananta-das",
        value: "Ananta Das",
        first_name: "Ananta",
        last_name: "Das",
      },
    ],
    display: "Make up",
  },
  {
    key: "assistant_of_make_up_artist",
    value: [
      {
        key: "pradip-bag",
        value: "Pradip Bag",
        first_name: "Pradip",
        last_name: "Bag",
      },
    ],
    display: "Assistant",
  },
  {
    key: "wardrobe",
    value: [
      {
        key: "haru-das",
        value: "Haru Das",
        first_name: "Haru",
        last_name: "Das",
      },
    ],
    display: "Wardrobe",
  },
  {
    key: "backdrop",
    value: [
      {
        key: "kabi-dasgupta",
        value: "Kabi Dasgupta",
        first_name: "Kabi",
        last_name: "Dasgupta",
      },
    ],
    display: "Backdrop",
  },
  {
    key: "assistant_director",
    value: [
      {
        key: "ramesh-sen",
        value: "Ramesh Sen",
        first_name: "Ramesh",
        last_name: "Sen",
      },
      {
        key: "santi-kumar-chatterji",
        value: "santi kumar chatterji",
        first_name: null,
        last_name: null,
      },
      {
        key: "sandip-ray",
        value: "Sandip-Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
      {
        key: "sandip-ray",
        value: "Sandip Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
    ],
    display: "Assistant Directors",
  },
  {
    key: "special_assistant",
    value: [
      {
        key: "javed-siddiqi",
        value: "Javed Siddiqi",
        first_name: "Javed",
        last_name: "Siddiqi",
      },
    ],
    display: "Special Assistant",
  },
  {
    key: "production_executive",
    value: [
      {
        key: "anil-chowdhury",
        value: "Anil Chowdhury",
        first_name: "Anil",
        last_name: "Chowdhury",
      },
    ],
    display: "Production Executive",
  },
  {
    key: "production_manager",
    value: [
      {
        key: "bhanu-ghosh",
        value: "Bhanu Ghosh",
        first_name: "Bhanu",
        last_name: "Ghosh",
      },
    ],
    display: "Production Manager",
  },
  {
    key: "location_manager",
    value: [
      {
        key: "j-n-srivastava",
        value: "J-N Srivastava",
        first_name: "J. N.",
        last_name: "Srivastava",
      },
    ],
    display: "Location Management",
  },
  {
    key: "production_assistant",
    value: [
      {
        key: "trailokya-das",
        value: "Trailokya Das",
        first_name: "Trailokya",
        last_name: "Das",
      },
      {
        key: "bolai-addy",
        value: "Bolai Addy",
        first_name: "Bolai",
        last_name: "Addy",
      },
    ],
    display: "Production Assistants",
  },
  {
    key: "studios",
    value: [
      {
        key: "Rajkamal Kalamandir, Bombay",
        value: "Rajkamal Kalamandir, Bombay",
      },
      {
        key: "Indrapuri, Calcutta",
        value: "Indrapuri, Calcutta",
      },
    ],
    display: "Studios",
  },
  {
    key: "eastmancolor_by",
    value: "gemini-colour-laboratory-madras",
    display: "Eastmancolor By",
  },
  {
    key: "equipment_manager",
    value: [
      {
        key: "n-c-cine-service",
        value: "n c cine service",
        first_name: null,
        last_name: null,
      },
      {
        key: "cinequip",
        value: "cinequip",
        first_name: null,
        last_name: null,
      },
      {
        key: "uma-deep-enterprises",
        value: "uma deep enterprises",
        first_name: null,
        last_name: null,
      },
      {
        key: "shree-krisna-films",
        value: "shree krisna films",
        first_name: null,
        last_name: null,
      },
    ],
    display: "Equipment",
  },
  {
    key: "office_manager",
    value: [
      {
        key: "ana-maria-de-souza",
        value: "Ana-Maria-De Souza",
        first_name: "Ana Maria",
        last_name: "De Souza",
      },
      {
        key: "n-b-sukhwani",
        value: "N-B Sukhwani",
        first_name: "N. B.",
        last_name: "Sukhwani",
      },
    ],
    display: "Office Management",
  },
  {
    key: "animator",
    value: [
      {
        key: "ram-mohan",
        value: "Ram Mohan",
        first_name: "Ram ",
        last_name: "Mohan",
      },
    ],
    display: "Animation",
  },
  {
    key: "graphic_designer",
    value: [
      {
        key: "zehra-tyabji",
        value: "Zehra Tyabji",
        first_name: "Zehra",
        last_name: "Tyabji",
      },
    ],
    display: "Graphic Design",
  },
  {
    key: "publicity_liasion_officer",
    value: [
      {
        key: "uma-da-cunhe",
        value: "Uma-Da Cunhe",
        first_name: "Uma",
        last_name: "Da Cunhe",
      },
    ],
    display: "Publicity & Liasion",
  },
  {
    key: "stills_photographer",
    value: [
      {
        key: "nemai-ghosh",
        value: "Nemai-Ghosh",
        first_name: "Nemai",
        last_name: "Ghosh",
      },
      {
        key: "sandip-ray",
        value: "Sandip Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
      {
        key: "nemai-ghosh",
        value: "Nemai Ghosh",
        first_name: "Nemai",
        last_name: "Ghosh",
      },
      {
        key: "pablo-bartholomew",
        value: "pablo bartholomew",
        first_name: null,
        last_name: null,
      },
      {
        key: "sandip-ray",
        value: "Sandip-Ray",
        first_name: "Sandip",
        last_name: "Ray",
      },
      {
        key: "sumatra-ghoshal",
        value: "Sumatra Ghoshal",
        first_name: "Sumatra",
        last_name: "Ghoshal",
      },
    ],
    display: "Stills",
  },
  {
    key: "dancer",
    value: [
      {
        key: "saswati-sen",
        value: "saswati sen",
        first_name: null,
        last_name: null,
      },
      {
        key: "gitanjali",
        value: "gitanjali",
        first_name: null,
        last_name: null,
      },
      {
        key: "kathak-kalakendra-ballet-troupe-new-delhi",
        value: "kathak kalakendra ballet troupe new delhi",
        first_name: null,
        last_name: null,
      },
    ],
    display: "Dances",
  },
  {
    key: "choreographer",
    value: [
      {
        key: "birju-maharaj",
        value: "Birju Maharaj",
        first_name: "Birju",
        last_name: "Maharaj",
      },
    ],
    display: "Dances composed and choreographed by",
  },
  {
    key: "re_recorder",
    value: [
      {
        key: "mangesh-desai",
        value: "Mangesh Desai",
        first_name: "Mangesh",
        last_name: "Desai",
      },
    ],
    display: "Re-recording",
  },
  {
    key: "music_recorder",
    value: [
      {
        key: "samir-majumdar",
        value: "Samir Majumdar",
        first_name: "Samir",
        last_name: "Majumdar",
      },
    ],
    display: "Music Recording",
  },
  {
    key: "dialogue_writer",
    value: [
      {
        key: "satyajit-ray",
        value: "Satyajit Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
      {
        key: "satyajit-ray",
        value: "Satyajit-Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
      {
        key: "javed-siddiqi",
        value: "Javed Siddiqi",
        first_name: "Javed",
        last_name: "Siddiqi",
      },
      {
        key: "shama-zaidi",
        value: "Shama Zaidi",
        first_name: "Shama",
        last_name: "Zaidi",
      },
    ],
    display: "Dialogue",
  },
  {
    key: "narrator",
    value: [
      {
        key: "amitabh-bachchan",
        value: "Amitabh Bachchan",
        first_name: "Amitabh",
        last_name: "Bachchan",
      },
    ],
    display: "Narration",
  },
  {
    key: "film_producer",
    value: [
      {
        key: "suresh-jindal",
        value: "Suresh-Jindal",
        first_name: "Suresh",
        last_name: "Jindal",
      },
      {
        key: "suresh-jindal",
        value: "Suresh Jindal",
        first_name: "Suresh",
        last_name: "Jindal",
      },
    ],
    display: "Producer",
  },
  {
    key: "screenplay",
    value: [
      {
        key: "satyajit-ray",
        value: "Satyajit-Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
      {
        key: "satyajit-ray",
        value: "Satyajit Ray",
        first_name: "Satyajit",
        last_name: "Ray",
      },
    ],
    display: "Screenplay, Music & Direction",
  },
];

//silsila
export const silsila_cast_view_data = [
  {
    "key": "banner",
    "value": [
        {
            "name": null,
            "alias": "Yash-Raj-Films",
            "type": "banner",
            "first_name": "Yash Raj",
            "last_name": "Films",
            "full_name": "Yash Raj Films",
            "link":true

        }
    ],
    "display": "Banner"
},
  {
      "key": "film_producer",
      "value": [
          {
              "name": null,
              "alias": "yash-chopra",
              "type": "film_producer",
              "first_name": "Yash",
              "last_name": "Chopra",
              "full_name": "Yash Chopra",
              "link":true

          }
      ],
      "display": "Film Producer"
  },
  {
      "key": "film_director",
      "display": "Film Director",
      "value": [
        {
          "name": null,
          "alias": "yash-chopra",
              "type": "film_producer",
              "first_name": "Yash",
              "last_name": "Chopra",
              "full_name": "Yash Chopra",
          "link":true
      }
    ]
  },
  {
      "key": "script",
      "display": "Script",
      "value": [
        {
          "name": null,
          "alias": "Sagar-Sarhadi",
          "type": "screenplay",
          "first_name": "Sagar",
          "last_name": "Sarhadi",
          "full_name": "Sagar Sarhadi",
          "link":true
      }
    ]
  },
  {
    "key": "Story",
    "value": [
      {
        "name": "Preeti Bedi",
        "alias": null,
        "type": "Story",
        "first_name": "Preeti",
        "last_name": "Bedi",
        "full_name": "Preeti Bedi",
        "link": false
      }
    ],
    "display": "Story"
  },  
  {
    "key": "Screenplay",
    "value": [
      {
        "name": "Sagar Sarhadi",
        "alias": null,
        "type": "Screenplay",
        "first_name": "Sagar",
        "last_name": "Sarhadi",
        "full_name": "Sagar Sarhadi",
        "link": false
      },
      {
        "name": "Yash Chopra",
        "alias": null,
        "type": "Screenplay",
        "first_name": "Yash",
        "last_name": "Chopra",
        "full_name": "Yash Chopra",
        "link": false
      }
    ],
    "display": "Screenplay"
  },
  {
      "key": "music",
      "value": [
        
          {
            "name": null,
            "alias": "Shiv",
            "type": "screenplay",
            "first_name": "Shiv",
            "last_name": "",
            "full_name": "Shiv",
            "link":true
        },
        {
          "name": null,
          "alias": "Hari",
          "type": "screenplay",
          "first_name": "Hari",
          "last_name": "",
          "full_name": "Hari",
          "link":true
      }
      ],
      "display": "Music"
  },
  {
    "key": "Director of Photography",
    "value": [
      {
        "name": "Kay Gee",
        "alias": null,
        "type": "Director of Photography",
        "first_name": "Kay",
        "last_name": "Gee",
        "full_name": "Kay Gee",
        "link": false
      },
      {
        "name": "W I C A",
        "alias": null,
        "type": "Director of Photography",
        "first_name": "W",
        "last_name": "I C A",
        "full_name": "W I C A",
        "link": false
      }
    ],
    "display": "Director of Photography"
  },
  {
    "key": "Lyrics",
    "value": [
      {
        "name": "Javed Akhtar",
        "alias": null,
        "type": "Lyrics",
        "first_name": "Javed",
        "last_name": "Akhtar",
        "full_name": "Javed Akhtar",
        "link": false
      },
      {
        "name": "Rajendra Krishan",
        "alias": null,
        "type": "Lyrics",
        "first_name": "Rajendra",
        "last_name": "Krishan",
        "full_name": "Rajendra Krishan",
        "link": false
      },
      {
        "name": "Nida Fazli",
        "alias": null,
        "type": "Lyrics",
        "first_name": "Nida",
        "last_name": "Fazli",
        "full_name": "Nida Fazli",
        "link": false
      },
      {
        "name": "Hassan Kamal",
        "alias": null,
        "type": "Lyrics",
        "first_name": "Hassan",
        "last_name": "Kamal",
        "full_name": "Hassan Kamal",
        "link": false
      },
      {
        "name": "Harivansh Rai Bachchan",
        "alias": null,
        "type": "Lyrics",
        "first_name": "Harivansh Rai",
        "last_name": "Bachchan",
        "full_name": "Harivansh Rai Bachchan",
        "link": false
      }
    ],
    "display": "Lyrics"
  },
  {
    "key": "Audiography",
    "value": [
      {
        "name": "M A Shaikh",
        "alias": null,
        "type": "Audiography",
        "first_name": "M A",
        "last_name": "Shaikh",
        "full_name": "M A Shaikh",
        "link": false
      }
    ],
    "display": "Audiography"
  },
  {
    "key": "Art Direction",
    "value": [
      {
        "name": "Sudhendu Roy",
        "alias": null,
        "type": "Art Direction",
        "first_name": "Sudhendu",
        "last_name": "Roy",
        "full_name": "Sudhendu Roy",
        "link": false
      }
    ],
    "display": "Art Direction"
  },
  {
    "key": "Art Direction",
    "value": [
      {
        "name": "Sudhendu Roy",
        "alias": null,
        "type": "Art Direction",
        "first_name": "Sudhendu",
        "last_name": "Roy",
        "full_name": "Sudhendu Roy",
        "link": false
      }
    ],
    "display": "Art Direction"
  },
  {
    "key": "Film Editor",
    "value": [
      {
        "name": "Keshav Naidu",
        "alias": null,
        "type": "Film Editor",
        "first_name": "Keshav",
        "last_name": "Naidu",
        "full_name": "Keshav Naidu",
        "link": false
      }
    ],
    "display": "Film Editor"
  },
  {
    "key": "Choreography",
    "value": [
      {
        "name": "Suresh Bhatt",
        "alias": null,
        "type": "Choreography",
        "first_name": "Suresh",
        "last_name": "Bhatt",
        "full_name": "Suresh Bhatt",
        "link": false
      }
    ],
    "display": "Choreography"
  },
  {
    "key": "Chief Make-up Artist",
    "value": [
      {
        "name": "Pandhari Juker",
        "alias": null,
        "type": "Chief Make-up Artist",
        "first_name": "Pandhari",
        "last_name": "Juker",
        "full_name": "Pandhari Juker",
        "link": false
      }
    ],
    "display": "Chief Make-up Artist"
  },
  {
    "key": "Thrills",
    "value": [
      {
        "name": "Khodi Irani",
        "alias": null,
        "type": "Thrills",
        "first_name": "Khodi",
        "last_name": "Irani",
        "full_name": "Khodi Irani",
        "link": false
      },
      {
        "name": "Manek",
        "alias": null,
        "type": "Thrills",
        "first_name": "Manek",
        "last_name": null,
        "full_name": "Manek",
        "link": false
      }
    ],
    "display": "Thrills"
  },
  {
    "key": "Sound Recoding",
    "value": [
      {
        "name": "S Das Gupta",
        "alias": null,
        "type": "Sound Recoding",
        "first_name": "S",
        "last_name": "Das Gupta",
        "full_name": "S Das Gupta",
        "link": false
      },
      {
        "name": "S Subramanium",
        "alias": null,
        "type": "Sound Recoding",
        "first_name": "S",
        "last_name": "Subramanium",
        "full_name": "S Subramanium",
        "link": false
      }
    ],
    "display": "Sound Recoding"
  },
  {
    "key": "Recording",
    "value": [
      {
        "name": "H M V ",
        "alias": null,
        "type": "Recording",
        "first_name": "H M V",
        "last_name": "",
        "full_name": "H M V ",
        "link": false
      }
    ],
    "display": "Recording"
  },
  {
    "key": "Publicity",
    "value": [
      {
        "name": "VP Sathe",
        "alias": null,
        "type": "Publicity",
        "first_name": "V P",
        "last_name": "Sathe",
        "full_name": "VP Sathe",
        "link": false
      }
    ],
    "display": "Publicity"
  },
  {
    "key": "Director of Publicity",
    "value": [
      {
        "name": "Bunny Reuben",
        "alias": null,
        "type": "Director of Publicity",
        "first_name": "Bunny",
        "last_name": "Reuben",
        "full_name": "Bunny Reuben",
        "link": false
      }
    ],
    "display": "Director of Publicity"
  },
  {
    "key": "Stills",
    "value": [
      {
        "name": "Vijay Hangal",
        "alias": null,
        "type": "Stills",
        "first_name": "Vijay",
        "last_name": "Hangal",
        "full_name": "Vijay Hangal",
        "link": false
      }
    ],
    "display": "Stills"
  },
  {
    "key": "Artwork",
    "value": [
      {
        "name": "Studi Diwakar",
        "alias": null,
        "type": "Artwork",
        "first_name": "Studi",
        "last_name": "Diwakar",
        "full_name": "Studi Diwakar",
        "link": false
      }
    ],
    "display": "Artwork"
  },
  {
    "key": "Playback Singers",
    "value": [
      {
        "name": "Lata Mangeshkar",
        "alias": null,
        "type": "Playback Singers",
        "first_name": "Lata",
        "last_name": "Mangeshkar",
        "full_name": "Lata Mangeshkar",
        "link": false
      },
      {
        "name": "Kishore Kumar",
        "alias": null,
        "type": "Playback Singers",
        "first_name": "Kishore",
        "last_name": "Kumar",
        "full_name": "Kishore Kumar",
        "link": false
      },
      {
        "name": "Amitabh Bachchan",
        "alias": null,
        "type": "Playback Singers",
        "first_name": "Amitabh",
        "last_name": "Bachchan",
        "full_name": "Amitabh Bachchan",
        "link": false
      },
      {
        "name": "Pamela Chopra",
        "alias": null,
        "type": "Playback Singers",
        "first_name": "Pamela",
        "last_name": "Chopra",
        "full_name": "Pamela Chopra",
        "link": false
      }
    ],
    "display": "Playback Singers"
  },
  {
    "key": "Cameraman",
    "value": [
      {
        "name": "Habib Ahmed",
        "alias": null,
        "type": "Cameraman",
        "first_name": "Habib",
        "last_name": "Ahmed",
        "full_name": "Habib Ahmed",
        "link": false
      }
    ],
    "display": "Cameraman"
  },
  {
    "key": "Costumes",
    "value": [
      {
        "name": "Kachins",
        "alias": null,
        "type": "Costumes",
        "first_name": null,
        "last_name": null,
        "full_name": "Kachins",
        "link": false
      },
      {
        "name": "Artistic",
        "alias": null,
        "type": "Costumes",
        "first_name": null,
        "last_name": null,
        "full_name": "Artistic",
        "link": false
      },
      {
        "name": "Debonair",
        "alias": null,
        "type": "Costumes",
        "first_name": null,
        "last_name": null,
        "full_name": "Debonair",
        "link": false
      }
    ],
    "display": "Costumes"
  }
           

];
export const silsila_horizontal_images_data=[
  [
      {
          "key": "artist_name",
          "value": "Sanjeev Kumar",
          "alias":"sanjeev-kumar",
          "display": "Film Producer",
          "link":true
      },
      {
          "key": "character_name",
          "value": "Mirza Sajjad Ali",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value":placeholderImg,
                  "date_of_artwork": "1977",
                  "accession_number": "Working Still",
                  "name_of_publication": "Shatranj Ke Khilari",
                  "publication_year": "",
                  "sortable_year_of_publication": "1977",
                  "artist_name": "Nemai Ghosh",
                  "medium_of_artwork": "Photographic Still",
                  "revised_medium": "CINE.pho"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Shabana Azmi",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Khurshid, Mirza’s wife",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                "value":placeholderImg,
                "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Saeed Jaffrey",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Mir Roshan Ali",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value":placeholderImg,
                  "date_of_artwork": "1977",
                  "accession_number": "Working Still",
                  "name_of_publication": "Shatranj Ke Khilari",
                  "publication_year": "",
                  "sortable_year_of_publication": "1977",
                  "artist_name": "Nemai Ghosh",
                  "medium_of_artwork": "Photographic Still",
                  "revised_medium": "CINE.pho"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Farida Jalal",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Nafeesa, Mir’s wife",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                   "value":placeholderImg,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Amjad Khan",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Wajid Ali Shah, Nawab (king)",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value":placeholderImg,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Victor Banerjee",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Ali Naqi Khan, the prime minister",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value":placeholderImg,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Richard Attenborough",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "General Outram",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value":placeholderImg,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ],
  [
      {
          "key": "artist_name",
          "value": "Farooq Shaikh",
          "display": "Film Producer"
      },
      {
          "key": "character_name",
          "value": "Aqueel",
          "display": "Film Director"
      },
      {
          "key": "characterImage",
          "value": [
              {
                  "value":placeholderImg,
                  "date_of_artwork": "2017",
                  "accession_number": "My Adventures with Satyajit Ray",
                  "name_of_publication": "My Adventures with Satyajit Ray: The Making of Shatranj Ke Khilari",
                  "publication_year": "2017",
                  "sortable_year_of_publication": "2017",
                  "artist_name": "Suresh Jindal",
                  "medium_of_artwork": "Book",
                  "revised_medium": "BOOK.bka"
              }
          ],
          "display": "Image"
      }
  ]
]
export const silsila_synopsis_data = [
  {
    key: "alias",
    value: "silsila",
    display: "Alias",
  },
  {
    key: "synopsis",
    value: [
      {
        value: `Far away from suspicions and self-love, somewhere close, very close to trust. Heart woke up with a mirage that she loves me.`,
      },
      {
        value: "Is it love or dream?",
      },
      {
        value: "Amit's dream-the sequence of dreams, the sequence of love.",
      },
      {
        value: "One face, one hope, one smile.",
      },
      {
        value: "A search, a poignancy, a happiness A world, a voice, a song.",
      },
      {
        value: "One dream, one illusion, one faith. ",
      },
      {
        value:
          "Everything merged into a face-a face called Chandni. Chandni and Amit, between them flowered an eloquent love-a music vibrated. Both set out step by step on a journey as co-travellers, but the idyll did not last.",
      },
      {
        value:
          "Amit approached Shobha to wipe her tears, gave up all his happiness. Now he had nothing except live embers of memories. Every heartbeat of Amit was coaxing him to remember some and forget the others. Shobha had stepped into his life. Shobha a silence personified, a summit, a worship. She was not a dream but a reality-a truth, a faith.",
      },
      {
        value:
          "Amit, Shobha and Chandni were not just three names-but three directions, three human beings bound by love-three facets of love. Dreams of these three were scattered by the mists of life..",
      },
      {
        value:
          "What does it matter if some dream ends in a calamity? Time cannot minimise the intensity of feelings,",
      },
    ],
    display: "Synopsis",
  },
  {
    key: "synopsisImages",
    value: [
      {
        key: "Silsila-book-ssb-000013a",
        value:synp1,
        date_of_artwork: "1981",
        wnn: "000013a",
        accession_number: "Book.ssb",
        name_of_publication: "Silsila",
        artist_name: "",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "Book.ssb",
      },
      {
        key: "Silsila-book-ssb-000013b",
        value:synp2,
        date_of_artwork: "1981",
        wnn: "000013a",
        accession_number: "Book.ssb",
        name_of_publication: "Silsila",
        artist_name: "",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "Book.ssb",
      },
      {
        key: "Silsila-book-ssb-000013c",
        value:synp3,
        date_of_artwork: "1981",
        wnn: "000013a",
        accession_number: "Book.ssb",
        name_of_publication: "Silsila",
        artist_name: "",
        medium_of_artwork: "Song Synopsis Booklet",
        revised_medium: "Book.ssb",
      },
    ],
    display: "Synopsis Images",
  },
];

export const silsila_credits_view_data=[
  {
    "key": "banner",
    "value": [
      {
        "key": "Yash-Raj-Films",
        "value": "Yash Raj Films",
        "display": "Banner"
      }
    ],
    "display": "Banner"
  },
  {
    "key": "producer",
    "value": [
      {
        "key": "Yash-Chopra",
        "value": "Yash Chopra",
        "display": "Producer"
      }
    ],
    "display": "Producer"
  },
  {
    "key": "director",
    "value": [
      {
        "key": "Yash-Chopra",
        "value": "Yash Chopra",
        "display": "Director"
      }
    ],
    "display": "Director"
  },
  {
    "key": "script",
    "value": [
      {
        "key": "Sagar-Sarhadi",
        "value": "Sagar Sarhadi",
        "display": "Script"
      }
    ],
    "display": "Script"
  },
  {
    "key": "story",
    "value": [
      {
        "key": "Preeti-Bedi",
        "value": "Preeti Bedi",
        "display": "Story"
      }
    ],
    "display": "Story"
  },
  {
    "key": "lead_cast",
    "value": [
      {
        "key": "Kapoor",
        "value": "Shashi Kapoor",
        "first_name": "Shashi",
        "last_name": "Kapoor",
        "link": true,
        "display": "Lead Cast"
      },
      {
        "key": "Kumar",
        "value": "Sanjeev Kumar",
        "first_name": "Sanjeev",
        "last_name": "Kumar",
        "link": true,
        "display": "Lead Cast"
      },
      {
        "key": "Bachchan",
        "value": "Amitabh Bachchan",
        "first_name": "Amitabh",
        "last_name": "Bachchan",
        "link": true,
        "display": "Lead Cast"
      },
      {
        "key": "Bachchan",
        "value": "Jaya Bachchan",
        "first_name": "Jaya",
        "last_name": "Bachchan",
        "link": true,
        "display": "Lead Cast"
      },
      {
        "key": "Rekha",
        "value": "Rekha",
        "first_name": "Rekha",
        "last_name": "",
        "display": "Lead Cast"
      }
    ],
    "display": "Lead Cast"
  },
  {
    "key": "lead_cast_rest",
    "value": [
      {
        "key": "Chopra",
        "value": "Sudha Chopra",
        "first_name": "Sudha",
        "last_name": "Chopra",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Seth",
        "value": "Sushma Seth",
        "first_name": "Sushma",
        "last_name": "Seth",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Dubey",
        "value": "Ravi Dubey",
        "first_name": "Ravi",
        "last_name": "Dubey",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Chowdhary",
        "value": "Sharmila Roy Chowdhary",
        "first_name": "Sharmila Roy",
        "last_name": "Chowdhary",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Raj",
        "value": "Jagdish Raj",
        "first_name": "Jagdish",
        "last_name": "Raj",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Anand",
        "value": "Vikas Anand",
        "first_name": "Vikas",
        "last_name": "Anand",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Raj",
        "value": "Ranvir Raj",
        "first_name": "Ranvir",
        "last_name": "Raj",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Arora",
        "value": "Shyam Arora",
        "first_name": "Shyam",
        "last_name": "Arora",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Bharti",
        "value": "Raj Bharti",
        "first_name": "Raj",
        "last_name": "Bharti",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Shankar",
        "value": "Gauri Shankar",
        "first_name": "Gauri",
        "last_name": "Shankar",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Puri",
        "value": "Damyanti Puri",
        "first_name": "Damyanti",
        "last_name": "Puri",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Verma",
        "value": "Nirupma Verma",
        "first_name": "Nirupma",
        "last_name": "Verma",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Anand",
        "value": "Kimti Anand",
        "first_name": "Kimti",
        "last_name": "Anand",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Khan",
        "value": "Feroz Khan",
        "first_name": "Feroz",
        "last_name": "Khan",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Manek",
        "value": "Manek",
        "first_name": "Manek",
        "last_name": "",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Verma",
        "value": "Ashok Verma",
        "first_name": "Ashok",
        "last_name": "Verma",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Puri",
        "value": "Pritam Puri",
        "first_name": "Pritam",
        "last_name": "Puri",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Kharbanda",
        "value": "Kulbhushan Kharbanda",
        "first_name": "Kulbhushan",
        "last_name": "Kharbanda",
        "display": "Lead Cast (Rest)"
      },
      {
        "key": "Verma",
        "value": "Deven Verma",
        "first_name": "Deven",
        "last_name": "Verma",
        "display": "Lead Cast (Rest)"
      }
    ],
    "display": "Lead Cast (Rest)"
  },
  {
    "key": "screenplay",
    "value": [
      {
        "key": "Sagar-Sarhadi, Yash-Chopra",
        "value": "Sagar Sarhadi, Yash Chopra",
        "display": "Screenplay"
      }
    ],
    "display": "Screenplay"
  },
  {
    "key": "music",
    "value": [
      {
        "key": "Shiv, Hari",
        "value": "Shiv, Hari",
        "display": "Music"
      }
    ],
    "display": "Music"
  },
  {
    "key": "director_of_photography",
    "value": [
      {
        "key": "Kay-Gee",
        "value": "Kay Gee",
        "display": "Director of Photography"
      }
    ],
    "display": "Director of Photography"
  },
  {
    "key": "assistants",
    "value": [
      {
        "key": "Kashinath-Bose",
        "value": "Kashinath Bose",
        "display": "Assistants"
      }
    ],
    "display": "Assistants"
  },
  {
    "key": "lyrics",
    "value": [
      {
        "key": "Javed-Akhtar",
        "value": "Javed Akhtar",
        "display": "Lyrics",
        "first_name": "Javed",
        "last_name": "Akhtar",
        "link": true
      },
      {
        "key": "Rajendra-Krishan",
        "value": "Rajendra Krishan",
        "display": "Lyrics",
        "first_name": "Rajendra",
        "last_name": "Krishan"
      },
      {
        "key": "Nida-Fazli",
        "value": "Nida Fazli",
        "display": "Lyrics",
        "first_name": "Nida",
        "last_name": "Fazli"
      },
      {
        "key": "Hassan-Kamal",
        "value": "Hassan Kamal",
        "display": "Lyrics",
        "first_name": "Hassan",
        "last_name": "Kamal"
      },
      {
        "key": "Harivansh-Rai-Bachchan",
        "value": "Harivansh Rai Bachchan",
        "display": "Lyrics",
        "first_name": "Harivansh Rai",
        "last_name": "Bachchan"
      }
    ],
    "display": "Lyrics"
  },
  {
    "key": "audiography",
    "value": [
      {
        "key": "M-A-Shaikh",
        "value": "M A Shaikh",
        "display": "Audiography"
      }
    ],
    "display": "Audiography"
  },
  {
    "key": "associate_audiography",
    "value": [
      {
        "key": "Ramesh-Yadav",
        "value": "Ramesh Yadav",
        "display": "Associate (Audiography)"
      }
    ],
    "display": "Associate (Audiography)"
  },
  {
    "key": "art_direction",
    "value": [
      {
        "key": "Sudhendu-Roy",
        "value": "Sudhendu Roy",
        "display": "Art Direction"
      }
    ],
    "display": "Art Direction"
  },
  {
    "key": "film_editor",
    "value": [
      {
        "key": "Keshav-Naidu",
        "value": "Keshav Naidu",
        "display": "Film Editor"
      }
    ],
    "display": "Film Editor"
  },
  {
    "key": "choreography",
    "value": [
      {
        "key": "Suresh-Bhatt",
        "value": "Suresh Bhatt",
        "display": "Choreography"
      }
    ],
    "display": "Choreography"
  },
  {
    "key": "associates_choreography",
    "value": [
      {
        "key": "Aruna",
        "value": "Aruna",
        "display": "Associates (Choreography)"
      }
    ],
    "display": "Associates (Choreography)"
  },
  {
    "key": "location_management",
    "value": [
      {
        "key": "J-N-Srivastava",
        "value": "J. N. Srivastava",
        "display": "Location Management"
      }
    ],
    "display": "Location Management"
  },
  {
    "key": "chief_make-up_artist",
    "value": [
      {
        "key": "Pandhari-Juker",
        "value": "Pandhari Juker",
        "display": "Chief Make-up  Artist"
      }
    ],
    "display": "Chief Make-up  Artist"
  },
  {
    "key": "thrills",
    "value": [
      {
        "key": "Khodi-Irani, Manek",
        "value": "Khodi Irani, Manek",
        "display": "Thrills"
      }
    ],
    "display": "Thrills"
  },
  {
    "key": "sound_recoding",
    "value": [
      {
        "key": "S-Das-Gupta, S-Subramanium",
        "value": "S Das Gupta, S Subramanium",
        "display": "Sound Recoding"
      }
    ],
    "display": "Sound Recoding"
  },
  {
    "key": "assistants_sound_recording",
    "value": [
      {
        "key": "S-Chawla",
        "value": "S Chawla",
        "display": "Assistants (Sound Recording)"
      }
    ],
    "display": "Assistants (Sound Recording)"
  },
  {
    "key": "recording",
    "value": [
      {
        "key": "H-M-V",
        "value": "H M V",
        "display": "Recording"
      }
    ],
    "display": "Recording"
  },
  {
    "key": "re-recording",
    "value": [
      {
        "key": "Mangesh-Desai",
        "value": "Mangesh Desai",
        "display": "Re-recording"
      }
    ],
    "display": "Re-recording"
  },
  {
    "key": "associate_sound_recording",
    "value": [
      {
        "key": "Kuldip-Sood",
        "value": "Kuldip Sood",
        "display": "Associate (Sound Recording)"
      }
    ],
    "display": "Associate (Sound Recording)"
  },
  {
    "key": "publicity",
    "value": [
      {
        "key": "VP-Sathe",
        "value": "VP Sathe",
        "display": "Publicity"
      }
    ],
    "display": "Publicity"
  },
  {
    "key": "director_of_publicity",
    "value": [
      {
        "key": "Bunny-Reuben",
        "value": "Bunny Reuben",
        "display": "Director of Publicity"
      }
    ],
    "display": "Director of Publicity"
  },
  {
    "key": "stills",
    "value": [
      {
        "key": "Vijay-Hangal",
        "value": "Vijay Hangal",
        "display": "Stills"
      }
    ],
    "display": "Stills"
  },
  {
    "key": "artwork",
    "value": [
      {
        "key": "Studi-Diwakar",
        "value": "Studi Diwakar",
        "display": "Artwork"
      }
    ],
    "display": "Artwork"
  },
  {
    "key": "printers",
    "value": [
      {
        "key": "Oriental-Offset",
        "value": "Oriental Offset",
        "display": "Printers"
      },
      {
        "key": "Parksons",
        "value": "Parksons",
        "display": "Printers"
      },
      {
        "key": "Diwakar-Prints",
        "value": "Diwakar Prints",
        "display": "Printers"
      }
    ],
    "display": "Printers"
  },
  {
    "key": "special_effects",
    "value": [
      {
        "key": "Danny-Desai",
        "value": "Danny Desai",
        "display": "Special Effects"
      }
    ],
    "display": "Special Effects"
  },
  {
    "key": "opticals",
    "value": [
      {
        "key": "Prasad-Productions, Madras",
        "value": "Prasad Productions, Madras",
        "display": "Opticals"
      }
    ],
    "display": "Opticals"
  },
  {
    "key": "titles",
    "value": [
      {
        "key": "Praful",
        "value": "Praful",
        "display": "Titles"
      }
    ],
    "display": "Titles"
  },
  {
    "key": "playback_singers",
    "value": [
      {
        "key": "Lata-Mangeshkar",
        "value": "Lata Mangeshkar",
        "display": "Playback Singers",
        "first_name": "Lata",
        "last_name": "Mangeshkar",
        "link": true
      },
      {
        "key": "Kishore-Kumar",
        "value": "Kishore Kumar",
        "display": "Playback Singers",
        "first_name": "Kishore",
        "last_name": "Kumar",
        "link": true
      },
      {
        "key": "Amitabh-Bachchan",
        "value": "Amitabh Bachchan",
        "display": "Playback Singers",
        "first_name": "Amitabh",
        "last_name": "Bachchan",
        "link": true
      },
      {
        "key": "Pamela-Chopra",
        "value": "Pamela Chopra",
        "display": "Playback Singers",
        "first_name": "Pamela",
        "last_name": "Chopra",
        "link": true
      }
    ],
    "display": "Playback Singers"
  },
  {
    "key": "disc",
    "value": [
      {
        "key": "H-M-V",
        "value": "H M V",
        "display": "Disc"
      }
    ],
    "display": "Disc"
  },
  {
    "key": "cameraman",
    "value": [
      {
        "key": "Habib-Ahmed",
        "value": "Habib Ahmed",
        "display": "Cameraman"
      }
    ],
    "display": "Cameraman"
  },
  {
    "key": "chief_assistant_director",
    "value": [
      {
        "key": "Rajesh-Sethi",
        "value": "Rajesh Sethi",
        "display": "Chief Assistant Director"
      }
    ],
    "display": "Chief Assistant Director"
  },
  {
    "key": "sound_editor",
    "value": [
      {
        "key": "Jethu-Mandal",
        "value": "Jethu Mandal",
        "display": "Sound Editor"
      }
    ],
    "display": "Sound Editor"
  },
  {
    "key": "general_manager",
    "value": [
      {
        "key": "Gurdip-Singh",
        "value": "Gurdip Singh",
        "display": "General Manager"
      }
    ],
    "display": "General Manager"
  },
  {
    "key": "production_executive",
    "value": [
      {
        "key": "Atamjit-Singh",
        "value": "Atamjit Singh",
        "display": "Production Executive"
      }
    ],
    "display": "Production Executive"
  },
  {
    "key": "chief_accountant",
    "value": [
      {
        "key": "V-W-K-Bonsale",
        "value": "V W K Bonsale",
        "display": "Chief Accountant"
      }
    ],
    "display": "Chief Accountant"
  },
  {
    "key": "accountant",
    "value": [
      {
        "key": "V-Jagannathan",
        "value": "V Jagannathan",
        "display": "Accountant"
      }
    ],
    "display": "Accountant"
  },
  {
    "key": "assistants_accountant",
    "value": [
      {
        "key": "A-Vithala",
        "value": "A Vithala",
        "display": "Assistants (Accountant)",
        "first_name": "A",
        "last_name": "Vithala"
      },
      {
        "key": "Sunil-V-Kamat",
        "value": "Sunil V Kamat",
        "display": "Assistants (Accountant)",
        "first_name": "Sunil",
        "last_name": "Kamat"
      }
    ],
    "display": "Assistants (Accountant)"
  },
  {
    "key": "wardrobe",
    "value": [
      {
        "key": "Jaikishan",
        "value": "Jaikishan",
        "display": "Wardrobe"
      }
    ],
    "display": "Wardrobe"
  },
  {
    "key": "associate_wardrobe",
    "value": [
      {
        "key": "Shashikant",
        "value": "Shashikant",
        "display": "Associate (Wardrobe)"
      }
    ],
    "display": "Associate (Wardrobe)"
  },
  {
    "key": "costumes",
    "value": [
      {
        "key": "Kachins",
        "value": "Kachins",
        "display": "Costumes"
      },
      {
        "key": "Artistic",
        "value": "Artistic",
        "display": "Costumes"
      },
      {
        "key": "Debonair",
        "value": "Debonair",
        "display": "Costumes"
      }
    ],
    "display": "Costumes"
  },
  {
    "key": "dress_designer",
    "value": [
      {
        "key": "Pamela-Chopra",
        "value": "Pamela Chopra",
        "display": "Dress Designer",
        "first_name": "Pamela",
        "last_name": "Chopra",
        "link": true
      },
      {
        "key": "Jenifer-Kapoor",
        "value": "Jenifer Kapoor",
        "display": "Dress Designer",
        "first_name": "Jenifer",
        "last_name": "Kapoor",
        "link": true
      },
      {
        "key": "Leena-Daru",
        "value": "Leena Daru",
        "display": "Dress Designer",
        "first_name": "Leena",
        "last_name": "Daru",
        "link": true
      }
    ],
    "display": "Dress Designer"
  },{
    "key": "hair_style",
    "value": [
      {
        "key": "Rabadi, Lily",
        "value": "Rabadi, Lily",
        "display": "Hair Style"
      }
    ],
    "display": "Hair Style"
  },
  {
    "key": "make-up",
    "value": [
      {
        "key": "Shyam",
        "value": "Shyam",
        "display": "Make-up"
      },
      {
        "key": "Ram-Sawant",
        "value": "Ram Sawant",
        "display": "Make-up"
      },
      {
        "key": "Shyam-Khandekar",
        "value": "Shyam Khandekar",
        "display": "Make-up"
      },
      {
        "key": "Parveen",
        "value": "Parveen",
        "display": "Make-up"
      }
    ],
    "display": "Make-up"
  },
  {
    "key": "costume_supplier",
    "value": [
      {
        "key": "Madanlal-Dresswala",
        "value": "Madanlal Dresswala",
        "display": "Costume Supplier"
      }
    ],
    "display": "Costume Supplier"
  },
  {
    "key": "junior_artist_supplier",
    "value": [
      {
        "key": "Lekhraj-And-Co",
        "value": "Lekhraj And Co",
        "display": "Junior Artist Supplier"
      }
    ],
    "display": "Junior Artist Supplier"
  },
  {
    "key": "chief_electrician",
    "value": [
      {
        "key": "M-B-Ansari",
        "value": "M B Ansari",
        "display": "Chief Electrician"
      }
    ],
    "display": "Chief Electrician"
  },
  {
    "key": "produced_at",
    "value": [
      {
        "key": "Rajkamal-Kalamandir, Filmcity",
        "value": "Rajkamal Kalamandir, Filmcity",
        "display": "Produced At"
      }
    ],
    "display": "Produced At"
  },
  {
    "key": "processed_at",
    "value": [
      {
        "key": "Navrang-Cine-Centre (Pvt.) Ltd. & Prasad Laboratories, Madras",
        "value": "Navrang Cine Centre (Pvt.) Ltd. & Prasad Laboratories, Madras",
        "display": "Processed At"
      }
    ],
    "display": "Processed At"
  },
  {
    "key": "technical_supervisor",
    "value": [
      {
        "key": "V-Padmakar",
        "value": "V Padmakar",
        "display": "Technical Supervisor"
      }
    ],
    "display": "Technical Supervisor"
  },
  {
    "key": "radio_publicity",
    "value": [
      {
        "key": "T-V- & Radio Publicity Servuces",
        "value": "T.V. & Radio Publicity Servuces",
        "display": "Radio Publicity"
      }
    ],
    "display": "Radio Publicity"
  },
  {
    "key": "travel_agents",
    "value": [
      {
        "key": "Skyspeed",
        "value": "Skyspeed",
        "display": "Travel Agents"
      },
      {
        "key": "Bombay",
        "value": "Bombay",
        "display": "Travel Agents"
      },
      {
        "key": "Ind-Travels",
        "value": "Ind Travels",
        "display": "Travel Agents"
      },
      {
        "key": "Delhi",
        "value": "Delhi",
        "display": "Travel Agents"
      },
      {
        "key": "Shiraz-Travels",
        "value": "Shiraz Travels",
        "display": "Travel Agents"
      },
      {
        "key": "Srinagar",
        "value": "Srinagar",
        "display": "Travel Agents"
      }
    ],
    "display": "Travel Agents"
  },
  {
    "key": "equipment_supplier",
    "value": [
      {
        "key": "H-Karmali",
        "value": "H. Karmali",
        "display": "Equipment Supplier"
      },
      {
        "key": "Ashrafi-Power-Supply",
        "value": "Ashrafi Power Supply",
        "display": "Equipment Supplier"
      }
    ],
    "display": "Equipment Supplier"
  },
  {
    "key": "furnishers_decorators",
    "value": [
      {
        "key": "Mohindra Construction Co",
        "value": "Mohindra Construction Co",
        "display": "Furnishers & Decorators"
      }
    ],
    "display": "Furnishers & Decorators"
  },
  {
    "key": "setting_materials",
    "value": [
      {
        "key": "A-K-Industries",
        "value": "A K Industries",
        "display": "Setting Materials"
      },
      {
        "key": "Gupta-Paints",
        "value": "Gupta Paints",
        "display": "Setting Materials"
      },
      {
        "key": "Ram-Co",
        "value": "Ram & Co.",
        "display": "Setting Materials"
      },
      {
        "key": "Plywood-Emporium",
        "value": "Plywood Emporium",
        "display": "Setting Materials"
      },
      {
        "key": "Bharat-Plywood",
        "value": "Bharat Plywood",
        "display": "Setting Materials"
      }
    ],
    "display": "Setting Materials"
  },
  {
    "key": "assistants_direction",
    "value": [
      {
        "key": "Deepak-Sareen",
        "value": "Deepak Sareen",
        "display": "Assistants (Direction)",
        "first_name": "Deepak",
        "last_name": "Sareen"
      },
      {
        "key": "Raju-Koregaonkar",
        "value": "Raju Koregaonkar",
        "display": "Assistants (Direction)",
        "first_name": "Raju",
        "last_name": "Koregaonkar"
      }
    ],
    "display": "Assistants (Direction)"
  },
  {
    "key": "assistant_camera",
    "value": [
      {
        "key": "T-Kishan",
        "value": "T Kishan",
        "display": "Assistant (Camera)",
        "first_name": "T",
        "last_name": "Kishan"
      },
      {
        "key": "Yusuf-Khan",
        "value": "Yusuf Khan",
        "display": "Assistant (Camera)",
        "first_name": "Yusuf",
        "last_name": "Khan"
      }
    ],
    "display": "Assistant (Camera)"
  },
  {
    "key": "assistants_music",
    "value": [
      {
        "key": "Gyan",
        "value": "Gyan",
        "display": "Assistants (Music)"
      },
      {
        "key": "Kishore",
        "value": "Kishore",
        "display": "Assistants (Music)"
      },
      {
        "key": "Ravi",
        "value": "Ravi",
        "display": "Assistants (Music)"
      },
      {
        "key": "Sameer",
        "value": "Sameer",
        "display": "Assistants (Music)"
      }
    ],
    "display": "Assistants (Music)"
  },
  {
    "key": "assistants_editing",
    "value": [
      {
        "key": "Virendra-Karnik",
        "value": "Virendra Karnik",
        "display": "Assistants (Editing)"
      },
      {
        "key": "Harkishan-Patel",
        "value": "Harkishan Patel",
        "display": "Assistants (Editing)"
      }
    ],
    "display": "Assistants (Editing)"
  },
  {
    "key": "assistants_production",
    "value": [
      {
        "key": "Anant-Gosavi",
        "value": "Anant Gosavi",
        "display": "Assistants (Production)"
      },
      {
        "key": "Rajesh-Bhatija",
        "value": "Rajesh Bhatija",
        "display": "Assistants (Production)"
      },
      {
        "key": "Murari",
        "value": "Murari",
        "display": "Assistants (Production)"
      },
      {
        "key": "Ashok-Verma",
        "value": "Ashok Verma",
        "display": "Assistants (Production)"
      }
    ],
    "display": "Assistants (Production)"
  },
  {
    "key": "assistants_stills",
    "value": [
      {
        "key": "Subhash",
        "value": "Subhash",
        "display": "Assistats (Stills)"
      }
    ],
    "display": "Assistats (Stills)"
  },
  {
    "key": "assistants_sound",
    "value": [
      {
        "key": "Hussain-Khan",
        "value": "Hussain Khan",
        "display": "Assistants (Sound)"
      }
    ],
    "display": "Assistants (Sound)"
  },
  {
    "key": "assistants_make-up",
    "value": [
      {
        "key": "Chaman",
        "value": "Chaman",
        "display": "Assistants (Make-up)"
      }
    ],
    "display": "Assistants (Make-up)"
  },
  {
    "key": "assistants_electrician",
    "value": [
      {
        "key": "Babban",
        "value": "Babban",
        "display": "Assistants (Electrician)"
      }
    ],
    "display": "Assistants (Electrician)"
  },
  {
    "key": "assistants_production_office",
    "value": [
      {
        "key": "Shekhar",
        "value": "Shekhar",
        "display": "Assistants (Production Office)"
      },
      {
        "key": "Elizabeth",
        "value": "Elizabeth",
        "display": "Assistants (Production Office)"
      }
    ],
    "display": "Assistants (Production Office)"
  }

];
