import { Box, Container, HStack, Image, Stack } from "@chakra-ui/react";
// Core files
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";

// Service Files
import * as getInsightDetailsData from "../../../services/Insight";

// Subsection Files
import Layout from "../../../components/sections/SidebarLayout";

function InsightVideo() {
  const { alias, insightAlias } = useParams();
  const masterlistTitle = alias;
  const insight = insightAlias;
  const [skkImg, setSkkImg] = useState(null);
  const [video, setVideo] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsight(
        "insights",
        masterlistTitle,
        insight
      );
      if (result) {
        setSkkImg(result[0]?.mainImage);
        setVideo(result[0]?.videolink);
      }
    };
    getData();
  }, [masterlistTitle, insight]);

  const opts = {
    height: "630px",
    width: "100%",
    playerVars: {
      autoplay: 2, // Change 2 to 1 for boolean value
      rel: 0,
    },
  };
  const onReady = (event) => {
    event.target.pauseVideo();
  };
    return (
    <Layout>
      <Container
        maxW={"auto"}
        paddingX={{ base: "15px", md: "30px" }}
        marginX={{ base: "0px", md: "30px" }}
      >
        <Stack>
          <Box
            position={"fixed"}
            zIndex={999}
            bg="white"
            width={{ base: "100%", md: "1230px" }}
            height={{ base: "auto", sm: "auto", md: "auto" }}
            paddingY={{ base: "20px", md: "30px" }}
          >
            <HStack>
              <Box
                marginRight={"0px!important"}
                width={{ base: "93%", md: "600px" }}
                height={{ base: "100%", md: "100%" }}
                display={"flex"}
              >
                {skkImg !== null && (
                  <Image
                    src={skkImg}
                    width={"100%"}
                    objectFit={"contain"}
                    height={"100%"}
                  />
                )}
              </Box>
             
            </HStack>
          </Box>
          <Box
                paddingY={{ base: "30px", md: "110px" }}
                marginRight={{ base: "0px", md: "30px" }}
                width={{ base: "100%", md: "1230px" }}
              >
               {video && <YouTube
                  videoId={video.slice(-11)}
                  opts={opts}
                  onReady={onReady}
                />}
              </Box>
        </Stack>
      </Container>
    </Layout>
  );
}
export default InsightVideo;
