//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const grid1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/1.png`
const grid2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/2.png`
const grid3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/3.png`
const grid4 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/4.png`
const grid5 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/5.png`
const grid6 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/6.png`
const grid7 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/7.png`
const grid8 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/8.png`
const grid9 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/9.png`
const grid10 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/10.png`
const grid11 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/11.png`
const grid12 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/12.png`
const grid13 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/13.png`
const grid14 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/14.png`
const grid16 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/16.png`
const grid17 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/17.png`
const grid18 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/GridView/18.png`
const slideimg1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/1.png`
const slideimg2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/2.png`
const slideimg3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/3.png`
const slideimg4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/4.png`
const slideimg5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/5.png`
const slideimg6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/6.png`
const slideimg7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/7.png`
const slideimg8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/8.png`

export const satyajit_filmography_data= [
    {
      id: 1,
      image: grid1,
      year: 1955,
      main_title: "Pather Panchali1",
      alternative_title: "Song of the Little Road",
      title_in_original: "পথের পাঁচালী",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "119 mins",
      region: "India",
    },
    {
      id: 2,
      image: grid2,
      year: 1956,
      main_title: "Aparajito",
      alternative_title: "The Unvanquished",
      title_in_original: "অপরাজিত",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "113 mins",
      region: "India",
    },
    {
      id: 3,
      image: grid3,
      year: 1958,
      main_title: "Jalsaghar",
      alternative_title: "The Music Room",
      title_in_original: "জলসাঘর",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "100 mins",
      region: "India",
    },
    {
      id: 4,
      image: grid4,
      year: 1958,
      main_title: "Parash Pathar",
      alternative_title: "The Philosopher's Stone",
      title_in_original: "পরশ পাথর",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "111 mins",
      region: "India",
    },
    {
      id: 5,
      image: grid5,
      year: 1959,
      main_title: "Apur Sansar",
      alternative_title: "The World of Apu",
      title_in_original: "অপুর সংসার",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "106 mins",
      region: "India",
    },
    {
      id: 6,
      image: grid6,
      year: 1960,
      main_title: "Devi",
      alternative_title: "The Goddess",
      title_in_original: "দেবী",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "99 mins",
      region: "India",
    },
    {
      id: 7,
      image: grid7,
      year: 1961,
      main_title: "Rabindranath Tagore",
      alternative_title: "N/A",
      title_in_original: "N/A",
      languages: ["English"],
      film_length: "Short",
      film_type: "Documentary",
      colour: "Black & White",
      duration: "54 mins",
      region: "India",
    },
    {
      id: 8,
      image: grid8,
      year: 1961,
      main_title: "Teen Kanya",
      alternative_title: "Three Daughters",
      title_in_original: "তিন কন্যা",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "56 mins",
      region: "India",
    },
    {
      id: 9,
      image: grid9,
      year: 1962,
      main_title: "Abhijan",
      alternative_title: "The Expedition",
      title_in_original: "অভিযান",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "150 mins",
      region: "India",
    },
    {
      id: 10,
      image: grid10,
      year: 1962,
      main_title: "Kanchanjungha",
      alternative_title: "Kanchonjônggha",
      title_in_original: "কাঞ্চনজঙ্ঘা",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Colour",
      duration: "120 mins",
      region: "India",
    },
    {
      id: 11,
      image: grid11,
      year: 1963,
      main_title: "Mahanagar",
      alternative_title: "The Big City",
      title_in_original: "মহানগর",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "131 mins",
      region: "India",
    },
    {
      id: 12,
      image: grid12,
      year: 1963,
      main_title: "The Householder",
      alternative_title: "",
      title_in_original: "",
      languages: ["Hindi"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "101 mins",
      region: "India",
    },
    {
      id: 13,
      image: grid13,
      year: 1964,
      main_title: "Charulata",
      alternative_title: "The Lonely Wife",
      title_in_original: "চারুলতা",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "117 mins",
      region: "India",
    },
    {
      id: 14,
      image: grid14,
      year: 1964,
      main_title: "Two",
      alternative_title: "",
      title_in_original: "",
      languages: [],
      film_length: "Short",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "12 mins",
      region: "India",
    },
    {
      id: 15,
      image: grid14,
      year: 1965,
      main_title: "Ka Purush",
      alternative_title: "The Coward",
      title_in_original: "কাপুরুষ",
      languages: ["Hindi"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "74 mins",
      region: "India",
    },
    {
      id: 16,
      image: grid16,
      year: 1965,
      main_title: "Maha Purush",
      alternative_title: "The Holy Man",
      title_in_original: "মহাপুরুষ",
      languages: ["Hindi"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "65 mins",
      region: "India",
    },
    {
      id: 17,
      image: grid17,
      year: 1965,
      main_title: "Shakespeare Wallah",
      alternative_title: "",
      title_in_original: "",
      languages: ["Hindi"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "120 mins",
      region: "India",
    },
    {
      id: 18,
      image: grid18,
      main_title: "Nayak",
      year: 1966,
      alternative_title: "The Hero",
      title_in_original: "নায়ক",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "120 mins",
      region: "India",
    },
    {
      id: 19,
      image: grid17,
      main_title: "Chidiakhana",
      year: 1967,
      alternative_title: "The Zoo",
      title_in_original: "চিড়িয়াখানা",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "125 mins",
      region: "India",
    },
    {
      id: 20,
      image: slideimg3,
      main_title: "Goopy Gyne Bagha Byne",
      year: 1969,
      alternative_title: "The Adventures of Goopy and Bagha",
      title_in_original: "গুপী গাইন বাঘা বাইন",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "132 mins",
      region: "India",
    },
    {
      id: 21,
      image: slideimg4,
      main_title: "Aranyer Din Ratri",
      year: 1970,
      alternative_title: "Days and Nights in the Forest",
      title_in_original: "অরণ্যের দিনরাত্রি",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "black & white ",
      duration: "115 mins",
      region: "India",
    },
    {
      id: 22,
      image: slideimg5,
      main_title: "Pratidwandi",
      year: 1970,
      alternative_title: "The Adversary",
      title_in_original: "প্রতিদ্বন্দ্বী",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "110 mins",
      region: "India",
    },
    {
      id: 23,
      image: slideimg6,
      main_title: "Baksa Badal",
      year: 1970,
      alternative_title: "",
      title_in_original: "",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "106 mins",
      region: "India",
    },
    {
      id: 24,
      image: slideimg7,
      main_title: "Seema Baddha",
      year: 1971,
      alternative_title: "Company Limited ",
      title_in_original: "সীমাবদ্ধ",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "112 mins",
      region: "India",
    },
    {
      id: 25,
      image: slideimg8,
      main_title: "Sikkim",
      year: 1971,
      alternative_title: "",
      title_in_original: "",
      languages: ["English"],
      film_length: "Short",
      film_type: "Documentary",
      colour: "Colour",
      duration: "55 mins",
      region: "India",
    },
    {
      id: 26,
      image: slideimg1,
      main_title: "The Inner Eye",
      year: 1972,
      alternative_title: "",
      title_in_original: "",
      languages: ["English"],
      film_length: "Short",
      film_type: "Documentary",
      colour: "Black & White",
      duration: "20 mins",
      region: "India",
    },
    {
      id: 27,
      image: slideimg2,
      main_title: "Ashani Sanket",
      year: 1973,
      alternative_title: "Distant Thunder",
      title_in_original: "অশনি সংকেত",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Colour",
      duration: "101 mins",
      region: "India",
    },
    {
      id: 28,
      image: slideimg3,
      main_title: "The Making of Asani Sanket",
      year: 1973,
      alternative_title: "",
      title_in_original: "",
      languages: ["English"],
      film_length: "",
      film_type: "Documentary",
      colour: "",
      duration: "",
      region: "India",
    },
    {
      id: 29,
      image: slideimg4,
      main_title: "Sonar Kella",
      year: 1973,
      alternative_title: "The Golden Fortress",
      title_in_original: "সোনার কেল্লা",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Colour",
      duration: "120 mins",
      region: "India",
    },
    {
      id: 30,
      image: slideimg5,
      main_title: "Creative Artists of India - Satyajit Ray",
      year: 1974,
      alternative_title: "",
      title_in_original: "",
      languages: ["English"],
      film_length: "Short",
      film_type: "Documentary",
      colour: "Black & White",
      duration: "13 mins",
      region: "India",
    },
    {
      id: 31,
      image: slideimg6,
      main_title: "Bala",
      year: 1976,
      alternative_title: "",
      title_in_original: "",
      languages: ["English"],
      film_length: "Short",
      film_type: "Documentary",
      colour: "Black & White",
      duration: "33 mins",
      region: "India",
    },
    {
      id: 32,
      image: slideimg7,
      main_title: "Jana Aranya",
      year: 1976,
      alternative_title: "The Middleman",
      title_in_original: "জন অরণ্য",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "131 mins",
      region: "India",
    },
    {
      id: 33,
      image: slideimg8,
      main_title: "Shatranj Ke Khilari",
      link: "/shatranj-ke-khilari-1977/",
      year: 1978,
      alternative_title: "The Chess Players",
      title_in_original: "शतरंज के खिलाड़ी",
      languages: ["Urdu", "Hindi"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Colour",
      duration: "113 mins",
      region: "India",
      drawer_data:{
        name: " Satyajit Ray & Shatranj ke Khilari",
        links: [
          { link_name: "Magazine Articles", link: "" },
          {
            link_name: "Song Synopsis Booklet",
            link: "/shatranj-ke-khilari-1977/body-of-work/1/shatranj-ke-khilari",
          },
          {
            link_name: "Working Stills",
            link: "/shatranj-ke-khilari-1977/body-of-work/0/shatranj-ke-khilari",
          },
        ],
        Image: "Img",
        desc: "Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working Still | CINE.wks",
        masterlist: {
          text: "Visit Shatranj ke Khilari Masterlist →",
          link: "/shatranj-ke-khilari-1977/0/shatranj-ke-khilari",
        },
      }
    },
    {
      id: 34,
      image: slideimg1,
      main_title: "Joi Baba Felunath",
      year: 1979,
      alternative_title: "Joi Baba Felunath: The Elephant God ",
      title_in_original: "জয় বাবা ফেলুনাথ",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Colour",
      duration: "112 mins",
      region: "India",
    },
    {
      id: 35,
      image: slideimg2,
      main_title: "Hirak Rajar Deshe",
      year: 1980,
      alternative_title: "The Kingdom of Diamonds",
      title_in_original: "হীরক রাজার দেশে",
      languages: ["Bengali"],
      film_length: "Feature",
      film_type: "Fiction",
      colour: "Black & White",
      duration: "118 mins",
      region: "India",
    },
  ]
 export const key_value_satyajit_filmography_data= [
    [
        {
            "key": "id",
            "value": "4be16dac-2384-4540-bf85-ee27d2ae7007",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.dailymotion.com/video/x6j4i9n",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Song of the Little Road",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "pather-panchali-1955",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "pather-panchali-1955-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "পথের পাঁচালী",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "150000",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1955",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "119 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Pather Panchali",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "government-of-west-bengal",
                    "name": "Government of west bengal"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0060-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid1,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "0a76c740-0a4d-49a1-a1df-e58201e2e55e",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=75gq75mAfhc",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Unvanquished",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "aparajito-1956",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "aparajito-1956-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "অপরাজিত",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1956",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "113 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Aparajito",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "epic-productions",
                    "name": "Epic productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0049-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid2,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "eabc7dca-512b-4e27-86ba-1d34e24db039",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://erosnow.com/movie/watch/1057803/jalsaghar/6778459/jalsaghar",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Music Room",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "jalsaghar-1958",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "jalsaghar-1958-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "জলসাঘর",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1958",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "100 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Jalsaghar",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "arora",
                    "name": "Arora"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0054-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid3,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "3a962ef0-29bd-4b98-abc6-c336459eb8f7",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=ouBS-y-RM8o",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Philosopher's Stone",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "parash-pathar-1957",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "parash-pathar-1958-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "পরশ পাথর",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1958",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "111 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Parash Pathar",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "lbfilms-international",
                    "name": "Lbfilms international"
                },
                {
                    "id": null,
                    "alias": "arora",
                    "name": "Arora"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "35d8d0e5-0e5c-45c6-a1fe-066177b22a6d",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=lzW2eAwlKy0",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Goddess",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "devi-1960",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "devi-1960-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "দেবী",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "A young woman's father-in-law begins to believe that she is a reincarnation of the goddess Kali, to whom he is strongly devoted. As she falls under the adoring eyes of other villagers, she begins to lose herself to the illusion. [Rotten Tomatoes]",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1960",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "99 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Devi",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "satyajit-ray-productions",
                    "name": "Satyajit ray productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "cc10069c-8c7c-4a78-8a66-9c45f2f52c79",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=pBECVjOrGkM",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "rabindranath-tagore-1961",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "rabindranath-tagore-1961-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Documentary",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1961",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "54 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Short Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Rabindranath Tagore",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "English",
                    "alias": "English",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "films-division",
                    "name": "Films division"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "b853b571-c6eb-47cc-93c1-2798683ac679",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=T2LbejQkHBk",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Three Daughters",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "teen-kanya-1961",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "teen-kanya-1961-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "তিন কন্যা",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1961",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "56 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Teen Kanya",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "satyajit-ray-productions",
                    "name": "Satyajit ray productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "0f315fd2-325b-4dba-a8c1-a2c553e17967",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=P0xuYF58jS0",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Expedition",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "abhijan-1962",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "abhijan-1962-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "অভিযান",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1962",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "150 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Abhijan",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "bn-roy-productions",
                    "name": "Bn roy productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0048-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid4,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "040391a6-6ce1-4293-9a6e-32198bc8f9c2",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Color",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=S28Mlhj9bTs",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Kanchonjônggha",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "kanchanjungha-1962",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "kanchanjungha-1962-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "কাঞ্চনজঙ্ঘা",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1962",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "120 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Kanchanjungha",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "nca-productions",
                    "name": "Nca productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "758e8f4c-a9fb-416c-9cb9-fb1c8c94f0a1",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=OjhjDLeQNH8",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Big City",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "mahanagar-1963",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "mahanagar-1963-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "মহানগর",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1963",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "131 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Mahanagar",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "r-d-bansal-productions",
                    "name": "R d bansal productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0056-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid5,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "3ae2fc33-757f-4220-842c-a42d25f9988e",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "the-householder-1963",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "the-householder-1963-hindi-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1963",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "101 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "The Householder",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "merchant-ivory-production",
                    "name": "Merchant ivory production"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "41698c2a-0f50-49d6-8cfb-58a5cb7f005d",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=RmKu7VE4IBA",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Lonely Wife",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "charulata-1964",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "charulata-1964-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "চারুলতা",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "The film revolves around Charulata / Charu (Madhabi Mukherjee), the childless, intelligent and beautiful wife of Bhupati (Sailen Mukherjee). Charu falls in love with Amal (Soumitra Chatterjee), Bhupati's younger brother.",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1964",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "117 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Charulata",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "r-d-bansal-productions",
                    "name": "R d bansal productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0050-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid6,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "8861841c-9829-49b5-bef8-5de8dd19c057",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=zACGLjd9JNY",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "two-1964-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1964",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "12 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Short Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Two",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "esso-world-theater",
                    "name": "Esso world theater"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "b856af82-b4e5-47fd-965d-a565540d7db2",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=otLkTgnKTOo",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Coward",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "ka-purush-maha-purush-1965",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "ka-purush-1965-hindi-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "কাপুরুষ",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1965",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "74 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Ka Purush",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "rajshri-production",
                    "name": "Rajshri production"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "48f79f98-4049-4277-abbb-863c865ba1fd",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=By9vfKaHjz4",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Holy Man",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "ka-purush-maha-purush-1965",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "maha-purush-1965-hindi-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "মহাপুরুষ",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1965",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "65 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Maha Purush",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "rajshri-production",
                    "name": "Rajshri production"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0057-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid7,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "d7857d69-09da-466f-93fa-6743d9b2e1a9",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "shakespeare-wallah-1965",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "shakespeare-wallah-1965-hindi-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1965",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "120 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Shakespeare Wallah",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "merchant-ivory-production",
                    "name": "Merchant ivory production"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "f7c6d2c5-2bb5-4566-bfdb-922df1888ccc",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=MQNKDa9IdTw",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Hero",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "nayak-1966",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "nayak-1966-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "নায়ক",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1966",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "120 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Nayak",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "rdb-and-company",
                    "name": "Rdb and company"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0058-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid8,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "75a1ffca-86a6-4c6e-840a-9b82604e52da",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://erosnow.com/movie/watch/1057526/chiriakhana/6775213/chiriakhana",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Zoo",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "chidiakhana-1967",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "chidiakhana-1967-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "চিড়িয়াখানা",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1967",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "125 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Chidiakhana",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "star-productions",
                    "name": "Star productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0051-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid9,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "d056a5d5-c87a-47ac-870e-05e4a2175e7f",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=kTgoDm1_BUI",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Adventures of Goopy and Bagha",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "goopy-gyne-bagha-byne-1968",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "goopy-gyne-bagha-byne-1969-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "গুপী গাইন বাঘা বাইন",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1969",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "132 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Goopy Gyne Bagha Byne",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "purnima-pictures",
                    "name": "Purnima pictures"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "4d516968-3653-4886-8fd5-4bb55294897e",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=Uu--1QfYKHc",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Days and Nights in the Forest",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "aranyer-din-ratri-1969",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "aranyer-din-ratri-1970-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "অরণ্যের দিনরাত্রি",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1970",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "115 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Aranyer Din Ratri",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "priya-films",
                    "name": "Priya films"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "5054f72b-a590-4946-9b0b-70f9c921c3ed",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=1lVt0sKFhDY",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Adversary",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "pratidwandi-1970",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "pratidwandi-1970-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "প্রতিদ্বন্দ্বী",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1970",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "110 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Pratidwandi",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "priya-films",
                    "name": "Priya films"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "5a8074eb-dd25-482b-9422-fe44a640fe7d",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "baksa-badal-1970-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1970",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "106 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Baksa Badal",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "d-m-productions",
                    "name": "D m productions"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "22b5729f-c4aa-479f-ab33-575fa834c503",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=HORb6BwyHkY",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Company Limited",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "seema-baddha-1971",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "seema-baddha-1971-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "সীমাবদ্ধ",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1971",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "112 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Seema Baddha",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0062-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid10,
                    "medium": null
                },
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0061-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid11,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c875c69a-e69d-4e5a-afd7-3942027cf307",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Color",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.dailymotion.com/video/xluujl",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "sikkim-1971",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "sikkim-1971-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Documentary",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1971",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "55 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Short Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Sikkim",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "English",
                    "alias": "English",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "government-of-sikkim",
                    "name": "Government of sikkim"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "611c73de-1daa-443d-a72e-331ffbd3f52a",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=566pTj4Xr6Y",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "inner-eye-the-1972",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "the-inner-eye-1972-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Documentary",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1972",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "20 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Short Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "The Inner Eye",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "English",
                    "alias": "English",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "films-division",
                    "name": "Films division"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "dbc5d13f-8dd8-48fe-92d8-fba1d8c35976",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Color",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://erosnow.com/movie/watch/1060484/ashani-sanket/6827710/WAIT",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Distant Thunder",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "ashani-sanket-1973",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "ashani-sanket-1973-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "অশনি সংকেত",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1973",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "101 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Ashani Sanket",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "balaka-movies",
                    "name": "Balaka movies"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "4e033155-f386-40c1-9882-7f7c266f164c",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "the-making-of-asani-sanket-1973-english-",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Documentary",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1973",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "The Making of Asani Sanket",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "English",
                    "alias": "English",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "153de2ac-1e77-4fec-87ec-b9121b94c9af",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Color",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://erosnow.com/movie/watch/1059605/sonar-kella/6811612/sonar-kella",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Golden Fortress",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "sonar-kella-1974",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "sonar-kella-1974-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "সোনার কেল্লা",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1974",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "120 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Sonar Kella",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "government-of-west-bengal",
                    "name": "Government of west bengal"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0066-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid12,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "1a0479c2-5fe7-4af2-97ee-e7ae56612653",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "creative-artists-of-india-satyajit-ray-1974-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Documentary",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1974",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "13 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Short Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Creative Artists of India - Satyajit Ray",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "English",
                    "alias": "English",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=ak_a1RJ2DZc",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "bala",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "bala-1976-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Documentary",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1976",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "33 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Short Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Bala",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "English",
                    "alias": "English",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "6cdbb9c4-9773-421c-9ac4-c9947460f525",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=QEMA1upF0rw",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Middleman",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "jana-aranya-1975",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "jana-aranya-1976-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "জন অরণ্য",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1976",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "131 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Jana Aranya",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "indus-films",
                    "name": "Indus films"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "6f98da89-db5e-4f2e-bc84-c6ec5919dfe8",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "https://www.youtube.com/watch?v=nrU4irG-_QY",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Color",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "December 1976 - June 1977",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.hotstar.com/in/movies/shatranj-ke-khilari/1000156621/watch",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "Eastman Color",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "Gemini Color Lab, Madras",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "3434.18 m",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "84526",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "14",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Chess Players",
            "display": "Alternative title"
        },
        {
            "key": "drawer_link",
            "value": true,
            "display": ""
        },
        {
            "key": "date_of_issue",
            "value": "1977-03-10",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "shatranj-ke-khilari-1977",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1978-urdu-hindi-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "शतरंज के खिलाड़ी",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "35 mm",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "Wajid Ali Shah is the ruler of one of the last independent kingdoms of India. The British, intent on controlling India, send General Outram on a secret mission to clear the way for an annexation. While pressure is mounting amidst intrigue and political manoeuvres, Wajid Ali Shah, secluded in his palace, composes poems and listens to music. The court is of no help, as exemplified by two slightly eccentric noblemen, Mir and Mirza who, ignoring the situation in the country and their duties towards their families, spend their days playing endless rounds of chess. The imminent threat of the British takeover of their region becomes a harsh reality. Even when they are forced to leave their comfortable homes, they continue to play chess in the mosquito ridden outdoors, with two loaded pistols, ready to oppose the British, or even themselves, if need be.",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "U",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1978",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "113 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "1987-02-10",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Shatranj Ke Khilari",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Urdu",
                    "alias": "Urdu",
                    "country": null
                },
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "devki-chitra",
                    "name": "Devki chitra"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [
                {
                    "id": null,
                    "name": "Jaipur",
                    "alias": "Jaipur"
                },
                {
                    "id": null,
                    "name": "Lucknow",
                    "alias": "Lucknow"
                }
            ],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [
                {
                    "id": null,
                    "name": "Calcutta",
                    "alias": "Calcutta"
                },
                {
                    "id": null,
                    "name": "Indrapuri",
                    "alias": "Indrapuri"
                }
            ],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0065-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid13,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "cec3930b-9b63-4f51-b463-ad91ed35fe2e",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Color",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.youtube.com/watch?v=3Hzuv9wVZb8",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Joi Baba Felunath: The Elephant God",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "joi-baba-felunath-1978",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "joi-baba-felunath-1979-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "জয় বাবা ফেলুনাথ",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1979",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "112 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Joi Baba Felunath",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "rdb-and-company",
                    "name": "Rdb and company"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0067-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid14,
                    "medium": null
                },
                {
                    "wnn": null,
                    "accession_number": null,
                    "alias": "0055-satyajit-ray-filmography.jpg",
                    "name_of_publication": null,
                    "volume_number": null,
                    "publication_year": null,
                    "sortable_year_of_publication": null,
                    "artist_name": null,
                    "date_of_artwork": null,
                    "medium_of_artwork": null,
                    "revised_medium": null,
                    "id": null,
                    "image": null,
                    "height_cm": null,
                    "width_cm": null,
                    "title": null,
                    "direct_url_of_preview_image": grid16,
                    "medium": null
                }
            ],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "593dbffb-3962-4f5c-9a2c-1a9e8e01c4d6",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "https://www.dailymotion.com/video/x6g1scl",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "The Kingdom of Diamonds",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "hirak-rajar-deshe-1980",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "hirak-rajar-deshe-1980-bengali-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "হীরক রাজার দেশে",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1980",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "118 mins",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Hirak Rajar Deshe",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Bengali",
                    "alias": "Bengali",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "government-of-west-bengal",
                    "name": "Government of west bengal"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
]
export const satyajit_filter_options = {

    categoriesFilter : [
      "Polymath",
      "Actor",
      "Art Director",
      "Cinematographer",
      "Costume Designer",
      "Dialogue",
      "Director",
      "Editor",
      "Film Publicity Material Designer",
      "Lyricist",
      "Music Director",
      "Playback Singer",
      "Producer",
      "Script Writer/ Screenplay / StoryWriter",
    ],
    languageFilter : ["Bengali", "English", "Hindi", "Urdu"],

     filmTypeFilter :["Fiction", "Documentary"],
     filmLengthFilter :["Feature Film", "Short Film"],
  };

  export const key_value_raj_kapoor_filmography_data= [
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "After the Earthquake",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "inquilab-1935-feature-film",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "inquilab-1935-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1935",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Inquilab",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "",
                    "name": ""
                },
                {
                    "id": null,
                    "alias": "",
                    "name": ""
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "gauri-1943-feature-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1943",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Gauri",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "",
                    "name": ""
                },
                {
                    "id": null,
                    "alias": "",
                    "name": ""
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "bala-1976-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1943",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Hamari Baat",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "bala-1976-english-short-film",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1946",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "4/13/1946",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Valmiki",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1947",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Neel Kamal",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1947",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Chittor Vijay",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | 130 mins | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1947",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Dil Ki Rani",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1947",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Jail Yatra",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Radha Krishna | Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1948",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Amar Prem",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | 155 mins | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1948",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Gopinath",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | 148 mins | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1949",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Andaz",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | 138 mins | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1948",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Aag",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1949",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Parivartan",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | 143 mins | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1949",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Sunhere Din",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | 171 mins | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1949",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Barsaat",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
    [
        {
            "key": "id",
            "value": "c1a09b1e-0bf9-49df-af7c-c61278fa0dad",
            "display": "Id"
        },
        {
            "key": "trailer_link",
            "value": "",
            "display": "Trailer link"
        },
        {
            "key": "color",
            "value": "Black & White",
            "display": "Color"
        },
        {
            "key": "shooting_period",
            "value": "",
            "display": "Shooting period"
        },
        {
            "key": "full_movie_link",
            "value": "",
            "display": "Full movie link"
        },
        {
            "key": "color_type",
            "value": "",
            "display": "Color type"
        },
        {
            "key": "lab",
            "value": "",
            "display": "Lab"
        },
        {
            "key": "length_of_reels",
            "value": "",
            "display": "Length of reels"
        },
        {
            "key": "certificate_number",
            "value": "",
            "display": "Certificate number"
        },
        {
            "key": "country_of_origin_of_film",
            "value": "India",
            "display": "Country of origin"
        },
        {
            "key": "number_of_reels",
            "value": "",
            "display": "Number of reels"
        },
        {
            "key": "alternative_title",
            "value": "Hindi | Feature Film | Fiction | Black & White | India",
            "display": "Alternative title"
        },
        {
            "key": "date_of_issue",
            "value": "",
            "display": "Date of issue"
        },
        {
            "key": "film_alias",
            "value": "Fiction",
            "display": "Film alias"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "title_in_other_languages",
            "value": "",
            "display": "Title in other languages"
        },
        {
            "key": "title_in_original_language",
            "value": "",
            "display": "Title in original language"
        },
        {
            "key": "gauge",
            "value": "",
            "display": "Gauge"
        },
        {
            "key": "sub_title",
            "value": "Hindi",
            "display": "Sub title"
        },
        {
            "key": "film_type",
            "value": "Fiction",
            "display": "Film type"
        },
        {
            "key": "budget_in_rupees",
            "value": "",
            "display": "Budget in rupees"
        },
        {
            "key": "synopsis",
            "value": "",
            "display": "Synopsis"
        },
        {
            "key": "certification",
            "value": "",
            "display": "Certification"
        },
        {
            "key": "year_of_film",
            "value": "1950",
            "display": "Year of film"
        },
        {
            "key": "duration_mins",
            "value": "",
            "display": "Duration"
        },
        {
            "key": "date_of_expiry",
            "value": "",
            "display": "Date of expiry"
        },
        {
            "key": "release_date",
            "value": "",
            "display": "Release date"
        },
        {
            "key": "film_sub_type",
            "value": "",
            "display": "Film sub type"
        },
        {
            "key": "film_length",
            "value": "Feature Film",
            "display": "Film length"
        },
        {
            "key": "region",
            "value": "India",
            "display": "Region"
        },
        {
            "key": "main_title",
            "value": "Banwra",
            "display": "Main title"
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor",
            "display": "masterlist"
        },
        {
            "key": "language",
            "value": [
                {
                    "id": null,
                    "name": "Hindi",
                    "alias": "Hindi",
                    "country": null
                }
            ],
            "display": "Language"
        },
        {
            "key": "banner",
            "value": [
                {
                    "id": null,
                    "alias": "national-center-for-performing-arts",
                    "name": "National center for performing arts"
                },
                {
                    "id": null,
                    "alias": "government-of-tamil-nadu",
                    "name": "Government of tamil nadu"
                }
            ],
            "display": "Banner"
        },
        {
            "key": "filmProducer",
            "value": [],
            "display": "Film Producer"
        },
        {
            "key": "filmDirector",
            "value": [],
            "display": "Film Director"
        },
        {
            "key": "scriptWriter",
            "value": [],
            "display": "Script"
        },
        {
            "key": "screenPlay",
            "value": [],
            "display": "Screenplay"
        },
        {
            "key": "storyWriter",
            "value": [],
            "display": "Story"
        },
        {
            "key": "dialogueWriter",
            "value": [],
            "display": "Dialogue"
        },
        {
            "key": "musicDirector",
            "value": [],
            "display": "Music Director"
        },
        {
            "key": "playbackSinger",
            "value": [],
            "display": "Playback Singer"
        },
        {
            "key": "lyricist",
            "value": [],
            "display": "Lyricist"
        },
        {
            "key": "editor",
            "value": [],
            "display": "Editor"
        },
        {
            "key": "cinematographer",
            "value": [],
            "display": "Cinematographer"
        },
        {
            "key": "artDirector",
            "value": [],
            "display": "Art Director"
        },
        {
            "key": "stillPhotographer",
            "value": [],
            "display": "Still Photographer"
        },
        {
            "key": "posterDesigner",
            "value": [],
            "display": "Poster Designer"
        },
        {
            "key": "materialDesigner",
            "value": [],
            "display": "Material Designer"
        },
        {
            "key": "narrator",
            "value": [],
            "display": "Narrator"
        },
        {
            "key": "artist",
            "value": [],
            "display": "Artist"
        },
        {
            "key": "poet",
            "value": [],
            "display": "Poet"
        },
        {
            "key": "commentryWriter",
            "value": [],
            "display": "Commentry Writer"
        },
        {
            "key": "translator",
            "value": [],
            "display": "Translator"
        },
        {
            "key": "makeUpArtist",
            "value": [],
            "display": "Make Up Artist"
        },
        {
            "key": "assistantMakeUpArtist",
            "value": [],
            "display": "Assistant Make Up Artist"
        },
        {
            "key": "costumeDesigner",
            "value": [],
            "display": "Costume Designer"
        },
        {
            "key": "choreographer",
            "value": [],
            "display": "Choreographer"
        },
        {
            "key": "fightDirector",
            "value": [],
            "display": "Fight Director"
        },
        {
            "key": "assistantMusician",
            "value": [],
            "display": "Assistant Musician"
        },
        {
            "key": "soundRecorder",
            "value": [],
            "display": "Sound Recorder"
        },
        {
            "key": "soundDesigner",
            "value": [],
            "display": "Sound Designer"
        },
        {
            "key": "soundMixer",
            "value": [],
            "display": "Sound Mixer"
        },
        {
            "key": "soundEditor",
            "value": [],
            "display": "Sound Editor"
        },
        {
            "key": "associateEditor",
            "value": [],
            "display": "Associate Editor"
        },
        {
            "key": "assistantEditor",
            "value": [],
            "display": "Assistant Editor"
        },
        {
            "key": "associateCameraman",
            "value": [],
            "display": "Associate Cameraman"
        },
        {
            "key": "assistantCameraman",
            "value": [],
            "display": "Assistant Cameraman"
        },
        {
            "key": "coProducer",
            "value": [],
            "display": "Co Producer"
        },
        {
            "key": "productionExecuter",
            "value": [],
            "display": "Production Executer"
        },
        {
            "key": "productionSecretary",
            "value": [],
            "display": "Production Secretary"
        },
        {
            "key": "associateProducer",
            "value": [],
            "display": "Associate Producer"
        },
        {
            "key": "productionDesigner",
            "value": [],
            "display": "Production Designer"
        },
        {
            "key": "supervisingProducer",
            "value": [],
            "display": "Supervising Producer"
        },
        {
            "key": "lineProducer",
            "value": [],
            "display": "Line Producer"
        },
        {
            "key": "productionInCharge",
            "value": [],
            "display": "Production In Charge"
        },
        {
            "key": "consultingProducer",
            "value": [],
            "display": "Consulting Producer"
        },
        {
            "key": "executiveProducer",
            "value": [],
            "display": "Executive Producer"
        },
        {
            "key": "associateDirector",
            "value": [],
            "display": "Associate Director"
        },
        {
            "key": "assistantDirector",
            "value": [],
            "display": "Assistant Director"
        },
        {
            "key": "executiveDirector",
            "value": [],
            "display": "Executive Director"
        },
        {
            "key": "location",
            "value": [],
            "display": "Location"
        },
        {
            "key": "studios",
            "value": [],
            "display": "Studios"
        },
        {
            "key": "posterImages",
            "value": [],
            "display": "Poster images"
        }
    ],
]

export const raj_kapoor_filter_options = {

    categoriesFilter : [
      "Polymath",
      "Actor",
      "Art Director",
      "Cinematographer",
      "Costume Designer",
      "Dialogue",
      "Director",
      "Editor",
      "Film Publicity Material Designer",
      "Lyricist",
      "Music Director",
      "Playback Singer",
      "Producer",
      "Script Writer/ Screenplay / StoryWriter",
    ],
    languageFilter : ["Bengali", "English", "Hindi", "Urdu"],

     filmTypeFilter :["Fiction", "Documentary"],
     filmLengthFilter :["Feature Film", "Short Film"],
  };