
import { mughal_e_azam_editorial_data, skk_editorial_data, skk_editorial_full_image_data, mughal_e_azam_editorial_full_image_data } from "../MockData/EditorialMockup";

import axios from "axios";
import { transformJSON } from "../util/transformer";
import { filmEditorialSchema} from "./schemas/Editorial";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const filmEditorialByMasterlistAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: EditorialWhere){` +
      tableName +
      ` (where: $where){
            sl_no
            masterlist
            alias
            artwork {
              accession_number
              alias
              artist_name
              any_other_sales_in_auction
              artwork_sr_no_as_per_essay_publication
              any_gallery_price_list_available_for_artwork
              any_collaborators_for_exhibition
              auction_code
              auction_date_year
              additional_information
              artwork_in_a_graphy_section_sub_section
              content_id
              conversion_rate
              collection_of_image_links
              current_collection
              dates_for_exhibition
              date_of_artwork
              depth_in_cm
              depth_in_inches
              direct_url_of_preview_image
              featured_artist_or_facial_recognition_names
              height_in_cm
              height_in_inches
              height_cm
              id
              image
              image_code_from_the_auction_econ_database
              image_file_name_code
              location
              masterlist
              medium_of_artwork
              name_of_institution
              name_of_exhibition
              name_of_publication
              notes6
              notes5
              notes4
              notes3
              notes2
              notes1
              old_image_name
              object_of_focus
              publication_year
              revised_medium
              section
              sp_home_currency
              sortable_year_of_publication
              title
              title_of_artwork_listed_in_essay
              type_of_exhibition
              venues
              volume_number
              width_in_inches
              width_in_cm
              width_cm
              wnn
              sp_usd
              medium
              artist {
                alias
                name
                first_name
                last_name
                full_name
              }
              featuredArtist {
                alias
                name
                first_name
                last_name
                full_name
              }
            }
          }
        }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      }
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.editorials) {
    let editorialData = result?.data?.data?.editorials;
    const sortedEditorialData = editorialData.sort((a, b) => a.sl_no - b.sl_no);
    // return result?.data?.data?.editorials;
    return sortedEditorialData;
  } else {
    return [];
  }
};

export const filmEditorialByMasterlist = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
   const response = await filmEditorialByMasterlistDummyData(tableName, masterlist);
   return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmEditorialByMasterlistAPI(tableName, masterlist);
    try{
      if (response) {
        let transformedData = transformJSON(filmEditorialSchema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const filmEditorialByMasterlistDummyData = async (tableName, masterlist) => {
  const filmEditorialDataMap = {
    "shatranj-ke-khilari-1977": skk_editorial_data,
    "mughal-e-azam": mughal_e_azam_editorial_data,
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmEditorialData = filmEditorialDataMap[masterlist] || skk_editorial_data;
  return FilmEditorialData;
};

export const getEditorialDataAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: EditorialWhere){` +
      tableName +
      ` (where: $where){
            sl_no
            alias
            artwork {
              accession_number
              alias
              artist_name
              additional_information
              artwork_in_a_graphy_section_sub_section
              content_id             
              collection_of_image_links
              current_collection
              direct_url_of_preview_image
              id
              image_file_name_code
              masterlist
              medium_of_artwork
              name_of_institution
              name_of_exhibition
              name_of_publication
              publication_year
              revised_medium
              section
              sortable_year_of_publication
              title
              title_of_artwork_listed_in_essay
              wnn
              artist {
                alias
                name
                first_name
                last_name
                full_name
              }
              featuredArtist {
                alias
                name
                first_name
                last_name
                full_name
              }
            }
          }
        }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      }
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.editorials) {
    let editorialData = result?.data?.data?.editorials;
    const sortedEditorialData = editorialData.sort((a, b) => a.sl_no - b.sl_no);
    // return result?.data?.data?.editorials;
    return sortedEditorialData;
  } else {
    return [];
  }
};
// This function return Editorial Full image data
export const getEditorialData = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getEditorialDataDummyData(tableName, masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getEditorialDataAPI(tableName, masterlist);
    const finalresponse = getFormattedDta(response);
    return finalresponse;
  }
};

export const getEditorialDataDummyData = async (tableName, masterlist) => {
  const filmEditorialDataMap = {
    "shatranj-ke-khilari-1977": skk_editorial_full_image_data,
    "mughal-e-azam": mughal_e_azam_editorial_full_image_data,
  };

  const FilmEditorialFullImageData = filmEditorialDataMap[masterlist] || skk_editorial_full_image_data;
  return FilmEditorialFullImageData;
};

const getFormattedDta = (response) => {
  if (response.length === 0){
    return [];
  }

  const convertedData = response.map(item => {
    const artwork = item.artwork[0];
    const desc = `${item.sl_no}_${artwork.artist_name}, ${artwork.name_of_publication} | ${artwork.sortable_year_of_publication} | ${artwork.medium_of_artwork} | ${artwork.revised_medium}`;
    return {
      id: item.sl_no,
      image: artwork.direct_url_of_preview_image,
      fullImage: artwork.direct_url_of_preview_image, // Full image URL needs to be filled
      side_image: artwork.direct_url_of_preview_image, // Side image URL needs to be filled
      desc: desc,
      author: artwork.artist_name,
      title: `${artwork.medium_of_artwork}, ${artwork.sortable_year_of_publication}_`,
      document_type: artwork.revised_medium,
      imgNum: artwork.wnn,
      photographer: artwork.artist_name,
      featured_actors: artwork.featuredArtist.map(artist => artist.full_name).join(", "),
      date_of_artwork: artwork.sortable_year_of_publication,
      content: "Content Awaited",
      description1: "",
    };
  })

  return convertedData;  
}

