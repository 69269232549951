import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service file
import * as getIntroductionData from "../../../../services/Introduction";
import * as getLandingMasterlistData from "../../../../services/SectionService";

//Component file
import MobileNav from "../../../../components/Topheader";
import { LyricsSoundtrack } from "./SongsSynopsisDetails/Soundtrack";
import CommonLeftSidebar from "../../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../../../CommonComponents/BottomMainMenu";
import SongsBook from "./SongsBook";
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";

//Language file
import { explore, filmography } from "../../../../constants/constants";
import HorizontalLayout from "./SongsSynopsisDetails/CrewAndCast/HorizontalLayout";
import VerticalLayout from "./SongsSynopsisDetails/CrewAndCast/VerticalLayout";
import Synopsis from "./SongsSynopsisDetails/Synopsis";

export default function SongSynopsisDetails({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [castTabFlag, setCastTabFlag] = useState(false);

  const {alias } = useParams();
  const aliasAPI = alias;
  const masterlistType = "object-book-ssb"
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "song_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "song_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [aliasAPI]);

  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - -64px)"
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        introData={introData}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
       {introData? <SongSynopsis
          winnerName={aliasAPI}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
          masterlistType={masterlistType}
          castTabFlag={castTabFlag}
          setCastTabFlag={setCastTabFlag}
        />
      :
      (
       <WebpageComingSoon />
      )
      }
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  winnerName,
  onClose,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {

      navigate(-1);
    }
    const oSections = [
      {
        to: `/${masterlistType}/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        leftFlag={true}
        handleNavigation={handleNavigation}
      />
    </>
  );
};

function SongSynopsis({winnerName,masterlistType,castTabFlag,setCastTabFlag,}) {
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const songName = parts[4];
  let { tabIndex } = useParams();
  const [creditsView, setCreditsView] = useState(false);
  const [fullCreditsView, setFullCreditsView] = useState(false);

  const tableName = "films";
  const handleCreditsView = (flag) => {
    setCreditsView(flag);
    setCastTabFlag(flag);
  };
  return (
      <Stack width={"100%"} height={"100%"}>
        <Container maxW={"auto"} px={"0px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt="9"
            variant="unstyled"
            defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
          >
            <HStack
              position={"fixed"}
              background={"white"}
              zIndex={9}
              top={"64px"}
              width={{ base: "100%", md: "1193px" }}
              padding={{ base: "0px", md: "16px 44px" }}
            >
                <TabList
                    overflowX="auto"
                    borderBottom={"1px"}
                    borderBottomColor={"#f1f1f1"}
                    gap={12}
                    className="verticalscroll"
                  >
                <Tab
                      className="Timeline"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}

                    >
                     {explore.BOOKLET}
                    </Tab>
                    <Tab
                      className="Timeline"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}

                    >
                      {explore.SYNOPSIS}
                    </Tab>
                    <Tab
                      className="Timeline"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(true)}

                    >
                      {explore.CREDITS_CAST}
                    </Tab>
                    <Tab
                      className="Timeline"
                      p="0"
                      color="#333333"
                      fontSize={"md"}
                      fontWeight={"400"}
                      _selected={{
                        borderBottomWidth: "6px",
                        color: "black",
                        borderBottomColor: "#333333",
                        fontWeight: "700",
                      }}
                      onClick={() => handleCreditsView(false)}

                    >
                      {explore.LYRICS_AND_SOUNDTRACK}
                    </Tab>
              </TabList>
              {creditsView && (
                  <Stack flexDirection={"row"} gap={0} id="listgridviewobjssb">
                    <HStack
                      marginTop={"0px!important"}
                      border={"1px"}
                      borderColor={"#C1C1C1"}
                      borderRadius={"6px"}
                      px={2}
                      flexDirection={"row"}
                      display={"flex"}
                    >
                      <Text
                        fontSize={"14px"}
                        fontWeight={!fullCreditsView ? "700" : "400"}
                        lineHeight={"16px"}
                        py={2}
                        color={"black"}
                        onClick={() => {
                          setFullCreditsView(false);
                          setCastTabFlag(true);
                        }}
                        cursor={"pointer"}
                      >
                        {filmography.CAST_VIEW}
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"400"}
                        lineHeight={"16px"}
                        py={2}
                        marginRight="8px"
                        color={"black"}
                      >
                        |
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={fullCreditsView ? "700" : "400"}
                        lineHeight={"16px"}
                        py={2}
                        marginRight="8px"
                        color={"black"}
                        onClick={() => {
                          setFullCreditsView(true);
                          setCastTabFlag(true);
                        }}
                        cursor={"pointer"}
                      >
                        {filmography.FULL_CREDITS_VIEW}
                      </Text>
                    </HStack>
                  </Stack>
                )}
            </HStack>
            
            <TabPanels paddingTop={{ base: "160px", md: "30px" }}>
              <TabPanel px={"0"} p="0px" className="introssbText" mx={"44px"}>
               <SongsBook songName={songName}/>
              </TabPanel>
              <TabPanel px={"0"} p="0px" className="introssbText" mx={"44px"}>

               <Synopsis masterlist={songName}/>
              </TabPanel>
              <TabPanel px={"0"} p="0px" mx={"44px"} className="introssbText">
              {fullCreditsView ? (
                      <VerticalLayout
                        winnerName={songName}
                        setCastTabFlag={setCastTabFlag}
                        tableName={tableName}
                        bg="white"
                      />
                    ) : (
                      <HorizontalLayout
                        winnerName={songName}
                        tableName={tableName}
                        masterlistType={masterlistType}
                      />
                    )}
              </TabPanel>
              <TabPanel px={"0"} p="0px" mx={"44px"}>
              <LyricsSoundtrack winnerName={songName} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
  );
}
