import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
  HStack,
  Image,
  Checkbox,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  TriangleDownIcon,
} from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Logo = `${IMAGE_BASE_PATH}/ui/Logo/logo.png`

export default function Navbar({ navLinks, onClickSubscribe }) {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box
      position="sticky"
      backgroundColor="white"
      w="100%"
      top="0px"
      zIndex={"9999"}
      boxShadow="md"
    >
      <Stack pr={"8"} pl={"8"} width={"100%"} className="mobileviewpaddingnav">
        <Container maxW="auto">
          <Flex
            bg={useColorModeValue("white", "gray.800")}
            color={useColorModeValue("gray.600", "white")}
            minH={"80px"}
            py={{ base: 2 }}
            px={{ base: 2 }}
            borderBottom={1}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.900")}
            align={"center"}
          >
            <Flex
              flex={{ base: 1, md: "auto" }}
              ml={{ base: -2 }}
              display={{ base: "flex", md: "none" }}
            >
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>
            <Flex
              flex={{ base: 1 }}
              justify={{ base: "center", md: "" }}
              justifyContent={"flex-start"}
            >
              <Text
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily={"heading"}
                color={useColorModeValue("gray.800", "white")}
              >
                <Text fontSize="2xl" fontWeight="900">
                  <Link to="/">
                    <Image src={Logo} width="100px" height={"100px"} />
                  </Link>
                </Text>
              </Text>

              <Flex display={{ base: "none", md: "flex" }} ml={10}>
                <DesktopNav {...{ navLinks }} />
              </Flex>
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              <HStack>
                {/* <InitialFocus /> */}
                <Button
                  onClick={() => {
                    if (onClickSubscribe) onClickSubscribe();
                  }}
                  ml="4"
                  fontSize={"sm"}
                  bg={"gray.900"}
                  fontWeight={400}
                  href={"#"}
                  color="white"
                  variant={"link"}
                  p="3"
                  style={{ textDecoration: "none" }}
                >
                  Subscribe
                </Button>
              </HStack>
            </Stack>
          </Flex>
        </Container>
      </Stack>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav {...{ navLinks, closeMenuButton: onToggle }} />
      </Collapse>
    </Box>
  );
}

export const DesktopNav = ({ navLinks }) => {
  const navigate = useNavigate();
  const linkColor = useColorModeValue("gray.900", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCheckbox = (type) => {
    return (event) => {
      if (event.target.checked) {
        if (
          selectedCategories.length < 5 &&
          !selectedCategories.includes(type)
        ) {
          setSelectedCategories((prevState) => [...prevState, type]); // Add type to array if conditions are met
        }
      } else {
        setSelectedCategories((prevState) =>
          prevState.filter((item) => item !== type)
        ); // Remove type if unchecked
      }
    };
  };
  return (
    <Stack direction={"row"} alignItems="center" spacing={4}>
      {navLinks.map((navItem) => (
        <Box key={navItem.label}>
          {navItem?.children ? (
            <Box>
              <Popover trigger={"hover"} placement={"bottom-start"}>
                <PopoverTrigger>
                  <Box
                    onClick={() => {
                      if (navItem?.href) {
                        navigate(navItem.href);
                      } else {
                        console.log("link not found");
                      }
                    }}
                    p={2}
                    cursor="pointer"
                    fontSize={"14px"}
                    fontWeight={"700"}
                    fontFamily={"Raleway"}
                    color={linkColor}
                    _hover={{
                      textDecoration: "none",
                      color: linkHoverColor,
                    }}
                  >
                    <HStack alignItems={"center"} spacing="1">
                      <Text>
                        {navItem?.label}
                        <TriangleDownIcon
                          color={"gray.500"}
                          fontSize={"10px"}
                          ml="3"
                          mr="1"
                        />
                        All
                      </Text>
                    </HStack>
                  </Box>
                </PopoverTrigger>

                {navItem.children && (
                  <PopoverContent
                    border={0}
                    borderRadius={"1px"}
                    bg={popoverContentBgColor}
                    px={1}
                    py="0px"
                    fontSize={"sm"}
                    minW={"xs"}
                    cursor="pointer"
                    color="black"
                    // className="popupcontent"
                  >
                    <Stack>
                      {navItem?.children?.map((child) => (
                        <DesktopSubNav
                          key={child?.label}
                          {...child}
                          Abbreviation={child.label}
                          Research_Category={child.hoverTitle}
                          type={child.type}
                          handleCheckbox={handleCheckbox}
                          selectedCategories={selectedCategories}
                          link={child.href}
                          alias={child.alias}
                        />
                      ))}
                      {/* <Text
                        fontSize={"19px"}
                        fontWeight="700"
                        lineHeight={"22px"}
                        textStyle="primary"
                      >
                        <Link href="#" color={"#0066B3"}>
                          Subscribe
                        </Link>{" "}
                        To Select Research Categories
                      </Text> */}
                    </Stack>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          ) : (
            <Button
              p={2}
              onClick={() => {
                if (navItem?.href) {
                  navigate(navItem.href);
                } else {
                  console.log("link not provided");
                }
              }}
              variant={"link"}
              textDecoration={"none"}
              fontSize={"sm"}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: "none",
              }}
            >
              {navItem?.label}
            </Button>
          )}
        </Box>
      ))}
    </Stack>
  );
};
const DesktopSubNav = ({
  label,
  href,
  subLabel,
  Abbreviation,
  Research_Category,
  type,
  alias,
  handleCheckbox,
  selectedCategories,
  link,
}) => {
  // const navigate = useNavigate();
  // console.log(navigate);
  return (
    <Box
      // onClick={() => {
      //   if (href) {
      //     navigate(href);
      //   } else {
      //     console.log("link not found");
      //   }
      // }}
      role={"group"}
      display={"block"}
      px={1}
      py={0}
      borderBottom={"1px"}
      borderColor={"gray.100"}
      _hover={{ bg: useColorModeValue("RGBA(0, 0, 0, 0.04)", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"} title={Research_Category}>
        <Checkbox
          size="lg"
          colorScheme="black"
          checked={selectedCategories.includes(type)}
          onChange={handleCheckbox(type)}
          disabled={
            selectedCategories.length >= 4 || selectedCategories.includes(type)
          }
        />
        <Box>
          <Link to={`/research-categories/${alias}`}>
          <Text px="1" fontSize="13px" fontWeight={"600"} color={"#333"}>
            {`${Abbreviation}_${type}`}
          </Text>
          </Link>
        </Box>

        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"black"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = ({ navLinks, closeMenuButton }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={8}
      display={{ md: "none" }}
    >
      {navLinks.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          {...navItem}
          closeMenuButton={closeMenuButton}
        />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, closeMenuButton }) => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Stack
      spacing={4}
      onClick={() => {
        if (children) {
          onToggle();
        } else if (href) {
          navigate(href);
          closeMenuButton();
        }
      }}
    >
      <Flex
        py={2}
        as={Link}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={500}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Button
                variant={"link"}
                onClick={() => {
                  navigate(child.href);
                  closeMenuButton();
                }}
                key={child.label}
                py={2}
                href={child.href}
                fontWeight={"400"}
                fontSize={"sm"}
              >
                {child.label}
              </Button>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
