import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";

import * as getResearchCentreBook from "../../../../services/book";

// Subsection Files
import { common } from "../../../../constants/constants";
import { EffectFade, Navigation, Pagination } from "swiper";
import Small from "../../../../components/layouts/Typography/Small";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";


export const Book = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAuthor, setIsExpandedAuthor] = useState(false);

  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleExpandAuthor = () => {
    setIsExpandedAuthor(!isExpandedAuthor);
  };

  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const { bookAlias } = useParams();
  const [bookData, setBookData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let result;
      result = await getResearchCentreBook.getResearchCentreBookbyBookAlias(
        "tableName",
        bookAlias
      );
      setBookData(result);
      if (bookData?.length > 0) {
        setSelectedImg({
          image: bookData[0].slider_data[0].image,
          desc: "",
          index: 0,
        });
      }
    };
    fetchData();
  }, [bookAlias, bookData]);

  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };
  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index + 1;
    const nextSlide = bookData[0]?.slider_data[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index - 1;

    const nextSlide = bookData[0]?.slider_data[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
      });
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {bookData[0]?.slider_data.length>0 &&<ImagePopupModal
        isOpen={synopsisFullScreenImage}
        onClose={() => showSynopsisImages(null, false)}
        imageData={synopsisImgData}
        data={bookData[0]?.slider_data}
        clickedImageIndex={clickedImageIndex}
      />}
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        py={{ base: "40px", md: "115px" }}
        gap={"20px"}
        width={{ base: "100%", md: "1140px" }}
        // px={8}
      >
        <HStack
          alignItems={"center"}
          flexDirection={"column"}
          width={{ base: "100%", md: "567px" }}
          // className="col-6"
          id="allsliderskkBook"
        >
          <Box width={"100%"} height={{ base: "100%", md: "450px" }}>
            <Image
              // src={"https://d3fsixjuo450s3.cloudfront.net/" + fullImage}
              // src={fullImage}
              src={selectedImg.image}
              width={"auto"}
              height={"inherit"}
           
              onClick={() =>
                showSynopsisImages(selectedImg.image, true, selectedImg.index)
              }
            ></Image>
          </Box>
          <Stack width={"100%"} position={"relative"} id="allsliderskkBook">
            <Swiper
              // effect={"fade"}
              ref={swiperRef}
              grabCursor={true}
              navigation={true}
              modules={[EffectFade, Navigation, Pagination]}
              // className="rightslideshow"
              style={{ marginTop: "30px" }}
              slidesPerView={4}
              slidesPerGroup={1}
              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
              //   loop={true}
            >
              {/* {imageData?.image?.map((item, index) => {
            return (
              <SwiperSlide key={index + 1}>
                {" "}
                <Image
                  height={"100px"}
                  src={"https://d3fsixjuo450s3.cloudfront.net/" + item}
                  //   onClick={() => handleBookClick(true, bibliographyObject)}
                />
              </SwiperSlide>
            );
          })} */}
              {bookData?.length > 0 &&
                bookData[0]?.slider_data?.length !== 0 &&
                bookData[0]?.slider_data?.map((item, index) => {
                  return (
                    <SwiperSlide>
                      <Stack position={"relative"} className="mt-0">
                        <Image
                          height={"90px"}
                          width={"auto"}
                          // src={imageData}
                          src={item.image}
                          // src={"https://d3fsixjuo450s3.cloudfront.net/" + item}
                          //   onClick={() => handleBookClick(true, bibliographyObject)}
                          onClick={() => {
                            swiperRef.current.swiper.slideTo(
                              swiperRef.current.swiper.activeIndex
                            );
                            setSelectedImg({
                              image: item.image,
                              desc: item.desc,
                              index: index,
                            });
                          }}
                        />
                        {swiperRef !== null && index !== selectedImg.index && (
                          <Stack
                            className="mt-0"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: "21px",
                              width: "60px",
                              height: "105px",
                              background: "rgba(0, 0, 0, 0.8)",
                            }}
                            onClick={() => {
                              // swiperRef.current.swiper.slideTo(index);
                              swiperRef.current.swiper.slideTo(
                                swiperRef.current.swiper.activeIndex
                              );
                              setSelectedImg({
                                image: item.image,
                                desc: item.desc,
                                index: index,
                              });
                            }}
                          />
                        )}
                      </Stack>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Stack>
        </HStack>
        <HStack
          flexDirection={"column"}
          height={"100%"}
          width={"100%"}
          px={{ base: "4", md: "8" }}
        >
          <Text
            fontSize={"20px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            color="#333333"
            ml={"0.5rem"}
          >
            {/* {bookData?.book_title_of_document} */}
            {bookData[0]?.bookName}
          </Text>
          {/* <Text fontSize={"15px"} fontWeight={"500"} lineHeight={"22px"}>
          {bookData?.book_sub_title_of_document}
        </Text> */}
          <Box width={{ base: "100%", md: "392px" }} py={5}>
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.CONTENTS}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Box maxH="160px" overflowY="auto">
                  <AccordionPanel pb={4} className="px-1">
                    {bookData?.length > 0 &&
                      bookData?.contentsData?.length > 0 &&
                      bookData[0]?.contentsData.map((item) => {
                        return (
                          <>
                            <Text
                              fontSize={"14px"}
                              pt={"4px"}
                              pb={"4px"}
                              px={5}
                            >
                              {item.title}
                            </Text>
                            <Divider color="#909090" />
                          </>
                        );
                      })}
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.INDEX}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} className="px-1">
                  <Small px={5}>{common.CONTENT_AWAITED}</Small>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Container px="0px">
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_BOOK}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpanded
                ? bookData?.length > 0 && bookData[0]?.bookText
                : `${
                    bookData?.length > 0 && bookData[0]?.bookText.slice(0, 360)
                  }`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpand}
              >
                {isExpanded ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
          <Container px="0px" py={5}>
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_AUTHOR}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpandedAuthor
                ? bookData?.length > 0 && bookData[0]?.authorText
                : `${
                    bookData?.length > 0 &&
                    bookData[0]?.authorText.slice(0, 263)
                  }`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpandAuthor}
              >
                {isExpandedAuthor ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
        </HStack>
      </Stack>
    </>
  );
};

const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
      zIndex={"9999"}
    >
      <ModalOverlay />
      <ModalContent overflowY="hidden" alignItems="center">
        <ModalBody>
          <Box bg="white" width={{ base: "350px", md: "1250px" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                // loop={true}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                      >
                        <Box
                          width={"auto"}
                          height={{ base: "100%", md: "90vh" }}
                        >
                          <Image
                            src={item.image}
                            width={"auto"}
                            height={"inherit"}
                            alt=""
                          />
                        </Box>
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={400}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.desc}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        // position={"relative"}
                        // justifyContent="flex-end"
                        position="absolute"
                        right={{ base: "95%", md: "15%" }}
                        top={{ base: "85%", md: "95%" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"50px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={"0.6"}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          // marginTop="-200px"
                          // marginRight={"250px"}
                          transform={""}
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
