import {
  Box,
  useColorModeValue,
  Container,
  Image,
  HStack,
  SimpleGrid,
  Text,
  Stack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  OrderedList,
  ListItem,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react";

//Core file
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service files
import * as getIntroductionData from "../../../../services/Introduction";
import * as getLandingMasterlistData from "../../../../services/SectionService";
import * as getMonumentDetailData from "../../../../services/Location";

//Component file
import MobileNav from "../../../../components/Topheader";
import { BottomMainMenu } from "../../../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import Small from "../../../../components/layouts/Typography/Small";
import ReadMore from "../../ReadMore";
import { FullScreenView } from "./FullScreenView";

//Language file
import { button, common, explore, filterAttribute, location } from "../../../../constants/constants";

export default function DetailPage() {
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias, monumentAlias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  useEffect(() => {
    const fetchData = async () => {
      const introductionResult = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (introductionResult) {
        setName(
          introductionResult[0]?.find((item) => item?.key === "name")?.value
        );
        setJournalDetails(
          introductionResult?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
      const landingResult = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (landingResult) {
        setIntroData(
          landingResult?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    fetchData();
  }, [aliasAPI, masterlistType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <MobileNav />
        <SidebarContent
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          <DetailPageBody aliasAPI={aliasAPI} monumentAlias={monumentAlias} />
        </Box>
      </Box>
    </Container>
  );
}

const SidebarContent = ({
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };

  const sections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      text: explore.INSIGHTS,
    },
  ];

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={sections}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        leftFlag={true}
      />
    </>
  );
};

const DetailPageBody = ({ aliasAPI, monumentAlias }) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [monumentData, setMonumentData] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [FullScreenImage, setFullScreenImage] = useState(false);
  const [ImgData, setImgData] = useState(null);
  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getMonumentDetailData.getMonumentDataByAlias(
          "tableName",
          aliasAPI,
          monumentAlias
          
        );
        if (result) {
          setMonumentData(result[0]);
        }
      } catch (error) {
        console.error("Error fetching monument data:", error);
      }
    };
    
    getData();
    window.scrollTo(0, 0);
  }, [aliasAPI, monumentAlias]);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  return (
  <>
  {/* Read More back navigation Remaining */}
    { readMore ? (<ReadMore />):
    <>
       <Drawer  
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef} 
        placement="right" id="rightsidebar">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody  pt="12">
              <Accordion  defaultIndex={[0]} allowMultiple>
                  <AccordionItem borderTopWidth={"0px"}>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                         {location.PATRON}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                      {monumentData?.patron?.length > 0 &&
                       monumentData?.patron?.map((it, index) => (
                      <ListItem pb="4">{it.list_content}</ListItem>
                      ))}
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                           {location.STRUCTURE}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                      {monumentData?.structure?.length > 0 &&
                       monumentData?.structure?.map((it, index) => (
                      <ListItem pb="4">{it.list_content}</ListItem>
                      ))}                      
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {location.LAYOUT}</Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                      {monumentData?.layout?.length > 0 &&
                       monumentData?.layout?.map((it, index) => (
                      <ListItem pb="4">{it.list_content}</ListItem>
                      ))}                      
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {location.MATERIALS_USED}</Text>
                      </Box>
                      <AccordionIcon  fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                      {monumentData?.materialsUsed?.length > 0 &&
                       monumentData?.materialsUsed?.map((it, index) => (
                      <ListItem pb="4">{it.list_content}</ListItem>
                      ))}                      
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {location.EVENT}</Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      {monumentData?.event?.length > 0 &&
                       monumentData?.event?.map((it, index) => (
                      <Text fontSize={"14px"} fontWeight={"400"}>{it.list_content}</Text>
                      ))}                    
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Container maxW="auto"  px={{ base: "4", md: "16" }} py={{ base: "4", md: "8" }} mx={"0"}>
          <Text pb="6" textAlign={"center"} color={"#333333"} fontSize={"24px"} fontWeight={"700"} lineHeight={"28px"} >
            {monumentData?.name}
          </Text>
      
          <HStack pb="3" justifyContent={"space-between"}>
          <Text color={"#333333"} textAlign={"center"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
            {monumentData?.comissioned_by}
          </Text>
          <Text color={"#333333"} textAlign={"center"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
            {monumentData?.architectural_material}
          </Text>
          <Text color={"#333333"} textAlign={"center"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
            {monumentData?.architectural_style}
          </Text>
          </HStack>
          <Text color={"#333333"} pb="3" textAlign={"left"} fontSize={"16px"} fontWeight={"500"} lineHeight={"20px"} >
            {monumentData?.description}
                     <Text px="1" as={"span"} cursor={"pointer"}  fontSize={"16px"} fontWeight={"700"} color={"#035DA1"} marginInlineStart={"0rem!important"}
                      onClick={ ()=> setReadMore(true)}
                     >
                      {common.READ_MORE}
                    </Text>
          </Text>
          <Box>
          <Text color={"#333333"} width={"max-content"} _hover={{color:"#035DA1"}} ref={btnRef} onClick={onOpen} py="6" textAlign={"left"} fontSize={"20px"} fontWeight={"700"} cursor={"pointer"} lineHeight={"24px"} >
            {location.OVERVIEW}
          </Text>
          </Box>
        <Stack>
        <Box>
          <Text color={"#333333"} textAlign={"left"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
            {location.RELATED_ARTWORKS}
          </Text>
          </Box>
          <Stack flexDirection={"row-reverse"}>
          <FilterButton marginRight={"-63px"} onClick={handleFilterClick} />
          <Stack py="4"  flexDirection={"row"} gap={4} justifyContent="center">
          <HStack >
          <SimpleGrid height={"500px"} overflowY={"scroll"} className="verticalscroll" columns={{ base: 2, md: 3, lg: 3, xl: 4 }} spacing="20px">
          {monumentData?.gridImages?.length > 0 &&
            monumentData?.gridImages?.map((it, index) => (
              <Box key={index} onClick={() => handleImageClick(it, index)} cursor={"pointer"} display="flex" flexDirection="column" alignContent="center">
              <Image src={it?.image} alt="" width="100%" height="auto" objectFit="cover" />
              <Text textAlign="left" onClick={() => handleImageClick(it, index)} color="#0066B3" py="4" fontSize="16px" fontWeight="400">
                {it?.citation_name}
              </Text>
            </Box>
              ))}
         </SimpleGrid>
         </HStack>
         <HStack className="mt-0" alignItems={"flex-start"}>
            {showFilterBox && (
              <Box marginRight={"-65px!important"}  className="box-filterArtHeritage">
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <HStack>
                    <SmallBold as="u" color={"black"}>
                    {button.CLEAR_ALL}
                    </SmallBold>
                  </HStack>
                  <HStack className="mt-0">
                    <RiCloseCircleFill
                      size={"22px"}
                      cursor="pointer"
                      onClick={(e) => setShowFilterBox(false)}
                    />
                  </HStack>
                </Stack>
                <HStack alignItems={"left"} flexDirection={"column"}>
                <InputGroup className="ml-0" py="4">
                                <Input
                                  placeholder="Search"
                                  height={"32px"}
                                  py={"4px"}
                                  _placeholder={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                                />
                                <InputRightElement mt={"10px"}>
                                  <BiSearch
                                    color="green.500"
                                    fontSize={"16px"}
                                  />
                                </InputRightElement>
                              </InputGroup>
                </HStack>
                <Accordion allowToggle allowMultiple>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                         {filterAttribute.DOCUMENT_TYPE}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                           {filterAttribute.PHOTOGRAPHERS_STUDIO}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {filterAttribute.DATE}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {filterAttribute.MEDIUM}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                      <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {filterAttribute.COMISSIONED_BY}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} className="px-1">
                      <Small color="#035DA1">{common.CONTENT_AWAITED}</Small>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <HStack marginTop={"10px"}>
                  <Button
                    color={"#ffffff"}
                    onClick={() => {
                    }}
                    width={"199px"}
                    height={"36px"}
                    bg={"#000000"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                    variant={"unstyled"}
                  >
                    {explore.APPLY}
                  </Button>
                </HStack>
              </Box>
            )}
          </HStack>
         </Stack>
         {FullScreenImage && <FullScreenView
            isOpen={FullScreenImage}
            onClose={() => showImages(null, false)}
            ImgData={ImgData}
            imageData={monumentData}
            bannerFlag={true}
            title="banner"
            setReadMoreFlag={""}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
          />}
         </Stack>
        </Stack>
      </Container>
    </>}
    </>
  );
};
