import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HStack } from "@chakra-ui/react";
import Large from '../components/layouts/Typography/Large';
import { siteInfo } from "../../src/constants/constants";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const LastCommit = ({ repositoryhost, projectId, accessToken, branchName }) => {
  const [lastCommit, setLastCommit] = useState(null);
  const [lastCommitDate, setLastCommitDate] = useState(null);

  useEffect(() => {
    const fetchLastCommit = async () => {
      try {
        const response = await axios.get(
            `https://${repositoryhost}/api/v4/projects/${projectId}/repository/commits?ref_name=${branchName}`,               
            {
                headers: {
                'Private-Token': accessToken,
                },
            }
        );

        if (response.data && response.data.length > 0) {
          setLastCommit(response.data[0]);
          const dateTimeString = response.data[0].committed_date;
          const dateObj = new Date(dateTimeString);
          const indianTimeZone = 'Asia/Kolkata';
          const indianDateObj = utcToZonedTime(dateObj, indianTimeZone);
          const formattedDateTime = format(indianDateObj, 'd MMMM yyyy hh:mm a');
          setLastCommitDate(formattedDateTime)
        } else {
          console.error('No commits found in the repository.');
        }
      } catch (error) {
        console.error('Error fetching last commit information:', error);
      }
    };

    fetchLastCommit();
  }, [repositoryhost, projectId, accessToken, branchName]);

  return (
    <div>
      {lastCommit ? (
        <HStack>
            <Large color={"white"}>
            {`${siteInfo.LAST_DEPLOYMENT_DATE}: ${lastCommitDate} IST | ${siteInfo.COMMIT_ID}: ${lastCommit.short_id} | ${siteInfo.DB_NAME}: ${process.env.REACT_APP_GRAPHQL_URL}`}
            </Large>
        </HStack>
      ) : (
        <HStack>
            <Large color={"white"}>Loading last commit information...</Large>;
        </HStack>
      )}
    </div>
  );
};

export default LastCommit;
