import axios from "axios";
import { transformJSON } from "../util/transformer";
import { filmCrewSchema } from "./schemas/Crew";
import { silsila_horizontal_images_data, skk_horizontal_images_data } from "../MockData/FilmMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const getFilmCrewsByMasterlistAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: CrewWhere){` +
      tableName +
      ` (where: $where){
        sl_no
        masterlist
        artist_name
        character_name
        id
        artistPersonality {
            name
            type
            first_name
            last_name
            full_name
        }
        characterImage {
            accession_number
            artist_name
            artwork_in_a_graphy_section_sub_section
            date_of_artwork
            direct_url_of_preview_image
            featured_artist_or_facial_recognition_names
            id
            height_cm
            medium_of_artwork
            name_of_institution
            publication_year
            name_of_publication
            revised_medium
            sortable_year_of_publication
        }              
      }
    }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.crews) {
    return result?.data?.data?.crews;
  } else {
    return [];
  }
};

export const getFilmCrewsByMasterlist = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getFilmCrewsByMasterlistDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false)  {
    const response = await getFilmCrewsByMasterlistAPI(tableName, masterlist);
    try {
      if (response) {
        let transformedData = transformJSON(filmCrewSchema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getFilmCrewsByMasterlistDummyData = async (masterlist) => {
  const filmCrewsDataMap = {
    "shatranj-ke-khilari-1977": skk_horizontal_images_data,
    "mughal-e-azam": skk_horizontal_images_data,
    "silsila":silsila_horizontal_images_data
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmCrewsData = filmCrewsDataMap[masterlist] || skk_horizontal_images_data;
  return FilmCrewsData;
};
