import React, { useEffect, useState } from "react";
import HighsBar from "./HistoryofHighs/HighsBar";
import { Container, HStack, Stack } from "@chakra-ui/react";
import * as getHistoryHighData from "./../../../services/PersonArtist";
import ImageGridlist from "../Component/ImageGridlist";
import { FullScreenImage } from "../Component/FullScreenImage";
import { CopyPasteService } from "../../../services/CopyPaste";

export default function HistoryofHighs({tableName,masterlist}) {
    const [recordData, setRecordData] = useState([]);
    const [fullScreenImage, setFullScreenImage] = useState(false);
    const [clickedImageIndex, setClickedImageIndex] = useState(null);
    const [ImgData, setImgData] = useState(null);
  
    const showImages = (image, flag) => {
      if (image) {
        setFullScreenImage(flag);
        setImgData(image);
      } else {
        setFullScreenImage(flag);
        setImgData(null);
      }
    };
    const handleImageClick = (item, index) => {
        setClickedImageIndex(index);
        setFullScreenImage(true);
      };
    const createDataObject = (dataArray) => {
        const dataObject = {};
        if (dataArray.length > 0) {
          const dataItem = dataArray[0];
          for (const key in dataItem) {
            dataObject[key] = dataItem[key];
          }
        }
        return dataObject;
      };

      const citationFactor = (data) => {
        return data.map((item, index) => {
          const artist = createDataObject([item.artist]);
          const medium_artwork = createDataObject([item.medium_artwork]);
          const title_of_artwork = createDataObject([item.title_of_artwork]);
    
          const lot_number = createDataObject([item?.lot_number]);
    
          const auction_house = createDataObject([item?.auction_house]);
    
          const venue_location = createDataObject([item?.venue_location]);
    
          const date_of_auction = createDataObject([item?.date_of_auction]);
    
          const type_of_auction = createDataObject([item?.type_of_auction]);
    
          const number_of_bids_received = createDataObject([
            item?.number_of_bids_received,
          ]);
    
          const percentage_over_under_mid_estimate = createDataObject([
            item?.percentage_over_under_mid_estimate,
          ]);
    
          const artwork_square_inch_rate = createDataObject([
            item?.artwork_square_inch_rate,
          ]);
    
          const previous_auction_sales = createDataObject([
            item?.previous_auction_sales,
          ]);
    
          const cagr_based_on_previous_sale = createDataObject([
            item?.cagr_based_on_previous_sale,
          ]);
          const content = createDataObject([item?.desc]);
          const estimates = createDataObject([item?.estimates]);
          const winning_bid = createDataObject([item?.winning_bid]);
          const size = createDataObject([item?.size]);
    
          return {
            image: item?.image,
            artist,
            desc: `${artist?.full_name}. ${title_of_artwork?.value}, ${medium_artwork?.value}, ${medium_artwork?.date_of_artwork} | ${auction_house?.value}, ${venue_location?.value}`,
            medium_artwork,
            title_of_artwork,
            link: item?.link,
            title: `${artist?.name}`,
            fullImg: item?.fullImage,
            essayistData: [
              title_of_artwork,
              medium_artwork,
              size,
              lot_number,
              auction_house,
              venue_location,
              date_of_auction,
              type_of_auction,
            ],
            auctionData: [
              number_of_bids_received,
              percentage_over_under_mid_estimate,
              artwork_square_inch_rate,
              previous_auction_sales,
              cagr_based_on_previous_sale,
            ],
            auction_estimates: [estimates, winning_bid],
            content: [content],
          };
        });
      };

      useEffect(() => {
        const fetchData = async () => {
          let result;
          result = await getHistoryHighData.getToprecordsbyMasterlist(
            tableName,
            masterlist
          );
    
          setRecordData(citationFactor(result));
        };
        fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tableName, masterlist]);
  return (
    <Container
      position={"relative"}
      maxW="auto"
      px="40px"
      className=""
      paddingTop={{ base: "40px", md: "0px" }}
    >
      <Stack
        flexDirection={{ base: "column", md: "column" }}
        justifyContent="space-between"
        gap={4}
        minH={{ base: "100vh", md: "82vh" }}
      >
        {" "}
        <HighsBar />

        {recordData.length > 0 && (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              <ImageGridlist
                data={recordData}
                handleImageClick={handleImageClick}
              />
            </HStack>
          )}
      </Stack>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={recordData.slice(0, 1)}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="TopAuctionSales"
        />
      )}
    </Container>
  );
}
