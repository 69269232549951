import {
  Box,
  HStack,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation,  } from "swiper";

//Service files
import { CopyPasteService } from "../../../services/CopyPaste";

//Component files
import { TextComponent } from "../../../CommonComponents/TextComponent";
import {ImagePopupModal} from "../../Film/BodyOfWork/Synopsis"
import { LyricsSoundtrack } from "../../Film/BodyOfWork/Soundtrack"

//Language files
import { explore } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const synopsis1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/Synopsis/banner1.png`
const synopsis2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/Synopsis/banner2.png`
const synopsis3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/Synopsis/banner3.png`
const sound1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/Synopsis/sound1.png`
const sound2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/Filmography/BookletSongs/Synopsis/sound2.png`

function BookletSongs() {
    const mergeData = [
        {
          imagePath: sound2,
          desc: "Shatranj Ke Khilari | 1977 | Song Synopsis Booklet | CINE.ssb",
          newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 0000013"
        },
        {
          imagePath: sound1,
          desc: "Caption Awaited",
          newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 000013a"
        }
      ]; 

  return (
    <Tabs py={{base:1,md:4}}>
    <TabList
      position="sticky"
      zIndex={99}
      width={{base:"100%",md:"100%"}}
      top={{base:"64px",md:"90px"}}
      px="0px"
      py="2"
      bg="white"
    >
      <Tab
        className="Timeline"
        p="0"
        mr="4"
        fontSize={"16px"}
        fontWeight={"400"}
        _selected={{
          borderBottom: "4px",
          color: "black",
          borderBottomColor: "black",
          fontWeight: "700",
          borderRadius: "4px",
        }}
      >
        {explore.SYNOPSIS}
      </Tab>
      <Tab
        className="Timeline"
        p="0"
        ml="4"
        fontSize={"md"}
        fontWeight={"400"}
        _selected={{
          borderBottom: "4px",
          color: "black",
          borderBottomColor: "black",
          fontWeight: "700",
          borderRadius: "4px",
        }}
      >
        {explore.LYRICS_AND_SOUNDTRACK}
      </Tab>
    </TabList>
    <TabPanels height={{base:"100vh",md:"calc(100vh - 130px)"}}>
      <TabPanel px="0px" py={0}>
      <Synopsis/>
      </TabPanel>
      <TabPanel px="0px" py={0}>
      <LyricsSoundtrack data={true} mergeData={mergeData}  marginLeft={{ base: "-20px !important", md: "-60px !important" }}/>
      </TabPanel>
    </TabPanels>
  </Tabs>
  );
}

export default BookletSongs;

const Synopsis=({ winnerName }) =>{
    const [clickedImageIndex, setClickedImageIndex] = useState(null);
  
    const mergeData = [
      {
        imagePath: synopsis1,
        desc: "Shatranj Ke Khilari | 1977 | Song Synopsis Booklet | CINE.ssb",
        newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 0000013"
      },
      {
        imagePath: synopsis2,
        desc: "Shatranj Ke Khilari | 1977 | Song Synopsis Booklet | CINE.ssb",
        newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 000013a"
      },
      {
        imagePath: synopsis3,
        desc: "Shatranj Ke Khilari | 1977 | Song Synopsis Booklet | CINE.ssb",
        newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 000013b"
      }
    ]; 

    const synopsisDesc = "Wajid Ali Shah is the ruler of one of the last independent kingdoms of India. The British, intent on controlling India, send General Outram on a secret mission to clear the way for an annexation. While pressure is mounting amidst intrigue and political manoeuvres, Wajid Ali Shah, secluded in his palace, composes poems and listens to music. The court is of no help, as exemplified by two slightly eccentric noblemen, Mir and Mirza who, ignoring the situation in the country and their duties towards their families, spend their days playing endless rounds of chess. The imminent threat of the British takeover of their region becomes a harsh reality. Even when they are forced to leave their comfortable homes, they continue to play chess in the mosquito ridden outdoors, with two loaded pistols, ready to oppose the British, or even themselves, if need be."
    const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
    const [synopsisImgData, setsynopsisImgData] = useState(null);
    const showSynopsisImages = (image, flag, index) => {
      if (image) {
        setClickedImageIndex(index);
        setSynopsisFullScreenImage(flag);
        setsynopsisImgData(image);
      } else {
        setSynopsisFullScreenImage(flag);
        setsynopsisImgData(null);
      }
    };
  
    return (
      <>
        <ImagePopupModal
          isOpen={synopsisFullScreenImage}
          onClose={() => showSynopsisImages(null, false)}
          imageData={synopsisImgData}
          data={mergeData}
          clickedImageIndex={clickedImageIndex}
        />
        <Stack
          pb="2"
          flexDirection={{base:"column",md:"row"}}
          alignItems={"flex-start"}
          gap={6}
          pt={{base:"0",md:"6"}}
        >
          <HStack
            marginTop="0.0rem!important"
            flexDirection={"column"}
            width={{base:"100%",md:"526px"}}
            px="0px"
            py="4"
            alignItems={"flex-start"}
          >
            <TextComponent text={synopsisDesc} fontSize={"16px"}
              textAlign={"justify"}
              fontWeight={"600"}
              color={"#333333"}
             
              lineHeight={"32px"}
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)} />
          </HStack>
          <HStack  width={{base:"100%",md:"526px"}}>
            <Stack className="col-12" id={"satyajitslidersynp"} position={"relative"}>
              <HStack py="4">
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation]}
                >
                  {mergeData.map((item, index) => (
                    <>
                      <SwiperSlide className="myslide">
                        <Box height={"100%"} width={{base:"100%",md:"484px"}}> 
                          <Image
                            height={"inherit"}
                            width={"100%"}
                            onClick={(e) =>
                              showSynopsisImages(item?.imagePath, true, index)
                            }
                            src={
                              index < 2
                                ? item
                                  ? 
                                    item?.imagePath
                                  : ""
                                : item?.imagePath
                            }
                            cursor={"pointer"}
                          />
  
                          <Text
                            pt={"16px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                            textAlign={"left"}
                          >
                            {item.desc}
                          </Text>
                        </Box>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              </HStack>
            </Stack>
          </HStack>
        </Stack>
      </>
    );
  }