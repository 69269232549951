//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Dummy1 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/1.png`
const Dummy2 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/2.png`
const Dummy3 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/3.png`
const Dummy4 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/4.png`
const Dummy5 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/5.png`
const Dummy6 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/6.png`
const Dummy7 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/7.png`
const Dummy8 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/8.png`
const Dummy9 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image29.png`
const Dummy10 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image30.png`
const Dummy11 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image31.png`
const Dummy12 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image32.png`
const Dummy13 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image33.png`
const Dummy14 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image34.png`
const Dummy15 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/image35.png`
const Dummy16 = `${IMAGE_BASE_PATH}/ui/Explore/Posts/1.jpg`
const Dummy17 = `${IMAGE_BASE_PATH}/ui/Explore/Explore/9.png`
const pictorial = `${IMAGE_BASE_PATH}/ui/Explore/Explore/pictorial.png`
const Amitabh= `${IMAGE_BASE_PATH}/ui/Explore/Explore/AmitabhBachchan.png`
const KGSubra = `${IMAGE_BASE_PATH}/ui/Explore/Explore/KGsubramanyan.png`
const ShammiKapoor = `${IMAGE_BASE_PATH}/ui/Explore/Explore/shammiKapoor.jpg`
const rajkapoor = `${IMAGE_BASE_PATH}/ui/Explore/Explore/rajkapoor.png`

export const exploreData = [
  {
    id: 1,
    imgPath: pictorial,
    navigationLink: "/event-exhibition/pictorial-space",
    title: "Pictorial Space",
    desc: "Pictorial Space",
  },
  {
    id: 1,
    imgPath: KGSubra,
    navigationLink: "/person-artist/k.g-Subramanyan",
    title: "K.G. Subramanyan",
    desc: "K.G. Subramanyan",
  },
  {
    id: 1,
    imgPath: Dummy1,
    navigationLink: "/whats-new-page",
    title: "Carcassonne",
    desc: "Carcassonne, painted by S.H. Raza in 1956, is a...",
  },
  {
    id: 2,
    imgPath: Dummy2,
    navigationLink: "/whats-new-page1",
    title: "The Big City Poster",
  },
  {
    id: 3,
    imgPath: Dummy3,
    navigationLink: "/whats-new-page2",
    title: "Gandhiji Visiting Political Prisoners At Dum Dum Jail In 1947",
  },
  {
    id: 4,
    imgPath: Dummy4,
    navigationLink: "/whats-new-page4",
    title: "The Swan Car",
  },
  {
    id: 5,
    imgPath: Dummy5,
    navigationLink: "/whats-new-page3",
    title: "Rumi Darwaaza",
  },
  {
    id: 6,
    imgPath: Dummy6,
    navigationLink: "/person-artist/sayed-haider-raza",
    title: "Sayed Haider Raza",
  },
  {
    id: 7,
    imgPath: Dummy7,
    navigationLink: "/person-poly/satyajit-ray",
    title: "Satyajit Ray",
  },
  {
    id: 8,
    imgPath: Dummy8,
    navigationLink: "/film/shatranj-ke-khilari-1977",
    title: "Shatranj Ke Khilari",
  },
  {
    id: 9,
    imgPath: Dummy16,
    navigationLink: "/object-book-jou/art-heritage-annual",
    title: "Art Heritage Annual",
  },
  {
    id: 10,
    imgPath: Dummy17,
    navigationLink: "/film/mughal-e-azam",
    title: "Mughal-E-Azam",
  },
  {
    id: 11,
    imgPath: rajkapoor,
    navigationLink: "/person-film-maker/raj-kapoor",
    title: "Raj Kapoor",
  },
  {
    id: 12,
    imgPath: Amitabh,
    navigationLink: "/person-actor/amitabh-bachchan",
    title: "Amitabh Bachchan",
  },
  {
    id: 12,
    imgPath: ShammiKapoor,
    navigationLink: "/person-actor/shammi-kapoor",
    title: "Shammi Kapoor",
  },
  {
    id: 13,
    imgPath: Dummy11,
    navigationLink: "/",
  },
  {
    id: 14,
    imgPath: Dummy12,
    navigationLink: "/",
  },
  {
    id: 15,
    imgPath: Dummy13,
    navigationLink: "/",
  },
  {
    id: 16,
    imgPath: Dummy14,
    navigationLink: "/",
  },
  {
    id: 17,
    imgPath: Dummy15,
    navigationLink: "/",
  },
  {
    id: 18,
    imgPath: Dummy9,
    navigationLink: "/",
  },
];

export const exploreDataForCalendar = [
  {
    id: 1,
    imgPath: Dummy1,
    navigationLink: "/whats-new-page",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 2,
    imgPath: Dummy2,
    navigationLink: "/whats-new-page1",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 3,
    imgPath: Dummy3,
    navigationLink: "/whats-new-page2",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 4,
    imgPath: Dummy4,
    navigationLink: "/whats-new-page4",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 5,
    imgPath: Dummy5,
    navigationLink: "/whats-new-page3",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 6,
    imgPath: Dummy6,
    navigationLink: "/sayed-haider-raza",
    title: "Sayed Haider Raza",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 7,
    imgPath: Dummy7,
    navigationLink: "/satyajit-ray",
    title: "Satyajit Ray",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 8,
    imgPath: Dummy8,
    navigationLink: "/film/shatranj-ke-khilari-1977",
    title: "Shatranj Ke Khilari",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 9,
    imgPath: Dummy16,
    navigationLink: "/art-heritage-annual",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 10,
    imgPath: Dummy10,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 11,
    imgPath: Dummy11,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 12,
    imgPath: Dummy12,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 13,
    imgPath: Dummy13,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 14,
    imgPath: Dummy14,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 15,
    imgPath: Dummy15,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 16,
    imgPath: Dummy9,
    navigationLink: "/",
    title: "Content Awaited",
    dateTime: "3:16 PM, Monday, 17 January 2023",
  },
];
