import { Container, HStack, Stack, Text } from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";

//Service files
import * as getComparableArtworksData from "./../../../services/EventExhibition";
import { CopyPasteService } from "../../../services/CopyPaste";

//Component files
import ImageGridlist from "../Component/ImageGridlist";
import ScrollToTop from "../../../components/sections/ScrollToTop";

//Language files
import { common } from "../../../constants/constants";


export default function ComparableArtworks({ winnerName }) {
  const [FilterData, setFilterData] = useState([{}]);

  const citationFactor = (data) => {
    return data.map((item, index) => {
      return {
        image: item?.image,
        desc: item?.desc,
      };
    });
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getComparableArtworksData.comparableArtworksData(
        "event-exhibition",
        winnerName
      );
      setFilterData(citationFactor(result));
    };

    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {FilterData.length > 0 ? (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              <ImageGridlist data={FilterData} />
            </HStack>
          ) : (
            <>
              <HStack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {common.NO_DATA}
                </Text>
              </HStack>
            </>
          )}
        </Stack>
        <ScrollToTop />
      </Container>
    </Stack>
  );
}
