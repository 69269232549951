import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import H4 from "../../../../components/layouts/Typography/H4";
import { isArray } from "lodash";
import dateFormat from "dateformat";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { explore } from "../../../../constants/constants";
import H2 from "../../../../components/layouts/Typography/H2";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const dot = `${IMAGE_BASE_PATH}/ui/Chevron/TimelineEnd.png`

export default function DrawerSelfIntro({
  intro,
  showFamilyMemberImages,
  personaliaData,
  showEducationImages,
  data,
  teachingData,
  personaliaDrawer,
}) {
  const [categorizedData, setCategorizedData] = useState({});

  const categorizeData = () => {
    const categorized = {};
    teachingData.forEach((item) => {
      const { post } = item;
      const key = post;
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(item);
    });
    setCategorizedData(categorized);
  };

  React.useEffect(() => {
    categorizeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Accordion
        allowToggle
        defaultIndex={[
          personaliaDrawer === explore.PERSONALIA
            ? 0
            : personaliaDrawer === explore.EDUCATION
            ? 1
            : 2,
        ]}
      >
        <AccordionItem borderTop={"none"}  borderBottom={"0.5rem"} borderBottomColor={"#e8e8e8"}>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <H2 textStyle={"primary"}>
                {explore.PERSONALIA}
              </H2>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel
            pb={4}
            /* isOpen={personaliaDrawer === "Personalia"}  */ isOpen={1}
          >
            {personaliaData &&
              personaliaData?.map((item, index) => {
                return (
                  <Stack key={index} marginLeft={"10px"}>
                    <HStack
                      py={"2"}
                      flexDirection={"row"}
                      marginTop={"0px !important"}
                    >
                      <Stack>
                        <H4>{item?.display}</H4>
                        {isArray(item?.value) ? (
                          item?.value?.map((member, memberIndex) => {
                            const imageUrl =
                              member?.image !== "undefined" &&
                              member?.image !== null
                                ? member?.image
                                : null;

                            return (
                              <React.Fragment key={memberIndex}>
                                <HStack
                                  marginLeft={
                                    imageUrl
                                      ? "-25px!important"
                                      : "0px!important"
                                  }
                                  alignItems={"flex-start"}
                                >
                                  {imageUrl && (
                                    <Box pt="1">
                                      <AiOutlinePicture
                                        onClick={() =>
                                          showFamilyMemberImages(imageUrl, true)
                                        }
                                        cursor={"pointer"}
                                        color="#1E90FF"
                                        size={18}
                                      />
                                    </Box>
                                  )}
                                  <Text fontSize={"16px"} fontWeight={"400"}>
                                    {member?.value?.charAt(0).toUpperCase() +
                                      member?.value?.slice(1)}
                                    <br />
                                    {member?.birth_date &&
                                      member?.death_date && (
                                        <>
                                          (
                                          {dateFormat(
                                            member?.birth_date,
                                            "d mmm yyyy"
                                          )}{" "}
                                          -{" "}
                                          {dateFormat(
                                            member?.death_date,
                                            "d mmm yyyy"
                                          )}
                                          )
                                        </>
                                      )}
                                  </Text>
                                </HStack>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <>
                            <HStack
                              pb={"2"} pt="0"
                              flexDirection={"row"}
                              marginTop={"0px !important"}
                            >
                              <Stack>
                                <Text fontSize={"16px"} fontWeight={"400"}>
                                  {item.key === "date_of_birth" ||
                                  item.key === "date_of_death" ||
                                  item.key === "marriageregistrationdate"
                                    ? item.value === "" ? "" : dateFormat(item?.value, "d mmm yyyy")
                                    : item?.value}
                                </Text>
                              </Stack>
                            </HStack>
                          </>
                        )}
                      </Stack>
                    </HStack>
                  </Stack>
                );
              })}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <H2 textStyle={"primary"}>
                {explore.EDUCATION}
              </H2>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <>
              <Stack>
                {data.length > 0 && (
                  <>
                    <HStack  pb={"2"} pt="0" flexDirection={"row"} marginLeft={"10px"}>
                      <Stack>
                        {data.map((education, educationIndex) => {
                          const imageObject = education?.image
                            ? education?.image
                            : null;
                          // const imageUrl = imageObject?.image
                          //   ? imageObject?.image
                          //   : null;
                          return (
                            <>
                              <Text
                                fontSize={"16px"}
                                fontWeight={"700"}
                                key={educationIndex}
                                marginTop={"0px !important"}
                              >
                                {education?.year && <> {education?.year}</>}
                              </Text>
                              <HStack
                                marginLeft={
                                  imageObject
                                    ? "-25px!important"
                                    : "0px!important"
                                }
                                alignItems={"flex-start"}
                              >
                                {imageObject && (
                                  <Box pt="1">
                                    <AiOutlinePicture
                                      onClick={() =>
                                        showEducationImages(imageObject, true)
                                      }
                                      cursor={"pointer"}
                                      color="#1E90FF"
                                      size={18}
                                    />
                                  </Box>
                                )}
                                <Text
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                  width={"280px"}
                                  key={educationIndex}
                                >
                                  {education?.value}
                                </Text>
                              </HStack>
                              <br />
                            </>
                          );
                        })}
                      </Stack>
                    </HStack>
                  </>
                )}
              </Stack>
            </>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <H2
                style={{ whiteSpace: "pre-wrap" }}
                textStyle={"primary"}
                // marginLeft={"10px"}
              >
                {explore.TEACHING_INFRA_B}
              </H2>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <>
              {categorizedData &&
                Object.entries(categorizedData).map(([category, items]) => {
                  return (
                    <Stack key={category} marginLeft={"10px"}>
                      <Text
                        fontSize={"18px"}
                        fontWeight={500}
                        pt={"16px"}
                        pb={"12px"}
                        lineHeight={"21px"}
                      >
                        {category}
                      </Text>
                      <VerticalTimeline layout={"1-column-left"}>
                        {items &&
                          items.map((item, index) => {
                            return (
                              <>
                                {item?.image && (
                                  <Box
                                    pos={"relative"}
                                    right={"20px"}
                                    top={"23px"}
                                  >
                                    <AiOutlinePicture
                                      onClick={() =>
                                        showFamilyMemberImages(
                                          item?.image,
                                          true
                                        )
                                      }
                                      cursor={"pointer"}
                                      color="#1E90FF"
                                      size={18}
                                    />
                                  </Box>
                                )}

                                <VerticalTimelineElement
                                  id="timeline1"
                                  contentStyle={{ fontSize: "sm" }}
                                  iconStyle={{
                                    background: "#fff",
                                    color: "black",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    maxWidth: "min-content",
                                  }}
                                  icon={
                                    item.display_date === "NA" ? (
                                      <Image
                                        marginLeft={"13px"}
                                        marginTop={"8px"}
                                        src={dot}
                                      ></Image>
                                    ) : (
                                      item.display_date
                                    )
                                  }
                                >
                                  <Text cursor={"pointer"} color="black">
                                    {item.value}
                                  </Text>
                                </VerticalTimelineElement>
                              </>
                            );
                          })}
                      </VerticalTimeline>
                    </Stack>
                  );
                })}
            </>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
