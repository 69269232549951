import { HStack } from "@chakra-ui/react";
import H5 from "../components/layouts/Typography/H5";
import { Link } from "react-router-dom";
import Large from "../components/layouts/Typography/Large";

export const VerticalMenu=({sections})=>{
    return(
      <>
      {sections.map((section) => {
        let isActive = window.location.href.includes(section.title);
        return (
          <HStack
            key={section.to}
            justifyContent={isActive === true ? "space-between" : ""}
            bg={isActive === true ? "#f6f6f6" : "white"}
            borderLeft={isActive === true ? "4px" : ""}
            borderColor={isActive === true ? "#FF4E4E" : ""}
            borderRadius={isActive === true ? "0px" : ""}
          >
            {isActive ? (
              <H5 py="2" px="3" color={"black"}>
                <Link
                  to={section.to}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  {section.text}
                </Link>
              </H5>
            ) : (
              <Large py="1" px="4" color={"black"}>
                <Link
                  to={section.to}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  {section.text}
                </Link>
              </Large>
            )}
          </HStack>
        );
      })}
      </>
    )
   }