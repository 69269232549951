import React from "react";
import { Helmet } from "react-helmet-async";

const UpdateHelmet = ({ title, description, type, image }) => {
  const defaultTitle = "Tuli Research Centre for India Studies";
  const tit = title ? `${defaultTitle} - ${title}` : defaultTitle;
  const siteUrl = "https://dev.osianamaworld.org/";
  const domainName = "dev.osianamaworld.org";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{tit}</title>
      <meta name="description" content={description || ""} />

      {/* Facebook and Gmail */}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content={type || "website"} />
      <meta property="og:title" content={tit} />
      <meta property="og:description" content={description || ""} />
      <meta property="og:image" content={image || ""} />

      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={domainName} />
      <meta property="twitter:url" content={siteUrl} />
      <meta name="twitter:title" content={tit} />
      <meta name="twitter:description" content={description || ""} />
      <meta name="twitter:image" content={image || ""} />
    </Helmet>
  );
};

export default UpdateHelmet;
