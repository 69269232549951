import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core files
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoBookmarksOutline } from "react-icons/io5";
import { IoBookmarks } from "react-icons/io5";

// Service Files
import * as getInsightDetailsData from "../../../services/Insight";
import { button, common } from "../../../constants/constants";

// Subsection Files
import Layout from "../../../components/sections/SidebarLayout";
import InsightPageSlider from "./InsightPageSlider";
import QuizDrawer from "./QuizDrawer";

function InsightDetails() {
  const { alias, insightAlias } = useParams();
  const masterlistTitle = alias;
  const insight = insightAlias;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [essentialbookmarkedItems, setEssentialBookmarkedItems] = useState([]);
  const [suggestingBookmarkedItems, setSuggestingBookmarkedItems] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [insightDetailsData, setInsightDetailsData] = useState([]);
  const [insightSuggestedReading, setInsightSuggestedReading] = useState([]);
  const [insightEssentialReading, setInsightEssentialReading] = useState([]);

  const handleShowContent = () => {
    setShowContent(prev=>!prev);
  };

  const toggleBookmark = (index) => {
    if (essentialbookmarkedItems.includes(index)) {
      setEssentialBookmarkedItems(essentialbookmarkedItems.filter((item) => item !== index));
    } else {
      setEssentialBookmarkedItems([...essentialbookmarkedItems, index]);
    }

  };
  const suggestingToggleBookmark = (index) => {
    if (suggestingBookmarkedItems.includes(index)) {
      setSuggestingBookmarkedItems(suggestingBookmarkedItems.filter((item) => item !== index));
    } else {
      setSuggestingBookmarkedItems([...suggestingBookmarkedItems, index]);
    }

  };

  const isItemBookmarked = (index) => {
    return essentialbookmarkedItems.includes(index);
  };

  const isItemSuggestingBookmarked = (index) => {
    return suggestingBookmarkedItems.includes(index);
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsight(
        "insights",
        masterlistTitle,
        insight
      );
      if (result) {
        setInsightDetailsData(result);
      }
    };
    getData();
  }, [masterlistTitle, insight]);

  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsightSuggestingReading(
        "masterlist_table",
        masterlistTitle
      );
      if (result) {
        setInsightSuggestedReading(result);
      }
    };
    getData();
  }, [masterlistTitle]);
  
  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsightEssentialReading(
        "masterlist_table",
        masterlistTitle
      );
      if (result) {
        setInsightEssentialReading(result);
      }
    };
    getData();
  }, [masterlistTitle]);

  const navigate = useNavigate();
  return (
    <Layout>
      <QuizDrawer setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
      <Container
        maxW={"auto"}
        marginTop={"30px"}
        marginX={{base:"0px",md:"30px"}}
        id="allslider"
      >
        {insightDetailsData.length > 0 &&
          insightDetailsData?.map((item, index) => (
            <Stack>
              <Stack justifyContent={"space-between"} flexDirection={{base:"column",md:"row"}} >
              <HStack flexDirection={"column"} alignItems={"flex-start"}>
                <Text
                  pb="2"
                  fontSize={"25px"}
                  fontWeight={"700"}
                  lineHeight={"30px"}
                >
                  {item.title}
                </Text>
                <Text
                  marginInlineStart={"0rem!important"}
                  fontSize={"20px"}
                  fontWeight={"500"}
                  lineHeight={"30px"}
                >
                  {item.subtitle}
                </Text>
              </HStack>
              <HStack width={{base:"100%",md:"30%"}} pb="2" gap={"10px"} wrap={"wrap"}>
                   <Button
                    border={"1px"}
                    borderColor={"#FFC015"}
                    className="keyword-btn"
                    marginInlineStart={"0rem!important"}
                    onClick={handleShowContent}
                    >
                      {button.INSIGHT_GYAN}
                    </Button>
                    <Button
                      border={"1px"}
                      borderColor={"#FFC015"}
                      className="keyword-btn"
                      marginInlineStart={"0rem!important"}
                      onClick={()=>navigate(`insight-video`)}
                    >
                      {button.INSIGHT_VIDEO}
                    </Button>
                    </HStack>
                    </Stack>
              <Stack flexDirection={{base:"column",md:"row"}}>
              <HStack width={{base:"100%",md:"60%"}} pb="2" gap={"10px"} wrap={"wrap"}>
                {item.primaryButton &&
                  item.primaryButton.map((it) => (
                    <Button
                      className={`keyword-btn ${
                        it.link ? `primary-color` : ``
                      }`}
                      marginInlineStart={"0rem!important"}
                      onClick={() => navigate(`${it.link}`)}
                    >
                      {it.name}
                    </Button>
                  ))}
                {item.secondaryButton &&
                  item.secondaryButton.map((it) => (
                    // This className need for future use "secondary-color"
                    <Button
                      className={`keyword-btn ${
                        it.link ? `primary-color` : ``
                      }`}
                      onClick={() => navigate(`${it.link}`)}
                      marginInlineStart={"0rem!important"}
                    >
                      {it.name}
                    </Button>
                  ))}
                {item.button8 && (
                  <Button
                    className="keyword-btn blob-button"
                    marginInlineStart={"0rem!important"}
                  >
                    {item.button8}
                  </Button>
                )}
                {item.keyWordButtons &&
                  item.keyWordButtons.map((it) => (
                    <Button
                      className={`keyword-btn ${
                        it.link ? `keyword-color` : ``
                      }`}
                      marginInlineStart={"0rem!important"}
                      onClick={() => navigate(`${it.link}`)}
                    >
                      {it.name}
                    </Button>
                  ))}
              </HStack>
                  <HStack marginInlineStart={"0rem!important"} flexDir={"row"}>
                  {item.categories.length>0 && 
                  item.categories.map((it,index)=>
                  {
                  return(<>
                  {it.link ?
                  <Link to={`${it.link}`}>
                  <Text
                    marginInlineStart={"0rem!important"}
                    fontSize={"12px"}
                    fontWeight={"500"}
                    lineHeight={"30px"}
                    color={"#0066B3"}
                  >
                    {` ${index===item.categories.length-1 ? ` ` + it.rc_name: ` `+ it.rc_name + ` |`} `}
                  </Text>
                  </Link>
                  :
                  <Text
                    marginInlineStart={"0rem!important"}
                    fontSize={"12px"}
                    fontWeight={"500"}
                    lineHeight={"30px"}
                    color={"#0066B3"}
                  >
                    {` ${index===item.categories.length-1 ? " " + it.rc_name: it.rc_name + ` | `} `}
                  </Text>
                }
                  </>
                  )})}
                </HStack>
              </Stack>
              <InsightPageSlider />
              <Stack
                gap="21px"
                paddingY={{base:"60px",md:"0"}}
                marginInlineStart={"0.0rem!important"}
                flexDirection={{base:"column-reverse",md:"row"}}
                alignItems={{base:"center",md:"flex-start"}}
              >
                <HStack
                  alignItems={"flex-start"}
                  width={{base:"100%",md:"762px"}}
                  py="4"
                  px="0"
                  flexDirection={"column"}
                >
                  
                  {showContent && (
                    <Box>
                      <Text
                        textStyle={"primary"}
                        fontSize={"20px"}
                        fontWeight={"500"}
                        color={"black"}
                        lineHeight={"23px"}
                        pb="2"
                      >
                        {item.subtitle1}
                      </Text>
                      <Text
                        textStyle={"primary"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        color={"black"}
                      >
                        {item.description1}
                      </Text>
                      <Text
                        textStyle={"primary"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        py="2"
                        color={"black"}
                      >
                        {item.description2}
                      </Text>
                      <Text
                        textStyle={"primary"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        color={"black"}
                      >
                        {item.description3}
                        <Button
                      color={"#035DA1"}
                      variant={"unstyled"}
                      fontSize={"16px"}
                      fontWeight={"700"}
                      onClick={()=>setShowContent(false)}
                    >
                      {common.READ_LESS}
                    </Button>
                      </Text>
                    </Box>
                  )}
                  
                </HStack>
                <Stack
                  width={{base:"100%",md:"355px"}}
                  flexDirection={"column"}
                  justifyContent={"flex-start"}
                  marginTop={{base:"0px!important", md:"-80px!important"}}
                  zIndex={999}
                >
                  <HStack
                    gap={{base:0,md:1}}
                    alignItems={"flex-start"}
                    flexDirection={{base:"column",md:"column"}}
                    marginInlineStart={"0rem!important"}
                  >
                     <Accordion
                        width={{base:"355px",md:"355px"}}
                        className="ml-0"
                        allowToggle
                        borderColor={"transparent"}
                      >
                        <AccordionItem>
                          <Text fontSize={"16px"}>
                            <AccordionButton
                              as="text"
                              p="0"
                              variant="unstyled"
                              justifyContent={"space-between"}
                            >
                              <Box
                                fontSize={"16px"}
                                fontWeight={"400"}
                                textAlign="left"
                              >
                                {common.E_READING}
                              </Box>
                              <AccordionIcon fontSize={"34px"} />
                            </AccordionButton>
                          </Text>
                          {insightEssentialReading.length > 0 &&
                            insightEssentialReading?.map((item, index) => (
                              <AccordionPanel key={index} py="0" px="0" pb={4}>
                                <Stack
                                  alignItems={"flex-start"}
                                  gap={"8px"}
                                  flexDirection={"row"}
                                  py="2"
                                >
                                  <HStack pt="1">
                                    <Checkbox
                                      borderColor={"#616161"}
                                      borderRadius={"0px"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      size={"sm"}
                                      iconColor="white"
                                      cursor="pointer"
                                    />
                                  </HStack>
                                  <HStack pt="1" marginTop={"0rem!important"}>
                                    <Stack
                                      cursor={"pointer"}
                                      onClick={() => toggleBookmark(index)}
                                    >
                                      {isItemBookmarked(index) ? (
                                        <IoBookmarks size={"14px"} />
                                      ) : (
                                        <IoBookmarksOutline size={"14px"} />
                                      )}
                                    </Stack>
                                  </HStack>
                                  <HStack marginTop={"0rem!important"}>
                                    <Text color={"#035DA1"}>
                                      {item.description}
                                    </Text>
                                  </HStack>
                                </Stack>
                              </AccordionPanel>
                            ))}
                        </AccordionItem>
                      </Accordion>
                      <Accordion
                        width={{base:"355px",md:"355px"}}
                        className="ml-0"
                        allowToggle
                        pb="2"
                        borderColor={"transparent"}
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              as="text"
                              p="0"
                              variant="unstyled"
                              justifyContent={"space-between"}
                            >
                              <Box
                                fontSize={"16px"}
                                fontWeight={"400"}
                                textAlign="left"
                              >
                                {" "}
                                {common.S_READING}
                              </Box>
                              <AccordionIcon fontSize={"34px"} />
                            </AccordionButton>
                          </h2>
                          {insightSuggestedReading.length > 0 &&
                            insightSuggestedReading?.map((item, index) => (
                              <AccordionPanel key={index} py="0" px="0" pb={4}>
                                <Stack
                                  alignItems={"flex-start"}
                                  gap={"8px"}
                                  flexDirection={"row"}
                                  py="2"
                                >
                                  <HStack pt="1">
                                    <Checkbox
                                      borderColor={"#616161"}
                                      borderRadius={"0px"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      size={"sm"}
                                      iconColor="white"
                                      cursor="pointer"
                                    />
                                  </HStack>
                                  <HStack pt="1" marginTop={"0rem!important"}>
                                    <Stack
                                      cursor={"pointer"}
                                      onClick={() => suggestingToggleBookmark(index)}
                                    >
                                      {isItemSuggestingBookmarked(index) ? (
                                        <IoBookmarks size={"14px"} />
                                      ) : (
                                        <IoBookmarksOutline size={"14px"} />
                                      )}
                                    </Stack>
                                  </HStack>
                                  <HStack marginTop={"0rem!important"}>
                                    <Text color={"#035DA1"}>
                                      {item.description}
                                    </Text>
                                  </HStack>
                                </Stack>
                              </AccordionPanel>
                            ))}
                        </AccordionItem>
                      </Accordion>
                  </HStack>
                  <HStack className="ml-0" gap="5">
                    <Text
                      as={"button"}
                      fontWeight={"700"}
                      variant={"unstyled"}
                      px="8"
                      py="3"
                      width={"200px"}
                      border={"1px"}
                      bg="#FFC015"
                      onClick={() => setDrawerOpen(true)}
                    >
                      {common.QA_BUTTON}
                    </Text>
                  </HStack>
                </Stack>
              </Stack>
            </Stack>
          ))}
      </Container>
    </Layout>
  );
}
export default InsightDetails;
