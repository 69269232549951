import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { SimpleGrid } from "@chakra-ui/react";
import { TbFoldersOff } from "react-icons/tb";

//Service files
import * as getFilmographyData from "../../../../services/Filmography";
import { CopyPasteService } from "../../../../services/CopyPaste";

//Component files
import H2 from "../../../../components/layouts/Typography/H2";
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import { common, explore, filterAttribute } from "../../../../constants/constants";
import Large from "../../../../components/layouts/Typography/Large";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import { isTransformationNotAllowed } from "../../../../util/isTranformationAllowed";

export function GridView({ masterlist, tableName }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    languages_v: [],
    filmTypes_v: [],
    filmLength_v: [],
    searches_v: [],
  });
  const [apply, setApply] = useState(false);
  const [mockFilterData, setMockFilterData] = useState([]);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "languages":
        selectedArray = filterOptions.languages_v;
        break;
      case "film_type":
        selectedArray = filterOptions.filmTypes_v;
        break;
      case "film_length":
        selectedArray = filterOptions.filmLength_v;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "languages":
        setFilterOptions({
          ...filterOptions,
          languages_v: selectedArray,
        });
        break;
      case "film_type":
        setFilterOptions({
          ...filterOptions,
          filmTypes_v: selectedArray,
        });
        break;
      case "film_length":
        setFilterOptions({
          ...filterOptions,
          filmLength_v: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getFilmographyData.getFilmographyFilterOptions(
        tableName,
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, tableName]);

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  // Fetch data function
  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getFilmographyData.getFilmographyDataByMasterlist(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getFilmographyData.getFilmographyDataByMasterlist(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }
    const data = result || []; // Ensure data is not null or undefined
    setMockFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
      }
      setApply(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const citationFactor = (data) => {
    return data.map((item) => ({
      year:
        item.find((nestedItem) => nestedItem.key === "year_of_film")?.value ||
        null,
      title: item.find((nestedItem) => nestedItem.key === "main_title")?.value,
      desc: `${
        item.find((nestedItem) => nestedItem.key === "alternative_title")
          ?.value || null
      } | ${
        item.find(
          (nestedItem) => nestedItem.key === "title_in_original_language"
        )?.value || null
      }${
        item.find((nestedItem) => nestedItem.key === "language")?.value.length >
        0
          ? ` | ${item
              .find((nestedItem) => nestedItem.key === "language")
              ?.value.map((it) => it.name)
              .join(" | ")}`
          : ""
      }`,
      shortdesc: `${
        item.find((nestedItem) => nestedItem.key === "film_length")?.value
      } | ${
        item.find((nestedItem) => nestedItem.key === "film_type")?.value || null
      } | ${
        item.find((nestedItem) => nestedItem.key === "color")?.value || null
      } | ${
        item.find((nestedItem) => nestedItem.key === "duration_mins")?.value ||
        null
      } | ${
        item.find((nestedItem) => nestedItem.key === "region")?.value || null
      }`,
      image:
        item
          .find((nestedItem) => nestedItem.key === "posterImages")
          ?.value.map((it) => it?.direct_url_of_preview_image) || null,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          await getFilmographyData.getFilmographyDataByMasterlist(
            tableName,
            masterlist
          );

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setMockFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            await getFilmographyData.getFilmographyDataByMasterlist(
              tableName,
              masterlist
            );
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setMockFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
    window.scrollTo(0, 0);
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  return (
    <>
      {mockFilterData?.length !== 0 ? (
        <Stack flexDirection={"row-reverse"}>
          {!showFilterBox && (
            <FilterButton onClick={handleFilterClick} marginRight={"-40px"} />
          )}
          {mockFilterData?.length !== 0 ? (
            <Container maxW="auto" p="0px" marginTop={"0px !important"}>
              <Stack
                flexDirection={"row"}
                gap={4}
                justifyContent="space-between"
                marginTop={"12px"}
              >
                <HStack
                  flexDirection={"column"}
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/1`)
                  }
                >
                  {mockFilterData?.length !== 0 && (
                    <GridVerticalTimeline data={mockFilterData} />
                  )}
                </HStack>
                <HStack
                  alignItems={"flex-start"}
                  marginRight={"-40px!important"}
                  marginTop={"0px!important"}
                >
                  {showFilterBox && (
                    <Box className="box-filterArtHeritage">
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <HStack>
                          <SmallBold
                            as="u"
                            color={"black"}
                            cursor="pointer"
                            onClick={() => {
                              setFilterOptions({
                                languages_v: [],
                                filmLength_v: [],
                                filmTypes_v: [],
                              });
                              setSearchTerm("");
                            }}
                          >
                            {explore.RESET}{" "}
                            <span style={{ color: "#004F79" }}>
                              {" "}
                              {filterCount > 0 && ` (${filterCount})`}
                            </span>
                          </SmallBold>
                        </HStack>
                        <HStack className="mt-0">
                          <RiCloseCircleFill
                            size={"22px"}
                            cursor="pointer"
                            onClick={(e) => setShowFilterBox(false)}
                          />
                        </HStack>
                      </Stack>
                      <HStack
                        alignItems={"left"}
                        flexDirection={"column"}
                        marginTop={"25px"}
                      >
                        <InputGroup className="ml-0" pb="4">
                          <Input
                            placeholder="Search"
                            height={"32px"}
                            pb={"4px"}
                            onChange={handleSearch}
                            _placeholder={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          />
                          <InputRightElement pb={"6px"}>
                            <BiSearch color="green.500" fontSize={"16px"} />
                          </InputRightElement>
                        </InputGroup>
                      </HStack>
                      <Accordion allowToggle allowMultiple>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.CATEGORIES}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel
                            pb={4}
                            className="px-1"
                            height={"144px"}
                            overflowY={"scroll"}
                          >
                            {filteroptionData.categoriesFilter &&
                              filteroptionData.categoriesFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      // isChecked={filterOptions.categories_v.includes(
                                      //   it
                                      // )}
                                      // onChange={() => {
                                      //   handleCheckboxChange("categories", it);
                                      // }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.LANGUAGES}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            {filteroptionData.languageFilter &&
                              filteroptionData.languageFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.languages_v.includes(
                                        it
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("languages", it);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.FILM_TYPE}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            {filteroptionData.filmTypeFilter &&
                              filteroptionData.filmTypeFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.filmTypes_v.includes(
                                        it
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("film_type", it);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}{" "}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.FILM_LENGTH}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            {filteroptionData.filmLengthFilter &&
                              filteroptionData.filmLengthFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.filmLength_v.includes(
                                        it
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("film_length", it);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}{" "}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <HStack marginTop={"10px"}>
                        <Button
                          color={"#ffffff"}
                          onClick={() => {
                            setApply((prev) => !prev);
                            setShowFilterBox(false);
                          }}
                          width={"199px"}
                          height={"36px"}
                          bg={"#000000"}
                          fontSize={"16px"}
                          fontWeight={"400"}
                          variant={"unstyled"}
                        >
                          {explore.APPLY}
                        </Button>
                      </HStack>
                    </Box>
                  )}
                </HStack>
              </Stack>
            </Container>
          ) : (
            <Container
              position={"relative"}
              maxW="auto"
              px="0px"
              className="songs"
              mt={"0px !important"}
            >
              {" "}
              <Stack
                flexDirection={"row"}
                justifyContent="space-between"
                height={"100vh"}
                gap={4}
              >
                <HStack
                  flexDirection={"column"}
                  flex="1"
                  onCopy={(e) => CopyPasteService(e, window.location.href)}
                >
                  {" "}
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <TbFoldersOff style={{ marginRight: "20px" }} />
                    {common.NO_DATA}
                  </Text>
                </HStack>
                <HStack alignItems={"flex-start"} marginTop={"0px!important"}>
                  {showFilterBox && (
                    <Box className="box-filterArtHeritage">
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <HStack>
                          <SmallBold
                            as="u"
                            color={"black"}
                            cursor={"pointer"}
                            onClick={() => {
                              setFilterOptions({
                                languages_v: [],
                                filmLength_v: [],
                                filmTypes_v: [],
                              });
                              setSearchTerm("");
                            }}
                          >
                            {explore.RESET}{" "}
                            <span style={{ color: "#004F79" }}>
                              {" "}
                              {filterCount > 0 && ` (${filterCount})`}
                            </span>
                          </SmallBold>
                        </HStack>
                        <HStack className="mt-0">
                          <RiCloseCircleFill
                            size={"22px"}
                            cursor="pointer"
                            onClick={(e) => setShowFilterBox(false)}
                          />
                        </HStack>
                      </Stack>
                      <HStack
                        alignItems={"left"}
                        flexDirection={"column"}
                        marginTop={"25px"}
                      >
                        <InputGroup className="ml-0" pb="4">
                          <Input
                            placeholder="Search"
                            height={"32px"}
                            pb={"4px"}
                            _placeholder={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                            onChange={handleSearch}
                          />
                          <InputRightElement pb={"6px"}>
                            <BiSearch color="green.500" fontSize={"16px"} />
                          </InputRightElement>
                        </InputGroup>
                      </HStack>
                      <Accordion allowToggle allowMultiple>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.CATEGORIES}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel
                            pb={4}
                            className="px-1"
                            height={"144px"}
                            overflowY={"scroll"}
                          >
                            {filteroptionData.categoriesFilter &&
                              filteroptionData.categoriesFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      // isChecked={filterOptions.categories_v.includes(
                                      //   it
                                      // )}
                                      // onChange={() => {
                                      //   handleCheckboxChange("categories", it);
                                      // }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.LANGUAGES}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            {filteroptionData.languageFilter &&
                              filteroptionData.languageFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.languages_v.includes(
                                        it
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("languages", it);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.FILM_TYPE}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            {filteroptionData.filmTypeFilter &&
                              filteroptionData.filmTypeFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.filmTypes_v.includes(
                                        it
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("film_type", it);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}{" "}
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {filterAttribute.FILM_LENGTH}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            {filteroptionData.filmLengthFilter &&
                              filteroptionData.filmLengthFilter.map((it) => {
                                return (
                                  <Flex
                                    borderBottom={"1px"}
                                    borderBottomColor={"#f1f1f1"}
                                  >
                                    <Checkbox
                                      borderColor={"black"}
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                      isChecked={filterOptions.filmLength_v.includes(
                                        it
                                      )}
                                      onChange={() => {
                                        handleCheckboxChange("film_length", it);
                                      }}
                                    />
                                    <Box p="1">
                                      <Text fontSize={"14px"}>{it}</Text>
                                    </Box>
                                  </Flex>
                                );
                              })}{" "}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <HStack marginTop={"10px"}>
                        <Button
                          // as="u"
                          color={"#ffffff"}
                          onClick={() => {
                            setApply((prev) => !prev);
                            setShowFilterBox(false);
                          }}
                          width={"199px"}
                          height={"36px"}
                          bg={"#000000"}
                          fontSize={"16px"}
                          fontWeight={"400"}
                          variant={"unstyled"}
                        >
                          {explore.APPLY}
                        </Button>
                      </HStack>
                    </Box>
                  )}
                </HStack>
              </Stack>
            </Container>
          )}
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.LOADING}
          </Text>
        </Stack>
      )}
    </>
  );
}

const GridVerticalTimeline = ({ data }) => {
  let currentYear = null;
  let sameYearElements = [];
  const dummyImg="https://asvs.in/wp-content/uploads/2017/08/dummy.png";
  const renderGridElements = () => {
    return data.map((item, index) => {
      const isNewYear = currentYear === null || currentYear !== +item?.year;
      let elementsToRender;
      if (isNewYear) {
        if (sameYearElements.length > 0) {
          elementsToRender = (
            <>
              <VerticalTimelineElement
                id="timeline2"
                contentStyle={{ fontSize: "sm" }}
                iconStyle={{
                  background: "#fff",
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                icon={currentYear}
              >
                <SimpleGrid columns={1} spacing={5}>
                  {sameYearElements}
                </SimpleGrid>
              </VerticalTimelineElement>
              {data[data.length - 1]?.year === item?.year && (
                <VerticalTimelineElement
                  id="timeline2"
                  contentStyle={{ fontSize: "sm" }}
                  iconStyle={{
                    background: "#fff",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                  icon={item?.year}
                >
                  <SimpleGrid columns={1} spacing={5}>
                    <Box pb="4">
                      <Image
                        src={
                          item?.image?.length > 0
                            ? item?.image[0]
                            : dummyImg
                        }
                        alt=""
                        width={"471px"}
                        height={"234px"}
                        objectFit={"cover"}
                        className={"img-border-radius"}
                      ></Image>

                      {item?.title && <H2 pt="2">{` ${item?.title} `}</H2>}
                      <Large>{item?.desc}</Large>
                    </Box>
                  </SimpleGrid>
                </VerticalTimelineElement>
              )}
            </>
          );
          sameYearElements = [];
        }

        currentYear = +item?.year;
      }
      sameYearElements.push(
        <>
          <Box pb="4">
            <Image
              src={
                item?.image?.length > 0
                  ? item?.image[0]
                  : dummyImg
              }
              alt=""
              width={"471px"}
              height={"234px"}
              objectFit={"cover"}
              className={"img-border-radius"}
            ></Image>

            {item?.title && <H2 pt="2">{` ${item?.title} `}</H2>}
            <Large>{item?.desc}</Large>
          </Box>
          <br />
        </>
      );
      return elementsToRender;
    });
  };

  return (
    <VerticalTimeline layout={"1-column-left"} className="layout">
      {renderGridElements()}
    </VerticalTimeline>
  );
};
