import React from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../styles.css";

// import required modules
import {
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Stack,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import Layout from "../components/sections/SidebarLayout";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

function SongPage(props) {
  const { id } = useParams();
  const [song, setSong] = React.useState({});

  React.useEffect(() => {
    const getData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/rest/songs`
      );
      if (result?.data?.songs) {
        const dataSongs = result?.data?.songs;
        const dataSong = dataSongs.find((e) => e.id === +id);
        setSong(dataSong);
      }
    };

    getData();
    return () => {};
  }, [id]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Stack m="5" pr={"8"} pl={"8"} className="mobileviewpadding">
        <Container maxW="auto" className="songs">
          <Box pt="5" pb="5">
            <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink href="/" style={{ textDecoration: "none" }}>
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href="/songs/all"
                  style={{ textDecoration: "none" }}
                >
                  All Song's
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href="" style={{ textDecoration: "none" }}>
                  {song?.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box>
          <Stack>
            <Box
              maxW={"auto"}
              w={"full"}
              boxShadow={"sm"}
              p={4}
              overflow={"hidden"}
            >
              <Box
                h={"auto"}
                bg={"white"}
                mt={-6}
                mx={-6}
                mb={6}
                pos={"relative"}
              >
                <AspectRatio w="100%" ratio={16 / 9}>
                  <iframe
                    title="All Songs"
                    // src={"https://www.youtube.com/embed/w_BmHDD8rSM"}
                    src={
                      song?.youtube_link
                        ? song?.youtube_link.replace("watch?v=", "embed/") +
                          "?autoplay=1"
                        : "https://www.youtube.com/embed/w_BmHDD8rSM"
                    }
                    allowFullScreen
                  />
                </AspectRatio>
              </Box>
              <Stack>
                <Box w="100%">
                  <Text fontSize={"2xl"} fontWeight={"700"} pt="4">
                    {song?.name}
                  </Text>
                </Box>
                <Box w="100%">
                  <Text pt="1"> {song?.song_code}</Text>
                </Box>
                <Box w="100%">
                  <Text pt="1"> {song?.film_id?.name}</Text>
                </Box>
                <Box w="100%">
                  <Text pt="1"> {song?.date}</Text>
                </Box>
              </Stack>
              {/* <Stack mt={2} direction={"row"} spacing={4} align={"center"}>
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text fontWeight={600}>{song?.film_id?.name}</Text>
                  <Text color={"gray.500"}>{song?.date}</Text>
                </Stack>
              </Stack> */}
            </Box>
          </Stack>
        </Container>
      </Stack>
    </Layout>
  );
}

export default SongPage;
