import { Box, Image, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const dot = `${IMAGE_BASE_PATH}/ui/Chevron/TimelineEnd.png`

export default function TeachingDrawerSelfIntro({
  techingBuilding,
  showFamilyMemberImages,
  teachingData,
}) {
  const [categorizedData, setCategorizedData] = useState({});

  const categorizeData = () => {
    const categorized = {};
    teachingData.forEach((item) => {
      const { post } = item;
      const key = post;
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(item);
    });
    setCategorizedData(categorized);
  };

  React.useEffect(() => {
    categorizeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {categorizedData &&
        Object.entries(categorizedData).map(([category, items]) => {
          return (
            <Stack key={category} marginLeft={"10px"}>
              <Text
                fontSize={"18px"}
                fontWeight={500}
                pt={"16px"}
                pb={"12px"}
                lineHeight={"21px"}
              >
                {category}
              </Text>

              <VerticalTimeline layout={"1-column-left"}>
                {items &&
                  items.map((item, index) => {
                    return (
                      <>
                        {item?.image && (
                          <Box pos={"relative"} right={"20px"} top={"23px"}>
                            <AiOutlinePicture
                              onClick={() =>
                                showFamilyMemberImages(item?.image, true)
                              }
                              cursor={"pointer"}
                              color="#1E90FF"
                              size={18}
                            />
                          </Box>
                        )}

                        <VerticalTimelineElement
                          id="timeline1"
                          contentStyle={{ fontSize: "sm" }}
                          iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                            maxWidth: "min-content",
                          }}
                          icon={
                            item.display_date === "NA" ? (
                              <Image
                                marginLeft={"13px"}
                                marginTop={"8px"}
                                src={dot}
                              ></Image>
                            ) : (
                              item.display_date
                            )
                          }
                        >
                          <Text cursor={"pointer"} color="black">
                            {item.value}
                          </Text>
                        </VerticalTimelineElement>
                      </>
                    );
                  })}
              </VerticalTimeline>
            </Stack>
          );
        })}
    </>
  );
}
