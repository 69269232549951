import { Stack } from "@chakra-ui/react";

//Core file
import React from "react";

//Service file
import { getBibliographyDummyData } from "../../../services/Bibliography";

//Component file
import GridView from "./GridView";
import TabularView from "./TabularView";

export const ResearchCentreBib = ({
  visible,
  handleBookClick,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
}) => {
  const [researchData, setResearchData] = React.useState([]);
  const [tabulardata, setTabulardata] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getBibliographyDummyData("grid");
      const researchResult = await getBibliographyDummyData("list");
      if (result && researchResult) {
        const data = result;
        setResearchData(data);
        setTabulardata(researchResult);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack position={"relative"} top={"10%"}>
      {visible ? (
        <>
          <GridView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            data={researchData}
            handleBookClick={handleBookClick}
            setleftFlag={setleftFlag}
          />
        </>
      ) : (
        <>
          <TabularView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            data={tabulardata}
            handleBookClick={handleBookClick}
            setleftFlag={setleftFlag}
          />
        </>
      )}
    </Stack>
  );
};
