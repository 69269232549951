import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { ResearchCentreBib } from "./ResearchCentre/ResearchCentreBib";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import MobileNav from "../../components/Topheader";
import ReadMore from "../Film/ReadMore";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import { Book } from "./ResearchCentre/Book";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language file
import { explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`

export default function ResearchCentre({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [researchCentreFlag, setResearchCentreFlag] = React.useState(true);
  const [leftFlag, setleftFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bookFlag, setBookFlag] = useState(false);
  const [bookReadmore, setBookReadmore] = useState(false);
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "journal",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "journal_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    window.scrollTo(0, 0);
  }, [masterlistType, aliasAPI]);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        leftFlag={leftFlag}
        bookFlag={bookFlag}
        openDrawer={openDrawer}
        setBookFlag={setBookFlag}
        researchCentreFlag={researchCentreFlag}
        bookReadmore={bookReadmore}
        setBookReadmore={setBookReadmore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        introData={introData}
        masterlistType={masterlistType}
        name={name}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            leftFlag={leftFlag}
            bookFlag={bookFlag}
            openDrawer={openDrawer}
            setBookFlag={setBookFlag}
            researchCentreFlag={researchCentreFlag}
            bookReadmore={bookReadmore}
            setBookReadmore={setBookReadmore}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
            masterlistType={masterlistType}
            name={name}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <ResearchCentreBody
          setResearchCentreFlag={setResearchCentreFlag}
          setleftFlag={setleftFlag}
          bookFlag={bookFlag}
          openDrawer={openDrawer}
          setBookFlag={setBookFlag}
          setOpenDrawer={setOpenDrawer}
          bookReadmore={bookReadmore}
          setBookReadmore={setBookReadmore}
          aliasAPI={aliasAPI}
          horislinkData={horislinkData}
          journalDetails={journalDetails}
          introData={introData}
          name={name}
          
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  leftFlag,
  researchCentreFlag,
  openDrawer,
  bookFlag,
  setBookFlag,
  bookReadmore,
  setBookReadmore,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (bookFlag) {
      if (bookReadmore) {
        setBookReadmore(false);
      } else {
        setBookFlag(false);
      }
    } else {
      navigate(-1);
    }
  };
  const drawerArtHeritage = [{
title:"Ebrahim Alkazi", name: "Art Heritage Annual & Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Webpage coming soon",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const [drawerName, setDrawerName] = useState("")

   const artistLinksData = [{
  name: "Ebrahim Alkazi",
 
},{
    name: "Art Heritage Gallery",
}

];;
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        leftFlag={leftFlag}
        researchCentreFlag={researchCentreFlag}
        openDrawer={openDrawer}
        bookFlag={bookFlag}
        setBookFlag={setBookFlag}
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        artistLinksData={artistLinksData}
        setDrawerName={setDrawerName}
        drawerName={drawerName}     
        handleNavigation={handleNavigation}
        drawerData={drawerArtHeritage}
      />
    </>
  );
};

const ResearchCentreBody = ({
  setleftFlag,
  setResearchCentreFlag,
  openDrawer,
  setOpenDrawer,
  bookFlag,
  setBookFlag,
  bookReadmore,
  setBookReadmore,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType,
}) => {
  const [visible, setVisible] = React.useState(true);
  const [bookData, setBookData] = useState({});
  const [listGridIcon, setListGridIcon] = useState(true);

  const navigate = useNavigate();
  let { tabIndex, gridview } = useParams();
  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${aliasAPI}`, { replace: true });

      if (parseInt(tabIndex) === 1) {
        setListGridIcon(true);
      }
      if (parseInt(gridview) === 0) {
        return setVisible(false);
      }
      setVisible(true);
    }
  }, [tabIndex, navigate, gridview, masterlistType, aliasAPI]);

  const handleBookClick = (flag, data) => {
    setBookData(data);
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack width={{ base: "100%", md: "1160px" }}>
      <Container
        minW={"100%"}
        pb="4"
        px="0px"
        marginLeft={{ base: "0px", md: "30px" }}
      >
        <Stack>
          {bookFlag ? (
            <>
              {bookReadmore ? (
                <ReadMore />
              ) : (
                <Book
                  bookData={bookData}
                  bookReadmore={bookReadmore}
                  setBookReadmore={setBookReadmore}
                />
              )}
            </>
          ) : (
            <>
              <Box
                position={"fixed"}
                zIndex={999}
                bg="white"
                width={{ base: "100%", md: "1150px" }}
              >
                <HStack py={"30px"}></HStack>
              </Box>
              <HStack>
                <Tabs
                  align={{ xs: "end", sm: "" }}
                  w="full"
                  defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 1}
                >
                  <Stack
                    flexDirection={"row-reverse"}
                    display={"flex"}
                    marginTop={"32px"}
                  >
                    <Box
                      position={"fixed"}
                      zIndex={999}
                      bg="white"
                      width={{ base: "100%", md: "1160px" }}
                    >
                      <TabList
                        overflowX="auto"
                        borderBottom={"1px"}
                        borderBottomColor={"#f1f1f1"}
                        gap={12}
                        className="verticalscroll"
                      >
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"md"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                          onClick={() => setListGridIcon(false)}
                        >
                          {explore.ALL}
                        </Tab>
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"md"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                          onClick={() => setListGridIcon(true)}
                        >
                          {explore.LIBRARY}
                        </Tab>
                        <Tab
                          className="Timeline"
                          p="0"
                          color="#333333"
                          fontSize={"md"}
                          fontWeight={"400"}
                          _selected={{
                            borderBottomWidth: "6px",
                            color: "black",
                            borderBottomColor: "#333333",
                            fontWeight: "700",
                          }}
                          onClick={() => setListGridIcon(true)}
                        >
                          {explore.ARCHIVE}
                        </Tab>
                      </TabList>
                    </Box>

                    {listGridIcon && (
                      <Stack
                        flexDirection={"row"}
                        gap={0}
                        zIndex={999}
                        id="listgridviewbtn"
                        marginRight={"20px !important"}
                        marginTop={"-16px !important"}
                        position={"fixed"}
                      >
                        <HStack marginTop={"0px!important"}>
                          <IconButton
                            variant="unstyled"
                            onClick={() => setVisible(false)}
                            color={!visible ? "black" : "#989898"}
                            fontSize="20px"
                            display="flex"
                            borderTopLeftRadius="6px"
                            borderBottomLeftRadius="6px"
                            borderTopRightRadius="0px"
                            borderBottomRightRadius="0px"
                            border="1px"
                            borderColor={"#989898"}
                            height="32px"
                          >
                            <RiMenuLine ml="4" />
                          </IconButton>
                        </HStack>
                        <HStack marginTop={"0px!important"}>
                          <IconButton
                            variant="unstyled"
                            onClick={() => setVisible(true)}
                            color={visible ? "black" : "#989898"}
                            fontSize="16px"
                            display="flex"
                            borderTopLeftRadius="0px"
                            borderBottomLeftRadius="0px"
                            borderTopRightRadius="6px"
                            borderBottomRightRadius="6px"
                            border="1px"
                            borderColor={"#989898"}
                            height="32px"
                          >
                            <BsGrid />
                          </IconButton>
                        </HStack>
                      </Stack>
                    )}
                  </Stack>
                  <TabPanels>
                    <TabPanel px="0px" pb={"80"}>
                      <WebpageComingSoon/>
                    </TabPanel>
                    <TabPanel px="0px">
                      <ResearchCentreBib
                        visible={visible}
                        handleBookClick={handleBookClick}
                        setleftFlag={setleftFlag}
                        openDrawer={openDrawer}
                        setOpenDrawer={setOpenDrawer}
                      />
                    </TabPanel>
                    <TabPanel px="0px" pb={"80"}>
                      {visible ? (
                           <WebpageComingSoon/>
                      ) : (
                        <WebpageComingSoon/>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </HStack>
            </>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
