import { 
  Box, 
  Container, 
  HStack, 
  Image,
  Stack, 
  Text } from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

//Service file
import { getMasterListsByResearchCategoryAlias } from "../../../services/ResearchCategoryDetails";

function Masterlist({rcAlias}) {
  const [masterlistsData, setMasterlistsData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await getMasterListsByResearchCategoryAlias("", rcAlias);
      if (result) {
        setMasterlistsData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [rcAlias]);

  return (
    <Stack>
    <Container px="0" maxW="auto" className="songs">
      <Stack
      flexDirection={"row"} gap={4} justifyContent="center">
        <HStack width={"100%"} position={"relative"} id="allslider"  pt={{ base: "4", md: "8" }}>
            <Swiper
            slidesPerView={1}
            spaceBetween={10}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            modules={[Navigation]}
            className="mySwiper"
            bg="white"
          >
           {masterlistsData.length > 0 &&
              masterlistsData?.map((it, index) => {
                return (
              <SwiperSlide key={index}>
                 <Stack className="content">
                    <Link to={it.link ? it.link:`/research-categories/${rcAlias}/masterlists/${it?.alias}`}>
                   <Stack className="content-overlay"></Stack>
                   <Box width={{base:"100%",md:"100%"}} height={"292px"} display={"flex"} alignItems={"center"}>
                  <Image
                      src={it?.image}
                      className="content-image"
                      height="auto"
                      width="100%"
                    /></Box>
                    <Stack className="content-details fadeIn-bottom" alignItems={"center"}>
                        <Text
                          fontSize={"24px"}
                          fontWeight={"700"}
                          textStyle={"scondary"}
                          color={"white"}
                        >
                          {it?.name}
                        </Text>
                      </Stack>
                  </Link>
                  </Stack>
              </SwiperSlide>
            );
          })}
          </Swiper>
        </HStack>
      </Stack>
    </Container>
  </Stack>
  );
}
export default Masterlist;
