import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";

export default function EducationDrawerSelfIntro({
  showEducationImages,
  data
}) {
  return (
    <>
        <Stack >
          {data.length > 0 && (
            <>
              <HStack py={"2"} flexDirection={"row"} marginLeft={"10px"}>
                <Stack>
                  {data.map((education, educationIndex) => {
                    const imageObject = education?.image
                      ? education?.image
                      : null;
                    // const imageUrl = imageObject?.image
                    //   ? imageObject?.image
                    //   : null;
                    return (
                      <>
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          key={educationIndex}
                          marginTop={"0px !important"}
                        >
                          {education?.year && <> {education?.year}</>}
                        </Text>
                        <HStack
                          marginLeft={
                            imageObject ? "-25px!important" : "0px!important"
                          }
                          alignItems={"flex-start"}
                        >
                          {imageObject && (
                            <Box pt="1">
                              <AiOutlinePicture
                                onClick={() =>
                                  showEducationImages(imageObject, true)
                                }
                                cursor={"pointer"}
                                color="#1E90FF"
                                size={18}
                              />
                            </Box>
                          )}
                          <Text
                            fontSize={"16px"}
                            fontWeight={"400"}
                            width={"280px"}
                            key={educationIndex}
                          >
                            {education?.value}
                          </Text>
                        </HStack>
                        <br />
                      </>
                    );
                  })}
                </Stack>
              </HStack>
            </>
          )}
        </Stack>
    </>
  );
}
