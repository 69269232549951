
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Image,
  Container,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Divider,
  AccordionPanel,
  VStack,
  Flex,
} from "@chakra-ui/react";

//Core files
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

//Services files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component files
import MobileNav from "../../components/Topheader";
import Large from "../../components/layouts/Typography/Large";
import H2 from "../../components/layouts/Typography/H2";
import ReadMore from "../PersonalityPolymath/ReadMore";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language files
import { artheritage, explore } from "../../constants/constants";
import { isArray } from "lodash";
import H4 from "../../components/layouts/Typography/H4";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import DrawerFamilyDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerFamilyDetails";
import DrawerModalDetails from "../../FunctionalComponents/Personality_Introduction/Drawer/Personalia/DrawerModalDetails";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const dot = `${IMAGE_BASE_PATH}/ui/Chevron/TimelineEnd.png`

export default function Introduction({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const {alias}=useParams();
  const aliasAPI = alias;
  const masterlistType="person-poly"
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});
  
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }`,
            title:it?.find((item) => item?.key === "landing_page_abbreviation")?.value
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <IntroductionBody
          setTitle={setTitle}
          setDate={setDate}
          setDateDod={setDateDod}
          readMoreText={readMoreText}
          setReadMore={setReadMore}
          aliasAPI={aliasAPI}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };

  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CommonLeftSidebar
        sections={introData}
        oSections={data.oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        backTo={data.backNavigation}
        handleNavigation={handleNavigation}
        leftFlag={true}
        readMoreText={readMoreText}
        aliasAPI={aliasAPI}
      />
    </>
  );
};

const IntroductionBody = ({
  setTitle,
  setDate,
  setDateDod,
  readMoreText,
  setReadMore,
  aliasAPI,
}) => {

  const [educationFullScreenImage, setEducationFullScreenImage] =
    useState(false);
  const [educationImgData, setEducationImgData] = useState(null);
  const [familyFullScreenImage, setFamilyFullScreenImage] = useState(false);
  const [familyImgData, setFamilyImgData] = useState(null);
  const [contentAwaited, setContentAwaited] = useState(false);
  const masterlistTitle = aliasAPI;

  const [introData, setIntroData] = useState([{}]);
  const [personaliaData, setPersonaliaData] = useState([]);
  const [teachingData, setTeachingData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [masterlistImage, setMasterListImage] = useState("");

  const showEducationImages = (images, flag) => {
    if (images) {
      setEducationImgData(images);
    } else {
      setEducationFullScreenImage(flag);
      setEducationImgData(null);
    }
  };

  const showFamilyMemberImages = (images, flag) => {
    if (images) {
      setFamilyImgData(images);
    } else {
      setFamilyFullScreenImage(flag);
      setFamilyImgData(null);
    }
  };

  const showFullImages = (flag) => {
    setFamilyFullScreenImage(flag);
  };


  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        masterlistTitle
      );
      const personalia = await getIntroductionData.getPersonaliaByAlias(
        "personality",
        masterlistTitle
      );
      const education = await getIntroductionData.getEducationByAlias(
        "personality",
        masterlistTitle
      );
      const teaching = await getIntroductionData.getTeachingInfraByAlias(
        "personality",
        masterlistTitle
      );

      if (result) {
        setContentAwaited(false);
        setMasterListImage(
          result[0].find((item) => item?.key === "image")?.value
        );
        setIntroData([
          {
            name: result[0].find((item) => item?.key === "full_name")?.value,
            date_of_birth: result[0].find(
              (item) => item?.key === "date_of_birth"
            )?.value,
            date_of_death: result[0].find(
              (item) => item?.key === "date_of_death"
            )?.value,
            description: result[0].find((item) => item?.key === "description")
              ?.value,
          },
        ]);
      } else {
        setContentAwaited(true);
      }

      if (personalia) {
        const dataIntro = personalia;
        setPersonaliaData(dataIntro);
      }

      if (education) {
        const dataIntro = education;
        setEducationData(dataIntro);
      }
      if (teaching) {
        const dataIntro = teaching;
        setTeachingData(categorizeData(dataIntro));
      }
    };
    getIntroData();
  }, [masterlistTitle]);

  const categorizeData = (data) => {
    const categorized = {};
    data.forEach((item) => {
      const { post } = item;
      const key = post;
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(item);
    });
    return categorized;
  };

  return (
    <>
      {readMoreText ? (
        <ReadMore />
      ) : (
        <>
          <Stack>
            {contentAwaited ? (
              <Text
                py={"370px"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                Content Awaited
              </Text>
            ) : (
              <>
              <Container maxW="auto" px="0" className="songs">
                <Stack
                  height="calc(100vh - 64px)"
                  position={"relative"}
                  flexDirection={{base:"column",md:"row"}}
                >
                  <HStack px="0" className="col-md-8 col-sm-12">
                  <Image src={masterlistImage} width={"100%"} height={"100%"} objectFit={'cover'} />
                  </HStack>
                  <HStack zIndex={"99"} alignItems="flex-start" overflowY={"scroll"} borderLeft={"1px"} borderColor={"#e8e8e8"} className="col-md-4 col-sm-12 mt-0">
                  <Box width={{ base: "100%", md: "392px" }}>
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {artheritage.PREFACE}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <Box>
                  <AccordionPanel py={2} className="px-0">
                    <Text
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color="black"
                      lineHeight={"16px"}
                      textAlign={"justify"}>{introData[0]?.description}</Text>
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                        {explore.PERSONALIA}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel className="acc-pannel-ht" overflowY={"scroll"} py={2} px={"4"}>
                      <VStack align="start" spacing={2}>
                        <Flex align="center" py={3}>
                          <Text
                            fontSize={"16px"}
                            fontWeight={"500"}
                            color={"#000000"}
                          >
                          {personaliaData &&
              personaliaData?.map((item, index) => {
                return (
                  <Stack key={index} marginLeft={"10px"}>
                    <HStack
                      py={"2"}
                      flexDirection={"row"}
                      marginTop={"0px !important"}
                    >
                      <Stack>
                        <H4>{item?.display}</H4>
                        {isArray(item?.value) ? (
                          item?.value?.map((member, memberIndex) => {
                            const imageUrl =
                              member?.image !== "undefined" &&
                              member?.image !== null
                                ? member?.image
                                : null;

                            return (
                              <React.Fragment key={memberIndex}>
                                <HStack
                                  marginLeft={
                                    imageUrl
                                      ? "-25px!important"
                                      : "0px!important"
                                  }
                                  alignItems={"flex-start"}
                                >
                                  {imageUrl && (
                                    <Box pt="1">
                                      <AiOutlinePicture
                                        onClick={() =>
                                          showFamilyMemberImages(imageUrl, true)
                                        }
                                        cursor={"pointer"}
                                        color="#1E90FF"
                                        size={18}
                                      />
                                    </Box>
                                  )}
                                  <Text fontSize={"16px"} fontWeight={"400"}>
                                    {member?.value?.charAt(0).toUpperCase() +
                                      member?.value?.slice(1)}
                                    <br />
                                    {member?.birth_date &&
                                      member?.death_date && (
                                        <>
                                          (
                                          {dateFormat(
                                            member?.birth_date,
                                            "d mmm yyyy"
                                          )}{" "}
                                          -{" "}
                                          {dateFormat(
                                            member?.death_date,
                                            "d mmm yyyy"
                                          )}
                                          )
                                        </>
                                      )}
                                  </Text>
                                </HStack>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <>
                            <HStack
                              pb={"2"} pt="0"
                              flexDirection={"row"}
                              marginTop={"0px !important"}
                            >
                              <Stack>
                                <Text fontSize={"16px"} fontWeight={"400"}>
                                  {item.key === "date_of_birth" ||
                                  item.key === "date_of_death" ||
                                  item.key === "marriageregistrationdate"
                                    ? item.value === "" ? "" : dateFormat(item?.value, "d mmm yyyy")
                                    : item?.value}
                                </Text>
                              </Stack>
                            </HStack>
                          </>
                        )}
                      </Stack>
                    </HStack>
                  </Stack>
                );
              })}
                          </Text>
                        </Flex>
                        <Divider
                          color={"#B5B5B5"}
                          borderBottomWidth={"0.1px"}
                        />
                      </VStack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {explore.EDUCATION}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel height={"auto"} py={2} px={"4"}>
                <>
              <Stack>
                {educationData.length > 0 && (
                  <>
                    <HStack  pb={"2"} pt="0" flexDirection={"row"} marginLeft={"10px"}>
                      <Stack>
                        {educationData.map((education, educationIndex) => {
                          const imageObject = education?.image
                            ? education?.image
                            : null;
                          // const imageUrl = imageObject?.image
                          //   ? imageObject?.image
                          //   : null;
                          return (
                            <>
                              <Text
                                fontSize={"16px"}
                                fontWeight={"700"}
                                key={educationIndex}
                                marginTop={"0px !important"}
                              >
                                {education?.year && <> {education?.year}</>}
                              </Text>
                              <HStack
                                marginLeft={
                                  imageObject
                                    ? "-25px!important"
                                    : "0px!important"
                                }
                                alignItems={"flex-start"}
                              >
                                {imageObject && (
                                  <Box pt="1">
                                    <AiOutlinePicture
                                      onClick={() =>
                                        showEducationImages(imageObject, true)
                                      }
                                      cursor={"pointer"}
                                      color="#1E90FF"
                                      size={18}
                                    />
                                  </Box>
                                )}
                                <Text
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                  width={"280px"}
                                  key={educationIndex}
                                >
                                  {education?.value}
                                </Text>
                              </HStack>
                              <br />
                            </>
                          );
                        })}
                      </Stack>
                    </HStack>
                  </>
                )}
              </Stack>
            </>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                    {explore.TEACHING_INFRA_B}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel className="acc-pannel-ht" overflowY={"scroll"} py={2} px={"4"}>
                <>
              {teachingData &&
                Object.entries(teachingData).map(([category, items]) => {
                  return (
                    <Stack key={category} marginLeft={"10px"}>
                      <Text
                        fontSize={"18px"}
                        fontWeight={500}
                        pt={"16px"}
                        pb={"12px"}
                        lineHeight={"21px"}
                      >
                        {category}
                      </Text>
                      <VerticalTimeline layout={"1-column-left"}>
                        {items &&
                          items.map((item, index) => {
                            return (
                              <>
                                {item?.image && (
                                  <Box
                                    pos={"relative"}
                                    right={"20px"}
                                    top={"23px"}
                                  >
                                    <AiOutlinePicture
                                      onClick={() =>
                                        showFamilyMemberImages(
                                          item?.image,
                                          true
                                        )
                                      }
                                      cursor={"pointer"}
                                      color="#1E90FF"
                                      size={18}
                                    />
                                  </Box>
                                )}

                                <VerticalTimelineElement
                                  id="timeline1"
                                  contentStyle={{ fontSize: "sm" }}
                                  iconStyle={{
                                    background: "#fff",
                                    color: "black",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    maxWidth: "min-content",
                                  }}
                                  icon={
                                    item.display_date === "NA" ? (
                                      <Image
                                        marginLeft={"13px"}
                                        marginTop={"8px"}
                                        src={dot}
                                      ></Image>
                                    ) : (
                                      item.display_date
                                    )
                                  }
                                >
                                  <Text cursor={"pointer"} color="black">
                                    {item.value}
                                  </Text>
                                </VerticalTimelineElement>
                              </>
                            );
                          })}
                      </VerticalTimeline>
                    </Stack>
                  );
                })}
            </>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
                  </HStack>
                </Stack>
              </Container>
              <DrawerFamilyDetails
        familyImgData={familyImgData}
        educationImgData={educationImgData}
        showFamilyMemberImages={showFamilyMemberImages}
        showEducationImages={showEducationImages}
        showFullImages={showFullImages}
        closeIcon={closeIcon}
        educationFullScreenImage={educationFullScreenImage}
      />
      <DrawerModalDetails
        familyImgData={familyImgData}
        educationImgData={educationImgData}
        showFullImages={showFullImages}
        familyFullScreenImage={familyFullScreenImage}

      />
              <Stack display={{base:"flex",md:"none"}}>
              <BottomMainMenu/>
              </Stack>
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export function ArtistDetails({ artistDetails }) {
  return (
    <>
      <HStack justifyContent="left" py="1" px="4" marginTop="0.0rem">
        <H2>{artistDetails?.name}</H2>
      </HStack>
      <HStack justifyContent="left" px="4">
        <Large color={"black"}>
          {dateFormat(artistDetails?.date_of_birth, "d mmm yyyy")} -{" "}
          {dateFormat(artistDetails?.date_of_death, "d mmm  yyyy")}
        </Large>
      </HStack>
    </>
  );
}
