import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Container,
  Stack,
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  InputRightElement,
  HStack,
  InputGroup,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import H2 from "../components/layouts/Typography/H2";
import { login } from "../services/User";
import { userRegistrationForm } from "../constants/constants";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState([]);
  const [successMsgFlag, setSuccessMsgFlag] = useState(false);
  const [errorMsgFlag, setErrorMsgFlag] = useState(false);
  const [user, setUser] = useState();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = (data) => {
    const errors = [];

    if (!data.email) {
      errors.push("Email Address is required.");
    }

    if (!data.password || data.password.length < 8) {
      errors.push("Password is required.");
    }

    return errors;
  };

  let navigate = useNavigate();

  const onSubmit = async (data) => {
    const validationErrors = validateForm(data);

    if (validationErrors.length > 0) {
      setErrorMsg(validationErrors);
      setErrorMsgFlag(true);
    } else {
      login(data)
        .then((response) => {
          if (response?.success) {
            setMessage(response?.message);
            setSuccessMsgFlag(true);

            // set the state of the user
            setUser(response.user)

            // store the user in localStorage
            localStorage.setItem('user',  JSON.stringify(response.user))

            let path = `/`;
            navigate(path);
          } else {
            setErrorMsg(
              response?.errors || ["Login failed. Please try again."]
            );
            setErrorMsgFlag(true);
          }
        })
        .catch((error) => {
          console.error("Error during login:", error);
          setErrorMsg(["Login failed. Please try again."]);
        });
    }
  };
  console.log("user", user);
  return (
    <Layout>
      <Stack className="mobileviewpadding">
        <Container mt="16">
          <Box borderWidth="1px" borderRadius="lg" bg="#f7f7f7" p="4" maxW="md">
            <H2 py="4" textAlign="center">
              {userRegistrationForm.LOGIN_FORM_TITLE}
            </H2>

            {errorMsgFlag && errorMsg && (
              <Alert status="error" justifyContent={"space-between"}>
                <Box>
                  <UnorderedList>
                    {errorMsg.map((errorMessage, index) => (
                      <ListItem key={index}>{errorMessage}</ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={() => setErrorMsgFlag(false)}
                />
              </Alert>
            )}
            {successMsgFlag && message && (
              <Alert status="success">
                <AlertIcon />
                <Box>
                  <AlertTitle>Congratulation!</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={() => setSuccessMsgFlag(false)}
                />
              </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors?.email} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  {userRegistrationForm.REGISTRATION_FORM_FIELD_EMAIL_ADDRESS_LABEL}
                </FormLabel>
                <Input
                  id="email"
                  bg="white"
                  name="email"
                  {...register("email")}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.password} pb="2">
                <Stack
                  flexDirection={"row"}
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <HStack>
                    <FormLabel fontSize={"14px"} marginBottom="4px">
                      {
                        userRegistrationForm.REGISTRATION_FORM_FIELD_PASSWORD_LABEL
                      }
                    </FormLabel>
                  </HStack>
                  <HStack>
                    <FormLabel fontSize={"14px"} marginBottom="4px">
                      <Link href="/forgot-password">
                        {userRegistrationForm.LOGIN_FORM_FORGOT}
                      </Link>
                    </FormLabel>
                  </HStack>
                </Stack>
                <InputGroup>
                  <Input
                    bg="white"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <InputRightElement>
                    <Text onClick={toggleShowPassword}>
                      {showPassword ? (
                        <FiEye size={"18px"} />
                      ) : (
                        <FiEyeOff size={"18px"} />
                      )}
                    </Text>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors?.password && <p>{errors?.password?.message}</p>}
                </FormErrorMessage>
              </FormControl>

              <Button mt={4} colorScheme="blue" type="submit">
                {userRegistrationForm.REGISTRATION_FORM_BUTTON_LOGIN_LABEL}
              </Button>
            </form>
            <Box py="4">
              <Link href="/register">
                {userRegistrationForm.LOGIN_FORM_NEW_USER_REGISTRATION_LINK}
              </Link>
            </Box>
          </Box>
        </Container>
      </Stack>
    </Layout>
  );
}

export default Login;
