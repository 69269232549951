//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/PostBanner/banner1.png`
const Banner2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Synopsis/synopsis1.png`
const Banner3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Synopsis/synopsis2.png`
const Banner4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/DetailPost/1.png`
const Banner5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/DetailPost/2.png`
const Banner6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/DetailPost/3.png`
const post1 = `${IMAGE_BASE_PATH}/ui/Nemai-Ghosh/post1.png`
const post2 = `${IMAGE_BASE_PATH}/ui/Nemai-Ghosh/post2.png`
const post3 = `${IMAGE_BASE_PATH}/ui/Nemai-Ghosh/post3.png`
const post4 = `${IMAGE_BASE_PATH}/ui/Nemai-Ghosh/post4.png`
const KGBanner1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Posts/PostBanner/post1.png`

export const skk_post = [
  {
    id: 1,
    imgPath: Banner1,
    post: "post1",
    title: "",
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "1977",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    imgPath: Banner2,
    post: "post1",
    title: "",
    button1: "Keyword",
    button2: "------",
    button3: "-------------",
    keyWordButtons: [
      { name: "Keyword", link: false },
      { name: "------", link: false },
      { name: "-------------", link: false },
    ],
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 3,
    imgPath: Banner3,
    post: "post1",
    title: "",
    button1: "Keyword",
    button2: "------",
    button3: "-------------",
    keyWordButtons: [
      { name: "Keyword", link: false },
      { name: "------", link: false },
      { name: "-------------", link: false },
    ],
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 4,
    imgPath: Banner4,
    post: "post2",
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
  {
    id: 5,
    imgPath: Banner5,
    post: "post2",
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
  {
    id: 6,
    imgPath: Banner6,
    post: "post2",
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fightl",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
];
//skk_main_post
export const skk_main_post = [
  {
    id: 1,
    imgPath: Banner1,
    post: "post1",
    title: "",
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977/" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "1977",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    alias:"shatranj-ke-khilari-1977"
  },
  {
    id: 4,
    imgPath: Banner4,
    post: "post2",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    insightButtons: [
      { name: "INSIGHT", link: `/film/shatranj-ke-khilari-1977/insight/insight1` },
    ],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
    alias:"shatranj-ke-khilari-1977"
  },
];

//nemai-ghosh post
export const nemai_ghosh_main_post = [
  {
    id: 1,
    imgPath: post1,
    post: "post1",
    title: "",
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "1977",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    imgPath: post2,
    post: "post2",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },

  {
    id: 3,
    imgPath: post3,
    post: "post2",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },

  {
    id: 4,
    imgPath: post4,
    post: "post2",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
];

export const lucknow_post = [
  {
    id: 1,
    imgPath: Banner1,
    post: "post1",
    title: "",
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "1977",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    imgPath: Banner2,
    post: "post1",
    title: "",
    button1: "Keyword",
    button2: "------",
    button3: "-------------",
    keyWordButtons: [
      { name: "Keyword", link: false },
      { name: "------", link: false },
      { name: "-------------", link: false },
    ],
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 3,
    imgPath: Banner3,
    post: "post1",
    title: "",
    button1: "Keyword",
    button2: "------",
    button3: "-------------",
    keyWordButtons: [
      { name: "Keyword", link: false },
      { name: "------", link: false },
      { name: "-------------", link: false },
    ],
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 4,
    imgPath: Banner4,
    post: "post2",
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
  {
    id: 5,
    imgPath: Banner5,
    post: "post2",
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
  {
    id: 6,
    imgPath: Banner6,
    post: "post2",
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fightl",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
  },
];
//location_main_post
export const location_main_post = [
  {
    id: 1,
    imgPath: Banner4,
    post: "post2",
    title: "Colonel Mordaunt's Cock-Fight",
    button1: "Shatranj Ke Khilari",
    button2: "Satyajit Ray",
    button3: "Animal welfare",
    button4: "Claude Martin",
    button5: "Warren Hastings",
    button6: "Lucknow",
    button7: "Nawabs of Lucknow",
    button8: "INSIGHT",
    button9: "Nemai Ghosh",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977` },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    secondaryButton: [
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow" },
      { name: "Nawabs of Lucknow", link: false },
    ],
    keyWordButtons: [{ name: "Nemai Ghosh", link: `/nemai-ghosh` }],
    insightButtons: [
      { name: "INSIGHT", link: `/film/shatranj-ke-khilari-1977/insight/insight1` },
    ],
    description1:
      "Cock-fights were a popular sport in India as well as the outside world in the late eighteenth century. In 1786, the then Governor-General of India Warren Hastings commissioned the German born artist Johan Zaoffany to create a painting of the historiccock-fight that saw many British East India Company employees and Indian royalties in attendance. Many a versions of the painting have.",
    description2:
      "thus been made and depicted in various mediums over the course of morethan two centuries, including Satyajit Ray’s Shatranj Ke Khilari.",
    description0: "",
    alias:"shatranj-ke-khilari-1977"
  },
  {
    id: 2,
    imgPath: Banner1,
    post: "post1",
    title: "",
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977/" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "1977",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    alias:"shatranj-ke-khilari-1977"
    
  },
];


//kg_subramanayam_post
export const kg_subramanayam_main_post = [
  {
    id: 1,
    imgPath: KGBanner1,
    post: "post1",
    title: "",
    button1: "",
    button2: "",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977/" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem um dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    alias:"k.g-Subramanyan"
  }
];
export const kg_subramanayam_post = [
  {
    id: 1,
    imgPath: KGBanner1,
    post: "post1",
    title: "The Window of an American Skyscraper",
    button1: "KG Subramanyan",
    button2: "Polytichs",
    primaryButton: [
      { name: "KG Subramanyan", link: "/person-artist/k.g-Subramanyan" },
      { name: "Polytichs", link: "/" },
    ],
    secondaryButton: [
      { name: "New York", link: "/location/new-york" },
    ],
    keyWordButtons: [{ name: "JD Rockefeller", link: `/jdrockefeller` }],
    button3: "",
    description1:
      `This 1968 painting called "Windows- II" is one among the several polytichs implemented by KG as a series of paintings and murals. There is both a practical and a theoretical side to his windows series. Having spent his inititial years of travel and art grounding in New York during the Rockefeller Scholarship, he faced a compactness of space- something he overcame practically by`,
    description2:`The idea of fragmented grids evolved into windows and become the foundation for several of his later murals and canvases.  Siva Kumar says "By showing the figures and their backdrops in fragmentary close-ups and rendering them in bright and flattened colours, he made the images strikingly animate, yet tantalisingly unrevealing.`,
    description0: "KG Subramanyan's Structure of the Polytich",
    insight: "insight1",
    button8: "INSIGHT",
  },
];