import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import {
  Box,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";

// Service Files
import * as getInsightDetailsData from "../../../services/Insight";

const InsightPageSlider = () => {
  const [imgData, setImgData] = useState([]);
  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage: "",
  });
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const {alias} = useParams();
  const masterlistTitle=alias
  const insight = parts[3];

  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;

    const activeIndex = selectedImg.index + 1;

    const nextSlide = imgData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.options[0]?.image,
        index: activeIndex,
        slideImage: nextSlide.options[0]?.side_image,
      });
    }
  };
  const handlePreviousButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index - 1;
    const nextSlide = imgData[activeIndex];
    // swiperRef.current.swiper.slideTo(index);

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.options[0]?.image,
        index: activeIndex,
        slideImage: nextSlide.options[0]?.side_image,
      });
    }
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsightImagesData(
        "masterlist_table",
        masterlistTitle
      );
      if (result) {
        setImgData(result);
        setSelectedImg({
          image: result[0]?.options[0]?.image,
          index: 0,
          slideImage: result[0]?.options[0]?.side_image,
        });
      }
    };
    getData();
  }, [masterlistTitle, insight]);
  const handleImageClick = (item, index) => {
    // setClickedImageIndex(index);
    // setPosterFullScreenImage(true);
  };

  return (
    <>
      <Container maxW="auto" className="songs ml-0"  id="allslider" px="0px">
        <Stack direction={{base:"column",md:"row  "}} px="0px" position={"relative"} gap={{base:"10px",md:"30px"}}>
          <Stack alignItems={"center"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={{ base: "100%", md: "752px" }}
              height={"auto"}
              className="img-shadow"
            >
              <Image
                src={selectedImg.slideImage}
                onClick={() =>
                  handleImageClick(selectedImg.slideImage, selectedImg.index)
                }
                width={"max-content"}
                height={"auto"}
              />
            </Box>
          </Stack>

          <Stack
            style={{ height: {base:"100%",md:"28rem"} }}
            width={{base:"100%",md:"30%"}}
            pt="4"
            // id="homeslider"
            position={"relative"}
          >
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              // slidesPerGroup={3}
              spaceBetween={20}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              height={"100%"}
              modules={[Navigation, Pagination]}
              // className="mySwiper"
              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
              id="slideshowinsight"
            >
              {imgData.length > 0 &&
                imgData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <HStack
                      style={{
                        border:
                          selectedImg.slideImage === item.image
                            ? "1px solid #e8e8e8"
                            : "none",
                      }}
                    >
                      <Stack>
                        {item.type === "image" && (
                          <SimpleGrid
                            justifyItems={"center"}
                            columns={[3, null, 3]}
                            spacing="10px"
                          >
                            {item.options.map((it, idx) => (
                              <Box
                                className="pegination-img"
                                onClick={() => {
                                  // swiperRef.current.swiper.slideTo(
                                  //   swiperRef.current.swiper.activeIndex
                                  // );
                                  setSelectedImg({
                                    image: it.image,
                                    index: 0,
                                    slideImage: it.side_image,
                                  });
                                }}
                              >
                                <Image
                                  src={it.image}
                                  width={"auto"}
                                  height={"100%"}
                                  alt={""}
                                />
                              </Box>
                            ))}
                          </SimpleGrid>
                        )}
                      </Stack>
                    </HStack>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};
export default InsightPageSlider;
