export const schema = [
  { key: "Exhibition", display: "Exhibition", seq: 1 },
  { key: "award_name", display: "Award name", seq: 2 },
  { key: "id", display: "Id", seq: 3 },
  { key: "issuing_authority", display: "Issuing authority", seq: 4 },
  { key: "sortable_date", display: "Sortable date", seq: 5 },
  { key: "type", display: "Type", seq: 6 },
  { key: "winner", display: "Winner", seq: 7 },
  {
    key: "awardcategory",
    display: "Award category",
    processor: "awardCategoryAliasArray",
    seq: 8,
  },
  {
    key: "awardImage",
    display: "Award image",
    processor: "imageAliasArray",
    seq: 9,
  },
];
