export const schema = [
  { key: "id", display: "Id", seq: 1 },
  { key: "title", display: "Title", seq: 2 },
  { key: "link1", display: "Link1", seq: 3 },
  { key: "link2", display: "Link2", seq: 4 },
  { key: "link3", display: "Link3", seq: 5 },
  { key: "link4", display: "Link4", seq: 6 },
  { key: "button1", display: "Button1", seq: 7 },
  { key: "button2", display: "Button2", seq: 8 },
  { key: "button3", display: "Button3", seq: 9 },
  { key: "description", display: "Description", seq: 9 },
  { key: "subSlider", display: "SubSlider", processor: "aliasArray", seq: 9 },
];