import { OExplorerData } from "../MockData/OExplorerData";
import { transformJSON } from "../util/transformer";
import { schema } from "./schemas/OExplorer";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

export const getOExplorerDataAPI = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return OExplorerData;
  } else {
    return [];
  }
};

export const getOExplorerData = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getOExplorerDataDummy(tableName, masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await getOExplorerDataAPI(tableName, masterlist);
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getOExplorerDataDummy = async (tableName, masterlist) => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return OExplorerData;
  } else {
    return [];
  }
};
