
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Service files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import { BookDetails } from "../Cine_MasterList/ResearchCentre/CommonComponents/BookDetails";
import MobileNav from "../../components/Topheader";
import { Library } from "./ResearchCentre/Library";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import ReadMore from "./ReadMore";
import Archive from "./ResearchCentre/Archive";
import All from "./ResearchCentre/All";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

//Language files
import { explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/6.png`
const Img7 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/7.png`
const Img8 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/DetailPage/8.png`


export default function ResearchCentre({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});

  const {alias} = useParams();
  const aliasAPI = alias;
  const masterlistType="event-exhibition"
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "journal_from"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
          setIntroData(
              result.map((it) => ({
                text: it?.find((item) => item?.key === "landing_page_title")?.value,
                to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
                  it?.find((item) => item?.key === "landing_page_abbreviation")?.value
                }`,
                title:it?.find((item) => item?.key === "landing_page_abbreviation")?.value
              }))
            );
      }
    };
    getIntroData();
    getLandingData();
  }, [aliasAPI]);
  const [readmoreNavigation, setReadmoreNavigation] = useState({});

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
      onClose={() => onClose}
      display={{ base: "none", md: "block" }}
      height="calc(100vh - -64px)"
      readmoreNavigation={readmoreNavigation}
      setReadmoreNavigation={setReadmoreNavigation}
      introData={introData}
      aliasAPI={aliasAPI}
      horislinkData={horislinkData}
      artistDetails={artistDetails}
      masterlistType={masterlistType}
    />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            // leftFlag={leftFlag}
            // bookFlag={bookFlag}
            // setBookFlag={setBookFlag}
            // researchCentreFlag={researchCentreFlag}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <LibraryAndArchive
          masterlist={aliasAPI}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
          masterlistType={masterlistType}
          // setResearchCentreFlag={setResearchCentreFlag}
          // bookFlag={bookFlag}
          // setBookFlag={setBookFlag}
          // openDrawer={openDrawer}
          // setOpenDrawer={setOpenDrawer}
          // setleftFlag={setleftFlag}
          // bookReadmore={bookReadmore}
          // setBookReadmore={setBookReadmore}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  leftFlag,
  researchCentreFlag,
  openDrawer,
  bookFlag,
  setBookFlag,
  bookReadmore,
  setBookReadmore,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  alias,
  ...rest
}) => {
  const navigate = useNavigate();

   /* Need to work :To handle back navigation from the drawer */
  // const handleNavigation = (alias) => {
  //   if (alias) {
  //     navigate(`/categories/${alias}`);
  //   } else if (bookFlag) {
  //     setBookFlag(false);
  //   } else {
  //     navigate(-1);
  //   }
  // };
  const handleNavigation = (alias) => {
    if (bookFlag) {
      if (bookReadmore) {
        setBookReadmore(false);
      } else {
        setBookFlag(false);
      }
    } else {
      navigate(-1);
    }
  };

   /* Need to work :To handle back nivagation from the drawer */
  // const handleBackNavigation = () => {
  //   if (readmoreNavigation?.isReadMore) {
  //     setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
  //     return;
  //   } else {
  //     navigate("/art-heritage-annual/body-of-work/timeline/exhibition_catlouge");
  //   }
  // };

  const oSections = [
    {
      to: `/${masterlistType}/${alias}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${alias}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];

  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
      sections={introData}
      oSections={oSections}
      hMenu={horislinkData}
      artistDetails={artistDetails}
      handleNavigation={handleNavigation}
      bookFlag={bookFlag}
      setBookFlag={setBookFlag}
      openDrawer={openDrawer}
      leftFlag={leftFlag}
      researchCentreFlag={researchCentreFlag}
    />
    </>
  );
};

const LibraryAndArchive = ({
  masterlist,
  setResearchCentreFlag,
  bookFlag,
  setBookFlag,
  openDrawer,
  setOpenDrawer,
  setleftFlag,
  bookReadmore,
  setBookReadmore,
  masterlistType,
}) => {
  const [visible, setVisible] = React.useState(true);
  const [bookData, setBookData] = useState({});
  const [listGridIcon, setListGridIcon] = useState(true);
  const navigate = useNavigate();
  let { tabIndex, gridview } = useParams();
  const { t } = useTranslation("");

  /* Need to check after dynamic book data */
  console.log("", bookData);

  const BookDetail = {
    Title: "Pather Panchali - A Film by Satyajit Ray",
    sliderData: [
      { image: Img1 },
      { image: Img2 },
      { image: Img3 },
      { image: Img4 },
      { image: Img5 },
      { image: Img6 },
      { image: Img7 },
      { image: Img8 },
    ],
    Book: [
      {
        title: "About the Book",
        content: "Contents-1",
        readmore: true,
      },
      {
        title: "About the Author",
        content: "Contents-2",
        readmore: true,
      },
    ],
    accordion: [
      {
        title: "Content",
        content: null,
      },
      {
        title: "Index",
        content: null,
      },
    ],
  };

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${masterlist}/research-centre`, { replace: true });


      if (parseInt(tabIndex) === 1) {
        setListGridIcon(true);
      }
      if (parseInt(gridview) === 0) {
        setVisible(false);
      }
      if (parseInt(gridview) === 1) {
        setVisible(true);
      }
    }
  }, [tabIndex, navigate, gridview, masterlistType, masterlist]);

  const handleBookClick = (flag, data) => {
    setBookData(data);
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };

  return (
    <Stack width={{base:"100%",md:"1160px"}}>
      <Container minW={"100%"} pb="4" px={{base:"4",md:"0px"}} marginX={{base:"0px",md:"30px"}}>
        <Stack>
          {bookFlag ? (
            <>
              {bookReadmore ? (
                <ReadMore />
              ) : (
                <BookDetails
                  BookDetail={BookDetail}
                  bookReadmore={bookReadmore}
                  setBookReadmore={setBookReadmore}
                />
              )}
            </>
          ) : (
            <HStack>
              <Tabs
                  align={{ xs: "end", sm: "" }}
                  width={{ base: "100%", md:"1160px"}}
                  overflowX={"auto"}
                  className="verticalscroll"
                defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 1}
              >
                <Stack
                  flexDirection={{base:"row",md:"row-reverse"}}
                  display={"flex"}
                >
                  <Box
                   position={"fixed"}
                   zIndex={999}
                   bg="white"
                   width={{ base: "100%", md: "1160px" }}
                   paddingX={{ base: "10px", md: "20px" }}
                   overflowX={"auto"}
                  >
                    <TabList
                      overflowX="auto"
                      borderBottom={"1px"}
                      borderBottomColor={"#f1f1f1"}
                      gap={12}
                      paddingTop={"30px"}
                      className="verticalscroll"
                      paddingBottom={"4"}
                    >
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(false)}
                      >
                        {t(explore.ALL)}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {t(explore.LIBRARY)}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "3px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {t(explore.ARCHIVE)}
                      </Tab>
                    </TabList>
                  </Box>
                  {listGridIcon && (
                    <Stack
                      flexDirection={"row"}
                      gap={0}
                      zIndex={999}
                      right={{base:"5%",md:"13%"}}
                      id="listgridviewbtn"
                      marginTop={"20px !important"}
                      position={"fixed"}
                    >
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(false)}
                          color={!visible ? "black" : "#989898"}
                          fontSize="20px"
                          display="flex"
                          borderTopLeftRadius="6px"
                          borderBottomLeftRadius="6px"
                          borderTopRightRadius="0px"
                          borderBottomRightRadius="0px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <RiMenuLine ml="4" />
                        </IconButton>
                      </HStack>
                      <HStack marginTop={"0px!important"}>
                        <IconButton
                          variant="unstyled"
                          onClick={() => setVisible(true)}
                          color={visible ? "black" : "#989898"}
                          fontSize="16px"
                          display="flex"
                          borderTopLeftRadius="0px"
                          borderBottomLeftRadius="0px"
                          borderTopRightRadius="6px"
                          borderBottomRightRadius="6px"
                          border="1px"
                          borderColor={"#989898"}
                          height="32px"
                        >
                          <BsGrid />
                        </IconButton>
                      </HStack>
                    </Stack>
                  )}
                </Stack>
                <TabPanels paddingTop={"4"}>
                  <TabPanel px="0px">
                    <All visible={visible} />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Library
                      visible={visible}
                      handleBookClick={handleBookClick}
                      openDrawer={openDrawer}
                      setOpenDrawer={setOpenDrawer}
                      setleftFlag={setleftFlag}
                      tableName={"bibliographies"}
                      masterlist={masterlist}
                    />
                  </TabPanel>
                  <TabPanel px="0px">
                    <Archive visible={visible} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
