import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Large from "../Typography/Large";
import Small from "../Typography/Small";
import SmallBold from "../Typography/SmallBold";
import H4 from "../Typography/H4";
import { Link, useNavigate } from "react-router-dom";
import { ResearchCategory } from "../../../MockData/Research_Category";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { common, hamburgerMenu } from "../../../constants/constants";
import { SocialMediaButton } from "../../../CommonComponents/SocialMediaButton";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeIcon.png`
const menuIcon = `${IMAGE_BASE_PATH}/ui/Chevron/menu.png`
const paw = `${IMAGE_BASE_PATH}/ui/Chevron/paw.png`
const book = `${IMAGE_BASE_PATH}/ui/Chevron/book.png`
const library = `${IMAGE_BASE_PATH}/ui/Chevron/library.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/circle.png`

function SideMenu(props) {
  const [user, setUser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const btnRef = React.useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user");

    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem("user");

    // Reset the user state
    setUser(null);

    // Redirect to login page or any other page
    navigate("/login");
  };

  return (
    <Container maxW="1400px" top={"0%"} position={"relative"} px="0px">
      <Stack>
        <IconButton
          className="humbargermenu"
          variant={"unstyled"}
          ml="-5px"
          ref={btnRef}
          onClick={() => setOpen(!open)}
        >
          {!open ? <Image src={menuIcon} /> : <Image src={closeIcon} />}
        </IconButton>

        <Drawer
          isOpen={open}
          placement="left"
          onClose={() => setOpen(false)}
          finalFocusRef={btnRef}
          id="humbergermenu"
        >
          <DrawerOverlay />
          <DrawerContent>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader
              borderBottom={"1px"}
              borderBottomColor="#f6f6f6"
              py="2"
            >
              <Stack py="2" alignItems="baseline">
                <Stack flexDirection={"row"}>
                  <HStack borderRadius={"5px"} alignItems={"flex-start"}>
                    <Image
                      src={circle}
                      bg="#F6F6F6"
                      padding={"6px"}
                      width="32px"
                      height={"32px"}
                      borderRadius={"5px"}
                    ></Image>
                  </HStack>
                  <HStack
                    flexDirection={"column"}
                    alignItems="baseline"
                    marginTop={"0px!important"}
                    pl="13px"
                  >
                    <Stack>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        lineHeight={"16px"}
                        color="#080808"
                      >
                        {hamburgerMenu.OSW}
                      </Text>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems="baseline">
                      {user === null && (
                        <Stack
                          border={"1px"}
                          borderRadius={"2px"}
                          borderColor="#e8e8e8"
                          p="1"
                          mr="2"
                          flexDirection={"row"}
                          gap="1"
                        >
                          <HStack>
                            <Image
                              src={member}
                              width={"10.5px"}
                              height="10px"
                            ></Image>
                          </HStack>
                          <HStack marginTop="0rem!important">
                            <Link to="/login">
                              <Small>{hamburgerMenu.LOGIN}</Small>
                            </Link>
                          </HStack>
                        </Stack>
                      )}
                      {user === null && (
                        <HStack
                          border={"1px"}
                          borderColor="#e8e8e8"
                          borderRadius={"2px"}
                          p="1"
                        >
                          <Link to="/register">
                            <SmallBold>
                              {hamburgerMenu.CREATE_ACCOUNT}
                            </SmallBold>
                          </Link>
                        </HStack>
                      )}
                      {user !== null && (
                        <HStack
                          border={"1px"}
                          borderColor="#e8e8e8"
                          borderRadius={"2px"}
                          p="1"
                        >
                          <Link to="/my-profile">
                            <SmallBold>{hamburgerMenu.MY_PROFILE}</SmallBold>
                          </Link>
                        </HStack>
                      )}
                      {user !== null && (
                        <HStack
                          border={"1px"}
                          borderColor="#e8e8e8"
                          borderRadius={"2px"}
                          p="1"
                        >
                          <button onClick={handleLogout}>
                            <SmallBold>{hamburgerMenu.LOGOUT}</SmallBold>
                          </button>
                        </HStack>
                      )}
                    </Stack>
                  </HStack>
                </Stack>
              </Stack>
            </DrawerHeader>
            <DrawerBody>
              <HStack py="3">
                <Link to="/whats-new">
                  <H4 color="#860F0F" mr="5">
                    {hamburgerMenu.WHATS_NEW}
                  </H4>
                </Link>
              </HStack>
              <Accordion allowMultiple>
                <AccordionItem
                  display={{ base: "content", md: "none" }}
                  py="4"
                  borderTopWidth={"0px"}
                >
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <HStack alignItems="flex-start" gap={0}>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"600"}
                          lineHeight={"16px"}
                          color="#080808"
                        >
                          {hamburgerMenu.RESEARCH_CATEGORY}
                        </Text>
                      </HStack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel px="0px" py={"3"}>
                    <Large color="#035DA1">
                      {ResearchCategory?.map((child) => (
                        <ResearchCategoryLabel key={child.label} {...child} />
                      ))}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem borderTopWidth={"0px"}>
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <Stack py="1" gap={0}>
                        <Stack flexDirection={"row"} gap={0}>
                          <HStack borderRadius={"5px"}>
                            <Image
                              src={book}
                              bg="#F6F6F6"
                              padding={"6px"}
                              width="42px"
                              height={"32px"}
                              borderRadius={"5px"}
                            ></Image>
                          </HStack>
                          <HStack alignItems="center" gap={0} px={"4"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"16px"}
                                color="#080808"
                                className="topmargin"
                              >
                                {hamburgerMenu.TRCI}
                              </Text>
                            </Stack>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel py={"3"}>
                    <Large color="#035DA1" pl="8">
                      {common.CONTENT_AWAITED}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem borderTopWidth={"0px"}>
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <Stack py="1" gap={0}>
                        <Stack flexDirection={"row"} gap={0}>
                          <HStack borderRadius={"5px"} gap={0}>
                            <Image
                              src={library}
                              bg="#F6F6F6"
                              padding={"6px"}
                              width="32px"
                              height={"32px"}
                              borderRadius={"5px"}
                            ></Image>
                          </HStack>
                          <HStack alignItems="center" gap={0} px={"4"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"16px"}
                                color="#080808"
                              >
                                {hamburgerMenu.ARCHIVE_LIB}
                              </Text>
                            </Stack>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel py={"3"}>
                    <Large color="#035DA1" pl="8">
                      {common.CONTENT_AWAITED}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem pb="4" borderTopWidth={"0px"}>
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <Stack py="1" gap={0}>
                        <Stack flexDirection={"row"} gap={0}>
                          <HStack borderRadius={"5px"}>
                            <Image
                              src={paw}
                              bg="#F6F6F6"
                              padding={"6px"}
                              width="32px"
                              height={"32px"}
                              borderRadius={"5px"}
                            ></Image>
                          </HStack>
                          <HStack alignItems="center" pl={"4"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"16px"}
                                color="#080808"
                                className="topmargin"
                              >
                                {hamburgerMenu.VANRAJA_CLINIC}
                              </Text>
                            </Stack>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel py={"3"}>
                    <Large color="#035DA1" pl="8">
                      {common.CONTENT_AWAITED}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Stack
                py="4"
                flexDirection={"row"}
                alignItems="flex-start"
                gap="4"
              >
                <HStack alignItems="flex-start">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.ABOUT_US}
                  </Text>
                </HStack>

                <HStack alignItems="flex-start" marginTop="0rem!important">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.CAREERS}
                  </Text>
                </HStack>
                <HStack alignItems="flex-start" marginTop="0rem!important">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.CONTACT_US}
                  </Text>
                </HStack>
              </Stack>
            </DrawerBody>

            <DrawerFooter justifyContent={"flex-start"}>
              <Stack>
                <HStack>
                  <Large>{hamburgerMenu.FOLLOW_US}</Large>
                </HStack>
                <SocialMediaButton />
              </Stack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Stack>
    </Container>
  );
}
export default SideMenu;

const ResearchCategoryLabel = ({ label, href, subLabel, Abbreviation }) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        if (href) {
          navigate(href);
        } else {
        }
      }}
      role={"group"}
      display={"block"}
      px={1}
      py={1}
      borderBottom={"1px"}
      borderColor={"gray.100"}
      _hover={{ bg: useColorModeValue("RGBA(0, 0, 0, 0.04)", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text fontSize="15px" fontWeight={"600"} color={"#333"}>
            {Abbreviation}
          </Text>
        </Box>

        <Flex
          transition={"all .3s ease"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justifyContent={"flex-end"}
          flex={1}
        >
          <Icon color={"black"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};
