import axios from "axios";
import { transformJSON } from "../util/transformer";
import { schema ,prefaceSchema, specificationSchema, synopsisSchema, filmCreditsSchema ,filmCastSchema} from "./schemas/Film";
import { skk_preface_data, skk_specification_data, skk_synopsis_data, skk_cast_view_data, skk_film_intro_data, skk_credits_view_data, mughal_film_intro_data, mughal_synopsis_data, mughal_cast_view_data, mughal_credits_view_data, silsila_cast_view_data, silsila_synopsis_data, silsila_credits_view_data } from "../MockData/FilmMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const filmByAliasAPI = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmWhere){` +
      tableName +
      ` (where: $where){
            id
            sl_no
            alias
            subject
            masterlist_type
            short_description
            rated
            duration_mins
            certificate_number
            date_of_issue
            date_of_expiry
            color
            lab
            gauge
            length_of_reels
            number_of_reels
            shooting_period
            editorial_text
            synopsis
            release_date
            first_release_location
            trailer_link
            full_movie_link
            eastmancolor_by
            researchCategories {
              alias
              abbreviation
              name
            }
            filmTitleImage {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            posterImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            publicityImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            certificateImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            language {
              name
              alias
            }
            studios {
              name
              alias
            }
            location {
              name
              alias
            }
            synopsisImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            filmMainImage {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            filmBowCreditImage {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            banner {
              name
              alias
            }
            storyWriter {
              name
              alias
              type      
            }
            cameraman {
              name
              alias
              type
            }
            assistant_of_cameraman {
              name
              alias
              type
            }
            art_director {
              name
              alias
              type
            }
            associate_of_art_director {
              name
              alias
              type
            }
            costume_designer {
              name
              alias
              type
            }
            associate_of_costume_designer {
              name
              alias
              type
            }
            sound {
              name
              alias
              type
            }
            assistant_of_sound {
              name
              alias
              type
            }
            editor {
              name
              alias
              type
            }
            assistant_of_editor {
              name
              alias
              type
            }
            make_up_artist {
              name
              alias
              type
            }
            assistant_of_make_up_artist {
              name
              alias
              type
            }
            wardrobe {
              name
              alias
              type
            }
            backdrop {
              name
              alias
              type
            }
            assistant_director {
              name
              alias
              type
            }
            special_assistant {
              name
              alias
              type
            }
            production_executive {
              name
              alias
              type
            }
            production_manager {
              name
              alias
              type
            }
            production_assistant {
              name
              alias
              type
            }
            studio {
              name
              alias
            }
            equipment_manager {
              name
              alias
              type
            }
            office_manager {
              name
              alias
              type
            }
            assistant_of_office_management {
              name
              alias
              type
            }
            animator {
              name
              alias
              type
            }
            graphic_designer {
              name
              alias
              type
            }
            assistant_of_graphic_designer {
              name
              alias
              type
            }
            associate_of_graphic_designer {
              name
              alias
              type
            }
            publicity_liasion_officer {
              name
              alias
              type
            }
            stills_photographer {
              name
              alias
              type
            }
            music_director {
              name
              alias
              type
            }
            dancer {
              name
              alias
              type
            }
            choreographer {
              name
              alias
              type
            }
            re_recorder {
              name
              alias
              type
            }
            music_recorder {
              name
              alias
              type
            }
            dialogue_writer {
              name
              alias
              type
            }
            narrator {
              name
              alias
              type
            }
            film_producer {
              name
              alias
              type
            }
            screenplay {
              name
              alias
              type
            }
            location_manager {
              name
              alias
              type
            }
          }
        }`,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.films) {
    return result?.data?.data?.films;
  } else {
    return [];
  }
};

export const filmByAlias = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
   const response = await filmByAliasDummyData(tableName, alias);
   return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmByAliasAPI(tableName, alias);
    try{
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const filmByAliasDummyData = async (tableName, masterlist) => {
  // return skk_film_intro_data
  const filmDataMap = {
    "satyajit-ray": skk_film_intro_data,
    "shatranj-ke-khilari-1977": skk_film_intro_data,
    "art-heritage-annual": skk_film_intro_data,
    "mughal-e-azam": mughal_film_intro_data,
    "sayed-haider-raza":skk_film_intro_data
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmPageData = filmDataMap[masterlist] || skk_film_intro_data;
  return FilmPageData;
};

export const filmPrefaceDataAPI = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmWhere){` +
      tableName +
      ` (where: $where){
            alias
            short_description
            posterImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            publicityImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }           
          }
        }`,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.films) {
    return result?.data?.data?.films;
  } else {
    return [];
  }
};

// Get Film Preface Data
export const filmPrefaceData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmPrefaceDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmPrefaceDataAPI(tableName, alias);
    try {
      if (response) {
        let transformedData = transformJSON(prefaceSchema, true, response);
        return transformedData[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmPrefaceDataDummy = async (tableName, alias) => {  
  return skk_preface_data;
};

export const filmSpecificationDataAPI = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmWhere){` +
      tableName +
      ` (where: $where){
            rated
            language {
              name
              alias
            }
            duration_mins
            color
            lab
            gauge
            length_of_reels
            number_of_reels
            shooting_period
            studios {
              name
              alias
            }
            studio {
              name
              alias
            }
            location {
              name
              alias
            }
            certificate_number
            certificateImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }
            date_of_issue
            date_of_expiry                      
          }
        }`,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.films) {
    return result?.data?.data?.films;
  } else {
    return [];
  }
};

// Get Film Specification Data
export const filmSpecificationData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmSpecificationDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await filmSpecificationDataAPI(tableName, alias);
    try {
      if (response) {
        let transformedData = transformJSON(specificationSchema, true, response);
        return transformedData[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmSpecificationDataDummy = async (tableName, alias) => {
  return skk_specification_data;
};

export const filmSynopsisDataAPI = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmWhere){` +
      tableName +
      ` (where: $where){
            alias
            synopsis
            synopsisImages {
              wnn
              accession_number
              alias
              name_of_publication
              artist_name
              date_of_artwork
              image
              title
              direct_url_of_preview_image
              medium_of_artwork
              revised_medium
            }                 
          }
        }`,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.films) {
    return result?.data?.data?.films;
  } else {
    return [];
  }
};

// Get Film Synopsis Data
export const filmSynopsisData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmSynopsisDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmSynopsisDataAPI(tableName, alias);
    try {
      if (response) {
        let transformedData = transformJSON(synopsisSchema, true, response);
        return transformedData[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmSynopsisDataDummy = async (tableName, alias) => {
  // return skk_synopsis_data;
  const filmSynopsisDataMap = {
    "shatranj-ke-khilari-1977": skk_synopsis_data,
    "mughal-e-azam": mughal_synopsis_data,
    "silsila": silsila_synopsis_data,
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmSynopsisData = filmSynopsisDataMap[alias] || skk_synopsis_data;
  return FilmSynopsisData;
};

export const filmCastDataAPI = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmWhere){` +
      tableName +
      ` (where: $where){
            alias
            film_producer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            screenplay {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            dialogue_writer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            music_director {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            editor {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            art_director {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            stills_photographer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            narrator {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            choreographer{
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            costume_designer{
              name
              alias
              type
              first_name
              last_name
              full_name
            }      
          }
        }`,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.films) {
    return result?.data?.data?.films;
  } else {
    return [];
  }
};

export const filmCastData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmCastDataDummy(tableName, alias);
    return response
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmCastDataAPI(tableName, alias);
    try {
      if (response) {
        let transformedData = transformJSON(filmCastSchema, true, response);
        return transformedData[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmCastDataDummy = async (tableName, alias) => {
  const filmCastDataMap = {
    "shatranj-ke-khilari-1977": skk_cast_view_data,
    "mughal-e-azam": mughal_cast_view_data,
    "silsila":silsila_cast_view_data
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmCastData = filmCastDataMap[alias] || skk_cast_view_data;
  return FilmCastData;
};

export const filmCreditsDataAPI = async (tableName, alias) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: FilmWhere){` +
      tableName +
      ` (where: $where){
            banner {
              name
              alias
            }
            leadCast {
              name
              alias
              type   
              first_name
              last_name
              full_name   
            }
            leadCastRest {
              name
              alias
              type   
              first_name
              last_name
              full_name   
            }
            storyWriter {
              name
              alias
              type   
              first_name
              last_name
              full_name   
            }
            cameraman {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            assistant_of_cameraman {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            art_director {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            associate_of_art_director {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            costume_designer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            associate_of_costume_designer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            sound {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            assistant_of_sound {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            editor {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            assistant_of_editor {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            make_up_artist {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            assistant_of_make_up_artist {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            wardrobe {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            backdrop {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            assistant_director {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            special_assistant {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            production_executive {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            production_manager {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            location_manager {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            production_assistant {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            studio {
              name
              alias
            }
            studios {
              name
              alias
            }
            eastmancolor_by
            equipment_manager {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            office_manager {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            animator {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            graphic_designer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            publicity_liasion_officer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            stills_photographer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            dancer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            choreographer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            re_recorder {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            music_recorder {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            dialogue_writer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            narrator {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            film_producer {
              name
              alias
              type
              first_name
              last_name
              full_name
            }
            screenplay {
              name
              alias
              type
              first_name
              last_name
              full_name
            }             
          }
        }`,
    variables: {
      where: {
        alias_IN: alias,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.films) {
    return result?.data?.data?.films;
  } else {
    return [];
  }
};

// Get Film Credits data
export const filmCreditsData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmCreditsDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmCreditsDataAPI(tableName, alias);
    try {
      if (response) {
        let transformedData = transformJSON(filmCreditsSchema, true, response);
        return transformedData[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmCreditsDataDummy = async (tableName, alias) => {
  const filmCreditsDataMap = {
    "shatranj-ke-khilari-1977": skk_credits_view_data,
    "mughal-e-azam": mughal_credits_view_data,
    "silsila":silsila_credits_view_data
  };
  const FilmCreditsData = filmCreditsDataMap[alias] || skk_credits_view_data;
  return FilmCreditsData;
};
