import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  introData: [],
  names: "",
  agraphy: [],
  filmMainImage: "",
  filmDetails: { date: "", location: "" },
  filmTrailer: { link: "", display: "", image: "" },
};

const skkLandingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setIntroData(state, action) {
      state.introData = action.payload;
    },
    setNames(state, action) {
      state.names = action.payload;
    },
    setAgraphy(state, action) {
      state.agraphy = action.payload;
    },
    setFilmMainImage(state, action) {
      state.filmMainImage = action.payload;
    },
    setFilmDetails(state, action) {
      state.filmDetails = action.payload;
    },
    setFilmTrailer(state, action) {
      state.filmTrailer = action.payload;
    },
  },
});

export const {
  setIntroData,
  setNames,
  setAgraphy,
  setFilmMainImage,
  setFilmDetails,
  setFilmTrailer,
} = skkLandingSlice.actions;

export default skkLandingSlice.reducer;
