import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core Files
import React, { useEffect, useRef, useState } from "react";

// Service Files
import { filmEditorialByMasterlist, getEditorialData } from "../../../services/Editorial";
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getFilmData from "../../../services/Film";

// Subsection Files
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade, Navigation, Pagination } from "swiper";
import { common, filmography } from "../../../constants/constants";
import { TextComponent } from "../../../CommonComponents/TextComponent";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function Editorial({ setReadMoreFlag, readmoreNavigation, masterlist }) {
  const [imgData, setImgData] = useState([]);
  const [desc, setDesc] = useState("");
  const [fullImgData, setFullImgData] = useState([]);
  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage: "",
  });
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [posterFullScreenImage, setPosterFullScreenImage] = useState(false);
  const [posterImgData, setposterImgData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const result = await getEditorialData("editorials", masterlist);
      if (result) {
        setFullImgData(result);
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);

  //key value
  const [editorialData, setEditorialData] = useState([]);
  const citationFactor = (data) => {
    return data?.map((item) => {
      const artwork =
        item?.find((nestedItem) => nestedItem?.key === "artwork")?.value || [];
      const artist_name = artwork?.map(
        (nestedItem) => nestedItem?.artist_name && nestedItem?.artist_name
      );
      const name_of_publication = artwork?.map(
        (nestedItem) =>
          nestedItem?.name_of_publication && nestedItem?.name_of_publication
      );
      const date_of_artwork = artwork?.map(
        (nestedItem) =>
          nestedItem?.date_of_artwork && nestedItem?.date_of_artwork
      );
      const medium_of_artwork = artwork?.map(
        (nestedItem) =>
          nestedItem?.medium_of_artwork && nestedItem?.medium_of_artwork
      );
      const revised_medium = artwork?.map(
        (nestedItem) => nestedItem?.revised_medium && nestedItem?.revised_medium
      );
      const sl_no =
        item?.find((nestedItem) => nestedItem?.key === "sl_no")?.value || "";
      return {
        desc: `${sl_no}_${artist_name}, ${name_of_publication}, ${date_of_artwork} | ${medium_of_artwork} | ${revised_medium}`,
        image: artwork[0]?.value,
        index: +item?.find((nestedItem) => nestedItem?.key === "sl_no")?.value,
        wnn: artwork[0]?.wnn,
        full_desc: `${artist_name} | ${medium_of_artwork}, ${date_of_artwork}_${revised_medium} ${artwork[0]?.wnn}`,
        date_of_artwork: date_of_artwork,
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await filmEditorialByMasterlist("editorials", masterlist);
      if (result) {
        setEditorialData(citationFactor(result));
        setImgData(citationFactor(result));
      }
    };
    const getDesc = async () => {
      const result = await getFilmData.filmByAlias("films", masterlist);
      if (result) {
        setDesc(result?.find((it) => it?.key === "editorial_text")?.value);
      }
    };
    getData();
    getDesc();
  }, [masterlist]);

  useEffect(() => {
    if (editorialData && editorialData.length > 0) {
      setSelectedImg({
        image: editorialData[0].image,
        desc: editorialData[0].desc,
        index: 0,
        slideImage: editorialData[0].image,
      });
    }
  }, [editorialData]);

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);

  const showPosterImages = (image, flag) => {
    if (image) {
      setPosterFullScreenImage(flag);
      setposterImgData(image);
    } else {
      setPosterFullScreenImage(flag);
      setposterImgData(null);
    }
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setPosterFullScreenImage(true);
  };

  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index + 1;
    const nextSlide = imgData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index - 1;
    const nextSlide = imgData[activeIndex];
    // swiperRef.current.swiper.slideTo(index);

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      if (readmoreNavigation?.title === "editorial") {
        setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
        setPosterFullScreenImage(true);
      }
    }
  }, [readmoreNavigation]);

  return (
    <>
      <Stack
        width={{ base: "100%", md: "1135px" }}
        mt={"20px"}
        height="calc(100vh - 300px)"
      >
        <Stack width={{ base: "100%", md: "60%" }}>
          <TextComponent
            text={desc}
            fontSize={"15px"}
            fontWeight="400"
            textAlign="justify"
            color={"black"}
            onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
          />
        </Stack>
        <Stack
          direction={"row"}
          borderWidth={"0.4px"}
          borderColor={"#e6e6e6"}
          bg={"white"}
          height={{ base: "100%", md: "500px" }}
          py={4}
        >
          <Stack py={5} marginLeft={{ base: "10px", md: "25px" }}>
            <Box
              mx={{ base: 0, md: 12 }}
              display={"flex"}
              justifyContent={"center"}
              height={{ base: "auto", md: "419px" }}
              width={{ base: "100%", md: "700px" }}
            >
              <Image
                cursor={"pointer"}
                width={"auto"}
                height={"inherit"}
                objectPosition={"center"}
                src={selectedImg.image}
                onClick={() =>
                  handleImageClick(selectedImg.image, selectedImg.index)
                }
              />
            </Box>
            <Text
              textAlign={"center"}
              fontSize={"15px"}
              fontWeight={"400"}
              color="#035DA1"
              mt={"20px"}
            >
              {selectedImg.desc}
            </Text>
          </Stack>

          <Stack
            position={"relative"}
            id="allsliderskkEditorial"
            paddingLeft={{ base: "0px", md: "40px" }}
            py={4}
          >
            <Swiper
              // grabCursor={true}
              ref={swiperRef}
              navigation={true}
              modules={[Navigation, Pagination]}
              direction="vertical"
              // loop={true}
              slidesPerView={4}
              slidesPerGroup={1}
              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
            >
              {editorialData &&
                editorialData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Stack
                      onClick={() => {
                        swiperRef.current.swiper.slideTo(
                          swiperRef.current.swiper.activeIndex
                        );
                        setSelectedImg({
                          image: item.image,
                          desc: item.desc,
                          index: index,
                          slideImage: item.image,
                        });
                      }}
                      style={{
                        border:
                          selectedImg.slideImage === item.image
                            ? "1px solid #A4A4A4"
                            : "none",
                        width: { base: "100px", md: "185px" },
                        height: { base: "auto", md: "98px" },
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={{ base: "100px", md: "170px" }}
                        mx={"auto"}
                        height={{ base: "100%", md: "95px" }}
                      >
                        <Image
                          width={"auto"}
                          height={"inherit"}
                          objectPosition={"center"}
                          cursor={"pointer"}
                          src={item.image}
                        />
                      </Box>
                    </Stack>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Stack>
        </Stack>
      </Stack>
      <FullScreenImage
        isOpen={posterFullScreenImage}
        onClose={() => showPosterImages(null, false)}
        imageData={posterImgData}
        data={fullImgData}
        clickedImageIndex={clickedImageIndex}
        setClickedImageIndex={setClickedImageIndex}
        setReadMoreFlag={setReadMoreFlag}
      />
    </>
  );
}
export default Editorial;

export const FullScreenImage = ({
  isOpen,
  onClose,
  imageData,
  clickedImageIndex,
  data,
  setClickedImageIndex,
  setReadMoreFlag,
}) => {
  return (
    <Container maxW={"auto"} px="0px">
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"}>
          <ModalBody margin={{ base: "0", md: "16" }}>
            <Box
              bg={"white"}
              justifyContent={"space-between"}
              position={"relative"}
            >
              <Stack>
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  // loop={true}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data.map((item, index) => {
                    return (
                      <SwiperSlide key={index + 1}>
                        <Stack
                          maxW={"1180px"}
                          marginTop={{ base: "20px", md: "0" }}
                          flexDirection={{ base: "column", md: "row" }}
                          className="row"
                          alignItems={"flex-start"}
                          gap={"0"}
                        >
                          <HStack
                            className="col-sm-12 col-md-7"
                            justifyContent={"center"}
                            border={"0.01rem solid #d7d7d7"}
                            borderRadius={"5px"}
                          >
                            <Stack m={{base:"8",md:"4"}}>
                              <Box
                                display={"block"}
                                justifyContent={"center"}
                                width={"100%"}
                                height={{ base: "100%", md: "550px" }}
                              >
                                <Image
                                  src={
                                    item?.fullImage
                                    // imageData !== null
                                    //   ? "https://d3fsixjuo450s3.cloudfront.net/" + imageData
                                    //   : ""
                                  }
                                  width={"100%"}
                                  height={"inherit"}
                                  objectFit={"contain"}
                                  alt=""
                                />
                              </Box>
                            </Stack>
                          </HStack>
                          <HStack
                            alignItems={"flex-start"}
                            className="col-sm-12 col-md-5"
                            flexDirection={"column"}
                          >
                            <Box
                              pb={"4"}
                              width={"100%"}
                            >
                              <Text
                                fontSize={"20px"}
                                fontWeight={"700"}
                                color="#0066B3"
                              >
                                {item?.author} | {item?.title}
                                <br />
                                {item?.document_type} | {item?.imgNum}
                              </Text>
                      
                            </Box>
                            <Stack  
                              borderBottom={"1px"}
                              borderColor={"#e8e8e8"} width={"100%"} 
                              pb="4" 
                              marginInlineStart={"0rem!important"}>
                            <Stack
                                marginInlineStart={"0rem!important"}
                                py="1"
                                gap={"2"}
                                className="mt-0 px-0"
                              >
                                <HStack className="mt-0 px-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {filmography.DATE_OF_ARTWORK}
                                  </Text>
                                </HStack>
                                <HStack className="mt-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                  >
                                    {item?.date_of_artwork}
                                  </Text>
                                </HStack>
                              </Stack>
                              <Stack
                                marginInlineStart={"0rem!important"}
                                py="1"
                                gap={"2"}
                                className="mt-0 px-0"
                              >
                                <HStack className="mt-0 px-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {filmography.PHOTOGRAPHER}
                                  </Text>
                                </HStack>
                                <HStack className="mt-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    // color="#035DA1"
                                  >
                                    {item?.photographer}
                                  </Text>
                                </HStack>
                              </Stack>
                              <Stack
                                marginInlineStart={"0rem!important"}
                                py="1"
                                gap={"2"}
                                className="mt-0 px-0"
                              >
                                <HStack className="mt-0 px-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {filmography.FEATURED_ACTORS}
                                  </Text>
                                </HStack>
                                <HStack className="mt-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    // color="#035DA1"
                                  >
                                    {item?.featured_actors}
                                  </Text>
                                </HStack>
                              </Stack>
                            </Stack>
                            <Box
                              py="2"
                              className="ml-0"
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                            >
                              <Text
                                fontSize={"14px"}
                                fontWeight={"400"}
                                color="#000000"
                              >
                                {item?.description1}
                              </Text>
                            </Box>
                            
                            <Stack marginInlineStart={"0rem!important"}>
                              <Box>
                                <Text
                                  fontSize={"15px"}
                                  fontWeight={"700"}
                                  color="#035DA1"
                                  cursor={"pointer"}
                                  onClick={() => {
                                    // onClose();
                                    setReadMoreFlag(
                                      true,
                                      isOpen,
                                      index,
                                      "editorial"
                                    );
                                  }}
                                >
                                  {common.READ_MORE}
                                </Text>
                              </Box>
                            </Stack>
                          </HStack>
                          <HStack>
                            <Image
                              className="editorialclosebtn"
                              // top={{ base: "1%!important", md: "" }}
                              // right={"0%!important"}
                              src={closeIcon}
                              onClick={() => {
                                onClose();
                              }}
                              cursor={"pointer"}
                            ></Image>
                          </HStack>
                        </Stack>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
