import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Box, Container, Image, Stack } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const LandingPageSlider=({ initialSlide,className,data}) =>{
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      const isLastItem = index === data.length - 1;
      const pipeCharacter = isLastItem ? "" : " | ";
      return `<span class="${className}">${data[index].label}</span>${pipeCharacter}`;
    },
  };
  const swiperRef = useRef(null);
  useEffect(() => {
    if (initialSlide !== null) {
      swiperRef?.current?.swiper?.slideTo(0);
    }
  }, [initialSlide]);

  return (
    <>
      <Container maxW="auto" className="songs" id="allslider">
        <Stack width={"100%"} position={"relative"} mb="20">
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            navigation
            height={"100%"}
            id={className ? className :"subsliderssk"}
            pagination={pagination}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                    <Link
                      key={index}
                      to={item.to}
                    >
                       <Box width={"100%"}  height={{base:"auto",md:"520px"}}>
                      <Image src={item.image} width={"auto"} height={"inherit"} alt="" />
                      </Box>
                    </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Stack>
      </Container>
    </>
  );
}
export default LandingPageSlider;
