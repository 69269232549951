import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { A11y, EffectCreative, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

//Service file
import { getPostsByResearchCategoryAlias } from "../../../../services/Post";

//Component file
import H2 from "../../../../components/layouts/Typography/H2";
import Large from "../../../../components/layouts/Typography/Large";
import Layout from "../../../../components/sections/SidebarLayout";
import { SocialMediaButton } from "../../../../CommonComponents/SocialMediaButton";
import H4 from "../../../../components/layouts/Typography/H4";

//Language file
import { common } from "../../../../constants/constants";

function RCPostDetails() {
  const { sliderIndex } = useParams();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistTitle = parts[1];
  const post = parts[4];

  const extractPost = (data, post) => {
    return data.filter((item) => item?.alias === post);
  };
  const [postDetailsData, setPostDetailsData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const result = await getPostsByResearchCategoryAlias("", parts[2]);
      if (result) {
        setPostDetailsData(extractPost(result, post));
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSlideChange = (swiper) => {};
  const navigate = useNavigate();

  return (
    <Layout>
      <Container maxW={"1400px"} id="allslider">
        <Stack>
          <Stack
            width={"100%"}
            paddingBottom={{base:12,md:4}}
            alignItems={"center"}
          >
            <Stack width={"100%"} position={"relative"} id="postdetailslider">
              <Swiper
                modules={[Navigation, Pagination, EffectCreative, A11y]}
                slidesPerView={1}
                loop={false}
                effect="creative"
                navigation
                height={"100%"}
                onSlideChange={handleSlideChange}
                onSlideChangeTransitionEnd={handleSlideChange}
                initialSlide={sliderIndex ? parseInt(sliderIndex) : 0}
              >
                {postDetailsData.length > 0 &&
                  postDetailsData?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <Stack flexDirection={"column"}>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            width={"100%"}
                            height={{ base: "100%", md: "533px" }}
                          >
                            <Image
                              src={item?.fullImage}
                              alt=""
                              width={"auto"}
                              cursor={"pointer"}
                              height={"100%"}
                            />
                          </Box>
                          <Stack pt={"32px"}>

                          <H2 pb="1">
                                {" "}
                                {item?.title !== ""
                                  ? item?.title
                                  : common.CONTENT_AWAITED}
                              </H2>
                              <H4 pb="1">
                                {" "}
                                {item?.title !== ""
                                  ? item?.title
                                  : common.CONTENT_AWAITED}
                              </H4>
                          <Stack
                            flexDirection={"row"}
                            paddingY={"8"}
                            className="row"
                            gap={"58px"}
                          >
                            <HStack
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              className="col-sm-12 col-md-4"
                              px={{ base: "0", md: "4" }}
                              justifyContent={"start"}
                            >
                              <Large marginInlineStart={"0rem!important"}>
                                {item?.desc}
                              </Large>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              justifyContent={"start"}
                              className="col-sm-12 col-md-4"
                              px={{ base: "0", md: "4" }}
                            >
                              <Large marginInlineStart={"0rem!important"}>
                                {item?.desc1}
                              </Large>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              justifyContent={"start"}
                              flexDirection={"column"}
                              className="col-sm-12 col-md-2"
                              px={{ base: "0", md: "2" }}
                            >
                              <HStack py={{base:"2",md:"0"}} gap={"10px"} wrap={"wrap"}>
                                {item?.primaryButton &&
                                  item?.primaryButton.map((it) => (
                                    <Button
                                      className={`keyword-btn ${
                                        it?.link ? `primary-color` : ``
                                      }`}
                                      marginInlineStart={"0rem!important"}
                                      onClick={() => navigate(`${it?.link}`)}
                                      width={"94px"}
                                    >
                                      {it?.name}
                                    </Button>
                                  ))}
                                {item?.secondaryButton &&
                                  item?.secondaryButton.map((it) => (
                                    <Button
                                      className={`keyword-btn ${
                                        it?.link ? `secondary-color` : ``
                                      }`}
                                      width={"94px"}
                                      marginInlineStart={"0rem!important"}
                                    >
                                      {it?.name}
                                    </Button>
                                  ))}
                                {item?.button8 && (
                                  <Button
                                    className="keyword-btn blob-button"
                                    marginInlineStart={"0rem!important"}
                                    onClick={() =>
                                      navigate(
                                        `/${masterlistTitle}/insight/${
                                          post === item?.post && item?.insight
                                        }`
                                      )
                                    }
                                  >
                                    {item?.button8}
                                  </Button>
                                )}
                                {item?.keyWordButtons &&
                                  item?.keyWordButtons.map((it) => (
                                    <Button
                                      className={`keyword-btn ${
                                        it?.link ? `keyword-color` : ``
                                      }`}
                                      width={"94px"}
                                      marginInlineStart={"0rem!important"}
                                      onClick={() => navigate(`${it?.link}`)}
                                    >
                                      {it?.name}
                                    </Button>
                                  ))}
                              </HStack>
                              <HStack
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                                marginInlineStart={"0rem!important"}
                              >
                                <Text
                                  py="4"
                                  fontSize={"18px"}
                                  fontWeight={"600"}
                                >
                                  {common.SHARE}
                                </Text>
                                <SocialMediaButton />
                              </HStack>
                            </HStack>
                          </Stack>
                          </Stack>

                        </Stack>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
}
export default RCPostDetails;
