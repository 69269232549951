import axios from "axios";
import { raj_kapoor_soundtrack, silsila_soundtrack, skk_soundtrack } from "../MockData/LyricsSound";
import { transformJSON } from "../util/transformer";
import { schema } from "./schemas/LyricsSoundtrack";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const lyricsSoundtrackByFlimAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery ($where: SoundTrackWhere){` +
      tableName +
      ` (where: $where){
        alias
        duration_of_song
        name
        name_of_song_language_2
        name_of_song_language_3
        song_link
        personality {
          alias
          name
          first_name
          last_name
          full_name
        }
        lyricsImage {
          wnn
          accession_number
          alias
          name_of_publication
          publication_year
          sortable_year_of_publication
          artist_name
          date_of_artwork
          medium_of_artwork
          revised_medium
          id
          image
          title
          direct_url_of_preview_image
        }
        language {
          id
          name
          alias
        }
        masterlist
      }
    }`,
    variables: {
      where: {
        masterlist: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.soundTrack) {
    return result?.data?.data?.soundTrack;
  } else {
    return [];
  }
};

export const lyricsSoundtrackByFlim = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getLyricsSoundtrackDummyData(tableName, masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
  const response = await lyricsSoundtrackByFlimAPI(tableName, masterlist);
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getLyricsSoundtrackDummyData = async (tableName, masterlist) => {
  
  const lyricsSoundtrackDataMap = {
    "shatranj-ke-khilari-1977": skk_soundtrack,
    "mughal-e-azam": skk_soundtrack,
    "silsila":silsila_soundtrack,
    "raj-kapoor":raj_kapoor_soundtrack,
  };
  const lyricsSoundtrackData = lyricsSoundtrackDataMap[masterlist] || skk_soundtrack;
  return lyricsSoundtrackData;
};
