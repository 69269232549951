import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import React from "react";

export default function TeachingDrawerDetails({
  familyImgData,
  showFamilyMemberImages,
  showFullImages,
  closeIcon,
}) {
  return (
    <>
      {familyImgData && (
        <>
          <Box
            position={"absolute"}
            right={{base:"0",md:"400px"}}
            top={0}
            zIndex={99999}
            background={"white"}
            width={{base:"100%",md:"794px"}}
            height={"100vh"}
            display={"flex"}
          > 
            <Image
              src={closeIcon}
              paddingLeft="5px"
              position="absolute"
              top={"18px"}
              right={{base:"18",md:"40px"}}
              cursor={"pointer"}
              onClick={() => showFamilyMemberImages(null, false)}
            />
            <Stack
              justifyContent={"space-evenly"}
              width={"100%"}
              alignItems={"flex-start"}
              // marginTop={"64px"}
              height={{base:"100%",md:"737px"}}
              flexDirection={"row"}
            >
              <HStack justifyContent={"center"} margin={"auto"}>
                <Image
                  src={
                    familyImgData !== null ||
                    familyImgData !== "undefined" ||
                    familyImgData !== undefined
                      ? /* "https://d3fsixjuo450s3.cloudfront.net/" + */
                        familyImgData
                      : ""
                  }
                  width={"auto"}
                  height={"100%"}
                  // marginTop={"18px"}
                  alt=""
                />
              </HStack>
              <HStack justifyContent="flex-end">
                <Text
                  fontSize={"xl"}
                  bg={"black"}
                  color={"white"}
                  fontWeight={"400"}
                  padding="2"
                  cursor={"pointer"}
                  borderRadius={"35px"}
                  width="max-content"
                  // marginTop="-200px"
                  // marginRight={"100px"}
                  position={"absolute"}
                  right={"32px"}
                  bottom={"70px"}
                  onClick={() => showFullImages(true)}
                >
                  <CgArrowsExpandRight />
                </Text>
              </HStack>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
}
