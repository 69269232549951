import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

//Core files
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";

// Service files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";
import { CopyPasteService } from "../../services/CopyPaste";

//Component files
import MobileNav from "../../components/Topheader";
import ScrollToTop from "../../components/sections/ScrollToTop";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import Screenings from "./EconomicsOfCinema/Screenings";
import AwardsHonours from "./EconomicsOfCinema/AwardsHonours";
import BoxOffice from "./EconomicsOfCinema/BoxOffice";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

//Language files
import { explore } from "../../constants/constants";

export default function EconomicsOfCinema({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {alias}=useParams();
  const masterlist = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState({});

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        masterlist
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].link
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };

    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        masterlist
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }`,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    window.scrollTo(0, 0);
  }, [masterlistType, masterlist]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        alias={alias}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} masterlistType={masterlistType}/>
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <EconomicsOfCinemaBody 
        masterlist={masterlist}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  horislinkData,
  artistDetails,
  introData,
  alias,
  masterlistType,
  ...rest
}) => {

  const oSections = [
    {
      to: `/${masterlistType}/${alias}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${alias}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
      sections={introData}
      oSections={oSections}
      hMenu={horislinkData}
      artistDetails={artistDetails}
      handleNavigation={handleNavigation}
    />
    </>
  );
};

const EconomicsOfCinemaBody = ({masterlist}) => {
  return (
    <Stack>
      <Container maxW="auto" width={"inherit"} mx={{base:"0",md:"10"}} className="songs">
        <Tabs >
          <TabList py={{base:"4",md:"0"}} pt={{base:"0",md:"8"}} position="sticky" top="64px" bg="white" zIndex={1}>
            <Tab
              className="no-wrap"
              p="0"
              mr="8"
              fontSize={"16px"}
              fontWeight={"400"}
              _selected={{
                borderBottom: "4px",
                color: "black",
                borderBottomColor: "black",
                fontWeight: "700",
                borderRadius: "4px",
              }}
            >
              {explore.SCREENINGS}
            </Tab>
            <Tab
               className="no-wrap"
              p="0"
              mr="8"
              fontSize={"16px"}
              fontWeight={"400"}
              _selected={{
                borderBottom: "4px",
                color: "black",
                borderBottomColor: "black",
                fontWeight: "700",
                borderRadius: "4px",
              }}
            >
              {explore.AWARDS_AND_HONOURS}
            </Tab>
            <Tab
               className="no-wrap"
              p="0"
              mr="8"
              fontSize={"md"}
              fontWeight={"400"}
              _selected={{
                borderBottom: "4px",
                color: "black",
                borderBottomColor: "black",
                fontWeight: "700",
                borderRadius: "4px",
              }}
            >
              {explore.BOX_OFFICE}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0px">
              <Screenings
                CopyPasteService={CopyPasteService}
                tableName={"screenings"}
                masterlist={masterlist}
              />
            </TabPanel>
            <TabPanel p="0px">
              <AwardsHonours
                CopyPasteService={CopyPasteService}
                tableName={"awards"}
                masterlist={masterlist}
              />
            </TabPanel>
            <TabPanel height={"100vh"} px="0px">
              <BoxOffice masterlist={masterlist} />
            </TabPanel>
          </TabPanels>
          <ScrollToTop />
        </Tabs>
      </Container>
    </Stack>
  );
};
