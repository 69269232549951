import { 
  Box, 
  HStack, 
  Image, 
  Stack, 
  Text 
} from "@chakra-ui/react";

//Core file
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

//Service file
import { getIntroductionByAlias } from "../../../services/ResearchCategoryDetails";

//Language file
import { common } from "../../../constants/constants";

function Introduction({rcAlias}) {
  const [sliderImages, setSliderImages] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getIntroductionByAlias("", rcAlias);
        if (result) {
          setSliderImages(result[0]?.images);
          setDescription(result[0]?.description);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [rcAlias]);

  return (
    <>
      <Stack width={"100%"} position={"relative"} id="rc_slider">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          slidesPerView={1}
          loop={false}
          height={"100%"}
        >
          {sliderImages.length > 0 &&
            sliderImages?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    width={"100%"}
                    height={{ base: "100%", md: "100%" }}
                  >
                    <Image
                      src={item}
                      alt=""
                      width={"100%"}
                      height={"inherit"}
                    />
                  </Box>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Stack>
      <Stack flexDirection={"row"} paddingY={"8"}>
        <HStack alignItems={"flex-start"} px={{ base: "4", md: "20" }}>
          <Text fontSize={"16px"} marginInlineStart={"0rem!important"}>
            {description}
            <Link to="/research-category/read-more">
              <Text
                px="1"
                as={"span"}
                fontSize={"16px"}
                fontWeight={"600"}
                color={"#035DA1"}
                marginInlineStart={"0rem!important"}
              >
                {common.READ_MORE}
              </Text>
            </Link>
          </Text>
        </HStack>
      </Stack>
    </>
  );
}
export default Introduction;
