import { 
  Box, 
  Container, 
  Drawer, 
  DrawerContent, 
  Stack, 
  useColorModeValue, 
  useDisclosure 
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "react-icons/io"

//Component file
import LeftVmenu from "../../../FunctionalComponents/LeftVmenu";
import MobileNav from "../../../components/Topheader";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

function RCInsights() {
  return (
    <SidebarWithHeader>
      <Stack>
        <Container>
         <WebpageComingSoon/>
        </Container>
      </Stack>
      </SidebarWithHeader>
  );
}
export default RCInsights;

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
