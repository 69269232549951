import { Box, HStack, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Small from "../../components/layouts/Typography/Small";
import H5 from "../../components/layouts/Typography/H5";
import { getMasterlistData } from "../../services/MasterlistService";
import { common } from "../../constants/constants";
import { CopyPasteService } from "../../services/CopyPaste";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// "https://d3fsixjuo450s3.cloudfront.net/introduction/Raza-SH_bw-with-colour-painting%2001.jpg"

function Masterlists() {
  // getExploreData
  const [masterlistData, setMasterlistData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);
  const { t } = useTranslation("");

  React.useEffect(() => {
    const getData = async () => {
      const result = await getMasterlistData();
      if (result?.length > 0) {
        setMasterlistData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);

  return (
    <Stack p="0">
      {showLabel ? (
        <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
          {t(common.WEBPAGE_COMING_SOON)}
        </Text>
      ) : (
        <>
          <SimpleGrid columns={{ base: 2, md: 3, lg: 5, xl: 6 }} spacing="20px">
            {masterlistData?.map((item) => {
              return (
                <Box height="auto">
                  <Link
                    to={item.link === "" ? null : item.link}
                    cursor={item.link === "" ? "" : "pointer"}
                    textDecoration={"none"}
                  >
                    <Image
                      src={item.imgPath}
                      className={"img-border-radius hover14"}
                      height="138px"
                      objectFit={"contain"}
                      width="100%"
                    />
                  </Link>
                  <Stack py="2">
                    <HStack
                      onCopy={(e) =>
                        CopyPasteService(e, `${window.location.to}/4`)
                      }
                    >
                      <H5 textAlign={"left"}>{item.title}</H5>
                    </HStack>
                    {item.published && (
                      <HStack>
                        <Small textAlign={"left"} color={"#C41212"}>
                          Published
                        </Small>
                      </HStack>
                    )}
                  </Stack>
                </Box>
              );
            })}
          </SimpleGrid>
        </>
      )}
    </Stack>
  );
}
export default Masterlists;
