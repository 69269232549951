export const schema = [
  { key: "id", display: "Id", seq: 1 },
  { key: "sl_no", display: "No.", seq: 2 },
  { key: "alias", display: "Alias", seq: 3 },
  { key: "subject", display: "Subject", seq: 4 },
  { key: "masterlist_type", display: "Masterlist Type", seq: 5 },
  { key: "short_description", display: "Short Description", seq: 6 },
  { key: "rated", display: "Rated", seq: 7 },
  { key: "duration_mins", display: "Duration", seq: 8 },
  { key: "certificate_number", display: "Certificate Number", seq: 9 },
  { key: "date_of_issue", display: "Date of Issue", seq: 10 },
  { key: "date_of_expiry", display: "Date of Expiry", seq: 11 },
  { key: "color", display: "Color", seq: 12 },
  { key: "lab", display: "LAB", seq: 13 },
  { key: "gauge", display: "Gauge", seq: 14 },
  { key: "length_of_reels", display: "Length of Reels", seq: 15 },
  { key: "number_of_reels", display: "Number of Reels", seq: 16 },
  { key: "shooting_period", display: "Shooting Period", seq: 17 },
  { key: "editorial_text", display: "Editorial Text" },
  { key: "synopsis", display: "Synopsis", seq: 18 },
  { key: "release_date", display: "Release Date", seq: 19 },
  { key: "first_release_location", display: "First Release Location", seq: 20 },
  { key: "trailer_link", display: "Watch Film Trailer", seq: 21 },
  { key: "full_movie_link", display: "Watch Full Film", seq: 22 },
  { key: "eastmancolor_by", display: "Eastmancolor By", seq: 23 },
  { key: "researchCategories", display: "Research Categories", seq: 24 },
  { key: "filmTitleImage", display: "Film Title Image", seq: 25 },
  { key: "posterImages", display: "Poster Images", seq: 26 },
  { key: "publicityImages", display: "Publicity Images", seq: 27 },
  { key: "certificateImages", display: "Certificate Images", seq: 28 },
  { key: "language", display: "Languages", seq: 29 },
  { key: "studios", display: "Studios", seq: 30 },
  { key: "location", display: "On Location", seq: 31 },
  { key: "synopsisImages", display: "Synopsis Images", seq: 32 },
  { key: "filmMainImage", display: "Film Main Image", seq: 33 },
  { key: "filmBowCreditImage", display: "Film Bow Credit Image", seq: 34 },
  { key: "banner", display: "Banner", seq: 35 },
  { key: "storyWriter", display: "Story Writer", seq: 36 },
  { key: "cameraman", display: "Cameraman", seq: 37 },
  { key: "assistant_of_cameraman", display: "assistant_of_cameraman", seq: 38 },
  { key: "art_director", display: "art_director", seq: 39 },
  {
    key: "associate_of_art_director",
    display: "associate_of_art_director",
    seq: 40,
  },
  { key: "costume_designer", display: "costume_designer", seq: 41 },
  {
    key: "associate_of_costume_designer",
    display: "associate_of_costume_designer",
    seq: 42,
  },
  { key: "sound", display: "sound", seq: 43 },
  { key: "assistant_of_sound", display: "assistant_of_sound", seq: 44 },
  { key: "editor", display: "editor", seq: 45 },
  { key: "assistant_of_editor", display: "assistant_of_editor", seq: 46 },
  { key: "make_up_artist", display: "make_up_artist", seq: 47 },
  {
    key: "assistant_of_make_up_artist",
    display: "assistant_of_make_up_artist",
    seq: 48,
  },
  { key: "wardrobe", display: "wardrobe", seq: 49 },
  { key: "backdrop", display: "backdrop", seq: 50 },
  { key: "assistant_director", display: "assistant_director", seq: 51 },
  { key: "special_assistant", display: "special_assistant", seq: 52 },
  { key: "production_executive", display: "production_executive", seq: 53 },
  { key: "production_manager", display: "production_manager", seq: 54 },
  { key: "production_assistant", display: "production_assistant", seq: 55 },
  { key: "studio", display: "studio", seq: 56 },
  { key: "equipment_manager", display: "equipment_manager", seq: 57 },
  { key: "office_manager", display: "office_manager", seq: 58 },
  {
    key: "assistant_of_office_management",
    display: "assistant_of_office_management",
    seq: 59,
  },
  { key: "animator", display: "animator", seq: 60 },
  { key: "graphic_designer", display: "graphic_designer", seq: 61 },
  {
    key: "assistant_of_graphic_designer",
    display: "assistant_of_graphic_designer",
    seq: 62,
  },
  {
    key: "associate_of_graphic_designer",
    display: "associate_of_graphic_designer",
    seq: 63,
  },
  {
    key: "publicity_liasion_officer",
    display: "publicity_liasion_officer",
    seq: 64,
  },
  { key: "stills_photographer", display: "stills_photographer", seq: 65 },
  { key: "music_director", display: "music_director", seq: 66 },
  { key: "dancer", display: "dancer", seq: 67 },
  { key: "choreographer", display: "choreographer", seq: 68 },
  { key: "re_recorder", display: "re_recorder", seq: 69 },
  { key: "music_recorder", display: "music_recorder", seq: 70 },
  { key: "dialogue_writer", display: "dialogue_writer", seq: 71 },
  { key: "narrator", display: "narrator", seq: 72 },
  { key: "film_producer", display: "film_producer", seq: 73 },
  { key: "screenplay", display: "screenplay", seq: 74 },
  { key: "location_manager", display: "location_manager", seq: 75 },
];

export const prefaceSchema = [
  { key: "short_description", display: "Short Description", seq: 1 },
  {
    key: "posterImages",
    display: "Poster Images",
    processor: "imageAliasArray",
    seq: 2,
  },
  {
    key: "publicityImages",
    display: "Publicity Images",
    processor: "imageAliasArray",
    seq: 3,
  },
  { key: "alias", display: "Alias", seq: 4 },
];

export const specificationSchema = [
  { key: "alias", display: "Alias", seq: 1 },
  { key: "rated", display: "Rated", seq: 2 },
  { key: "language", display: "Languages", seq: 3 },
  { key: "duration_mins", display: "Duration", seq: 4 },
  { key: "color", display: "Color", seq: 5 },
  { key: "lab", display: "LAB", seq: 6 },
  { key: "gauge", display: "Gauge", seq: 7 },
  { key: "length_of_reels", display: "Length of Reels", seq: 8 },
  { key: "number_of_reels", display: "Number of Reels", seq: 9 },
  { key: "shooting_period", display: "Shooting Period", seq: 10 },
  { key: "studios", display: "Studio", seq: 11 },
  // { key: "studio", display: "Studio", seq: 12 },
  { key: "location", display: "On Location", seq: 13 },
  { key: "certificate_number", display: "Certificate Number", seq: 14 },
  {
    key: "certificateImages",
    display: "certificateImages",
    processor: "imageAliasArray",
    seq: 15,
  },
  { key: "date_of_issue", display: "Date of Issue", seq: 16 },
  { key: "date_of_expiry", display: "Date of Expiry", seq: 17 },
];

export const synopsisSchema = [
  { key: "alias", display: "Alias", seq: 1 },
  { key: "synopsis", display: "Synopsis", seq: 2 },
  {
    key: "synopsisImages",
    display: "Synopsis Images",
    processor: "imageAliasArray",
    seq: 3,
  },
];

export const filmCreditsSchema = [
  { key: "banner", display: "Banner", processor: "creatorAliasArray", seq: 1 },
  { key: "storyWriter", display: "Story", processor: "creatorAliasArray", seq: 2 },
  { key: "leadCast", display:"Lead Cast", processor: "creatorAliasArray", seq: 3},
  { key: "cameraman", display: "Camera", processor: "creatorAliasArray", seq: 4 },
  { key: "assistant_of_cameraman", display: "Assistants", processor: "creatorAliasArray", seq: 5 },
  { key: "art_director", display: "Art Direction", processor: "creatorAliasArray", seq: 6 },
  { key: "associate_of_art_director", display: "Associate", processor: "creatorAliasArray", seq: 7 },
  { key: "costume_designer", display: "Costumes", processor: "creatorAliasArray", seq: 8 },
  { key: "associate_of_costume_designer", display: "Associates", processor: "creatorAliasArray", seq: 9 },
  { key: "sound", display: "Sound", processor: "creatorAliasArray", seq: 10 },
  { key: "editor", display: "Editing", processor: "creatorAliasArray", seq: 11 },
  { key: "assistant_of_editor", display: "Assistant", processor: "creatorAliasArray", seq: 12 },
  { key: "make_up_artist", display: "Make up", processor: "creatorAliasArray", seq: 13 },
  { key: "assistant_of_make_up_artist", display: "Assistant", processor: "creatorAliasArray", seq: 14 },
  { key: "wardrobe", display: "Wardrobe", processor: "creatorAliasArray", seq: 15 },
  { key: "backdrop", display: "Backdrop", processor: "creatorAliasArray", seq: 16 },
  { key: "assistant_director", display: "Assistant Directors", processor: "creatorAliasArray", seq: 17 },
  { key: "special_assistant", display: "Special Assistant", processor: "creatorAliasArray", seq: 18 },
  { key: "production_executive", display: "Production Executive", processor: "creatorAliasArray", seq: 19 },
  { key: "production_manager", display: "Production Manager", processor: "creatorAliasArray", seq: 20 },
  { key: "location_manager", display: "Location Management", processor: "creatorAliasArray", seq: 21 },
  { key: "production_assistant", display: "Production Assistants", processor: "creatorAliasArray", seq: 22 },
  { key: "studios", display: "Studios", processor: "creatorAliasArray", seq: 23 },
  { key: "eastmancolor_by", display: "Eastmancolor By", seq: 24 },
  { key: "equipment_manager", display: "Equipment", processor: "creatorAliasArray", seq: 25 },
  { key: "office_manager", display: "Office Management", processor: "creatorAliasArray", seq: 26 },
  { key: "animator", display: "Animation", processor: "creatorAliasArray", seq: 27 },
  { key: "graphic_designer", display: "Graphic Design", processor: "creatorAliasArray", seq: 28 },
  { key: "publicity_liasion_officer", display: "Publicity & Liasion", processor: "creatorAliasArray", seq: 29 },
  { key: "stills_photographer", display: "Stills", processor: "creatorAliasArray", seq: 30 },
  // { key: "music_director", display: "Songs", processor:"creatorAliasArray", seq: 31 },
  { key: "dancer", display: "Dances", processor: "creatorAliasArray", seq: 32 },
  { key: "choreographer", display: "Dances composed and choreographed by", processor: "creatorAliasArray", seq: 33 },
  { key: "re_recorder", display: "Re-recording", processor: "creatorAliasArray", seq: 34 },
  { key: "music_recorder", display: "Music Recording", processor: "creatorAliasArray", seq: 35 },
  { key: "dialogue_writer", display: "Dialogue", processor: "creatorAliasArray", seq: 36 },
  { key: "narrator", display: "Narration", processor: "creatorAliasArray", seq: 37 },
  { key: "film_producer", display: "Producer", processor: "creatorAliasArray", seq: 38 },
  { key: "screenplay", display: "Screenplay, Music & Direction", processor: "creatorAliasArray", seq: 39 },
];

export const filmCastSchema = [
  { key: "film_producer", display: "Film Producer", seq: 1 },
  { key: "film_director", display: "Film Director", seq: 2 },
  { key: "script", display: "Script", seq: 3 },
  { key: "screenplay", display: "Screenplay", seq: 4 },
  { key: "dialogue_writer", display: "Dialogue", seq: 5 },
  { key: "music_director", display: "Music Director", seq: 6 },
  { key: "editor", display: "Editing", seq: 7 },
  { key: "cameraman", display: "Cinematography", seq: 8 },
  { key: "art_director", display: "Art Direction", seq: 9 },
  { key: "stills_photographer", display: "Still Photographer", seq: 10 },
  { key: "poster_designer", display: "Poster Designer", seq: 11 },
  { key: "narrator", display: "Narrator", seq: 12 },
  { key: "costume_designer", display: "Costume Designer", seq: 13 },
  { key: "choreographer", display: "Choreography", seq: 14 },
];

