import { Box, HStack, Image, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from "@chakra-ui/react";

//Core files
import React, { Fragment, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";

// Service files
import * as getAwardData from "../../../services/AwardsHonours";

//Component files
import ScrollToTop from "../../../components/sections/ScrollToTop";

//Language files
import { common } from "../../../constants/constants";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";

function AwardsHonours({ CopyPasteService, tableName, masterlist }) {
  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);


  const showFullImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };
  
  const extractedImages = (data) => {
    let extractImg = data?.flatMap((entry) => {
      const awardImage = entry?.find(
        (item) => item.key === "awardImage"
      );
      if (awardImage && awardImage?.value.length > 0) {
        return awardImage?.value?.map((image) => image);
      }
      return [];
    });
    return extractImg;
  };

  const [awardsHonours, setAwardsHonours] = useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getAwardData.awardshonoursByWinner(
        tableName,
        masterlist
      );
      if (result) {
        setAwardsHonours(result);
        setImageData(extractedImages(result));
      }
    };
    getData();
  }, [tableName, masterlist]);

  return (
    <>
      {awardsHonours.length !== 0 ? (
        <Stack position={"relative"} py="4" flexDirection={{base:"column",md:"row"}} px="0px">
          <HStack
            flexDirection={"column"}
            alignItems={"baseline"}
            className="col-md-8 col-sm-12 mt-0"
            onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
          >
            <Stack flexDirection={"row"}>
              <VerticalTimeline layout={"1-column-left"}>
                {awardsHonours?.map((item, index) => {
                  return (
                    <>
                      {item?.find((item) => item?.key === "awardImage").value
                        .length > 0 ? (
                        <Box pos={"relative"} right={"20px"} top={"20px"}>
                          <AiOutlinePicture
                            onClick={() => showImage(0, true)}
                            cursor={"pointer"}
                            color="#1E90FF"
                            size={16}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <>
                        <VerticalTimelineElement
                          key={index}
                          id="timeline1"
                          contentStyle={{ fontSize: "sm" }}
                          iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                          icon={
                            item?.find((item) => item.key === "sortable_date")
                              .value
                          }
                        >
                          <Text className="fs-14" cursor={"pointer"}>
                            {item?.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it.key === "award_name" && (
                                    <span>{`${it.value} `}</span>
                                  )}
                                </Fragment>
                              );
                            })}
                            {item.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it.key === "awardcategory" &&
                                    it?.value.length > 0 && (
                                      <span>{`| ${it?.value[0]?.value} `}</span>
                                    )}
                                </Fragment>
                              );
                            })}
                            {item.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it?.key === "issuing_authority" && (
                                    <span>{`| ${it?.value} `}</span>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Text>
                        </VerticalTimelineElement>
                      </>
                    </>
                  );
                })}
              </VerticalTimeline>
            </Stack>
          </HStack>
          <HStack
            px={"0px"} 
            className="col-md-4 col-sm-12 mt-0 box-sticky"
            alignItems={"flex-start"}
            justifyContent={"flex-end"}
            paddingLeft={{base:"0px", md:"50px"}}
            paddingBottom={{base:"150px", md:"0px"}}
          >
            <Stack
              className="col-12 mt-2"
              id="allslidersatyajitAward"
              position={"relative"}
            >
              <HStack className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation, Pagination]}
                  onSwiper={setSwiper}
                >
                    {imageData.length > 0 && (
            <HStack className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
              <Swiper
                grabCursor={true}
                navigation={true}
                modules={[EffectFlip, Navigation, Pagination]}
                onSwiper={setSwiper}
              >
                {imageData.length > 0 &&
                  imageData.map((it,id) => (
                    <SwiperSlide>
                      <Box height={"auto"} width={"244px"}>
                        <Image
                          onClick={(e) => showFullImages(it, true, id)}
                          src={it}
                          cursor={"pointer"}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </HStack>
          )}
                </Swiper>
              </HStack>
            </Stack>
          </HStack>
          <ScrollToTop />
          <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imageData}
        imgData={imgData}
        clickedImageIndex={clickedImageIndex}
        imageHeight={{ base: "100%", md: "90vh" }}
      />
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </>
  );
}

export default AwardsHonours;

export const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  imageHeight,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {imageData?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                        height={"100vh"}
                      >
                        <Image
                          src={
                            item
                          }
                          height={imageHeight ? imageHeight : "100%"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.title}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};