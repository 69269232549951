import { Box, Image, Text } from "@chakra-ui/react";
import { common } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const ycircle = `${IMAGE_BASE_PATH}/ui/Chevron/sidecircle.png`

export const SubscribeCircle = () => {
    return (
      <>
       <Box>
       <Image src={ycircle} width="auto" height={"100%"} px="1" pb="1" />
       <Text fontSize={"10px"} fontWeight="700" textStyle={"secondary"} color={"#0066B3"} textAlign="center">{common.SUBSCRIBE}</Text>
       </Box>
     </>
    );
  };