import { pictorial_space_annual_data } from "../MockData/Introduction";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { transformJSON } from "../util/transformer";
import { pictorial_space_all_data, pictorial_space_essay_data } from "../MockData/EssayData";
import { pictorial_space_exhibit_data, pictorial_space_exhibition_filter_options } from "../MockData/ExhibitsData";
import { pictorial_space_artworks_catalogues_data, pictorial_space_comparable_artworks_data } from "../MockData/ArtworksCatalogues";
import { kg_subramanym_essay_data } from "../MockData/PersonalityArtist";
const schema=[{}];

export const introductionByAliasAPI = async (tableName, alias) => {

      return [];
    
  };
  
      /**
        This function return introduction data for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} masterlist - Masterlist alias.
        @returns {Promise} - Function return introduction data.
      */
  export const introductionByAlias = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response= await getIntroductiondummydata(masterlist);
      return response;
    }else if (isTransformationNotAllowed() === false)  {
      const response = await introductionByAliasAPI(tableName, masterlist);
      try {
        if (response) {
          let transformedData = transformJSON(schema, true, response);
          return transformedData;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getIntroductiondummydata = (winner) => {

    const introductionDataMap = {

      "pictorial-space":pictorial_space_annual_data
  
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const introductionData = introductionDataMap[winner] || pictorial_space_annual_data;
    return introductionData;
  };

  // Get All Data
  /**
        This function return All tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return All tab data of Body of work section.
  */
export const allData = async (tableName, alias) => {
    if (isTransformationNotAllowed() === true) {
      const response = await allDataDummy(tableName, alias);
      return response;
    }else if (isTransformationNotAllowed() === false) {
      
  };
}
  
  export const allDataDummy = async (tableName, alias) => {  
    const allDataMap = {

        "pictorial-space":pictorial_space_all_data
    
        // Add more cases as needed
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const allData = allDataMap[alias] || pictorial_space_all_data;
      return allData;
  };


  // Get Essay Data
  /**
        This function return Essay tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return Essay tab data of Body of work section.
  */
export const essayData = async (tableName, alias) => {
    if (isTransformationNotAllowed() === true) {
      const response = await essayDataDummy(tableName, alias);
      return response;
    }else if (isTransformationNotAllowed() === false) {
      
  };
}
  
  export const essayDataDummy = async (tableName, alias) => {  
    const essayDataMap = {
        "pictorial-space":pictorial_space_essay_data ,
        "k.g-Subramanyan":kg_subramanym_essay_data
        // Add more cases as needed
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const essayData = essayDataMap[alias] || pictorial_space_annual_data;
      return essayData;
  };
  
  // Get Exhibit Data
  /**
        This function return Exhibit tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return Exhibit tab data of Body of work section.
  */
export const exhibitData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await exhibitDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    
};
}

export const exhibitDataDummy = async (tableName, alias) => {  
  const exhibitDataMap = {
      "pictorial-space":pictorial_space_exhibit_data   
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const exhibitData = exhibitDataMap[alias] || pictorial_space_exhibit_data;
    return exhibitData;
};

 // This function return Personality Bibliography Filter options data
export const getExhibitsFilterOptionsAPI = async (
  tableName,
  masterlist
) => {
  return []
};


export const getExhibitsFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getExhibitsFilterOptionsDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getExhibitsFilterOptionsAPI(
      tableName,
      masterlist
    );
    const FilterOptions = {
      documentTypeFilter: [
        ...new Set(
          response
            .map((item) => item.document_type.trim())
            .filter((value) => value !== "")
        ),
      ],
      yearFilter: [
        ...new Set(
          response
            .map((item) => item.sortable_year_date_of_publication.trim())
            .filter((value) => value !== "")
        ),
      ],
      creatorTypeFilter: [
        ...new Set(
          response
            .map((item) => item.title_and_type_of_creator.trim())
            .filter((value) => value !== "")
        ),
      ],
      publisher: [
        ...new Set(
          response
            .map((item) => item.publisher.trim())
            .filter((value) => value !== "")
        ),
      ],
    };

    return FilterOptions;
  }
};

const getExhibitsFilterOptionsDummyData = (masterlist) => {
  const bibliographDataMap = {

    "pictorial-space":pictorial_space_exhibition_filter_options
  };
  return bibliographDataMap[masterlist];
};


// Get Artworks from the Catalogues Data
  /**
        This function return ArtworksCatalogues tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return ArtworksCatalogues tab data of Body of work section.
  */
    export const artworksCataloguesData = async (tableName, alias) => {
      if (isTransformationNotAllowed() === true) {
        const response = await artworksCataloguesDataDummy(tableName, alias);
        return response;
      }else if (isTransformationNotAllowed() === false) {
        
    };
    }
    
    export const artworksCataloguesDataDummy = async (tableName, alias) => {  
      const artworksCataloguesDataMap = {
          "pictorial-space":pictorial_space_artworks_catalogues_data   
          // Add more cases as needed
        };
        // Check if the masterlist exists in the map, if not, use a default value
        const artworksCataloguesData = artworksCataloguesDataMap[alias] || pictorial_space_artworks_catalogues_data;
        return artworksCataloguesData;
    };

    
    // Get Comparable Artworks Data
    /**
        This function return ComparableArtworks tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return ComparableArtworks tab data of Body of work section.
    */
        export const comparableArtworksData = async (tableName, alias) => {
          if (isTransformationNotAllowed() === true) {
            const response = await comparableArtworksDataDummy(tableName, alias);
            return response;
          }else if (isTransformationNotAllowed() === false) {
            
        };
        }
        
        export const comparableArtworksDataDummy = async (tableName, alias) => {  
          const comparableArtworksDataMap = {
              "pictorial-space":pictorial_space_comparable_artworks_data   
              // Add more cases as needed
            };
            // Check if the masterlist exists in the map, if not, use a default value
            const comparableArtworksData = comparableArtworksDataMap[alias] || pictorial_space_comparable_artworks_data;
            return comparableArtworksData;
        };