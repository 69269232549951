import React from "react";
import TeachingDrawerSelfIntro from "./TeachingDrawerSelfIntro";
import TeachingDrawerDetails from "./TeachingDrawerDetails";
import TeachingDrawerModalDetails from "./TeachingDrawerModalDetails";

export default function TeachingDrawerBody({
  techingBuilding,
  showFamilyMemberImages,
  familyImgData,
  showFullImages,
  familyFullScreenImage,
  closeIcon,
  teachingData
}) {
  return (
    <>
      <TeachingDrawerSelfIntro
        key={teachingData}
        techingBuilding={techingBuilding}
        teachingData={teachingData}
        showFamilyMemberImages={showFamilyMemberImages}
      />
      <TeachingDrawerDetails
        familyImgData={familyImgData}
        showFamilyMemberImages={showFamilyMemberImages}
        showFullImages={showFullImages}
        closeIcon={closeIcon}
      />
      <TeachingDrawerModalDetails
        familyImgData={familyImgData}
        familyFullScreenImage={familyFullScreenImage}
        showFullImages={showFullImages}
      />
    </>
  );
}
