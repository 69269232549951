import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade, Navigation, Pagination } from "swiper";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;

export const FullScreenImage = ({
  isOpen,
  onClose,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {
  return (
    <Container maxW={"auto"} px="0px">
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"}>
          <ModalBody margin={{ base: "0", md: "16" }}>
            <Box
              bg={"white"}
              justifyContent={"space-between"}
              position={"relative"}
            >
              <Stack>
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 1}>
                          <Stack
                            maxW={"1180px"}
                            marginTop={{ base: "250px", md: "0" }}
                            flexDirection={{ base: "column", md: "row" }}
                            className="row"
                            alignItems={"flex-start"}
                            gap={"0"}
                          >
                            <HStack
                              className="col-sm-12 col-md-7"
                              justifyContent={"center"}
                              border={"0.01rem solid #d7d7d7"}
                              borderRadius={"5px"}
                              // background={"#eeeeee"}
                            >
                              <Stack m="8">
                                <Box
                                  display={"block"}
                                  justifyContent={"center"}
                                  width={"100%"}
                                  height={{ base: "auto", md: "550px" }}
                                >
                                  <Image
                                    src={
                                      item?.fullImg
                                      // imageData !== null
                                      //   ? "https://d3fsixjuo450s3.cloudfront.net/" + imageData
                                      //   : ""
                                    }
                                    width={"100%"}
                                    height={"inherit"}
                                    objectFit={"contain"}
                                    alt=""
                                  />
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              className="col-sm-12 col-md-5"
                              flexDirection={"column"}
                            >
                              <Box
                                width={"100%"}
                                borderBottom={"1px"}
                                borderColor={"#e8e8e8"}
                              >
                                <Box paddingBottom={"10px"} width={"100%"}>
                                  <Text
                                    fontSize={"20px"}
                                    fontWeight={"700"}
                                    color="#0066B3"
                                  >
                                    {item.designerdesc}
                                  </Text>
                                </Box>
                                <Stack
                                  pb="4"
                                  marginInlineStart={"0rem!important"}
                                  borderBottom={"1px"}
                                  borderColor={"#e8e8e8"}
                                >
                                  {item.designerData.length > 0 &&
                                    item.designerData.map((it) => (
                                      <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        gap={"2"}
                                        className="mt-0 px-0"
                                        flexDirection={"column"}
                                      >
                                        <HStack className="mt-0 px-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                            color="black"
                                          >
                                            {it.display}
                                          </Text>
                                        </HStack>
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                            // color="#035DA1"
                                          >
                                            {it.value}
                                          </Text>
                                        </HStack>
                                      </Stack>
                                    ))}
                                </Stack>
                              </Box>
                              <Stack pt="2" marginInlineStart={"0rem!important"}>
                                <Box>
                                  <Text fontSize={"14px"} lineHeight={"16.44px"}>
                                    {item?.content}
                                  </Text>
                                  <Text
                                    fontSize={"16px"}
                                    fontWeight={"700"}
                                    color="#035DA1"
                                    pt="2"
                                    cursor={"pointer"}
                                    onClick={() => {
                                      onClose();
                                      // setReadMoreFlag(true);
                                      setReadMoreFlag(
                                        true,
                                        isOpen,
                                        index,
                                        bannerFlag,
                                        title
                                      );
                                    }}
                                  >
                                    Read More
                                  </Text>
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack>
                              <Image
                                className="closebtn"
                                src={closeIcon}
                                onClick={() => {
                                  onClose();
                                }}
                                cursor={"pointer"}
                              ></Image>
                            </HStack>
                          </Stack>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
