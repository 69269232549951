export const schema = [
  { key: "alias", display: "Alias", seq: 1 },
  { key: "duration_of_song", display: "Duration of Song", seq: 2 },
  { key: "name", display: "Name", seq: 3 },
  {
    key: "name_of_song_language_2",
    display: "Name of song language 2",
    seq: 4,
  },
  {
    key: "name_of_song_language_3",
    display: "name of song language 3",
    seq: 5,
  },
  { key: "song_link", display: "Song Link", seq: 6 },
  { key: "masterlist", display: "Masterlist", seq: 7 },
  {
    key: "personality",
    display: "Personality",
    processor: "creatorAliasArray",
    seq: 8,
  },
  {
    key: "lyricsImage",
    display: "LyricsImage",
    processor: "imageAliasArray",
    seq: 9,
  },
];
