import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import { CopyPasteService } from "../../../services/CopyPaste";

//Component file
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import ScrollToTop from "../../../components/sections/ScrollToTop";
import ResearchImageGridList from "../../Cine_MasterList/ResearchCentre/CommonComponents/ResearchImageGridList";
import ResearchCentreDrawer from "../../Film/ResearchCentre/Library/Components/ResearchCentreDrawer";
import Small from "../../../components/layouts/Typography/Small";
import { FilterButton } from "../../../CommonComponents/FilterButton";

//Language file
import { button, common, filterAttribute, explore } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/msl1.jpg`
const Img2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/msl2.jpg`
const Img3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/DetailPage/msl3.jpg`

function GridView({
  data,
  handleBookClick,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const documentTypeData = [
    "BOOK.auc",
    "BOOK.bka",
    "BOOK.bke",
    "BOOK.bkq",
    "BOOK.ess",
  ];

  const [bibliographyObject, setBibliographyObject] = React.useState();
  const handleDrawer = (item) => {
    setBibliographyObject(item);
    setleftFlag(true);
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setBibliographyObject();
    setOpenDrawer(false);
  };
  const drawerData = {
    imagePath: "",
    desc: "A.M.,Davierwalla | 1979. Book.exc",
    header: "Art Heritage Annual & A Sculptor's Vocation - A.M. Davierwalla",
    bookKey: "A Sculptor's Vocation",
    typeCreator: "Author",
    link: true,
    author: "A.M. Davierwalla",
    book: "A Sculptor's Vocation - A.M. Davierwalla",
    publicationYear: "1979",
    publisher: "Cine Central",
    nameText: "A.M.,Davierwalla",
    sliderImage: [Img1, Img2, Img3],
    fullname: "A.M.,Davierwalla",
    footer: [
      {
        text: "Index Page →",
      },
    ],
  };

  return (
    <>
      <Stack flexDirection={"row-reverse"}>
        <ResearchCentreDrawer
          data={drawerData}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleClose={handleClose}
          handleBookClick={handleBookClick}
          bibliographyObject={bibliographyObject}
        />
        <FilterButton onClick={handleFilterClick} />
        <Container maxW="auto" className="songs" px="0" py="8">
          <Stack flexDirection={"row"} gap={4} justifyContent="center">
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              <ResearchImageGridList
                data={data}
                handleDrawer={handleDrawer}
                border={false}
              />
            </HStack>
            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterArtHeritage">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold
                        as="u"
                        color={"black"}
                        cursor="pointer"
                      >
                        {button.CLEAR_ALL}
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      />
                      <InputRightElement pb={"6px"}>
                        <BiSearch color="green.500" fontSize={"16px"} />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                      {/* Need to uncomment when author data added */}
                      {/* <AuthorAccordionPanel
                      data={data}
                      setAuthorName={setAuthorName}
                      authorName={authorName}
                    /> */}
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.PUBLISHING_HOUSE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.DATE_OF_PUBLICATION}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <AccordionPanel pb={4} className="px-1">
                        <Small>{common.CONTENT_AWAITED}</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <Text
                            fontSize={"14px"}
                            fontWeight={"700"}
                            lineHeight={"24px"}
                          >
                            {filterAttribute.DOCUMENT_TYPE}
                          </Text>
                        </Box>
                        <AccordionIcon fontSize={"30px"} />
                      </AccordionButton>
                      <Box maxH="200px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {documentTypeData.map((item) => {
                            return (
                              <Flex
                                borderBottom={"1px"}
                                borderBottomColor={"#f1f1f1"}
                              >
                                <Checkbox
                                // Need this commented code for use
                                  // isChecked={selections.includes(
                                  //   item?.sortable_year_date_of_publication
                                  // )}
                                  // onChange={() =>
                                  //   handleOptionClick(
                                  //     item?.sortable_year_date_of_publication
                                  //   )
                                  // }
                                  borderColor={"black"}
                                  _checked={{
                                    "& .chakra-checkbox__control": {
                                      background: "black",
                                    },
                                  }}
                                  iconColor="white"
                                  cursor="pointer"
                                  marginRight={"5px"}
                                />
                                <Box p="1">
                                  <Text fontSize={"14px"}>{item}</Text>
                                </Box>
                              </Flex>
                            );
                          })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                    <HStack marginTop={"10px"}>
                      <Button
                        color={"#ffffff"}
                        width={"199px"}
                        height={"36px"}
                        bg={"#000000"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        variant={"unstyled"}
                      >
                        {explore.APPLY}
                      </Button>
                    </HStack>
                  </Accordion>
                </Box>
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
      <ScrollToTop />
    </>
  );
}
export default GridView;
