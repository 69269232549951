import {
  Box,
  CloseButton,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { common } from "../constants/constants";
import { BsArrowLeftCircleFill } from "react-icons/bs";

export default function LeftVmenu({
  onClose,
  toggleModal,
  member,
  circle,
  LeftVmenuData,
  subscribe,
  backArrow,
  display,
  handleNavigation,
  ...rest
}) {
  return (
    <>
      <Box
        // transition="3s ease"
        bg={useColorModeValue("black", "gray.900")}
        // borderRight="1px"
        // borderRightColor={useColorModeValue("gray.200", "gray.700")}
        w={{ base: "none", md: 14 }}
        display={{ base: "none", md: "block" }}
        pos="fixed"
        h="full"
        {...rest}
        color={"white"}
      >
        <Flex
          h="20"
          alignItems="flex-end"
          m="4"
          justifyContent="space-between"
          flexDirection="row-reverse"
        >
          {onClose && (
            <CloseButton
              display={{ base: "none", md: "none" }}
              onClick={onClose}
            />
          )}{" "}
        </Flex>
        {LeftVmenuData &&
          LeftVmenuData.map((it, index) => (
            <HStack
              key={it.id}
              justifyContent={it.active ? "space-around" : "center"}
              borderLeft="4px"
              borderColor={it.active ? "#FF4E4E" : "black"}
              borderRadius={it.active ? "0px" : "none"}
              height={"51px"}
              pr="4px"
            >
              <Link to={it.link} id={it.id}>
                <IconButton
                  my="2"
                  py="4"
                  fontSize="24px"
                  bg={"black"}
                  color={it.active ? "white" : "#4C4C4C"}
                  _hover={{
                    color: "white",
                  }}
                >
                  {it.icon && React.createElement(it.icon)}
                </IconButton>
              </Link>
            </HStack>
          ))}

        <HStack justifyContent="center" px="4px" height={"51px"}>
          <Link my="5px" py="3" id="membericon">
            <Image
              src={member}
              onClick={toggleModal}
              width="auto"
              height={"100%"}
            ></Image>
          </Link>
        </HStack>
        <HStack justifyContent="center" px="4px" height={"51px"}>
          <Link to="/osianamaworld" my="5px" py="3" id="osianamacircleicon">
            <Image
              src={circle}
              // onClick={toggleModal}
              width="auto"
              height={"100%"}
              px="1"
              pb="1"
            ></Image>
            {subscribe && (
              <Text
                fontSize={"10px"}
                fontWeight="700"
                textStyle={"secondary"}
                color={"#0066B3"}
                textAlign="center"
                // id="HiddenText"
              >
                {common.SUBSCRIBE}
              </Text>
            )}
          </Link>
        </HStack>
        {backArrow && (
          <Box
            pos={"absolute"}
            px="2"
            bottom={"10px"}
            onClick={handleNavigation}
          >
            <IconButton
              bg={"black"}
              color="#4C4C4C"
              _hover={{
                color: "white",
              }}
            >
              <BsArrowLeftCircleFill size={"30px"} />
            </IconButton>
          </Box>
        )}
      </Box>
      <Stack
        bg={useColorModeValue("black", "gray.900")}
        display={display}
        className="mobilemenu"
      >
        <Stack
          justifyContent={"space-evenly"}
          alignItems={"flex-end"}
          flexDirection={"row"}
        >
          {LeftVmenuData &&
            LeftVmenuData.map((it, index) => (
              <HStack
                justifyContent={it.active ? "space-around" : "center"}
                borderBottom="4px"
                flexDirection={"column"}
                borderColor={it.active ? "#FF4E4E" : "black"}
                borderRadius={it.active ? "0px" : "none"}
                height={"51px"}
                // pr="4px"
              >
                <Link to={it.link} id={it.id}>
                  <IconButton
                    my="2"
                    py="4"
                    fontSize="24px"
                    bg={"black"}
                    color={it.active ? "white" : "#4C4C4C"}
                    _hover={{
                      color: "white",
                    }}
                  >
                    {it.icon && React.createElement(it.icon)}
                  </IconButton>
                </Link>
              </HStack>
            ))}

          {/* <HStack justifyContent="center" px="4px" height={"51px"}>
        <Link my="5px" py="3" id="membericon">
          <Image
            src={member}
            onClick={toggleModal}
            width="auto"
            height={"100%"}
          ></Image>
        </Link>
      </HStack> */}
          <HStack justifyContent="center" px="4px" height={"51px"}>
            <Link to="/osianamaworld" my="5px" py="3" id="osianamacircleicon">
              <Image
                src={circle}
                // onClick={toggleModal}
                width="auto"
                height={"100%"}
                px="1"
                pb="1"
              ></Image>
              {subscribe && (
                <Text
                  fontSize={"10px"}
                  fontWeight="700"
                  textStyle={"secondary"}
                  color={"#0066B3"}
                  textAlign="center"
                  // id="HiddenText"
                >
                  {common.SUBSCRIBE}
                </Text>
              )}
            </Link>
          </HStack>
          {/* { backArrow && 
      <Box pos={"absolute"} px="2" bottom={"10px"} onClick={handleNavigation}>
        <IconButton  bg={"black"} color="#4C4C4C"  _hover={{
                  color: "white",
                }}><FiArrowLeftCircle  size={"30px"} /></IconButton>
      
      </Box>
      } */}
        </Stack>
      </Stack>
    </>
  );
}
