import {
  Box,
  Flex,
  HStack,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
  Image,
  Divider,
  Text,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import MobileNav from "../../components/Topheader";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { TextComponent } from "../../CommonComponents/TextComponent";

//Language file
import { artheritage, common, explore } from "../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const Image11 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/Establishment/1.png`
const Image13 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/Establishment/3.png`
const IntroImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/IntroImg.png`
const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`


export default function Introduction({ children }) {

  const {onOpen, onClose } = useDisclosure();
  const [setPage] = useState("banner");
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "journal",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "journal_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);

  return (
    <Container maxW={{ base: "auto", md: "1400px" }} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <MobileNav onOpen={onOpen} setPage={setPage} />
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          horislinkData={horislinkData}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <IntroductionBody   
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
            name={name}/>
        </Box>
      </Box>
    </Container>
  );
}

const SidebarContent = ({ 
  title, 
  date, 
  datedod, 
  onClose, 
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType, 
  ...rest }) => {
  const drawerArtHeritage = [
    {
    name:"Art Heritage Annual & Ebrahim Alkazi",
    title: "Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Content Awaited",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const artistLinksData = [{
  name: "Ebrahim Alkazi",
 
},{
    name: "Art Heritage Gallery",
}

];
const [drawerName, setDrawerName] = useState("")

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        oSections={oSections}
        onClose={onClose}
        sections={introData}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        drawerData={drawerArtHeritage}
        masterlistType={masterlistType}
        artistLinksData={artistLinksData}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
      />
    </>
  );
};

const IntroductionBody = ({ setTitle, setDate, setDateDod }) => {
  const [contentClicked, setContentClicked] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ahjFullScreenImage, setAhjFullScreenImage] = useState(false);
  const showAhjImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setAhjFullScreenImage(flag);
    } else {
      setAhjFullScreenImage(flag);
    }
  };
  React.useEffect(() => {
    const getData = async () => {
      const result =
        await getIntroductionData.getArtHeritagePublicationIntroData();
      if (result) {
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);

  const establishmentData = [
    {
      image: true,
      name: "Ebrahim Alkazi",
    },
    {
      image: false,
      name: "Roshen Alkazi",
    },
    {
      image: false,
      name: "Amal Allana",
    },
    {
      image: false,
      name: "Art Heritage Gallery",
    },
  ];

  const publishersData = [
    {
      name: "Art Heritage Foundation",
    },
    {
      name: "Roshen Alkazi",
    },
    {
      name: "Ebrahim Alkazi",
    },
  ];
  const desc =
    "Founded by Ebrahim and Roshen Alkazi in 1977, Art Heritage Annual Published its first edition in 1978-79. The Journal focuses on the exhibitions and reterospectives curated for the Art Heritage Gallery supplemented with the essays written by eminent art scholar and writers to compile data on the contemporary art movement in India.";
  return (
    <>
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        width={{ base: "100%", md: "1170px" }}
        px={{ base: 4, md: 0 }}
        height={{ base: "auto", md: "calc(100vh - 75px)" }}
        pb={4}
      >
        <Stack
          height={"100%"}
          width={{
            base: contentClicked ? "100%" : "100%",
            md: contentClicked ? "750px" : "683px",
          }}
          marginTop={"32px"}
        >
          {!contentClicked ? (
            <>
              <Stack>
                <Text
                  fontSize={{ base: "26px", md: "48px" }}
                  fontWeight={"800"}
                  color="#000000"
                  textStyle={"primary"}
                  marginLeft={{ base: "0px", md: "70px" }}
                >
                 {artheritage.ART_HERITAGE_ANNUAL}
                </Text>

                <Box paddingLeft={{ base: "0px", md: "100px" }} py={6}>
                  <Image src={IntroImg}></Image>
                </Box>
              </Stack>
            </>
          ) : (
            <Stack>
              <Stack
                zIndex={99}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                paddingRight={{ base: "0px", md: "30px" }}
              >
                <Image
                  src={closeIcon}
                  height={"20px"}
                  width={"26px"}
                  paddingLeft="5px"
                  position="absolute"
                  cursor={"pointer"}
                  onClick={() => {
                    setContentClicked(false);
                  }}
                />
              </Stack>
              <Stack
                paddingTop={{ base: "0px", md: "50px" }}
                position={"relative"}
                id="allsliderahj"
              >
                <Swiper
                  loop={true}
                  navigation={false}
                  modules={[EffectFade, Navigation, Pagination]}
                >
                  {[Image11].map((item, index) => {
                    return (
                      <>
                        <SwiperSlide key={index + 1}>
                          <HStack>
                            <Image src={item} />
                          </HStack>
                          <HStack
                            justifyContent="flex-end"
                            position="absolute"
                            right="0"
                            top="90%"
                            marginRight={{ base: "0px", md: "10px" }}
                            zIndex={"999"}
                            transform="translateY(-30%)"
                          >
                            <Text
                              fontSize={"xl"}
                              bg={"black"}
                              opacity={"0.6"}
                              color={"white"}
                              fontWeight={"400"}
                              padding="2"
                              cursor={"pointer"}
                              borderRadius={"35px"}
                              width="40px"
                              height={"40px"}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              onClick={(e) => showAhjImages(item, true, index)}
                            >
                              <CgArrowsExpandRight />
                            </Text>
                          </HStack>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack paddingBottom={"100px"}>
          <Box width={{ base: "100%", md: "392px" }}>
            <Accordion defaultIndex={[0]} allowToggle>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {artheritage.PREFACE}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <Box>
                  <AccordionPanel py={2} className="px-0">
                    <TextComponent
                      text={desc}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color="black"
                      lineHeight={"16px"}
                      textAlign={"justify"}
                    />
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {artheritage.ESTABLISHMENT}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel py={2} px={"1"}>
                  {establishmentData?.map((item, index) => {
                    return (
                      <VStack align="start" spacing={2}>
                        <Flex align="center" py={3}>
                          {item.image ? (
                            <Box marginLeft={"-2px"} marginRight={"8px"}>
                              <AiOutlinePicture
                                onClick={() => setContentClicked(true)}
                                cursor={"pointer"}
                                color="#1E90FF"
                                size={16}
                              />
                            </Box>
                          ) : (
                            <Box
                              marginLeft={"-2px"}
                              marginRight={"8px"}
                              height={"16px"}
                              width={"16px"}
                            ></Box>
                          )}
                          <Text
                            fontSize={"16px"}
                            fontWeight={"500"}
                            color={"#000000"}
                          >
                            {item.name}
                          </Text>
                        </Flex>
                        <Divider
                          color={"#B5B5B5"}
                          borderBottomWidth={"0.1px"}
                        />
                      </VStack>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {artheritage.PUBLISHERS_AND_EDITORS}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel py={2} className="pl-4 pr-0">
                  {publishersData?.map((item, index) => {
                    return (
                      <VStack align="start" spacing={2}>
                        <Text
                          fontSize={"16px"}
                          fontWeight={"500"}
                          color={"#000000"}
                          py={3}
                        >
                          {item.name}
                        </Text>
                        <Divider
                          color={"#B5B5B5"}
                          borderBottomWidth={"0.1px"}
                        />
                      </VStack>
                    );
                  })}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {artheritage.HIGHLIGHTS}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel py={2} className="px-0">
                  <Text fontSize={"14px"} fontWeight={"400"}>
                    {common.CONTENT_AWAITED} - 1
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Stack>
      </Stack>
      <ImagePopupModal
        isOpen={ahjFullScreenImage}
        onClose={() => showAhjImages(null, false)}
        data={[Image13]}
        clickedImageIndex={clickedImageIndex}
      />
    </>
  );
};

const ImagePopupModal = ({ isOpen, onClose, data, clickedImageIndex }) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody>
          <Box bg="white" mx="10">
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation={false}
                initialSlide={clickedImageIndex}
                loop={true}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                      >
                        <Image
                          src={item}
                          alt=""
                        />
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        left={"auto"}
                        right={{ base: "0%", md: "5%" }}
                        top={{ base: "80%", md: "90%" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={{ base: "0px", md: "30px" }}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={"0.6"}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
