import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service file
import * as getIntroductionData from "../../../services/Introduction";
import * as getLandingMasterlistData from "../../../services/SectionService";

//Component file
import MobileNav from "../../../components/Topheader";
import Essay from "./Essay";
import Exhibit from "./Exhibit";
import ReadMore from "../../Film/ReadMore";
import { BottomMainMenu } from "../../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language file
import { artheritage, explore } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const EbrahimImg = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/Introduction/drawer1.png`

export default function ExhibitionCatlouge() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [leftFlag, setleftFlag] = useState(false);
  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const handleReadMoreNavigation = (flag, isOpen, index, bannerFlag, title) => {
    setReadmoreNavigation({
      isReadMore: flag,
      isOpen: isOpen,
      clickedImageIndex: index,
      bannerFlag: bannerFlag,
      title: title,
    });
  };
  const handleEssayReadMoreNavigation = (
    flag,
    isOpen,
    index,
    posterFlag,
    title
  ) => {
    setReadmoreNavigation({
      isReadMore: flag,
      isOpen: isOpen,
      clickedImageIndex: index,
      posterFlag: posterFlag,
      title: title,
    });
  };
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "journal",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "journal_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "journal_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        "sections",
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  let { tabIndex } = useParams();
  useEffect(() => {
    if (tabIndex) {
      navigate(
        `/${masterlistType}/${aliasAPI}/body-of-work/timeline/exhibition-catlouge/`,
        { replace: true }
      );
    }
  }, [masterlistType, tabIndex, navigate,aliasAPI]);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <MobileNav onOpen={onOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        leftFlag={leftFlag}
        setReadmoreNavigation={setReadmoreNavigation}
        readmoreNavigation={readmoreNavigation}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        introData={introData}
        masterlistType={masterlistType}
        name={name}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            onClose={onClose}
            leftFlag={leftFlag}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
            masterlistType={masterlistType}
            name={name}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white">
        <Stack width={{ base: "100%", md: "1160px" }}>
          {readmoreNavigation?.isReadMore ? (
            <ReadMore />
          ) : (
            <Container minW={"100%"} pb="4" px="0px">
              <Stack>
                <HStack position={"sticky"}>
                  <Tabs align={{ xs: "end", sm: "" }} w="full">
                    <Stack flexDirection={"row-reverse"} display={"flex"}>
                      <Box
                        zIndex={999}
                        bg="white"
                        width={{ base: "100%", md: "1160px" }}
                      >
                        <Box
                          width={{ base: "100%", md: "1160px" }}
                          position={"fixed"}
                          backgroundColor={"white"}
                          zIndex={999}
                          paddingBottom={"18px"}
                        >
                          <TabList
                            overflowX="auto"
                            borderBottom={"1px"}
                            gap={12}
                            className="verticalscroll"
                            borderColor={"white"}
                            marginLeft={"42px"}
                            marginTop={"32px"}
                          ></TabList>
                        </Box>
                        <HStack>
                          <Tabs
                            align={{ xs: "end", sm: "" }}
                            w="full"
                            defaultIndex={
                              parseInt(tabIndex)
                                ? 1
                                : readmoreNavigation.bannerFlag
                                ? 1
                                : 0
                            }
                          >
                            <Stack
                              flexDirection={"row-reverse"}
                              display={"flex"}
                            >
                              <Box
                                position={"fixed"}
                                marginTop={"40px"}
                                zIndex={999}
                                bg="white"
                                width={{ base: "100%", md: "1160px" }}
                              >
                                <TabList
                                  overflowX="auto"
                                  borderBottom={"1px"}
                                  gap={12}
                                  className="verticalscroll"
                                  borderColor={"white"}
                                  marginLeft={"42px"}
                                >
                                  <Tab
                                    className="Timeline"
                                    p="0"
                                    color="#333333"
                                    fontSize={"md"}
                                    fontWeight={"400"}
                                    _selected={{
                                      borderBottomWidth: "6px",
                                      color: "black",
                                      borderBottomColor: "#333333",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {artheritage.ESSAYS}
                                  </Tab>
                                  <Tab
                                    className="Timeline"
                                    p="0"
                                    color="#333333"
                                    fontSize={"md"}
                                    fontWeight={"400"}
                                    marginLeft={"32px"}
                                    _selected={{
                                      borderBottomWidth: "6px",
                                      color: "black",
                                      borderBottomColor: "#333333",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {artheritage.EXHIBITS}
                                  </Tab>
                                </TabList>
                              </Box>
                            </Stack>
                            <Box w={"full"}>
                              <TabPanels>
                                <TabPanel px="0px" marginTop={"60px"}>
                                  <Essay
                                    setleftFlag={setleftFlag}
                                    setReadMoreFlag={
                                      handleEssayReadMoreNavigation
                                    }
                                    readmoreNavigation={readmoreNavigation}
                                  />
                                </TabPanel>
                                <TabPanel
                                  px="0px"
                                  marginTop={"70px"}
                                  marginLeft={"26px"}
                                >
                                  <Exhibit
                                    setReadMoreFlag={handleReadMoreNavigation}
                                    readmoreNavigation={readmoreNavigation}
                                  />
                                </TabPanel>
                              </TabPanels>
                            </Box>
                          </Tabs>
                        </HStack>
                      </Box>
                    </Stack>
                  </Tabs>
                </HStack>
              </Stack>
            </Container>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  leftFlag,
  readmoreNavigation,
  setReadmoreNavigation,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  name, 
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const drawerArtHeritage = [{
title:"Ebrahim Alkazi", name: "Art Heritage Annual & Ebrahim Alkazi",
    links: [
      { link_name: "Exhibition Invitation Cards", link: "" },
      { link_name: "Newspaper Articles", link: "" },
      { link_name: "Research & Reference Material", link: "" },
    ],
    Image: EbrahimImg,
    desc: "Content Awaited",
    masterlist: {
      text: "Visit Ebrahim Alkazi Masterlist →",
      link: "/ebrahim-alkazi",
    },
  }];
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const [drawerName, setDrawerName] = useState("")

   const artistLinksData = [{
  name: "Ebrahim Alkazi",
 
},{
    name: "Art Heritage Gallery",
}

];;
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        artistLinksData={artistLinksData}
        handleNavigation={handleNavigation}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        leftFlag={leftFlag}
        setDrawerName={setDrawerName}
        drawerName={drawerName}  
        drawerData={drawerArtHeritage}
      />
    </>
  );
};
