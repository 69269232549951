// Core Files
import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import Small from "../../components/layouts/Typography/Small";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

function FilmReleaseDetails({ filmReleaseDetails }) {
  return (
    <>
      <HStack justifyContent="left" px="4">
        <Small color={"black"}>
          {dateFormat(filmReleaseDetails.date, "d mmmm yyyy")},
        </Small>
        {filmReleaseDetails.link? (
          <Link to={`/${filmReleaseDetails?.link}`}>
            <Text
              as="p"
              fontSize="12px"
              fontWeight="500"
              lineHeight={"15px"}
              marginLeft={"-5px !important"}
              color={"#035DA1"}
            >
              {filmReleaseDetails.location}
            </Text>
          </Link>
        ) : (
          <Text
            as="p"
            fontSize="12px"
            fontWeight="400"
            lineHeight={"15px"}
            marginLeft={"2px !important"}
            color={"#000"}
          >
            {filmReleaseDetails.location}
          </Text>
        )}
      </HStack>
    </>
  );
}

export default FilmReleaseDetails;