import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  Grid,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core files
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "react-icons/io";

//Component files
import MobileNav from "../components/Topheader";
import { BottomMainMenu } from "../CommonComponents/BottomMainMenu";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";

//Language files
import { common } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

function ContentAwaitedNew() {
  const { alias } = useParams();

  let aliasName = "";

  switch (alias) {
    case "cinemae":
      aliasName = "CinemaE";
      break;
    case "indian-cinema":
      aliasName = "Indian Cinema";
      break;
    case "calcutta":
      aliasName = "Calcutta";
      break;
    default:
      aliasName = alias;
  }

  return (
    <>
    <SidebarWithHeader>
      <Stack height={"calc(100vh - 64px)"} bg={"white"}>
        <Container maxW="auto" px="0">
          <HStack flexDirection="column" alignItems={"center"} py="60">
            <Box>
              <Text
                textAlign={"center"}
                fontSize={{ base: "25px", md: "45px" }}
                fontWeight={"700"}
                pt="5"
              >
                {common.WEBPAGE_COMING_SOON_FOR}
                <br />
                {aliasName}
              </Text>
            </Box>
          </HStack>
          <HStack m="8" flexDirection="column" alignItems={"flex-start"}>
            <Box>
              <Grid
                className="category"
                templateColumns="repeat(1, 1fr)"
                gap={0}
              >
              </Grid>
            </Box>
          </HStack>
        </Container>
      </Stack>
      <Stack display={{ base: "flex", md: "none" }}>
        <BottomMainMenu />
      </Stack>
      </SidebarWithHeader>
    </>
  );
}
export default ContentAwaitedNew;
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
