import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";

export default function CommonDrawer({
  isOpen,
  ref,
  onClose,
  id,
  autoFocus,
  IntroDrawerHeader,
  IntroDrawerBody,
  showFamilyMemberImages,
  showEducationImages
}) {
  return (
    <>
      <Drawer
        // size={"sm"}
        isOpen={isOpen}
        placement="right"
        onClose={(e) => onClose()}
        finalFocusRef={ref}
        id={id}
        autoFocus={autoFocus}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader pl="4">
            {/* place component props of IntroDrawerHeader here */}
            {/* {IntroDrawerHeader} */}
          </DrawerHeader>
          <DrawerBody pl="8">
            {/* place component props of IntroDrawerBody here */}
            {IntroDrawerBody}
          </DrawerBody>
          {/* <DrawerFooter></DrawerFooter> */}
        </DrawerContent>
      </Drawer>
    </>
  );
}
