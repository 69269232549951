//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const ly1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/LyricsSoundtrack/1.png`

export const skk_soundtrack = [
  [
    {
      key: "alias",
      value: "kanha-mai-tause-haari-birju-maharaj",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "04:49",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Kanha mai tause haari",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=0CjWbLCm_8s",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "birju-maharaj",
          value: "birju maharaj",
          first_name: "Birju",
          last_name: "Maharaj",
          full_name: "Birju Maharaj",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [
        {
          key: "shatranj-ke-khilari-1977-cine-ssb-000013b",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/i2kmom2va978dc8oepssan7c21/HqCrs7FtmiXvkYV8GWO7b_K5Jns/m800/800",
          date_of_artwork: "1977",
          wnn: "000013b",
          accession_number: "Cine.ssb",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Satyajit Ray",
          medium_of_artwork: "Song Synopsis Booklet",
          revised_medium: "CINE.ssb",
        },
        {
          key: "shatranj-ke-khilari-1977-cine-ssb-000013c",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ct9tlok12l7k99tvog0gtrj50u/scLrHQp8cqEkHBQxjGtjW89Se8A/m800/800",
          date_of_artwork: "1977",
          wnn: "000013c",
          accession_number: "Cine.ssb",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Satyajit Ray",
          medium_of_artwork: "Song Synopsis Booklet",
          revised_medium: "CINE.ssb",
        },
      ],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "hindola-jhule-shyam-calcutta-youth-choir",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "04:46",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Hindola jhule shyam",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=g-rHMJSIL34",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "tadap-tadap-sagri-rayan-guzari-amjad-khan",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "01:20",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Tadap tadap sagri rayan guzari",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=8TfVHwztF64",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "amjad-khan",
          value: "amjad khan",
          first_name: "Amjad",
          last_name: "Khan",
          full_name: "Amjad Khan",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "are-haan-chabi-dikhla-ja-reba-muhuri",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "07:56",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Are haan chabi dikhla ja",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=DbvNXw3OqRw",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "reba-muhuri",
          value: "reba muhuri",
          first_name: "Reba",
          last_name: "Muhuri",
          full_name: "Reba Muhuri",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "bajaye-bansuriya-shyam-yamuna-kinare-reba-muhuri",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "05:30",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Bajaye bansuriya shyam yamuna kinare",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=W8SmrSUgXvc",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "reba-muhuri",
          value: "reba muhuri",
          first_name: "Reba",
          last_name: "Muhuri",
          full_name: "Reba Muhuri",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
];

export const silsila_soundtrack = [
  [
    {
      key: "alias",
      value: "kanha-mai-tause-haari-birju-maharaj",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "0:10",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Birju Maharaj - Lata Mangeshkar",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=0CjWbLCm_8s",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "birju-maharaj",
          value: "birju maharaj",
          first_name: "Birju",
          last_name: "Maharaj",
          full_name: "Birju Maharaj",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [
        {
          key: "shatranj-ke-khilari-1977-cine-ssb-000013b",
          value:ly1,
          date_of_artwork: "1981",
          wnn: "000013b",
          accession_number: "Cine.ssb",
          name_of_publication: "Silsila",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Satyajit Ray",
          medium_of_artwork: "Song Synopsis Booklet",
          revised_medium: "BOOK.ssb",
        },
      ],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "kanha-mai-tause-haari-birju-maharaj",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "2:43",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Neela aasman so gaya",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=0CjWbLCm_8s",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "birju-maharaj",
          value: "birju maharaj",
          first_name: "Birju",
          last_name: "Maharaj",
          full_name: "Calcutta Youth Choir - Amitabh Bachchan",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [
        {
          key: "shatranj-ke-khilari-1977-cine-ssb-000013b",
          value:ly1,
          date_of_artwork: "1981",
          wnn: "000013b",
          accession_number: "Cine.ssb",
          name_of_publication: "Silsila",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Satyajit Ray",
          medium_of_artwork: "Song Synopsis Booklet",
          revised_medium: "BOOK.ssb",
        },
      ],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "tadap-tadap-sagri-rayan-guzari-amjad-khan",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "3:10",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Dekha ek khwab",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=8TfVHwztF64",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "amjad-khan",
          value: "amjad khan",
          first_name: "Amjad",
          last_name: "Khan",
          full_name: "Amjad Khan - Lata Mangeshkar, Kishore Kumar",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "are-haan-chabi-dikhla-ja-reba-muhuri",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "1:26",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Khud se jo vaada kiya",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=DbvNXw3OqRw",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "reba-muhuri",
          value: "reba muhuri",
          first_name: "Reba",
          last_name: "Muhuri",
          full_name: "Reba Muhuri - Pamela Chopra",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "bajaye-bansuriya-shyam-yamuna-kinare-reba-muhuri",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "0:44",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Ye kahan aa gye hum",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=W8SmrSUgXvc",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "reba-muhuri",
          value: "reba muhuri",
          first_name: "Reba",
          last_name: "Muhuri",
          full_name: "Calcutta Youth Choir - Lata",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "bajaye-bansuriya-shyam-yamuna-kinare-reba-muhuri",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "3:56",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Rang barse bheega chunarwali",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=W8SmrSUgXvc",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "reba-muhuri",
          value: "reba muhuri",
          first_name: "Reba",
          last_name: "Muhuri",
          full_name: "Calcutta Youth Choir - Lata Mangeshkar, Amitabh Bachchan",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
  [
    {
      key: "alias",
      value: "bajaye-bansuriya-shyam-yamuna-kinare-reba-muhuri",
      display: "Alias",
    },
    {
      key: "duration_of_song",
      value: "3:56",
      display: "Duration of Song",
    },
    {
      key: "name",
      value: "Jo tum todo piya",
      display: "Name",
    },
    {
      key: "name_of_song_language_2",
      value: "",
      display: "Name of song language 2",
    },
    {
      key: "name_of_song_language_3",
      value: "",
      display: "name of song language 3",
    },
    {
      key: "song_link",
      value: "https://www.youtube.com/watch?v=W8SmrSUgXvc",
      display: "Song Link",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "personality",
      value: [
        {
          key: "reba-muhuri",
          value: "reba muhuri",
          first_name: "Reba",
          last_name: "Muhuri",
          full_name: "Calcutta Youth Choir - Lata Mangeshkar",
        },
      ],
      display: "Personality",
    },
    {
      key: "lyricsImage",
      value: [],
      display: "LyricsImage",
    },
  ],
];

export const raj_kapoor_soundtrack = [
  // [
  //   {
  //     key: "alias",
  //     value: "Content Awaited",
  //     display: "Alias",
  //   },
  //   {
  //     key: "duration_of_song",
  //     value: "Content Awaited",
  //     display: "Duration of Song",
  //   },
  //   {
  //     key: "name",
  //     value: "Content Awaited",
  //     display: "Name",
  //   },
  //   {
  //     key: "name_of_song_language_2",
  //     value: "",
  //     display: "Name of song language 2",
  //   },
  //   {
  //     key: "name_of_song_language_3",
  //     value: "",
  //     display: "name of song language 3",
  //   },
  //   {
  //     key: "song_link",
  //     value: "",
  //     display: "Song Link",
  //   },
  //   {
  //     key: "masterlist",
  //     value: "raj-kapoor",
  //     display: "Masterlist",
  //   },
  //   {
  //     key: "personality",
  //     value: [
  //       {
  //         key: "",
  //         value: "",
  //         first_name: "",
  //         last_name: "",
  //         full_name: "",
  //       },
  //     ],
  //     display: "Personality",
  //   },
  //   {
  //     key: "lyricsImage",
  //     value: [
  //       {
  //         key: "",
  //         value:ly1,
  //         date_of_artwork: "",
  //         wnn: "",
  //         accession_number: "",
  //         name_of_publication: "",
  //         publication_year: "",
  //         sortable_year_of_publication: "",
  //         artist_name: "",
  //         medium_of_artwork: "",
  //         revised_medium: "",
  //       },
  //     ],
  //     display: "LyricsImage",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "Content Awaited",
  //     display: "Alias",
  //   },
  //   {
  //     key: "duration_of_song",
  //     value: "2:43",
  //     display: "Duration of Song",
  //   },
  //   {
  //     key: "name",
  //     value: "Neela aasman so gaya",
  //     display: "Name",
  //   },
  //   {
  //     key: "name_of_song_language_2",
  //     value: "",
  //     display: "Name of song language 2",
  //   },
  //   {
  //     key: "name_of_song_language_3",
  //     value: "",
  //     display: "name of song language 3",
  //   },
  //   {
  //     key: "song_link",
  //     value: "https://www.youtube.com/watch?v=0CjWbLCm_8s",
  //     display: "Song Link",
  //   },
  //   {
  //     key: "masterlist",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "Masterlist",
  //   },
  //   {
  //     key: "personality",
  //     value: [
  //       {
  //         key: "birju-maharaj",
  //         value: "birju maharaj",
  //         first_name: "Birju",
  //         last_name: "Maharaj",
  //         full_name: "Calcutta Youth Choir - Amitabh Bachchan",
  //       },
  //     ],
  //     display: "Personality",
  //   },
  //   {
  //     key: "lyricsImage",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-cine-ssb-000013b",
  //         value:ly1,
  //         date_of_artwork: "1981",
  //         wnn: "000013b",
  //         accession_number: "Cine.ssb",
  //         name_of_publication: "Silsila",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Satyajit Ray",
  //         medium_of_artwork: "Song Synopsis Booklet",
  //         revised_medium: "BOOK.ssb",
  //       },
  //     ],
  //     display: "LyricsImage",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "",
  //     display: "Alias",
  //   },
  //   {
  //     key: "duration_of_song",
  //     value: "3:10",
  //     display: "Duration of Song",
  //   },
  //   {
  //     key: "name",
  //     value: "Dekha ek khwab",
  //     display: "Name",
  //   },
  //   {
  //     key: "name_of_song_language_2",
  //     value: "",
  //     display: "Name of song language 2",
  //   },
  //   {
  //     key: "name_of_song_language_3",
  //     value: "",
  //     display: "name of song language 3",
  //   },
  //   {
  //     key: "song_link",
  //     value: "https://www.youtube.com/watch?v=8TfVHwztF64",
  //     display: "Song Link",
  //   },
  //   {
  //     key: "masterlist",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "Masterlist",
  //   },
  //   {
  //     key: "personality",
  //     value: [
  //       {
  //         key: "amjad-khan",
  //         value: "amjad khan",
  //         first_name: "Amjad",
  //         last_name: "Khan",
  //         full_name: "Amjad Khan - Lata Mangeshkar, Kishore Kumar",
  //       },
  //     ],
  //     display: "Personality",
  //   },
  //   {
  //     key: "lyricsImage",
  //     value: [],
  //     display: "LyricsImage",
  //   },
  // ]
];