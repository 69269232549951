import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { AiOutlinePicture } from "@react-icons/all-files/ai/AiOutlinePicture";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { TbFoldersOff } from "react-icons/tb";
import dateFormat from "dateformat";

// Service files
import * as getScreeningMasterlistData from "../../../services/Screenings";

//Component files
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import { isTransformationNotAllowed } from "../../../util/isTranformationAllowed";

//Language files
import { common, explore, filterAttribute } from "../../../constants/constants";

export default function Screenings({
  CopyPasteService,
  tableName,
  masterlist,
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [signatureImg, setSignatureImg] = useState(null);
  const [apply, setApply] = useState(false);
  const [filterOptionData, setFilterOptionsData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    typeOfScreening: [],
    years: [],
    searches_v: [],
  });
  const [keyvalueData, setKeyvalueData] = useState([{}]);
  const [loading, setLoading] = useState(true);

  // Show/Hide Filter box
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
    setShowSignature(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "Type_of_Screening":
        selectedArray = filterOptions.typeOfScreening;
        break;
      case "Years":
        selectedArray = filterOptions.years;
        break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;
      default:
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    switch (value) {
      case "Type_of_Screening":
        setFilterOptions({
          ...filterOptions,
          typeOfScreening: selectedArray,
        });
        break;
      case "Years":
        setFilterOptions({
          ...filterOptions,
          years: selectedArray,
        });
        break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  const showSignatureImages = (image) => {
    setShowSignature((prev) => !prev);
    setSignatureImg(image);
  };

  // This function return Filter data options to showing in filter box
  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result =
        await getScreeningMasterlistData.getScreeningTypeFilterOptions(
          tableName,
          masterlist
        );
      if (result) {
        setFilterOptionsData(result);
      }
    };

    getFilterOptionsData();
  }, [tableName, masterlist]);

  // This function return user applied filter counts.
  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  // Fetch data function
  const fetchData = async () => {
    let result;

    if (
      Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getScreeningMasterlistData.getScreeningByMasterList(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getScreeningMasterlistData.getScreeningByMasterList(
        tableName,
        masterlist,
        filterOptions,
        searchTerm
      );
    }

    const data = result || []; // Ensure data is not null or undefined
    setLoading(false);
    setKeyvalueData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  // Need to refactor the function to bring real data for filter.
  const citationFactor = (data) => {
    return data.map((item) => ({
      year:
        item.find((nestedItem) => nestedItem.key === "sortable_date")?.value ||
        null,
      value:
        `${item.find((nestedItem) => nestedItem.key === "event_name")?.value}` +
        `: ${
          item.find((nestedItem) => nestedItem.key === "organiser")?.value ||
          null
        }` +
        (item.find((nestedItem) => nestedItem.key === "type")?.value !== ""
          ? ` | ${item.find((nestedItem) => nestedItem.key === "type")?.value}`
          : ``) +
        (item.find((nestedItem) => nestedItem.key === "opening_date")?.value !==
        ""
          ? ` | ${dateFormat(
              item.find((nestedItem) => nestedItem.key === "opening_date")
                ?.value,
              "d mmm yyyy"
            )}`
          : ``),
      images:
        item.find((nestedItem) => nestedItem.key === "screeningImages")
          ?.value[0] || null,
      wnn: item.find((nestedItem) => nestedItem.key === "wnn")?.value || null,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getScreeningMasterlistData.getScreeningByMasterList(
            tableName,
            masterlist
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          setLoading(false);
          const processedData = citationFactor(mockData);
          setKeyvalueData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getScreeningMasterlistData.getScreeningByMasterList(
              tableName,
              masterlist
            )) || {};
          const filteredMockDataResult = await filterMockData(
            filterOptions,
            searchTerm
          );
          setKeyvalueData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);

  return (
    <>
      {!loading ? (
        <>
          <Stack flexDirection={"row-reverse"} justifyContent="space-between">
            {!showFilterBox && (
              <FilterButton onClick={handleFilterClick} marginRight={"-55px"} />
            )}
            <Stack
              flexDirection={{base:"column",md:"row"}}
              justifyContent={"space-between"}
              marginTop={"0px !important"}
              pb={{base:"8",md:"0px"}}
              width={"100%"}
              minH={"100vh"}
            >
              <HStack flexDirection={"column"} px="5" width={{base:"100%",md:"720px"}}>
                <>
                  {keyvalueData.length > 0 ? (
                    <VerticalTimeline
                      layout={"1-column-left"}
                      className="layout"
                    >
                      {keyvalueData?.map((item, index) => (
                        <>
                          {item?.images && (
                            <Box pos={"relative"} right={"20px"} top={"20px"}>
                              <AiOutlinePicture
                                onClick={() =>
                                  showSignatureImages(item?.images)
                                }
                                cursor={"pointer"}
                                color="#1E90FF"
                                size={16}
                              />
                            </Box>
                          )}
                          <VerticalTimelineElement
                            key={index}
                            id="satyajit_timeline"
                            contentStyle={{ fontSize: "sm" }}
                            iconStyle={{
                              background: "#fff",
                              color: "black",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                            icon={item?.year}
                          >
                            <Stack
                              p="0"
                              flexDirection={"inherit"}
                              flexWrap="wrap"
                              gap="1"
                              onCopy={(e) =>
                                CopyPasteService(e, window.location.href)
                              }
                            >
                              <HStack>
                                <Text color="#035DA1" m="0">
                                  {item.value}
                                </Text>
                              </HStack>
                            </Stack>
                          </VerticalTimelineElement>
                        </>
                      ))}
                    </VerticalTimeline>
                  ) : (
                    <HStack w={"100%"}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        w={"inherit"}
                        justifyContent={"right"}
                        gap={4}
                      >
                        <TbFoldersOff size={40} />
                        <Text
                          py={"40"}
                          fontSize={"30px"}
                          fontWeight="700"
                          textAlign="center"
                          display={"flex"}
                          alignItems={"center"}
                        >
                          {common.NO_DATA}
                        </Text>
                      </Box>
                    </HStack>
                  )}
                </>
              </HStack>
              {showSignature && (
              <HStack width={{base:"100%",md:"320px"}}>
                  <Box pt={{base:2,md:2}}>
                    <Image
                      src={signatureImg}
                      width={"336px"}
                      height={"132px"}
                    />
                  </Box>
                </HStack>
              )}
              <HStack
                marginLeft={"4"}
                marginRight={"-55px!important"}
                alignItems={"flex-start"}
              >
                {showFilterBox && (
                  <FilterBox
                    setShowFilterBox={setShowFilterBox}
                    setApply={setApply}
                    explore={explore}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSearch={handleSearch}
                    filterOptions={filterOptions}
                    filterOptionData={filterOptionData}
                    setFilterOptions={setFilterOptions}
                    setSearchTerm={setSearchTerm}
                    filterCount={filterCount}
                    searchTerm={searchTerm}
                  />
                )}
              </HStack>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          flexDirection={"row-reverse"}
          justifyContent="space-between"
        >
          {!showFilterBox && (
            <FilterButton onClick={handleFilterClick} marginRight={"-55px"} />
          )}
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginLeft={"24px !important"}
            marginTop={"0px !important"}
            width={"100%"}
          >
            <HStack flexDirection={"column"} className="col-8">
              <Text
                py={"40"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                {common.LOADING}
              </Text>
            </HStack>
            <HStack
              marginLeft={"4"}
              marginRight={"-55px!important"}
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filterOptionData={filterOptionData}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                />
              )}
            </HStack>
          </Stack>
        </Stack>
      )}
    </>
  );
}

const FilterBox = ({
  setShowFilterBox,
  setApply,
  handleCheckboxChange,
  handleSearch,
  filterOptionData,
  filterOptions,
  setFilterOptions,
  setSearchTerm,
  searchTerm,
  filterCount,
}) => {
  return (
    <Box className="box-filterArtHeritage">
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <HStack>
          <SmallBold
            as="u"
            color={"black"}
            onClick={() => {
              setFilterOptions({
                typeOfScreening: [],
                years: [],
              });
              setSearchTerm("");
            }}
            cursor="pointer"
          >
            {explore.RESET}
            <span style={{ color: "#004F79" }}>
              {" "}
              {filterCount > 0 && ` (${filterCount})`}
            </span>
          </SmallBold>
        </HStack>
        <HStack className="mt-0">
          <RiCloseCircleFill
            size={"22px"}
            cursor="pointer"
            onClick={(e) => setShowFilterBox(false)}
          />
        </HStack>
      </Stack>
      <HStack alignItems={"left"} flexDirection={"column"}>
        <SmallBold py="4">{explore.SEARCH}</SmallBold>
        <InputGroup className="ml-0" pb="4">
          <Input
            placeholder="Search"
            onChange={handleSearch}
            value={searchTerm}
          />
          <InputRightElement>
            <BiSearch color="green.500" />
          </InputRightElement>
        </InputGroup>
      </HStack>
      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <Text fontSize={"14px"} fontWeight={"700"} lineHeight={"24px"}>
                {filterAttribute.TYPE_OF_SCREENING}
              </Text>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel
            pb={4}
            className="px-1"
            height={"144px"}
            overflowY={"scroll"}
          >
            {filterOptionData &&
              filterOptionData?.screeningTypeFilter.map((it, index) => {
                return (
                  <>
                    <Flex borderBottom={"1px"} borderBottomColor={"#f1f1f1"}>
                      <Checkbox
                        borderColor={"black"}
                        _checked={{
                          "& .chakra-checkbox__control": {
                            background: "black",
                          },
                        }}
                        iconColor="white"
                        cursor="pointer"
                        marginRight={"5px"}
                        isChecked={filterOptions.typeOfScreening.includes(it)}
                        onChange={() => {
                          handleCheckboxChange("Type_of_Screening", it);
                        }}
                      />
                      <Box p="1">
                        <Text fontSize={"14px"}>{it}</Text>
                      </Box>
                    </Flex>
                  </>
                );
              })}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton className="px-0">
            <Box flex="1" textAlign="left">
              <Text fontSize={"14px"} fontWeight={"700"} lineHeight={"24px"}>
                {filterAttribute.YEAR}
              </Text>
            </Box>
            <AccordionIcon fontSize={"30px"} />
          </AccordionButton>
          <AccordionPanel
            pb={4}
            className="px-1"
            height={"144px"}
            overflowY={"scroll"}
          >
            {filterOptionData &&
              filterOptionData?.yearFilter.map((it, index) => {
                return (
                  <>
                    <Flex borderBottom={"1px"} borderBottomColor={"#f1f1f1"}>
                      <Checkbox
                        borderColor={"black"}
                        _checked={{
                          "& .chakra-checkbox__control": {
                            background: "black",
                          },
                        }}
                        iconColor="white"
                        cursor="pointer"
                        marginRight={"5px"}
                        isChecked={filterOptions.years.includes(it)}
                        onChange={() => {
                          handleCheckboxChange("Years", it);
                        }}
                      />
                      <Box p="1">
                        <Text fontSize={"14px"}>{it}</Text>
                      </Box>
                    </Flex>
                  </>
                );
              })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <HStack marginTop={"10px"}>
        <Button
          color={"#ffffff"}
          onClick={() => {
            setApply((prev) => !prev);
            setShowFilterBox(false);
          }}
          width={"199px"}
          height={"36px"}
          bg={"#000000"}
          fontSize={"16px"}
          fontWeight={"400"}
          variant={"unstyled"}
        >
          {explore.APPLY}
        </Button>
      </HStack>
    </Box>
  );
};
