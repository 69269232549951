import axios from "axios";
import { transformJSON } from "../util/transformer";
import { mughal_filter_options, mughal_screeningData, raj_kapoor_screeningData, satyajit_filter_options, satyajit_screeningData, skk_filter_options, skk_screeningData} from "../MockData/ScreeningMockup";
import _ from "lodash";
import { schema } from "./schemas/Screening";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const getScreeningByMasterListAPI = async (tableName, masterlist, filterOptions, searchTerm) => {
  const whereCondition = {
    masterlist_IN: masterlist,
  };

  // Conditionally add type_IN property if filterOptions?.type of screening is set and not empty
  if ( filterOptions?.typeOfScreening && filterOptions?.typeOfScreening.length > 0) {
    whereCondition.type_IN = filterOptions.typeOfScreening;
  }

  // Conditionally add sortable_date_IN property if filterOptions?.years is set and not empty
  if (filterOptions?.years && filterOptions?.years.length > 0) {
    whereCondition.sortable_date_IN = filterOptions.years;
  }

  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery($where: ScreeningWhere, $options: ScreeningOptions) {` +
      tableName +
      `(where: $where, options: $options) {
          id
          event_name
          type
          sortable_date
          opening_date
          closing_date
          masterlist
          alias
          organiser
          sl_no
          venue_location
          wnn
          screeningImages {
            wnn
            accession_number
            alias
            name_of_publication
            volume_number
            publication_year
            sortable_year_of_publication
            artist_name
            date_of_artwork
            medium_of_artwork
            revised_medium
            id
            image
            height_cm
            width_cm
            title
            direct_url_of_preview_image
            medium
          }
        }
      }`,
    variables: {
      where: whereCondition,
      options: {
        sort: [
          {
            sortable_date: "ASC",
          },
        ],
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.screenings) {
    return result?.data?.data?.screenings;
  } else {
    return [];
  }
};

export const getScreeningByMasterList = async ( tableName, masterlist, filterOptions = {}, searchTerm = "") => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } = await getScreeningByMasterListDummyData(masterlist);
    return { mockData, filterMockData };
  } else if (isTransformationNotAllowed() === false) {
    const filteredData = Object.fromEntries(Object.entries(filterOptions).filter(([_, v]) => v.length > 0));
    const response = await getScreeningByMasterListAPI( tableName, masterlist, filteredData, searchTerm );
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

// Todo - Remove this after filter working on Screen page
export const getScreeningByMasterListDummyData = async (masterlist) => {
  const screeningDataMap = {
    "satyajit-ray": satyajit_screeningData,
    "shatranj-ke-khilari-1977": skk_screeningData,
    "art-heritage-annual": satyajit_screeningData,
    "mughal-e-azam": mughal_screeningData,
    "sayed-haider-raza":satyajit_screeningData,
    "raj-kapoor":raj_kapoor_screeningData,
  }
  const mockData= screeningDataMap[masterlist];
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories.typeOfScreening.length > 0 ||
      filterCategories.years.length > 0 ||
      searchTerm !== ""
    ) {
      const filteredData = _.filter(mockData, entry => {

        const isTypeMatch = filterCategories.typeOfScreening.length > 0
          ? filterCategories.typeOfScreening.includes(entry.find(item => item.key === 'type').value)
          : true;

      const isYearsCheck =
        filterCategories.years.length > 0
          ? filterCategories.years.includes(
              entry.find((item) => item.key === "sortable_date").value
            )
          : true;

      const searchTermCheck =
        searchTerm !== ""
          ? entry
              .find((item) => item.key === "type")
              .value.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            entry
              .find((item) => item.key === "sortable_date")
              .value.includes(searchTerm)
          : true;

      return isTypeMatch && isYearsCheck && searchTermCheck;
    });
    return filteredData;
  } else {
    return mockData;
  }
};
return {
  mockData,
  filterMockData,
};
};

export const getScreeningTypeFilterOptionsAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery($where: ScreeningWhere, $options: ScreeningOptions) {` +
      tableName +
      `(where: $where, options: $options) {
              type
              sortable_date             
          }
        }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.screenings) {
    return result?.data?.data?.screenings;
  } else {
    return [];
  }
};

export const getScreeningTypeFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getScreeningTypeFilterOptionsDummyData(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await getScreeningTypeFilterOptionsAPI(tableName, masterlist);
    const FilterOptions = {
      screeningTypeFilter: [...new Set(response.map((item) => item.type.trim()).filter((value)=> value !== ""))],
      yearFilter: [...new Set(response.map((item) => item.sortable_date.trim()).filter((value)=> value !== ""))],
    };

    return FilterOptions;
  }
};

const getScreeningTypeFilterOptionsDummyData = ( masterlist) => {
  const ScreeningTypeFilterOptionsMap = {
    "satyajit-ray": satyajit_filter_options,
    "shatranj-ke-khilari-1977": skk_filter_options,
    "mughal-e-azam": mughal_filter_options,
  };
  const ScreeningTypeFilterOptionsData = ScreeningTypeFilterOptionsMap[masterlist] || satyajit_filter_options;
  return ScreeningTypeFilterOptionsData;
};
