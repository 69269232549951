import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Stack,
  Container,
  Text,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Accordion,
  InputRightElement,
  Input,
  InputGroup,
  Button,
  Flex,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
} from "@chakra-ui/react";

// Core files
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import InfiniteScroll from 'react-infinite-scroll-component';

// Service Files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getArchiveData from "../../../services/Archive";

// Subsection Files
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import { common, explore, filterAttribute } from "../../../constants/constants";
import ScrollToTop from "../../../components/sections/ScrollToTop";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import { isTransformationNotAllowed } from "../../../util/isTranformationAllowed";
import { useParams } from "react-router";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import ImageGridList from "../Component/ImageGridList";

//ImageFiles
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export const Archive = ({ tableName, masterlist }) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {},sortBy_v: "" });
  const [apply, setApply] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [archiveFullScreenImage, setArchiveFullScreenImage] = useState(false);
  const [archiveImgData, setArchiveImgData] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { alias } = useParams();
  // const isInitialRender = useRef(true);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  //  Filter Execution starts
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches",e.target.value)
  };

const handleCheckboxChange = (value, type) => {
  let selectedArray = [];
  let selectedString = "";

  switch (value) {
    case "sort":
      selectedString = filterOptions.sortBy_v;
      break;
    case "searches":
      selectedString = filterOptions.search;
      break;
    default:
      if (value in filterOptions.filters) {
        selectedArray = filterOptions.filters[value];
      }
      break;
  }

  if (selectedArray.includes(type)) {
    selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
  } else {
    selectedArray = [...selectedArray, type];
  }

  if (selectedString !== type) {
    selectedString = type;
  }

  switch (value) {
    case "sort":
      setFilterOptions(prevFilterOptions => ({
        ...prevFilterOptions,
        sortBy_v: selectedString,
      }));
      break;
    case "searches":
      setFilterOptions(prevFilterOptions => ({
        ...prevFilterOptions,
        search: selectedString,
      }));
      break;
    default:
      if (value in filterOptions.filters) {
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          filters: {
            ...prevFilterOptions.filters,
            [value]: selectedArray,
          },
        }));
      }
      break;
  }
};

  const citationFactor = (data) => {
    return data.map((item) => ({
      image: item.find((nestedItem) => nestedItem.key ===
        "direct_url_of_preview_image")?.value ||
        "",
        title:item.find((nestedItem) => nestedItem.key ===
        "bibliograhpic_citation")?.value ||
        ""
    }));
  };
  const [facets, setFacets] = useState([]);
  const [sort, setSort] = useState([]);
  async function fetchData() {
    try {
      const  result = await getArchiveData.getArchiveImages('person-actor', alias,filterOptions,page);
      
      if (result && result?.data?.length>0 && isTransformationNotAllowed() === true) {
        const processedData = citationFactor(result?.data);
         setData(processedData);
        setPage(prevPage => prevPage + 1);
        const formattedFacets = result?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key]; // Assuming facetsData has only one object
    
          return {
            key,
            display,
            value
          };
        });
        const sortedFacets = result?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
      });
        setSort(sortedFacets);
        setFacets(formattedFacets);
        if(formattedFacets.length>0){
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          filters: {
            ...prevFilterOptions.filters,
            ...formattedFacets.reduce((acc, facet) => {
              acc[facet.key] = [];
              return acc;
            }, {})
          }
        }));
      }
    }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    // if (isInitialRender.current) {
    //   isInitialRender.current = false;  // Mark initial render as complete
    //   return;  // Skip the initial render
    // }
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {
        try {
      const  mockData = await getArchiveData.getArchiveImages('person-actor', alias,filterOptions,page);
      
      if (mockData && isTransformationNotAllowed() === true) {
       
        const processedData = citationFactor(mockData?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
        const formattedFacets = mockData?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = mockData?.facets[0][key]; // Assuming facetsData has only one object
    
          return {
            key,
            display,
            value
          };
        });
        const sortedFacets = mockData?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
      });
        setSort(sortedFacets);
        setFacets(formattedFacets);
        if (data.length===mockData?.total) {
          setHasMore(false);
        }
        
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData() {

    try {
      const  mockData = await getArchiveData.getArchiveImages('person-actor', alias,filterOptions,page) || {};

      if (mockData && isTransformationNotAllowed() === true) {
        const processedData = citationFactor(mockData?.data);
        setData(processedData);
        const formattedFacets = mockData?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = mockData?.facets[0][key]; // Assuming facetsData has only one object
    
          return {
            key,
            display,
            value
          };
        });
        setFacets(formattedFacets);
        const sortedFacets = mockData?.sortByHeader?.map(obj => {
          const key = Object.keys(obj)[0];
          return { key: key, display: obj[key] };
      });
        setSort(sortedFacets);
        if (data.length===mockData?.total) {
          setHasMore(false);
        }
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    // if (isInitialRender.current) {
    //   isInitialRender.current = false;  // Mark initial render as complete
    //   return;  // Skip the initial render
    // }
    if(apply){
      setApply(false);
      fetchFilterData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const showArchiveImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setArchiveFullScreenImage(flag);
      setArchiveImgData(image);
    } else {
      setArchiveFullScreenImage(flag);
      setArchiveImgData(null);
    }
  };
  return (
    <>
      <Stack flexDirection={"row-reverse"} height={"100%"} maxW={"1160px"} marginTop={"30px"}>
        {!showFilterBox && (
          <FilterButton onClick={handleFilterClick} />
        )}

        <Container maxW={"auto"} py="8" pr={0}>
          <Stack flexDirection={"row"} justifyContent="flex-start" gap={{ base: 0, md: 12 }}>
            {data.length > 0 ?
              <HStack marginRight={{base:"0px",md:showFilterBox ? "220px":"30px"}}
              >
                 <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Box textAlign="center" marginLeft={"50%"}>
                  <Stack marginTop={"20px"}>
                     <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                  </Stack>
                </Box>}
                  endMessage={<p>No more items to load</p>}
                  >
                <ImageGridList
                  columns={[1, null, 4]}
                  spacing={"38px"}
                  height={"249px"}
                  data={data}
                  showArchiveImages={showArchiveImages}
                  archive={true}
                  text={true}
                />
                </InfiniteScroll>
              </HStack> :
              <>
                <HStack
                  onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
                  margin={"auto"}
                >
                  <Text
                    py={"40"}
                    fontSize={"45px"}
                    fontWeight="700"
                    textAlign="center"
                  >
                    {common.NO_DATA}
                  </Text>
                </HStack>
              </>
            }

            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filter-personality-actor" paddingTop={"30px"}>
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold as="u" color={"black"} cursor="pointer" onClick={() => {
                        setSearchTerm("");
                        setFilterOptions({
                          search: "",
                          filters: {
                            ...facets.reduce((acc, facet) => {
                              acc[facet.key] = [];
                              return acc;
                            }, {})
                          },
                          sortBy_v: ""
                        });
                        setHasMore(true);
                      }}>
                        {explore.RESET}{" "}

                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        onChange={handleSearch}
                        value={searchTerm}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      />
                      <InputRightElement>
                        <BiSearch color="green.500" />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <SmallBold>{filterAttribute.SORT_BY}</SmallBold>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <Box maxH="200px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {sort?.length>0 &&
                            sort?.map((it) => {
                              return (
                                <Flex borderBottom={'1px'} borderBottomColor={"#f1f1f1"}>
                                  <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.sortBy_v===it?.key
                                    }
                                    onChange={() => {
                                      handleCheckboxChange("sort", it.key);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>
                                      {it.display}
                                    </Text>
                                  </Box>
                                </Flex>
                              );
                            })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem> 
                    {
                      facets.length>0 && facets?.map((item,id)=>(
                        <AccordionItem>
                      <AccordionButton className="px-0" key={id}>
                        <Box flex="1" textAlign="left">
                          <SmallBold>{item?.display}</SmallBold>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <Box maxH="200px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {item?.value?.length>0 &&
                            item?.value?.map((it) => {
                              return (
                                <Flex borderBottom={'1px'} borderBottomColor={"#f1f1f1"}>
                                 <Checkbox
                                    borderColor={"black"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    iconColor="white"
                                    cursor="pointer"
                                    marginRight={"5px"}
                                    isChecked={filterOptions.filters[item?.key].includes(
                                      it?._id
                                    )}
                                    onChange={() => {
                                      handleCheckboxChange(item?.key, it?._id);
                                    }}
                                  />
                                  <Box p="1">
                                    <Text fontSize={"14px"}>
                                      {it?._id}
                                    </Text>
                                  </Box>
                                </Flex>
                              );
                            })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                      ))
                    }
                  </Accordion>
                  <HStack marginTop={"10px"}>
                    <Button
                      color={"#ffffff"}
                      onClick={() => {
                        setApply(true);
                        setPage(1)
                        setShowFilterBox(false);
                      }}
                      width={"199px"}
                      height={"36px"}
                      bg={"#000000"}
                      fontSize={"16px"}
                      fontWeight={"400"}
                      variant={"unstyled"}
                    >
                      {explore.APPLY}
                    </Button>
                  </HStack>
                </Box>
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
      {archiveFullScreenImage && <ImagePopupModal
        isOpen={archiveFullScreenImage}
        onClose={() => showArchiveImages(null, false)}
        imageData={archiveImgData}
        data={data}
        clickedImageIndex={clickedImageIndex}
        imageHeight={{ base: "100%", md: "90vh" }}
      />}
      <ScrollToTop />
     
    </>
  );
};

export const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  imageHeight,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                        height={"100vh"}
                      >
                        <Image
                          src={
                            item.image
                          }
                          height={imageHeight ? imageHeight : "100%"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.title}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};