import {
  AspectRatio,
  Box,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Layout from "../components/sections/SidebarLayout";
import "react-vertical-timeline-component/style.min.css";
import SongsCard from "../components/sections/SongsCard";
import { useNavigate } from "react-router-dom";

import ScrollToTop from "../components/sections/ScrollToTop";
import { common, homeTitle } from "../constants/constants";
import { CopyPasteService } from "../services/CopyPaste";

function NewallSongs() {
  const [paginationData, setPaginationData] = React.useState([]);
  const [songs, setSongs] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const [url, setUrl] = React.useState();
  React.useEffect(() => {
    const getData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/rest/songs`
      );
      if (result?.data?.songs) {
        const dataCategories = result?.data?.songs.slice(0);
        setSongs(dataCategories);
      }
      setPage(1);
    };
    getData();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    //setPaginationData(pagination(songs, 8, page));
    setPaginationData(songs);
  }, [page, songs]);
  // const listInnerRef = React.useRef();
  // const onScroll = () => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //     }
  //   }
  //   console.log("onScroll");
  // };
  let toggel = true;
  const [dummyData, setDummyData]= useState (false)
    const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
    useEffect (()=>{
      if (dummyDataEnabled.toLowerCase() === "yes") {
        setDummyData(true)
      } else {
        setDummyData(false)
      }
    },[dummyDataEnabled]) 
  return (
    <Layout>
      <Stack mx="8" className="mobileviewpadding">
        <Box py="5" onCopy={(e) => CopyPasteService(e, window.location.href)}>
          {dummyData?   <Heading fontSize={"2xl"}>
           {homeTitle.Heading3}
          </Heading> :  <Heading fontSize={"2xl"}>
            {common.WEBPAGE_COMING_SOON}
          </Heading>
        } 
        </Box>
        {paginationData.length === 0 ? (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
           {common.WEBPAGE_COMING_SOON}
          </Text>
        ) : (
          <SimpleGrid columns={[2, null, 4]} spacing={0}>
            {paginationData ? (
              paginationData.map((item, index) => {
                const dataResult = toggel ? "same" : "reverse";
                if ((index + 1) % 4 === 0) {
                  toggel = !toggel;
                }
                return (
                  <Box
                    onClick={() => navigate("/songpage/" + item.id)}
                    cursor={"pointer"}
                    // onClick={() => setUrl(item?.youtube_link)}
                  >
                    <SongsCard item={item} type={dataResult} />{" "}
                  </Box>
                );
              })
            ) : (
              <React.Fragment />
            )}
          </SimpleGrid>
        )}
      </Stack>
      <Box>
        <Modal
          motionPreset="slideInBottom"
          size={"3xl"}
          isOpen={url}
          onClose={() => setUrl()}
        >
          <ModalOverlay />
          <ModalContent>
            <Box bg={"white"} m={4} pos={"relative"}>
              <AspectRatio w="auto" height={"auto"} ratio={16 / 9}>
                <iframe
                  title="All Songs"
                  src={
                    url
                      ? url.replace("watch?v=", "embed/") + "?autoplay=1"
                      : "https://www.youtube.com/embed/w_BmHDD8rSM"
                  }
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
          </ModalContent>
        </Modal>
      </Box>
      <ScrollToTop />
    </Layout>
  );
}
export default NewallSongs;
