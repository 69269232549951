import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Box, Container, Image, Stack, } from "@chakra-ui/react";
import React from "react";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner1 = `${IMAGE_BASE_PATH}/ui/EbrahimAlkazi/ebrahimAlkazi.png`
const Banner2 = `${IMAGE_BASE_PATH}/ui/EbrahimAlkazi/ebrahimAlkazi.png`
const Banner3 = `${IMAGE_BASE_PATH}/ui/EbrahimAlkazi/ebrahimAlkazi.png`
const Banner4 = `${IMAGE_BASE_PATH}/ui/EbrahimAlkazi/ebrahimAlkazi.png`
const Banner5 = `${IMAGE_BASE_PATH}/ui/EbrahimAlkazi/ebrahimAlkazi.png`

const menu = [
  "Introduction",
  "Body of Work",
  "Integrated Timeline",
  "Economics of Art",
  "Research Centre",
];
const urlAliase = [
  "introduction",
  "body-of-work",
  "integrated-timeline",
  "economics-of-art",
  "research centre",
];
const banners = [Banner1, Banner2, Banner3, Banner4, Banner5];

function Slider() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      const isLastItem = index === menu.length - 1;
      const pipeCharacter = isLastItem ? "" : " | ";
      return `<span class="${className}">${menu[index]}</span>${pipeCharacter}`;
    },
  };

  return (
    <>
      <Container maxW="auto" className="songs" id="allslider">
        <Stack width={"100%"} position={"relative"} mb="20">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            // loop={true}
            navigation
            height={"100%"}
            id="subsliderebrahimA"
            style={{left:"154px !important"}}
            // className="sliderborder"
            pagination={pagination}
          >
            {urlAliase.map((alias, index) => {
              let imgPath = banners[index];
              return (
                <SwiperSlide>
                  <Box className="Bgimage">
                    {/* <Link
                      key={index}
                      href={`/shatranj-ke-khilari-1977/${alias}/`}
                    > */}
                      <Image src={imgPath} alt="" />
                      {/* <Text
                        py={"40"}
                        fontSize={"45px"}
                        fontWeight="700"
                        textAlign="center"
                        color="black"
                      >
                        Content Awaited
                      </Text> */}
                    {/* </Link> */}
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Stack>
      </Container>
    </>
  );
}
export default Slider;
