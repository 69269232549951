import React from "react";
import { Box, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { common, explore } from "../../constants/constants";
import { getExploreData } from "../../services/ExploreService";
import H4 from "../../components/layouts/Typography/H4";
// import { IoIosLink } from "react-icons/io";
// import { IoIosArrowRoundForward } from "react-icons/io";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ExplorePosts() {
  // getExploreData
  const [exploreData, setExploreData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);
  const { t } = useTranslation("");

  React.useEffect(() => {
    const getData = async () => {
      const result = await getExploreData();
      if (result?.length > 0) {
        setExploreData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  return (
    <Stack p="0">
      {showLabel ? (
        <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
          {t(common.WEBPAGE_COMING_SOON)}
        </Text>
      ) : (
        <>
          <SimpleGrid columns={{ base: 2, md: 3, lg: 5, xl: 5 }} spacing="16px">
            {exploreData.map((item) => {
              return (
                <Link
                  key={item.id}
                  to={item.id > 12 ? null : item.navigationLink}
                  cursor={item.id > 12 ? "" : "pointer"}
                > 
                  <Box className="card" width={"100%"} height="240px">
                    <Image
                      src={item.imgPath}
                      height="auto"
                      width="100%"
                    />
                    <Box textAlign={"center"} className="overlay">
                      <Stack alignItems={"center"}>
                        {/* <Text pt="12" pb="4" className="text-p" ><IoIosLink fontSize={"28px"} fontWeight={"600"} color="white" /></Text> */}
                        <Text
                          fontSize={"16px"}
                          fontWeight={"600"}
                          className="text-p"
                        >
                          {item.title}
                        </Text>
                        {/* <Text  className="text-p">
                          {item.desc}
                        </Text> */}
                        {/* <Stack flexDirection={"row"}>
                          <Text width={"-moz-max-content"} fontSize={"14px"} className="text-p readmore">
                            Read More <IoIosArrowRoundForward />
                          </Text>
                        </Stack> */}
                      </Stack>
                    </Box>
                  </Box>
                </Link>
              );
            })}
          </SimpleGrid>
          <H4 color="#0368B4" textAlign="center" pt="27px" pb="17px">
            {t(explore.SEE_MORE)}
          </H4>
        </>
      )}
    </Stack>
  );
}

export default ExplorePosts;
