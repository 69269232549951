import { Box, Button, Container, HStack, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';

//Core file
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//Service file
import { isTransformationNotAllowed } from '../../util/isTranformationAllowed';
import * as getPostData from '../../services/Post';

//Language file
import { common } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

export default function GlobalPost() {
    const [postsData, setPostsData] = useState(true);
    const { t } = useTranslation("");

    useEffect(() => {
      if (isTransformationNotAllowed() === false) {
        setPostsData(false);
      }
      // eslint-disable-next-line
    }, []);
    const [postData, setPostData] = useState([]);
    useEffect(() => {
      const getData = async () => {
        const result = await getPostData.getPosts(
          "posts",
          "globalposts"
        );
        if (result) {
          setPostData(result);
        }
      };
      getData();
    }, []);
  return (
    <>
      {postsData ? (
        <>
          <Container
            maxW="auto"
            pr="0px"
            pl="0px"
          >
            <Stack width={"100%"} position={"relative"} id="allsliderskkObject">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                slidesPerView={1}
                navigation
                height={"100%"}
                className="subslider"
              >
                {postData.length > 0 &&
                  postData.map((it, index) => (
                    <SwiperSlide>
                      <Box
                        width={"auto"}
                        height={{ base: "auto", md: "570px" }}
                        className="Bgimage"
                      >
                        <Link to={`/film/shatranj-ke-khilari-1977/post/${it.post}`}>
                          <Image
                            src={it.imgPath}
                            width={"auto"}
                            height={"100%"}
                            alt=""
                          />

                          {index === 0 && (
                            <Box
                              className="top-right"
                              zIndex={1}
                              pt="8"
                              pl="40"
                              id="DivForHoverItem"
                              width={"570px"}
                              height="700px"
                              _hover={{
                                backgroundColor: "black",
                                opacity: "0.6",
                              }}
                            >
                              <Text
                                className="title-on-slide"
                                fontSize={"20px"}
                                fontWeight={"600"}
                                teststyle="secondary"
                                textAlign={"left"}
                                id="HiddenText"
                                color="white"
                              >
                                {t(common.CONTENT_AWAITED)}
                              </Text>
                              <Text
                                fontSize={"10px"}
                                fontWeight={"400"}
                                lineHeight={"12px"}
                                teststyle="secondary"
                                textAlign={"left"}
                                py="1"
                                id="HiddenText"
                                color="white"
                              >
                                ------------------------------------------------------
                              </Text>
                              <Text
                                fontSize={"10px"}
                                fontWeight={"400"}
                                lineHeight={"12px"}
                                teststyle="secondary"
                                textAlign={"left"}
                                py="1"
                                id="HiddenText"
                                color="white"
                              >
                                -------------------------------------
                              </Text>
                              <Stack
                                spacing={4}
                                direction="row"
                                gap={"3"}
                                className="btn-wrap-onmobile"
                                align="center"
                              >
                                <Link
                                  className="ml-0"
                                  to="/satyajit-ray/introduction"
                                >
                                  <Button
                                    id="HiddenText"
                                    className="btn-on-slide"
                                    color={"black"}
                                    // size={"md"}
                                    bg="#ffffffb5"
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                    height={"30px"}
                                  >
                                    Satyajit ray
                                  </Button>
                                </Link>
                                <Link className="ml-0" to="#">
                                  <Button
                                    id="HiddenText"
                                    className="btn-on-slide"
                                    color={"black"}
                                    // size={"md"}
                                    bg="#ffffffb5"
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                    height={"30px"}
                                  >
                                    Shatranj Ke Khilari
                                  </Button>
                                </Link>
                                <Link className="ml-0" to="#">
                                  <Button
                                    id="HiddenText"
                                    className="btn-on-slide"
                                    color={"black"}
                                    // size={"md"}
                                    bg="#ffffffb5"
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                    height={"30px"}
                                  >
                                    1977
                                  </Button>
                                </Link>
                              </Stack>
                            </Box>
                          )}
                        </Link>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
          </Container>
          <Container maxW="auto" marginTop={{ base: "0px", md: "40px" }}>
            <Stack className="exploretabs">
              <SimpleGrid columns={[1, null, 3]} w={"auto"} spacing="30px">
                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                  return (
                    <Box height="auto" key={index}>
                      <Link to="">
                        <Box
                          height="272px"
                          width={"auto"}
                          bg={"#C4C4C4"}
                          display="flex"
                        ></Box>
                      </Link>
                      <Stack pb="8" mt="8px">
                        <HStack>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color="#035DA1"
                          >
                            {common.CONTENT_AWAITED}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color="#333333"
                          >
                            {common.CONTENT_AWAITED}
                          </Text>
                        </HStack>
                      </Stack>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Stack>
          </Container>
        </>
      ) : (
        <Text py={"20"} fontSize={"20px"} fontWeight="600" textAlign="center">
          {t(common.WEBPAGE_COMING_SOON)}
        </Text>
      )}
    </>
    )
}
