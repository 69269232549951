import React, { useEffect, useState } from "react";
import Layout from "../components/sections/SidebarLayout";
import { Container, Stack, Text, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { userRegistrationForm, userProfile } from "../constants/constants";

function MyProfile() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const userData = localStorage.getItem("user");

    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  return (
    <Layout>
      {user === null ? (
        <HStack border={"1px"} borderColor="#e8e8e8" borderRadius={"2px"} p="1">
          <Text>{userProfile.LOGIN_INSTRUCTION}</Text>
          <Link to="/login">
            <Text color="blue.500" textDecoration="underline">
              {userProfile.CLICK_HERE}
            </Text>
          </Link>
        </HStack>
      ) : (
        <Stack className="mobileviewpadding">
          <Container mt="16">
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              lineHeight={"16px"}
              color="#080808"
            >
              {`${userRegistrationForm.REGISTRATION_FORM_FIELD_NAME_LABEL}: ${user.name}`}
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              lineHeight={"16px"}
              color="#080808"
            >
              {`${userRegistrationForm.REGISTRATION_FORM_FIELD_EMAIL_ADDRESS_LABEL}: ${user.email}`}
            </Text>
          </Container>
        </Stack>
      )}
    </Layout>
  );
}

export default MyProfile;
