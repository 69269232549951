import { Stack } from "@chakra-ui/react";

//Core files
import React from "react";

//Component files
import GridView from "./Exhibits/GridView";
import TabularView from "./Exhibits/TabularView";

export const Exhibits = ({
    winnerName,
    visible
}) => {


  return (
    <Stack position={"relative"}>
      {visible ? (
        <>
          <GridView
            winnerName={winnerName}
          />
        </>
      ) : (
        <>
          <TabularView
           winnerName={winnerName}
          />
        </>
      )}
    </Stack>
  );
};
