import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Stack,
  Container,
  Text,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Accordion,
  InputRightElement,
  Input,
  InputGroup,
  Divider,
  Button,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import SmallBold from "../../../components/layouts/Typography/SmallBold";
import { common, explore } from "../../../constants/constants";
import ScrollToTop from "../../../components/sections/ScrollToTop";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import * as getArchiveData from "../../../services/Archive";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import { isTransformationNotAllowed } from "../../../util/isTranformationAllowed";
import { CopyPasteService } from "../../../services/CopyPaste";
import ImageGridList from "../../Film/BodyOfWork/CrewAndCast/ImageGridList";

export const Archive = ({tableName,masterlist}) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({ docType: [], sortBy_v: [], searches_v: [] });
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [apply, setApply] = useState(false);
  const [mockFilterData, setMockFilterData] = useState([]);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  React.useEffect(() => {
    const getFilterOptionsData = async () => {
      const result =
        await getArchiveData.getArchiveFilterOptions(
          tableName,
          masterlist
        );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist,tableName]);

  //  Filter Execution starts

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "docType":
        selectedArray = filterOptions.docType;
        break;
      // case "film_type":
      //   selectedArray = filterOptions.sortBy_v;
      //   break;
      case "searches":
        selectedArray = filterOptions.searches_v;
        break;    
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "docType":
        setFilterOptions({
          ...filterOptions,
          docType: selectedArray,
        });
        break;
      // case "film_type":
      //   setFilterOptions({
      //     ...filterOptions,
      //     sortBy_v: selectedArray,
      //   });
      //   break;
      case "searches":
        setFilterOptions({
          ...filterOptions,
          searches_v: selectedArray,
          });
        break;        
      default:
        break;
    }
  };

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const fetchData = async () => {
    let result;

    if (Object.values(filterOptions).every((options) => options.length === 0) && searchTerm === "") {
      // No filters applied, fetch all data
      result = await getArchiveData.getArchiveImages(tableName, masterlist);
    } else {
      // Filters are applied, fetch filtered data
      result = await getArchiveData.getArchiveImages(tableName, masterlist, filterOptions, searchTerm);
    }

    const data = result || []; // Ensure data is not null or undefined
    setMockFilterData(citationFactor(data));
  };

  useEffect(() => {
    if(isTransformationNotAllowed() === false){
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(isTransformationNotAllowed() === false){
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  const citationFactor = (data) => {
    return data?.map((item) => ({
      image:item?.find((nestedItem) => nestedItem?.key ===
      "direct_url_of_preview_image")?.value ||
      null
    }));
  };

  useEffect(() => {

    async function fetchData() {
      try {
        const { mockData } = await getArchiveData.getArchiveImages( tableName, masterlist ) || {};
  
        if (mockData && isTransformationNotAllowed()===true) {
          const processedData = citationFactor(mockData);
          setMockFilterData(processedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed()===true) {
        try {
          const { filterMockData } = await getArchiveData.getArchiveImages( tableName, masterlist ) || {};
          const filteredMockDataResult = await filterMockData(filterOptions, searchTerm);
          setMockFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error('Error filtering data:', error);
        }
        setApply(false);
      }
    }
    fetchData();
  }, [apply, filterOptions, masterlist, searchTerm, tableName]);
  return (
    <>
      <Stack flexDirection={"row-reverse"} height={"100%"} maxW={"1160px"}>
      {!showFilterBox && (
            <FilterButton onClick={handleFilterClick}   />
          )}

        <Container maxW={"auto"}  px="0" py="8">
          <Stack flexDirection={"row"} justifyContent="flex-start" gap={12}>
            {mockFilterData.length>0?
            <HStack marginRight={"10px"}>
              <ImageGridList
                columns={[0, null, 4]}
                spacing={"38px"}
                height={"249px"}
                data={mockFilterData}
                text={false}
              />
            </HStack>:
            <>
            <HStack
            onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
            margin={"auto"}
          >
              <Text
                py={"40"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                {common.NO_DATA}
              </Text>
              </HStack>
            </>
            }

            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <Box className="box-filterSKK" paddingTop={"30px"}>
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <HStack>
                        <SmallBold as="u" color={"black"} cursor="pointer" onClick={() => {
                              setFilterOptions({
                                docType: []
                              });
                              setSearchTerm("");
                              }}>
                          {explore.RESET}{" "}
                          <span style={{color:"#004F79"}}> {filterCount > 0 && ` (${filterCount})`}</span> 

                        </SmallBold>
                      </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack
                    alignItems={"left"}
                    flexDirection={"column"}
                    marginTop={"25px"}
                  >
                    <InputGroup className="ml-0" pb="4">
                      <Input
                        placeholder="Search"
                        height={"32px"}
                        pb={"4px"}
                        onChange={handleSearch}
                        value={searchTerm}
                        _placeholder={{ fontSize: "12px", fontWeight: "400" }}
                      />
                      <InputRightElement>
                        <BiSearch color="green.500" />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <SmallBold>Sort By</SmallBold>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <Box maxH="130px" overflowY="auto">
                        <AccordionPanel pb={4} className="px-1">
                          {["Featured Personality", "Document Type"].map(
                            (item) => {
                              return (
                                <>
                                  <Flex>
                                    <Checkbox
                                      // isChecked={selections.includes(
                                      //   item?.sortable_year_date_of_publication
                                      // )}
                                      // onChange={() =>
                                      //   handleOptionClick(
                                      //     item?.sortable_year_date_of_publication
                                      //   )
                                      // }
                                      _checked={{
                                        "& .chakra-checkbox__control": {
                                          background: "black",
                                        },
                                      }}
                                      iconColor="white"
                                      cursor="pointer"
                                      marginRight={"5px"}
                                    />
                                    <Text
                                      fontSize={"14px"}
                                      pt={"4px"}
                                      pb={"4px"}
                                    >
                                      {item}
                                    </Text>
                                  </Flex>
                                  <Divider color="#909090" />
                                </>
                              );
                            }
                          )}
                          <Divider color="#909090" />
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <SmallBold>Document Type</SmallBold>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <Box maxH="200px" overflowY="auto">  
                        <AccordionPanel pb={4} className="px-1">
                          {filteroptionData.documentTypeFilter &&
                            filteroptionData.documentTypeFilter.map((it) => {
                              return (
                                 <Flex borderBottom={'1px'} borderBottomColor={"#f1f1f1"}>
                                 <Checkbox
                                   borderColor={"black"}
                                   _checked={{
                                     "& .chakra-checkbox__control": {
                                       background: "black",
                                     },
                                   }}
                                   iconColor="white"
                                   cursor="pointer"
                                   marginRight={"5px"}
                                   isChecked={filterOptions.docType.includes(
                                     it
                                   )}
                                   onChange={() => {
                                     handleCheckboxChange("docType", it);
                                   }}
                                 />
                                  <Box  p="1">
                                   <Text fontSize={"14px"}>
                                   {it}
                                 </Text>
                                 </Box>
                               </Flex>
                              );
                            })}
                        </AccordionPanel>
                      </Box>
                    </AccordionItem>
                  </Accordion>
                  <HStack marginTop={"10px"}>
                        <Button
                          color={"#ffffff"}
                          onClick={() => {
                            setApply((prev) => !prev);
                            setShowFilterBox(false);
                          }}
                          width={"199px"}
                          height={"36px"}
                          bg={"#000000"}
                          fontSize={"16px"}
                          fontWeight={"400"}
                          variant={"unstyled"}
                        >
                          {explore.APPLY}
                        </Button>
                      </HStack>
                </Box>
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
      <ScrollToTop />
    </>
  );
};
